import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  List,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { ListItem } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import { useEffect, useState } from 'react';
import { AtsConfirm } from 'utils/swal';
import { swalContent } from 'types';
import {
  useDeleteRecruitersMutation,
  useGetJobsMutation,
  useListJobsMutation,
} from 'store/jobs.slice';
import JobsRecruitersModal from './Modal/recruiters';

const JobsRecruitmentTeam = () => {
  const toast = useToast();

  const { userList } = useSelector((state: any) => state.user);
  const { fullData, jobPgBtn, jobData } = useSelector(
    (state: any) => state.jobs
  );

  const [recruitmentTeamData, setRecruitmentTeamData] = useState({});

  const [reqJobs] = useListJobsMutation();
  const [reqGetJob] = useGetJobsMutation();
  const [reqDeleteRecruiter, resDeleteRecruiter] =
    useDeleteRecruitersMutation();

  const {
    isOpen: isEditRecruitmentTeamOpen,
    onOpen: onEditRecruitmentTeamOpen,
    onClose: onEditRecruitmentTeamClose,
  } = useDisclosure();

  let content: swalContent = {
    title: 'Are you sure?',
    text: 'You are about delete this recruit team, continue?',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleDeleteRecruitmentTeam = async (data: any) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      reqDeleteRecruiter({ id: data.id });
    }
  };

  useEffect(() => {
    if (resDeleteRecruiter.isSuccess) {
      reqJobs(jobPgBtn);
      reqGetJob({ id: jobData.id });

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (resDeleteRecruiter.isSuccess) {
          title = 'Deleted Recruitment Team';
          description = 'A recruitment team has been deleted';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [
    jobData?.id,
    jobPgBtn,
    reqGetJob,
    reqJobs,
    resDeleteRecruiter.isSuccess,
    toast,
  ]);

  const amFirstName =
    (jobData?.am_id &&
      Object.values(userList).filter((obj: any) => {
        return obj.id === jobData.am_id;
      })[0]) ||
    '';
  const amLastName =
    (jobData?.am_id &&
      Object.values(userList).filter((obj: any) => {
        return obj.id === jobData.am_id;
      })[0]) ||
    '';

  const hasRecruiterData =
    jobData.user_id &&
    Object.values(userList).filter((obj: any) => {
      return obj.id === jobData.user_id;
    })[0];

  return (
    <Box fontFamily="Proxima Nova Regular">
      <List>
        {fullData.recruiters?.map((item: any, key: number) => {
          return (
            <ListItem key={`recruiters-${key}`}>
              <Flex position="relative" borderBottom="0.5px solid #E6E6E6">
                <Box p="20px 22px">
                  <Flex gap={2} mb="8px">
                    <Box
                      fontSize="15px"
                      lineHeight="18px"
                      textAlign="left"
                      width="130px"
                      color="default.gray.600"
                    >
                      Created By:
                    </Box>
                    <Box sx={{ color: 'default.dark', fontSize: '15px' }}>
                      {[
                        (hasRecruiterData as any)?.first_name,
                        (hasRecruiterData as any)?.last_name,
                      ].join(' ')}
                    </Box>
                  </Flex>
                  <Flex gap={2} mb="8px">
                    <Box
                      fontSize="15px"
                      lineHeight="18px"
                      textAlign="left"
                      width="130px"
                      color="default.gray.600"
                    >
                      Primary Recruiter:
                    </Box>
                    <Box sx={{ color: 'default.dark', fontSize: '15px' }}>
                      {[
                        item.recruiter?.first_name,
                        item.recruiter?.last_name,
                      ].join(' ')}
                    </Box>
                  </Flex>
                  <Flex gap={2} mb="8px">
                    <Box
                      fontSize="15px"
                      lineHeight="18px"
                      textAlign="left"
                      width="130px"
                      color="default.gray.600"
                    >
                      Account Manager:
                    </Box>
                    <Box sx={{ color: 'default.dark', fontSize: '15px' }}>
                      {[
                        (amFirstName as any)?.first_name,
                        (amLastName as any)?.last_name,
                      ].join(' ')}
                    </Box>
                  </Flex>
                  <Flex gap={2}>
                    <Box
                      fontSize="15px"
                      lineHeight="18px"
                      textAlign="left"
                      width="130px"
                      color="default.gray.600"
                    >
                      Split Percentage:
                    </Box>
                    <Box sx={{ color: 'default.dark', fontSize: '15px' }}>
                      {item.split} / {100 - parseFloat(item.split)}
                    </Box>
                  </Flex>
                </Box>
                <Flex
                  gap="15px"
                  position="absolute"
                  right="55px"
                  top="0"
                  bottom="0"
                >
                  <ButtonGroup>
                    <Button
                      variant="ghost"
                      color="#6930CA"
                      onClick={() => {
                        setRecruitmentTeamData(item);
                        onEditRecruitmentTeamOpen();
                      }}
                    >
                      <FAIcon iconName="pencil" />
                    </Button>
                    <Button
                      variant="ghost"
                      color="#CB2C2C"
                      onClick={() => handleDeleteRecruitmentTeam(item)}
                    >
                      <FAIcon iconName="trash" />
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Flex>
            </ListItem>
          );
        })}
      </List>
      {isEditRecruitmentTeamOpen && (
        <JobsRecruitersModal
          isOpen={isEditRecruitmentTeamOpen}
          onClose={onEditRecruitmentTeamClose}
          recruitmentData={recruitmentTeamData}
          update={true}
        />
      )}
    </Box>
  );
};

export default JobsRecruitmentTeam;

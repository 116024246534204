import * as Yup from 'yup';
import {
    Box,
    Checkbox,
    CloseButton,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    Radio,
    Stack,
    TabPanels,
    Tag,
    TagCloseButton,
    TagLabel,
    Text,
    Button as ChakraButton,
    ModalFooter
} from '@chakra-ui/react';
import ScrollToFieldError from 'components/app/ScrollError';
import { Field, FieldArray, Formik } from 'formik';
import { useState, useEffect, useMemo } from 'react';
import LoadingEditCandidate from '../../components/CandidateLoadingModal';
import AtsSelect from 'components/app/AtsSelect';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';
import AtsSelect3 from 'components/app/AtsSelect3';
import { CheckIcon } from '@chakra-ui/icons';
import AddressComponent from '../../components/address';
import { authStorage } from 'utils/localStorage';
import { useSelector } from 'react-redux';

import {
    useGetCandidateSourceMutation,
    useGetCandidateWorkStatusMutation,
    useGetCountriesMutation,
} from 'store/constant.slice';

import { useUserListMutation } from 'store/user.slice';


interface initialValueInt {
    first_name: string;
    last_name: string;
    emails: { email: string, primary: boolean }[];
    contact: { number: string, primary: boolean, type: string, category: any }[];
    linkedin_url: string;
    work_status: string;
    source_type: any;
    sub_source_type: any;
    skills: string[];
    zip_code: string;
    city: string;
    state_province: string;
    country: any;
    dnc: boolean;
}

interface CandidatePersonalProps {
    initialValues: initialValueInt;
    onSubmit: (e: any) => void;
    CandidateModalFooter?: any;
    edit: boolean;
    candidateData: any;
    loading: boolean;
    countryOption: any;
    isOpenAlert: boolean;
    userSource: any;
}

export default function CandidatePersonal({
    initialValues,
    onSubmit,
    CandidateModalFooter,
    edit,
    candidateData,
    loading,
    countryOption,
    isOpenAlert,
    userSource,
}: CandidatePersonalProps) {
    const authSt = authStorage();
    const { countries, candidate_work_status, candidate_source, educations } =
        useSelector((state: any) => state.constants);
    const { userList, UserPgBtn } = useSelector((state: any) => state.user);


    const [reqCandSource, resCandSource] = useGetCandidateSourceMutation();
    const [reqCountry] = useGetCountriesMutation();
    const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
    const [reqUserList, resUserList] = useUserListMutation();

    // SKILLS
    const [hasSkills, setHasSkills] = useState('');

    // SOURCE & SUBSOURCE
    const [candSource, setCandSource] = useState([]);
    const [candSubSource, setCandSubSource] = useState([]);
    // const [userSource, setUserSource] = useState([]);
    const [candSourceVisible, setCandSourceVisible] = useState(false);

    // COUNTRY
    // const [countryOption, setCountryOption] = useState([]);

    const subSource = (sub: any) => {
        let option: any = [];
        if (sub?.length > 0) {
            sub.map((item: any) => {
                option.push({
                    label: item?.sub_source,
                    value: item?.id,
                });
            });
        }
        setCandSubSource(option);
    };

    useMemo(() => {
        // reqCountry({});
        reqCandSource({}).then((res: any) => {
            let option: any = [];
            if (res?.data?.data?.length > 0) {
                res?.data?.data.map((item: any) => {
                    option.push({
                        label: item.source,
                        value: item.id,
                        sub: item.sub,
                    });
                });
            }
            setCandSource(option);
        });
        // reqCanWorkStat({});
        // reqUserList({ data: UserPgBtn });
    }, [])

    useMemo(() => {
        if (candidateData) {
            if (candidateData?.source) {
                if (candidateData?.source?.source !== "Career's Page")
                    setCandSourceVisible(true);
            }
            if (candidateData?.subsource) {
                subSource(candidateData?.source?.sub);
                setCandSourceVisible(true);
            }
            if (candidateData?.user_source) {
                setCandSourceVisible(true);
                setCandSubSource(userSource);
            }

            if (candidateData?.source?.id === 3) {
                setCandSourceVisible(false);
                setCandSubSource(userSource);
            }
        }
    }, [candSource]);

    const validationSchema = edit
        ? Yup.lazy(() =>
            Yup.object().shape({
                first_name: Yup.string(),
                last_name: Yup.string().required('Last name is required.'),
                emails: Yup.array()
                    .of(
                        Yup.object().shape({
                            email: Yup.string().required('Email is required'),
                            primary: Yup.boolean(),
                        })
                    )
                    .test(
                        'emails-one-primary',
                        'There should only be one primary email',
                        (value) => {
                            const primaryCount = value?.filter(
                                (item) => item?.primary
                            ).length;
                            return primaryCount === 1;
                        }
                    )
                    .test('email-unique', 'Email should be unique', (value) => {
                        const numArr = value?.map((item: any) => item?.email);
                        return numArr.length === new Set(numArr).size;
                    }),
                contact: Yup.array()
                    .of(
                        Yup.object().shape({
                            number: Yup.string().matches(
                                /^[\d ()+-.]+$/gi,
                                'Contact is invalid'
                            ),
                            primary: Yup.boolean(),
                            type: Yup.string(),
                        })
                    )
                    .test(
                        'contact-one-primary',
                        'There should only be one primary contact',
                        (value) => {
                            const primaryCount = value?.filter(
                                (item) => item?.primary
                            ).length;
                            return primaryCount === 1;
                        }
                    )
                    .test(
                        'contact-unique',
                        'Contact number should be unique',
                        (value) => {
                            const numArr = value?.map((item: any) => item?.number);
                            return numArr.length === new Set(numArr).size;
                        }
                    ),
                linkedin_url: Yup.string(),
                work_status: Yup.string().nullable(),
                source_type: Yup.object().shape({
                    label: Yup.string().required('Pick at least 1 Source'),
                    value: Yup.number(),
                    sub: Yup.array(),
                }),
                sub_source_type: Yup.object().when('source_type', {
                    is: (value: any) => value.label === "Career's Page",
                    then: Yup.object().shape({}).nullable(),
                    otherwise: Yup.object().shape({
                        label: Yup.string().required('Pick at least 1 Sub Source'),
                        value: Yup.number(),
                    }),
                }),
                skills: Yup.array().of(Yup.string()),
                zip_code: Yup.string().nullable(),
                city: Yup.string().nullable(),
                state_province: Yup.string().nullable(),
                country: Yup.object(),
            })
        )
        : Yup.lazy(() =>
            Yup.object().shape({
                // personal
                first_name: Yup.string(),
                last_name: Yup.string().required('Last name is required.'),
                // emails
                emails: Yup.array()
                    .of(
                        Yup.object().shape({
                            email: Yup.string().required('Email is required'),
                            primary: Yup.boolean(),
                        })
                    )
                    .test(
                        'emails-one-primary',
                        'There should only be one primary email',
                        (value) => {
                            const primaryCount = value?.filter(
                                (item) => item?.primary
                            ).length;
                            return primaryCount === 1;
                        }
                    )
                    .test('email-unique', 'Email should be unique', (value) => {
                        const numArr = value?.map((item: any) => item?.email);
                        return numArr.length === new Set(numArr).size;
                    }),
                // contact
                contact: Yup.array()
                    .of(
                        Yup.object().shape({
                            number: Yup.string().matches(
                                /^[\d ()+-.]+$/gi,
                                'Contact is invalid'
                            ),
                            primary: Yup.boolean(),
                            type: Yup.string(),
                        })
                    )
                    .test(
                        'contact-one-primary',
                        'There should only be one primary contact',
                        (value) => {
                            const primaryCount = value?.filter(
                                (item) => item?.primary
                            ).length;
                            return primaryCount === 1;
                        }
                    )
                    .test(
                        'contact-unique',
                        'Contact number should be unique',
                        (value) => {
                            const numArr = value?.map((item: any) => item?.number);
                            return numArr.length === new Set(numArr).size;
                        }
                    ),
                linkedin_url: Yup.string(),
                work_status: Yup.string().nullable(),
                source_type: Yup.object().shape({
                    label: Yup.string().required('Pick at least 1 Source'),
                    value: Yup.number(),
                    sub: Yup.array(),
                }),
                sub_source_type: Yup.object().when('source_type', {
                    is: (value: any) => value.label === "Career's Page",
                    then: Yup.object().shape({}).nullable(),
                    otherwise: Yup.object().shape({
                        label: Yup.string().required('Pick at least 1 Sub Source'),
                        value: Yup.number(),
                    }),
                }),
                skills: Yup.array().of(Yup.string()),
                zip_code: Yup.string().required('Zip Code is required').nullable(),
                city: Yup.string().required('City is required').nullable(),
                state_province: Yup.string().required('State is required').nullable(),
                country: Yup.object().required('Country is required'),
            })
        );

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({
                    values,
                    handleChange,
                    handleSubmit,
                    errors,
                    touched,
                    isValid,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <ScrollToFieldError />
                        <TabPanels>
                            {loading || resCandSource.isLoading ? (
                                <Box
                                    overflowY="scroll"
                                    minHeight="450px"
                                    p="30px"
                                    background="#fff"
                                    borderRadius="8px"
                                >
                                    <LoadingEditCandidate />
                                </Box>
                            ) : (
                                <Box
                                    overflowY="scroll"
                                    maxHeight="450px"
                                    p="30px"
                                    background="#fff"
                                    borderRadius="8px"
                                >
                                    <Flex gap="32px" mb="34px">
                                        <FormControl
                                            isInvalid={Boolean(
                                                !!errors.first_name &&
                                                touched.first_name
                                            )}
                                        >
                                            <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                            >
                                                First Name
                                            </FormLabel>
                                            <Input
                                                id="first_name"
                                                name="first_name"
                                                type="text"
                                                placeholder="First Name"
                                                variant="outline"
                                                value={values.first_name}
                                                onChange={handleChange}
                                            />
                                            <FormErrorMessage>
                                                {String(errors.first_name)}
                                            </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                            isInvalid={Boolean(
                                                !!errors.last_name && touched.last_name
                                            )}
                                        >
                                            <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                            >
                                                Last Name{' '}
                                                <Box as="span" color="caution.800">
                                                    *
                                                </Box>
                                            </FormLabel>
                                            <Input
                                                id="last_name"
                                                name="last_name"
                                                type="text"
                                                placeholder="Last Name"
                                                variant="outline"
                                                value={values.last_name}
                                                onChange={handleChange}
                                            />
                                            <FormErrorMessage>
                                                {String(errors.last_name)}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Flex>
                                    <Flex gap="32px" mb="34px">
                                        <FormControl>
                                            <Checkbox
                                                colorScheme="purple"
                                                color="default.secondarytext"
                                                mx={1}
                                                onChange={handleChange}
                                                name="dnc"
                                                id="dnc"
                                                defaultChecked={values.dnc}
                                            >
                                                DNC
                                            </Checkbox>
                                        </FormControl>
                                    </Flex>
                                    <Flex gap="32px" mb="34px">
                                        <Box
                                            overflowY="auto"
                                            maxHeight="450px"
                                            width="100%"
                                        >
                                            <FieldArray name="contact">
                                                {({ remove, push }) => (
                                                    <div>
                                                        <FormLabel
                                                            fontSize="14px"
                                                            lineHeight="18px"
                                                            color="default.secondarytext"
                                                        >
                                                            Contact
                                                        </FormLabel>

                                                        {values.contact?.map(
                                                            (cont: any, index: any) => (
                                                                <FormControl
                                                                    isInvalid={Boolean(
                                                                        !!errors.contact &&
                                                                        touched.contact
                                                                    )}
                                                                >
                                                                    <FormControl
                                                                        isInvalid={Boolean(
                                                                            !!(
                                                                                errors.contact as any[]
                                                                            )?.[index]?.number &&
                                                                            (
                                                                                touched.contact as any[]
                                                                            )?.[index]?.number
                                                                        )}
                                                                    >
                                                                        <Flex
                                                                            width="100%"
                                                                            direction="row"
                                                                            key={index}
                                                                            mb="12px"
                                                                            alignItems="center"
                                                                            gap="12px"
                                                                        >
                                                                            <Flex width="70%">
                                                                                <Field
                                                                                    as={Input}
                                                                                    name={`contact.${index}.number`}
                                                                                    placeholder="Contact"
                                                                                    size="md"
                                                                                    type="text"
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                />
                                                                            </Flex>

                                                                            <Flex width="25%">
                                                                                <FormControl
                                                                                    isInvalid={Boolean(
                                                                                        !!(
                                                                                            errors.contact as any[]
                                                                                        )?.[index]
                                                                                            ?.category &&
                                                                                        (
                                                                                            touched.contact as any[]
                                                                                        )?.[index]
                                                                                            ?.category
                                                                                    )}
                                                                                >
                                                                                    <Flex
                                                                                        justifyContent="center"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <AtsSelect
                                                                                            variant="outline"
                                                                                            menuPosition="fixed"
                                                                                            defaultValue={
                                                                                                values.contact[
                                                                                                    index
                                                                                                ].category
                                                                                            }
                                                                                            onChange={(
                                                                                                e: any
                                                                                            ) => {
                                                                                                setFieldValue(
                                                                                                    `contact.${index}.category`,
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                            options={[
                                                                                                {
                                                                                                    value:
                                                                                                        'Personal',
                                                                                                    label: (
                                                                                                        <FAIcon iconName="user" />
                                                                                                    ),
                                                                                                },
                                                                                                {
                                                                                                    value: 'Work',
                                                                                                    label: (
                                                                                                        <FAIcon iconName="briefcase" />
                                                                                                    ),
                                                                                                },
                                                                                            ]}
                                                                                            isSearchable={
                                                                                                false
                                                                                            }
                                                                                            styles={{
                                                                                                zIndex: '9999',
                                                                                                indicatorSeparator:
                                                                                                    () => { },
                                                                                                dropdownIndicator:
                                                                                                    (
                                                                                                        defaultStyles: any
                                                                                                    ) => ({
                                                                                                        ...defaultStyles,
                                                                                                        paddingLeft:
                                                                                                            '0px',
                                                                                                        '& svg': {
                                                                                                            width:
                                                                                                                '10px',
                                                                                                            height:
                                                                                                                '10px',
                                                                                                        },
                                                                                                    }),
                                                                                            }}
                                                                                        />
                                                                                    </Flex>
                                                                                </FormControl>
                                                                            </Flex>

                                                                            <Flex
                                                                                width="5%"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                            >
                                                                                <FormControl>
                                                                                    <Flex
                                                                                        justifyContent="center"
                                                                                        alignItems="center"
                                                                                    >
                                                                                        <Radio
                                                                                            id={`contact.${index}.primary`}
                                                                                            name={`contactPrimary`} // Set the same name for all radio buttons
                                                                                            value={index} // Use the index as the value
                                                                                            isChecked={
                                                                                                cont.primary
                                                                                            }
                                                                                            onChange={(e) => {
                                                                                                // Handle radio button change to update the selected row
                                                                                                const updatedContact =
                                                                                                    values.contact.map(
                                                                                                        (
                                                                                                            contact: any,
                                                                                                            key: number
                                                                                                        ) => ({
                                                                                                            ...contact,
                                                                                                            primary:
                                                                                                                key ===
                                                                                                                index,
                                                                                                        })
                                                                                                    );
                                                                                                setFieldValue(
                                                                                                    'contact',
                                                                                                    updatedContact
                                                                                                );
                                                                                            }}
                                                                                        ></Radio>
                                                                                    </Flex>
                                                                                </FormControl>
                                                                            </Flex>
                                                                            <Flex
                                                                                width="5%"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                            >
                                                                                {values.contact.length >
                                                                                    1 && (
                                                                                        <Flex
                                                                                            onClick={() => {
                                                                                                remove(index);
                                                                                                if (
                                                                                                    values.contact
                                                                                                        .length === 2
                                                                                                ) {
                                                                                                    // console.log(
                                                                                                    //   'went here to make last one email primary to true'
                                                                                                    // );
                                                                                                    const updatedContact =
                                                                                                        values.contact
                                                                                                            .map(
                                                                                                                (
                                                                                                                    contact: any,
                                                                                                                    key: number
                                                                                                                ) => ({
                                                                                                                    ...contact,
                                                                                                                    primary:
                                                                                                                        true,
                                                                                                                })
                                                                                                            )
                                                                                                            .filter(
                                                                                                                (
                                                                                                                    contact: any,
                                                                                                                    key: number
                                                                                                                ) =>
                                                                                                                    index !==
                                                                                                                    key
                                                                                                            );
                                                                                                    setFieldValue(
                                                                                                        'contact',
                                                                                                        updatedContact
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            justifyContent="center"
                                                                                            alignItems="center"
                                                                                            color="#D5605D"
                                                                                            cursor="pointer"
                                                                                        >
                                                                                            <FAIcon iconName="trash" />
                                                                                        </Flex>
                                                                                    )}
                                                                            </Flex>
                                                                        </Flex>
                                                                        <FormErrorMessage mb="12px">
                                                                            {String(
                                                                                (
                                                                                    errors.contact as any[]
                                                                                )?.[index]?.number
                                                                            )}
                                                                        </FormErrorMessage>
                                                                    </FormControl>
                                                                    {index + 1 ===
                                                                        values.contact.length &&
                                                                        typeof errors.contact !==
                                                                        'object' && (
                                                                            <FormErrorMessage mb="12px">
                                                                                {String(errors.contact)}
                                                                            </FormErrorMessage>
                                                                        )}
                                                                </FormControl>
                                                            )
                                                        )}

                                                        <Button
                                                            leftIcon="plus"
                                                            onClick={() => {
                                                                push({
                                                                    number: '',
                                                                    type: 'phone',
                                                                    primary: false,
                                                                    category: {
                                                                        value: 'Personal',
                                                                        label: (
                                                                            <FAIcon iconName="user" />
                                                                        ),
                                                                    },
                                                                });
                                                            }}
                                                        >
                                                            Add Contact
                                                        </Button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </Box>
                                        <Box
                                            overflowY="auto"
                                            maxHeight="450px"
                                            width="100%"
                                        >
                                            <FieldArray name="emails">
                                                {({ remove, push }) => (
                                                    <div>
                                                        <FormLabel
                                                            fontSize="14px"
                                                            lineHeight="18px"
                                                            color="default.secondarytext"
                                                        >
                                                            Email{' '}
                                                            <Box
                                                                as="span"
                                                                color="caution.800"
                                                            >
                                                                *
                                                            </Box>
                                                        </FormLabel>

                                                        {values.emails?.map(
                                                            (email: any, index: any) => (
                                                                <FormControl
                                                                    isInvalid={Boolean(
                                                                        !!errors.emails &&
                                                                        touched.emails
                                                                    )}
                                                                >
                                                                    <FormControl
                                                                        isInvalid={Boolean(
                                                                            !!(
                                                                                errors.emails as any[]
                                                                            )?.[index]?.email &&
                                                                            (
                                                                                touched.emails as any[]
                                                                            )?.[index]?.email
                                                                        )}
                                                                    >
                                                                        <Flex
                                                                            width="100%"
                                                                            direction="row"
                                                                            key={index}
                                                                            mb="12px"
                                                                            alignItems="center"
                                                                            gap="12px"
                                                                        >
                                                                            <Flex width="80%">
                                                                                <Field
                                                                                    as={Input}
                                                                                    name={`emails.${index}.email`}
                                                                                    placeholder="Email Address"
                                                                                    size="md"
                                                                                    type="text"
                                                                                    onChange={
                                                                                        handleChange
                                                                                    }
                                                                                />
                                                                            </Flex>

                                                                            <Flex
                                                                                width="10%"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                            >
                                                                                <Flex
                                                                                    justifyContent="center"
                                                                                    alignItems="center"
                                                                                >
                                                                                    <Radio
                                                                                        id={`emails.${index}.primary`}
                                                                                        name={`emailsPrimary`} // Set the same name for all radio buttons
                                                                                        value={index} // Use the index as the value
                                                                                        isChecked={
                                                                                            email.primary
                                                                                        }
                                                                                        onChange={(e) => {
                                                                                            // Handle radio button change to update the selected row
                                                                                            const updatedEmails =
                                                                                                values.emails.map(
                                                                                                    (
                                                                                                        email: any,
                                                                                                        key: number
                                                                                                    ) => ({
                                                                                                        ...email,
                                                                                                        primary:
                                                                                                            key ===
                                                                                                            index,
                                                                                                    })
                                                                                                );
                                                                                            setFieldValue(
                                                                                                'emails',
                                                                                                updatedEmails
                                                                                            );
                                                                                        }}
                                                                                    ></Radio>
                                                                                </Flex>
                                                                            </Flex>

                                                                            <Flex
                                                                                width="10%"
                                                                                justifyContent="center"
                                                                                alignItems="center"
                                                                            >
                                                                                {values.emails.length >
                                                                                    1 && (
                                                                                        <Flex
                                                                                            onClick={() => {
                                                                                                remove(index);
                                                                                                if (
                                                                                                    values.emails
                                                                                                        .length === 2
                                                                                                ) {
                                                                                                    // console.log(
                                                                                                    //   'went here to make last one email primary to true'
                                                                                                    // );
                                                                                                    const updatedEmails =
                                                                                                        values.emails
                                                                                                            .map(
                                                                                                                (
                                                                                                                    email: any,
                                                                                                                    key: number
                                                                                                                ) => ({
                                                                                                                    ...email,
                                                                                                                    primary:
                                                                                                                        true,
                                                                                                                })
                                                                                                            )
                                                                                                            .filter(
                                                                                                                (
                                                                                                                    email: any,
                                                                                                                    key: number
                                                                                                                ) =>
                                                                                                                    index !==
                                                                                                                    key
                                                                                                            );
                                                                                                    setFieldValue(
                                                                                                        'emails',
                                                                                                        updatedEmails
                                                                                                    );
                                                                                                }
                                                                                            }}
                                                                                            justifyContent="center"
                                                                                            alignItems="center"
                                                                                            color="#D5605D"
                                                                                            cursor="pointer"
                                                                                        >
                                                                                            <FAIcon iconName="trash" />
                                                                                        </Flex>
                                                                                    )}
                                                                            </Flex>
                                                                        </Flex>
                                                                        <FormErrorMessage mb="12px">
                                                                            {String(
                                                                                (
                                                                                    errors.emails as any[]
                                                                                )?.[index]?.email
                                                                            )}
                                                                        </FormErrorMessage>
                                                                    </FormControl>
                                                                    {index + 1 ===
                                                                        values.emails.length &&
                                                                        typeof errors.emails !==
                                                                        'object' && (
                                                                            <FormErrorMessage mb="12px">
                                                                                {String(errors.emails)}
                                                                            </FormErrorMessage>
                                                                        )}
                                                                </FormControl>

                                                                // </div>
                                                            )
                                                        )}

                                                        <Button
                                                            leftIcon="plus"
                                                            onClick={() => {
                                                                push({
                                                                    email: '',
                                                                    primary: false,
                                                                });
                                                            }}
                                                        >
                                                            Add Email
                                                        </Button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </Box>
                                    </Flex>

                                    <Flex gap="32px" mb="34px">
                                        <FormControl
                                            isInvalid={Boolean(
                                                !!errors.linkedin_url &&
                                                touched.linkedin_url
                                            )}
                                        >
                                            <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                            >
                                                LinkedIn Url
                                            </FormLabel>
                                            <Input
                                                id="linkedin_url"
                                                name="linkedin_url"
                                                type="text"
                                                placeholder="LinkedIn Url"
                                                variant="outline"
                                                value={values.linkedin_url}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                        <FormControl
                                            isInvalid={Boolean(
                                                !!errors.work_status &&
                                                touched.work_status
                                            )}
                                        >
                                            <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                            >
                                                Work Status
                                            </FormLabel>

                                            <AtsSelect
                                                id="work_status"
                                                name="work_status"
                                                placeholder="Select"
                                                variant="outline"
                                                onChange={(e: any) => {
                                                    setFieldValue(
                                                        'work_status',
                                                        String(e.value)
                                                    );
                                                }}
                                                defaultValue={
                                                    (candidate_work_status?.length > 0 &&
                                                        candidate_work_status
                                                            .map((item: any) => ({
                                                                label: item.status,
                                                                value: item.id,
                                                            }))
                                                            .filter(
                                                                (val: any) =>
                                                                    val.value ===
                                                                    Number(values.work_status)
                                                            )[0]) ||
                                                    values.work_status
                                                }
                                                options={
                                                    candidate_work_status?.length > 0 &&
                                                    candidate_work_status.map(
                                                        (item: any) => ({
                                                            label: item.status,
                                                            value: item.id,
                                                        })
                                                    )
                                                }
                                            />
                                            <FormErrorMessage>
                                                {String(errors.work_status)}
                                            </FormErrorMessage>
                                        </FormControl>
                                    </Flex>
                                    <Flex gap="32px" mb="34px">
                                        <FormControl
                                            isInvalid={Boolean(
                                                !!errors.source_type &&
                                                touched.source_type
                                            )}
                                        >
                                            <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                            >
                                                Candidate Source{' '}
                                                <Box as="span" color="caution.800">
                                                    *
                                                </Box>
                                            </FormLabel>
                                            <AtsSelect
                                                options={candSource}
                                                isLoading={resCandSource.isLoading}
                                                defaultValue={values.source_type}
                                                onChange={(e: any) => {
                                                    // && (values.work_status)

                                                    if (
                                                        e.value === 3 ||
                                                        e.value === 5 ||
                                                        e.value === 6
                                                    ) {
                                                        const data = {
                                                            value: authSt?.id,
                                                            label: [
                                                                authSt?.first_name,
                                                                authSt?.last_name,
                                                            ].join(' '),
                                                        };
                                                        setFieldValue(
                                                            'sub_source_type',
                                                            data
                                                        );

                                                        setCandSubSource(userSource);
                                                        if (e.value === 3) {
                                                            setCandSourceVisible(false);
                                                        } else {
                                                            setCandSourceVisible(true);
                                                        }
                                                    } else {
                                                        subSource(e?.sub);
                                                        setCandSourceVisible(true);
                                                        const data = e?.sub?.[0]
                                                            ? {
                                                                label: e.sub[0].sub_source,
                                                                value: e.sub[0].id,
                                                            }
                                                            : null;
                                                        setFieldValue(
                                                            'sub_source_type',
                                                            data
                                                        );
                                                        if (e.sub.length === 0) {
                                                            setFieldValue(
                                                                'sub_source_type',
                                                                null
                                                            );
                                                            setCandSourceVisible(false);
                                                        }
                                                    }
                                                    setFieldValue('source_type', e);
                                                }}
                                            />
                                            <FormErrorMessage>
                                                {errors.source_type &&
                                                    touched.source_type &&
                                                    String(
                                                        (errors.source_type as any).label
                                                    )}
                                            </FormErrorMessage>
                                        </FormControl>
                                        {candSourceVisible ? (
                                            <FormControl
                                                isInvalid={Boolean(
                                                    !!errors.sub_source_type &&
                                                    touched.sub_source_type &&
                                                    !candSourceVisible
                                                )}
                                            >
                                                <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                >
                                                    Sub Source
                                                </FormLabel>
                                                <AtsSelect3
                                                    options={candSubSource}
                                                    defaultValue={values.sub_source_type}
                                                    value={values.sub_source_type}
                                                    onChange={(e: any) => {
                                                        setFieldValue('sub_source_type', e);
                                                    }}
                                                />
                                                <FormErrorMessage>
                                                    {errors.sub_source_type &&
                                                        touched.sub_source_type &&
                                                        String(
                                                            (errors.sub_source_type as any)
                                                                .label
                                                        )}
                                                </FormErrorMessage>
                                            </FormControl>
                                        ) : (
                                            <FormControl></FormControl>
                                        )}
                                    </Flex>

                                    <Flex gap="32px" mb="34px">
                                        <FormControl>
                                            <FieldArray name="skills">
                                                {({ push }) => {
                                                    const handleKeyDown = (e: any) => {
                                                        if (e.key === 'Enter') {
                                                            push(hasSkills);
                                                            setHasSkills('');
                                                        }
                                                    };
                                                    return (
                                                        <FormControl mb="16px">
                                                            <Flex
                                                                justifyContent="space-between"
                                                                alignItems="flex-end"
                                                            >
                                                                <FormLabel
                                                                    fontSize="14px"
                                                                    lineHeight="18px"
                                                                    color="default.secondarytext"
                                                                >
                                                                    Skills
                                                                </FormLabel>
                                                            </Flex>
                                                            <InputGroup>
                                                                <Input
                                                                    name="skills"
                                                                    id="kills"
                                                                    placeholder="Type Skills Name"
                                                                    type="text"
                                                                    variant="outline"
                                                                    value={hasSkills}
                                                                    onChange={(e) =>
                                                                        setHasSkills(e.target.value)
                                                                    }
                                                                    onKeyDown={handleKeyDown}
                                                                />
                                                                <InputRightElement
                                                                    background="#F3EEFB"
                                                                    borderRadius="0 4px 4px 0"
                                                                    zIndex={0}
                                                                    children={
                                                                        <CheckIcon color="priamry.800" />
                                                                    }
                                                                />
                                                            </InputGroup>
                                                            <Input display="none" />
                                                        </FormControl>
                                                    );
                                                }}
                                            </FieldArray>
                                            <FieldArray name="skills">
                                                {({ remove }) => (
                                                    <Flex
                                                        gap="10px"
                                                        display="inline-block"
                                                    >
                                                        {values.skills?.length > 0 &&
                                                            values.skills?.map(
                                                                (skill: any, index: any) => (
                                                                    <Tag
                                                                        style={{
                                                                            display: 'inline-block',
                                                                        }}
                                                                        key={index}
                                                                        mr="10px"
                                                                        mb="10px"
                                                                    >
                                                                        <Stack spacing={1}>
                                                                            <HStack>
                                                                                <TagLabel maxW="100px">
                                                                                    {skill as String}
                                                                                </TagLabel>
                                                                                <TagCloseButton
                                                                                    onClick={() => {
                                                                                        remove(index);
                                                                                    }}
                                                                                />
                                                                            </HStack>
                                                                        </Stack>
                                                                    </Tag>
                                                                )
                                                            )}
                                                    </Flex>
                                                )}
                                            </FieldArray>
                                        </FormControl>
                                    </Flex>

                                    <Box>
                                        <Text mb="34px">Address Information</Text>
                                        <AddressComponent
                                            setFieldValue={setFieldValue}
                                            handleChange={handleChange}
                                            countryOption={countryOption}
                                            countryField={{
                                                name: 'country',
                                                value: values.country,
                                                errors: errors.country,
                                                touched: touched.country,
                                            }}
                                            cityField={{
                                                name: 'city',
                                                value: values.city,
                                                errors: errors.city,
                                                touched: touched.city,
                                            }}
                                            stateField={{
                                                name: 'state_province',
                                                value: values.state_province,
                                                errors: errors.state_province,
                                                touched: touched.state_province,
                                            }}
                                            zipField={{
                                                name: 'zip_code',
                                                value: values.zip_code,
                                                errors: errors.zip_code,
                                                touched: touched.zip_code,
                                            }}
                                            edit={edit}
                                        />
                                    </Box>
                                </Box >
                            )
                            }
                        </TabPanels >
                        {CandidateModalFooter(handleSubmit, errors, isValid, values)}
                    </form >
                )}
            </Formik >
        </>
    )
};
import { Flex } from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';

export const tableHeader: { label: any }[] = [
  { label: '' },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#38A169"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
        >
          <FAIcon iconName="user" />
        </Flex>
        New Clients
      </Flex>
    ),
  },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#975A16"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
        >
          <FAIcon iconName="user-friends" />
        </Flex>
        new client w/ interview
      </Flex>
    ),
  },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#285E61"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
        >
          <FAIcon iconName="briefcase" />
        </Flex>
        job posted
      </Flex>
    ),
  },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#00B5D8"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
        >
          <FAIcon iconName="arrow-right" />
        </Flex>
        submits
      </Flex>
    ),
  },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#319795"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
        >
          <FAIcon iconName="calendar" />
        </Flex>
        interviews
      </Flex>
    ),
  },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#DD6B20"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
        >
          <FAIcon iconName="align-left" />
        </Flex>
        offers
      </Flex>
    ),
  },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#805AD5"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
        >
          <FAIcon iconName="trophy" />
        </Flex>
        placements
      </Flex>
    ),
  },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#D53F8C"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
          sx={{
            transform: 'rotate(315deg)',
          }}
        >
          <FAIcon iconName="long-arrow-right" />
        </Flex>
        outbound calls
      </Flex>
    ),
  },
  {
    label: (
      <Flex gap="4px">
        <Flex
          color="#D69E2E"
          fontSize="12px"
          mt="auto"
          mb="auto"
          verticalAlign="center"
        >
          <FAIcon iconName="money-bill" />
        </Flex>
        revenue
      </Flex>
    ),
  },
];

export const tableHeader1: { label: any }[] = [
  { label: 'Rank' },
  { label: 'Name' },
  { label: 'Jobs Closed' },
  { label: 'Revenue' },
];

export const chartMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const byMonth = [
  {
    value: 0,
    label: 'by month',
  },
  {
    value: 1,
    label: 'by quarter',
  },
  {
    value: 2,
    label: 'current year',
  },
  {
    value: 3,
    label: 'year-to-date',
  },
];

export const byActivity = [
  {
    value: 0,
    label: 'current week',
  },
  {
    value: 1,
    label: 'current month',
  },
  {
    value: 2,
    label: 'current quarter',
  },
  {
    value: 3,
    label: 'last week',
  },
  {
    value: 4,
    label: 'last 2 weeks',
  },
  {
    value: 5,
    label: 'last month',
  },
  {
    value: 6,
    label: 'last 30 days',
  },
  {
    value: 7,
    label: 'last quarter',
  },
  {
    value: 8,
    label: 'last 3 months',
  },
  {
    value: 9,
    label: 'YTD',
  },
];

import { Field, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormErrorMessage,
  Flex,
  Text,
  Textarea,
  Wrap,
  WrapItem,
  useToast,
  Grid,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import * as Yup from 'yup';

import ScrollToFieldError from 'components/app/ScrollError';
import ReactQuill from 'react-quill';
import BoxLoading from 'components/app/Loading/box';
import 'react-quill/dist/quill.snow.css';
import 'assets/css/minHquill.css';
import AtsDropZone from 'components/app/Dropzone';
import AtsCraetableSelDyn from 'components/app/AtsCreatabeSelect/Dyanmic';
import {
  useListTemplateMutation,
  usePlaceHoldersMutation,
  useUpdateTemplateMutation,
} from 'store/template.slice';
import { FaRegCopy } from 'react-icons/fa';
import { useGroupsMutation } from 'store/email_group.slice';
import TemplateAttachments from './attachment';
import { cleanUpload } from 'store/uploads.slice';
import AtsEmailBody from 'components/app/EmailBody';
import AtsSelect from 'components/app/AtsSelect';
import { useUserListMutation } from 'store/user.slice';
import '../../../../../../assets/css/custom-modal.css';

interface TemplateAdd {
  isOpen: any;
  onClose: any;
  template: any;
}

export default function TemplateEditModal({
  isOpen,
  onClose,
  template,
}: TemplateAdd) {
  const [reqPlaceHolder, resPlaceholder] = usePlaceHoldersMutation();
  const [reqGroups, resGroups] = useGroupsMutation();
  const [reqUpdateTemplate, resUpdateTemplate] = useUpdateTemplateMutation();
  const [reqListTemplate] = useListTemplateMutation();
  const [reqUser, resUser] = useUserListMutation();

  const [groupList, setGroupList] = useState([]);
  const [quilValue, setquilValue] = useState(template?.body || '');
  const [users, setUsers] = useState();

  const { uploaded, uploading } = useSelector((state: any) => state.uploads);
  const { placehodlers } = useSelector((state: any) => state.template);
  const { templatePageBtn } = useSelector((state: any) => state.template);

  const dispatch = useDispatch();
  const onSubmit = async (data: any) => {
    data['attachments'] = uploaded;

    reqUpdateTemplate({ data, id: template?.id });
  };
  const toast = useToast();

  const usersDefault = () => {
    let users = [] as any;
    if (template?.users?.length > 0) {
      template?.users?.map((item: any) => {
        users.push({
          label: [item?.user?.first_name, item?.user?.last_name].join(' '),
          value: item?.user?.id,
        });
      });
    }
    return users;
  };

  const initialValues = {
    title: template?.title,
    remarks: template?.remarks,
    group: {
      value: template?.group?.id,
      label: template?.group?.name,
    },
    subject: template?.subject,
    body: template?.body,
    share: template?.shareable || 'only me',
    users: usersDefault(),
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Subject is required.'),
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      group: Yup.array().min(1, 'Pick at least 1 company').nullable(),
    })
  );

  useEffect(() => {
    if (resUpdateTemplate.isSuccess) {
      onClose();
      reqListTemplate(templatePageBtn);
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
    }
  }, [resUpdateTemplate.isSuccess]);

  useEffect(() => {
    let users: any = [];
    if (resUser.isSuccess) {
      if (resUser.data?.data.length > 0) {
        Promise.all(
          resUser.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setUsers(users);
  }, [resUser.data?.data, resUser.isSuccess]);

  useEffect(() => {
    if (isOpen) {
      const load = async () => {
        await reqPlaceHolder({});
        await reqGroups({});
        await dispatch(cleanUpload({ attachments: [] }));
        await dispatch(cleanUpload({ uploaded: [] }));
        await reqUser({});
      };

      load();
    }
  }, [isOpen]);

  useEffect(() => {
    if (resGroups.isSuccess) {
      const data: any = resGroups.data.data;
      let option: any = [];
      data.map((item: any) => {
        option.push({
          label: item.name,
          value: item.id,
        });
      });
      setGroupList(option);
    }
  }, [resGroups.isSuccess]);

  const placeHolderClick = (holder: string) => {
    navigator.clipboard.writeText(`{{${holder}}}`);
    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => onClose()}
        size="6xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Edit Template
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <Flex gap="32px" mb="34px">
                        <Box w="40%">
                          <Text>Placeholders</Text>
                          <Box
                            width="100%"
                            borderWidth="1px"
                            borderRadius="lg"
                            padding={5}
                          >
                            {resPlaceholder.isLoading ? (
                              <BoxLoading box={20} width="70px" />
                            ) : (
                              <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                                {Object.values(placehodlers).length > 0 &&
                                  Object.values(placehodlers).map(
                                    (category: any, key: any) => {
                                      return (
                                        <Box mb="20px">
                                          <Text
                                            color="gray.700"
                                            mb="5px"
                                            fontSize="12px"
                                          >
                                            {category.category}
                                          </Text>
                                          <Wrap spacing={1}>
                                            {category.subs.map(
                                              (item: any, i: number) => {
                                                return (
                                                  <WrapItem>
                                                    <Button
                                                      leftIcon={<FaRegCopy />}
                                                      onClick={() =>
                                                        placeHolderClick(item)
                                                      }
                                                      sx={{
                                                        height: 'fit-content',
                                                        p: '5px 10px',
                                                        fontSize: '10px',
                                                      }}
                                                    >
                                                      {item}
                                                    </Button>
                                                  </WrapItem>
                                                );
                                              }
                                            )}
                                          </Wrap>
                                        </Box>
                                      );
                                    }
                                  )}
                              </Grid>
                            )}
                          </Box>
                        </Box>
                        <Box w="60%">
                          <Flex mb="20px" gap="32px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.group && touched.group
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Template Group
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <AtsSelect
                                id="group"
                                name="group"
                                defaultValue={values.group}
                                placeholder="Template Group"
                                onChange={(event: any) => {
                                  setFieldValue('group', event);
                                }}
                                // onInputChange={() => {}}
                                isLoading={resGroups.isLoading}
                                options={groupList}
                              />
                              <FormErrorMessage>
                                {String(errors.group)}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.title && touched.title
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Template Name
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Input
                                name="title"
                                type="text"
                                placeholder="Template Name"
                                variant="outline"
                                value={values.title}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.title)}
                              </FormErrorMessage>
                            </FormControl>
                          </Flex>
                          <Box mb="20px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.subject && touched.subject
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Subject
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Input
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                variant="outline"
                                value={values.subject}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.subject)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="20px">
                            <FormControl
                              isInvalid={Boolean(!!errors.body && touched.body)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Body
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Box
                                sx={{
                                  '& .ql-editor': {
                                    minHeight: '40vh !important',
                                  },
                                }}
                              >
                                {/* <ReactQuill
                                  theme="snow"
                                  value={quilValue}
                                  onChange={(e) => {
                                    setquilValue(e);
                                    setFieldValue('body', e);
                                  }}
                                  placeholder="Type what ever you need here..."
                                /> */}
                                <AtsEmailBody
                                  value={quilValue}
                                  onChange={(e: any) => {
                                    setquilValue(e);
                                    setFieldValue('body', e);
                                  }}
                                  height={200}
                                />
                              </Box>
                              <FormErrorMessage>
                                {String(errors.body)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="20px">
                            <TemplateAttachments template_id={template?.id} />
                          </Box>
                          <Box mb="20px">
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Attachment(s)
                            </FormLabel>
                            <AtsDropZone
                              multipleFile={true}
                              uploadedEnd={(e: any) => {}}
                              deletedFile={(key: string) => {}}
                            />
                          </Box>

                          <Box
                            pt="32px"
                            pb="15px"
                            color="default.primarytext"
                            fontSize="16px"
                            fontWeight="600"
                            lineHeight="18px"
                          >
                            Share this with:
                          </Box>

                          <RadioGroup defaultValue={values.share}>
                            <Stack spacing={2} direction="column">
                              <Radio
                                colorScheme="purple"
                                name="share"
                                onChange={handleChange}
                                value="only me"
                              >
                                Only me
                              </Radio>
                              <Radio
                                colorScheme="purple"
                                name="share"
                                onChange={handleChange}
                                value="everyone"
                              >
                                Everyone
                              </Radio>
                              <Flex gap="16px">
                                <Radio
                                  colorScheme="purple"
                                  name="share"
                                  onChange={handleChange}
                                  value="selected users"
                                >
                                  Selected User
                                </Radio>

                                {values.share === 'selected users' && (
                                  <FormControl width="300px">
                                    <Field
                                      name="users"
                                      id="users"
                                      options={users}
                                      component={AtsSelect}
                                      defaultValue={usersDefault()}
                                      onChange={(e: any) => {
                                        setFieldValue('users', e);
                                      }}
                                      isMulti
                                    />
                                    <FormErrorMessage>
                                      {String(errors.users)}
                                    </FormErrorMessage>
                                  </FormControl>
                                )}
                              </Flex>
                            </Stack>
                          </RadioGroup>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      type="submit"
                      disabled={uploading}
                      isLoading={resUpdateTemplate.isLoading}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

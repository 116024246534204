import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import LeadsActivityLog from './ActivityLog';
import LeadsAgreements from './Agreement';
import LeadsAttachments from './Attachments';
import LeadsDetails from './Details';
import LeadsJobs from './Jobs';
import LeadsNotes from './Notes';

import { getLead } from 'store/leads.slice';

const LeadsTabs = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const { leads, leadData } = useSelector((state: any) => state.leads);

  const tabIndex: any = {
    details: 0,
    jobs: 1,
    agreements: 2,
    attachments: 3,
    'activity-log': 4,
    notes: 5,
  };

  const [activeTab, setActiveTab] = useState(params.leadsTab);

  const stateTab: any = params.leadsTab;
  const leadID = params.leadsId;

  useEffect(() => {
    if (leadID) {
      const getLeadData = leads.filter(
        (lead: any) => lead.id === Number(leadID)
      )[0];
      dispatch(getLead({ leadData: getLeadData }));
    }
  }, [dispatch, leadID, leads]);

  const handleRoute = (route: string) => {
    navigate(`/leads/${leadData.id}/${route}`);
    setActiveTab(route);
  };

  return (
    <Tabs colorScheme="purple" defaultIndex={tabIndex[stateTab]}>
      <TabList fontSize="md" fontWeight="bold">
        <Tab
          onClick={() => handleRoute('details')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Details
        </Tab>
        <Tab
          onClick={() => handleRoute('jobs')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Jobs
        </Tab>
        <Tab
          onClick={() => handleRoute('agreements')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Agreements
        </Tab>
        <Tab
          onClick={() => handleRoute('attachments')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Attachments
        </Tab>
        <Tab
          onClick={() => handleRoute('activity-log')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Activity Log
        </Tab>
        <Tab
          onClick={() => handleRoute('notes')}
          _focus={{ boxShadow: 'none !important' }}
        >
          Notes
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>{activeTab === 'details' && <LeadsDetails />}</TabPanel>
        <TabPanel>{activeTab === 'jobs' && <LeadsJobs />}</TabPanel>
        <TabPanel>{activeTab === 'agreements' && <LeadsAgreements />}</TabPanel>
        <TabPanel>
          {activeTab === 'attachments' && <LeadsAttachments />}
        </TabPanel>
        <TabPanel>
          {activeTab === 'activity-log' && <LeadsActivityLog />}
        </TabPanel>
        <TabPanel>{activeTab === 'notes' && <LeadsNotes />}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default LeadsTabs;

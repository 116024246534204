import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  ModalFooter,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import Select from 'react-select';
import AtsDropZone from 'components/app/Dropzone';
import AtsEmailBody from 'components/app/EmailBody';
import ScrollToFieldError from 'components/app/ScrollError';
import { Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRawListTemplateMutation } from 'store/template.slice';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import { ConfirmationPreviewInt, fileUploadedInt } from 'types';
import { BsEyeFill } from 'react-icons/bs';
import PreviewConfirmation from './previews/Confirmation';
import NotesModal from '../../Modals/Notes';

interface initialvaluesInt {
  to: string;
  cc: any;
  subject: string;
  body: string;
  attachment: any;
  auto_interview: boolean;
  template: any;
}

interface ConfirmationInterview {
  initialvalues: initialvaluesInt;
  onSubmit: (e: any) => void;
  onPrev: (data: any) => void;
  placeholders: (body: any) => string | null;
  panelOptions: any;
  loading: boolean;
  userOptions: any;
}

const InterviewConfirmation = ({
  initialvalues,
  onSubmit,
  onPrev,
  placeholders,
  panelOptions,
  loading,
  userOptions,
}: ConfirmationInterview) => {
  const [quilNotes, setquilNotes] = useState(initialvalues.body);
  const [template, setTemplate] = useState([]);
  const [reqList, resList] = useRawListTemplateMutation();
  const { placehoderPair } = useSelector((state: any) => state.template);
  const [attachments, setAttachments] = useState(initialvalues.attachment);
  const [panelOption, setPanelOptions] = useState<any>(panelOptions);
  useEffect(() => {
    const getData = async () => {
      await reqList({ id: 1 });
    };
    getData();
  }, [initialvalues]);

  useEffect(() => {
    if (resList.isSuccess) {
      if (resList?.data?.data?.length > 0) {
        let option: any = [];
        resList.data.data.map((item: any) => {
          option.push({
            value: item.id,
            label: item.title,
            subject: item.subject,
            body: item.body,
            attachments: item.attachments,
          });
        });
        setTemplate(option);
      }
    }
  }, [resList.isSuccess]);

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      to: Yup.string().when('auto_interview', {
        is: false,
        then: Yup.string(),
        otherwise: Yup.string().required('To is required'),
      }),
      cc: Yup.array().of(
        Yup.object().shape({
          value: Yup.mixed().test(
            'is-string-or-number',
            'Must be a string or a number',
            (value) => {
              return typeof value === 'string' || typeof value === 'number';
            }
          ),
          label: Yup.string().required('Label is required'),
        })
      ),
      body: Yup.string().when('auto_interview', {
        is: false,
        then: Yup.string(),
        otherwise: Yup.string().required('Body is required'),
      }),
      subject: Yup.string().when('auto_interview', {
        is: false,
        then: Yup.string(),
        otherwise: Yup.string().required('Subject is required'),
      }),
      auto_interview: Yup.boolean(),
    })
  );

  const uploadedEnd = async (data: any) => {
    setAttachments([...attachments, data]);
  };

  const deleteFile = (key: string) => {
    const items = attachments.filter(
      (file: fileUploadedInt) => file.id !== key
    );
    setAttachments(items);
  };

  const ConfirmSubmit = (data: any) => {
    const param = {
      ...data,
      attachment: attachments,
    };
    onSubmit(param);
  };

  const ClickBack = (values: any) => {
    const data = {
      ...values,
      attachment: attachments,
    };
    onPrev(data);
    // console.log('values', values);
  };

  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreview,
  } = useDisclosure();
  const {
    isOpen: isOpenNotes,
    onClose: onCloseNotes,
    onOpen: onOpenNotes,
  } = useDisclosure();

  const PanelMembers = (members: any) => {
    let panels = [] as string[];
    if (members.length > 0) {
      members.map((item: any) => {
        panels.push(item.label.toString());
      });
    }

    return panels;
  };

  const [PrevParam, setPrevParam] = useState<ConfirmationPreviewInt>();

  const prevAttachments = () => {
    let att = [] as string[];
    if (attachments.length > 0) {
      attachments.map((item: any) => {
        att.push(item.original_file_name);
      });
    }
    return att;
  };

  const PreviewClick = (values: any) => {
    const param = {
      body: placeholders(values.body),
      to: values.to,
      cc: PanelMembers(values.cc),
      subject: placeholders(values.subject),
      attachments: prevAttachments(),
    } as ConfirmationPreviewInt;

    setPrevParam(param);
    onOpenPreview();
  };

  const onCCInputChange = (e: any) => {
    if (e.length > 2) {
      setPanelOptions(userOptions);
    } else {
      setPanelOptions(panelOptions);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialvalues}
        validationSchema={validationSchema}
        onSubmit={ConfirmSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <ScrollToFieldError />
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem p="32px" width="100%">
                <Checkbox
                  defaultChecked={values.auto_interview}
                  onChange={handleChange}
                  name="auto_interview"
                  mb="24px"
                >
                  Send Auto-Interview Confirmation to the candidate
                </Checkbox>
                <FormControl
                  mb="24px"
                  mt="20px"
                  isInvalid={Boolean(!!errors.to && touched.to)}
                >
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    To{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="to"
                    name="to"
                    type="text"
                    placeholder="To"
                    variant="outline"
                    value={values.to}
                    onChange={handleChange}
                    disabled={!values.auto_interview}
                  />
                  <FormErrorMessage>{String(errors.to)}</FormErrorMessage>
                </FormControl>
                <FormControl mb="24px">
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    CC
                  </FormLabel>
                  <AtsCraetableSelect
                    id="cc"
                    name="cc"
                    onChange={(event: any) => setFieldValue('cc', event)}
                    options={panelOption}
                    isMulti={true}
                    placeholder="cc"
                    defaultValue={values.cc}
                    isDisabled={!values.auto_interview}
                    onInputChange={onCCInputChange}
                  />
                  <FormErrorMessage>{String(errors.cc)}</FormErrorMessage>
                </FormControl>
              </GridItem>
              <GridItem p="32px" width="100%">
                <Text as="b">Edit Confirmation Email</Text>
                <FormControl mb="24px">
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Template
                  </FormLabel>
                  <Select
                    placeholder="Select"
                    isMulti={false}
                    options={template}
                    defaultValue={values.template}
                    onChange={(e: any) => {
                      setquilNotes(e.body);
                      setFieldValue('body', e.body);
                      setFieldValue('subject', e.subject);
                      setFieldValue('template', e);
                    }}
                    isDisabled={!values.auto_interview}
                  />
                </FormControl>
                <FormControl
                  mb="24px"
                  mt="20px"
                  isInvalid={Boolean(!!errors.subject && touched.subject)}
                >
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Subject{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="subject"
                    name="subject"
                    type="text"
                    placeholder="Subject"
                    variant="outline"
                    value={values.subject}
                    onChange={handleChange}
                    disabled={!values.auto_interview}
                  />
                  <FormErrorMessage>{String(errors.subject)}</FormErrorMessage>
                </FormControl>

                <Box mb="24px">
                  <FormControl
                    isInvalid={Boolean(!!errors.body && touched.body)}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Body{' '}
                      <Box as="span" color="caution.800">
                        *
                      </Box>
                    </FormLabel>
                    <AtsEmailBody
                      id="body"
                      value={quilNotes}
                      onChange={(e: any) => {
                        setquilNotes(e);
                        setFieldValue('body', e);
                      }}
                      height={250}
                      disabled={!values.auto_interview}
                    />
                    <FormErrorMessage>{String(errors.body)}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box mb="24px">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Attachments{' '}
                      <Box as="span" color="caution.800">
                        *
                      </Box>
                    </FormLabel>
                    <AtsDropZone
                      multipleFile
                      uploadedEnd={(e: any) => uploadedEnd(e)}
                      reference={attachments}
                      hasRef={true}
                      deletedFile={(key: string) => deleteFile(key)}
                      disabled={!values.auto_interview}
                    />
                  </FormControl>
                </Box>
              </GridItem>
            </Grid>
            <ModalFooter
              position="sticky"
              bottom="0"
              background="default.white.100"
              boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
              gap={5}
            >
              <Flex width="100%" justifyContent="space-between">
                <Button
                  onClick={onOpenNotes}
                  left="0px"
                  variant="ghost"
                  color="primary.800"
                >
                  Notes
                </Button>
                <Flex gap={5}>
                  <Button
                    variant="ghost"
                    type="button"
                    onClick={() => ClickBack(values)}
                    disabled={loading}
                  >
                    Back
                  </Button>
                  <Button
                    variant="outline"
                    type="button"
                    leftIcon={<BsEyeFill />}
                    disabled={loading || !values.auto_interview}
                    onClick={() => PreviewClick(values)}
                  >
                    Preview
                  </Button>
                  <Button
                    variant="solid"
                    type="submit"
                    isLoading={loading}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Flex>
              </Flex>
            </ModalFooter>
          </form>
        )}
      </Formik>
      {isOpenPreview && (
        <PreviewConfirmation
          isOpen={isOpenPreview}
          onClose={onClosePreview}
          param={PrevParam}
        />
      )}
      {isOpenNotes && (
        <NotesModal
          isOpen={isOpenNotes}
          onClose={onCloseNotes} />
      )}
    </>
  );
};

export default InterviewConfirmation;

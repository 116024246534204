import CandidatesDrawerForm from './DrawerForm';
import CandidatesPersonalInformation from './Information';

interface CandidateAboutProps {
  isEdit: boolean;
  setIsEdit: any;
}

const CandidatesAbout = ({ isEdit, setIsEdit }: CandidateAboutProps) => {
  return isEdit ? (
    <CandidatesDrawerForm setIsEdit={setIsEdit} />
  ) : (
    <CandidatesPersonalInformation />
  );
};

export default CandidatesAbout;

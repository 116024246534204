/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BarChart } from '@mui/x-charts';

import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material';

import BG from 'assets/images/bg.svg';
import BG1 from 'assets/images/bg1.svg';

import MdAccount from 'assets/images/MdAccount.gif';
import MdCall from 'assets/images/MdCall.gif';
import MdInbox from 'assets/images/MdInbox.gif';
import MdAssignment from 'assets/images/MdAssignment.gif';

import MdCall1 from 'assets/images/MdCall1.svg';
import MdSend from 'assets/images/MdSend.svg';
import MdCheck from 'assets/images/MdCheck.svg';
import MdFace from 'assets/images/MdFace.svg';
import MdOffer from 'assets/images/MdOffer.svg';

import MdUp from 'assets/images/MdUp.svg';
import MdDown from 'assets/images/MdDown.svg';
import Button from 'Library/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  useActivityListMutation,
  useGetDealsMutation,
  useGetKanbanMutation,
  useGetWidgetsMutation,
} from 'store/dashboard.slice';

import { formatDigits } from 'utils/utilFormat';
import { byActivity, byMonth, chartMonths, tableHeader } from './constants';

const RecruiterDashboard = () => {
  const MuiTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const { first_name } = useSelector((state: any) => state.auth);
  const { activities, deals, widgets, kanban } = useSelector(
    (state: any) => state.dashboard
  );

  const [reqDeals] = useGetDealsMutation();
  const [reqWidgets] = useGetWidgetsMutation();
  const [reqKanban] = useGetKanbanMutation();
  const [reqActivity] = useActivityListMutation();

  const [selectByMonth, setByMonth] = useState(0);
  const [selectByActivity, setByActivity] = useState('current month');
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    reqDeals({});
    reqWidgets({});
    reqKanban({});
  }, []);

  useEffect(() => {
    reqActivity({
      data: { target: selectByActivity, recruiters: null },
    });
  }, [selectByActivity]);

  useEffect(() => {
    const currentHour = moment().hour();

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      setGreeting('Good afternoon');
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      setGreeting('Good evening');
    } else {
      // Between dawn and noon
      setGreeting('Good morning');
    }
  }, []);

  return (
    <Box
      sx={{
        background: '#F5F5F5',
        backgroundImage: BG,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        fontFamily: 'Proxima Nova Regular',
        p: '24px',
      }}
    >
      <Box
        sx={{
          fontSize: '24px',
          lineHeight: '29.23px',
          fontWeight: 700,
          mb: '24px',
          color: '#fff',
        }}
      >
        {greeting}, {first_name}!
      </Box>

      <Flex sx={{ gap: '16px', mb: '16px' }}>
        <Flex
          sx={{
            borderRadius: '8px',
            background: '#fff',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Box
              sx={{
                fontSize: '48px',
                lineHeight: '58.46px',
                fontWeight: 400,
                color: '#322659',
              }}
            >
              {widgets.leads}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17.05px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Leads
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '10px',
            }}
          >
            <Image sx={{ height: '50px' }} src={MdAccount} />
          </Box>
        </Flex>
        <Flex
          sx={{
            borderRadius: '8px',
            background: '#fff',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Box
              sx={{
                fontSize: '48px',
                lineHeight: '58.46px',
                fontWeight: 400,
                color: '#322659',
              }}
            >
              {widgets.outbound_calls}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17.05px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Outbound Calls
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '10px',
            }}
          >
            <Image sx={{ height: '50px' }} src={MdCall} />
          </Box>
        </Flex>
        <Flex
          sx={{
            borderRadius: '8px',
            background: '#fff',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Box
              sx={{
                fontSize: '48px',
                lineHeight: '58.46px',
                fontWeight: 400,
                color: '#322659',
              }}
            >
              {widgets.send_emails}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17.05px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Sent Emails
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '10px',
            }}
          >
            <Image sx={{ height: '50px' }} src={MdInbox} />
          </Box>
        </Flex>
        <Flex
          sx={{
            borderRadius: '8px',
            background: '#fff',
            p: '16px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>
            <Box
              sx={{
                fontSize: '48px',
                lineHeight: '58.46px',
                fontWeight: 400,
                color: '#322659',
              }}
            >
              {widgets.tasks}
            </Box>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17.05px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Tasks
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '10px',
            }}
          >
            <Image sx={{ height: '50px' }} src={MdAssignment} />
          </Box>
        </Flex>
      </Flex>

      <Flex sx={{ gap: '16px', mb: '16px' }}>
        <Flex
          sx={{
            width: '20%',
            flexDir: ' column',
            borderRadius: '8px',
            background: '#fff',
            backgroundImage: BG1,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            p: '8px 16px',
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
              boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
            }}
          >
            Deals Closed
          </Box>
          <Box
            sx={{
              fontSize: '50px',
              lineHeight: '73.08px',
              fontWeight: 700,
              color: 'primary.500',
              textAlign: 'center',
              py: '88px',
            }}
          >
            {formatDigits(deals?.closed?.total_revenue)}
          </Box>
        </Flex>
        <Box
          sx={{
            width: '80%',
            borderRadius: '8px',
            background: '#fff',
            p: '8px 16px',
            boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Flex sx={{ gap: '10px', alignItems: 'center' }}>
            <Box
              sx={{
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Deals
            </Box>

            <Flex width="175px">
              <Menu closeOnSelect={true}>
                <MenuButton type="button">
                  <Box
                    sx={{
                      button: {
                        p: '4px 8px',
                        pl: 0,
                        height: '33px',
                        fontSize: '14px',
                        border: 'none',
                        color: 'primary.500',
                      },
                    }}
                  >
                    <Button rightIcon="chevron-down" type="button">
                      {
                        byMonth.filter(
                          (val: any) => val.value === selectByMonth
                        )[0].label
                      }
                    </Button>
                  </Box>
                </MenuButton>
                {
                  <MenuList fontSize="sm" maxHeight="200px" overflowY="auto">
                    {byMonth.map((val: any) => {
                      return (
                        <MenuItem
                          type="button"
                          onClick={() => setByMonth(val.value)}
                        >
                          {val.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                }
              </Menu>
            </Flex>
          </Flex>
          <Flex sx={{ gap: '30px' }}>
            <Box>
              <Flex sx={{ py: '24px' }}>
                <Box
                  sx={{
                    height: 'fit-content',
                    border: '1px solid #E2E8F0',
                    borderRadius: '8px',
                    p: '16px',
                  }}
                >
                  <Box
                    as="span"
                    sx={{
                      fontSize: '32px',
                      lineHeight: '38.98px',
                      fontWeight: 400,
                      color: '#171923',
                      mb: '20px',
                    }}
                  >
                    {/* $
                    <Box
                      as="span"
                      sx={{
                        fontSize: '40px',
                        lineHeight: '48.72px',
                        fontWeight: 400,
                        color: '#171923',
                      }}
                    >
                      100
                    </Box>
                    K */}
                    {formatDigits(deals?.current_month?.total_revenue)}
                  </Box>
                  <Flex sx={{ gap: '12px', minWidth: '120px' }}>
                    <Box
                      sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 700,
                        color: '#718096',
                      }}
                    >
                      Current Month
                    </Box>
                    <Image src={MdUp} alt="up" />
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Box>
              <ThemeProvider theme={MuiTheme}>
                <BarChart
                  xAxis={[
                    {
                      id: 'barCategories',
                      data: chartMonths,
                      scaleType: 'band',
                    },
                  ]}
                  series={[
                    {
                      data:
                        deals?.chart?.length > 0
                          ? deals?.chart?.map((val: any) => val.total_revenue)
                          : [0],
                      color: '#805AD5',
                    },
                  ]}
                  width={850}
                  height={250}
                  yAxis={[
                    {
                      id: 'valueAxis',
                      position: 'left',
                      valueFormatter: (value) => formatDigits(value),
                    },
                  ]}
                />
              </ThemeProvider>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Box
        sx={{
          borderRadius: '8px',
          background: '#fff',
          mb: '16px',
          p: '8px 16px',
        }}
      >
        <Flex sx={{ gap: '10px', alignItems: 'center' }}>
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
            }}
          >
            Activities
          </Box>
          <Flex width="175px">
            <Menu closeOnSelect={true}>
              <MenuButton type="button">
                <Box
                  sx={{
                    button: {
                      p: '4px 8px',
                      pl: 0,
                      height: '33px',
                      fontSize: '14px',
                      border: 'none',
                      color: 'primary.500',
                    },
                  }}
                >
                  <Button rightIcon="chevron-down" type="button">
                    {
                      byActivity.filter(
                        (val: any) => val.label === selectByActivity
                      )[0].label
                    }
                  </Button>
                </Box>
              </MenuButton>
              {
                <MenuList fontSize="sm" maxHeight="200px" overflowY="auto">
                  {byActivity.map((val: any) => {
                    return (
                      <MenuItem
                        type="button"
                        onClick={() => setByActivity(val.label)}
                      >
                        {val.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              }
            </Menu>
          </Flex>
        </Flex>

        <Box sx={{ my: '24px' }}>
          <TableContainer
            boxSizing="border-box"
            border="1px solid #EEEEEE"
            borderRadius="4px"
          >
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr bg="common.white">
                  {tableHeader.map((title) => (
                    <Th key={title.label}>
                      <Box
                        sx={{
                          fontSize: '10px',
                          lineHeight: '20px',
                          fontWeight: 400,
                          color: '#322659',
                        }}
                      >
                        {title.label}
                      </Box>
                    </Th>
                  ))}
                </Tr>
              </Thead>

              <Tbody
                boxSizing="border-box"
                background="default.white.100"
                borderBottom="1px solid"
                borderColor="default.white.400"
              >
                {activities?.map((item: any) => {
                  return (
                    <Tr
                      cursor="pointer"
                      key={`activity-logs-${item.label}`}
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        fontWeight: 700,
                      }}
                    >
                      <Td sx={{ bg: '#DEE2E6 !important' }}>
                        Week {item.label}
                      </Td>
                      <Td>
                        {Number(item.new_client) !== 0 ? item.new_client : '-'}
                      </Td>
                      <Td>
                        {Number(item.new_client_interview) !== 0
                          ? item.new_client_interview
                          : '-'}
                      </Td>
                      <Td>
                        {Number(item.job_posted) !== 0 ? item.job_posted : '-'}
                      </Td>
                      <Td>{Number(item.submits) !== 0 ? item.submits : '-'}</Td>
                      <Td>
                        {Number(item.interviews) !== 0 ? item.interviews : '-'}
                      </Td>
                      <Td>{Number(item.offers) !== 0 ? item.offers : '-'}</Td>
                      <Td>
                        {Number(item.placements) !== 0 ? item.placements : '-'}
                      </Td>
                      <Td>
                        {Number(item.outbound_calls) !== 0
                          ? item.outbound_calls
                          : '-'}
                      </Td>
                      <Td
                        sx={{ color: Number(item.revenue) !== 0 && '#D69E2E' }}
                      >
                        {Number(item.revenue) !== 0
                          ? formatDigits(item.revenue)
                          : '-'}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Box sx={{ borderRadius: '8px', background: '#fff', p: '8px 16px' }}>
        <Box
          sx={{
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 700,
            color: '#718096',
            mb: '8px',
          }}
        >
          Deals and Pipelines
        </Box>

        <Flex
          sx={{
            gap: '16px',
            mt: '24px',
            mb: '16px',
            alignItems: 'flex-start',
          }}
        >
          <Box
            sx={{
              height: 'fit-content',
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              p: '16px',
            }}
          >
            <Box
              as="span"
              sx={{
                fontSize: '40px',
                lineHeight: '48.72px',
                fontWeight: 400,
                color: '#171923',
                width: '200px',
              }}
            >
              {formatDigits(kanban?.total)}
            </Box>
            <Flex sx={{ justifyContent: 'space-between', minWidth: '170px' }}>
              <Box
                sx={{
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: 700,
                  color: '#718096',
                }}
              >
                Total
              </Box>
              <Image src={MdDown} alt="down" />
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#CBD5E0',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#322659' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.exploration?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.exploration?.total} Exploration calls
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdCall1} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.exploration?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#3182CE',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#fff' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.roles_qualified?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.roles_qualified?.total} Role(s) qualified
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdCheck} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.roles_qualified?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Close Date: {val.date}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#805AD5',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#fff' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.submitted?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.submitted?.total} candidates submitted
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdSend} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.submitted?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Close Date: {val.date}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#D69E2E',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#fff' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.interview?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.interview?.total} interview scheduled
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdFace} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.interview?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Close Date: {val.date}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Flex
              sx={{
                bg: '#38A169',
                borderRadius: '8px',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: '18px 8px',
                width: '100%',
              }}
            >
              <Box sx={{ color: '#fff' }}>
                <Box
                  sx={{
                    fontSize: '24px',
                    lineHeight: '20px',
                    fontWeight: 400,
                    mb: '4px',
                  }}
                >
                  {formatDigits(kanban?.offer?.amount)}
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    lineHeight: '20px',
                    fontWeight: 700,
                  }}
                >
                  {kanban?.offer?.total} offer
                </Box>
              </Box>
              <Box
                sx={{
                  p: '10px',
                }}
              >
                <Image src={MdOffer} />
              </Box>
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                p: '8px',
                color: '#322659',
                gap: '10px',
              }}
            >
              {kanban?.offer?.data?.map((val: any) => {
                return (
                  <Box
                    sx={{
                      border: '1px solid #E2E8F0',
                      borderRadius: '8px',
                      p: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17.05px',
                        fontWeight: 700,
                        mb: '8px',
                      }}
                    >
                      {val.title}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Amount: {formatDigits(val.amount)}
                    </Box>
                    <Box
                      sx={{
                        fontSize: '10px',
                        lineHeight: '12.18px',
                        fontWeight: 400,
                      }}
                    >
                      Close Date: {val.date}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default RecruiterDashboard;

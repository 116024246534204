import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
} from '@chakra-ui/react';

import { cleanUpload, uploadList } from 'store/uploads.slice';
import { useGetLeadsMutation } from 'store/leads.slice';
import {
  useCreateAttachmentMutation,
  useListLeadAttachmentMutation,
} from 'store/leadattachment.slice';
import AtsDropZone from '../../Dropzone';
import { changeUploadName } from 'utils/newFilename';
import ScrollToFieldError from 'components/app/ScrollError';

interface LeadsAttachmentModalProps {
  isOpen: any;
  onClose: any;
}

export default function LeadsAddAttachmentModal({
  isOpen,
  onClose,
}: LeadsAttachmentModalProps) {
  const dispatch = useDispatch();
  const { leadData } = useSelector((state: any) => state.leads);
  const { uploaded, prefix, uploading } = useSelector(
    (state: any) => state.uploads
  );
  const { LAPgBtn } = useSelector((state: any) => state.leadattachment);
  const [reqcreate, rescreate] = useCreateAttachmentMutation();
  const [reqList] = useListLeadAttachmentMutation();
  const [reqleads] = useGetLeadsMutation();
  const [prefixStr, setPrefix] = useState(prefix);

  const submitAgreement = async (data: any) => {
    console.log('uploaded', uploaded);
    data['attachments'] = await changeUploadName(uploaded, prefix);
    await reqcreate({ data, id: leadData?.id });
  };

  const initialValues = {
    prefix: 'Attachments',
  };

  useEffect(() => {
    dispatch(cleanUpload({ withPrefix: true }));
    dispatch(uploadList({ prefix: 'Attachment' }));
    if (rescreate.isSuccess) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      reqleads({ id: leadData?.id });
      reqList({ data: LAPgBtn, id: leadData?.id });
      onClose();
    }
  }, [
    LAPgBtn,
    dispatch,
    leadData?.id,
    onClose,
    reqList,
    reqleads,
    rescreate.isSuccess,
  ]);
  const changePrefix = async (e: any) => {
    let prefix = e.target.value;
    setPrefix(prefix);
    dispatch(uploadList({ prefix: prefix }));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Add Attachments
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik initialValues={initialValues} onSubmit={submitAgreement}>
              {({ values, handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          File Name
                        </FormLabel>
                        <Input
                          name="prefix"
                          type="text"
                          placeholder="File Name"
                          variant="outline"
                          value={prefixStr}
                          onChange={changePrefix}
                        />
                      </FormControl>
                    </Box>
                    <AtsDropZone
                      multipleFile={true}
                      uploadedEnd={(e: any) => {}}
                      deletedFile={(key: string) => {}}
                    />
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button variant="solid" type="submit" disabled={uploading}>
                      Add
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import MyClientsTable from './MyClients/Table';
import AllClientsTable from './AllClients/Table';
import ContactsClientsTable from './Contacts/Table';
import { useDispatch, useSelector } from 'react-redux';
import { navClient } from 'store/client.slice';

export default function ClientsPage() {
  const dispatch = useDispatch();

  document.documentElement.dir = 'ltr';

  const tabIndex: any = {
    'my-clients': 0,
    'all-clients': 1,
    contacts: 2,
  };

  const { clientNav } = useSelector((state: any) => state.clients);

  return (
    <Box>
      <Tabs colorScheme="purple" defaultIndex={tabIndex[clientNav]}>
        <TabList fontSize="md" fontWeight="bold" mx={10}>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => {
              dispatch(navClient({ clientNav: 'my-clients' }));
              dispatch(navClient({ clientTab: 'my-clients' }));
            }}
          >
            My Clients
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => {
              dispatch(navClient({ clientNav: 'all-clients' }));
              dispatch(navClient({ clientTab: 'all-clients' }));
            }}
          >
            All Clients
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => {
              dispatch(navClient({ clientNav: 'contacts' }));
              dispatch(navClient({ clientTab: 'contacts' }));
            }}
          >
            Contacts
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <MyClientsTable />
          </TabPanel>
          <TabPanel>
            <AllClientsTable />
          </TabPanel>
          <TabPanel>
            <ContactsClientsTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

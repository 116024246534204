import { Formik } from 'formik';
import { BsEnvelope } from 'react-icons/bs';

import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Flex,
  Alert,
  AlertTitle,
  AlertIcon,
  AlertDescription,
  Link,
  CloseButton,
} from '@chakra-ui/react';

import Select from 'react-select';
import * as Yup from 'yup';
import ScrollToFieldError from 'components/app/ScrollError';
import { useEffect, useState } from 'react';
import AtsDropZone from 'components/app/Dropzone';
import { useDispatch, useSelector } from 'react-redux';
import AtsEmailBody from 'components/app/EmailBody';
import { useParams } from 'react-router';
import {
  setPairTemplate,
  useRawListTemplateMutation,
} from 'store/template.slice';
import {
  useBulkSendEmailCandidateMutation,
  useSendEmailCandidateMutation,
} from 'store/candidates.slice';
import { cleanUpload } from 'store/uploads.slice';
import bulkReplacePlaceholders from 'utils/bulkTextPlaceholders';
import replacePlaceholders from 'utils/textPlaceholders';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import escapeRegExp from 'utils/escapeRegExp';
import { CAREER_PAGE_URL } from 'constants/values';

interface SendEmailModalProps {
  isOpen: any;
  onClose: any;
  id: any;
  email: any;
  idList?: number[];
  emailList?: string[];
  isBulkEmail?: boolean;
}

interface CandidatePairingIntParam {
  candidate_full_name: string;
  candidate_first_name: string;
  candidate_email: string;
  candidate_current_position: string;
  candidate_current_company: string;
  candidate_mobile: string;
  candidate_linkedin_url: string;
  candidate_work_status: string;
  candidate_city: string;
  candidate_state: string;
  candidate_degree: string;
  candidate_total_years_of_exp: string;
  job_url: string;
  job_name_url: string;
  job_title: string;
  job_location: string;
  job_contact_name: string;
  job_contact_first_name: string;
  job_company_name: string;
  job_primary_recruiter_name: string;
  job_ad_description: string;
  job_contact_title: string;
  internal_job_description: string;
}

export default function SendEmailModal({
  isOpen,
  onClose,
  id,
  email,
  idList,
  emailList,
  isBulkEmail,
}: SendEmailModalProps) {
  const initialValues = {
    subject: '',
    body: '',
    to: isBulkEmail ? emailList?.join('; ') : email,
    cc: [] as any,
    bcc: [] as any,
  };

  const [quilValue, setquilValue] = useState('');
  const [reqList, resList] = useRawListTemplateMutation();
  const [template, setTemplate] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [reqEmail, resEmail] = useSendEmailCandidateMutation();
  const [reqBulkEmail, resBulkEmail] = useBulkSendEmailCandidateMutation();
  const dispatch = useDispatch();
  const [tokenLink, setTokenLink] = useState('');
  const param = useParams();
  const [ccBcc, setCcBcc] = useState([]);
  const [ccOption, setCcOption] = useState([]);
  const [bccOption, setBccOption] = useState([]);

  const { userList } = useSelector((state: any) => state.user);
  const { placehoderPair } = useSelector((state: any) => state.template);
  const { jobData } = useSelector((state: any) => state.jobs);
  const { candidateData } = useSelector((state: any) => state.candidates);
  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  useEffect(() => {
    const getData = async () => {
      // await reqPair(data);
      await reqList({ id: 1 });
      setTokenLink('');
      await dispatch(cleanUpload({ attachments: [] }));
      await dispatch(cleanUpload({ uploaded: [] }));
    };
    getData();

    let ccOption = [] as any;
    Object.values(userList).map((item: any) => {
      ccOption.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.email,
      });
    });
    setCcBcc(ccOption);
  }, [id]);

  useEffect(() => {
    if (resList.isSuccess) {
      if (resList?.data?.data?.length > 0) {
        let option: any = [];
        resList.data.data.map((item: any) => {
          option.push({
            value: item.id,
            label: item.title,
            subject: item.subject,
            body: item.body,
            attachments: item.attachments,
          });
        });
        setTemplate(option);
      }
    }
  }, [resList.isSuccess]);

  useEffect(() => {
    if (resEmail.isSuccess || resBulkEmail.isSuccess) {
      onClose();
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
    }
  }, [resEmail.isSuccess, resBulkEmail.isSuccess]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (resEmail.isError || resBulkEmail.isError) {
      const error: any = resEmail.error;
      try {
        const message =
          error?.data?.error?.message ||
          error?.data?.data?.message ||
          'Unkown error';

        if (error?.data?.data?.type === 'redirect') {
          setTokenLink(error?.data?.data?.url);
        }
        setErrorMsg(message);
        openAlert();
      } catch (e) {
        console.log('error', e);
      }
    } else {
      setErrorMsg('');
    }
  }, [resEmail.isError, resBulkEmail.isError]);

  const { uploaded, uploading } = useSelector((state: any) => state.uploads);

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      ...(!isBulkEmail && {
        to: Yup.string()
          .email('Invalid email format')
          .required('Email is required.'),
      }),
    })
  );
  const handleSubmit = (data: any) => {
    data['attachments'] = uploaded;
    data['att_template'] = attachments;
    const toRecipients = data.to.split('; ');
    setTokenLink('');
    let cc: any = [];
    if (data.cc.length > 0) {
      data.cc.map((item: any) => {
        cc.push(item.value);
      });
    }
    let bcc: any = [];
    if (data.bcc.length > 0) {
      data.bcc.map((item: any) => {
        bcc.push(item.value);
      });
    }
    const newParam = {
      ...data,
      to: toRecipients,
      bcc: bcc,
      cc: cc,
    };

    if (isBulkEmail) {
      newParam.body = replaceWithPlaceholder(data.body);
      newParam.subject = replaceWithPlaceholder(data.subject);
    }
    if (isBulkEmail) {
      newParam.idList = idList;
      reqBulkEmail({ data: newParam });
    } else {
      reqEmail({ data: newParam, id });
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (candidateData) {
        const candidate_full_name = candidateData?.data
          ? [candidateData?.data?.first_name, candidateData?.data?.last_name]
            .filter(Boolean)
            .join(', ')
          : [candidateData?.first_name, candidateData?.last_name]
            .filter(Boolean)
            .join(', ') || '';
        const candidate_first_name =
          candidateData?.data?.first_name ||
          candidateData?.first_name ||
          placehoderPair?.first_name ||
          '';
        const candidate_email =
          candidateData?.data?.primary_email ||
          candidateData?.primary_email ||
          placehoderPair?.candidate_email ||
          '';
        const candidate_current_position =
          candidateData?.data?.latest_job_title ||
          candidateData?.latest_job_title ||
          placehoderPair?.candidate_current_position ||
          '';
        const candidate_current_company =
          candidateData?.data?.latest_employer ||
          candidateData?.latest_employer ||
          placehoderPair?.candidate_current_company ||
          '';
        const candidate_mobile =
          (placehoderPair?.candidate_mobile) ? String(placehoderPair?.candidate_mobile) :
            String(getPrimaryMobile(candidateData?.data?.contact)) ??
            String(getPrimaryMobile(candidateData?.contact)) ??
            '';
        const candidate_linkedin_url =
          candidateData?.linkedin_url ||
          placehoderPair?.candidate_linkedin_url ||
          '';
        const candidate_work_status =
          candidateData?.work_status?.status ||
          placehoderPair?.candidate_work_status ||
          '';
        const candidate_city =
          candidateData?.city || placehoderPair?.candidate_city || '';
        const candidate_state =
          candidateData?.state_province ||
          placehoderPair?.candidate_state ||
          '';
        let candidate_degree = placehoderPair?.candidate_degree || '';
        if (candidateData?.education?.length > 0) {
          candidate_degree = candidateData?.education?.[0]?.degree_level?.name;
        }
        const candidate_total_years_of_exp =
          candidateData?.total_year_of_experience ||
          placehoderPair?.candidate_total_years_of_exp ||
          '';
        let job_url = '';
        let job_name_url = '';
        let job_title = '';
        let job_location = '';
        let job_contact_name = '';
        let job_contact_first_name = '';
        let job_company_name = '';
        let job_primary_recruiter_name = '';
        let job_ad_description = '';
        let job_contact_title = '';
        let internal_job_description = '';
        if (param?.jobsId) {
          job_title = jobData?.title || placehoderPair?.job_title || '';
          job_location =
            [
              jobData?.data?.location?.city,
              jobData?.data?.location?.state,
              jobData?.data?.location?.country?.country,
            ]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_location ||
            '';
          job_contact_name =
            [jobData?.lead?.first_name, jobData?.lead?.last_name]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_contact_name ||
            '';
          job_contact_first_name =
            jobData?.lead?.first_name ||
            placehoderPair?.job_contact_first_name ||
            '';
          job_company_name =
            jobData?.client?.name || placehoderPair?.job_company_name || '';
          job_primary_recruiter_name =
            [
              jobData?.primary?.recruiter?.first_name,
              jobData?.primary?.recruiter?.last_name,
            ]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_primary_recruiter_name ||
            '';
          job_contact_title =
            jobData?.lead?.title || placehoderPair?.job_contact_title || '';
          internal_job_description =
            jobData?.data?.description ||
            placehoderPair?.internal_job_description ||
            '';
          if (jobData?.jobAds?.length > 0) {
            const ads = jobData?.jobAds[0];
            job_ad_description =
              ads?.description || placehoderPair?.job_ad_description;
            if (ads) {
              const url = `${CAREER_PAGE_URL}/job-details/${ads?.id}`;
              job_url = `<a href="${url}" target="_blank">${url}</a>`;
              job_name_url = `<a href="${url}" target="_blank">${ads.title}</a>`;
            }
          }
        }

        const candidateParam = {
          candidate_full_name,
          candidate_first_name,
          candidate_email,
          candidate_current_position,
          candidate_current_company,
          candidate_mobile,
          candidate_linkedin_url,
          candidate_work_status,
          candidate_city,
          candidate_state,
          candidate_degree,
          candidate_total_years_of_exp,
          job_url,
          job_name_url,
          job_title,
          job_location,
          job_contact_name,
          job_contact_first_name,
          job_company_name,
          job_primary_recruiter_name,
          job_ad_description,
          job_contact_title,
          internal_job_description,
        } as CandidatePairingIntParam;

        candidatePairing(candidateParam);
      }
    }
  }, [isOpen]);

  const getPrimaryMobile = (contact: any) => {
    try {
      let mobile = '';

      contact.map((item: any) => {
        if (item.primary && item.type == 'mobile') {
          mobile = item?.number;
        }
      });

      return mobile;
    } catch (e) {
      return '';
    }
  };

  const candidatePairing = async (param: CandidatePairingIntParam) => {
    const newpair = {
      ...placehoderPair,
      candidate_full_name: param.candidate_full_name,
      candidate_first_name: param.candidate_first_name,
      candidate_email: param.candidate_email,
      candidate_current_position: param.candidate_current_position,
      candidate_current_company: param.candidate_current_company,
      candidate_mobile: param.candidate_mobile,
      candidate_linkedin_url: param.candidate_linkedin_url,
      candidate_work_status: param.candidate_work_status,
      candidate_city: param.candidate_city,
      candidate_state: param.candidate_state,
      candidate_degree: param.candidate_degree,
      candidate_total_years_of_exp: param.candidate_total_years_of_exp,
      job_url: param.job_url,
      job_name_url: param.job_name_url,
      job_title: param.job_title,
      job_location: param.job_location,
      job_contact_name: param.job_contact_name,
      job_contact_first_name: param.job_contact_first_name,
      job_company_name: param.job_company_name,
      job_primary_recruiter_name: param.job_primary_recruiter_name,
      job_ad_description: param.job_ad_description,
      job_contact_title: param.job_contact_title,
      internal_job_description: param.internal_job_description,
    };

    // setPlaceHoders(newpair);
    await dispatch(setPairTemplate({ placehoderPair: newpair }));
  };

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  const placholders = (text: string) => {
    return isBulkEmail
      ? bulkReplacePlaceholders(text, placehoder)
      : replacePlaceholders(text, placehoder);
  };

  const replaceWithPlaceholder = (text: string) => {
    for (const placeholder in placehoder) {
      if (placehoder[placeholder] !== '') {
        const escapedPlaceholder = escapeRegExp(
          String(placehoder[placeholder])
        );
        const regex = new RegExp(escapedPlaceholder, 'g');
        text = text.replace(regex, `{{${placeholder}}}`);
      }
    }
    return text;
  };

  const onCcInputChange = (e: any) => {
    if (e.length > 2) {
      setCcOption(ccBcc);
    } else {
      setCcOption([]);
    }
  };

  const onBccInputchange = (e: any) => {
    if (e.length > 2) {
      setBccOption(ccBcc);
    } else {
      setBccOption([]);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Send Email
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box width="100%">
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errMsg}</AlertDescription>
                  {tokenLink !== '' && (
                    <AlertDescription>
                      <Link href={tokenLink} isExternal>
                        Click to follow this link
                      </Link>
                    </AlertDescription>
                  )}
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <FormLabel
                        fontSize="18px"
                        lineHeight="18px"
                        fontWeight={500}
                        color="default.gray.800"
                      >
                        Client Name
                      </FormLabel>
                      <Box
                        fontSize="14px"
                        lineHeight="18px"
                        fontWeight={500}
                        color="default.borderdark"
                      >
                        {jobData.client?.name}
                      </Box>
                    </Box>
                    <Box mb="34px">
                      <Flex gap="32px" mb="20px">
                        <FormControl
                          isInvalid={Boolean(!!errors.to && touched.to)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            To
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="to"
                            name="to"
                            type={isBulkEmail ? 'text' : 'email'}
                            variant="outline"
                            value={values.to}
                            onChange={handleChange}
                            isDisabled={isBulkEmail}
                          />
                          <FormErrorMessage>
                            {String(errors.to)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(!!errors.cc && touched.cc)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Cc
                          </FormLabel>
                          <AtsCraetableSelect
                            id="cc"
                            name="cc"
                            onChange={(event: any) =>
                              setFieldValue('cc', event)
                            }
                            options={ccOption}
                            isMulti={true}
                            placeholder="cc"
                            defaultValue={values.cc}
                            onInputChange={onCcInputChange}
                          />

                          <FormErrorMessage>
                            {String(errors.cc)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="20px">
                        <FormControl mb="20px">
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Template
                          </FormLabel>
                          <Select
                            placeholder="Select"
                            isMulti={false}
                            options={template}
                            onChange={(e: any) => {
                              setquilValue(placholders(e.body));
                              setFieldValue('body', placholders(e.body));
                              setFieldValue('subject', placholders(e.subject));

                              // setquilValue(e.body);
                              // setFieldValue('body', e.body);
                              // setFieldValue('subject', e.subject);
                              // setAttachments(e.attachments);
                            }}
                          />
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(!!errors.bcc && touched.bcc)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Bcc
                          </FormLabel>
                          <AtsCraetableSelect
                            id="bcc"
                            name="bcc"
                            onChange={(event: any) =>
                              setFieldValue('bcc', event)
                            }
                            options={bccOption}
                            isMulti={true}
                            placeholder="bcc"
                            defaultValue={values.bcc}
                            onInputChange={onBccInputchange}
                          />

                          <FormErrorMessage>
                            {String(errors.bcc)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <FormControl
                        isInvalid={Boolean(!!errors.subject && touched.subject)}
                        mb="20px"
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Subject
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          name="subject"
                          type="text"
                          placeholder="Subject"
                          variant="outline"
                          value={values.subject}
                          onChange={(e: any) => {
                            setFieldValue(
                              'subject',
                              placholders(e.target.value)
                            );
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.subject)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>

                    <Box mb="20px">
                      <FormControl
                        isInvalid={Boolean(!!errors.body && touched.body)}
                      >
                        {/* <ReactQuill
                        id="notes"
                        theme="snow"
                        value={values.notes || quilNotes}
                        onChange={setquilNotes}
                        placeholder="Type whatever you need from here"
                      /> */}
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Body
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsEmailBody
                          initialValue={''}
                          value={quilValue}
                          onChange={(e: any) => {
                            setquilValue(placholders(e));
                            setFieldValue('body', placholders(e));
                          }}
                        />
                        <FormErrorMessage>
                          {String(errors.body)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                    <Box mb="20px">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Attachments{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                      </FormControl>
                      <AtsDropZone
                        multipleFile
                        isAgreement
                        uploadedEnd={(e: any) => { }}
                        deletedFile={(key: string) => { }}
                      />
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      type="submit"
                      disabled={uploading}
                      isLoading={resEmail.isLoading || resBulkEmail.isLoading}
                      leftIcon={<BsEnvelope />}
                    >
                      Send
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import ClientsContactsOverview from './Overview';
import { getContact } from 'store/contact.slice';
import ClientsJobs from '../Jobs';
import ClientsActivityLog from '../ActivityLog';

const ClientsContactsTabs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { contactList, contactData } = useSelector(
    (state: any) => state.contacts
  );

  const tabIndex: any = {
    overview: 0,
    jobs: 1,
    'activity-log': 2,
  };

  const stateTab: any = params.clientsTab;
  const clientID = params.clientsId;

  useEffect(() => {
    if (clientID) {
      const getContactData = contactList.filter(
        (client: any) => client.id === Number(clientID)
      )[0];
      dispatch(getContact({ contactData: getContactData }));
    }
  }, [contactList, clientID, dispatch]);

  const handleRoute = (route: string) => {
    navigate(`/clients/${contactData?.id}/${route}`);
  };

  return (
    <Tabs colorScheme="purple" defaultIndex={tabIndex[stateTab]}>
      <TabList fontSize="md" fontWeight="bold">
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('overview')}
        >
          Overview
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('jobs')}
        >
          Jobs
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('activity-log')}
        >
          Activity Logs
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <ClientsContactsOverview />
        </TabPanel>
        <TabPanel>
          <ClientsJobs />
        </TabPanel>
        <TabPanel>
          <ClientsActivityLog />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ClientsContactsTabs;

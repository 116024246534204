import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Text,
  Grid,
  Wrap,
  Button,
  WrapItem,
} from '@chakra-ui/react';

import FAIcon from 'components/lib/FAIcon';

import LoadingPage from 'components/app/Loading';

import * as Yup from 'yup';

import ScrollToFieldError from 'components/app/ScrollError';
import 'react-quill/dist/quill.snow.css';
import 'assets/css/minHquill.css';
import AtsEmailBody from 'components/app/EmailBody';
import { useCreateInterviewBodyMutation, useUpdateInterviewBodyMutation } from 'store/candidateinterview.slice';
import { usePlaceHoldersMutation } from 'store/template.slice';
import BoxLoading from 'components/app/Loading/box';
import { FaRegCopy } from 'react-icons/fa';

export default function ScheduleInterview() {
  const toast = useToast();

  const { placehodlers } = useSelector((state: any) => state.template);
  const { interviewBody } = useSelector((state: any) => state.candidatesInterview)

  const [reqPlaceHolder, resPlaceholder] = usePlaceHoldersMutation();
  const [reqCreateBody, resCreateBody] = useCreateInterviewBodyMutation();
  const [reqUpdateBody, resUpdateBody] = useUpdateInterviewBodyMutation();
  const [quilValue, setquilValue] = useState(interviewBody?.body || '');

  useEffect(() => {
    if (resCreateBody.isSuccess) {
      toast({
        title: 'Created Default Interview Body',
        duration: 1000,
        position: 'top',
      });
    }
    if (resUpdateBody.isSuccess) {
      toast({
        title: 'Updated Default Interview Body',
        duration: 1000,
        position: 'top',
      });
    }
  }, [resCreateBody.isSuccess, resUpdateBody.isSuccess]);

  useEffect(() => {
    reqPlaceHolder({});
  }, []);

  const placeHolderClick = (holder: string) => {
    navigator.clipboard.writeText(`{{${holder}}}`);
    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  const initialValues = {
    // body: signature?.body,
    body: interviewBody?.body || ''
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      body: Yup.string().required('Body is required.'),
    })
  );

  const onSubmit = async (data: any) => {
    reqUpdateBody({ data });
  };

  return (
    <Box fontFamily="Proxima Nova Regular">
      <Box
        bg="white"
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
      >
        <Flex justifyContent="space-between" p={6}>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              handleSubmit,
              handleChange,
              setFieldValue,
              errors,
              touched,
            }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box width="100%">
                  <Box mb="20px">
                    <FormControl
                      // isInvalid={Boolean(!!errors.body && touched.body)}
                      isInvalid={Boolean(!!errors.body && touched.body)}
                    >
                      <FormLabel
                        fontSize="18px"
                        lineHeight="18px"
                        fontWeight="bold"
                        color="default.primarytext"
                      >
                        Interview Body
                      </FormLabel>
                      <Box>
                        <Flex gap="32px" mb="34px">
                          <Box w="35%">
                            <Text>Placeholders</Text>
                            <Box
                              width="100%"
                              borderWidth="1px"
                              borderRadius="lg"
                              padding={5}
                            >
                              {resPlaceholder.isLoading ? (
                                <BoxLoading box={20} width="70px" />
                              ) : (
                                <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                                  {Object.values(placehodlers).length > 0 &&
                                    Object.values(placehodlers).map(
                                      (category: any, key: any) => {
                                        return (
                                          <Box mb="20px">
                                            <Text
                                              color="gray.700"
                                              mb="5px"
                                              fontSize="12px"
                                            >
                                              {category.category}
                                            </Text>
                                            <Wrap spacing={1}>
                                              {category.subs.map(
                                                (item: any, i: number) => {
                                                  return (
                                                    <WrapItem>
                                                      <Button
                                                        leftIcon={<FaRegCopy />}
                                                        onClick={() =>
                                                          placeHolderClick(item)
                                                        }
                                                        sx={{
                                                          height: 'fit-content',
                                                          p: '5px 10px',
                                                          fontSize: '10px',
                                                        }}
                                                      >
                                                        {item}
                                                      </Button>
                                                    </WrapItem>
                                                  );
                                                }
                                              )}
                                            </Wrap>
                                          </Box>
                                        );
                                      }
                                    )}
                                </Grid>
                              )}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              '& .ql-editor': {
                                minHeight: '100vh !important',
                              },
                            }}
                            w="65%"
                          >
                            <Box mb="34px">
                              <Text>Body</Text>
                              <AtsEmailBody
                                value={quilValue}
                                onChange={(e: any) => {
                                  setquilValue(e);
                                  setFieldValue('body', e);
                                }}
                                height={250}
                              />
                              <FormErrorMessage>
                                {String(errors.body)}
                              </FormErrorMessage>
                            </Box>

                            <Button
                              variant="solid"
                              type="submit"
                              isLoading={resUpdateBody.isLoading || resCreateBody.isLoading}
                            >
                              Update
                            </Button>

                          </Box>


                        </Flex>

                      </Box>
                    </FormControl>

                  </Box>


                </Box>

              </form>
            )}
          </Formik>
        </Flex>
      </Box>

    </Box >
  );
}

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import '../../../theme/fonts.css';

import Button from 'Library/Button';
import FAIcon from '../../lib/FAIcon';

import LeadsModal from './Modal';
import LeadsTable from './Table';

import {
  useListLeadsMutation,
  listLeads,
  useGetStatusMutation,
  useGetTypesMutation,
} from 'store/leads.slice';
import ScrollToFieldError from '../ScrollError';
import TrLoading from '../TrLoading';

interface statusInf {
  id: number;
}

export default function LeadsPage() {
  const dispatch = useDispatch();

  document.documentElement.dir = 'ltr';

  const { leadPgBtn, leadStatus } = useSelector((state: any) => state.leads);

  const [reqLeads, resLeads] = useListLeadsMutation();
  const [reqStatus] = useGetStatusMutation();
  const [reqTypes] = useGetTypesMutation();
  const [typeIsChecked, setTypeChecked] = useState([]);
  const [statusIsChecked, setStatusChecked] = useState([]);

  useEffect(() => {
    setTypeChecked(leadPgBtn.types);
    setStatusChecked(leadPgBtn.status);
  }, [leadPgBtn.status, leadPgBtn.types]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialValue = {
    search: leadPgBtn.query,
    hot: false,
    qualified: false,
    status: [] as statusInf[],
    types: [] as statusInf[],
  };

  const submitForm = (params: any) => {
    dispatch(
      listLeads({
        leadPgBtn: params,
      })
    );
    reqLeads(params);
  };

  useEffect(() => {
    reqStatus({});
    reqTypes({});
    const leadFilterData: string | null = localStorage.getItem('leadsFilter');
    const initFilter =
      leadFilterData == null ? leadPgBtn : JSON.parse(leadFilterData);

    dispatch(
      listLeads({
        leadPgBtn: initFilter,
      })
    );
    reqLeads(initFilter);
  }, []);

  const onSubmit = (data: any) => {
    let params = {
      page: 1,
      take: leadPgBtn.take,
      query: data.search,
      status: data.status,
      types: data.types,
    };
    submitForm(params);
  };

  const handleCheckStatus = useCallback(
    (e: any) => {
      let params = {
        page: 1,
        take: leadPgBtn.take,
        query: leadPgBtn.query,
        status: statusIsChecked,
        types: typeIsChecked,
      };

      const { id, checked } = e.target;
      let ID = parseInt(id);
      setStatusChecked([...statusIsChecked, ID]);
      params.status = [...statusIsChecked, ID];
      if (!checked) {
        const isChecked = statusIsChecked.filter((item: number) => item !== ID);
        setStatusChecked(isChecked);
        params.status = isChecked;
      }

      submitForm(params);
      localStorage.setItem('leadsFilter', JSON.stringify(params));
    },
    [
      leadPgBtn.query,
      leadPgBtn.take,
      statusIsChecked,
      submitForm,
      typeIsChecked,
    ]
  );

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        page: 1,
        take: leadPgBtn.take,
        query: '',
        status: leadPgBtn.status,
        types: leadPgBtn.types,
      };
      dispatch(
        listLeads({
          leadPgBtn: params,
        })
      );
      reqLeads(params);
    }
  };

  return (
    <Box fontFamily="Proxima Nova Regular">
      <Flex justifyContent="space-between" px={6}>
        <Flex justifyContent="space-around" maxWidth="40%" alignItems="center">
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Box pr={2}>
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <InputGroup background="#fff">
                    <InputRightElement
                      cursor="pointer"
                      children={<FAIcon iconName="search" />}
                    />
                    <Input
                      type="search"
                      placeholder="Search a lead name"
                      value={values.search}
                      id="search"
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </form>
              </Box>
            )}
          </Formik>
        </Flex>

        <Flex gap="20px">
          <Flex px={2} justifyContent="space-between">
            {Object.keys(leadStatus).length > 0 &&
              Object.values(leadStatus).map((data: any, key: number) => {
                return (
                  <Checkbox
                    key={key}
                    colorScheme="purple"
                    color="default.secondarytext"
                    mx={1}
                    onChange={(e) => {
                      handleCheckStatus(e);
                    }}
                    isChecked={statusIsChecked.includes(data.id)}
                    id={data.id}
                  >
                    {data.lead_status}
                  </Checkbox>
                );
              })}
          </Flex>
          <Button leftIcon="plus" variant="solid" onClick={onOpen}>
            Add new lead
          </Button>
        </Flex>

        {isOpen && (
          <LeadsModal
            isOpen={isOpen}
            onClose={onClose}
            modalTitle="Create New Lead"
          />
        )}
      </Flex>
      <Box>
        {resLeads.isLoading ? (
          <Box px={6} py={4} justifyContent="space-between">
            <TableContainer
              boxSizing="border-box"
              borderX="1px solid"
              borderTop="1px solid"
              borderColor="default.white.400"
              borderRadius="md"
              minHeight="70vh"
            >
              <Table>
                <Thead height="40.5px">
                  <Tr bg="default.white.600">
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody
                  boxSizing="border-box"
                  background="default.white.100"
                  borderBottom="1px solid"
                  borderColor="default.white.400"
                >
                  <TrLoading rows={7} columns={10} />
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <LeadsTable />
        )}
      </Box>
    </Box>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Formik } from 'formik';

import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link,
  Switch,
  InputLeftElement,
  Checkbox,
} from '@chakra-ui/react';
import { BsEnvelope, BsLinkedin, BsStar, BsTelephone } from 'react-icons/bs';

import FAIcon from 'components/lib/FAIcon';
import Pagination from 'Library/Pagination';

import { navClient } from 'store/client.slice';
import { listContact } from 'store/contact.slice';
import ScrollToFieldError from 'components/app/ScrollError';
import toUrl from 'utils/toUrl';
import { useViewContactMutation } from 'store/contact.slice';
import TrLoading from '../../TrLoading';

const ClientsContacts = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const { clientData } = useSelector((state: any) => state.clients);

  const { contactData, contactPageBtn, contactPage } = useSelector(
    (state: any) => state.contacts
  );
  const { leadTypes } = useSelector((state: any) => state.leads);

  let newParam = {
    page: contactPageBtn.page,
    take: contactPageBtn.take,
    query: contactPageBtn.query,
    types: contactPageBtn.types || [4],
    status: [2],
    contacts: false,
  };
  const { clientNav } = useSelector((state: any) => state.clients);
  const [reqContact, resContact] = useViewContactMutation();

  const tableHeader: { label: string }[] = [
    { label: 'Contact Name' },
    { label: 'Contact Details' },
    { label: 'Actions' },
  ];

  const clientID = params.clientsId;

  const initialValues = {
    search: '',
  };

  const [selectedContact, setSelectedContact] = useState(null);
  const onSubmit = (data: any) => {
    newParam = {
      query: data.search,
      page: contactPageBtn.page,
      take: contactPageBtn.take,
      types: contactPageBtn.types,
      status: contactPageBtn.status,
      contacts: contactPageBtn.contacts,
    };

    reqContact({ data: newParam, id: clientID });
    dispatch(listContact({ contactPageBtn: newParam }));
  };

  const pageChange = async (page: number) => {
    newParam = {
      page: page,
      take: contactPageBtn.take,
      query: contactPageBtn.query,
      types: contactPageBtn.types,
      status: contactPageBtn.status,
      contacts: contactPageBtn.contacts,
    };
    dispatch(listContact({ contactPageBtn: newParam }));
    reqContact({ data: newParam, id: clientID });
  };

  useEffect(() => {
    if (params.clientsTab === 'contacts') {
      newParam = {
        page: contactPageBtn.page,
        take: contactPageBtn.take,
        query: contactPageBtn.query,
        types: contactPageBtn.types || [4],
        status: [2],
        contacts: false,
      };
      dispatch(listContact({ contactPageBtn: newParam }));
      reqContact({ data: newParam, id: clientID });
    }
  }, [clientID, clientNav]);

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      newParam = {
        page: contactPageBtn.page,
        take: contactPageBtn.take,
        query: '',
        types: contactPageBtn.types,
        status: contactPageBtn.status,
        contacts: contactPageBtn.contacts,
      };
      dispatch(listContact({ contactPageBtn: newParam }));
      reqContact({ data: newParam, id: clientID });
    }
  };

  const entryChange = (entries: number) => {
    let newParam = {
      page: 1,
      take: contactPageBtn.take,
      query: entries,
      types: contactPageBtn.types,
      status: contactPageBtn.status,
      contacts: contactPageBtn.contacts,
    };
    dispatch(listContact({ contactPageBtn: newParam }));
    reqContact({ data: newParam, id: clientID });
  };

  const handleClick = async (contact: any) => {
    if (contact.leadType.id === 4) {
      setSelectedContact(contact);
      dispatch(navClient({ clientNav: '' }));
    } else if (contact.leadType.id === 1) {
      navigate(`/leads/${contact?.id}/details`);
      dispatch(navClient({ clientNav: 'leads' }));
    }
  };

  useEffect(() => {
    if (clientNav === '') {
      dispatch(navClient({ clientNav: 'contacts' }));
    }
  }, [clientNav]);

  useEffect(() => {
    if (clientNav === 'contacts') {
      navigate(`/clients/${selectedContact?.id}/overview`);
    }
  }, [clientNav]);

  const [typeIsChecked, setTypeChecked] = useState([]);
  const [statusIsChecked, setStatusChecked] = useState([]);

  useEffect(() => {
    setTypeChecked(contactPageBtn.types);
    setStatusChecked(contactPageBtn.status);
  }, [contactPageBtn.status, contactPageBtn.types]);

  const submitForm = (params: any) => {
    dispatch(listContact({ contactPageBtn: params }));
    reqContact({ data: params, id: clientID });
  };

  const handleCheckTypes = useCallback(
    (e: any) => {
      let params = {
        page: contactPageBtn.page,
        take: contactPageBtn.take,
        query: contactPageBtn.query,
        types: typeIsChecked,
        status: statusIsChecked,
        contacts: contactPageBtn.contacts,
      };

      const { id, checked } = e.target;
      let ID = parseInt(id);
      setTypeChecked([...typeIsChecked, ID]);
      params.types = [...typeIsChecked, ID];
      if (!checked) {
        const isChecked = typeIsChecked.filter((item: number) => item !== ID);
        setTypeChecked(isChecked);
        params.types = isChecked;
      }
      submitForm(params);
    },
    [
      contactPageBtn.query,
      contactPageBtn.take,
      statusIsChecked,
      submitForm,
      typeIsChecked,
    ]
  );

  return (
    <Box fontFamily="Proxima Nova Regular">
      <Flex justifyContent="space-between" pb="19px" mt="20px">
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, handleChange, handleSubmit, errors, touched }) => (
            <form onSubmit={handleSubmit}>
              <ScrollToFieldError />
              <Box pr={2}>
                <InputGroup background="#fff">
                  <InputLeftElement children={<FAIcon iconName="search" />} />
                  <Input
                    type="search"
                    value={values.search}
                    name="search"
                    placeholder="Search Contacts"
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                  />
                </InputGroup>
              </Box>
            </form>
          )}
        </Formik>
        <Flex gap="10px">
          <Switch
            colorScheme="purple"
            defaultChecked={clientData.is_active === 1 ? true : false}
            onChange={(e) => e.target.checked}
          />
          Active
        </Flex>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {resContact.isLoading ? (
                <TrLoading rows={3} columns={3} />
              ) : (
                contactData?.length > 0 &&
                contactData.map((data: any) => {
                  return (
                    <Tr
                      key={data?.id}
                      _hover={{
                        bg: '#f8f9fa',
                      }}
                      cursor="pointer"
                    >
                      <Td
                        onClick={() => {
                          handleClick(data);
                        }}
                      >
                        <Box>
                          <Box fontWeight="bold">
                            {[data?.first_name, data?.last_name].join(' ')}
                          </Box>
                          <Box fontSize="sm"> {data?.title}</Box>
                          <Box fontSize="sm"> {data?.company}</Box>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <Box fontWeight="bold" fontSize="sm">
                            {data?.primary_email}
                          </Box>
                          <Box fontSize="sm"> {data?.personal_phone}</Box>
                        </Box>
                      </Td>
                      <Td>
                        <HStack spacing="25px">
                          <Icon as={BsStar} />
                          <Link href={toUrl(data?.linked_in_url)} isExternal>
                            <Icon as={BsLinkedin} />
                          </Link>
                          <Link href={`mailto:${data?.primary_email}`}>
                            <Icon as={BsEnvelope} />
                          </Link>
                          <Link href={`tel:${data?.personal_phone}`}>
                            <Icon as={BsTelephone} />
                          </Link>
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={contactPage.lastPage}
          currentPage={contactPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={contactPage.count}
          targetCount={contactPageBtn.take}
        />
      </Box>
    </Box>
  );
};

export default ClientsContacts;

import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import ClientAgreementAttachments from './attachments';
import ClientEditgreementModal from './edit';
import moment from 'moment';

interface ClientAgrrmentModalProps {
  isOpen: any;
  onClose: any;
}

export default function ClientViewAgreementModal({
  isOpen,
  onClose,
}: ClientAgrrmentModalProps) {
  const { clientAgreementData, clientAttachments } = useSelector(
    (state: any) => state.clientAgreements
  );

  const {
    isOpen: isEditAgreementOpen,
    onOpen: onEditAgreementOpen,
    onClose: onEditAgreementClose,
  } = useDisclosure();

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            View Agreement
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Box p="32px">
              <Flex gap="32px" mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Title
                  </FormLabel>
                  {clientAgreementData?.title}
                </FormControl>
              </Flex>
              <Box mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Content
                  </FormLabel>
                  {clientAgreementData?.content}
                </FormControl>
              </Box>

              <Flex gap="32px" mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Signed On:{' '}
                    {moment
                      .utc(clientAgreementData?.date_signed)
                      .format('MM/DD/YYYY')}
                  </FormLabel>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Valid Until:{' '}
                    {(clientAgreementData?.valid_until) ? moment
                      .utc(clientAgreementData?.valid_until)
                      .format('MM/DD/YYYY')
                      : 'No Expiry'}
                  </FormLabel>
                </FormControl>
              </Flex>
              <Flex gap="32px" mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Fee Percentage:{' '}
                    {`${clientAgreementData?.fee_pct} %`}
                  </FormLabel>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Payable:{' '}
                    {`${clientAgreementData?.payable} ${clientAgreementData?.payable === 1 ? 'day' : 'days'}`}
                  </FormLabel>
                </FormControl>
              </Flex>
              <Flex gap="32px" mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Guarantee:{' '}
                    {`${clientAgreementData?.guarantee} ${clientAgreementData?.guarantee === 1 ? 'day' : 'days'}`}
                  </FormLabel>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Guarantee Type:{' '}
                    {clientAgreementData?.guarantee_type}
                  </FormLabel>
                </FormControl>
              </Flex>
              <Box mb="34px">
                <ClientAgreementAttachments attachments={clientAttachments} />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter
            position="sticky"
            bottom="0"
            background="default.white.100"
            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
          >
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="solid" type="button" onClick={onEditAgreementOpen}>
              Edit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isEditAgreementOpen && (
        <ClientEditgreementModal
          isOpen={isEditAgreementOpen}
          onClose={onEditAgreementClose}
        />
      )}
    </>
  );
}

import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Button from '../../../../Library/Button';
import FAIcon from 'components/lib/FAIcon';
import LeadsAddAgreementModal from './add';
import LeadsEditgreementModal from './edit';

import {
  useGetAgreementMutation,
  useDelAgreementMutation,
  useListLeadAgreementMutation,
  getAgreement,
} from 'store/leadagreement.sclice';
import { AtsConfirm } from 'utils/swal';

import { swalContent } from 'types';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import Pagination from 'Library/Pagination';
import { Formik } from 'formik';
import ScrollToFieldError from 'components/app/ScrollError';
import moment from 'moment';
import LeadsViewAgreementModal from './view';

const LeadsAgreements = () => {
  const { agreements, leadAgreementPage, leadAgreementPgBtn } = useSelector(
    (state: any) => state.leadagreement
  );
  const [reqget] = useGetAgreementMutation();
  const [reqdel, resDel] = useDelAgreementMutation();
  const [reqAgreement] = useListLeadAgreementMutation();
  const tableHeader: { label: string }[] = [
    { label: 'Name Of Agreement' },
    { label: 'Signed On' },
    { label: 'Valid Until' },
    { label: '' },
  ];
  const params = useParams();
  const leadID = params.leadsId;
  const dispatch = useDispatch();

  const {
    isOpen: isAddAgreementOpen,
    onOpen: onAddAgreementOpen,
    onClose: onAddAgreementClose,
  } = useDisclosure();

  const {
    isOpen: isEditAgreementOpen,
    onOpen: onEditAgreementOpen,
    onClose: onEditAgreementClose,
  } = useDisclosure();

  const {
    isOpen: isViewAgreementOpen,
    onOpen: onViewAgreementOpen,
    onClose: onViewAgreementClose,
  } = useDisclosure();

  const editClick = async (id: number) => {
    await reqget({ id: id });
    onEditAgreementOpen();
  };

  useEffect(() => {
    reqAgreement({ data: leadAgreementPgBtn, id: leadID });
  }, [
    leadAgreementPgBtn,
    leadID,
    reqAgreement,
    onAddAgreementClose,
    onEditAgreementClose,
    onViewAgreementClose,
  ]);

  useEffect(() => {
    if (resDel.isSuccess) {
      reqAgreement({ data: leadAgreementPgBtn, id: leadID });
    }
  }, [leadAgreementPgBtn, leadID, reqAgreement, resDel.isSuccess]);

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'Do you really want to delete this agreement?',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const deleteClick = async (id: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      reqdel({ id: id });
    }
  };

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: leadAgreementPgBtn.take,
      query: leadAgreementPgBtn.query,
    };
    await dispatch(
      getAgreement({
        leadAgreementPgBtn: newParam,
      })
    );
    reqAgreement({ data: newParam, id: leadID });
  };

  const initialValue = {
    search: '',
  };

  const onSubmit = async (data: any) => {
    let newParam = {
      page: leadAgreementPgBtn.page,
      take: leadAgreementPgBtn.take,
      query: data.search,
    };
    await dispatch(
      getAgreement({
        leadAgreementPgBtn: newParam,
      })
    );

    reqAgreement({ data: newParam, id: leadID });
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let newParam = {
        page: 1,
        take: leadAgreementPgBtn.take,
        query: '',
        status: leadAgreementPgBtn.status,
        types: leadAgreementPgBtn.types,
      };
      dispatch(
        getAgreement({
          leadAgreementPgBtn: newParam,
        })
      );
      reqAgreement({ data: newParam, id: leadID });
    }
  };

  const entryChange = async (entries: number) => {
    let newParam = {
      // page: leadAgreementPgBtn.page,
      page: 1,
      take: entries,
      query: leadAgreementPgBtn.query,
    };
    await dispatch(
      getAgreement({
        leadAgreementPgBtn: newParam,
      })
    );
    reqAgreement({ data: newParam, id: leadID });
  };

  const viewAgreement = async (id: number) => {
    await reqget({ id: id });
    onViewAgreementOpen();
  };

  return (
    <Box>
      <Flex justifyContent="space-between" py={6}>
        <Box pr={2}>
          <Formik initialValues={initialValue} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <InputGroup background="#fff">
                  <InputRightElement
                    cursor="pointer"
                    children={<FAIcon iconName="search" />}
                  />
                  <Input
                    type="search"
                    name="search"
                    value={values.search}
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                    placeholder="Search File Name"
                  />
                </InputGroup>
              </form>
            )}
          </Formik>
        </Box>

        <Button leftIcon="plus" variant="solid" onClick={onAddAgreementOpen}>
          Add Agreement
        </Button>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {agreements.length > 0 &&
                agreements?.map((data: any) => (
                  <Tr key={data.id} cursor="pointer" onClick={() => {}}>
                    <Td>
                      <Link onClick={() => viewAgreement(data.id)}>
                        {data.title}
                      </Link>
                    </Td>
                    <Td>{moment.utc(data.date_signed).format('MM/DD/YYYY')}</Td>
                    <Td>{moment.utc(data.valid_until).format('MM/DD/YYYY')}</Td>
                    <Td>
                      <Menu>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                        <MenuList>
                          <MenuItem onClick={() => editClick(data.id)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="pencil" />
                              <Box ml={12} cursor="pointer">
                                Edit
                              </Box>
                            </Flex>
                          </MenuItem>
                          <MenuItem onClick={() => deleteClick(data.id)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <FAIcon iconName="trash" />
                              <Box ml={12} cursor="pointer">
                                Delete
                              </Box>
                            </Flex>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={leadAgreementPage.lastPage}
          currentPage={leadAgreementPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={leadAgreementPage.count}
          currentCount={agreements?.length}
          targetCount={leadAgreementPgBtn.take}
        />
        {isAddAgreementOpen && (
          <LeadsAddAgreementModal
            isOpen={isAddAgreementOpen}
            onClose={onAddAgreementClose}
          />
        )}
        {isEditAgreementOpen && (
          <LeadsEditgreementModal
            isOpen={isEditAgreementOpen}
            onClose={onEditAgreementClose}
          />
        )}
        {isViewAgreementOpen && (
          <LeadsViewAgreementModal
            isOpen={isViewAgreementOpen}
            onClose={onViewAgreementClose}
          />
        )}
      </Box>
    </Box>
  );
};

export default LeadsAgreements;

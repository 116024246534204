import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaRegCopy } from 'react-icons/fa';

import {
  Box,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Button as ChakraButton,
} from '@chakra-ui/react';

import CandidatesDrawer from './Drawer';
import Pagination from 'Library/Pagination';
import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import {
  getCandidate,
  listCandidates,
  useDeleteCandidatesMutation,
  useToggleHideCandidatesMutation,
  useListCandidatesMutation,
  useGetCandidatesMutation,
} from 'store/candidates.slice';

import { CandidateData, candidateJobs, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import CandidatesTagModal from './Modals/Tag';
import CandidatesMergeModal from './Modals/CandidatesMergeModal';
import CandidatesAssociateJobModal from './Modals/AssociateJobs';
// import CandidatesModal from './Modals';
import CandidateTR from './components/CandidateTR';
import TrLoading from '../TrLoading';
import CandidateEmail from './Modals/Email';
import { getJob, useGetJobsMutation } from 'store/jobs.slice';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
  usePinCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import CandidatesModal from './Modals/modal';
import { debounce } from 'lodash';

const CandidatesTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  const {
    candidates,
    candidatePage,
    candidateData,
    candidatePgBtn,
    candidateLoading,
  } = useSelector((state: any) => state.candidates);

  const [candidateSort, setCandidateSort] = useState(candidates);

  useEffect(() => {
    const sort = candidates.map((candidate: any, index: number) => {
      return { ...candidate, rowId: index + 1 };
    });

    setCandidateSort(sort);
  }, [candidates]);

  const abortControllerRef = useRef(null); // Ref to store the AbortController instance

  const [reqCandidates, resCandidates] = useListCandidatesMutation();

  const debounceReqCandidates = debounce(reqCandidates, 300);
  const [reqGetJob] = useGetJobsMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqGetCandidate2, resGetCandidate2] = useGetCandidatesMutation();

  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();

  const [rowId, setRowId] = useState(1);
  const [candidateId, setCandidateId] = useState(0);
  const [candidateIdList, setCandidateIdList] = useState([0]);
  const [assocJobs, setAssocJobs] = useState([]);

  document.documentElement.dir = 'ltr';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isSorted, setIsSorted] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [orderBy, setOrderBy] = useState(null);

  const [isBulkAction, setIsBulkAction] = useState(false);
  const [emailList, setEmailList] = useState([]);
  const [filterCache, setFilterCache] = useState(JSON.stringify({}));

  const {
    isOpen: isOpenCandidate,
    onOpen: onOpenCandiate,
    onClose: onCloseCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenTag,
    onOpen: onOpenTag,
    onClose: onCloseTag,
  } = useDisclosure();

  const {
    isOpen: isOpenAssociate,
    onOpen: onOpenAssociate,
    onClose: onCloseAssociate,
  } = useDisclosure();

  const {
    isOpen: isOpenMergeCandidates,
    onOpen: onOpenMergeCandidates,
    onClose: onCloseMergeCandidates,
  } = useDisclosure();

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  const [fetching, setFetching] = useState(false);

  const fetchCandidate = async () => {
    console.log('fethcing', fetching);
    const abortController = new AbortController();
    const signal = abortController.signal;
    if (fetching) {
      console.log('aborting...');
      // await reqCandidates({ data: candidatePgBtn, signal }).abort();
    }
    console.log('fetching..');
    setFetching(true);
    await reqCandidates(candidatePgBtn);
  };

  useEffect(() => {
    setFetching(resCandidates.isLoading);
  }, [resCandidates.isLoading]);

  const associateJobSuccess = async () => {
    setIsBulkAction(false);

    fetchCandidate();

    await reqFilterList({});
    await reqPinList({});
    // await reqGetJob({ id: params.jobsId });
  };
  useEffect(() => {
    // const candidateID =
    //   candidateSort.findIndex(
    //     (x: CandidatesTypes) => x.id === Number(params.candidatesId)
    //   ) + 1;
    // setRowId(candidateID);
    setIsBulkAction(false);
  }, [candidateSort, params.candidatesId]);

  useEffect(() => {
    reqPinList({});
  }, []);
  useMemo(() => {
    if (filterCache != '{}' && filterCache != JSON.stringify(candidatePgBtn)) {
      fetchCandidate();
    }
    setFilterCache(JSON.stringify(candidatePgBtn));
    return candidatePgBtn;
  }, [candidatePgBtn]);

  useEffect(() => {
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      fetchCandidate();
      reqPinList({});
    }
  }, [resDeleteCandidate.isSuccess, resToggleHide.isSuccess]);

  useEffect(() => {
    if (isSorted) {
      const params = {
        ...candidatePgBtn,
        job_id: null as any,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };
      dispatch(
        listCandidates({
          candidatePgBtn: params,
        })
      );

      setIsSorted(false);
    }
  }, [isSorted]);

  useEffect(() => {
    if (params.candidatesId && !isOpen) {
      if (candidateData?.id !== params.candidatesId) {
        pageChange(Number(params?.candidatesPageNum)).then(() => {
          onOpen();
        });
      }
      dispatch(getJob({ jobData: {} }));
    }
  }, [params.candidatesId, isOpen]);

  const { filter } = useSelector((state: any) => state.candidateFilters);

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  useEffect(() => {
    if (filter) {
      setIsGroupByJob(true);
    }
  }, [filter]);

  const handleClick = (candidate: any) => {
    dispatch(
      getCandidate({
        candidateData: candidate,
        candidateDataJobId: isGroupByJob && candidate?.jobs?.[0]?.id,
      })
    );

    onOpen();

    if (isGroupByJob && candidate?.jobs?.[0]) {
      navigate(
        `/candidates/${candidatePage.currentPage}/${candidate.id}/about?jobId=${candidate?.jobs?.[0]?.id}`
      );
    } else {
      navigate(
        `/candidates/${candidatePage.currentPage}/${candidate.id}/about`
      );
    }

    if (isGroupByJob) {
      reqGetCandidate({ id: candidate.id });
    }
  };

  const handleDeleteCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDeleteCandidate({ id: candidate.id });
      await reqFilterList({});
      await reqPinList({});
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const handleHideCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      console.log('hidden Table');
      await reqToggleHide({ id: candidate.id });
      await reqFilterList({});
      await reqPinList({});
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const stateTab: any = params.candidatesTab;

  const handlePrevData = () => {
    const candidateID = candidateSort.filter(
      (item: any) => item.rowId === rowId - 1
    )[0]?.id;

    setRowId((prevKey: number) => prevKey - 1);
    const prevData = candidateSort.filter(
      (item: any) => item.rowId === rowId - 1
    )[0];
    if (isGroupByJob && prevData?.jobs?.[0]) {
      navigate(
        `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${prevData?.jobs[0]?.id}`
      );
    } else {
      navigate(
        `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}`
      );
    }
    dispatch(
      getCandidate({
        candidateData: prevData,
        candidateDataJobId: isGroupByJob && prevData?.jobs[0]?.id,
      })
    );
    if (isGroupByJob) {
      reqGetCandidate({ id: candidateID });
    }
  };

  const handleNextData = () => {
    const candidateID = candidateSort.filter(
      (item: any) => item.rowId === rowId + 1
    )[0].id;

    setRowId((prevKey: number) => prevKey + 1);
    const nextData = candidateSort.filter(
      (item: any) => item.rowId === rowId + 1
    )[0];
    if (isGroupByJob && nextData?.jobs?.[0]) {
      navigate(
        `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${nextData?.jobs[0]?.id}`
      );
    } else {
      navigate(
        `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}`
      );
    }
    dispatch(
      getCandidate({
        candidateData: nextData,
        candidateDataJobId: isGroupByJob && nextData?.jobs[0]?.id,
      })
    );
    if (isGroupByJob) {
      reqGetCandidate({ id: candidateID });
    }
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(candidateSort.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    const selectedCandidate = candidates?.filter(
      (candidate: any) => ID === candidate.id
    );
    // setSelectedMergeCandidate([...selectedMergeCandidate, selectedCandidate[0]])
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  const handleSort = async (column_name: string) => {
    const newSortOrder = sortOrder ? 'ASC' : 'DESC';
    setSortOrder(!sortOrder);
    setIsSorted(true);
    setOrderBy({
      column_name: column_name,
      sort_order: newSortOrder,
    });
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < candidatePgBtn.take) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === candidatePgBtn.take) {
      setIsCheckAll(true);
    }
  }, [candidatePgBtn.take, isCheck, isCheckAll]);

  const tableHeader: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            position: 'sticky',
            left: 0,
            bg: 'default.white.600',
            color: 'default.gray.600',
            boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Flex gap="30px">
            <Checkbox
              onChange={handleSelectAll}
              isIndeterminate={isIndeterminate}
              checked={isCheckAll}
              defaultChecked={false}
              colorScheme="purple"
            />
            <Box>Candidate Details</Box>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Location</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Created on
            <Flex onClick={() => handleSort('created_at')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex cursor="pointer" onClick={() => handleSort('last_activity')}>
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },

    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Associated Jobs</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Tags</Box>
        </Th>
      ),
    },
  ];

  const pageChange = async (page: number) => {
    let newParam = {
      ...candidatePgBtn,
      page: page,
    };

    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...candidatePgBtn,
      take: entries,
    };
    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
  };

  const addTagClick = (id: any) => {
    setIsBulkAction(false);
    setCandidateId(id);
    onOpenTag();
  };

  const associateToJob = async (candidate: CandidateData) => {
    let list: any = [];
    // console.log('candidate', candidate);
    if (candidate.jobs.length > 0) {
      await Promise.all(
        candidate.jobs.map((job: candidateJobs) => {
          list.push(job.id);
        })
      );
    }
    // console.log('list', list);
    setAssocJobs(list);
    setCandidateId(candidate?.id);
    onOpenAssociate();
  };

  const editCandidate = async (candidate: any) => {
    // console.log("candidate ", candidate)
    await reqGetCandidate({ id: candidate.id });
    onOpenCandiate();
    setCandidateId(candidate.id);
  };

  const bulkAssociateToJob = async () => {
    // console.log('went to bulk associate to job');
    setIsBulkAction(true);
    const candidateList = candidateSort.filter((candidate: CandidateData) =>
      isCheck.includes(candidate.id)
    );
    candidateList.map(async (candidate: CandidateData) => {
      let list: any = [];
      if (candidate?.jobs?.length > 0) {
        await Promise.all(
          candidate.jobs.map((job: candidateJobs) => {
            list.push(job.id);
          })
        );
      }
      setAssocJobs(list);
      setCandidateIdList(isCheck);
    });
    onOpenAssociate();
  };

  const bulkTags = () => {
    setIsBulkAction(true);
    onOpenTag();
  };

  const bulkEmail = async () => {
    setIsBulkAction(true);
    const candidateList = candidateSort?.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    const emails = candidateList?.map((candidate: any) => {
      const candidateEmail = [...candidate?.emails]?.sort(
        (emailA: any, emailB: any) => {
          return emailB.primary - emailA.primary;
        }
      );
      return candidateEmail[0].email;
    });
    setEmailList(emails);
    setCandidateId(candidateList[0].id);
    // dispatch(getCandidate({ candidateData: candidateList[0] }));
    const data = {
      candidate_id: isCheck[0],
      job_id: params?.jobsId || null,
    } as any;
    await reqPair(data);
    onOpenSendEmail();
    await reqGetCandidate({ id: candidateList[0].id });
    // if (candidateList[0]?.jobs.length > 0) {
    //   await reqGetJob({ id: candidateList[0]?.jobs?.[0]?.id });
    // }
  };

  const mergeCandidates = async () => {
    onOpenMergeCandidates();
    await reqGetCandidate({ id: isCheck[0] });
    await reqGetCandidate2({ id: isCheck[1] });
    setIsCheck([]);
  };
  return (
    <Box px={6} pt={7} justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button variant="basic" leftIcon="comment-dots" size="sm">
            Send SMS
          </Button>
          <Button
            variant="basic"
            leftIcon="envelope"
            size="sm"
            onClick={bulkEmail}
          >
            Send Email
          </Button>
          <Button
            variant="basic"
            leftIcon="eye"
            size="sm"
            onClick={bulkAssociateToJob}
          >
            Associate to Job
          </Button>
          <Button variant="basic" leftIcon="tags" size="sm" onClick={bulkTags}>
            Add Tags
          </Button>
          <ChakraButton
            variant="basic"
            leftIcon={<FaRegCopy />}
            size="sm"
            onClick={mergeCandidates}
            disabled={isCheck.length !== 2}
          >
            Merge Candidates
          </ChakraButton>
        </Flex>
      )}

      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        height="70vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <Tr bg="default.white.600">
              {tableHeader.map((title) => title.label)}
            </Tr>
          </Thead>
          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {resCandidates.isLoading ||
            resToggleHide.isLoading ||
            candidateLoading ? (
              <TrLoading rows={10} columns={tableHeader.length} />
            ) : (
              <>
                {candidateSort.map((candidate: any, key: number) => {
                  return (
                    <CandidateTR
                      candidate={candidate}
                      handleCheck={handleCheck}
                      isCheck={isCheck}
                      handleClick={() => {
                        handleClick(candidate);
                        setRowId(candidate?.rowId);
                      }}
                      associateToJob={associateToJob}
                      addTagClick={addTagClick}
                      editCandidate={editCandidate}
                      handleDeleteCandidate={(candidate: any) =>
                        handleDeleteCandidate(candidate)
                      }
                      handleHideCandidate={(candidate: any) =>
                        handleHideCandidate(candidate)
                      }
                      fromGroupByJobs={false}
                      fromJob={false}
                      key={key}
                    />
                  );
                })}
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      {isOpen && (
        <CandidatesDrawer
          // key={"0"}
          isOpen={isOpen}
          onClose={onClose}
          rowId={rowId}
          setRowId={setRowId}
          totalCandidates={candidateSort?.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
          isLoading={resGetCandidate.isLoading}
          candidates={candidateSort}
        />
      )}
      {isOpenMergeCandidates && (
        // Add merge candidates component
        <CandidatesMergeModal
          isOpen={isOpenMergeCandidates}
          onClose={onCloseMergeCandidates}
          candidate1={resGetCandidate}
          candidate2={resGetCandidate2}
        />
      )}
      {isOpenTag && (
        <CandidatesTagModal
          isOpen={isOpenTag}
          onClose={onCloseTag}
          id={candidateId}
          idList={isCheck}
          isBulkTag={isBulkAction}
        />
      )}
      {isOpenAssociate && (
        <CandidatesAssociateJobModal
          isOpen={isOpenAssociate}
          onClose={onCloseAssociate}
          id={candidateId}
          idList={candidateIdList}
          assoc_jobs={assocJobs}
          isBulkAssociateJob={isBulkAction}
          onSuccess={() => associateJobSuccess()}
        />
      )}
      {isOpenCandidate && (
        // <CandidatesModal
        //   isOpen={isOpenCandidate}
        //   onClose={onCloseCandidate}
        //   edit={true}
        //   id={candidateId}
        // />
        <CandidatesModal
          isOpen={isOpenCandidate}
          onClose={onCloseCandidate}
          edit={true}
          id={Number(candidateId)}
          candidateLoading={resGetCandidate.isLoading}
        />
      )}
      {isOpenSendEmail && (
        <CandidateEmail
          isOpen={isOpenSendEmail}
          onClose={onCloseSendEmail}
          id={candidateId}
          email={''}
          idList={isCheck}
          emailList={isBulkAction ? emailList : []}
          isBulkEmail={isBulkAction}
          // job_id={jobId}
        />
      )}
      <Pagination
        totalPages={candidatePage.lastPage}
        currentPage={candidatePage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={candidatePage.count}
        currentCount={candidateSort?.length}
        targetCount={candidatePgBtn.take}
      />
    </Box>
  );
};

export default CandidatesTable;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  Box,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import Pagination from 'Library/Pagination';

import FAIcon from '../../lib/FAIcon';

import LeadsDrawer from './Drawer';
import LeadsModal from './Modal';
import {
  getLead,
  useInactiveLeadsMutation,
  useGetLeadsMutation,
  useListLeadsMutation,
  listLeads,
  useReActivateMutation,
  useCancelApprovalMutation,
} from 'store/leads.slice';

import { AtsConfirm } from 'utils/swal';

import { LeadsTypes, swalContent } from '../../../types';
import LeadStatus from '../LeadStatus';
import LeadsConvertClientModal from './ConvertContactModal';
import LeadsSendForApprovalModal from './SendForApproval';

const LeadsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();

  const { leads, leadPage, leadPgBtn } = useSelector(
    (state: any) => state.leads
  );

  const [reqLeads] = useListLeadsMutation();
  const [reqGetLead] = useGetLeadsMutation();
  const [reqInactiveLead, resInactiveLead] = useInactiveLeadsMutation();
  const [reqActivate, resActivate] = useReActivateMutation();
  const [reqCancelApproval, resCancelApproval] = useCancelApprovalMutation();

  const [rowId, setRowId] = useState(1);
  const [isSendApproval, setIsSendApproval] = useState(false);

  const [isSorted, setIsSorted] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [orderBy, setOrderBy] = useState(null);

  document.documentElement.dir = 'ltr';

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const {
    isOpen: isEditLeadOpen,
    // onOpen: onEditLeadOpen,
    onClose: onEditLeadClose,
  } = useDisclosure();

  const {
    isOpen: isConvertClientOpen,
    onOpen: onConvertClientOpen,
    onClose: onConvertClientClose,
  } = useDisclosure();

  const {
    isOpen: isConvertLeadOpen,
    onOpen: onConvertLeadOpen,
    onClose: onConvertLeadClose,
  } = useDisclosure();

  useEffect(() => {
    const leadID =
      leads.findIndex((x: LeadsTypes) => x.id === Number(params.leadsId)) + 1;
    setRowId(leadID);
  }, [leads, params.leadsId]);

  useEffect(() => {
    if (isSorted) {
      const params = {
        page: leadPgBtn.page,
        take: leadPgBtn.take,
        query: leadPgBtn.query,
        // location: leadPgBtn.location,
        // tags: leadPgBtn.tags,
        // skills: leadPgBtn.skills,
        // experience: leadPgBtn.experience,
        // work_status: leadPgBtn.work_status,
        // job_id: null as any,
        hot: leadPgBtn.hot,
        qualified: leadPgBtn.qualified,
        status: leadPgBtn.status,
        types: leadPgBtn.types,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };

      dispatch(
        listLeads({
          leadPgBtn: params,
        })
      );
      reqLeads(params);
      setIsSorted(false);
    }
  }, [isSorted]);

  useEffect(() => {
    if (resActivate.isSuccess) {
      reqLeads(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resActivate.isSuccess &&
          resActivate.data.data.message.includes('Lead has been reactivated')
        ) {
          title = 'Mark as Active';
          description = 'Lead status was changed to Active';
        }

        if (
          resActivate.isSuccess &&
          resActivate.data.data.message.includes('Failed to reactivate')
        ) {
          title = 'Failed to reactivate';
          description = 'No logs found to retrace it. Status still Inactive';
          status = 'warning';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resActivate.isSuccess]);

  useEffect(() => {
    if (resInactiveLead.isSuccess) {
      reqLeads(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resInactiveLead.isSuccess &&
          resInactiveLead.data.data.message.includes(
            'Lead has been deactivated'
          )
        ) {
          title = 'Mark as Inactive';
          description = 'Lead status was changed to Inactive';
          status = 'info';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resInactiveLead.isSuccess]);

  useEffect(() => {
    if (resCancelApproval.isSuccess) {
      reqLeads(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resCancelApproval.isSuccess &&
          resCancelApproval.data.data.message.includes(
            'Approval has been canceld'
          )
        ) {
          title = 'Cancel Approval';
          description = 'Lead approval was cancelled';
          status = 'info';
        }

        if (
          resCancelApproval.isSuccess &&
          resCancelApproval.data.data.message.includes(
            'Failed to cancel Approval'
          )
        ) {
          title = 'Failed to cancel Approval';
          description = 'There is no logs to retrace';
          status = 'warning';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resCancelApproval.isSuccess]);

  useEffect(() => {
    if (params.leadsId) {
      reqGetLead({ id: params.leadsId });
      onOpenDrawer();
    }
  }, [params.leadsId]);

  const handleClick = (lead: LeadsTypes) => {
    // dispatch(getLead({ leadData: lead }));

    onOpenDrawer();
    navigate(`/leads/${lead.id}/details`);

    const key = leads.findIndex((x: LeadsTypes) => x.id === lead.id) + 1;
    setRowId(key);
  };

  let content: swalContent = {
    title: 'Are you sure?',
    text: 'You are about to mark this lead as inactive, continue?',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleInactive = async (lead: LeadsTypes) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqInactiveLead({ id: lead.id });
      dispatch(getLead({ leadData: {} }));
    }
  };

  const stateTab: any = params.leadsTab;

  const handlePrevData = () => {
    const leadID = leads[rowId - 1 - 1].id;
    setRowId((prevKey: number) => prevKey - 1);
    navigate(`/leads/${leadID}/${stateTab}`);
    const prevData = leads.filter((data: LeadsTypes) => data.id === leadID);
    dispatch(getLead({ leadData: prevData[0] }));
  };

  const handleNextData = () => {
    const leadID = leads[rowId - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(`/leads/${leadID}/${stateTab}`);
    const nextData = leads.filter((data: LeadsTypes) => data.id === leadID);
    dispatch(getLead({ leadData: nextData[0] }));
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(leads.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  const handleSort = async (column_name: string) => {
    const newSortOrder = sortOrder ? 'ASC' : 'DESC';
    console.log('click handle Sort');
    setSortOrder(!sortOrder);
    setIsSorted(true);
    setOrderBy({
      column_name: column_name,
      sort_order: newSortOrder,
    });
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < leadPgBtn.take) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === leadPgBtn.take) {
      setIsCheckAll(true);
    }
  }, [isCheck, isCheckAll, leadPgBtn.take]);

  const tableHeader: { label: any }[] = [
    {
      label: (
        <Checkbox
          onChange={handleSelectAll}
          isIndeterminate={isIndeterminate}
          checked={isCheckAll}
          defaultChecked={false}
          colorScheme="purple"
        />
      ),
    },
    { label: 'Lead Details' },
    { label: 'Contact Information' },
    { label: 'Status' },
    {
      label: (
        <Box>
          <FAIcon iconName="location-pin" /> Open Jobs
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="clipboard" /> Submits
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="phone" /> Interviews
        </Box>
      ),
    },
    {
      label: (
        <Flex gap="10px" justifyContent="center" alignItems="center">
          Created On
          <Flex onClick={() => handleSort('created_at')} cursor="pointer">
            <FAIcon iconName="sort" />
          </Flex>
        </Flex>
      ),
    },
    { label: '' },
  ];

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: leadPgBtn.take,
      query: leadPgBtn.query,
      hot: leadPgBtn.hot,
      qualified: leadPgBtn.qualified,
      status: leadPgBtn.status,
      types: leadPgBtn.types,
    };
    dispatch(
      listLeads({
        leadPgBtn: newParam,
      })
    );
    reqLeads(newParam);
  };

  const converClientClick = (lead: any) => {
    dispatch(getLead({ leadData: lead }));
    onConvertClientOpen();
  };

  const sendForApproval = (lead: any) => {
    dispatch(getLead({ leadData: lead }));
    setIsSendApproval(true);
    onConvertLeadOpen();
  };

  const cancelApprovalClick = async (lead: any) => {
    content.text = 'You are about to cancel this approval, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqCancelApproval({ id: lead.id });
    }
  };

  const marAsActiveClick = async (lead: any) => {
    content.text = 'You are about to mark this lead as active, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqActivate({ id: lead.id });
    }
  };

  const handleMenuList = (lead: any) => {
    const type = lead?.leadType?.type;
    const status = lead.leadStatus.lead_status;
    if (status === 'Rejected') {
      return (
        <>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (status === 'Approval Pending') {
      return (
        <>
          <MenuItem onClick={() => cancelApprovalClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="times" />
              <Box ml={12}>Cancel Approval</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (status === 'Active') {
      return (
        <>
          <MenuItem onClick={() => {}}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>View Contact</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (
      type === 'Lead' &&
      (status === 'Draft' || status === 'Rejected')
    ) {
      return (
        <>
          <MenuItem onClick={() => sendForApproval(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="check-circle" />
              <Box ml={12}>Send for Approval</Box>
            </Flex>
          </MenuItem>

          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Active') {
      return (
        <>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Approval Pending') {
      return (
        <>
          <MenuItem onClick={() => cancelApprovalClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="times" iconColor="caution.800" />
              <Box ml={12}>Cancel Approval</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Inactive') {
      return (
        <>
          <MenuItem onClick={() => marAsActiveClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="square-check" />
              <Box ml={12}>Mark as Active</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Qualified' && status === 'Active') {
      return (
        <>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Qualified' && status === 'Approval Pending') {
      return (
        <>
          <MenuItem onClick={() => cancelApprovalClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="times" iconColor="caution.800" />
              <Box ml={12}>Cancel Approval</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (type === 'Qualified' && status === 'Rejected') {
      return (
        <>
          <MenuItem onClick={() => sendForApproval(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="check-circle" />
              <Box ml={12}>Send for Approval</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => converClientClick(lead)}>
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="briefcase" />
              <Box ml={12}>Request to Convert</Box>
            </Flex>
          </MenuItem>
          <MenuItem onClick={() => handleInactive(lead)} color="caution.800">
            <Flex alignItems="center" justifyContent="space-between">
              <FAIcon iconName="ban" />
              <Box ml={12}>Mark as Inactive</Box>
            </Flex>
          </MenuItem>
        </>
      );
    } else if (status === 'Inactive') {
      return (
        <MenuItem onClick={() => marAsActiveClick(lead)}>
          <Flex alignItems="center" justifyContent="space-between">
            <FAIcon iconName="square-check" />
            <Box ml={12}>Mark as Active</Box>
          </Flex>
        </MenuItem>
      );
    }
  };

  const entryChange = (entries: number) => {
    let newParam = {
      // page: leadPgBtn.page,
      page: 1,
      take: entries,
      query: leadPgBtn.query,
      hot: leadPgBtn.hot,
      qualified: leadPgBtn.qualified,
      status: leadPgBtn.status,
      types: leadPgBtn.types,
    };
    dispatch(
      listLeads({
        leadPgBtn: newParam,
      })
    );
    reqLeads(newParam);
  };

  return (
    <Box px={6} py={4} justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex py={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button variant="basic" leftIcon="comment-dots" size="sm">
            Send SMS
          </Button>
          <Button variant="basic" leftIcon="envelope" size="sm">
            Send Email
          </Button>
        </Flex>
      )}

      <TableContainer
        boxSizing="border-box"
        borderX="1px solid"
        borderTop="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        minHeight="70vh"
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              {tableHeader.map((title) => (
                <Th key={title.label}>
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {leads.map((lead: LeadsTypes) => (
              <Tr
                key={lead.id}
                _hover={{
                  bg: '#f8f9fa',
                }}
                cursor="pointer"
              >
                <Td>
                  <Checkbox
                    onChange={handleCheck}
                    isChecked={isCheck.includes(lead.id)}
                    id={lead.id.toString()}
                    key={lead.id}
                    colorScheme="purple"
                  />
                </Td>
                <Td onClick={() => handleClick(lead)}>
                  <Box whiteSpace="pre-line" maxWidth="200px">
                    <Box fontWeight="bold" textTransform="capitalize">
                      {[lead.first_name, lead.last_name].join(' ')}
                    </Box>
                    <Box fontSize="sm"> {lead.title}</Box>
                    <Box fontSize="sm"> {lead?.client?.name}</Box>
                  </Box>
                </Td>
                <Td onClick={() => handleClick(lead)}>
                  <Box>
                    <Box fontWeight="bold" fontSize="sm">
                      {lead.primary_email}
                    </Box>
                    <Box fontSize="sm"> {lead.personal_phone}</Box>
                  </Box>
                </Td>
                <Td onClick={() => handleClick(lead)}>
                  <LeadStatus status={lead.leadStatus.lead_status} />
                </Td>
                <Td width="50px" p="5px 24px" fontSize="14px">
                  {lead?.total_opening}
                </Td>
                <Td width="50px" p="5px 24px" fontSize="14px">
                  {lead?.total_submits}
                </Td>
                <Td width="50px" p="5px 24px" fontSize="14px">
                  {lead?.total_interview}
                </Td>
                <Td width="50px" p="5px 24px" fontSize="14px">
                  {moment.utc(lead?.created_at).format('MM/DD/YYYY hh:mm A')}
                </Td>
                <Td>
                  <Menu>
                    <MenuButton>
                      <Button
                        htmlType="menu"
                        variant="ghost"
                        iconName="ellipsis-h"
                        iconSize="xl"
                      />
                    </MenuButton>

                    <MenuList fontSize="sm">{handleMenuList(lead)}</MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
          </Tbody>
          {isOpenDrawer && (
            <LeadsDrawer
              isOpen={isOpenDrawer}
              onClose={onCloseDrawer}
              rowId={rowId}
              totalLeads={leads?.length}
              handlePrevData={handlePrevData}
              handleNextData={handleNextData}
            />
          )}

          {isConvertClientOpen && (
            <LeadsConvertClientModal
              isOpen={isConvertClientOpen}
              onClose={onConvertClientClose}
            />
          )}
          {isConvertLeadOpen && (
            <LeadsSendForApprovalModal
              isOpen={isConvertLeadOpen}
              onClose={onConvertLeadClose}
              isSendApproval={isSendApproval}
              setIsSendApproval={setIsSendApproval}
            />
          )}
        </Table>
      </TableContainer>

      <Pagination
        totalPages={leadPage.lastPage}
        currentPage={leadPage.currentPage}
        onPageChange={(page) => pageChange(page)}
        totalEntries={leadPage.count}
        onEntryChange={(entries) => entryChange(entries)}
        currentCount={leads.length}
        targetCount={leadPgBtn.take}
      />

      {isEditLeadOpen && (
        <LeadsModal
          isOpen={isEditLeadOpen}
          onClose={onEditLeadClose}
          modalTitle="Edit Lead"
        />
      )}
    </Box>
  );
};

export default LeadsTable;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import ClientsOverview from '../Overview';
import ClientsContacts from '../Contacts';
import ClientsJobs from '../Jobs';
import ClientsAgreements from '../Agreement';
import ClientsSettings from '../Settings';
import ClientsActivityLog from '../ActivityLog';

import { getClient } from 'store/client.slice';

const ClientsAllTabs = ({ isLoading }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { allClient, clientData } = useSelector((state: any) => state.clients);

  const tabIndex: any = {
    overview: 0,
    contacts: 1,
    jobs: 2,
    agreements: 3,
    settings: 4,
    'activity-log': 5,
  };

  const stateTab: any = params.clientsTab;
  const clientID = params.clientsId;

  useEffect(() => {
    if (clientID) {
      const getClientData = allClient.filter(
        (client: any) => client.id === Number(clientID)
      )[0];
      dispatch(getClient({ clientData: getClientData }));
    }
  }, [allClient, clientID, dispatch]);

  const handleRoute = (route: string) => {
    navigate(`/clients/${clientData?.id}/${route}`);
  };
  return (
    <Tabs colorScheme="purple" defaultIndex={tabIndex[stateTab]}>
      <TabList fontSize="md" fontWeight="bold">
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('overview')}
        >
          Overview
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('contacts')}
        >
          Contacts
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('jobs')}
        >
          Jobs
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('agreements')}
        >
          Agreements
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('settings')}
        >
          Settings
        </Tab>
        <Tab
          _focus={{ boxShadow: 'none !important' }}
          onClick={() => handleRoute('activity-log')}
        >
          Activity Logs
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <ClientsOverview isLoading={isLoading} />
        </TabPanel>
        <TabPanel>
          <ClientsContacts />
        </TabPanel>
        <TabPanel>
          <ClientsJobs />
        </TabPanel>
        <TabPanel>
          <ClientsAgreements />
        </TabPanel>
        <TabPanel>
          <ClientsSettings />
        </TabPanel>
        <TabPanel>
          <ClientsActivityLog />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ClientsAllTabs;

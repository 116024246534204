import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Box,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';

import {
  useDelAgreementAttachmentMutation,
  removeAttachment,
} from 'store/leadagreement.sclice';

import { AtsConfirm } from 'utils/swal';
import { downloadLink } from 'utils/downloadlink';

import { LeadAgreementAttachmentTypes, swalContent } from 'types';

import GoTo from '../../../../assets/images/goto.svg';
import { useState } from 'react';
import StreamAttachmentModal from '../Attachments/stream';

interface leadAgreementAttachmentInt {
  attachments: LeadAgreementAttachmentTypes[];
}

const LeadAgreementAttachments = ({
  attachments,
}: leadAgreementAttachmentInt) => {
  const tableHeader: { label: string }[] = [
    { label: 'File Name' },
    { label: 'Uploaded By' },
    { label: 'Uploaded On' },
    { label: '' },
  ];

  const dispatch = useDispatch();

  const [fileData, setFileData] = useState(null);

  const [reqdel] = useDelAgreementAttachmentMutation();

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this file.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const {
    isOpen: isStreamOpen,
    onOpen: onStreamOpen,
    onClose: onStreamClose,
  } = useDisclosure();

  const delAttachment = async (id: number, key: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqdel({ id });
      dispatch(removeAttachment(key));
    }
  };

  return (
    <Box>
      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {attachments?.map(
                (item: LeadAgreementAttachmentTypes, key: number) => (
                  <Tr
                    key={item.original_file_name}
                    cursor="pointer"
                    onClick={() => {}}
                  >
                    <Td>
                      <Flex alignItems="center" color="primary.800">
                        <Link
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                          onClick={() => {
                            onStreamOpen();
                            setFileData(item);
                          }}
                        >
                          {item.original_file_name}
                          <span>
                            <Image src={GoTo} alt="logo" />
                          </span>
                        </Link>
                      </Flex>
                    </Td>
                    <Td></Td>
                    <Td>
                      {moment.utc(item.created_at).format('MM/DD/YYYY hh:mm A')}
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton onClick={() => delAttachment(item.id, key)}>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="trash"
                            iconSize="xl"
                          />
                        </MenuButton>
                      </Menu>
                    </Td>
                  </Tr>
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {isStreamOpen && (
        <StreamAttachmentModal
          isOpen={isStreamOpen}
          onClose={onStreamClose}
          file={fileData}
          route={'/lead-agreement/download/'}
        />
      )}
    </Box>
  );
};

export default LeadAgreementAttachments;

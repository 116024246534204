import {
  Box,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { navClient } from 'store/client.slice';
import { useNavigate } from 'react-router';
import ButtonV2 from 'Library/Button';
import {
  useGetJobsMutation,
  useToggleCampaignMutation,
} from 'store/jobs.slice';
import { useEffect } from 'react';

const JobsDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { jobData } = useSelector((state: any) => state.jobs);

  const [reqToggleCampaign, resToggleCampaign] = useToggleCampaignMutation();
  const [reqJobData] = useGetJobsMutation();

  const WorkType = (type: number) => {
    if (type === 1) return 'Remote';
    if (type === 2) return 'On Site';
    if (type === 3) return 'Hybrid';
    return <Box>{type}</Box>;
  };

  const CompensationType = (type: number) => {
    if (type === 1) return 'Hourly';
    if (type === 2) return 'Yearly';
    return <Box>{type}</Box>;
  };

  const EmploymentType = (type: number) => {
    if (type === 1) return 'Full-Time';
    if (type === 2) return 'Part-Time';
    if (type === 3) return 'Temporary';
    if (type === 4) return 'Contract';
    if (type === 5) return 'Internship';
    return <Box>{type}</Box>;
  };

  const EducationType = (type: number) => {
    if (type === 1) return `Bachelor's Degree`;
    if (type === 2) return `Master's Degree`;
    if (type === 3) return 'MBA';
    if (type === 4) return 'PhD';
    if (type === 5) return `Associate's Degree`;
    if (type === 6) return 'None';
    return <Box>{type}</Box>;
  };

  const ExperienceType = (type: number) => {
    if (type === 1) return '0 - 2 Years';
    if (type === 2) return '2 - 5 Years';
    if (type === 3) return '5+ Years';
    if (type === 4) return 'Manager';
    if (type === 5) return 'Director';
    if (type === 6) return 'Executive';
    if (type === 7) return 'Not Applicable';
    return <Box>{type}</Box>;
  };

  useEffect(() => {
    if (resToggleCampaign.isSuccess) {
      reqJobData({ id: jobData.id });
    }
  }, [jobData.id, reqJobData, resToggleCampaign.isSuccess]);

  return (
    <Box fontFamily="Proxima Nova Regular" p={5} pt={2}>
      <Flex gap="16px" mb="24px">
        <Flex
          direction="column"
          alignItems="center"
          pt="20px"
          pb="14px"
          width="249px"
          border="1px solid #718096"
          borderRadius="4px"
          bg="#F7FAFC"
          color="#2D3748"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {jobData.screen}
          </Box>
          <Box fontWeight={500} fontSize="14px" lineHeight="18px">
            Screen
          </Box>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          pt="20px"
          pb="14px"
          width="249px"
          border="1px solid #805AD5"
          borderRadius="4px"
          bg="#FAF5FF"
          color="#553C9A"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {jobData.submits}
          </Box>
          <Box fontWeight={500} fontSize="14px" lineHeight="18px">
            Submits
          </Box>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          pt="20px"
          pb="14px"
          width="249px"
          border="1px solid #D69E2E"
          borderRadius="4px"
          bg="#FFFFF0"
          color="#975A16"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {jobData.interview}
          </Box>
          <Box fontWeight={500} fontSize="14px" lineHeight="18px">
            Interviews
          </Box>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          pt="20px"
          pb="14px"
          width="249px"
          border="1px solid #3182CE"
          borderRadius="4px"
          bg="#EBF8FF"
          color="#2C5282"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {jobData.offered}
          </Box>
          <Box fontWeight={500} fontSize="14px" lineHeight="18px">
            Offer
          </Box>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          pt="20px"
          pb="14px"
          width="249px"
          border="1px solid #38A169"
          borderRadius="4px"
          bg="#F0FFF4"
          color="#285E61"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {jobData.hired}
          </Box>
          <Box fontWeight={500} fontSize="14px" lineHeight="18px">
            Hired
          </Box>
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          pt="20px"
          pb="14px"
          width="249px"
          border="1px solid #E53E3E"
          borderRadius="4px"
          bg="#FFF5F5"
          color="#9B2C2C"
        >
          <Box
            fontWeight={700}
            fontSize="20px"
            lineHeight="18px"
            marginBottom="8px"
          >
            {jobData.rejected}
          </Box>
          <Box fontWeight={500} fontSize="14px" lineHeight="18px">
            Rejected
          </Box>
        </Flex>
      </Flex>
      <Flex gap={7}>
        <Box width="40%">
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Client
            </Box>
            <Box
              onClick={() => {
                dispatch(navClient({ clientNav: 'all-clients' }));
                navigate(`/clients/${jobData.client?.id}/overview`);
              }}
            >
              <Link
                style={{
                  fontWeight: 400,
                  fontSize: '16px',
                }}
                rel="noreferrer"
              >
                {jobData.client?.name}
              </Link>
            </Box>
          </Flex>
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Contact
            </Box>
            <Box
              onClick={() => {
                if (jobData.lead?.leadType.id === 4) {
                  dispatch(navClient({ clientNav: 'contacts' }));
                  navigate(`/clients/${jobData.lead?.id}/overview`);
                } else if (jobData.lead?.leadType.id === 1) {
                  navigate(`/leads/${jobData.lead?.id}/details`);
                  dispatch(navClient({ clientNav: 'leads' }));
                }
              }}
            >
              <Link
                style={{
                  fontWeight: 400,
                  fontSize: '16px',
                }}
                rel="noreferrer"
              >
                {[jobData.lead?.first_name, jobData.lead?.last_name].join(' ')}
              </Link>
            </Box>
          </Flex>
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              No. of Openings
            </Box>
            <Box style={{ fontSize: '16px' }}>{jobData?.opening}</Box>
          </Flex>
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Experience
            </Box>
            <Box style={{ fontSize: '16px' }}>
              {ExperienceType(jobData?.experience_level_id)}
            </Box>
          </Flex>
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Education
            </Box>
            <Box style={{ fontSize: '16px' }}>
              {EducationType(jobData?.education_id)}
            </Box>
          </Flex>
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Work Type
            </Box>
            <Box style={{ fontSize: '16px' }}>
              {WorkType(jobData?.work_type_id)}
            </Box>
          </Flex>
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Employment Type
            </Box>
            <Box style={{ fontSize: '16px' }}>
              {EmploymentType(jobData?.employment_type_id)}
            </Box>
          </Flex>
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Compensation Type
            </Box>
            <Box style={{ fontSize: '16px' }}>
              {CompensationType(jobData?.compensation_id)}
            </Box>
          </Flex>
          <Flex py={3} gap="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Salary Range
            </Box>
            <Box style={{ fontSize: '16px' }}>
              {jobData?.salary_range_start !== -1 &&
                `$ ${jobData?.salary_range_start?.toLocaleString()}`}
              {jobData?.salary_range_start !== -1 &&
                ` - $ ${jobData?.salary_range_end?.toLocaleString()}`}
            </Box>
          </Flex>
          <Flex py={3} gap="50px" mb="50px">
            <Box
              fontSize="16px"
              lineHeight="18px"
              color="default.gray.600"
              textAlign="left"
              width="180px"
            >
              Campaign Status
            </Box>
            <Menu closeOnSelect={true}>
              <MenuButton>
                <Box
                  sx={{
                    button: {
                      p: '4px 8px',
                      pl: 0,
                      height: '25px',
                      fontSize: '14px',
                      border: 'none',
                      color: 'primary.500',
                    },
                  }}
                >
                  <ButtonV2 rightIcon="chevron-down">
                    {jobData.is_campaign ? 'Active' : 'Inactive'}
                  </ButtonV2>
                </Box>
              </MenuButton>
              <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
                <MenuItem
                  onClick={async () => {
                    await reqToggleCampaign({
                      data: {
                        campaign: true,
                      },
                      id: jobData.id,
                    });
                  }}
                >
                  Active
                </MenuItem>
                <MenuItem
                  onClick={async () => {
                    await reqToggleCampaign({
                      data: {
                        campaign: false,
                      },
                      id: jobData.id,
                    });
                  }}
                >
                  Inactive
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>
        <Box width="60%">
          {jobData.description ? (
            <Box>
              <Box
                sx={{
                  pt: 3,
                  fontSize: '15px',
                  fontWeight: 700,
                  mb: '24px',
                }}
              >
                Job Description
              </Box>
              {parse(jobData.description)}
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 700,
                mt: '130px',
              }}
            >
              No Job Description Available
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default JobsDetails;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import moment from 'moment';

import {
  Box,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Text,
  Checkbox,
  Link,
  Stack,
  Progress,
  Tag,
  useToast,
  HStack,
  TagLabel,
  TagRightIcon,
  Tooltip,
} from '@chakra-ui/react';

import Button from '../../../Library/Button';
// import JobsTabs from './JobsTabs';

import {
  addToParsingQueue,
  removeFromParsingQueue,
  uploadList,
  setParsingProgress,
} from 'store/uploads.slice';

import {
  useDownloadCandidateAttachmentMutation,
  useCreateCandidateAttachmentMutation,
  useExtractResumeMutation,
  useListResumeMutation,
} from 'store/candidateattachment.slice';
import { UploadTypes } from 'types';
import {
  getCandidate,
  listCandidates,
  useDeleteCandidatesMutation,
  useListCandidatesMutation,
  useRejectCandidateMutation,
} from 'store/candidates.slice';
import { JobsData, JobsTypes, swalContent } from 'types';
import FAIcon from 'components/lib/FAIcon';
import { AtsConfirm } from 'utils/swal';
import { authStorage } from 'utils/localStorage';
import axios from 'axios';
import { BASE_URL, SOCKET_URL } from 'constants/values';
import {
  listBatch,
  useGetParseListMutation,
  useReParseMutation,
} from 'store/resumerparse.slice';
import { wsScoket } from 'common/ws';

import { RepeatIcon } from '@chakra-ui/icons';
import ManualResumeModal from '../Candidates/Modals/manualResume';
import Pagination from 'Library/Pagination';
import AtsSelect from '../AtsSelect';
import ParseLoading from './loading';

interface ParseQueueProps {
  isOpen: boolean;
  onClose: any;
}

function ParseQueue({ isOpen, onClose }: ParseQueueProps) {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const { batches, batchesPgBtn, batchesPage } = useSelector(
    (state: any) => state.resumeParse
  );

  const [reqBatch, resBatch] = useGetParseListMutation();
  const [totalProcessing, setTotalProcessing] = useState(0);
  const [reqParse, resParse] = useReParseMutation();

  const {
    isOpen: isOpenManual,
    onOpen: onOpenManual,
    onClose: onCloseManual,
  } = useDisclosure();

  const [resumeData, setResumeData] = useState({});

  useEffect(() => {
    if (batches.length > 0) {
      let total = 0;
      Promise.all(
        batches.map(async (item: any) => {
          if (!item.is_parse) {
            total++;
          }
        })
      );

      setTotalProcessing(total);
    } else {
      setTotalProcessing(0);
    }
  }, [resBatch.isSuccess, batches]);

  const fetchBatch = (params: any) => {
    dispatch(
      listBatch({
        batchesPgBtn: params,
      })
    );
    reqBatch(params);
  };

  useEffect(() => {
    let newParam = {
      page: 1,
      take: batchesPgBtn.take,
      status: batchesPgBtn.status,
    };
    fetchBatch(newParam);
  }, []);

  const reParse = (id: any) => {
    const data = {
      tags: [] as any,
      source_name: '',
    };
    reqParse({ data, id }).then(() => {
      fetchBatch(batchesPgBtn);
    });
  };

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: batchesPgBtn.take,
      status: batchesPgBtn.status,
    };
    fetchBatch(newParam);
  };

  const entryChange = (entries: number) => {
    let newParam = {
      page: 1,
      take: entries,
      status: batchesPgBtn.status,
    };
    fetchBatch(newParam);
  };

  const statusChange = (event: any) => {
    let newParam = {
      page: 1,
      take: batchesPgBtn.take,
      status: event.value,
    };
    fetchBatch(newParam);
  };

  const statusOption: any = [
    {
      label: 'All',
      value: null,
    },
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Processing',
      value: 'processing',
    },
    {
      label: 'Success',
      value: 'success',
    },
    {
      label: 'Failed',
      value: 'error',
    },
  ];

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size="sm">
        <DrawerOverlay top="60px" />
        <DrawerContent
          maxW="30vw"
          top="62px! important"
          fontFamily="Proxima Nova Regular"
        >
          <DrawerCloseButton
            position="absolute"
            top="0px"
            left="-40px"
            borderRadius="unset"
            p="20px"
            bg="#E6E9EE"
            color="default.secondarytext"
            _hover={{
              backgroundColor: 'none',
            }}
          />

          <DrawerBody p="0">
            <Box
              fontSize="20px"
              fontWeight="600"
              color="default.primarytext"
              p="5"
            >
              <Flex>
                Parsing
                <Flex
                  color="primary.800"
                  fontSize="20px"
                  mt="auto"
                  mb="auto"
                  verticalAlign="center"
                  ml="2"
                >
                  <FAIcon iconName="hourglass-start" />
                </Flex>
                <Text
                  color="primary.800"
                  fontSize="18px"
                  fontWeight="bold"
                  ml="2px"
                >
                  {totalProcessing}
                </Text>
              </Flex>
            </Box>
            <Box
              fontSize="15px"
              fontWeight="600"
              pl="5"
              pr="5"
              pt="5"
              color="default.primarytext"
            >
              Parsed Resume List
            </Box>
            <Box
              fontSize="15px"
              fontWeight="600"
              pl="5"
              pr="5"
              pt="5"
              color="default.primarytext"
            >
              <AtsSelect
                options={statusOption}
                defaultValue={statusOption[0]}
                onChange={(event: any) => statusChange(event)}
              />
            </Box>
            {resBatch.isLoading ? (
              <ParseLoading />
            ) : (
              <Box gap="10px" p="5" minHeight="68vh">
                {batches.length > 0 ? (
                  batches.map((item: any, key: number) => {
                    return (
                      <Box mb="12px" key={key}>
                        <HStack spacing={4}>
                          <Link
                            onClick={() => {
                              onClose();
                              dispatch(
                                getCandidate({ candidateData: item?.candidate })
                              );
                              navigate(
                                `/candidates/1/${item?.candidate_id}/about`
                              );
                            }}
                          >
                            <Text color="primary.800" fontSize="12px">
                              {item.original_file_name}{' '}
                            </Text>
                          </Link>
                          {item.status === 'pending' && (
                            <Button size="xs" onClick={() => reParse(item.id)}>
                              <RepeatIcon />
                            </Button>
                          )}
                        </HStack>
                        <Box mt="5px">
                          <HStack spacing={4}>
                            {item.status === 'pending' && (
                              <Tag variant="outline" colorScheme="gray">
                                Pending
                              </Tag>
                            )}
                            {item.status === 'processing' && (
                              <Tag variant="outline" colorScheme="gray">
                                Processing
                              </Tag>
                            )}
                            {item.status === 'error' && (
                              <Flex gap="10px" alignItems="center">
                                <Tag variant="outline" colorScheme="red">
                                  Failed
                                </Tag>
                                <Tooltip label="Manual Resume">
                                  <Box
                                    sx={{
                                      cursor: 'pointer',
                                      color: '#1b7e8c',
                                    }}
                                    onClick={() => {
                                      onOpenManual();
                                      setResumeData(item);
                                    }}
                                  >
                                    <Flex fontSize="26px">
                                      <FAIcon
                                        iconName="clipboard"
                                        alt="Manual"
                                      />
                                    </Flex>
                                  </Box>
                                </Tooltip>
                                <Button
                                  size="xs"
                                  onClick={() => reParse(item.id)}
                                >
                                  <RepeatIcon />
                                </Button>
                              </Flex>
                            )}

                            {item.is_duplicate && (
                              <Tag variant="outline" colorScheme="blue">
                                <TagLabel>Duplicate</TagLabel>
                                <TagRightIcon>
                                  <FAIcon iconName="clone" />
                                </TagRightIcon>
                              </Tag>
                            )}
                            {item.status === 'success' && (
                              <Tag variant="outline" colorScheme="green">
                                Success
                              </Tag>
                            )}
                          </HStack>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box>
                    <Text>No resume currently parsing</Text>
                  </Box>
                )}
              </Box>
            )}

            <Box>
              <Pagination
                totalPages={batchesPage.lastPage}
                currentPage={batchesPage.currentPage}
                onPageChange={(page) => pageChange(page)}
                totalEntries={batchesPage.count}
                onEntryChange={(entries) => entryChange(entries)}
                currentCount={batches.length}
                targetCount={batchesPgBtn.take}
                showEntries={false}
              />
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {isOpenManual && (
        <ManualResumeModal
          isOpen={isOpenManual}
          onClose={onCloseManual}
          resumeData={resumeData}
        />
      )}
    </>
  );
}

export default ParseQueue;

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import '../../../../assets/css/autocomplete.css';

import { useGetJobsMutation } from 'store/jobs.slice';
import { useGetSubStatusMutation } from 'store/constant.slice';
import {
  getCandidate,
  useChangeSubStatusMutation,
  useGetCandidatesMutation,
  useListCandidatesMutation,
  useRejectCandidateMutation,
  useUnRejectCandidateMutation,
} from 'store/candidates.slice';

import Button from 'Library/Button';
import CandidateJobList from './CandidateJobList';
import CandidatesInterviewModal from '../Interviews/modal';
import RejectModal from 'components/app/Jobs/ViewCandidates/form/rejectModal';
import SubmitToClientModal from 'components/app/Jobs/ViewCandidates/form/SubmitToClientModal';
import { CandidatesTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import LoadingPage from 'components/app/Loading';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import { useListCandidateFilterMutation, usePinListCandidateFilterMutation } from 'store/candidatefilter.slice';
import HiredModal from 'components/app/Jobs/ViewCandidates/form/HiredModal';
import { setClientAgreement, useListClientAgreementMutation } from 'store/clientagreement.slice';

interface jobselectInt {
  id: number;
  type: string;
  assoc_id?: number;
  reject?: string;
}

const ActionButton = ({
  rowId,
  setRowId,
  totalCandidates,
  onClose,
  isLoadingDrawer,
  candidates,
}: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    isOpen: isOpenJobList,
    onClose: onCloseJobList,
    onOpen: onOpenJobList,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenSubmit,
    onClose: onCloseSubmit,
    onOpen: onOpenSubmit,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenScheduleInterview,
    onOpen: onOpenScheduleInterview,
    onClose: onCloseScheduleInterview,
  } = useDisclosure();

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const param = useParams();
  const stateTab: any = param.candidatesTab;

  const {
    candidateData,
    candidatePgBtn,
    candidatePage,
    candidateDataJobId,
    candidateOthers,
  } = useSelector((state: any) => state.candidates);
  const { clientAgreementPgBtn } =
    useSelector((state: any) => state.clientAgreements);
  const { candidate_status } = useSelector((state: any) => state.constants);

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  const [reqPair] = usePlaceHolderPairMutation();

  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  const jobDetails =
    candidateDataJobId &&
    candidateData?.jobs?.filter(
      (job: any) => job.job_id === candidateDataJobId
    )[0];

  const { filter } = useSelector((state: any) => state.candidateFilters);
  const {
    isOpen: isOpenHired,
    onOpen: onOpenHired,
    onClose: onCloseHired,
  } = useDisclosure();
  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
      candidateOthers.length > 0 &&
      candidateOthers[0].group_by_job || filter
    );
  }, [candidateOthers, filter, candidateData]);

  // useEffect(() => {
  //   if (filter) {
  //     setIsGroupByJob(true);
  //   }
  // }, [filter, candidateData]);

  const [jobList, setJobList] = useState([]);
  const [hasSubmit, setHasSubmit] = useState(false);
  const [hasInterview, setHasInterview] = useState(false);
  const [hasReject, setHasReject] = useState(false);
  const [hasUnReject, setHasUnReject] = useState(false);
  const [actionType, steActionType] = useState('');
  const [jobId, setJobId] = useState(null);
  const [assocId, setAssocId] = useState(0);
  const [intSubj, setSubject] = useState('');
  const [rejectType, setRejectType] = useState('other');
  const [contactOptionValue, setContactOptionValue] = useState([]);
  const [statOption, setStatOption] = useState([]);

  const [reqjob] = useGetJobsMutation();
  const [reqAgreement, resAgreement] = useListClientAgreementMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqStatusChange, resStatusChange] = useChangeSubStatusMutation();
  const [reSub] = useGetSubStatusMutation();
  const [reqReject, resReject] = useRejectCandidateMutation();
  const [reqUnReject, resUnReject] = useUnRejectCandidateMutation();
  const [reqCandidates] = useListCandidatesMutation();
  const [reqGetJob] = useGetJobsMutation();

  const [hasStatus, setHasStatus] = useState(false);
  const [hasRejectButton, setHasRejectButton] = useState(false);
  const [isShow, setIsShow] = useState(isLoadingDrawer);
  const [submitData, setSubmitData] = useState({});


  const isLoading =
    resReject.isLoading || resUnReject.isLoading || resStatusChange.isLoading || resGetCandidate.isLoading;

  const submitToClient = async () => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    const data = {
      candidate_id: candidateData.id,
      job_id: param?.jobsId || null,
    } as any;
    await reqPair(data);
    steActionType('submit');

    if (isGroupByJob) {
      onJobSelect({
        id: Number(jobDetails?.job?.id),
        type: 'submit',
        assoc_id: jobDetails?.id,
        reject: jobDetails?.sub?.target,
      });
    } else {
      await Promise.all(
        candidateData?.jobs?.map(async (item: any) => {
          if (param?.jobsId) {
            if (
              item.candidate_status_id === 2 &&
              item?.job?.id == param?.jobsId
            ) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          } else {
            if (item.candidate_status_id === 2) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          }
        })
      );
      setJobList(jobs);

      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'submit',
          assoc_id: assoc?.id,
          reject: assoc?.sub?.target,
        });
      } else {
        onOpenJobList();
      }
    }
  };

  const scheduleInterview = async () => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    steActionType('interview');
    const data = {
      candidate_id: candidateData.id,
      job_id: param?.jobsId || null,
    } as any;
    await reqPair(data);
    if (isGroupByJob) {
      onJobSelect({
        id: Number(jobDetails?.job?.id),
        type: 'interview',
        assoc_id: jobDetails?.id,
        reject: jobDetails?.sub?.target,
      });
    } else {
      await Promise.all(
        candidateData?.jobs?.map(async (item: any) => {
          if (param?.jobsId) {
            if (
              (item.candidate_status_id === 3 ||
                item.candidate_status_id === 2 ||
                item.candidate_status_id === 1) &&
              item?.job?.id == param?.jobsId
            ) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          } else {
            if (
              item.candidate_status_id === 3 ||
              item.candidate_status_id === 2 ||
              item.candidate_status_id === 1
            ) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          }
        })
      );
      setJobList(jobs);

      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'interview',
          assoc_id: assoc?.id,
          reject: assoc?.sub?.target,
        });
      } else {
        onOpenJobList();
      }
    }
  };

  const rejectClick = async (data: any) => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    steActionType('reject');
    if (isGroupByJob) {
      handleSubmit(data, jobDetails?.id);
    } else {
      await Promise.all(
        candidateData?.jobs?.map(async (item: any) => {
          if (param?.jobsId) {
            if (
              item.candidate_status_id !== 6 &&
              item?.job?.id == param?.jobsId
            ) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          } else {
            if (item.candidate_status_id !== 6) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          }
        })
      );
      setJobList(jobs);

      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'reject',
          assoc_id: assoc?.id,
          reject: assoc?.sub?.target,
        });
        handleSubmit(data, assoc?.id);
      } else {
        onOpenJobList();
      }
    }
  };

  const callBackActionHired = () => {
    console.log("submitData", submitData)
    const form = {
      sub_id: submitData
    };

    reqStatusChange({ id: jobDetails?.id, data: form });
  };

  const callBackAction = () => {
    const candidateID = candidates.filter(
      (item: any) => item.rowId === rowId + 1
    )[0]?.id;
    const nextData = candidates.filter(
      (item: any) => item.rowId === rowId + 1
    )[0];

    if (rowId === totalCandidates || !candidateID) {
      onClose();
      if (param.candidatesTab) {
        navigate('/candidates');
      }
    } else if (stateTab) {
      setRowId(rowId + 1);
      if (isGroupByJob && nextData?.jobs?.[0]) {
        navigate(`/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${nextData?.jobs?.[0]?.id}`);
      } else {
        navigate(
          `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}`
        );
      }
      reqGetCandidate({ id: candidateID });

      dispatch(
        getCandidate({
          candidateData: nextData,
          candidateDataJobId: nextData?.jobs[0]?.id,
        })
      );
    } else if (rowId !== totalCandidates) {
      reqGetCandidate({ id: candidateID });
    }
    // for actions within view candidates
    if (param['*'].includes('view-candidates')) {
      onClose()
    }
  };

  const onJobSelect = (data: jobselectInt) => {
    setAssocId(data.assoc_id);
    setRejectType(data.reject);
    if (data.type === 'submit' || data.type === 'interview') {
      reqjob({ id: data.id }).then((res: any) => {
        setJobId(data.id);
        const job = res?.data?.data;
        if (data.type === 'submit') {
          onOpenSubmit();
        }
        if (data.type === 'interview') {
          const client_name = job?.data?.client ? job?.data?.client?.name : '';
          const subject =
            'Video Interview' +
            ' | ' +
            job?.data?.title +
            ' | ' +
            candidateData?.first_name +
            ' ' +
            candidateData?.last_name +
            '/' +
            client_name;

          setSubject(subject);
          let contactOption: any = [];
          // if (job?.data?.lead?.leadStatus?.id !== 5) {
          contactOption = [
            {
              label: [
                job?.data?.lead?.first_name,
                job?.data?.lead?.last_name,
              ].join(' '),
              value: job?.data?.lead?.id,
            },
          ];
          // }
          setContactOptionValue(contactOption);
          onOpenScheduleInterview();
        }
      });
    }

    if (data.type === 'reject' && param.candidatesTab && !isGroupByJob) {
      onOpenReject();
    }

    if (data.type === 'unreject' && param.candidatesTab && !isGroupByJob) {
      reqUnReject({ data: [], id: data.assoc_id });
    }
  };

  const handleStatus = async (data: any) => {
    const form = {
      sub_id: (data?.sub_id && data?.sub_id?.value) || data,
    };
    if (form.sub_id === 8) {
      console.log("went here")
      setSubmitData(data);

      let clientID = null as any
      candidateData?.jobs.map(async (job: any, index: number) => {
        console.log("jobDetails", jobDetails)
        console.log("param.jobsId", param.jobsId)
        console.log("job", job)
        if (job?.job_id == param.jobsId || job?.job_id == jobDetails?.job_id) {
          if (job?.job?.client?.id) {
            clientID = job?.job?.client?.id
          }
          console.log("candidateData", candidateData)
          if (!candidateData?.source?.source) {
            await reqGetCandidate({ id: candidateData?.id })
          }
          if (jobDetails?.job_id !== candidateData?.jobs?.[index]?.job_id && !jobDetails?.lead?.lead_source?.source) {
            await reqGetJob({ id: candidateData?.jobs?.[index]?.job_id });
          }
          if (clientID) {
            console.log("clientID", clientID)
            let newParam = {
              page: 1,
              take: clientAgreementPgBtn.take,
              query: '',
              status: clientAgreementPgBtn.status,
              types: clientAgreementPgBtn.types,
            };
            dispatch(
              setClientAgreement({
                leadAgreementPgBtn: newParam,
              })
            );
            await reqAgreement({ data: newParam, id: clientID });
          }

          onOpenHired();

        }
      });

    }
    else if (isGroupByJob) {
      await reqStatusChange({ id: Number(jobDetails?.id), data: form });
    } else if (candidateData?.jobs?.length > 0) {
      candidateData?.jobs.map(async (job: any) => {
        if (job?.job_id == param.jobsId) {
          await reqStatusChange({ id: Number(job?.id), data: form });

        }
      });
    }
  };

  const handleSubmit = async (data: any, id: any) => {
    await reqReject({
      id: id,
      data: {
        reason: data,
      },
    });
  };

  useEffect(() => {
    if (candidate_status?.length > 0) {
      let groups: any = [];
      candidate_status.map((item: any) => {
        if (item?.sub?.length > 0) {
          let option: any = [];
          item?.sub?.map((sub: any) => {
            option.push({
              label: sub.sub_status,
              value: sub.id,
            });
          });

          groups.push({
            label: item.status,
            options: option,
          });
        }
      });
      setStatOption(groups);
    }
  }, [candidate_status]);

  useEffect(() => {
    if (!isOpenScheduleInterview) {
      setContactOptionValue([]);
    }
  }, [isOpenScheduleInterview]);

  useEffect(() => {
    if (isGroupByJob && jobDetails) {
      if (jobDetails.candidate_status_id === 2) {
        setHasSubmit(true);
      }
      if (
        jobDetails.candidate_status_id === 3 ||
        jobDetails.candidate_status_id === 2 ||
        jobDetails.candidate_status_id === 1
      ) {
        setHasInterview(true);
        setHasReject(true);
        setHasUnReject(false);
      }
      if (
        jobDetails.candidate_status_id === 3 ||
        jobDetails.candidate_status_id === 4 ||
        jobDetails.candidate_status_id === 5
      ) {
        setHasReject(true);
        setHasUnReject(false);
      }
      if (jobDetails.candidate_status_id === 6) {
        setHasUnReject(true);
        setHasRejectButton(false);
        setHasReject(false);
        setHasInterview(false);
      }
    } else {
      candidateData?.jobs?.map(async (item: any) => {
        if (param?.jobsId) {
          if (item.job_id == param?.jobsId) {
            const hasSubmit = item.candidate_status_id === 2 ? true : false;
            setHasSubmit(hasSubmit);
            setHasInterview(false);

            if (
              item.candidate_status_id === 3 ||
              item.candidate_status_id === 2 ||
              item.candidate_status_id === 1
            ) {
              setHasInterview(true);
              setHasReject(true);
            }
            if (
              item.candidate_status_id === 3 ||
              item.candidate_status_id === 4 ||
              item.candidate_status_id === 5
            ) {
              setHasReject(true);
            }
            if (item.candidate_status_id === 6) {
              setHasUnReject(true);
              setHasInterview(false);
            }
          }
        } else {
          if (item.candidate_status_id === 2) {
            setHasSubmit(true);
          }
          if (
            item.candidate_status_id === 3 ||
            item.candidate_status_id === 2 ||
            item.candidate_status_id === 1
          ) {
            setHasInterview(true);
            setHasReject(true);
          }
          if (
            item.candidate_status_id === 3 ||
            item.candidate_status_id === 4 ||
            item.candidate_status_id === 5
          ) {
            setHasReject(true);
          }
          if (item.candidate_status_id === 6) {
            setHasUnReject(true);
            setHasInterview(false);
          }
        }
      });
    }
  }, [candidateData, isGroupByJob, jobDetails, param?.jobsId]);

  useEffect(() => {
    reSub({ id: 6 });
  }, [assocId]);


  useEffect(() => {
    if (
      resStatusChange.isSuccess ||
      resReject.isSuccess ||
      resUnReject.isSuccess
    ) {
      reqFilterList({})
      reqPinList({})
      let candidateID: any = null;
      let nextData: any = null;
      reqCandidates(candidatePgBtn);
      // INITIALIZE CANDIDATEID AND NEXTDATA FOR JOBS MODULE
      if (param.jobsId) {
        // reqCandidates(candidatePgBtn);
        candidateID = candidates[rowId - 1 + 1]?.id;
        nextData = candidates.filter(
          (data: CandidatesTypes) => data.id === candidateID
        );
        setRowId((prevKey: number) => prevKey + 1);

        reqGetJob({ id: param.jobsId || jobId });
      }
      // INITIALIZE CANDIDATEID AND NEXTDATA FOR CANDIDATES MODULE
      else {
        candidateID = candidates.filter(
          (item: any) => item.rowId === rowId + 1
        )[0]?.id;
        nextData = candidates.filter(
          (item: any) => item.rowId === rowId + 1
        )[0];
      }

      // CLOSE DRAWER WHEN ROWID IS AT THE END
      if (rowId === totalCandidates || !candidateID) {
        onClose();
        if (param.candidatesTab) {
          navigate('/candidates');
        } if (param.jobsId) {
          console.log("`${param.jobsId}/${param.jobsTab}/${param.jobsTabId}`", `${param.jobsId}/${param.jobsTab}/${param.jobsTabId}`)
          // setTimeout(() => {
          navigate(-1);
          console.log("param", param)
          // }, 500)
        }
      }

      // FOR CANDIDATES MODULE
      else if (stateTab) {
        setRowId((prevKey: number) => prevKey + 1);

        if (isGroupByJob && nextData?.jobs?.[0]) {
          navigate(
            `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${nextData?.jobs?.[0]?.id}`
          );
        } else {
          navigate(
            `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}`
          );
        }
        reqGetCandidate({ id: candidateID })
        dispatch(
          getCandidate({
            candidateData: nextData,
            candidateDataJobId: nextData?.jobs?.[0]?.id,
          })
        );
      }
      // FOR JOBS MODULE
      else if (param.jobsId) {
        reqGetCandidate({ id: candidateID }).then((res: any) => {
          console.log("rowId ", rowId)
          console.log("went here to reqgetcandidate")
          navigate(
            `${param.jobsId}/${param.jobsTab}/${param.jobsTabId}/${candidateID}/${param.jobsDrawerTab}`
          );
          dispatch(
            getCandidate({
              candidateData: res?.data?.data?.data,
              candidateDataJobId: nextData?.jobs?.[0]?.id,
            })
          );
        });
      }
      else if (rowId !== totalCandidates) {
        reqGetCandidate({ id: candidateID });
      }
    }
  }, [resReject.isSuccess, resStatusChange.isSuccess, resUnReject.isSuccess]);

  const jobStatus = (candidate: any) => {
    try {
      if (isGroupByJob) {
        return jobDetails?.sub?.sub_status;
      }
      if (candidate?.jobs?.length > 0) {
        const data: any = candidate?.jobs?.filter(
          (job: any) => job.job_id === Number(param.jobsId || jobId || candidateDataJobId)
        )[0];
        return data?.sub?.sub_status;
      } else {
        return '--';
      }
    } catch (e) {
      return 'Error';
    }
  };

  const unRejectClick = async (candidate: any) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to unreject this candidate.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      let id = null;
      if (isGroupByJob) {
        id = jobDetails?.id;
      } else {
        let jobs = [] as any;
        let job_id = null as any;
        let assoc = null as any;

        steActionType('unreject');


        await Promise.all(
          candidateData?.jobs?.map(async (item: any) => {
            if (param?.jobsId) {
              if (
                item.candidate_status_id === 6 &&
                item?.job?.id == param?.jobsId
              ) {
                if (item?.job) {
                  jobs.push(item);
                  job_id = item?.job?.id;
                  assoc = item;
                }
              }
            } else {
              if (item.candidate_status_id === 6 && item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          })
        );
        setJobList(jobs);

        if (jobs.length === 1) {
          onJobSelect({
            id: Number(job_id),
            type: 'unreject',
            assoc_id: assoc?.id,
            reject: assoc?.sub?.target,
          });
          id = assoc?.id;
        } else {
          onOpenJobList();
        }
      }

      id && await reqUnReject({ data: [], id });
    }
  };

  useEffect(() => {
    if (param.candidatesTab && isGroupByJob && filter && candidateDataJobId) {
      setHasStatus(true);
    }

    if (param.jobsTabId && param.jobsTabId !== 'candidate-pool') {
      setHasStatus(true);
    }
  }, [
    candidateData,
    filter,
    candidateDataJobId,
    param.candidatesTab,
    param.jobsTabId,
  ]);

  useEffect(() => {
    if (hasReject && !hasUnReject) {
      console.log("param.candidatesTab", param.candidatesTab)
      console.log("isGroupByJob", isGroupByJob)
      console.log("filter", filter)
      if (param.candidatesTab && isGroupByJob && filter) {
        setHasRejectButton(true);
      }

      if (param.jobsTab && param.jobsTabId !== 'candidate-pool') {
        setHasRejectButton(true);
      }
    }
  }, [
    filter,
    hasReject,
    hasUnReject,
    isGroupByJob,
    param.candidatesTab,
    param.jobsTab,
    param.jobsTabId,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (isLoadingDrawer) {
        setIsShow(isLoadingDrawer);
      }
    }, 800);
  }, [isLoadingDrawer]);

  const rejectItems = () => {
    let jobs = [] as any;

    candidateData?.jobs?.map(async (item: any) => {
      if (param?.jobsId) {
        if (item.candidate_status_id !== 6 && item?.job?.id == param?.jobsId) {
          if (item?.job) {
            jobs.push(item);
          }
        }
      } else {
        if (item.candidate_status_id !== 6 && item?.job) {
          jobs.push(item);
        }
      }
    });

    return jobs.length;
  };

  const unRejectItems = () => {
    let jobs = [] as any;

    candidateData?.jobs?.map(async (item: any) => {
      if (param?.jobsId) {
        if (item.candidate_status_id === 6 && item?.job?.id == param?.jobsId) {
          if (item?.job) {
            jobs.push(item);
          }
        }
      } else {
        if (item.candidate_status_id === 6 && item?.job) {
          jobs.push(item);
        }
      }
    });

    return jobs.length;
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        isShow && (
          <>
            {hasStatus && (
              <Menu closeOnSelect={true}>
                <Flex>
                  <Box
                    sx={{
                      button: {
                        p: '4px',
                        height: '35px',
                        fontSize: '12px',
                        borderRight: 'none',
                        borderRadius: '6px 0 0 6px',
                      },
                    }}
                  >
                    <Button>{jobStatus(candidateData)}</Button>
                  </Box>
                  <MenuButton>
                    <Box
                      sx={{
                        button: {
                          p: '4px 8px',
                          height: '35px',
                          fontSize: '12px',
                          borderLeft: 'none',
                          borderRadius: '0 6px 6px 0',
                        },
                      }}
                    >
                      <Button rightIcon="chevron-down">| </Button>
                    </Box>
                  </MenuButton>
                </Flex>
                {
                  <MenuList
                    fontSize="sm"
                    maxHeight="120px"
                    overflowY="auto"
                    sx={{
                      '.chakra-menu__group__title': {
                        margin: '0.4rem 0.8rem',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '10px',
                        color: 'default.gray.400',
                        textTransform: 'uppercase',
                      },
                    }}
                  >
                    {statOption.map((val) => {
                      return (
                        <MenuGroup title={val.label}>
                          {val.options.map((op: any) => {
                            return (
                              <MenuItem onClick={() => handleStatus(op.value)}>
                                {op.label}
                              </MenuItem>
                            );
                          })}
                        </MenuGroup>
                      );
                    })}
                  </MenuList>
                }
              </Menu>
            )}
            {param.jobsTabId !== 'candidate-pool' && hasSubmit && (
              <Box
                sx={{
                  button: {
                    height: '35px',
                    fontSize: '12px',
                  },
                }}
              >
                <Button
                  leftIcon="arrow-right"
                  size="sm"
                  onClick={() => submitToClient()}
                >
                  Submit to Client
                </Button>
              </Box>
            )}
            {param.jobsTabId !== 'candidate-pool' && hasInterview && (
              <Box
                sx={{
                  button: {
                    height: '35px',
                    fontSize: '12px',
                  },
                }}
              >
                <Button
                  leftIcon="video"
                  size="sm"
                  onClick={() => scheduleInterview()}
                >
                  Schedule Interview
                </Button>
              </Box>
            )}
            {hasReject && param.candidatesTab && !isGroupByJob && (
              <Box
                sx={{
                  button: {
                    position: 'relative',
                    p: '4px 8px',
                    height: '35px',
                    fontSize: '12px',
                    borderRadius: '6px',
                  },
                }}
              >
                <Button
                  leftIcon="circle-xmark"
                  size="sm"
                  onClick={() => rejectClick(null)}
                >
                  <Box>Reject</Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-8px',
                      float: 'right',
                      background: '#7e5ec2',
                      borderRadius: '4px',
                      padding: '3px 5px',
                      display: 'flex',
                      fontSize: '10px',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'white',
                    }}
                  >
                    {rejectItems()}
                  </Box>
                </Button>
              </Box>
            )}
            {hasRejectButton && (
              <Menu closeOnSelect={true}>
                <Flex>
                  <Box
                    sx={{
                      button: {
                        p: '4px',
                        height: '35px',
                        fontSize: '12px',
                        borderRight: 'none',
                        borderRadius: '6px 0 0 6px',
                      },
                    }}
                  >
                    <Button onClick={() => rejectClick(14)}>
                      Recruiter Reject
                    </Button>
                  </Box>
                  <MenuButton>
                    <Box
                      sx={{
                        button: {
                          p: '4px 8px',
                          height: '35px',
                          fontSize: '12px',
                          borderLeft: 'none',
                          borderRadius: '0 6px 6px 0',
                        },
                      }}
                    >
                      <Button rightIcon="chevron-down">|</Button>
                    </Box>
                  </MenuButton>
                </Flex>
                {
                  <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
                    <MenuItem onClick={() => rejectClick(15)}>
                      Client Reject
                    </MenuItem>
                    <MenuItem onClick={() => rejectClick(16)}>
                      Candidate Not Interested
                    </MenuItem>
                  </MenuList>
                }
              </Menu>
            )}
            {param.jobsTabId !== 'candidate-pool' && hasUnReject && (
              <Box
                sx={{
                  button: {
                    position: 'relative',
                    p: '4px 8px',
                    height: '35px',
                    fontSize: '12px',
                    borderRadius: '6px',
                  },
                }}
              >
                <Button
                  leftIcon="circle-xmark"
                  size="sm"
                  onClick={() => unRejectClick(candidateData)}
                >
                  <Box>Un - Reject</Box>
                  {param.candidatesTab && !isGroupByJob && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-8px',
                        float: 'right',
                        background: '#7e5ec2',
                        borderRadius: '4px',
                        padding: '3px 5px',
                        display: 'flex',
                        fontSize: '10px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'white',
                      }}
                    >
                      {unRejectItems()}
                    </Box>
                  )}
                </Button>
              </Box>
            )}
            {isOpenJobList && (
              <CandidateJobList
                jobs={jobList}
                isOpen={isOpenJobList}
                onClose={onCloseJobList}
                onSelect={(e: any) => onJobSelect(e)}
                type={actionType}
              />
            )}
            {isOpenSubmit && (
              <SubmitToClientModal
                isOpen={isOpenSubmit}
                onClose={onCloseSubmit}
                candidate={candidateData}
                job_id={jobId}
                callback={() => {
                  callBackAction();
                }}
              />
            )}
            {isOpenScheduleInterview && (
              <CandidatesInterviewModal
                isOpen={isOpenScheduleInterview}
                onClose={onCloseScheduleInterview}
                associate={assocId}
                subject={intSubj}
                isJobView={true}
                jobsId={jobId}
                contactOptionValue={contactOptionValue}
                callback={() => {
                  callBackAction();
                }}
              />
            )}
            {isOpenReject && (
              <RejectModal
                isOpen={isOpenReject}
                onClose={onCloseReject}
                jobsId={jobId}
                id={assocId}
                rejectType={rejectType}
                callback={() => {
                  callBackAction();
                }}
              />
            )}
            {isOpenHired && (
              <HiredModal
                isOpen={isOpenHired}
                // onCloseStatus={onClose}
                onClose={onCloseHired}
                candidate={candidateData}
                onCallback={callBackActionHired}
              />
            )}

          </>
        )
      )}
    </>
  );
};

export default ActionButton;

import {
  Box,
  HStack,
  SimpleGrid,
  Skeleton,
  Stack,
  Td,
  Tr,
} from '@chakra-ui/react';

interface BoxInterface {
  box: number;
  width?: string;
}

export default function BoxLoading({ box, width = '50px' }: BoxInterface) {
  return (
    <SimpleGrid spacing="12px" minChildWidth={width}>
      {Array.from(Array(box), (e, i) => {
        return (
          <Box w={width}>
            <Skeleton height="30px" />
          </Box>
        );
      })}
    </SimpleGrid>
  );
}

import Select from 'react-select';
export default function AtsSelect(FieldProps: any) {
  return (
    <Select
      options={FieldProps.options}
      {...FieldProps.field}
      onChange={(option) => {
        if (typeof FieldProps.onChange === 'function') {
          FieldProps.onChange(option);
        }
      }}
      isMulti={FieldProps.isMulti}
      defaultValue={FieldProps.defaultValue || FieldProps.value}
      value={FieldProps.value}
      isLoading={FieldProps.isLoading}
      placeholder={FieldProps.placeholder}
      menuIsOpen={FieldProps.menuIsOpen}
      styles={FieldProps.styles}
      menuPosition={FieldProps.menuPosition}
      isSearchable={FieldProps.isSearchable}
      onFocus={FieldProps.onFocus}
      onBlur={FieldProps.onBlur}
      onMenuClose={FieldProps.onMenuClose}
      onMenuOpen={FieldProps.onMenuOpen}
      components={FieldProps.components}
    />
  );
}

import CreatableSelect from 'react-select/creatable';

export default function AtsCraetableSelect(FieldProps: any) {
  return (
    <CreatableSelect
      {...FieldProps.field}
      onChange={(option) => {
        // FieldProps.form.setFieldValue(FieldProps.field.name, option);
        if (typeof FieldProps.onChange === 'function') {
          FieldProps.onChange(option);
        }
      }}
      options={FieldProps.options}
      placeholder={FieldProps.placeholder}
      defaultValue={FieldProps.defaultValue}
      isMulti={FieldProps.isMulti}
      isDisabled={FieldProps.isDisabled}
      onInputChange={FieldProps.onInputChange}
    />
  );
}

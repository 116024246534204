import { useSelector } from 'react-redux';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Text,
  Grid,
  Tooltip,
  SkeletonCircle
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';

import User from 'assets/images/user.png';

interface UserTableProp {
  handleEditClick: (id: number) => void;
}

const UserTable = ({ handleEditClick }: UserTableProp) => {
  const { userList } = useSelector((state: any) => state.user);
  const default_pic = User;

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap={2} p={6}>
      {Object.values(userList).length > 0 &&
        Object.values(userList).map((user: any, key: number) => {
          return (
            <Flex
              key={key}
              flexBasis="25%"
              bg="#fff"
              p="10px"
              borderRadius="5px"
              gap="10px"
            >
              {user?.profile_picture && user?.profile_picture !== null && user?.profile_picture !== "" ?
                user?.thumbnailSignedUrl ?
                  <Image
                    src={user?.thumbnailSignedUrl}
                    border="5px solid"
                    borderColor="#fff"
                    width="68px"
                    height="68px"
                    borderRadius="50%"
                    objectFit="contain"
                  />
                  :
                  <Box gap="10px" p="10px">
                    <SkeletonCircle size="50" />
                  </Box>

                : <Image
                  src={default_pic}
                  border="5px solid"
                  borderColor="#fff"
                  width="68px"
                  height="68px"
                  borderRadius="50%"
                  objectFit="contain"
                />
              }

              <Flex
                w="100%"
                justifyContent="space-between"
                alignItems="flex-start"
                mt="5px"
                gap="20px"
              >
                <Flex gap="10px" alignItems="flex-start" wordBreak="break-all">
                  <Tooltip
                    label={user.isActive === 1 ? 'Active' : 'Deactivate'}
                  >
                    <Box
                      fontSize="12px"
                      color={user.isActive === 1 ? '#75B42D' : '#D5605D'}
                      mt="3px"
                    >
                      <FAIcon iconName="circle" />
                    </Box>
                  </Tooltip>
                  <Box>
                    <Text
                      fontWeight="600"
                      color="default.primarytext"
                      fontSize="md"
                    >
                      {[user.first_name, user.last_name].join(' ')}
                    </Text>
                    <Text color="gray.400" fontSize="sm" fontWeight="500">
                      {user.email}
                    </Text>
                    <Text
                      color="default.secondarytext"
                      fontSize="sm"
                      fontWeight="500"
                    >
                      Admin
                    </Text>
                  </Box>
                </Flex>
                <Menu>
                  <MenuButton mr="10px">
                    <Button
                      htmlType="menu"
                      variant="ghost"
                      iconName="ellipsis-h"
                      iconSize="xl"
                    />
                  </MenuButton>

                  <MenuList fontSize="sm">
                    <MenuItem
                      onClick={() => {
                        handleEditClick(user.id);
                      }}
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="pencil" />
                        <Box ml={12} cursor="pointer">
                          Edit
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem color="caution.800">
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="ban" />
                        <Box ml={12} cursor="pointer">
                          Deactivate
                        </Box>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </Flex>
          );
        })}
    </Grid>
  );
};

export default UserTable;

import { Tooltip } from 'react-tooltip';
import moment from 'moment';

import {
  Box,
  Checkbox,
  Flex,
  Link,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Td,
  Tr,
  UnorderedList,
  MenuItem,
  Badge,
  useDisclosure,
  Tag,
} from '@chakra-ui/react';

import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';

import CandidateEmail from '../Modals/Email';

import { useDispatch, useSelector } from 'react-redux';
import {
  getCandidate,
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useGetJobsMutation } from 'store/jobs.slice';
import CandidateJobList from './CandidateJobList';
import SubmitToClientModal from 'components/app/Jobs/ViewCandidates/form/SubmitToClientModal';
import { CandidateData, candidateEmail, candidateJobs } from 'types';
import ChangeStatusModal from 'components/app/Jobs/ViewCandidates/form/ChangeStatusModal';

interface jobselectInt {
  id: number;
  type: string;
  assoc_id?: number;
  reject?: string;
  candidate?: CandidateData;
}

interface CandidateTrInterface {
  candidate: CandidateData;
  handleCheck: any;
  isCheck: any;
  handleClick: any;
  associateToJob: any;
  addTagClick: any;
  editCandidate: any;
  handleDeleteCandidate: (e: any) => void;
  handleHideCandidate: (e: any) => void;
  HandleMenuItem?: any;
  fromJob?: boolean;
  hasStatus?: boolean;
  isCheckWithJob?: any;
  fromGroupByJobs?: boolean;
  key?: any;
}
const CandidateTR = ({
  candidate,
  handleCheck,
  isCheck,
  handleClick,
  associateToJob,
  addTagClick,
  editCandidate,
  handleDeleteCandidate,
  handleHideCandidate,
  HandleMenuItem,
  fromJob = false,
  hasStatus = false,
  isCheckWithJob = [],
  fromGroupByJobs = false,
  key = 0,
}: CandidateTrInterface) => {
  let emails = candidate?.emails?.filter(
    (email: candidateEmail) => email.primary
  );
  emails = emails.length === 0 ? candidate?.emails : emails;
  const param = useParams();
  const dispatch = useDispatch();
  const [reqPair] = usePlaceHolderPairMutation();

  const jobsPopulate = (data: any[], id: any) => {
    const memoizedList = (key: number, less?: boolean, color?: boolean) => {
      return (
        <UnorderedList>
          {data?.map((job: any, index: number) => {
            return (
              <>
                {(less ? index < key : index > key) && (
                  <ListItem key={`memoizedlist-${key}`}>
                    <Link
                      href={`/jobs/${job?.id}/details`}
                      color={color && '#fff'}
                    >
                      {job?.title}
                    </Link>
                  </ListItem>
                )}
              </>
            );
          })}
        </UnorderedList>
      );
    };

    if (data.length > 3) {
      return (
        <>
          {memoizedList(3, true)}
          <Badge
            id={`jobTooltip${id}`}
            colorScheme="purple"
            border="0.5px solid #44337a"
            borderRadius="4px"
            height="fit-content"
          >
            + {data.length - 3}
          </Badge>
          <Tooltip anchorSelect={`#jobTooltip${id}`} place="bottom" clickable>
            {memoizedList(2, false, true)}
          </Tooltip>
        </>
      );
    }
    return memoizedList(-1);
  };

  const tagsPopulate = (data: string[], id: any) => {
    if (data.length > 3) {
      return (
        <>
          {data.slice(0, 3).join(', ')}
          <Badge
            id={`tagTooltip${id}`}
            colorScheme="purple"
            border="0.5px solid #44337a"
            borderRadius="4px"
            height="fit-content"
          >
            + {data.length - 3}
          </Badge>
          <Tooltip anchorSelect={`#tagTooltip${id}`} place="bottom">
            <Box maxWidth="200px" width="inherit" height="max-content">
              {data.slice(3).join(', ')}
            </Box>
          </Tooltip>
        </>
      );
    }
    return data.join(', ');
  };

  const jobStatus = (candidate: any) => {
    try {
      if (candidate?.jobs?.length > 0) {
        const data: any = candidate?.jobs[0];
        return <Tag variant="outline">{data?.sub?.sub_status}</Tag>;
      } else {
        return '--';
      }
    } catch (e) {
      return 'Error';
    }
  };

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  const openSendEmailClick = async (candidate: any) => {
    const job_id = candidateDataJobId || candidate?.jobs?.[0]?.id || param?.jobsId || null
    const data = {
      candidate_id: candidate?.id,
      job_id: (fromJob || fromGroupByJobs) ? job_id : null,
    } as any;
    await reqPair(data);
    onOpenSendEmail();
    await reqGetCandidate({ id: candidate?.id });
    if (candidate?.jobs.length > 0) {
      await reqjob({ id: candidate?.jobs?.[0]?.id });
    }
  };

  const [jobList, setJobList] = useState([]);
  const [actionType, steActionType] = useState('');
  const [jobId, setJobId] = useState(null);
  const [assocId, setAssocId] = useState(0);
  const [assocIdList, setAssocIdList] = useState([0]);
  const [mainStatus, setMainStatus] = useState(0);
  const [subStatus, setSubStatus] = useState(0);

  const { candidateDataJobId, candidateOthers } = useSelector(
    (state: any) => state.candidates
  );

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  const jobDetails: any =
    candidateDataJobId &&
    candidate?.jobs.filter((job: any) => job.id === candidateDataJobId)[0];

  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
      candidateOthers.length > 0 &&
      candidateOthers[0].group_by_job
    );
  }, [candidateOthers]);

  const { filter } = useSelector((state: any) => state.candidateFilters);

  useEffect(() => {
    if (filter) {
      setIsGroupByJob(true);
    }
  }, [filter]);

  const [reqjob] = useGetJobsMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const {
    isOpen: isOpenJobList,
    onClose: onCloseJobList,
    onOpen: onOpenJobList,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenSubmit,
    onClose: onCloseSubmit,
    onOpen: onOpenSubmit,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  const statusChangeSuccess = async () => {
    // setIsBulkAction(false);
    await reqCandidates(candidatePgBtn);
    // await reqGetJob({ id: params.jobsId });
  };

  const onJobSelect = (data: jobselectInt) => {
    if (data.type === 'submit' || data.type === 'interview') {
      reqjob({ id: data.id }).then((res: any) => {
        setJobId(data.id);
        if (data.type === 'submit') {
          onOpenSubmit();
        }
      });
    } else if (data.type === 'status') {
      candidate?.jobs.map((job: candidateJobs) => {
        if (Number(job?.id) == data.id) {
          setAssocId(Number(job?.assoc_id));
          setMainStatus(Number(job?.status.id));
          setSubStatus(Number(job?.sub.id));
          onOpenChangeStatus();
        }
      });
    }
  };

  const changeStatus = async (candidate: any) => {
    steActionType('status');
    const jobs_length = candidate?.jobs?.length;
    if (jobs_length > 0) {
      let jobs = [] as any;
      let job_id = null as any;
      let assoc = null as any;
      await Promise.all(
        candidate?.jobs?.map(async (item: any) => {
          const param = {
            job: {
              ...item,
            },
          };
          jobs.push(param);
          job_id = item.id;
          assoc = item.assoc_id;
        })
      );
      setJobList(jobs);
      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'status',
          assoc_id: assoc,
        });
      } else {
        onOpenJobList();
      }
    }
  };

  const submitToClient = async (candidateData: any) => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    steActionType('submit');

    if (isGroupByJob) {
      onJobSelect({
        id: Number(jobDetails?.job?.id) || candidateDataJobId,
        type: 'submit',
        assoc_id: jobDetails?.id,
        reject: jobDetails?.sub?.target,
      });
    } else {
      await Promise.all(
        candidateData?.jobs?.map(async (item: any) => {
          if (param?.jobsId) {
            if (
              item.candidate_status_id === 2 &&
              item?.job?.id == param?.jobsId
            ) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          } else {
            if (item.candidate_status_id === 2) {
              if (item?.job) {
                jobs.push(item);
                job_id = item?.job?.id;
                assoc = item;
              }
            }
          }
        })
      );

      setJobList(jobs);
      if (jobs.length === 1) {
        onJobSelect({
          id: Number(job_id),
          type: 'submit',
          assoc_id: assoc?.id,
          reject: assoc?.sub?.target,
        });
      } else {
        onOpenJobList();
      }
    }
  };

  return (
    <>
      <Tr
        key={`candidate-tr-${key}-${candidate.id}`}
        _hover={{
          bg: '#f8f9fa',
        }}
        cursor="pointer"
      >
        <Td
          position="sticky"
          left={0}
          bg="white"
          boxShadow="inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)"
          _hover={{
            bg: '#f8f9fa',
          }}
        >
          <Flex gap="30px">
            <Checkbox
              onChange={(e: any) => {
                !fromGroupByJobs
                  ? handleCheck(e)
                  : handleCheck(e, candidate?.jobs?.[0]?.id);
              }}
              isChecked={
                !fromGroupByJobs
                  ? isCheck.includes(candidate?.id)
                  : isCheckWithJob.some(
                    (obj: any) =>
                      obj.id === candidate?.id &&
                      obj.job_id === candidate?.jobs?.[0]?.id
                  )
              }
              id={candidate.id.toString()}
              key={candidate.id}
              colorScheme="purple"
            />
            <Flex alignItems="center" justifyContent="space-between" gap="20px">
              <Flex
                onClick={() => handleClick(candidate)}
                flexDirection="column"
                justifyContent="center"
                width="300px"
                minH="60px"
              >
                <Box
                  fontWeight="bold"
                  fontSize="14px"
                  color="default.primarytext"
                  isTruncated
                  textTransform="capitalize"
                >
                  {[candidate.first_name, candidate.last_name].join(' ')}
                </Box>
                {candidate.latest_job_title && (
                  <Box
                    fontSize="14px"
                    color="default.secondarytext"
                    isTruncated
                  >
                    {candidate.latest_job_title}
                  </Box>
                )}
                {candidate.latest_employer && (
                  <Box
                    fontSize="14px"
                    color="default.secondarytext"
                    isTruncated
                  >
                    {candidate.latest_employer}
                  </Box>
                )}
              </Flex>
              <Menu>
                <MenuButton>
                  <Button
                    htmlType="menu"
                    variant="ghost"
                    iconName="ellipsis-h"
                    iconSize="xl"
                  />
                </MenuButton>
                {!fromJob ? (
                  <MenuList
                    fontSize="sm"
                    sx={{
                      position: 'relative',
                      left: '38px',
                    }}
                  >
                    <MenuItem>
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="comment-dots" />
                        <Box ml={12} cursor="pointer">
                          Send SMS
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem onClick={() => openSendEmailClick(candidate)}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="envelope" />
                        <Box ml={12} cursor="pointer">
                          Send Email
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem onClick={() => associateToJob(candidate)}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="eye" />
                        <Box ml={12} cursor="pointer">
                          Associate to Job
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        onClick={async () => {
                          await reqGetCandidate({ id: candidate.id });
                          submitToClient(candidate);
                        }}
                      >
                        <FAIcon iconName="clipboard" />
                        <Box ml={12} cursor="pointer">
                          Submit to Client
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem onClick={() => addTagClick(candidate.id)}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="tags" />
                        <Box ml={12} cursor="pointer">
                          Add Tags
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem
                      onClick={() => changeStatus(candidate)}
                      isDisabled={candidate?.jobs?.length === 0}
                    >
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="person-circle-check" />
                        <Box ml={12} cursor="pointer">
                          Change Status
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem onClick={() => editCandidate(candidate)}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="pencil" />
                        <Box ml={12} cursor="pointer">
                          Edit
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem onClick={() => handleHideCandidate(candidate)}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="eye-slash" />
                        <Box ml={12} cursor="pointer">
                          Hide
                        </Box>
                      </Flex>
                    </MenuItem>
                    <MenuItem onClick={() => handleDeleteCandidate(candidate)}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <FAIcon iconName="trash" />
                        <Box ml={12} cursor="pointer">
                          Delete
                        </Box>
                      </Flex>
                    </MenuItem>
                  </MenuList>
                ) : (
                  <MenuList
                    fontSize="sm"
                    sx={{
                      position: 'relative',
                      left: '38px',
                    }}
                  >
                    {HandleMenuItem}
                  </MenuList>
                )}
              </Menu>
            </Flex>
          </Flex>
        </Td>
        {hasStatus && (
          <Td onClick={() => handleClick(candidate)}>
            <Box fontSize="14px" color="default.primarytext">
              {jobStatus(candidate)}
            </Box>
          </Td>
        )}
        <Td onClick={() => handleClick(candidate)}>
          <Box
            fontSize="14px"
            color="default.primarytext"
            sx={{ w: '200px', textWrap: 'balance' }}
          >
            {/* {locationParse(candidate)} */}
            {[candidate?.city, candidate?.state_province]
              .filter(Boolean)
              .join(', ')}
          </Box>
        </Td>
        <Td onClick={() => handleClick(candidate)}>
          {candidate.resume_update_on && (
            <Box fontSize="14px" color="default.primarytext">
              {moment.utc(candidate.resume_update_on).format('MM/DD/YYYY')}
            </Box>
          )}
        </Td>
        <Td onClick={() => handleClick(candidate)}>
          <Box
            fontSize="14px"
            textTransform="capitalize"
            color="default.primarytext"
          >
            {candidate.work_status?.status}
          </Box>
        </Td>
        <Td onClick={() => handleClick(candidate)}>
          <Box fontSize="14px" color="default.primarytext">
            {moment.utc(candidate.created_at).format('MM/DD/YYYY')}
          </Box>
        </Td>
        <Td onClick={() => handleClick(candidate)}>
          {candidate.last_activity && (
            <Box fontSize="14px" color="default.primarytext">
              {moment.utc(candidate.last_activity).format('MM/DD/YYYY')}
            </Box>
          )}
        </Td>

        {candidate.jobs?.length > 0 ? (
          <Td>
            <Flex
              fontSize="14px"
              color="default.primarytext"
              whiteSpace="normal"
              width="200px"
              gap="10px"
              alignItems="flex-end"
              sx={{ w: '200px', textWrap: 'balance' }}
            >
              {candidate.jobs?.length > 0 &&
                jobsPopulate(candidate.jobs, candidate.id)}
            </Flex>
          </Td>
        ) : (
          <Td onClick={() => handleClick(candidate)}></Td>
        )}

        {candidate.tags?.length > 0 ? (
          <Td>
            <Flex
              fontSize="14px"
              color="default.primarytext"
              whiteSpace="normal"
              width="200px"
              gap="10px"
              alignItems="flex-end"
              sx={{ w: '200px', textWrap: 'balance' }}
            >
              <>
                {tagsPopulate(candidate.tags, candidate.id)}
                <Box color="rgb(105 48 202 / 70%)">
                  <FAIcon iconName="tags" />
                </Box>
              </>
            </Flex>
          </Td>
        ) : (
          <Td onClick={() => handleClick(candidate)}></Td>
        )}
      </Tr>

      {isOpenSendEmail && (
        <CandidateEmail
          isOpen={isOpenSendEmail}
          onClose={onCloseSendEmail}
          id={candidate.id}
          email={emails?.[0]?.email || ''}
        />
      )}

      {isOpenJobList && (
        <CandidateJobList
          jobs={jobList}
          isOpen={isOpenJobList}
          onClose={onCloseJobList}
          onSelect={(e: any) => onJobSelect(e)}
          type={actionType}
        />
      )}

      {isOpenSubmit && (
        <SubmitToClientModal
          isOpen={isOpenSubmit}
          onClose={onCloseSubmit}
          candidate={candidate}
          job_id={jobId}
          callback={() => {
            reqGetCandidate({ id: candidate?.id });
          }}
        />
      )}

      {isOpenChangeStatus && (
        <ChangeStatusModal
          isOpen={isOpenChangeStatus}
          onClose={onCloseChangeStatus}
          id={assocId}
          idList={assocIdList}
          main_status={mainStatus}
          sub_status={subStatus}
          onSuccess={() => statusChangeSuccess()}
          candidate={candidate}
        // isBulkChangeStatus={isBulkAction}
        />
      )}
    </>
  );
};

export default CandidateTR;

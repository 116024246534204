import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import LeadsAddAttachmentModal from './add';
import StreamAttachmentModal from './stream';

import {
  useDelAttachmentMutation,
  useListLeadAttachmentMutation,
} from 'store/leadattachment.slice';
import { useGetLeadsMutation } from 'store/leads.slice';
import { AtsConfirm } from 'utils/swal';

import { swalContent } from 'types';

import GoTo from 'assets/images/goto.svg';
import { useParams } from 'react-router';
import Pagination from 'Library/Pagination';
import { getAttachment } from 'store/candidateattachment.slice';
import { Formik } from 'formik';
import ScrollToFieldError from 'components/app/ScrollError';

const LeadsAttachments = () => {
  const dispatch = useDispatch();
  const { LAlist, LAPage, LAPgBtn } = useSelector(
    (state: any) => state.leadattachment
  );
  const [reqdel, resdel] = useDelAttachmentMutation();
  const [reqleads] = useGetLeadsMutation();
  const [reqList] = useListLeadAttachmentMutation();
  const [fileData, setFileData] = useState(null);
  const params = useParams();
  const leadID = params.leadsId;

  const tableHeader: { label: string }[] = [
    { label: 'File Name' },
    { label: 'Uploaded By' },
    { label: 'Uploaded On' },
    { label: '' },
  ];

  const {
    isOpen: isAddAttachmentOpen,
    onOpen: onAddAttachmentOpen,
    onClose: onAddAttachmentClose,
  } = useDisclosure();

  const {
    isOpen: isStreamOpen,
    onOpen: onStreamOpen,
    onClose: onStreamClose,
  } = useDisclosure();

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this file.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const delAttachment = async (id: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqdel({ id });
    }
  };

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: LAPgBtn.take,
      query: LAPgBtn.query,
    };
    await dispatch(
      getAttachment({
        LAPgBtn: newParam,
      })
    );
    reqList({ data: newParam, id: leadID });
  };

  const initialValue = {
    search: '',
  };

  const onSubmit = async (data: any) => {
    let newParam = {
      page: LAPgBtn.page,
      take: LAPgBtn.take,
      query: data.search,
    };
    await dispatch(
      getAttachment({
        LAPgBtn: newParam,
      })
    );

    reqList({ data: newParam, id: leadID });
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let newParam = {
        page: 1,
        take: LAPgBtn.take,
        query: '',
        status: LAPgBtn.status,
        types: LAPgBtn.types,
      };
      dispatch(
        getAttachment({
          LAPgBtn: newParam,
        })
      );
      reqList({ data: newParam, id: leadID });
    }
  };

  useEffect(() => {
    reqList({ data: LAPgBtn, id: leadID });
    if (resdel.isSuccess) {
      reqleads({ id: leadID });
    }
  }, [LAPgBtn, leadID, reqList, reqleads, resdel.isSuccess]);

  const entryChange = async (entries: number) => {
    let newParam = {
      // page: LAPgBtn.page,
      page: 1,
      take: entries,
      query: LAPgBtn.query,
    };
    await dispatch(
      getAttachment({
        LAPgBtn: newParam,
      })
    );
    reqList({ data: newParam, id: leadID });
  };

  return (
    <Box>
      <Flex justifyContent="space-between" py={6}>
        <Box pr={2}>
          <Formik initialValues={initialValue} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <InputGroup background="#fff">
                  <InputRightElement
                    cursor="pointer"
                    children={<FAIcon iconName="search" />}
                  />
                  <Input
                    type="search"
                    name="search"
                    value={values.search}
                    onChange={(e) => {
                      handleChange(e);
                      clearSearch(e);
                    }}
                    placeholder="Search File Name"
                  />
                </InputGroup>
              </form>
            )}
          </Formik>
        </Box>

        <Button leftIcon="plus" variant="solid" onClick={onAddAttachmentOpen}>
          Add Attachments
        </Button>
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {LAlist.length > 0 &&
                LAlist.map((attachments: any) => (
                  <Tr key={attachments.id} cursor="pointer" onClick={() => {}}>
                    <Td>
                      <Flex alignItems="center" color="primary.800">
                        <Link
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                          onClick={() => {
                            onStreamOpen();
                            setFileData(attachments);
                          }}
                        >
                          {attachments.original_file_name}
                          <span>
                            <Image src={GoTo} alt="logo" />
                          </span>
                        </Link>
                      </Flex>
                    </Td>
                    <Td>
                      {attachments.user?.first_name +
                        ' ' +
                        attachments.user?.last_name}
                    </Td>
                    <Td>
                      {moment
                        .utc(attachments.created_at)
                        .format('MM/DD/YYYY hh:mm A')}
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton
                          onClick={() => delAttachment(attachments.id)}
                        >
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="trash"
                            iconSize="xl"
                          />
                        </MenuButton>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          totalPages={LAPage.lastPage}
          currentPage={LAPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={LAPage.count}
          currentCount={LAlist?.length}
          targetCount={LAPgBtn.take}
        />
        {isAddAttachmentOpen && (
          <LeadsAddAttachmentModal
            isOpen={isAddAttachmentOpen}
            onClose={onAddAttachmentClose}
          />
        )}

        {isStreamOpen && (
          <StreamAttachmentModal
            isOpen={isStreamOpen}
            onClose={onStreamClose}
            file={fileData}
          />
        )}
      </Box>
    </Box>
  );
};

export default LeadsAttachments;

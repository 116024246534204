import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDropzone } from 'react-dropzone';
import { BsCloudUpload } from 'react-icons/bs';
import axios from 'axios';
import { BASE_URL } from 'constants/values';

import {
  Box,
  Button,
  Flex,
  Icon,
  FormControl,
  HStack,
  Progress,
  Stack,
  Text,
  Tag,
  TagLabel,
  TagCloseButton,
  List,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';

import {
  setAttachment,
  setResumeUploaded,
  setResumeAttachments,
  removeAttachment,
  useUploadMutation,
  uploadList,
  setProgress,
  setUploaded,
  setResumeProgress,
  useResumeUploadMutation,
  removeResumeAttachment,
  removeUploaded,
  removeResumeUploaded,
} from 'store/uploads.slice';

import randomstring from 'utils/randomstring';

import { fileUploadedInt, UploadTypes } from 'types';
import {
  fileFormat,
  agreementFileFormat,
  maxUpload,
  fileFormatLabel,
  agreementFileFormatLabel,
} from 'constants/files';
import { bytesToSizeStr } from 'utils/bytesToSize';
import { changeName, newFilename } from 'utils/newFilename';

interface AtsDropZoneProps {
  multipleFile: boolean;
  isAgreement?: boolean;
  isResume?: boolean;
  candidate_id?: number;
  uploadedEnd: (data: any) => void;
  reference?: any[];
  hasRef?: boolean;
  deletedFile: (data: any) => void;
  disabled?: boolean;
}

export default function AtsDropZone({
  multipleFile,
  isAgreement,
  isResume,
  candidate_id = null,
  uploadedEnd,
  reference,
  hasRef = false,
  deletedFile,
  disabled = false,
}: AtsDropZoneProps) {
  const dispatch = useDispatch();

  const {
    attachments,
    prefix,
    withPrefix,
    uploaded,
    resumeUploaded,
    resumeAttachments,
    uploading,
  } = useSelector((state: any) => state.uploads);

  const hasFileFormat =
    isAgreement || isResume ? agreementFileFormat : fileFormat;
  const fileLabel =
    isAgreement || isResume ? agreementFileFormatLabel : fileFormatLabel;

  const [filesStr, setFilesStr] = useState('');
  const [reqUpload] = useUploadMutation();
  const [reqResumeUpload] = useResumeUploadMutation();

  let url = isResume
    ? BASE_URL + '/resume-parser/file'
    : BASE_URL + '/upload/file/temp';
  if (isResume && candidate_id) {
    url = BASE_URL + `/resume-parser/candidate-file/${candidate_id}`;
  }
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const uploadPromises = acceptedFiles.map(async (file) => {
        let id = randomstring();

        let count = isResume
          ? resumeAttachments.length + 1
          : attachments.length + 1;
        let file_name = withPrefix
          ? newFilename(prefix + '-' + count, file.name)
          : file.name;
        dispatch(uploadList({ uploading: true }));
        isResume
          ? dispatch(
            setResumeAttachments({
              id: id,
              name: file_name,
              progress: 0,
              uploading: true,
              file: file,
            })
          )
          : dispatch(
            setAttachment({
              id: id,
              name: file_name,
              progress: 0,
              uploading: true,
              file: file,
            })
          );
        const config = {
          withCredentials: true,
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            isResume
              ? dispatch(
                setResumeProgress({
                  id: id,
                  name: file_name,
                  progress: percentCompleted,
                  uploading: percentCompleted < 100 ? true : false,
                  file: file,
                })
              )
              : dispatch(
                setProgress({
                  id: id,
                  name: file_name,
                  progress: percentCompleted,
                  uploading: percentCompleted < 100 ? true : false,
                  file: file,
                })
              );
          },
        };
        let formData = new FormData();
        formData.append('file', file, file.name);
        await axios
          .post(url, formData, config)
          .then((res) => {
            const data = {
              ...res.data?.data,
              local_id: id,
            };
            if (isResume) {
              dispatch(setResumeUploaded(data));
              // console.log('resume', isResume);
            } else {
              dispatch(setUploaded(data));
            }

            uploadedEnd(data);
          })
          .catch((err) => {
            console.log('err', err);
          });
      });

      await Promise.all(uploadPromises);
    },
    [
      attachments.length,
      resumeAttachments.length,
      dispatch,
      prefix,
      reqUpload,
      reqResumeUpload,
      withPrefix,
    ]
  );

  useEffect(() => {
    dispatch(uploadList({ uploading: false }));
  }, []);

  useEffect(() => {
    // console.log(
    //   'attachments sa ats dropzone ',
    //   isResume ? resumeAttachments : attachments
    // );
  }, [attachments, resumeAttachments]);

  useEffect(() => {
    if (isResume) {
      if (resumeAttachments.length > 0) {
        let stillUploading = false;
        resumeAttachments.map((item: any) => {
          if (item.uploading) {
            stillUploading = true;
          }
        });

        dispatch(uploadList({ uploading: stillUploading }));
      }
    } else {
      if (attachments.length > 0) {
        let stillUploading = false;
        attachments.map((item: any) => {
          if (item.uploading) {
            stillUploading = true;
          }
        });

        dispatch(uploadList({ uploading: stillUploading }));
      }
    }
  }, [uploaded, resumeUploaded]);

  const { getRootProps, fileRejections } = useDropzone({
    onDrop,
    multiple: multipleFile,
    accept: hasFileFormat,
    maxSize: 26214400,
    disabled: disabled,
  });

  const { ref, ...rootProps } = getRootProps();

  const removeAtt = (key: string) => {
    // const uploaded_id = uploaded.findIndex((file: any) => file.id === key) || 0;
    isResume
      ? dispatch(removeResumeAttachment(key))
      : dispatch(removeAttachment(key));

    isResume
      ? dispatch(removeResumeUploaded(key))
      : dispatch(removeUploaded(key));
    deletedFile(key);
  };

  const formatString = () => {
    setFilesStr(fileLabel.join(', '));
  };

  const ApplyPrefix = async () => {
    let newAttachments = withPrefix
      ? isResume
        ? await changeName(resumeAttachments, prefix)
        : await changeName(attachments, prefix)
      : isResume
        ? resumeAttachments
        : attachments;
    isResume
      ? await dispatch(uploadList({ resumeAttachments: newAttachments }))
      : await dispatch(uploadList({ attachments: newAttachments }));
  };

  useEffect(() => {
    formatString();
    ApplyPrefix();
  }, [prefix]);
  interface FileWithPath extends File {
    path: string;
  }
  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <ListItem>
      {(file as FileWithPath).path} - {bytesToSizeStr(file.size)}
      <UnorderedList>
        {errors.map((e) => (
          <ListItem key={e.code}>{e.message}</ListItem>
        ))}
      </UnorderedList>
    </ListItem>
  ));

  // console.log('reference', reference);
  interface TagEleInt {
    data: UploadTypes;
    key: number;
  }
  const TagElement = ({ data, key }: TagEleInt) => {
    const display = hasDisplay(data);
    // const display = true;
    console.log("data ", data)
    // console.log("data ", data.progress)
    // console.log("data.uploading", data.uploading)
    return (
      <>
        {display ? (
          <Tag
            style={{ display: 'inline-block' }}
            key={data?.id}
            mr="10px"
            mb="10px"
          >
            <Stack spacing={1}>
              {data.uploading && (
                <Progress value={data.progress} style={{ height: '5px' }} />
              )}
              <HStack>
                <TagLabel maxW="100px">{data.name}</TagLabel>
                <TagCloseButton onClick={() => removeAtt(data?.id)} />
              </HStack>
            </Stack>
          </Tag>
        ) : (
          <></>
        )}
      </>
    );
  };

  const hasDisplay = (data: UploadTypes) => {
    let willDisplay = true;
    if (hasRef) {
      willDisplay = reference.some(
        (item: fileUploadedInt) => {
          console.log(data.id, item?.local_id, item?.local_id === data.id)
          return item?.local_id === data.id
        }
      );
    }
    return willDisplay;
  };

  return (
    <>
      <Stack direction={['column', 'row']} spacing="24px" mb="10px">
        <Box gap="10px" display="inline-block">
          {isResume
            ? resumeAttachments.length > 0 &&
            resumeAttachments.map((item: UploadTypes, key: number) => {
              return <TagElement data={item} key={key} />;
            })
            : attachments.length > 0 &&
            attachments.map((item: UploadTypes, key: number) => {
              return <TagElement data={item} key={key} />;
            })}
        </Box>
      </Stack>
      <Box
        {...rootProps}
        style={{
          borderRadius: 5,
          border: '2px dashed #ccd0d4',
          backgroundColor: '#f7f7f7',
          minHeight: '50px',
          boxShadow: 'inset 0px 2px 6px 0px #d2d2d291',
          transition: 'border-color 300ms cubic-bezier(0, 0, 0.2, 1)',
        }}
        mb="34px"
      >
        <Flex mb="34px" mt="34px">
          <FormControl
            style={{
              textAlign: 'center',
              width: '50%',
              marginLeft: '25%',
            }}
          >
            <Icon
              as={BsCloudUpload}
              boxSize={8}
              style={{ display: 'block', margin: 'auto' }}
            />
            <Button variant="solid" type="button" disabled={disabled}>
              Choose a file
            </Button>
          </FormControl>
          <FormControl>
            <Stack spacing={2}>
              <Text>- Max total file size: {maxUpload}MB</Text>
              <Text>- Upload only {filesStr}</Text>
            </Stack>
          </FormControl>
        </Flex>
      </Box>
      <Box color="red.400">
        <List>{fileRejectionItems}</List>
      </Box>
    </>
  );
}

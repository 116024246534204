import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Field, Formik } from 'formik';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import {
  useBulkChangeSubStatusMutation,
  useChangeSubStatusMutation,
  useGetCandidatesMutation,
} from 'store/candidates.slice';
import { useGetSubStatusMutation } from 'store/constant.slice';

import AtsSelect2 from 'components/app/AtsSelect2';
import ScrollToFieldError from 'components/app/ScrollError';
import HiredModal from './HiredModal';
import {
  setClientAgreement,
  useGetClientAgreementMutation,
  useListClientAgreementMutation,
} from 'store/clientagreement.slice';
import { useGetJobsMutation } from 'store/jobs.slice';
import { usePinListCandidateFilterMutation } from 'store/candidatefilter.slice';

interface ChangeStatusModalProps {
  isOpen: any;
  onClose: any;
  id?: number;
  main_status: number;
  sub_status: number;
  idList?: number[];
  isBulkChangeStatus?: boolean;
  candidate?: any;
  onSuccess: (e: any) => void;
}

export default function ChangeStatusModal({
  isOpen,
  onClose,
  id,
  main_status,
  sub_status,
  idList,
  candidate,
  isBulkChangeStatus = false,
  onSuccess,
}: ChangeStatusModalProps) {
  const { candidate_status } = useSelector((state: any) => state.constants);
  const { clientAgreementPgBtn } = useSelector(
    (state: any) => state.clientAgreements
  );

  const { jobData } = useSelector((state: any) => state.jobs);
  const { candidateData } = useSelector((state: any) => state.candidates);

  const dispatch = useDispatch();

  const [reqGetJob] = useGetJobsMutation();
  const [reSub] = useGetSubStatusMutation();
  const [reqStatusChange, resStatusChange] = useChangeSubStatusMutation();
  const [reqBulkStatusChange, resBulkStatusChange] =
    useBulkChangeSubStatusMutation();
  const [currentStatus, setCurrentStatus] = useState([]);
  const [statOption, setStatOption] = useState([]);
  const [minHeight, setMinHeight] = useState('10vh');
  const [subId, setSubId] = useState();
  const [submitData, setSubmitData] = useState({});
  const [reqAgreement, resAgreement] = useListClientAgreementMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();

  const {
    isOpen: isOpenHired,
    onOpen: onOpenHired,
    onClose: onCloseHired,
  } = useDisclosure();

  useEffect(() => {
    if (candidate_status?.length > 0) {
      let groups: any = [];
      candidate_status.map((item: any) => {
        if (item?.sub?.length > 0) {
          let option: any = [];
          item?.sub?.map((sub: any) => {
            option.push({
              label: sub.sub_status,
              value: sub.id,
            });
            if (sub_status === sub.id) {
              setCurrentStatus([{ label: sub.sub_status, value: sub.id }]);
            }
          });

          groups.push({
            label: item.status,
            options: option,
          });
        }
      });
      setStatOption(groups);
    }
  }, [candidate_status]);

  const initialValues = {
    sub_id: currentStatus,
  };

  useEffect(() => {
    reSub({ id: main_status });
  }, [main_status]);

  const handleSubmit = async (data: any) => {
    const form = {
      sub_id:
        data.sub_id && data.sub_id.length > 0
          ? data.sub_id[0].value
          : data.sub_id.value,
      ...(isBulkChangeStatus && { idList }),
    };
    setSubId(data.sub_id.value);

    if (form.sub_id === 8) {
      setSubmitData(data);

      let clientID = null;
      if (candidate?.jobs?.[0]?.client?.id) {
        clientID = candidate?.jobs?.[0]?.client?.id;
      } else if (
        jobData?.client_id &&
        jobData?.id === candidate?.jobs?.[0]?.id
      ) {
        clientID = jobData?.client_id;
      }
      if (
        candidateData?.id !== candidate?.id ||
        !candidateData?.source?.source
      ) {
        await reqGetCandidate({ id: candidate?.id });
      }
      if (
        jobData?.id !== candidate?.jobs?.[0]?.id ||
        !jobData?.lead?.lead_source?.source
      ) {
        await reqGetJob({ id: candidate?.jobs?.[0]?.id });
      }
      if (clientID) {
        let newParam = {
          page: 1,
          take: clientAgreementPgBtn.take,
          query: '',
          status: clientAgreementPgBtn.status,
          types: clientAgreementPgBtn.types,
        };
        dispatch(
          setClientAgreement({
            leadAgreementPgBtn: newParam,
          })
        );
        await reqAgreement({ data: newParam, id: clientID });
      }

      onOpenHired();
    } else {
      if (isBulkChangeStatus) {
        // console.log('went to is BulkChangeStatus');
        reqBulkStatusChange({ data: form });
      } else {
        reqStatusChange({ id, data: form });
      }
    }
  };

  const callBackAction = () => {
    const form = {
      sub_id:
        (submitData as any)?.sub_id && (submitData as any)?.sub_id.length > 0
          ? (submitData as any)?.sub_id[0].value
          : (submitData as any)?.sub_id.value,
      ...(isBulkChangeStatus && { idList }),
    };

    if (isBulkChangeStatus) {
      // console.log('went to is BulkChangeStatus');
      reqBulkStatusChange({ data: form });
    } else {
      reqStatusChange({ id, data: form });
    }
  };

  const [reqpin] = usePinListCandidateFilterMutation();

  useEffect(() => {
    if (resStatusChange.isSuccess || resBulkStatusChange.isSuccess) {
      onSuccess({ sub_id: subId, id });
      reqpin({});
      onClose();
    }
  }, [resStatusChange.isSuccess, resBulkStatusChange.isSuccess]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (resStatusChange.isError || resBulkStatusChange.isError) {
      onOpenAlert();
    }
  }, [resStatusChange.isError, resBulkStatusChange.isError]);

  const onFocusSelect = () => {
    setMinHeight('45vh');
  };

  const onBlurSelect = () => {
    setMinHeight('10vh');
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
      }}
      size="lg"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="Proxima Nova Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Change Status
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box>
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>
                  There was an error processing your request. Change a few
                  things up and try again.
                </AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody
          borderRadius="0 0 4px 4px"
          p={0}
          style={{ overflow: 'hidden' }}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {({ values, handleSubmit, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box p="32px" minHeight={minHeight}>
                  <Box mb="34px">
                    <FormControl>
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Current Job Status
                      </FormLabel>
                      <Field
                        name="sub_id"
                        options={statOption}
                        component={AtsSelect2}
                        defaultValue={values.sub_id}
                        onMenuOpen={() => onFocusSelect()}
                        onMenuClose={() => onBlurSelect()}
                      />
                    </FormControl>
                  </Box>
                </Box>

                <ModalFooter
                  position="sticky"
                  bottom="0"
                  background="default.white.100"
                  boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                >
                  <Button
                    mr={3}
                    onClick={() => onClose()}
                    disabled={
                      resStatusChange.isLoading || resBulkStatusChange.isLoading
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="solid"
                    type="submit"
                    disabled={
                      resStatusChange.isLoading || resBulkStatusChange.isLoading
                    }
                    isLoading={
                      resStatusChange.isLoading || resBulkStatusChange.isLoading
                    }
                  >
                    Update
                  </Button>
                </ModalFooter>
              </form>
            )}
          </Formik>

          <HiredModal
            isOpen={isOpenHired}
            onCloseStatus={onClose}
            onClose={onCloseHired}
            candidate={candidate}
            onCallback={callBackAction}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BarChart, pieArcLabelClasses, PieChart } from '@mui/x-charts';

import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material';

import BG from 'assets/images/bg.svg';
import BG1 from 'assets/images/bg1.svg';

import User from 'assets/images/user.png';

import MdUp from 'assets/images/MdUp.svg';
import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';
import {
  useActivityListMutation,
  useGetAcquisitionSourceMutation,
  useGetDealsMutation,
  useGetRecruitmentPipelinesMutation,
  useGetTopRecruitersMutation,
  usePostGoalMutation,
} from 'store/dashboard.slice';

import { formatDigits, truncateToTwoDecimalPlaces } from 'utils/utilFormat';
import { byActivity, byMonth, chartMonths, tableHeader, tableHeader1 } from './constants';
import InputWidthForm from './InputWidthForm';

const AdminDashboard = () => {
  const MuiTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const { first_name } = useSelector((state: any) => state.auth);
  const { userList } = useSelector((state: any) => state.user);
  const {
    activities,
    deals,
    recruitmentPipelines,
    topRecruiters,
    acquisitionSource,
  } = useSelector((state: any) => state.dashboard);

  const [reqDeals] = useGetDealsMutation();
  const [reqActivity] = useActivityListMutation();
  const [reqPostGoal, resPostGoal] = usePostGoalMutation();
  const [reqTopRecruiters] = useGetTopRecruitersMutation();
  const [reqRecruitmentPipelines] = useGetRecruitmentPipelinesMutation();
  const [reqAcquisitionSource] = useGetAcquisitionSourceMutation();

  const [selectByMonth, setByMonth] = useState(0);
  const [selectByActivity, setByActivity] = useState('current month');
  const [selectByUser, setSelectByUser] = useState(null);
  const [greeting, setGreeting] = useState('');
  const [isEditGoal, setIsEditGoal] = useState(false);

  const percentGoal = (deals?.total_revenue?.total_revenue / deals?.goal) * 100;

  const data = recruitmentPipelines?.map((val: any) => {
    return {
      id: val.candidate_sub_status_id,
      value: Number(val.count_by_candidate_sub_status),
      label: val.label,
    };
  });

  const total = data.reduce(
    (acc: any, item: { value: any }) => acc + item.value,
    0
  );

  const recruitmentPipelinesPercentageData = data.map(
    (item: { value: number }) => ({
      ...item,
      value: parseFloat(((item.value / total) * 100).toFixed(2)),
    })
  );

  const [byUser, setByUser] = useState([
    {
      value: null,
      label: 'all users',
    },
  ]);

  const topRecruiterSort = topRecruiters?.map((val: any, index: number) => {
    return {
      rank: index + 1,
      name: [val.first_name, val.last_name].join(' '),
      jobClosed: val.total_closed,
      revenue: val.total_revenue,
    };
  });

  const customOrder = [2, 1, 3];
  const sortedTopRecruiters = topRecruiterSort
    .slice(0, 3)
    .sort(
      (a: any, b: any) =>
        customOrder.indexOf(a.rank) - customOrder.indexOf(b.rank)
    );

  const handleTopBG = (val: number) => {
    if (val === 1) {
      return '#ECC94B';
    } else if (val === 2) {
      return '#ED8936';
    }
    return '#A0AEC0';
  };

  useEffect(() => {
    reqDeals({});
    reqTopRecruiters({});
    reqRecruitmentPipelines({});
    reqAcquisitionSource({});
  }, []);

  useEffect(() => {
    reqActivity({
      data: { target: selectByActivity, recruiters: selectByUser },
    });
  }, [selectByActivity, selectByUser]);

  useEffect(() => {
    // Combine byUser and userList
    const combinedList = [
      {
        value: null,
        label: 'all users',
      },
      ...Object.values(userList).map((user: any) => ({
        label: [user.first_name, user.last_name].join(' '),
        value: user.id,
      })),
    ];

    setByUser(combinedList);
  }, [userList]);

  useEffect(() => {
    if (resPostGoal.isSuccess) {
      setIsEditGoal(false);
    }
  }, [resPostGoal?.isSuccess]);

  useEffect(() => {
    const currentHour = moment().hour();

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      setGreeting('Good afternoon');
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      setGreeting('Good evening');
    } else {
      // Between dawn and noon
      setGreeting('Good morning');
    }
  }, []);

  return (
    <Box
      sx={{
        background: '#F5F5F5',
        backgroundImage: BG,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        fontFamily: 'Proxima Nova Regular',
        p: '24px',
      }}
    >
      <Box
        sx={{
          fontSize: '24px',
          lineHeight: '29.23px',
          fontWeight: 700,
          mb: '24px',
          color: '#fff',
        }}
      >
        {greeting}, {first_name}!
      </Box>

      <Flex sx={{ gap: '16px', mb: '16px' }}>
        <Flex
          sx={{
            width: '20%',
            flexDir: ' column',
            borderRadius: '8px',
            background: '#fff',
            backgroundImage: BG1,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            p: '8px 16px',
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
              boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
            }}
          >
            Actual Revenue
          </Box>
          <Box
            sx={{
              fontSize: '50px',
              lineHeight: '73.08px',
              fontWeight: 700,
              color: 'primary.500',
              textAlign: 'center',
              pt: '62px',
              pb: '30px',
            }}
          >
            {formatDigits(deals?.total_revenue?.total_revenue)}
          </Box>
          <Progress
            value={truncateToTwoDecimalPlaces(percentGoal)}
            colorScheme="purple"
            borderRadius="8px"
          />
          <Flex
            sx={{
              gap: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            {isEditGoal ? (
              <Flex
                sx={{
                  gap: '5px',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    mt: '12px',
                    fontSize: '14px',
                    lineHeight: '17.5px',
                    fontWeight: 700,
                  }}
                >
                  $
                </Box>
                <InputWidthForm
                  valueKey="goal"
                  dashboardData={deals}
                  reqUpdate={reqPostGoal}
                  resUpdate={resPostGoal}
                  placeholder="0"
                />
              </Flex>
            ) : (
              <Box
                sx={{
                  mt: '12px',
                  fontSize: '14px',
                  lineHeight: '17.5px',
                  fontWeight: 700,
                }}
                onClick={() => setIsEditGoal(!isEditGoal)}
              >
                {truncateToTwoDecimalPlaces(percentGoal).toLocaleString()}% of
                GOAL {formatDigits(deals?.goal)}
              </Box>
            )}

            <Box mt="10px" onClick={() => setIsEditGoal(!isEditGoal)}>
              <FAIcon iconName={'pencil'} />
            </Box>
          </Flex>
        </Flex>

        <Box
          sx={{
            width: '80%',
            borderRadius: '8px',
            background: '#fff',
            p: '8px 16px',
            boxShadow: '0px -3px 7px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Flex sx={{ gap: '10px', alignItems: 'center' }}>
            <Box
              sx={{
                fontSize: '16px',
                lineHeight: '20px',
                fontWeight: 700,
                color: '#718096',
              }}
            >
              Actual Revenue - Deals Won
            </Box>

            <Flex width="175px">
              <Menu closeOnSelect={true}>
                <MenuButton type="button">
                  <Box
                    sx={{
                      button: {
                        p: '4px 8px',
                        pl: 0,
                        height: '33px',
                        fontSize: '14px',
                        border: 'none',
                        color: 'primary.500',
                      },
                    }}
                  >
                    <Button rightIcon="chevron-down" type="button">
                      {
                        byMonth.filter(
                          (val: any) => val.value === selectByMonth
                        )[0].label
                      }
                    </Button>
                  </Box>
                </MenuButton>
                {
                  <MenuList fontSize="sm" maxHeight="200px" overflowY="auto">
                    {byMonth.map((val: any) => {
                      return (
                        <MenuItem
                          type="button"
                          onClick={() => setByMonth(val.value)}
                        >
                          {val.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                }
              </Menu>
            </Flex>
          </Flex>
          <Flex sx={{ gap: '30px' }}>
            <Box>
              <Flex sx={{ py: '24px' }}>
                <Box
                  sx={{
                    height: 'fit-content',
                    border: '1px solid #E2E8F0',
                    borderRadius: '8px',
                    p: '16px',
                  }}
                >
                  <Box
                    as="span"
                    sx={{
                      fontSize: '32px',
                      lineHeight: '38.98px',
                      fontWeight: 400,
                      color: '#171923',
                      mb: '20px',
                    }}
                  >
                    {/* $
                    <Box
                      as="span"
                      sx={{
                        fontSize: '40px',
                        lineHeight: '48.72px',
                        fontWeight: 400,
                        color: '#171923',
                      }}
                    >
                      100
                    </Box>
                    K */}
                    {formatDigits(deals?.current_month?.total_revenue)}
                  </Box>
                  <Flex sx={{ gap: '12px', minWidth: '120px' }}>
                    <Box
                      sx={{
                        fontSize: '16px',
                        lineHeight: '20px',
                        fontWeight: 700,
                        color: '#718096',
                      }}
                    >
                      Current Month
                    </Box>
                    <Image src={MdUp} alt="up" />
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Box>
              <ThemeProvider theme={MuiTheme}>
                <BarChart
                  xAxis={[
                    {
                      id: 'barCategories',
                      data: chartMonths,
                      scaleType: 'band',
                    },
                  ]}
                  series={[
                    {
                      data:
                        deals?.chart?.length > 0
                          ? deals?.chart?.map((val: any) => val.total_revenue)
                          : [0],
                      color: '#805AD5',
                    },
                  ]}
                  width={850}
                  height={250}
                  yAxis={[
                    {
                      id: 'valueAxis',
                      position: 'left',
                      valueFormatter: (value) => formatDigits(value),
                    },
                  ]}
                />
              </ThemeProvider>
            </Box>
          </Flex>
        </Box>
      </Flex>

      <Box
        sx={{
          borderRadius: '8px',
          background: '#fff',
          mb: '16px',
          p: '8px 16px',
        }}
      >
        <Flex sx={{ gap: '10px', alignItems: 'center' }}>
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
            }}
          >
            Activities
          </Box>
          <Flex width="fit-content">
            <Menu closeOnSelect={true}>
              <MenuButton type="button">
                <Box
                  sx={{
                    button: {
                      p: '4px 8px',
                      pl: 0,
                      height: '33px',
                      fontSize: '14px',
                      border: 'none',
                      color: 'primary.500',
                    },
                  }}
                >
                  <Button rightIcon="chevron-down" type="button">
                    {
                      byActivity.filter(
                        (val: any) => val.label === selectByActivity
                      )[0].label
                    }
                  </Button>
                </Box>
              </MenuButton>
              {
                <MenuList fontSize="sm" maxHeight="200px" overflowY="auto">
                  {byActivity.map((val: any) => {
                    return (
                      <MenuItem
                        type="button"
                        onClick={() => {
                          setByActivity(val.label);
                        }}
                      >
                        {val.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              }
            </Menu>
          </Flex>
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
            }}
          >
            of
          </Box>
          <Flex width="fit-content">
            <Menu closeOnSelect={true}>
              <MenuButton type="button">
                <Box
                  sx={{
                    button: {
                      p: '4px 8px',
                      pl: 0,
                      height: '33px',
                      fontSize: '14px',
                      border: 'none',
                      color: 'primary.500',
                    },
                  }}
                >
                  <Button rightIcon="chevron-down" type="button">
                    {
                      byUser.filter((val: any) => val.value === selectByUser)[0]
                        .label
                    }
                  </Button>
                </Box>
              </MenuButton>
              {
                <MenuList fontSize="sm" maxHeight="200px" overflowY="auto">
                  {byUser.map((val: any) => {
                    return (
                      <MenuItem
                        type="button"
                        onClick={() => setSelectByUser(val.value)}
                      >
                        {val.label}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              }
            </Menu>
          </Flex>
        </Flex>

        <Box sx={{ my: '24px' }}>
          <TableContainer
            boxSizing="border-box"
            border="1px solid #EEEEEE"
            borderRadius="4px"
          >
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr bg="common.white">
                  {tableHeader.map((title) => (
                    <Th key={title.label}>
                      <Box
                        sx={{
                          fontSize: '10px',
                          lineHeight: '20px',
                          fontWeight: 400,
                          color: '#322659',
                        }}
                      >
                        {title.label}
                      </Box>
                    </Th>
                  ))}
                </Tr>
              </Thead>

              <Tbody
                boxSizing="border-box"
                background="default.white.100"
                borderBottom="1px solid"
                borderColor="default.white.400"
              >
                {activities?.map((item: any) => {
                  return (
                    <Tr
                      cursor="pointer"
                      key={`activity-logs-${item.label}`}
                      sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        fontWeight: 700,
                      }}
                    >
                      <Td sx={{ bg: '#DEE2E6 !important' }}>
                        Week {item.label}
                      </Td>
                      <Td>
                        {Number(item.new_client) !== 0 ? item.new_client : '-'}
                      </Td>
                      <Td>
                        {Number(item.new_client_interview) !== 0
                          ? item.new_client_interview
                          : '-'}
                      </Td>
                      <Td>
                        {Number(item.job_posted) !== 0 ? item.job_posted : '-'}
                      </Td>
                      <Td>{Number(item.submits) !== 0 ? item.submits : '-'}</Td>
                      <Td>
                        {Number(item.interviews) !== 0 ? item.interviews : '-'}
                      </Td>
                      <Td>{Number(item.offers) !== 0 ? item.offers : '-'}</Td>
                      <Td>
                        {Number(item.placements) !== 0 ? item.placements : '-'}
                      </Td>
                      <Td>
                        {Number(item.outbound_calls) !== 0
                          ? item.outbound_calls
                          : '-'}
                      </Td>
                      <Td
                        sx={{ color: Number(item.revenue) !== 0 && '#D69E2E' }}
                      >
                        {Number(item.revenue) !== 0
                          ? formatDigits(item.revenue)
                          : '-'}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>

      <Flex sx={{ gap: '16px' }}>
        <Box
          sx={{
            width: '20%',
            borderRadius: '8px',
            background: '#fff',
            p: '8px 16px',
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
              mb: '8px',
            }}
          >
            Recruitment Pipeline
          </Box>
          <Box sx={{ position: 'absolute', left: '-45px', mt: '24px' }}>
            <ThemeProvider theme={MuiTheme}>
              <PieChart
                colors={['#D69E2E', '#38A169', '#3182CE', '#805AD5']}
                series={[
                  {
                    arcLabel: (item) => `${item.value}%`,
                    data: recruitmentPipelinesPercentageData,
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: 'gray',
                    },
                  },
                ]}
                width={380}
                height={290}
                margin={{ top: 0, bottom: 70, left: 0, right: 0 }}
                slotProps={{
                  legend: {
                    itemGap: 5,
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                    padding: 0,
                    labelStyle: {
                      fontSize: 9,
                    },
                  },
                }}
                sx={{
                  position: 'absolute',
                  left: 0,
                  [`& .${pieArcLabelClasses.root}`]: {
                    fill: 'white',
                  },
                }}
              />
            </ThemeProvider>
          </Box>
        </Box>

        <Box
          sx={{
            width: '28%',
            borderRadius: '8px',
            background: '#fff',
            p: '8px 16px',
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
              mb: '8px',
            }}
          >
            Top recruiters by closed jobs
          </Box>
          <Flex
            sx={{
              gap: '30px',
              alignItems: 'flex-start',
              justifyContent: 'center',
              mt: '40px',
              mb: '17px',
            }}
          >
            {sortedTopRecruiters.map((val: any, index: any) => {
              return (
                <Flex
                  key={index}
                  sx={{
                    flexDir: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    top: val.rank === 1 && '-15px',
                  }}
                >
                  <Box
                    sx={{
                      bgColor: handleTopBG(val.rank),
                      borderRadius: '100%',
                      p: '5px',
                      position: 'relative',
                    }}
                  >
                    <Image
                      borderRadius="full"
                      boxSize="50px"
                      placeSelf="center"
                      objectFit="contain"
                      src={User}
                    />
                    <Box
                      sx={{
                        height: '30px',
                        width: '30px',
                        bgColor: handleTopBG(val.rank),
                        borderRadius: '100%',
                        p: '5px',
                        textAlign: 'center',
                        position: 'absolute',
                        left: '-9px',
                        bottom: '-2px',
                        fontWeight: 700,
                      }}
                    >
                      {val.rank}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      mt: '10px',
                      fontSize: '14px',
                      lineHeight: '17.5px',
                      fontWeight: 700,
                      width: '80px',
                      textAlign: 'center',
                    }}
                  >
                    {val.name}
                  </Box>
                  <Box
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17.5px',
                    }}
                  >
                    {val.jobClosed} • {formatDigits(val.revenue)}
                  </Box>
                </Flex>
              );
            })}
          </Flex>

          {topRecruiterSort.length > 3 && (
            <Box>
              <TableContainer
                boxSizing="border-box"
                border="1px solid #EEEEEE"
                borderRadius="4px"
              >
                <Table>
                  <Thead>
                    <Tr bg="default.white.800">
                      {tableHeader1.map((title) => (
                        <Th key={title.label}>
                          <Box color="default.gray.600">{title.label}</Box>
                        </Th>
                      ))}
                    </Tr>
                  </Thead>

                  <Tbody
                    boxSizing="border-box"
                    background="default.white.100"
                    borderBottom="1px solid"
                    borderColor="default.white.400"
                  >
                    {topRecruiterSort
                      .slice(3)
                      .map((item: any, index: number) => {
                        return (
                          <Tr cursor="pointer" key={`activity-logs-${item.id}`}>
                            <Td>{item.rank}</Td>
                            <Td>{item.name}</Td>
                            <Td>{item.jobClosed}</Td>
                            <Td>{formatDigits(item.revenue)}</Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            width: '52%',
            borderRadius: '8px',
            background: '#fff',
            p: '8px 16px',
          }}
        >
          <Box
            sx={{
              fontSize: '16px',
              lineHeight: '20px',
              fontWeight: 700,
              color: '#718096',
              mb: '8px',
            }}
          >
            Acquisition Sources
          </Box>
          <Box mt="70px">
            <ThemeProvider theme={MuiTheme}>
              <BarChart
                xAxis={[
                  {
                    id: 'barCategories',
                    data:
                      acquisitionSource?.length > 0
                        ? acquisitionSource?.map((val: any) => val.label)
                        : [
                            'Job Board',
                            'LinkedIn',
                            'Agency',
                            'Referrals',
                            'Outreach',
                            'Career Website',
                            'Talent Pool',
                          ],
                    scaleType: 'band',
                  },
                ]}
                series={[
                  {
                    data:
                      acquisitionSource?.length > 0
                        ? acquisitionSource?.map((val: any) =>
                            Number(val.total)
                          )
                        : [0],
                    color: '#805AD5',
                  },
                ]}
                width={720}
                height={250}
              />
            </ThemeProvider>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
export default AdminDashboard;

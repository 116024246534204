import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';

import Verified from '../../../../assets/images/MdVerified.svg';

import toUrl from 'utils/toUrl';
import Button from 'Library/Button';
import {
  useGetCandidatesMutation,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import FAIcon from 'components/lib/FAIcon';
import WorkStatus from './WorkStatus';
import Degree from './Degree';
import CandidateSource from './CandidateSource';

const CandidatesPersonalInformation = () => {
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );

  const [isHideContact, setIsHideContact] = useState(false);
  const [isHideEmails, setIsHideEmails] = useState(false);

  const [reqCandidatesList] = useListCandidatesMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();

  const [workStatusUpdate, SetWorkStatusUpdate] = useState(false);

  const contactPopulate = (data: any) => {
    const contactSet: any = [];

    Promise.all(
      data.map(async (item: any) => {
        contactSet.push(item);
      })
    );

    const memoizedContacts = (data: any) => {
      return (
        <>
          {data.map((contact: any, index: any) => {
            return (
              <Flex gap="10px" alignItems="center">
                <Box color="primary.800">
                  <FAIcon
                    iconName={
                      contact?.category.toLowerCase() === 'work'
                        ? 'briefcase'
                        : 'user'
                    }
                  />
                </Box>
                <Box sx={{ fontWeight: 400 }}>
                  {index + 1 !== candidateData?.contact.length
                    ? contact?.number
                    : contact?.number}
                </Box>
                <Flex gap="5px" alignItems="center">
                  {contact?.valid && (
                    <Image src={Verified} alt="logo" height="14px" />
                  )}
                  {contact?.primary && (
                    <Box
                      sx={{
                        button: {
                          p: '4px 8px',
                          borderRadius: '6px',
                          height: '20px',
                        },
                      }}
                    >
                      <Button variant="solid">Primary</Button>
                    </Box>
                  )}
                </Flex>
              </Flex>
            );
          })}
        </>
      );
    };

    if (!isHideContact && contactSet.length > 3) {
      return (
        <>
          {memoizedContacts(contactSet.slice(0, 3))}
          <Box
            id={`tagTooltip`}
            color="primary.600"
            fontSize="14px"
            lineHeight="14.62px"
            height="fit-content"
            cursor="pointer"
            onClick={() => setIsHideContact((prev) => !prev)}
          >
            Show {contactSet.length - 3}+ contacts
          </Box>
        </>
      );
    }
    return memoizedContacts(data);
  };

  const mailPopulate = (data: any) => {
    const mailSet: any = [];

    Promise.all(
      data.map(async (item: any) => {
        mailSet.push(item);
      })
    );

    const memoizedEmails = (data: any) => {
      return (
        <>
          {data.map((email: any, index: any) => {
            return (
              <Flex gap="10px" alignItems="center">
                <Box sx={{ display: 'flex', fontWeight: 400, gap: '7px' }}>
                  {index + 1 !== candidateData?.emails.length
                    ? email?.email
                    : email?.email}
                </Box>
                {email?.valid && (
                  <Image src={Verified} alt="logo" height="14px" />
                )}
                {email?.primary && (
                  <Box
                    sx={{
                      button: {
                        p: '4px 8px',
                        borderRadius: '6px',
                        height: '20px',
                      },
                    }}
                  >
                    <Button variant="solid">Primary</Button>
                  </Box>
                )}
              </Flex>
            );
          })}
        </>
      );
    };

    if (!isHideEmails && mailSet.length > 3) {
      return (
        <>
          {memoizedEmails(mailSet.slice(0, 3))}
          <Box
            id={`tagTooltip`}
            color="primary.600"
            fontSize="14px"
            lineHeight="14.62px"
            height="fit-content"
            cursor="pointer"
            onClick={() => setIsHideEmails((prev) => !prev)}
          >
            Show {mailSet.length - 3}+ emails
          </Box>
        </>
      );
    }
    return memoizedEmails(data);
  };

  useEffect(() => {
    if (workStatusUpdate) {
      reqCandidatesList(candidatePgBtn);
      reqGetCandidate({ id: candidateData.id });
    }
  }, [candidateData.id, candidatePgBtn, workStatusUpdate]);

  return (
    <Box color="default.primarytext">
      <Flex py={3} gap="50px">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Contact
        </Box>
        <Flex
          sx={{
            flexDir: 'column',
            textAlign: 'left',
            fontSize: '16px',
            lineHeight: '17.05px',
            gap: '8px',
          }}
        >
          {candidateData?.contact && contactPopulate(candidateData?.contact)}
        </Flex>
      </Flex>
      <Flex py={3} gap="50px">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Email
        </Box>
        <Flex
          sx={{
            flexDir: 'column',
            textAlign: 'left',
            fontSize: '16px',
            lineHeight: '17.05px',
            gap: '8px',
          }}
        >
          {candidateData?.emails && mailPopulate(candidateData?.emails)}
        </Flex>
      </Flex>
      <Flex py={3} gap="50px" alignItems="center">
        <WorkStatus candidateData={candidateData} SetWorkStatusUpdate={SetWorkStatusUpdate} />
      </Flex>

      <Flex py={3} gap="50px" alignItems="center">
        <Degree candidateData={candidateData} />
      </Flex>
      <Flex py={3} gap="50px">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Linkedin
        </Box>
        <Flex
          sx={{
            textAlign: 'left',
            fontSize: '16px',
            lineHeight: '17.05px',
            gap: '5px',
          }}
        >
          <Link
            href={toUrl(candidateData.linkedin_url)}
            target="_blank"
            style={{
              fontWeight: 400,
              fontSize: '16px',
              width: '300px',
              overflow: 'hidden',
            }}
            rel="noreferrer"
          >
            {candidateData.linkedin_url}
          </Link>
        </Flex>
      </Flex>
      <Flex py={3} gap="50px">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Address
        </Box>
        <Box
          textAlign="left"
          fontSize="16px"
          lineHeight="18px"
          sx={{ textWrap: 'balance' }}
        >
          {[candidateData.city, candidateData.state_province]
            .filter(Boolean)
            .join(', ')}
        </Box>
      </Flex>
      <CandidateSource candidateData={candidateData} />
    </Box>
  );
};

export default CandidatesPersonalInformation;

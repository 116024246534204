import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import {
  listJobs,
  useListJobsMutation,
  useListLeadJobsMutation,
} from 'store/jobs.slice';

import JobsModal from '../Jobs/Modal';
import TrLoading from '../TrLoading';

import { JobsData } from 'types';
import { useParams } from 'react-router';
import { Formik } from 'formik';
import ScrollToFieldError from '../ScrollError';
import Pagination from 'Library/Pagination';

const LeadsJobs = () => {
  const dispatch = useDispatch();
  const UrlParams = useParams();

  const { leadData, leadDetails } = useSelector((state: any) => state.leads);
  const { jobPgBtn, jobs, jobPage } = useSelector((state: any) => state.jobs);

  const [reqList, resList] = useListLeadJobsMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isOpenCheck, setOpenCheck] = useState(false);
  const [isCloseCheck, setCloseCheck] = useState(false);
  const [isQuickJobCheck, setQuickJobCheck] = useState(false);
  const [isAssignCheck, setAssignCheck] = useState(false);
  const [defCompany, setDefCompany] = useState({});
  const [defContact, setDefContact] = useState({});

  useEffect(() => {
    reqList({ data: jobPgBtn, id: UrlParams.leadsId });
  }, [UrlParams.leadsId]);

  useEffect(() => {
    setDefCompany({
      label: leadDetails?.lead?.client?.name,
      value: leadDetails?.client?.client_id,
    });
    setDefContact({
      label: [leadDetails?.lead?.first_name, leadDetails?.lead?.last_name].join(
        ' '
      ),
      value: leadDetails?.lead?.id,
    });
  }, [leadDetails]);

  const tableHeader: { label: any }[] = [
    { label: 'Job Details' },
    {
      label: (
        <Box>
          <FAIcon iconName="clipboard" /> Submits
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="phone" /> Interview
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="location-pin" /> Offered
        </Box>
      ),
    },
    {
      label: (
        <Box>
          <FAIcon iconName="diamond" /> Placed
        </Box>
      ),
    },
    { label: '' },
  ];
  const submitForm = (params: any) => {
    dispatch(
      listJobs({
        jobPgBtn: params,
      })
    );
    reqList({ data: params, id: UrlParams.leadsId });
  };

  const filterSubmit = (params: any) => {
    submitForm(params);
  };

  const initialValues = {
    search: '',
  };

  const pageChange = async (page: number) => {
    let param = {
      page: page,
      take: jobPgBtn.take,
      query: jobPgBtn.query,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    submitForm(param);
  };

  const onSubmit = (data: any) => {
    let param = {
      query: data.search,
      page: jobPgBtn.page,
      take: jobPgBtn.take,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    submitForm(param);
  };

  const entryChange = (entries: number) => {
    let param = {
      page: 1,
      take: entries,
      query: jobPgBtn.query,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    submitForm(param);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let param = {
        query: '',
        page: jobPgBtn.page,
        take: jobPgBtn.take,
        closed: jobPgBtn.closed,
        open: jobPgBtn.open,
        quickJobs: jobPgBtn.quickJobs,
        job_assigned: jobPgBtn.job_assigned,
        job_ads: jobPgBtn.job_ads,
      };
      submitForm(param);
    }
  };

  return (
    <Box>
      <Flex justifyContent="space-between" py={6}>
        <Flex>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <ScrollToFieldError />
                <Box pr={2}>
                  <InputGroup>
                    <InputLeftElement children={<FAIcon iconName="search" />} />
                    <Input
                      type="search"
                      placeholder="Search Jobs"
                      name="search"
                      value={values.search}
                      onChange={(e: any) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </Box>
              </form>
            )}
          </Formik>

          <Flex px={2} justifyContent="flex-start">
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isOpenCheck}
              onChange={(e) => {
                setOpenCheck(e.target.checked);
                let params = {
                  page: 1,
                  take: jobPgBtn.take,
                  query: jobPgBtn.query,
                  closed: isCloseCheck,
                  open: e.target.checked,
                  quickJobs: isQuickJobCheck,
                  job_assigned: isAssignCheck,
                  job_ads: jobPgBtn.job_ads,
                };
                filterSubmit(params);
              }}
            >
              Open
            </Checkbox>
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isCloseCheck}
              onChange={(e) => {
                setCloseCheck(e.target.checked);
                let params = {
                  page: 1,
                  take: jobPgBtn.take,
                  query: jobPgBtn.query,
                  closed: e.target.checked,
                  open: isOpenCheck,
                  quickJobs: isQuickJobCheck,
                  job_assigned: isAssignCheck,
                  job_ads: jobPgBtn.job_ads,
                };
                filterSubmit(params);
              }}
            >
              Close
            </Checkbox>
          </Flex>
        </Flex>

        <Flex>
          <Flex px={2} justifyContent="flex-start">
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isQuickJobCheck}
              onChange={(e) => {
                setQuickJobCheck(e.target.checked);
                let params = {
                  page: 1,
                  take: jobPgBtn.take,
                  query: jobPgBtn.query,
                  closed: isCloseCheck,
                  open: isOpenCheck,
                  quickJobs: e.target.checked,
                  job_assigned: isAssignCheck,
                  job_ads: jobPgBtn.job_ads,
                };
                filterSubmit(params);
              }}
            >
              Quick Jobs
            </Checkbox>
            <Checkbox
              colorScheme="purple"
              color="default.secondarytext"
              mx={1}
              isChecked={isAssignCheck}
              onChange={(e) => {
                setAssignCheck(e.target.checked);
                let params = {
                  page: 1,
                  take: jobPgBtn.take,
                  query: jobPgBtn.query,
                  closed: isCloseCheck,
                  open: isOpenCheck,
                  quickJobs: isQuickJobCheck,
                  job_assigned: e.target.checked,
                  job_ads: jobPgBtn.job_ads,
                };
                filterSubmit(params);
              }}
            >
              Job Assigned to me
            </Checkbox>
          </Flex>
          <Button leftIcon="plus" variant="solid" onClick={onOpen}>
            Create New Job
          </Button>
        </Flex>

        {isOpen && (
          <JobsModal
            isOpen={isOpen}
            onClose={onClose}
            modalTitle="Create New Job"
            isDrawer
            searchableLeads={false}
            companyDef={defCompany}
            contactDef={defContact}
          />
        )}
      </Flex>

      <Box>
        <TableContainer
          boxSizing="border-box"
          border="1px solid"
          borderColor="default.white.400"
          borderRadius="md"
          minHeight="45vh"
        >
          <Table>
            <Thead>
              <Tr bg="default.white.800" key="client-job-header">
                {tableHeader.map((title) => (
                  <Th key={title.label}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                ))}
              </Tr>
            </Thead>

            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {resList.isLoading ? (
                <TrLoading rows={3} columns={6} />
              ) : (
                jobs &&
                jobs?.map((jobs: JobsData) => (
                  <Tr key={jobs.id} cursor="pointer" onClick={() => {}}>
                    <Td>
                      <Link href={`/jobs/${jobs.id}/details`}>
                        {jobs.title}
                      </Link>
                    </Td>
                    <Td>{jobs.submits}</Td>
                    <Td>{jobs.interview}</Td>
                    <Td>{jobs.offered}</Td>
                    <Td>{jobs.placed}</Td>
                    <Td>
                      <Menu>
                        <MenuButton>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="ellipsis-h"
                            iconSize="xl"
                          />
                        </MenuButton>
                      </Menu>
                    </Td>
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          totalPages={jobPage.lastPage}
          currentPage={jobPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={jobPage.count}
          currentCount={jobs?.length}
          targetCount={jobPgBtn.take}
        />
      </Box>
    </Box>
  );
};

export default LeadsJobs;

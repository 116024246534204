import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import { getClient, navClient } from 'store/client.slice';
import ClientEditModal from '../Modal';
import MyClientsTabs from './Tabs';
import toUrl from 'utils/toUrl';
import { listContact } from 'store/contact.slice';

interface MyClientProps {
  isOpen: boolean;
  onClose: () => void;
  rowId: number;
  totalClients: number;
  handlePrevData: (client: any) => void;
  handleNextData: (client: any) => void;
}

export default function MyClientsDrawer({
  isOpen,
  onClose,
  rowId,
  totalClients,
  handlePrevData,
  handleNextData,
}: MyClientProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { clientNav, MyClients, clientData, clientTab } = useSelector(
    (state: any) => state.clients
  );
  const { contactPageBtn } = useSelector((state: any) => state.contacts);

  const clientID = params.clientsId;

  const {
    isOpen: isEditModalOpen,
    onOpen: onEditModalOpen,
    onClose: onEditModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (clientID) {
      const getData =
        MyClients &&
        MyClients.filter((client: any) => client.id === Number(clientID))[0];
      dispatch(getClient({ clientData: getData }));
    }
  }, [clientID]);

  useEffect(() => {
    if (clientNav === '') {
      onClose();
    }
  }, [clientNav]);

  const handleOpenDrawer = () => {
    if (clientNav === 'my-clients' && clientID) {
      return true;
    }
    return isOpen;
  };

  return (
    <>
      {clientData && (
        <Drawer
          key={clientData.id}
          onClose={() => {
            onClose();
            navigate('/clients');
            dispatch(getClient({ clientData: {} }));
            dispatch(navClient({ clientNav: clientTab }));
            dispatch(
              listContact({ contactPageBtn: { ...contactPageBtn, types: [4] } })
            );
          }}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="80vw"
            top="62px! important"
            fontFamily="Proxima Nova Regular"
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bgColor="#E6E9EE"
              color="#6B6C7B"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            <DrawerHeader
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p="0 0 0 32px"
              borderBottom="1px solid #E6E9EE"
              fontSize="sm"
              fontWeight="500"
              color="#6B6C7B"
            >
              <Box>
                Showing <b>{rowId}</b> of {totalClients}
              </Box>
              <Flex>
                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-left"
                    variant="ghost"
                    onClick={() => rowId !== 1 && handlePrevData(clientData)}
                    disabled={rowId === 1}
                  />
                </Box>

                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-right"
                    variant="ghost"
                    onClick={() =>
                      rowId !== totalClients && handleNextData(clientData)
                    }
                    disabled={rowId === totalClients}
                  />
                </Box>
              </Flex>
            </DrawerHeader>
            <DrawerBody p="0">
              <Box
                bg="default.white.800"
                borderBottom="1px solid"
                borderColor="default.white.400"
                p="32px"
                pb="60px"
              >
                <Flex justifyContent="space-between" mb="10px">
                  <Box
                    fontSize="20px"
                    fontWeight="600"
                    color="default.primarytext"
                  >
                    {clientData.name}
                  </Box>
                  <Button
                    variant="solid"
                    leftIcon="pencil"
                    onClick={() => {
                      dispatch(getClient({ clientData: clientData }));
                      onEditModalOpen();
                    }}
                  >
                    Edit
                  </Button>
                </Flex>
                <Flex justifyContent="space-between">
                  <Box>
                    <Flex
                      gap={2}
                      fontSize="md"
                      color="default.gray.600"
                      mb="10px"
                    >
                      <Box fontSize="md" color="default.gray.800">
                        <Link
                          href={toUrl(clientData.website)}
                          target="_blank"
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                        >
                          {toUrl(clientData.website)}
                        </Link>
                      </Box>
                      |
                      <Box fontSize="md" color="default.gray.800">
                        {clientData.location}
                      </Box>
                    </Flex>
                    <Flex
                      alignItems="center"
                      fontSize="md"
                      color="default.gray.600"
                    >
                      <Flex gap={2}>
                        <Box fontSize="sm" color="default.secondarytext">
                          Added on:{' '}
                          {moment(clientData.created_at).format('MM/DD/YYYY')}
                        </Box>
                      </Flex>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
              <Box mt="-39px" p="0 32px">
                <MyClientsTabs />
              </Box>
            </DrawerBody>

            {isEditModalOpen && (
              <ClientEditModal
                isOpen={isEditModalOpen}
                onClose={onEditModalClose}
              />
            )}
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import ScrollToFieldError from 'components/app/ScrollError';
import FAIcon from 'components/lib/FAIcon';
import { Formik } from 'formik';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  listCandidates,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import { listJobs } from 'store/jobs.slice';

export default function JobsInProgress() {
  document.documentElement.dir = 'ltr';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const { jobData } = useSelector((state: any) => state.jobs);
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const storageName = 'candidatesFilterProgress';
  const defaultParam = localStorage.getItem(storageName) as any;

  const initialValue = {
    search: defaultParam?.query || '',
  };

  let subs: any = [];
  subs['submits'] = [1];
  subs['screens'] = [2];
  subs['interviews'] = [3];
  subs['offers'] = [4];
  subs['hired'] = [5];
  subs['rejected'] = [6];

  let status: any;

  if (params?.jobsTabId) {
    switch (params?.jobsTabId) {
      case 'screen':
        status = [2];
        break;
      case 'in-progress':
        status = params?.jobsTabNav ? subs[params.jobsTabNav] : [1];
        break;
      case 'hired':
        status = [5];
        break;
      case 'rejected':
        status = [6];
        break;
      default:
        status = null;
    }
  }

  const [reqCandidates] = useListCandidatesMutation();

  const onSubmit = (data: any) => {
    let newParams = {
      ...candidatePgBtn,
      page: 1,
      take: candidatePgBtn.take,
      query: data.search,
      ...(params.jobsTabId !== 'candidate-pool' && { status: status }),
      ...(data?.job_id &&
        params.jobsTabId === 'screen' && { job_id: data.job_id }),
    };
    submitForm(newParams);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        ...candidatePgBtn,
        page: 1,
        take: candidatePgBtn.take,
        query: '',
      };
      submitForm(params);
    }
  };

  const submitForm = (params: any) => {
    dispatch(
      listCandidates({
        candidatePgBtn: params,
      })
    );
    reqCandidates(params);
    localStorage.setItem(storageName, JSON.stringify(params));
  };

  const handleRoute = useCallback(
    (route: string) => {
      dispatch(
        listJobs({
          jobRoute: route,
        })
      );
      navigate(`/jobs/${jobData.id}/view-candidates/in-progress/${route}`);
    },
    [jobData.id, navigate]
  );

  useEffect(() => {
    if (params.jobsTabId === 'in-progress' && !params.jobsTabNav) {
      handleRoute('submits');
    }
  }, [params.jobsTabId, params.jobsTabNav]);

  return (
    <>
      <Flex justifyContent="space-between" px={6} pt="8px">
        <Flex gap="10px">
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Box pr={2}>
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <InputGroup background="#fff">
                    <InputLeftElement
                      cursor="pointer"
                      children={
                        <Box sx={{ color: 'primary.800' }}>
                          <FAIcon iconName="search" />
                        </Box>
                      }
                    />
                    <Input
                      type="search"
                      placeholder="Search by name, phone, email"
                      value={values.search}
                      id="search"
                      width="300px"
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </form>
              </Box>
            )}
          </Formik>
        </Flex>
      </Flex>

      <Flex gap="18px" px={6} pt="25px">
        <Box
          p="20px"
          bg="#fff"
          width="112px"
          border={
            params.jobsTabNav === 'submits'
              ? '2px solid #6930CA'
              : '0.5px solid rgba(197, 197, 197, 0.8)'
          }
          borderRadius="4px"
          cursor="pointer"
          _hover={{
            outline:
              params.jobsTabNav === 'submits' ? 'none' : '2px solid #6930CA',
            borderRadius: '4px',
          }}
          onClick={() => handleRoute('submits')}
        >
          <Box
            marginBottom="8px"
            fontWeight={500}
            fontSize="14px"
            lineHeight="18px"
            color="#6B6C7B"
          >
            Submits
          </Box>
          <Box fontWeight={700} fontSize="20px" lineHeight="18px">
            {jobData.submits}
          </Box>
        </Box>
        <Box
          p="20px"
          bg="#fff"
          width="112px"
          border={
            params.jobsTabNav === 'interviews'
              ? '2px solid #6930CA'
              : '0.5px solid rgba(197, 197, 197, 0.8)'
          }
          borderRadius="4px"
          cursor="pointer"
          _hover={{
            outline:
              params.jobsTabNav === 'interviews' ? 'none' : '2px solid #6930CA',
            borderRadius: '4px',
          }}
          onClick={() => handleRoute('interviews')}
        >
          <Box
            marginBottom="8px"
            fontWeight={500}
            fontSize="14px"
            lineHeight="18px"
            color="#6B6C7B"
          >
            Interviews
          </Box>
          <Box fontWeight={700} fontSize="20px" lineHeight="18px">
            {jobData.interview}
          </Box>
        </Box>
        <Box
          p="20px"
          bg="#fff"
          width="112px"
          border={
            params.jobsTabNav === 'offers'
              ? '2px solid #6930CA'
              : '0.5px solid rgba(197, 197, 197, 0.8)'
          }
          borderRadius="4px"
          cursor="pointer"
          _hover={{
            outline:
              params.jobsTabNav === 'offers' ? 'none' : '2px solid #6930CA',
            borderRadius: '4px',
          }}
          onClick={() => handleRoute('offers')}
        >
          <Box
            marginBottom="8px"
            fontWeight={500}
            fontSize="14px"
            lineHeight="18px"
            color="#6B6C7B"
          >
            Offers
          </Box>
          <Box fontWeight={700} fontSize="20px" lineHeight="18px">
            {jobData.offered}
          </Box>
        </Box>
      </Flex>
    </>
  );
}

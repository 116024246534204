import {
  Box,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import Button from '../../../Library/Button';

import FAIcon from '../../lib/FAIcon';
import JobsTable from './Table';
import JobsModal from './Modal';
import { useEffect, useState } from 'react';
import JobsViewCandidatesPage from './ViewCandidates/ViewCandidatesPage';
import { useParams } from 'react-router';
import { Formik } from 'formik';
import ScrollToFieldError from '../ScrollError';
import { useDispatch, useSelector } from 'react-redux';
import { listJobs, useListJobsMutation } from 'store/jobs.slice';
import TrLoading from '../TrLoading';
import { listCandidates } from 'store/candidates.slice';
import HiredModal from './ViewCandidates/form/HiredModal';

export default function JobsPage() {
  const params = useParams();
  const dispatch = useDispatch();
  document.documentElement.dir = 'ltr';
  const storageName = 'jobFilter';

  const { isOpen, onOpen, onClose } = useDisclosure();

  // const {
  //   isOpen: isOpenHired,
  //   onOpen: onOpenHired,
  //   onClose: onCloseHired,
  // } = useDisclosure({defaultIsOpen: true});

  const [reqList, resList] = useListJobsMutation();

  const { candidateOthers } = useSelector((state: any) => state.candidates);
  const { jobPgBtn } = useSelector((state: any) => state.jobs);

  const jobFilerData: string | null = localStorage.getItem(storageName);
  const initFilter = jobFilerData == null ? jobPgBtn : JSON.parse(jobFilerData);

  useEffect(() => {
    if (params['*'] == 'jobs') {
      reqList(initFilter);
    }
  }, [params['*']]);

  const hasJobFormData: string | null = localStorage.getItem('jobFormData');

  useEffect(() => {
    const initJobFormData =
      hasJobFormData == null ? {} : JSON.parse(hasJobFormData);
    if (Object.keys(initJobFormData).length > 0) {
      onOpen();
    }
  }, [hasJobFormData, onOpen]);

  const [isViewCandidates, setIsViewCandidates] = useState(false);
  const [isOpenCheck, setOpenCheck] = useState(false);
  const [isCloseCheck, setCloseCheck] = useState(false);
  const [isDraftCheck, setDraftCheck] = useState(false);
  const [isAllJobCheck, setAllJobCheck] = useState(false);
  const [isQuickJobCheck, setQuickJobCheck] = useState(false);
  const [isFullJobCheck, setFullJobCheck] = useState(false);
  const [isAssignCheck, setAssignCheck] = useState(false);

  useEffect(() => {
    dispatch(
      listCandidates({
        candidateOthers: [{ ...candidateOthers, group_by_job: false }],
      })
    );
    if (params.jobsTab === 'view-candidates') {
      setIsViewCandidates(true);
    } else {
      setIsViewCandidates(false);
    }
  }, [params.jobsTab]);

  useEffect(() => {
    setOpenCheck(Boolean(initFilter.open));
    setCloseCheck(Boolean(initFilter.closed));
    setDraftCheck(Boolean(initFilter.draft));
    setFullJobCheck(Boolean(initFilter.full));
    setAllJobCheck(Boolean(initFilter.all));
    setQuickJobCheck(Boolean(initFilter.quickJobs));
    setAssignCheck(Boolean(initFilter.job_assigned));

    dispatch(
      listJobs({
        jobPgBtn: initFilter,
      })
    );
  }, []);

  const initialValue = {
    search: initFilter.query,
  };

  const onSubmit = (data: any) => {
    let params = {
      page: 1,
      take: jobPgBtn.take,
      query: data.search,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      draft: jobPgBtn.draft,
      full: jobPgBtn.full,
      all: jobPgBtn.all,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    submitForm(params);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        page: 1,
        take: jobPgBtn.take,
        query: '',
        closed: jobPgBtn.closed,
        open: jobPgBtn.open,
        draft: jobPgBtn.draft,
        full: jobPgBtn.full,
        all: jobPgBtn.all,
        quickJobs: jobPgBtn.quickJobs,
        job_assigned: jobPgBtn.job_assigned,
        job_ads: jobPgBtn.job_ads,
      };
      submitForm(params);
    }
  };

  const filterSubmit = (params: any) => {
    submitForm(params);
  };

  const submitForm = (params: any) => {
    dispatch(
      listJobs({
        jobPgBtn: params,
      })
    );
    reqList(params);
    localStorage.setItem(storageName, JSON.stringify(params));
  };

  const groupingChange = (e: any) => {
    const grouping = parseInt(e.target.value) === 0 ? false : true;
    let params = {
      page: 1,
      take: jobPgBtn.take,
      query: jobPgBtn.query,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      draft: jobPgBtn.draft,
      full: jobPgBtn.full,
      all: jobPgBtn.all,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: grouping,
    };
    submitForm(params);
  };

  const radioGroup = initFilter.job_ads || jobPgBtn.job_ads ? '1' : '0';

  return (
    <>
      {isViewCandidates ? (
        <JobsViewCandidatesPage />
      ) : (
        <Box fontFamily="Proxima Nova Regular" padding="8px 16px">
          <RadioGroup defaultValue={radioGroup.toString()} mb="10px" px={6}>
            <Stack spacing={5} direction="row">
              <Radio
                colorScheme="purple"
                name="groupings"
                onChange={groupingChange}
                value="0"
              >
                Group by Job
              </Radio>
              <Radio
                colorScheme="purple"
                name="groupings"
                onChange={groupingChange}
                value="1"
              >
                Group by Job Ads
              </Radio>
            </Stack>
          </RadioGroup>

          <Flex justifyContent="space-between" mb="16px" px={6}>
            <Flex
              justifyContent="space-around"
              maxWidth="40%"
              alignItems="center"
            >
              <Formik
                enableReinitialize
                initialValues={initialValue}
                onSubmit={onSubmit}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Box pr={2}>
                    <form onSubmit={handleSubmit}>
                      <ScrollToFieldError />
                      <InputGroup background="#fff">
                        <InputRightElement
                          cursor="pointer"
                          children={<FAIcon iconName="search" />}
                        />
                        <Input
                          type="search"
                          placeholder="Search Jobs"
                          value={values.search}
                          id="search"
                          onChange={(e) => {
                            handleChange(e);
                            clearSearch(e);
                          }}
                        />
                      </InputGroup>
                    </form>
                  </Box>
                )}
              </Formik>

              <Flex px={2} justifyContent="flex-start">
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  isChecked={isOpenCheck}
                  onChange={(e) => {
                    setOpenCheck(e.target.checked);
                    let params = {
                      page: 1,
                      take: jobPgBtn.take,
                      query: jobPgBtn.query,
                      closed: isCloseCheck,
                      open: e.target.checked,
                      draft: isDraftCheck,
                      full: isFullJobCheck,
                      all: isAllJobCheck,
                      quickJobs: isQuickJobCheck,
                      job_assigned: isAssignCheck,
                      job_ads: jobPgBtn.job_ads,
                    };
                    filterSubmit(params);
                  }}
                >
                  Open
                </Checkbox>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  isChecked={isCloseCheck}
                  onChange={(e) => {
                    setCloseCheck(e.target.checked);
                    let params = {
                      page: 1,
                      take: jobPgBtn.take,
                      query: jobPgBtn.query,
                      closed: e.target.checked,
                      open: isOpenCheck,
                      draft: isDraftCheck,
                      full: isFullJobCheck,
                      all: isAllJobCheck,
                      quickJobs: isQuickJobCheck,
                      job_assigned: isAssignCheck,
                      job_ads: jobPgBtn.job_ads,
                    };
                    filterSubmit(params);
                  }}
                >
                  Close
                </Checkbox>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  isChecked={isDraftCheck}
                  onChange={(e) => {
                    setDraftCheck(e.target.checked);
                    let params = {
                      page: 1,
                      take: jobPgBtn.take,
                      query: jobPgBtn.query,
                      draft: e.target.checked,
                      closed: isCloseCheck,
                      open: isOpenCheck,
                      full: isFullJobCheck,
                      all: isAllJobCheck,
                      quickJobs: isQuickJobCheck,
                      job_assigned: isAssignCheck,
                      job_ads: jobPgBtn.job_ads,
                    };
                    filterSubmit(params);
                  }}
                >
                  Draft
                </Checkbox>
              </Flex>
            </Flex>

            <Flex>
              <Flex px={2} justifyContent="flex-start">
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  isChecked={isAllJobCheck}
                  onChange={(e) => {
                    setAllJobCheck(e.target.checked);
                    let params = {
                      page: 1,
                      take: jobPgBtn.take,
                      query: jobPgBtn.query,
                      closed: isCloseCheck,
                      draft: isDraftCheck,
                      open: isOpenCheck,
                      full: isFullJobCheck,
                      all: e.target.checked,
                      quickJobs: isQuickJobCheck,
                      job_assigned: isAssignCheck,
                      job_ads: jobPgBtn.job_ads,
                    };
                    filterSubmit(params);
                  }}
                >
                  All Jobs
                </Checkbox>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  isChecked={isQuickJobCheck}
                  onChange={(e) => {
                    setQuickJobCheck(e.target.checked);
                    let params = {
                      page: 1,
                      take: jobPgBtn.take,
                      query: jobPgBtn.query,
                      closed: isCloseCheck,
                      draft: isDraftCheck,
                      open: isOpenCheck,
                      full: isFullJobCheck,
                      all: isAllJobCheck,
                      quickJobs: e.target.checked,
                      job_assigned: isAssignCheck,
                      job_ads: jobPgBtn.job_ads,
                    };
                    filterSubmit(params);
                  }}
                >
                  Quick Jobs
                </Checkbox>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  isChecked={isFullJobCheck}
                  onChange={(e) => {
                    setFullJobCheck(e.target.checked);
                    let params = {
                      page: 1,
                      take: jobPgBtn.take,
                      query: jobPgBtn.query,
                      closed: isCloseCheck,
                      draft: isDraftCheck,
                      open: isOpenCheck,
                      full: e.target.checked,
                      all: isAllJobCheck,
                      quickJobs: isQuickJobCheck,
                      job_assigned: isAssignCheck,
                      job_ads: jobPgBtn.job_ads,
                    };
                    filterSubmit(params);
                  }}
                >
                  Full Jobs
                </Checkbox>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  isChecked={isAssignCheck}
                  onChange={(e) => {
                    setAssignCheck(e.target.checked);
                    let params = {
                      page: 1,
                      take: jobPgBtn.take,
                      query: jobPgBtn.query,
                      closed: isCloseCheck,
                      open: isOpenCheck,
                      draft: isDraftCheck,
                      full: isFullJobCheck,
                      all: isAllJobCheck,
                      quickJobs: isQuickJobCheck,
                      job_assigned: e.target.checked,
                      job_ads: jobPgBtn.job_ads,
                    };
                    filterSubmit(params);
                  }}
                >
                  Job Assigned to me
                </Checkbox>
              </Flex>
              <Button leftIcon="plus" variant="solid" onClick={onOpen}>
                Create New Job
              </Button>
            </Flex>

            {isOpen && (
              <JobsModal
                isOpen={isOpen}
                onClose={onClose}
                modalTitle="Create New Job"
              />
            )}
          </Flex>
          <Box>
            {resList.isLoading ? (
              <Box px={6} pt={7} justifyContent="space-between">
                <TableContainer
                  boxSizing="border-box"
                  border="1px solid"
                  borderColor="default.white.400"
                  borderRadius="md"
                  minHeight="70vh"
                >
                  <Table>
                    <Thead height="40.5px">
                      <Tr bg="default.white.600">
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody
                      boxSizing="border-box"
                      background="default.white.100"
                      borderBottom="1px solid"
                      borderColor="default.white.400"
                    >
                      <TrLoading rows={7} columns={10} />
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            ) : (
              <JobsTable setIsViewCandidates={setIsViewCandidates} />
            )}

            {/* <HiredModal isOpen={isOpenHired} onClose={onCloseHired} /> */}
          </Box>
        </Box>
      )}
    </>
  );
}

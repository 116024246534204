import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import Pagination from 'Library/Pagination';

import {
  getCandidate,
  listCandidates,
  useBulkRemoveFromJobCandidateMutation,
  useBulkUnRejectCandidateMutation,
  useDeleteCandidatesMutation,
  useHiredCandidateMutation,
  useListCandidatesMutation,
  useOfferCandidateMutation,
  useRemoveFromJobCandidateMutation,
  useScreenCandidateMutation,
  useUnRejectCandidateMutation,
  useToggleHideCandidatesMutation,
  useGetCandidatesMutation,
} from 'store/candidates.slice';

import {
  CandidateData,
  candidateJobs,
  CandidatesTypes,
  swalContent,
} from 'types';
import { AtsConfirm } from 'utils/swal';
import { useNavigate, useParams } from 'react-router';
import ChangeStatusModal from './form/ChangeStatusModal';
import AddToCampaignModal from './form/AddToCampaignModal';
import SendEmailModal from './form/SendEmailModal';
import CandidatesInterviewModal from 'components/app/Candidates/Interviews/modal';
import SubmitToClientModal from './form/SubmitToClientModal';
import CandidateTR from 'components/app/Candidates/components/CandidateTR';
import MenuItemComponent from 'components/app/Candidates/components/MenuItemComponent';
import CandidatesTagModal from 'components/app/Candidates/Modals/Tag';
import jobCandidateStatus from 'constants/jobCandidateStatus';
import CandidatesDrawer from 'components/app/Candidates/Drawer';
import TrLoading from 'components/app/TrLoading';
import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';
import { useGetJobsMutation } from 'store/jobs.slice';
import RejectModal from './form/rejectModal';
import { useGetCandidatetatusMutation } from 'store/constant.slice';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import HiredModal from './form/HiredModal';
import { setClientAgreement, useListClientAgreementMutation } from 'store/clientagreement.slice';

interface TableInterface {
  hasStatus?: boolean;
}

const JobsViewCandidatesTable = ({ hasStatus = true }: TableInterface) => {
  const params = useParams();
  const dispatch = useDispatch();
  const { clientAgreementPgBtn } =
    useSelector((state: any) => state.clientAgreements);
  const { candidates, candidateData, candidatePage, candidatePgBtn } =
    useSelector((state: any) => state.candidates);
  const { jobData, jobRoute } = useSelector((state: any) => state.jobs);
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();

  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();

  const [reqUnReject, resUnReject] = useUnRejectCandidateMutation();
  const [reqBulkUnReject, resBulkUnReject] = useBulkUnRejectCandidateMutation();
  const [reqScreen, resScreen] = useScreenCandidateMutation();
  const [reqOffer, resOffer] = useOfferCandidateMutation();
  const [reqHired, resHired] = useHiredCandidateMutation();
  const [reqRemJob, resRemJob] = useRemoveFromJobCandidateMutation();
  const [reqBulkRemJob, resBulkRemJob] =
    useBulkRemoveFromJobCandidateMutation();
  const [reqAgreement, resAgreement] = useListClientAgreementMutation();

  const [reqStatus] = useGetCandidatetatusMutation();
  const [reqGetJob] = useGetJobsMutation();
  const [reqPair] = usePlaceHolderPairMutation();

  const [assocId, setAssocId] = useState(0);
  const [assocIdList, setAssocIdList] = useState([0]);
  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState(null);
  const [mainStatus, setMainStatus] = useState(0);
  const [subStatus, setSubStatus] = useState(0);
  const [intSubj, setSubject] = useState('');
  const [isSorted, setIsSorted] = useState(false);
  const [orderBy, setOrderBy] = useState(null);
  const [contactOptionValue, setContactOptionValue] = useState([]);
  const [filterCache, setFilterCache] = useState(JSON.stringify({}));
  const navigate = useNavigate();
  const [tabCandidateList, setTabCandidateList] = useState([]);

  document.documentElement.dir = 'ltr';

  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenReject,
    onOpen: onOpenReject,
    onClose: onCloseReject,
  } = useDisclosure();

  const {
    isOpen: isOpenAddTags,
    onOpen: onOpenAddTags,
    onClose: onCloseAddTags,
  } = useDisclosure();

  const {
    isOpen: isOpenAddToCampaign,
    onOpen: onOpenAddToCampaign,
    onClose: onCloseAddToCampaign,
  } = useDisclosure();

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenScheduleInterview,
    onOpen: onOpenScheduleInterview,
    onClose: onCloseScheduleInterview,
  } = useDisclosure();

  const {
    isOpen: isOpenSubmitToClient,
    onOpen: onOpenSubmitToClient,
    onClose: onCloseSubmitToClient,
  } = useDisclosure();

  const {
    isOpen: isOpenHired,
    onOpen: onOpenHired,
    onClose: onCloseHired,
  } = useDisclosure();

  useEffect(() => {
    reqStatus({});
  }, [resCandidates.isSuccess]);

  useEffect(() => {
    if (!isOpenScheduleInterview) {
      setContactOptionValue([]);
    }
  }, [isOpenScheduleInterview]);

  const candidatedFilterData: string | null = null;
  // localStorage.getItem('candidatesFilter');

  const filter =
    candidatedFilterData === null
      ? candidatePgBtn
      : JSON.parse(candidatedFilterData);

  // useMemo(() => {
  //   if (filterCache != '{}' && filterCache != JSON.stringify(filter)) {
  //     // reqCandidates(filter); //this is causing multiple calls on the api and filter issue
  //   }
  //   setFilterCache(JSON.stringify(filter));
  //   return filter;
  // }, [candidatePgBtn]);

  useEffect(() => {
    if (resCandidates.isSuccess) {
      // console.log('disable-dispatch-');
      // dispatch(listCandidates({ candidates: resCandidates.data.data.data }));
    }
  }, [resCandidates?.data?.data, resCandidates.isSuccess]);

  useEffect(() => {
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      let status = jobCandidateStatus[params.jobsTabId];
      if (params.jobsTabId === 'in-progress') {
        let subs: any = [];
        subs['submits'] = 1;
        subs['interviews'] = 3;
        subs['offers'] = 4;

        try {
          status = [subs[params.jobsTabNav]];
          if (subs[params.jobsTabNav] === null) {
            status = [1];
          }
        } catch (e) {
          status = [1];
        }
      }

      let job_id = Number(params.jobsId);
      let excluded_job_id = null;

      if (params.jobsTabId === 'candidate-pool') {
        job_id = null;
        excluded_job_id = Number(params.jobsId);
      }

      const newParam = {
        ...filter,
        excluded_job_id,
        job_id: job_id,
        status: status,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };
      submitPage(newParam, 'useEffect');
      // reqCandidates(filter);
    }
  }, [resDeleteCandidate.isSuccess, resToggleHide.isSuccess]);

  useEffect(() => {
    // console.log("=================== Jobs View Candidates Table ===================")
    let status = jobCandidateStatus[params.jobsTabId];
    if (params.jobsId) {
      reqGetJob({ id: params.jobsId });
    }

    if (params.jobsTabId === 'in-progress') {
      let subs: any = [];
      subs['submits'] = 1;
      subs['interviews'] = 3;
      subs['offers'] = 4;

      try {
        status = [subs[params.jobRoute]];
        if (subs[params.jobRoute] === null) {
          status = [1];
        }
      } catch (e) {
        status = [1];
      }
    }

    let job_id = Number(params.jobsId);
    let excluded_job_id: any = null;

    if (params.jobsTabId === 'candidate-pool') {
      excluded_job_id = Number(params.jobsId);
      job_id = null;
    }

    let newParam = {
      ...filter,
      page: 1,
      excluded_job_id,
      job_id: job_id,
      status: status,
      ...(orderBy !== null && {
        orderBy: {
          column_name: orderBy?.column_name,
          sort_order: orderBy?.sort_order,
        },
      }),
    };

    // console.log('status', status.filter(Boolean));
    if (status?.filter(Boolean).length > 0) submitPage(newParam, `useEffect`);
    // reqCandidates(newParam);

    if (resHired.isSuccess) {
      reqCandidates(filter);
    }
  }, [
    // params.jobsId,
    // params.jobsTabNav,
    resUnReject.isSuccess,
    resBulkUnReject.isSuccess,
    resScreen.isSuccess,
    resOffer.isSuccess,
    resHired.isSuccess,
    resRemJob.isSuccess,
    resBulkRemJob.isSuccess,
    // params.jobsTabId,
    // orderBy,
  ]);

  const resetFilters = () => {
    const defaultFilters = {
      page: 1,
      take: 50,
      query: '',
      job_filter: '',
      job_filter_include: 'Current',
      location: '',
      tags: [] as any,
      skills: [] as any,
      experience: [] as any,
      work_status: null as any,
      job_id: null as any,
      excluded_job_id: null as any,
      status: null as any,
      job_status: null as any,
      resume_updated_on: null as any,
      filter: null as any,
      search: '',
      boolean: true,
      reject_reason: null as any,
      source: null as any,
      sub_source: null as any,
    };

    return defaultFilters
  }

  useEffect(() => {
    let status = jobCandidateStatus[params.jobsTabId];

    let queryParamName = '';
    if (params.jobsTabId === 'in-progress') {
      queryParamName = 'candidatesFilterProgress';
      let subs: any = [];
      subs['submits'] = 1;
      subs['interviews'] = 3;
      subs['offers'] = 4;
      try {
        status = [subs[jobRoute]];
        if (subs[jobRoute] === null || subs[jobRoute] === undefined) {
          status = [1];
        }
      } catch (e) {
        status = [1];
      }
    }

    let job_id = Number(params.jobsId);
    let excluded_job_id = null;

    if (params.jobsTabId === 'candidate-pool') {
      queryParamName = 'candidatesFilterPool';
      excluded_job_id = Number(params.jobsId);
      job_id = null;
    }

    if (params.jobsTabId === 'screen') {
      queryParamName = 'candidatesFilterScreen';
    }

    if (params.jobsTabId === 'hired') {
      queryParamName = 'candidatesFilterHired';
    }

    if (params.jobsTabId === 'rejected') {
      queryParamName = 'candidatesFilterRejected';
    }

    let queryParam = localStorage.getItem(queryParamName) as any;

    if (queryParam) {
      try {
        queryParam = JSON.parse(queryParam);
      } catch (e) { }
    }

    const newParam = {
      ...filter,
      ...resetFilters(),
      job_status: null as any,
      excluded_job_id,
      job_id: job_id,
      status: status,
      query: queryParam?.query || '',
      ...(orderBy !== null && {
        orderBy: {
          column_name: orderBy?.column_name,
          sort_order: orderBy?.sort_order,
        },
      }),
    };
    submitPage(
      newParam,
      `useEffect isSorted, params.jobsTabId, params.jobsTabNav`
    );
    // reqCandidates(newParam);
  }, [isSorted, params.jobsTabId, params.jobsTabNav]);

  const handleDeleteCandidate = async (candidate: CandidatesTypes) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDeleteCandidate({ id: candidate.id });
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const handleHideCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleHide({ id: candidate.id });
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);
  const [rowId, setRowId] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [candidateId, setCandidateId] = useState(0);
  const [candidateSingleData, setCandidateData] = useState(null);
  const [isBulkAction, setIsBulkAction] = useState(false);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(candidates.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleSort = async (column_name: string) => {
    const newSortOrder = isSorted ? 'ASC' : 'DESC';
    setIsSorted(!isSorted);
    setOrderBy({
      column_name: column_name,
      sort_order: newSortOrder,
    });
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  const submitPage = (page: any, source: string) => {
    // console.log('source', source);
    let param = {
      ...page,
      filter: null,
    };
    localStorage.setItem('candidatesFilter', JSON.stringify(page));
    dispatch(
      listCandidates({
        candidatePgBtn: param,
      })
    );
    // console.log('page', { page, source });
    reqCandidates(param);
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < filter.take) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === filter.take) {
      setIsCheckAll(true);
    }
  }, [filter.take, isCheck, isCheckAll]);

  const tableHeader: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            position: 'sticky',
            left: 0,
            bg: 'default.white.600',
            color: 'default.gray.600',
            boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Flex gap="30px">
            <Checkbox
              onChange={handleSelectAll}
              isIndeterminate={isIndeterminate}
              checked={isCheckAll}
              isChecked={isCheckAll}
              defaultChecked={false}
              colorScheme="purple"
            />
            <Box>Candidate Details</Box>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Location</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Created on
            <Flex onClick={() => handleSort('created_at')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex onClick={() => handleSort('last_activity')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },

    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Associated Jobs</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Tags</Box>
        </Th>
      ),
    },
  ];

  const pageChange = async (page: number) => {
    let job_id = Number(params.jobsId);
    let excluded_job_id = null;
    if (params.jobsTabId === 'candidate-pool') {
      excluded_job_id = Number(params.jobsId);
      job_id = null;
    }

    const newParam = {
      ...filter,
      page: page,
      excluded_job_id,
      job_id: job_id,
      ...(orderBy !== null && {
        orderBy: {
          column_name: orderBy?.column_name,
          sort_order: orderBy?.sort_order,
        },
      }),
    };

    submitPage(newParam, 'pageChange');
  };

  const entryChange = (entries: number) => {
    let job_id = Number(params.jobsId);
    let excluded_job_id = null;
    if (params.jobsTabId === 'candidate-pool') {
      excluded_job_id = Number(params.jobsId);
      job_id = null;
    }

    const newParam = {
      ...filter,
      page: 1,
      take: entries,
      excluded_job_id,
      job_id: job_id,
      // status: filter.status,
      ...(orderBy !== null && {
        orderBy: {
          column_name: orderBy?.column_name,
          sort_order: orderBy?.sort_order,
        },
      }),
    };
    submitPage(newParam, 'entryChange');
  };
  const handleClick = (candidate: CandidatesTypes) => {
    if (candidate) {
      dispatch(
        getCandidate({
          candidateData: candidate,
          candidateDataJobId: Number(params.jobsId),
        })
      );

      onOpen();
      navigate(
        `${params.jobsId}/${params.jobsTab}/${params.jobsTabId}/${candidate.id}/about`
      );

      const key =
        candidates.findIndex((x: CandidatesTypes) => x.id === candidate.id) + 1;
      setRowId(key);
    }
  };
  const handlePrevData = () => {
    const candidateID = candidates[rowId - 1 - 1].id;
    setRowId((prevKey: number) => prevKey - 1);
    navigate(
      `${params.jobsId}/${params.jobsTab}/${params.jobsTabId}/${candidateID}/${params.jobsDrawerTab}`
    );
    const prevData = candidates.filter(
      (data: CandidatesTypes) => data.id === candidateID
    );
    dispatch(getCandidate({ candidateData: prevData[0] }));
  };

  const handleNextData = () => {
    const candidateID = candidates[rowId - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(
      `${params.jobsId}/${params.jobsTab}/${params.jobsTabId}/${candidateID}/${params.jobsDrawerTab}`
    );
    const nextData = candidates.filter(
      (data: CandidatesTypes) => data.id === candidateID
    );
    dispatch(getCandidate({ candidateData: nextData[0] }));
  };

  useEffect(() => {
    if (Number(candidateData.id)) {
      const data = candidates.find(
        (x: CandidatesTypes) => x.id === Number(candidateData.id)
      );
      handleClick(data);
    }
  }, [params.jobsTabNav]);

  const addTagClick = (id: any) => {
    setCandidateId(id);
    onOpenAddTags();
  };

  const editCandidate = (candidate: any) => {
    setCandidateId(candidate.id);
  };

  const moveToScreen = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This candidate will be moved to screen.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      const id = Number(candidate.id);
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: [id],
      };
      let eligable = true;
      if (candidate.jobs?.length > 0) {
        await Promise.all(
          candidate.jobs.map((item: any) => {
            if (item.job_id == params.jobsId) {
              eligable = false;
            }
          })
        );
      }
      if (eligable) {
        reqScreen({ data });
      } else {
        const content2: swalContent = {
          title: 'Duplicate entry',
          text: 'This candidate is already associated to this job.',
          icon: 'warning',
        };
        await AtsConfirm(content2);
      }
    }
  };

  const moveToOffer = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This candidate will be moved to offer.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      if (candidate.jobs?.length > 0) {
        const id = Number(candidate.id);
        const data = {
          job_id: [Number(params.jobsId)],
          candidate_id: [id],
        };
        reqOffer({ data });
      }
    }
  };

  const moveToHired = async (candidate: CandidateData) => {
    dispatch(
      getCandidate({
        candidateData: candidate,
        candidateDataJobId: Number(params.jobsId),
      })
    );
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This candidate will be moved to hired.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      let clientID = null
      console.log("candidate", candidate)
      if (candidate?.jobs?.[0]?.client?.id) {
        clientID = candidate?.jobs?.[0]?.client?.id
      } else if (jobData?.client_id && jobData?.id === candidate?.jobs?.[0]?.id) {
        clientID = jobData?.client_id
      }
      if (candidateData?.id !== candidate?.id || !candidateData?.source?.source) {
        await reqGetCandidate({ id: candidate?.id })
      }
      if (jobData?.id !== candidate?.jobs?.[0]?.id || !jobData?.lead?.lead_source?.source) {
        await reqGetJob({ id: candidate?.jobs?.[0]?.id });
      }
      if (clientID) {
        let newParam = {
          page: 1,
          take: clientAgreementPgBtn.take,
          query: '',
          status: clientAgreementPgBtn.status,
          types: clientAgreementPgBtn.types,
        };
        dispatch(
          setClientAgreement({
            leadAgreementPgBtn: newParam,
          })
        );
        await reqAgreement({ data: newParam, id: clientID });
      }

      onOpenHired();
    }
  };

  const callBackAction = () => {
    if (candidateData.jobs?.length > 0) {
      const id = Number(candidateData.id);
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: [id],
      };
      reqHired({ data });
    }
  };

  const bulkScreen = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This candidate(s) will be moved to screen.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: isCheck,
      };
      reqScreen({ data });
      reqCandidates(candidatePgBtn);
      setIsCheck([])
    }
  };

  const rejectClick = async (candidate: any) => {
    if (candidate?.jobs?.length > 0) {
      candidate?.jobs.map((job: candidateJobs) => {
        if (job?.id == params.jobsId) {
          setAssocId(Number(job?.assoc_id));
          onOpenReject();
        }
      });
    }
  };

  const unRejectClick = async (candidate: any) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to unreject this candidate.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      if (candidate.jobs?.length > 0) {
        const job = candidate.jobs[0] as candidateJobs;
        reqUnReject({ data: [], id: job.assoc_id });
      }
    }
  };

  const removeJobClick = async (candidate: any) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to remove this candidate from this job.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      if (candidate.jobs?.length > 0) {
        const job = candidate.jobs[0] as candidateJobs;
        const id = job.assoc_id;
        reqRemJob({ data: [], id });
      }
    }
  };

  const bulkRemoveJob = async () => {
    const candidateList = candidates.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to remove these candidates from this job.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    let associateIdList: any = [];

    if (confirm) {
      candidateList.map((candidate: any) => {
        if (candidate?.jobs?.length > 0) {
          candidate?.jobs.map((job: candidateJobs) => {
            if (candidate.jobs?.length > 0) {
              // const id = candidate.jobs[0]['id'];
              const job = candidate.jobs[0] as candidateJobs;
              associateIdList.push(job.assoc_id);
            }
          });
        }
      });
      const data = {
        idList: associateIdList,
      };
      reqBulkRemJob({ data });
    }
  };

  const submitToClient = async (candidate: any) => {
    setCandidateData(candidate);
    await reqGetCandidate({ id: candidate.id });
    onOpenSubmitToClient();
  };

  const changeStatus = (candidate: any) => {
    if (candidate?.jobs?.length > 0) {
      dispatch(getCandidate({ candidateData: candidate }));
      candidate?.jobs.map((job: candidateJobs) => {
        if (job?.id == params.jobsId) {
          setAssocId(Number(job?.assoc_id));
          setMainStatus(Number(job?.status.id));
          setSubStatus(Number(job?.sub.id));
          onOpenChangeStatus();
        }
      });
    }
  };

  const bulkChangeStatus = async () => {
    const candidateList = candidates.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    setIsBulkAction(true);
    let associateIdList: any = [];
    let mainStatusSet = false;
    candidateList.map((candidate: any) => {
      if (candidate?.jobs?.length > 0) {
        candidate?.jobs.map((job: candidateJobs) => {
          if (job?.id == params.jobsId) {
            associateIdList.push(Number(job?.assoc_id));
          }
          // SET THE MAIN STATUS AND SUB STATUS TO THE CURRENT STATUS OF FIRST CANDIDATE
          if (!mainStatusSet) {
            setMainStatus(Number(job?.status?.id));
            setSubStatus(Number(job?.sub?.id));
            mainStatusSet = true;
          }
        });
      }
    });
    setAssocIdList(associateIdList);
    onOpenChangeStatus();
  };

  useEffect(() => {
    if (candidates) {
      if (
        !(
          isOpenAddTags ||
          isOpenChangeStatus ||
          isOpenReject ||
          isOpenSendEmail
        )
      )
        setIsBulkAction(false);
      setIsCheckAll(false);
      // setIsCheck([]);
    }
  }, [
    candidates,
    isOpenAddTags,
    isOpenChangeStatus,
    isOpenReject,
    isOpenSendEmail,
  ]);

  const hideCandidate = () => { };

  const bulkReject = async () => {
    const candidateList = candidates.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    setIsBulkAction(true);
    let associateIdList: any = [];

    candidateList.map((candidate: any) => {
      if (candidate?.jobs?.length > 0) {
        candidate?.jobs.map((job: candidateJobs) => {
          if (job?.id == params.jobsId) {
            associateIdList.push(Number(job?.assoc_id));
          }
        });
      }
    });
    setAssocIdList(associateIdList);
    onOpenReject();
  };

  const bulkUnreject = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'You are about to unreject these candidates.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    const candidateList = candidates.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    let associateIdList: any = [];

    if (confirm) {
      candidateList.map((candidate: any) => {
        if (candidate.jobs?.length > 0) {
          // const job = candidate.jobs[0]['id'] as candidateJobs;
          try {
            associateIdList.push(candidate.jobs[0]['assoc_id']);
          } catch (e) { }
        }
      });
      let data = {
        idList: associateIdList,
      };
      reqBulkUnReject({ data });
    }
  };

  const bulkOffer = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'These candidates will be moved to offer.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);

    if (confirm) {
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: isCheck,
      };
      reqOffer({ data });
    }
  };

  const bulkHired = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'These candidates will be moved to hired.',
      buttons: ['Cancel', true],
      icon: 'info',
    };
    const confirm = await AtsConfirm(content);

    if (confirm) {
      const data = {
        job_id: [Number(params.jobsId)],
        candidate_id: isCheck,
      };
      reqHired({ data });
    }
  };

  const bulkTags = () => {
    setIsBulkAction(true);
    onOpenAddTags();
  };

  const bulkEmail = async () => {
    setIsBulkAction(true);
    const candidateList = candidates?.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );
    const emails = candidateList?.map(
      (candidate: any) => candidate?.emails[0]?.email
    );
    setEmailList(emails);
    setCandidateId(candidateList[0].id);
    dispatch(getCandidate({ candidateData: candidateList[0] }));
    onOpenSendEmail();
  };

  const CandidateButtonItem = () => {
    if (params.jobsTabId === 'in-progress') {
      if (params.jobsTabNav === 'submits') {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="circle-xmark"
              size="sm"
              onClick={bulkReject}
            >
              Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
      if (params.jobsTabNav === 'interviews') {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="file"
              size="sm"
              onClick={bulkOffer}
            >
              Move to Offer
            </Button>
            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="circle-xmark"
              size="sm"
              onClick={bulkReject}
            >
              Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
      if (params.jobsTabNav === 'offers') {
        return (
          <>
            <Button
              variant="basic"
              leftIcon="briefcase"
              size="sm"
              onClick={bulkHired}
            >
              Move to Hired
            </Button>
            <Button
              variant="basic"
              leftIcon="envelope"
              size="sm"
              onClick={bulkEmail}
            >
              Send Email
            </Button>
            <Button
              variant="basic"
              leftIcon="person-circle-check"
              size="sm"
              onClick={bulkChangeStatus}
            >
              Change Status
            </Button>
            <Button
              variant="basic"
              leftIcon="tags"
              size="sm"
              onClick={bulkTags}
            >
              Add Tags
            </Button>
            <Button
              variant="basic"
              leftIcon="circle-xmark"
              size="sm"
              onClick={bulkReject}
            >
              Reject
            </Button>
            <Button
              variant="basic"
              leftIcon="ban"
              size="sm"
              onClick={bulkRemoveJob}
            >
              Remove from Job
            </Button>
          </>
        );
      }
    }
    if (params.jobsTabId === 'candidate-pool') {
      return (
        <>
          {/* <Button
            variant="basic"
            leftIcon="clipboard"
            size="sm"
            onClick={onOpenSubmitToClient}
          >
            Submit to Client
          </Button> */}
          <Button
            variant="basic"
            leftIcon="plus"
            size="sm"
            onClick={bulkScreen}
          >
            Add to Screen
          </Button>
          <Button variant="basic" leftIcon="tags" size="sm" onClick={bulkTags}>
            Add Tags
          </Button>
          <Button
            variant="basic"
            leftIcon="eye-slash"
            size="sm"
            onClick={hideCandidate}
          >
            Hide
          </Button>
        </>
      );
    }
    if (params.jobsTabId === 'screen') {
      return (
        <>
          <Button
            variant="basic"
            leftIcon="plus"
            size="sm"
            onClick={onOpenAddToCampaign}
          >
            Add to Campaign
          </Button>
          {/* <Button
            variant="basic"
            leftIcon="clipboard"
            size="sm"
            onClick={onOpenSubmitToClient}
          >
            Submit to Client
          </Button> */}
          <Button
            variant="basic"
            leftIcon="envelope"
            size="sm"
            onClick={bulkEmail}
          >
            Send Email
          </Button>
          <Button
            variant="basic"
            leftIcon="person-circle-check"
            size="sm"
            onClick={bulkChangeStatus}
          >
            Change Status
          </Button>
          <Button variant="basic" leftIcon="tags" size="sm" onClick={bulkTags}>
            Add Tags
          </Button>
          <Button
            variant="basic"
            leftIcon="eye-slash"
            size="sm"
            onClick={hideCandidate}
          >
            Hide
          </Button>
          <Button
            variant="basic"
            leftIcon="circle-xmark"
            size="sm"
            onClick={bulkReject}
          >
            Reject
          </Button>
          <Button
            variant="basic"
            leftIcon="ban"
            size="sm"
            onClick={bulkRemoveJob}
          >
            Remove from Job
          </Button>
        </>
      );
    }
    if (params.jobsTabId === 'hired') {
      return (
        <>
          <Button
            variant="basic"
            leftIcon="envelope"
            size="sm"
            onClick={bulkEmail}
          >
            Send Email
          </Button>
          <Button
            variant="basic"
            leftIcon="person-circle-check"
            size="sm"
            onClick={bulkChangeStatus}
          >
            Change Status
          </Button>
          <Button variant="basic" leftIcon="tags" size="sm" onClick={bulkTags}>
            Add Tags
          </Button>
          <Button
            variant="basic"
            leftIcon="circle-xmark"
            size="sm"
            onClick={bulkReject}
          >
            Reject
          </Button>
          <Button
            variant="basic"
            leftIcon="ban"
            size="sm"
            onClick={bulkRemoveJob}
          >
            Remove from Job
          </Button>
        </>
      );
    }
    if (params.jobsTabId === 'rejected') {
      if (params.jobsTabNav === 'all') {
      }
      if (params.jobsTabNav === 'screens') {
      }
      if (params.jobsTabNav === 'submits') {
      }
      if (params.jobsTabNav === 'interviews') {
      }
      if (params.jobsTabNav === 'offers') {
      }
      if (params.jobsTabNav === 'hired') {
      }

      return (
        <>
          <Button
            variant="basic"
            leftIcon="person-circle-check"
            size="sm"
            onClick={bulkChangeStatus}
          >
            Change Status
          </Button>
          <Button
            variant="basic"
            leftIcon="envelope"
            size="sm"
            onClick={bulkEmail}
          >
            Send Email
          </Button>
          <Button variant="basic" leftIcon="tags" size="sm" onClick={bulkTags}>
            Add Tags
          </Button>
          <Button
            variant="basic"
            leftIcon="rotate-left"
            size="sm"
            onClick={bulkUnreject}
          >
            Un - Reject
          </Button>
          <Button
            variant="basic"
            leftIcon="ban"
            size="sm"
            onClick={bulkRemoveJob}
          >
            Remove from Job
          </Button>
        </>
      );
    }
    return <></>;
  };

  const scheduleForInterview = async (candidate: any) => {
    const jobs: candidateJobs[] = candidate?.jobs;
    const latest: any = jobs[jobs.length - 1];
    const client_name = jobData?.client ? jobData?.client?.name : '';
    const contactOption = [
      {
        label: [jobData?.lead?.first_name, jobData?.lead?.last_name].join(' '),
        value: jobData?.lead?.id,
      },
    ];
    setContactOptionValue(contactOption);
    const subject =
      'Video Interview' +
      ' | ' +
      latest?.title +
      ' | ' +
      candidate?.first_name +
      ' ' +
      candidate?.last_name +
      '/' +
      client_name;
    setAssocId(latest?.assoc_id);
    setSubject(subject);
    onOpenScheduleInterview();
    dispatch(getCandidate({ candidateData: candidate }));
  };

  const statusChangeSuccess = async () => {
    setIsBulkAction(false);
    await reqCandidates(candidatePgBtn);
    await reqGetJob({ id: params.jobsId });
  };

  // useEffect(() => {
  //   console.log('candidates', candidates);
  //   let oldTabCandidates:any = tabCandidateList;
  //   oldTabCandidates[`${params.jobsTabId}-${params.jobsId}-${jobRoute}`] = candidates;
  //   setTabCandidateList(oldTabCandidates);
  //   console.log(oldTabCandidates);
  // }, [candidates]);

  const memoizedTbody = useMemo(() => {
    return (
      <Tbody
        boxSizing="border-box"
        background="default.white.100"
        borderBottom="1px solid"
        borderColor="default.white.400"
      >
        {candidates.map((candidate: any, key: number) => {
          return (
            <CandidateTR
              key={candidate.id}
              candidate={candidate}
              handleCheck={(e: any) => handleCheck(e)}
              isCheck={isCheck}
              handleClick={() => handleClick(candidate)}
              associateToJob={() => { }}
              addTagClick={() => addTagClick(candidate.id)}
              editCandidate={() => editCandidate(candidate)}
              handleDeleteCandidate={(candidate: any) =>
                handleDeleteCandidate(candidate)
              }
              handleHideCandidate={(candidate: any) =>
                handleHideCandidate(candidate)
              }
              fromJob={true}
              hasStatus={hasStatus}
              HandleMenuItem={
                <MenuItemComponent
                  key={`menu-candidate-${candidate.id}`}
                  id={candidate.id}
                  type={params.jobsTabId}
                  nav={params.jobsTabNav}
                  openTags={() => addTagClick(candidate.id)}
                  addToScreen={() => moveToScreen(candidate)}
                  addToCapaign={onOpenAddToCampaign}
                  submitToClients={() => submitToClient(candidate)}
                  scheduleInterview={() => {
                    scheduleForInterview(candidate);
                  }}
                  sendEmail={async () => {
                    let emails = candidate?.emails?.filter(
                      (email: any) => email.primary
                    );
                    emails = emails.length === 0 ? candidate?.emails : emails;
                    setEmail(emails?.[0]?.email);
                    const data = {
                      candidate_id: candidate?.id,
                      job_id: params.jobsId || null,
                    } as any;
                    await reqPair(data);
                    setCandidateId(candidate?.id);
                    dispatch(getCandidate({ candidateData: candidate }));
                    onOpenSendEmail();
                  }}
                  changeStatus={() => changeStatus(candidate)}
                  hide={() => handleHideCandidate(candidate)}
                  del={() => handleDeleteCandidate(candidate)}
                  reject={() => rejectClick(candidate)}
                  offer={() => moveToOffer(candidate)}
                  moveToHired={() => moveToHired(candidate)}
                  unReject={() => unRejectClick(candidate)}
                  removeFromJob={() => removeJobClick(candidate)}
                />
              }
            />
          );
        })}
      </Tbody>
    );
  }, [candidates]);

  return (
    <Box px={9} pt={7} justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <CandidateButtonItem />
        </Flex>
      )}
      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        height="50vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              {(!hasStatus
                ? tableHeader.filter((item, key: number) => key !== 1)
                : tableHeader
              ).map((title, key: number) => {
                return title.label;
              })}
            </Tr>
          </Thead>

          {resCandidates.isLoading ? (
            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              <TrLoading rows={3} columns={tableHeader.length + 1} />
            </Tbody>
          ) : (
            <Tbody
              boxSizing="border-box"
              background="default.white.100"
              borderBottom="1px solid"
              borderColor="default.white.400"
            >
              {candidates.filter(Boolean).map((candidate: any, key: number) => {
                return (
                  <CandidateTR
                    key={`${candidate.id}-${key}`}
                    candidate={candidate}
                    handleCheck={(e: any) => handleCheck(e)}
                    isCheck={isCheck}
                    handleClick={() => handleClick(candidate)}
                    associateToJob={() => { }}
                    addTagClick={() => addTagClick(candidate.id)}
                    editCandidate={() => editCandidate(candidate)}
                    handleDeleteCandidate={(candidate: any) =>
                      handleDeleteCandidate(candidate)
                    }
                    handleHideCandidate={(candidate: any) =>
                      handleHideCandidate(candidate)
                    }
                    fromJob={true}
                    hasStatus={hasStatus}
                    HandleMenuItem={
                      <MenuItemComponent
                        key={`menu-candidate-${candidate.id}`}
                        id={candidate.id}
                        type={params.jobsTabId}
                        nav={params.jobsTabNav}
                        openTags={() => addTagClick(candidate.id)}
                        addToScreen={() => moveToScreen(candidate)}
                        addToCapaign={onOpenAddToCampaign}
                        submitToClients={() => submitToClient(candidate)}
                        scheduleInterview={() => {
                          scheduleForInterview(candidate);
                        }}
                        sendEmail={async () => {
                          let emails = candidate?.emails?.filter(
                            (email: any) => email.primary
                          );
                          emails =
                            emails.length === 0 ? candidate?.emails : emails;
                          setEmail(emails?.[0]?.email);
                          const data = {
                            candidate_id: candidate?.id,
                            job_id: params.jobsId || null,
                          } as any;
                          await reqPair(data);
                          setCandidateId(candidate?.id);
                          dispatch(getCandidate({ candidateData: candidate }));
                          onOpenSendEmail();
                        }}
                        changeStatus={() => changeStatus(candidate)}
                        hide={() => handleHideCandidate(candidate)}
                        del={() => handleDeleteCandidate(candidate)}
                        reject={() => rejectClick(candidate)}
                        offer={() => moveToOffer(candidate)}
                        moveToHired={() => moveToHired(candidate)}
                        unReject={() => unRejectClick(candidate)}
                        removeFromJob={() => removeJobClick(candidate)}
                      />
                    }
                  />
                );
              })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        totalPages={candidatePage.lastPage}
        currentPage={candidatePage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={candidatePage.count}
        currentCount={candidates?.length}
        targetCount={filter.take}
      />

      {isOpenChangeStatus && (
        <ChangeStatusModal
          isOpen={isOpenChangeStatus}
          onClose={onCloseChangeStatus}
          id={assocId}
          idList={assocIdList}
          main_status={mainStatus}
          sub_status={subStatus}
          onSuccess={() => statusChangeSuccess()}
          isBulkChangeStatus={isBulkAction}
          candidate={candidateData}
        />
      )}

      {isOpenReject && (
        <RejectModal
          id={assocId}
          idList={assocIdList}
          isOpen={isOpenReject}
          onClose={onCloseReject}
          isBulkReject={isBulkAction}
        />
      )}

      {isOpenHired && (
        <HiredModal
          isOpen={isOpenHired}
          onClose={onCloseHired}
          candidate={candidateData}
          onCallback={callBackAction}
        />
      )}

      {isOpenAddTags && (
        <CandidatesTagModal
          id={candidateId}
          isOpen={isOpenAddTags}
          onClose={onCloseAddTags}
          idList={isCheck}
          isBulkTag={isBulkAction}
        />
      )}
      {isOpenAddToCampaign && (
        <AddToCampaignModal
          isOpen={isOpenAddToCampaign}
          onClose={onCloseAddToCampaign}
        />
      )}
      {isOpenSendEmail && (
        <SendEmailModal
          isOpen={isOpenSendEmail}
          id={candidateId}
          email={email}
          onClose={onCloseSendEmail}
          idList={isCheck}
          emailList={emailList}
          isBulkEmail={isBulkAction}
        />
      )}
      {isOpenScheduleInterview && (
        <CandidatesInterviewModal
          isOpen={isOpenScheduleInterview}
          onClose={onCloseScheduleInterview}
          associate={assocId}
          subject={intSubj}
          isJobView={true}
          contactOptionValue={contactOptionValue}
        />
      )}
      {isOpenSubmitToClient && (
        <SubmitToClientModal
          isOpen={isOpenSubmitToClient}
          onClose={onCloseSubmitToClient}
          candidate={candidateData}
          callback={() => { }}
        />
      )}
      {isOpen && (
        <CandidatesDrawer
          isOpen={isOpen}
          onClose={onClose}
          rowId={rowId}
          setRowId={setRowId}
          totalCandidates={candidates?.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
          isLoading={resGetCandidate.isLoading}
          candidates={candidates}
        />
      )}
    </Box>
  );
};

export default JobsViewCandidatesTable;

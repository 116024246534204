import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import {
  Box,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { BsEnvelope, BsLinkedin, BsTelephone } from 'react-icons/bs';

import Button from '../../../Library/Button';
import LeadsTabs from './Tabs';

import FAIcon from 'components/lib/FAIcon';
import LeadsSendForApprovalModal from './SendForApproval';
import LeadsConvertClientModal from './ConvertContactModal';
import LeadsModal from './Modal';

import {
  getLead,
  useCancelApprovalMutation,
  useGetLeadsMutation,
  useInactiveLeadsMutation,
  useListLeadsMutation,
  useReActivateMutation,
} from 'store/leads.slice';

import { LeadsTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import LeadType from '../LeadTypes';
import { useCreateNotesMutation } from 'store/leadnotes.slice';
import { cleanUpload } from 'store/uploads.slice';
import toUrl from 'utils/toUrl';
import ProfileCard from 'components/profile-card.component';
import ContactEmail from '../Clients/Contacts/Modal/Email';
import AtsEmailBody from '../EmailBody';
import { navClient } from 'store/client.slice';

interface LeadProps {
  isOpen: boolean;
  onClose: () => void;
  rowId: number;
  totalLeads: number;
  handlePrevData: (lead: LeadsTypes) => void;
  handleNextData: (lead: LeadsTypes) => void;
}

function LeadsDrawer({
  isOpen,
  onClose,
  rowId,
  totalLeads,
  handlePrevData,
  handleNextData,
}: LeadProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const toast = useToast();

  const { leads, leadData, leadPgBtn } = useSelector(
    (state: any) => state.leads
  );

  const [reqLeadList] = useListLeadsMutation();
  const [reqleads] = useGetLeadsMutation();
  const [reqNotes, resNotes] = useCreateNotesMutation();
  const [reqInactiveLead, resInactiveLead] = useInactiveLeadsMutation();
  const [reqActivate, resActivate] = useReActivateMutation();
  const [reqCancelApproval, resCancelApproval] = useCancelApprovalMutation();
  const { clientNav, clientTab } = useSelector((state: any) => state.clients);
  const {
    isOpen: isEditLeadOpen,
    onOpen: onEditLeadOpen,
    onClose: onEditLeadClose,
  } = useDisclosure();

  const {
    isOpen: isConvertClientOpen,
    onOpen: onConvertClientOpen,
    onClose: onConvertClientClose,
  } = useDisclosure();

  const {
    isOpen: isConvertLeadOpen,
    onOpen: onConvertLeadOpen,
    onClose: onConvertLeadClose,
  } = useDisclosure();

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const stateTab: any = params.leadsTab;
  const leadID = params.leadsId;
  useEffect(() => {
    dispatch(cleanUpload({ withPrefix: false }));
    if (leadID) {
      // const getData =
      //   leads && leads.filter((lead: any) => lead.id === Number(leadID))[0];
      reqleads({ id: leadID });
      // dispatch(getLead({ leadData: getData }));
    }
  }, [leadID]);

  const handleOpenDrawer = () => {
    if (leadID) {
      return true;
    }
    return isOpen;
  };

  let content: swalContent = {
    title: 'Are you sure?',
    text: 'You are about to mark this lead as inactive, continue?',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleInactiveLead = async (lead: LeadsTypes) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqInactiveLead({ id: lead.id });
      dispatch(getLead({ leadData: {} }));
      onClose();
      navigate('/leads');
    }
  };

  const converClientClick = (lead: any) => {
    dispatch(getLead({ leadData: lead }));
    onConvertClientOpen();
  };

  const [quilValue, setquilValue] = useState('');
  const [isNewButton, setIsNewButton] = useState(false);
  const [isSendApproval, setIsSendApproval] = useState(false);
  const [hover, setHover] = useState(false);

  const type = leadData?.leadType?.type;
  const status = leadData?.leadStatus?.lead_status;

  const createNotes = async () => {
    let data = {
      note: quilValue,
    };
    await reqNotes({ data, id: leadID });
  };

  useEffect(() => {
    if (
      resInactiveLead.isSuccess ||
      resCancelApproval.isSuccess ||
      resActivate.isSuccess
    ) {
      reqLeadList(leadPgBtn);
    }
  }, [
    resInactiveLead.isSuccess,
    resCancelApproval.isSuccess,
    resActivate.isSuccess,
  ]);

  useEffect(() => {
    if (resNotes.isSuccess) {
      reqleads({ id: leadID });
      setquilValue('');
    }
  }, [resNotes.isSuccess]);

  useEffect(() => {
    if (resActivate.isSuccess) {
      reqLeadList(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resActivate.isSuccess &&
          resActivate.data.data.message.includes('Lead has been reactivated')
        ) {
          title = 'Mark as Active';
          description = 'Lead status was changed to Active';
        }

        if (
          resActivate.isSuccess &&
          resActivate.data.data.message.includes('Failed to reactivate')
        ) {
          title = 'Failed to reactivate';
          description = 'No logs found to retrace it. Status still Inactive';
          status = 'warning';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resActivate.isSuccess]);

  useEffect(() => {
    if (resInactiveLead.isSuccess) {
      reqLeadList(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resInactiveLead.isSuccess &&
          resInactiveLead.data.data.message.includes(
            'Lead has been deactivated'
          )
        ) {
          title = 'Mark as Inactive';
          description = 'Lead status was changed to Inactive';
          status = 'info';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resInactiveLead.isSuccess]);

  useEffect(() => {
    if (resCancelApproval.isSuccess) {
      reqLeadList(leadPgBtn);

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (
          resCancelApproval.isSuccess &&
          resCancelApproval.data.data.message.includes(
            'Approval has been canceld'
          )
        ) {
          title = 'Cancel Approval';
          description = 'Lead approval was cancelled';
          status = 'info';
        }

        if (
          resCancelApproval.isSuccess &&
          resCancelApproval.data.data.message.includes(
            'Failed to cancel Approval'
          )
        ) {
          title = 'Failed to cancel Approval';
          description = 'There is no logs to retrace';
          status = 'warning';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [resCancelApproval.isSuccess]);

  const sendForApproval = (lead: any) => {
    dispatch(getLead({ leadData: lead }));
    setIsSendApproval(true);
    onConvertLeadOpen();
  };

  const cancelApprovalClick = async (lead: any) => {
    content.text = 'You are about to cancel this approval, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqCancelApproval({ id: lead.id });
    }
  };

  const marAsActiveClick = async (lead: any) => {
    content.text = 'You are about to mark this lead as active, continue?';
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqActivate({ id: lead.id });
    }
  };

  const handleEditList = () => {
    if (type === 'Contact' && status === 'Rejected') {
      return (
        <>
          <MenuItem
            icon={<FAIcon iconName="pencil" />}
            onClick={() => {
              dispatch(getLead({ leadData: leadData }));
              onEditLeadOpen();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    } else if (type === 'Contact' && status === 'Approval Pending') {
      return (
        <MenuItem
          icon={<FAIcon iconName="pencil" />}
          onClick={() => {
            dispatch(getLead({ leadData: leadData }));
            onEditLeadOpen();
          }}
        >
          Edit
        </MenuItem>
      );
    } else if (
      type === 'Lead' &&
      (status === 'Draft' || status === 'Rejected')
    ) {
      return (
        <>
          <MenuItem
            icon={<FAIcon iconName="pencil" />}
            onClick={() => {
              dispatch(getLead({ leadData: leadData }));
              onEditLeadOpen();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Active') {
      return (
        <>
          <MenuItem
            icon={<FAIcon iconName="pencil" />}
            onClick={() => {
              dispatch(getLead({ leadData: leadData }));
              onEditLeadOpen();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    } else if (type === 'Lead' && status === 'Approval Pending') {
      return (
        <MenuItem
          icon={<FAIcon iconName="pencil" />}
          onClick={() => {
            dispatch(getLead({ leadData: leadData }));
            onEditLeadOpen();
          }}
        >
          Edit
        </MenuItem>
      );
    } else if (type === 'Qualified' && status === 'Active') {
      return (
        <>
          <MenuItem
            icon={<FAIcon iconName="pencil" />}
            onClick={() => {
              dispatch(getLead({ leadData: leadData }));
              onEditLeadOpen();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    } else if (type === 'Qualified' && status === 'Approval Pending') {
      return (
        <MenuItem
          icon={<FAIcon iconName="pencil" />}
          onClick={() => {
            dispatch(getLead({ leadData: leadData }));
            onEditLeadOpen();
          }}
        >
          Edit
        </MenuItem>
      );
    } else if (type === 'Qualified' && status === 'Rejected') {
      return (
        <>
          <MenuItem
            icon={<FAIcon iconName="pencil" />}
            onClick={() => {
              dispatch(getLead({ leadData: leadData }));
              onEditLeadOpen();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            icon={<FAIcon iconName="ban" />}
            color="caution.800"
            onClick={() => {
              handleInactiveLead(leadData);
            }}
          >
            Mark as Inactive
          </MenuItem>
        </>
      );
    }
  };

  const handleConvertType = () => {
    if (type === 'Contact' && status === 'Rejected') {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    } else if (
      type === 'Lead' &&
      (status === 'Draft' || status === 'Rejected')
    ) {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="check-circle" />}
            onClick={() => sendForApproval(leadData)}
          >
            Send for Approval
          </MenuItem>
          {/* <MenuItem
            icon={<FAIcon iconName="square-check" />}
            onClick={onConvertLeadOpen}
          >
            Convert to Qualified Lead
          </MenuItem> */}
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    } else if (type === 'Lead' && status === 'Active') {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          {/* <MenuItem
            icon={<FAIcon iconName="square-check" />}
            onClick={onConvertLeadOpen}
          >
            Convert to Qualified Lead
          </MenuItem> */}
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    } else if (type === 'Qualified' && status === 'Active') {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    } else if (type === 'Qualified' && status === 'Rejected') {
      return (
        <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
          <MenuItem
            icon={<FAIcon iconName="check-circle" />}
            onClick={() => sendForApproval(leadData)}
          >
            Send for Approval
          </MenuItem>
          <MenuItem
            icon={<FAIcon iconName="briefcase" />}
            onClick={() => converClientClick(leadData)}
          >
            Request to Convert
          </MenuItem>
        </MenuList>
      );
    }
  };

  useEffect(() => {
    if (
      (type === 'Contact' && status === 'Approval Pending') ||
      (type === 'Contact' && status === 'Active') ||
      (type === 'Lead' && status === 'Approval Pending') ||
      (type === 'Qualified' && status === 'Approval Pending') ||
      status === 'Inactive'
    ) {
      setIsNewButton(true);
    } else {
      setIsNewButton(false);
    }
  }, [status, type]);

  const handleNewButton = () => {
    if (
      (type === 'Contact' && status === 'Approval Pending') ||
      (type === 'Lead' && status === 'Approval Pending') ||
      (type === 'Qualified' && status === 'Approval Pending')
    ) {
      return (
        <Button onClick={() => cancelApprovalClick(leadData)}>
          Cancel Approval
        </Button>
      );
    } else if (type === 'Contact' && status === 'Active') {
      return (
        <>
          <Menu closeOnSelect={false}>
            <MenuButton>
              <Button iconName="ellipsis" />
            </MenuButton>

            <MenuList fontSize="12px" lineHeight="16px" fontWeight="600">
              <MenuItem
                icon={<FAIcon iconName="square-check" />}
                onClick={onConvertLeadOpen}
              >
                View Contact
              </MenuItem>
              <MenuItem
                icon={<FAIcon iconName="briefcase" />}
                onClick={() => converClientClick(leadData)}
              >
                Request to Convert
              </MenuItem>
              <MenuItem
                icon={<FAIcon iconName="ban" />}
                color="caution.800"
                onClick={() => {
                  handleInactiveLead(leadData);
                }}
              >
                Mark as Inactive
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      );
    } else if (status === 'Inactive') {
      return (
        <Button onClick={() => marAsActiveClick(leadData)}>
          Mark as Active
        </Button>
      );
    }
  };

  return (
    <>
      {leadData && (
        <Drawer
          key={leadData.id}
          onClose={() => {
            onClose();
            if (clientNav === 'leads') {
              dispatch(navClient({ clientNav: clientTab }));
              navigate(-1);
            } else {
              navigate('/leads');
            }
            dispatch(getLead({ leadData: {} }));
          }}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="80vw"
            top="62px! important"
            fontFamily="Proxima Nova Regular"
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bg="#E6E9EE"
              color="default.secondarytext"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            <Flex
              justifyContent="space-between"
              alignItems="center"
              pl={8}
              pr={0}
              borderBottom="1px solid #E6E9EE"
              fontSize="sm"
              fontWeight="normal"
              color="default.secondarytext"
            >
              <Box>
                Showing <b>{rowId}</b> of {totalLeads}
              </Box>
              <Flex>
                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-left"
                    variant="ghost"
                    onClick={() => rowId !== 1 && handlePrevData(leadData)}
                    disabled={rowId === 1}
                  />
                </Box>

                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-right"
                    variant="ghost"
                    onClick={() =>
                      rowId !== totalLeads && handleNextData(leadData)
                    }
                    disabled={rowId === totalLeads}
                  />
                </Box>
              </Flex>
            </Flex>
            <DrawerBody p="0">
              <Box
                bg="default.white.800"
                borderBottom="1px solid"
                borderColor="default.white.400"
                p="32px"
                pb="60px"
              >
                <Flex justifyContent="space-between" mb="10px">
                  <Box
                    fontSize="20px"
                    fontWeight="600"
                    color="default.primarytext"
                    whiteSpace="pre-line"
                    maxWidth="300px"
                    textTransform="capitalize"
                  >
                    {[leadData?.first_name, leadData?.last_name].join(' ')}
                  </Box>
                  <Flex gap={2}>
                    <Box fontSize="sm" color="default.secondarytext">
                      Current Lead Type:
                    </Box>
                    <Box fontSize="sm" color="default.primarytext">
                      <LeadType type={leadData.leadType?.type} />
                    </Box>
                  </Flex>
                </Flex>
                <Flex justifyContent="space-between">
                  <Box>
                    <Flex
                      gap={2}
                      fontSize="md"
                      color="default.gray.600"
                      mb="10px"
                    >
                      <Box fontSize="md" color="default.gray.800">
                        {leadData.title}
                      </Box>
                      |
                      <Box
                        fontSize="md"
                        color="default.gray.800"
                        onClick={() => {
                          dispatch(navClient({ clientNav: 'all-clients' }));
                          navigate(`/clients/${leadData.client_id}/overview`);
                        }}
                      >
                        <Link>{leadData?.client?.name}</Link>
                      </Box>
                      |
                      <Box fontSize="md" color="default.gray.800">
                        <Link
                          href={toUrl(leadData.website)}
                          target="_blank"
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                        >
                          {toUrl(leadData.website)}
                        </Link>
                      </Box>
                    </Flex>
                    <Flex
                      alignItems="center"
                      gap={2}
                      fontSize="md"
                      color="default.gray.600"
                    >
                      <Box
                        fontSize="sm"
                        color="default.secondarytext"
                        onMouseEnter={() => {
                          setHover(true);
                        }}
                        onMouseLeave={() => {
                          setHover(false);
                        }}
                      >
                        Created on:{' '}
                        {hover
                          ? moment
                              .utc(leadData.created_at)
                              .format('MM/DD/YYYY hh:mm A')
                          : moment
                              .utc(leadData.created_at)
                              .format('MM/DD/YYYY')}
                      </Box>
                      |
                      <Flex
                        fontSize="sm"
                        color="default.secondarytext"
                        // textTransform="capitalize"
                        gap="5px"
                      >
                        Lead Owner:{' '}
                        <ProfileCard
                          // id={leadData?.leadOwner?.id}
                          id="1"
                          user_id={leadData?.leadOwner?.id}
                          name={[
                            leadData?.leadOwner?.first_name,
                            leadData?.leadOwner?.last_name,
                          ].join(' ')}
                          email={leadData?.leadOwner?.email || ''}
                          thumbnail={leadData?.leadOwner?.thumbnailSignedUrl}
                          profile={leadData?.leadOwner?.signedUrl}
                        />
                      </Flex>
                    </Flex>
                  </Box>

                  <ButtonGroup alignItems="center">
                    <Flex gap="30px" alignItems="center" mr="20px">
                      <Link
                        href={toUrl(leadData?.linked_in_url)}
                        target="_blank"
                        style={{ display: 'flex', gap: '7px' }}
                        rel="noreferrer"
                        fontSize="20px"
                      >
                        <Icon as={BsLinkedin} />
                      </Link>
                      <Link fontSize="20px" onClick={() => onOpenEmail()}>
                        <Icon as={BsEnvelope} />
                      </Link>
                      <Link
                        fontSize="20px"
                        href={`tel:${leadData?.personal_phone}`}
                      >
                        <Icon as={BsTelephone} />
                      </Link>
                    </Flex>
                    {(leadData?.leadType?.type === 'Contact' &&
                      leadData?.leadStatus?.lead_status === 'Active') ||
                      (leadData?.leadStatus?.lead_status !== 'Inactive' && (
                        <Menu closeOnSelect={false}>
                          <MenuButton>
                            <Button iconName="ellipsis" />
                          </MenuButton>
                          <MenuList
                            fontSize="12px"
                            lineHeight="16px"
                            fontWeight="600"
                          >
                            {handleEditList()}
                          </MenuList>
                        </Menu>
                      ))}
                    {isNewButton ? (
                      handleNewButton()
                    ) : (
                      <Menu closeOnSelect={false}>
                        <MenuButton>
                          <Button rightIcon="chevron-down">
                            Convert Lead Type
                          </Button>
                        </MenuButton>

                        {handleConvertType()}
                      </Menu>
                    )}
                  </ButtonGroup>
                </Flex>
              </Box>
              <Box mt="-39px" p="0 32px">
                <LeadsTabs />
              </Box>
              {isEditLeadOpen && (
                <LeadsModal
                  isOpen={isEditLeadOpen}
                  onClose={onEditLeadClose}
                  modalTitle="Edit Lead"
                />
              )}

              {isConvertClientOpen && (
                <LeadsConvertClientModal
                  isOpen={isConvertClientOpen}
                  onClose={onConvertClientClose}
                />
              )}
              {isConvertLeadOpen && (
                <LeadsSendForApprovalModal
                  isOpen={isConvertLeadOpen}
                  onClose={onConvertLeadClose}
                  isSendApproval={isSendApproval}
                  setIsSendApproval={setIsSendApproval}
                />
              )}
              {stateTab === 'notes' && (
                <>
                  <Box
                    position="absolute"
                    width="100%"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                    p="16px 32px"
                  >
                    <AtsEmailBody
                      value={quilValue}
                      menubar={false}
                      onChange={setquilValue}
                      height={50}
                    />
                    <Box mt="3" textAlign={'right'}>
                      <Button variant="solid" onClick={() => createNotes()}>
                        Add Note
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
              {isOpenEmail && (
                <ContactEmail
                  isOpen={isOpenEmail}
                  onClose={onCloseEmail}
                  id={leadData.id}
                  email={leadData?.primary_email || ''}
                />
              )}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default LeadsDrawer;

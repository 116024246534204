import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';

import LoadingPage from 'components/app/Loading';

import { CandidatesTypes, swalContent } from 'types';

import Button from '../../../Library/Button';
import CandidatesTabs from './Tabs';
import {
  getCandidate,
  useCompanyUpdateCandidatesMutation,
  useDeleteCandidatesMutation,
  useFirstNameUpdateCandidatesMutation,
  useGetCandidatesMutation,
  useJobTitleUpdateCandidatesMutation,
  useLastNameUpdateCandidatesMutation,
  useListCandidatesMutation,
  useToggleHideCandidatesMutation,
} from 'store/candidates.slice';
import { getResume } from 'store/candidateresume.slice';
import { AtsConfirm } from 'utils/swal';
import { useListResumeCandidateMutation } from 'store/candidateresume.slice';
import ActionButton from './components/ActionButton';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import { useGetJobsMutation } from 'store/jobs.slice';

import FAIcon from 'components/lib/FAIcon';
import DynamicInputWidthForm from './DynamicInputWidthForm';
import { useCandidateActivityLogsMutation } from 'store/candidateActivity';
import CandidatesTagModal from './Modals/Tag';
import CandidatesAssociateJobModal from './Modals/AssociateJobs';
// import CandidatesModal from './Modals';
import SubmitToClientModal from '../Jobs/ViewCandidates/form/SubmitToClientModal';
import CandidateEmail from './Modals/Email';
import CandidatesInterviewModal from './Interviews/modal';
import CandidatesModal from './Modals/modal';
import { useListCandidateFilterMutation, usePinListCandidateFilterMutation } from 'store/candidatefilter.slice';

interface CandidateProps {
  isOpen: boolean;
  onClose: () => void;
  rowId: number;
  setRowId: any;
  totalCandidates: number;
  handlePrevData: (candidate: CandidatesTypes) => void;
  handleNextData: (candidate: CandidatesTypes) => void;
  isLoading?: boolean;
  candidates: any;
}

function CandidatesDrawer({
  isOpen,
  onClose,
  rowId,
  setRowId,
  totalCandidates,
  handlePrevData,
  handleNextData,
  isLoading,
  candidates,
}: CandidateProps) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const toast = useToast();
  const [reqjob] = useGetJobsMutation();

  const [reqCandidatesList] = useListCandidatesMutation();
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqAttachment] = useListResumeCandidateMutation();
  const [reqLogs, resLogs] = useCandidateActivityLogsMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqGetJob, resGetJob] = useGetJobsMutation();
  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();

  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();

  const [reqFirstNameUpdate, resFirstNameUpdate] =
    useFirstNameUpdateCandidatesMutation();
  const [reqLastNameUpdate, resLastNameUpdate] =
    useLastNameUpdateCandidatesMutation();
  const [reqJobTitleUpdate, resJobTitleUpdate] =
    useJobTitleUpdateCandidatesMutation();
  const [reqCompanyUpdate, resCompanyUpdate] =
    useCompanyUpdateCandidatesMutation();

  const { candidateData, candidatePgBtn, candidateOthers, candidateDataJobId } =
    useSelector((state: any) => state.candidates);
  const { candidateLogsPgBtn } = useSelector(
    (state: any) => state.candidateActivity
  );
  const { jobData, jobRoute } = useSelector((state: any) => state.jobs);

  let emails = candidateData?.emails?.filter((email: any) => email.primary);
  emails = emails?.length === 0 ? candidateData?.emails : emails;

  const primaryContact =
    candidateData?.contact?.filter((contact: any) => contact.primary)?.[0] ||
    null;

  const candidateID = params.candidatesId || candidateData?.id;
  const [assocJobs, setAssocJobs] = useState([]);
  const [hasFilter, setHasFilter] = useState(true);
  const [contactOptionValue, setContactOptionValue] = useState([]);

  const [isGroupByJob, setIsGroupByJob] = useState(false);
  const associateJobSuccess = async () => {
    await reqGetCandidate({ id: candidateID });
    // await reqGetJob({ id: params.jobsId });
  };

  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
      candidateOthers.length > 0 &&
      candidateOthers?.[0].group_by_job
    );
  }, [candidateOthers]);

  const { filter } = useSelector((state: any) => state.candidateFilters);

  useEffect(() => {
    if (filter) {
      setIsGroupByJob(true);
    }
  }, [filter, candidateData]);

  useEffect(() => {
    if (
      params?.jobsId !== undefined &&
      params?.jobsId !== 'undefined' &&
      params?.jobsId !== null
    ) {
      reqGetJob({ id: params?.jobsId });
    }
  }, []);

  useEffect(() => {
    if (
      params?.jobsId !== undefined &&
      params?.jobsId !== 'undefined' &&
      params?.jobsId !== null &&
      resGetJob.isSuccess
    ) {
      const contactOption = [
        {
          label: [jobData?.lead?.first_name, jobData?.lead?.last_name].join(
            ' '
          ),
          value: jobData?.lead?.id,
        },
      ];
      setContactOptionValue(contactOption);
    }
  }, [resGetJob.isSuccess]);

  const {
    isOpen: isOpenCandidate,
    onOpen: onOpenCandiate,
    onClose: onCloseCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenTag,
    onOpen: onOpenTag,
    onClose: onCloseTag,
  } = useDisclosure();

  const {
    isOpen: isOpenAssociate,
    onOpen: onOpenAssociate,
    onClose: onCloseAssociate,
  } = useDisclosure();

  const {
    isOpen: isOpenEmail,
    onOpen: onOpenEmail,
    onClose: onCloseEmail,
  } = useDisclosure();

  const {
    isOpen: isOpenSubmitToClient,
    onOpen: onOpenSubmitToClient,
    onClose: onCloseSubmitToClient,
  } = useDisclosure();

  const {
    isOpen: isOpenScheduleInterview,
    onOpen: onOpenScheduleInterview,
    onClose: onCloseScheduleInterview,
  } = useDisclosure();

  const queryParam = new URLSearchParams(search)
  const jobId = (queryParam.get('jobId') && !isNaN(Number(queryParam.get('jobId')))) ? Number(queryParam.get('jobId')) : null

  const jobDetails =
    (candidateDataJobId || jobId) &&
    candidateData?.jobs?.filter(
      (job: any) => job.job_id === (candidateDataJobId || jobId || jobData?.id)
    )[0];

  useEffect(() => {
    const api = async () => {
      await reqGetCandidate({ id: candidateID });
      await reqAttachment({ id: candidateID });
    };
    api();
  }, [candidateID]);

  // useEffect(() => {
  //   reqLogs({ id: candidateID, data: candidateLogsPgBtn });
  // }, [candidateID]);


  useEffect(() => {
    if (resGetCandidate.isSuccess) {
      const candidateData = resGetCandidate?.data?.data;
      if ((candidateData === null || candidateData === undefined) && !isOpenEmail) {
        const title = 'Candidate Details';
        const description = 'Candidate Not Found';
        toast({
          title: title,
          description: description,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      }
    }
  }, [resGetCandidate.isSuccess]);

  useEffect(() => {
    if (!isOpenScheduleInterview) {
      setContactOptionValue([]);
    }
  }, [isOpenScheduleInterview]);

  useEffect(() => {
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      reqCandidatesList(candidatePgBtn);
      onClose();
    }
  }, [resDeleteCandidate.isSuccess, resToggleHide.isSuccess]);

  useEffect(() => {
    if (params.jobsTabId) {
      setHasFilter(false);
    } else {
      setHasFilter(true);
    }
  }, [params.jobsTabId]);

  useEffect(() => {
    if (candidateID) {
      const getData =
        candidates &&
        candidates.filter(
          (candidate: any) => candidate.id === Number(candidateID)
        )[0];
      dispatch(getCandidate({ candidateData: getData }));
      // const data = {
      //   candidate_id: candidateID,
      //   job_id: params?.jobsId || null,
      // } as any;
      // reqPair(data);
    }
  }, [candidateID]);

  const handleOpenDrawer = () => {
    if (candidateID) {
      return true;
    }
    return isOpen;
  };

  const associateToJob = async () => {
    let list: any = [];
    if (candidateData.jobs?.length > 0) {
      await Promise.all(
        candidateData?.jobs?.map((job: any) => {
          list.push(job.job_id);
        })
      );
    }

    setAssocJobs(list);
    onOpenAssociate();
  };

  const openSendEmailClick = async (candidate: any) => {
    const data = {
      candidate_id: candidate?.id,
      job_id: jobId || null,
    } as any;
    await reqPair(data);
    onOpenEmail();
    await reqGetCandidate({ id: candidate?.id });
    if (candidate?.jobs?.length > 0) {
      await reqjob({ id: candidate?.jobs?.[0]?.id });
    }
  };

  const addTagClick = () => {
    onOpenTag();
  };

  const editCandidate = () => {
    onOpenCandiate();
  };

  const handleDeleteCandidate = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDeleteCandidate({ id: candidateID });
      await reqFilterList({});
      await reqPinList({})
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const handleHideCandidate = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleHide({ id: candidateID });
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const callBackAction = () => {
    reqGetCandidate({ id: candidateID });
  };

  const spanRef = useRef<HTMLSpanElement | null>(null);
  const [inputWidth, setInputWidth] = useState<number | 'auto'>(
    spanRef.current?.offsetWidth
  );
  const newInputWidth = spanRef.current?.offsetWidth;

  useEffect(() => {
    if (inputWidth !== newInputWidth) {
      setInputWidth(newInputWidth);
    }
  }, [inputWidth, newInputWidth]);

  useEffect(() => {
    if (!inputWidth) {
      setInputWidth(newInputWidth);
    }
  }, [inputWidth, newInputWidth]);

  useEffect(() => {
    if (jobId) {
      dispatch(getCandidate({ candidateDataJobId: jobId }));
    }
  }, [jobId])

  const handleCloseDrawer = () => {
    onClose();
    navigate(
      params['*'].includes('view-candidates')
        ? `${params.jobsId}/${params.jobsTab}/${params.jobsTabId}/${jobRoute}`
        : '/candidates'
    );
    dispatch(getCandidate({ candidateData: {}, candidateDataJobId: null }));
    dispatch(getResume({ latest: {} }));
    dispatch(getResume({ resumes: [] }));
  };

  return (
    <>
      {candidateData && (
        <Drawer
          key={candidateData.id}
          onClose={handleCloseDrawer}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="95vw"
            top="62px! important"
            fontFamily="Proxima Nova Regular"
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bg="#E6E9EE"
              color="default.secondarytext"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            <Flex
              justifyContent="space-between"
              alignItems="center"
              gap="18px"
              pl={4}
              pr={0}
              borderBottom="1px solid #E6E9EE"
              fontSize="14px"
            >
              <Box
                id={`tagTooltip`}
                color="primary.600"
                fontSize="12px"
                lineHeight="18px"
                fontWeight={500}
                height="fit-content"
              >
                {params.jobsTab && (
                  <>
                    <Flex gap="5px">
                      <Link
                        href={`/jobs/${jobData.id}/details`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {jobData.title}
                      </Link>
                      -
                      <Link
                        href={`/clients/${jobData.client?.id}/overview`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {jobData.client?.name}
                      </Link>
                    </Flex>
                  </>
                )}
                {params.candidatesTab && isGroupByJob && (
                  <>
                    <Flex gap="5px">
                      <Link
                        href={`/jobs/${jobDetails?.job?.id}/details`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {jobDetails && jobDetails?.job?.title}
                      </Link>
                      -
                      <Link
                        href={`/clients/${jobDetails?.job?.client?.id}/overview`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {jobDetails && jobDetails?.job?.client?.name}
                      </Link>
                    </Flex>
                  </>
                )}
              </Box>
              <Flex alignItems="center">
                {rowId > 0 ? (
                  <>
                    <Flex gap="8px" mr="12px">
                      Showing <b>{rowId}</b> of <b>{totalCandidates}</b>
                    </Flex>
                    <Flex>
                      <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                        <Button
                          iconName="chevron-left"
                          variant="ghost"
                          onClick={() =>
                            rowId !== 1 && handlePrevData(candidateData)
                          }
                          disabled={rowId === 1}
                        />
                      </Box>

                      <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                        <Button
                          iconName="chevron-right"
                          variant="ghost"
                          onClick={() =>
                            rowId !== totalCandidates &&
                            handleNextData(candidateData)
                          }
                          disabled={rowId === totalCandidates}
                          borderLeft="1px solid #6B6C7B"
                          borderRadius="unset"
                        />
                      </Box>
                    </Flex>
                  </>
                ) : (
                  <Flex height="41.11px"></Flex>
                )}
              </Flex>
            </Flex>
            <DrawerBody p="0" overflow="hidden">
              {(isLoading || resGetCandidate.isLoading) && <LoadingPage />}
              <Box
                bg="default.white.800"
                borderBottom="1px solid"
                borderColor="default.white.400"
                pt="19px"
                px="20px"
                pb="60px"
              >
                <Flex justifyContent="space-between">
                  <Flex
                    gap="6px"
                    flexDir="column"
                    fontSize="18px"
                    lineHeight="21.92px"
                    fontWeight="700"
                    color="default.primarytext"
                  >
                    <Flex mb="2px" gap="5px">
                      <DynamicInputWidthForm
                        valueKey="first_name"
                        candidateData={candidateData}
                        reqUpdate={reqFirstNameUpdate}
                        resUpdate={resFirstNameUpdate}
                        reqCandidatesList={reqCandidatesList}
                        candidatePgBtn={candidatePgBtn}
                        placeholder="First Name"
                        isMain
                      />
                      <DynamicInputWidthForm
                        valueKey="last_name"
                        candidateData={candidateData}
                        reqUpdate={reqLastNameUpdate}
                        resUpdate={resLastNameUpdate}
                        reqCandidatesList={reqCandidatesList}
                        candidatePgBtn={candidatePgBtn}
                        placeholder="Last Name"
                        isMain
                      />
                    </Flex>
                    <Flex
                      sx={{
                        gap: '8px',
                        alignItems: 'center',
                        fontSize: '14px',
                        lineHeight: '17.05px',
                      }}
                    >
                      <DynamicInputWidthForm
                        valueKey="job_title"
                        candidateData={{
                          ...candidateData,
                          job_title: candidateData.latest_job_title,
                        }}
                        reqUpdate={reqJobTitleUpdate}
                        resUpdate={resJobTitleUpdate}
                        reqCandidatesList={reqCandidatesList}
                        candidatePgBtn={candidatePgBtn}
                        placeholder="Job Title"
                      />
                      <Box fontSize="18px" color="default.gray.600">
                        |
                      </Box>
                      <DynamicInputWidthForm
                        valueKey="company"
                        candidateData={{
                          ...candidateData,
                          company: candidateData.latest_employer,
                        }}
                        reqUpdate={reqCompanyUpdate}
                        resUpdate={resCompanyUpdate}
                        reqCandidatesList={reqCandidatesList}
                        candidatePgBtn={candidatePgBtn}
                        placeholder="Company"
                      />
                    </Flex>
                  </Flex>
                  <Flex alignItems="center" gap={6}>
                    <Box cursor="pointer">
                      <Link
                        href={`tel:${primaryContact?.number}`}
                        color="rgba(132, 87, 229, 0.8);"
                        sx={{
                          '.fa-sm': {
                            fontSize: '18px',
                          },
                        }}
                      >
                        <FAIcon iconName="phone" />
                      </Link>
                    </Box>
                    <Box
                      color="rgba(132, 87, 229, 0.8);"
                      cursor="pointer"
                      onClick={openSendEmailClick}
                      sx={{
                        '.fa-sm': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      <FAIcon iconName="envelope" />
                    </Box>
                    <Box
                      cursor="pointer"
                      color="rgba(132, 87, 229, 0.8);"
                      sx={{
                        '.fa-sm': {
                          fontSize: '18px',
                        },
                      }}
                    >
                      <FAIcon iconName="comment" />
                    </Box>
                    <Menu closeOnSelect={false}>
                      <MenuButton>
                        <Box
                          sx={{
                            button: {
                              background: 'none',
                              radius: '6px',
                              fontSize: '12px',
                              fontWeight: 600,
                              lineHeight: '16px',
                              color: 'primary.600',
                            },
                          }}
                        >
                          <Button rightIcon="chevron-down">Actions</Button>
                        </Box>
                      </MenuButton>
                      <MenuList fontSize="sm">
                        <MenuItem onClick={() => associateToJob()}>
                          Associate to Job
                        </MenuItem>
                        <MenuItem onClick={() => addTagClick()}>
                          Add Tags
                        </MenuItem>
                        <MenuItem onClick={() => editCandidate()}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleHideCandidate()}>
                          Hide
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteCandidate()}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                </Flex>
                <Flex gap={15} mt="20px">
                  <ActionButton
                    rowId={rowId}
                    setRowId={setRowId}
                    totalCandidates={totalCandidates}
                    onClose={onClose}
                    isLoadingDrawer={isLoading || resGetCandidate.isLoading}
                    candidates={candidates}
                  />
                </Flex>
              </Box>
              <Box mt="-39px">
                <CandidatesTabs />
              </Box>
              {isOpenTag && (
                <CandidatesTagModal
                  isOpen={isOpenTag}
                  onClose={onCloseTag}
                  id={candidateID}
                />
              )}
              {isOpenAssociate && (
                <CandidatesAssociateJobModal
                  isOpen={isOpenAssociate}
                  onClose={onCloseAssociate}
                  id={candidateID}
                  assoc_jobs={assocJobs}
                  onSuccess={() => associateJobSuccess()}
                />
              )}
              {isOpenCandidate && (
                <CandidatesModal
                  isOpen={isOpenCandidate}
                  onClose={onCloseCandidate}
                  edit={true}
                  id={Number(candidateID)}
                  fromDrawer={true}
                />
              )}
              {isOpenSubmitToClient && (
                <SubmitToClientModal
                  isOpen={isOpenSubmitToClient}
                  onClose={onCloseSubmitToClient}
                  candidate={candidateData}
                  callback={callBackAction}
                />
              )}
              {isOpenScheduleInterview && (
                <CandidatesInterviewModal
                  isOpen={isOpenScheduleInterview}
                  onClose={onCloseScheduleInterview}
                  callback={callBackAction}
                  contactOptionValue={contactOptionValue}
                />
              )}
            </DrawerBody>
          </DrawerContent>
          {isOpenEmail && (
            <CandidateEmail
              isOpen={isOpenEmail}
              onClose={onCloseEmail}
              id={candidateData.id}
              email={emails?.[0]?.email || ''}
              job_id={jobId ? jobId : undefined}
            />
          )}
        </Drawer>
      )}
    </>
  );
}

export default CandidatesDrawer;

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { debounce } from 'lodash';
import { number } from 'yup';
import AsyncCreatableSelect from 'react-select/async-creatable'

import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';

import AtsSelect from '../AtsSelect';
import FilterList from '../AtsSelectCandidateView/filterlist';
import AtsAsyncSelect2 from '../AtsAsyncSelect2';
import AtsCraetableSelDyn from '../AtsCreatabeSelect/Dyanmic';
import AtsSelectCandidateView from '../AtsSelectCandidateView';

import {
  useGetCandidateSourceMutation,
  useGetCandidatetatusMutation,
  useGetCandidateWorkStatusMutation,
} from 'store/constant.slice';
import { listCandidates } from 'store/candidates.slice';
import { useJobTitleSearchMutation } from 'store/jobs.slice';
import { useWildSearchGeoTypesMutation } from 'store/geo.slice';
import { useTagListMutation } from 'store/tag.slice';

import jobCandidateStatus from 'constants/jobCandidateStatus';

import { AutoResizeTextarea } from '../AutoResizeTextarea';
import CandidateFilter from './components/CandidateFilter';
import { listFilters } from 'store/candidatefilter.slice';
import LoadingPage from '../Loading';

import ViewList from 'assets/images/MdViewList.svg';
import LocationPin from 'assets/images/MdLocationPin.svg';
import Work from 'assets/images/MdWork.svg';
import DocumentScanner from 'assets/images/MdDocumentScanner.svg';
import Circle from 'assets/images/MdCircle.svg';
import Tag from 'assets/images/MdTag.svg';
import BooleanImg from 'assets/images/MdBoolean.svg';
import FAIcon from 'components/lib/FAIcon';
import { useUserListMutation } from 'store/user.slice';

interface optionInterface {
  label: string;
  value: any;
}

export default function CandidatesFilterBar() {
  const dispatch = useDispatch();
  const urlParam = useParams();

  document.documentElement.dir = 'ltr';

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const { UserPgBtn } = useSelector((state: any) => state.user);
  const { candidate_status, candidate_work_status } = useSelector(
    (state: any) => state.constants
  );
  const { candidate_filters, params } = useSelector(
    (state: any) => state.candidateFilters
  );
  const { geo_location } = useSelector((state: any) => state.geolocation);
  const { batches } = useSelector((state: any) => state.resumeParse);

  const [reqSubStatus, resSubStatus] = useGetCandidatetatusMutation();
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
  const [reJobs] = useJobTitleSearchMutation();
  const [reqTags, resTags] = useTagListMutation();
  const [reqGeo, resGeo] = useWildSearchGeoTypesMutation();
  const [reqCandSource, resCandSource] = useGetCandidateSourceMutation();
  const [reqUserList] = useUserListMutation();

  const { onClose } = useDisclosure();

  let intialOption: optionInterface[] = [];
  let tagForm = {
    tag: '',
    is_private: false,
  };

  const candidatedFilterData: string | null = null;
  // localStorage.getItem('candidatesFilter');
  const filter =
    candidatedFilterData === null
      ? candidatePgBtn
      : JSON.parse(candidatedFilterData);

  const [isBool, setIsBool] = useState(filter.boolean);

  const job_id =
    urlParam.jobsTabId !== 'candidate-pool' ? Number(urlParam.jobsId) : null;
  const excluded_job_id =
    urlParam.jobsTabId === 'candidate-pool' ? Number(urlParam.jobsId) : null;

  useEffect(() => {
    // search;
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      search: filter.search,
      excluded_job_id: excluded_job_id,
    };
    if (!isBool) sumbitList(newParam);
  }, [isBool]);

  const [titleInclude, setTitleInclude] = useState(filter.job_filter_include);
  const [tagPrivate, setTagPrivate] = useState('1');
  const [defOption, setDefOption] = useState(intialOption);
  const [candSource, setCandSource] = useState([]);
  const [candSubSource, setCandSubSource] = useState([]);
  const [candSourceVisible, setCandSourceVisible] = useState(false);
  const [userSource, setUserSource] = useState([]);

  const resumeOptions = [
    { label: 'All Time', value: 0 },
    { label: 'Last Week', value: 1 },
    { label: 'Last Month', value: 2 },
    { label: '0-3 months', value: 3 },
    { label: '3-6 months', value: 4 },
    { label: '6-12 months', value: 5 },
    { label: 'More than a year', value: 6 },
  ];

  const [filterActive, setFilterActive] = useState(filter.filter);
  const [filterDefValue, setFilterDefValue] = useState([
    {
      value: null,
      label: 'All Candidates',
    },
  ]);
  const [location, setLocation] = useState<any>(filter.location);
  const [jobTitle, setJobTitle] = useState<any>(filter.job_filter);
  const [visibleJS, setVisibleJS] = useState(false);
  const [jsOption, setJsOption] = useState([]);
  const [defTags, setDefTags] = useState([]);
  const [itemLocation, setItemLocation] = useState([]);
  const [currentName] = useState('');
  const [visibleViewFilter, setVisibleFilter] = useState(
    urlParam?.jobsId ? false : true
  );
  const [search, setSearch] = useState(
    isBool
      ? filter.search
      : {
        must_have:
          (filter.search.must_have &&
            filter.search.must_have?.map((item: any, index: number) => ({
              title: item,
              id: index,
            }))) ||
          [],
        exclude:
          (filter.search.exclude &&
            filter.search.exclude?.map((item: any, index: number) => ({
              title: item,
              id: index,
            }))) ||
          [],
      }
  );
  const [mustHaveTasks, setMustHaveTasks] = useState(
    (filter.search.must_have &&
      filter.search.must_have?.map((item: any, index: number) => ({
        title: item,
        id: index,
      }))) ||
    []
  );

  const [excludeTasks, setExcludeTasks] = useState(
    (filter.search.exclude &&
      filter.search.exclude?.map((item: any, index: number) => ({
        title: item,
        id: index,
      }))) ||
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [prevParam, setPrevParam] = useState(filter);

  useEffect(() => {
    if (urlParam?.jobsTabId) {
      const filterData = {
        ...resetFilters(),
      };
      dispatch(
        listCandidates({
          candidatePgBtn: filterData,
        }))
      sumbitList(filterData)
    }

  }, [urlParam?.jobsTabId])
  const workStatusOptions = candidate_work_status.map((item: any) => ({
    value: item.id,
    label: item.status,
  }));

  const sumbitList = (param: any) => {

    let subs: any = [];
    subs['submits'] = [1];
    subs['screens'] = [2];
    subs['interviews'] = [3];
    subs['offers'] = [4];
    subs['hired'] = [5];
    subs['rejected'] = [6];

    let status;

    if (urlParam?.jobsTabId) {
      switch (urlParam?.jobsTabId) {
        case 'screen':
          status = [2];
          break;
        case 'in-progress':
          status = urlParam?.jobsTabNav ? subs[urlParam.jobsTabNav] : [1];
          break;
        case 'hired':
          status = [5];
          break;
        case 'rejected':
          status = [6];
          break;
        default:
          status = null;
      }
    }

    let newParam = {
      page: param.page,
      take: param.take,
      query: param.query,
      search: param.search,
      boolean: param.boolean,
      job_filter: param.job_filter,
      job_filter_include: param.job_filter_include,
      location: param.location,
      tags: param.tags,
      skills: param.skills,
      experience: param.experience,
      work_status: param.work_status,
      job_status: param.job_status,
      resume_updated_on: param.resume_updated_on,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      filter: param.filter,
      source: param.source,
      sub_source: param.sub_source,
      ...(urlParam.jobsTabId !== 'candidate-pool' && { status: status }),
      ...(param?.job_id &&
        urlParam.jobsTabId === 'screen' && { job_id: param.job_id }),
    };
    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
    localStorage.setItem('candidatesFilter', JSON.stringify(newParam));
    //either table or tablev2 will be trigger as long as you pass the parameter here
    // reqCandidates(newParam);
  };

  useEffect(() => {
    setPrevParam(candidatePgBtn);
  }, [candidatePgBtn]);

  useEffect(() => {
    if (candidatePgBtn !== prevParam) {
      setIsLoading(false);
    }
  }, [candidatePgBtn, prevParam]);

  // const formatLocOption = async (loc: any) => {
  //   let options: any = [];

  //   await Promise.all(
  //     loc.map(async (item: any) => {
  //       const value = currentName === 'city' ? item.city : item.postal_code;
  //       options.push({
  //         // city: item.city,
  //         // country: item.country,
  //         // postal_code: item.postal_code,
  //         // country_code: item.country_code,
  //         // state: item.state,
  //         value: 1,
  //         label: [item.city, item.state, item.country].filter(Boolean).join(', '),
  //         // id: number,
  //       });
  //     })
  //   );

  //   return await options;
  // };

  const formatLocOption = async (loc: any) => {
    let options: any = [];
    await Promise.all(
      loc.map(async (item: any) => {

        // const value = currentName === 'city' ? tag.city : tag.postal_code;
        options.push({
          label: [item.city, item.state, item.country].filter(Boolean).join(', '),
          value: item.id,
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
        });
      })
    );

    return await options;
  };

  const formatTagOption = async (tags: any) => {
    let options: optionInterface[] = [];
    await Promise.all(
      tags.map(async (tag: any) => {
        options.push({
          label: tag.tag_name,
          value: tag.id,
        });
      })
    );

    return await options;
  };

  const promiseLocOptions = debounce(
    (inputValue: string, callback: (options: any[]) => void) => {
      const data = {
        search: inputValue,
        currentName,
      }
      reqGeo(data).then((res: any) => {
        const getData = async () => {
          const option = await formatLocOption(res?.data?.data);
          setItemLocation(option);
          callback(option);
        };
        getData().catch(console.error);
      });
    },
    500
  );

  const promiseTagOptions = debounce(
    (inputValue: string, callback: (options: optionInterface[]) => void) => {
      tagForm.tag = inputValue;
      tagForm.is_private = tagPrivate === '1' ? false : true;
      reqTags(tagForm).then((res: any) => {
        const getData = async () => {
          const option = await formatTagOption(res?.data?.data);
          callback(option);
        };
        getData().catch(console.error);
      });
    },
    500
  );

  const jobTitleChange = debounce(async (e: any) => {
    e.preventDefault();

    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      job_filter: e.target.value,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };
    sumbitList(newParam);
  }, 2000);

  const jobTitleIncludeChange = async (e: any) => {
    e.preventDefault();

    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      job_filter_include: e.target.value,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };

    setTitleInclude(e.target.value);
    sumbitList(newParam);
  };

  const tagChange = async (value: any) => {
    let tags: any = [];
    await Promise.all(
      value.map(async (item: any) => {
        tags.push(item.value);
      })
    );

    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      tags: tags,
      defTags: value,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };

    setDefTags(newParam.defTags);
    sumbitList(newParam);
    if (value.length < 1) {
      reqTags(tagForm);
    }
  };

  const resumeUpdatedOnChange = (e: any) => {
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      resume_updated_on: e.label,
    };
    sumbitList(newParam);
  };

  const workStatusChange = (e: any) => {
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      work_status: e.map((val: any) => val.value),
    };
    sumbitList(newParam);
  };

  const sourceChange = (e: any) => {
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      source: e.value,
      sub_source: null,
    };
    sumbitList(newParam);
  }

  const subSourceChange = (e: any) => {
    let newParam = {
      ...filter,
      page: 1,
      boolean: isBool,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      sub_source: e.value
    };
    sumbitList(newParam);
  }

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      geo_location.map((item: any, key: number) => {
        const value = currentName === 'city' ? item.city : item.postal_code;
        optios.push({
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
          // value: value,
          value: item.id,
          label: `${item.state}, ${item.country}`,
          id: number,
        });
      });
      setItemLocation(optios);
    } else {
      setItemLocation([]);
    }
  };

  const filterChange = (filter: any) => {
    let newParam = {
      ...filter.filter,
      page: 1,
      search: filter.search || {
        must_have:
          (filter.search.must_have &&
            filter.search.must_have?.map((item: any, index: number) => ({
              title: item,
              id: index,
            }))) ||
          [],
        exclude:
          (filter.search.exclude &&
            filter.search.exclude?.map((item: any, index: number) => ({
              title: item,
              id: index,
            }))) ||
          [],
      },
      boolean: isBool,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };
    sumbitList(newParam);
  };

  const urlChangeStatus = () => {
    if (urlParam?.jobsTabId) {
      if (urlParam?.jobsTabId === 'candidate-pool') {
        setVisibleJS(false);
      } else {
        const filters: any = {
          screen: 2,
          hired: 5,
          rejected: 6,
          submits: 1,
          interviews: 3,
          offers: 4,
        };
        let selected: any = null;
        setVisibleJS(true);
        if (
          urlParam?.jobsTabId === 'screen' ||
          urlParam?.jobsTabId === 'hired' ||
          urlParam?.jobsTabId === 'rejected'
        ) {
          selected = filters[urlParam?.jobsTabId];
        }
        if (urlParam?.jobsTabId === 'in-progress') {
          if (
            urlParam?.jobsTabNav === 'submits' ||
            urlParam?.jobsTabNav === 'interviews' ||
            urlParam?.jobsTabNav === 'offers'
          ) {
            selected = filters[urlParam?.jobsTabNav];
          }
        }
        setOptionStatus(selected);
      }
    } else {
      setVisibleJS(false);
    }
  };

  const setOptionStatus = (id: any) => {
    setJsOption([]);

    if (candidate_status?.length > 0) {
      let groups: any = [];
      candidate_status.map((item: any) => {
        if (id === item?.id) {
          if (item?.sub?.length > 0) {
            let option: any = [];
            option.push({
              label: 'All',
              value: null,
            });
            item?.sub?.map((sub: any, key: number) => {
              option.push({
                label: sub.sub_status,
                value: sub.id,
              });
            });
            groups.push({
              label: item.status,
              options: option,
            });
          }
          setJsOption(groups);
        }
      });
    }
  };

  const booleanSearchChange = debounce(async (event: any) => {
    event.preventDefault();

    let newParam = {
      ...filter,
      page: 1,
      search: event.target.value,
      boolean: isBool,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };

    sumbitList(newParam);
  }, 2000)

  const jsChange = (event: any) => {
    let newParam = {
      ...filter,
      page: 1,
      search: search,
      boolean: isBool,
      defTags: defTags,
      job_status: event.value,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
    };

    localStorage.setItem('candidatesFilter', JSON.stringify(newParam));
    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );

    if (urlParam.jobsTabId) {
      setIsLoading(true);
    }
    sumbitList(newParam);
  };

  const filterViewChange = (id: any) => {
    let newParam = {
      ...filter,
      page: 1,
      defTags: defTags,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      filter: id,
      boolean: isBool,
    };
    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    ); //either table or tablev2 will be trigger as long as you pass the parameter here
    sumbitList(newParam);
  };

  const filterPinChange = (e: any) => {
    let id = e.label == 'All Candidates' ? null : e.value;
    // let id = e.value;
    filterViewChange(id);
    setFilterDefValue([e]);
  };

  const resetFilters = () => {
    const defaultFilters = {
      page: 1,
      take: 50,
      query: '',
      job_filter: '',
      job_filter_include: 'Current',
      location: '',
      tags: [] as any,
      skills: [] as any,
      experience: [] as any,
      work_status: null as any,
      job_id: null as any,
      excluded_job_id: null as any,
      status: null as any,
      job_status: null as any,
      resume_updated_on: null as any,
      filter: null as any,
      search: '',
      boolean: true,
      reject_reason: null as any,
      source: null as any,
      sub_source: null as any,
    };

    setIsBool(true);
    setLocation('');
    setTitleInclude('Current');
    setJobTitle('');
    setSearch('');
    setMustHaveTasks([]);
    setExcludeTasks([]);
    setCandSourceVisible(false)

    return defaultFilters
  }

  const clearFilterPanel = () => {
    const filterData = {
      ...resetFilters(),
      filter: filter.filter,
    };
    dispatch(
      listCandidates({
        candidatePgBtn: filterData,
      })
    );
    sumbitList(filterData);
  };

  const [prevUrl, setPrevUrl] = useState(params);

  useEffect(() => {
    setPrevUrl((prev: any) => prev);
  }, []);
  useEffect(() => {
    if (
      (params === 'view-candidates' && prevUrl === 'candidates') ||
      (params === 'candidates' &&
        prevUrl === 'view-candidates' &&
        params !== prevUrl)
    ) {
      clearFilterPanel();
    }
  }, [params, prevUrl]);

  useEffect(() => {
    if (urlParam['*'] === 'candidates') {
      dispatch(listFilters({ params: urlParam['*'] }));
    }
  }, [urlParam]);

  useEffect(() => {
    if (filter.filter) {
      setFilterActive(filter.filter);
      resetFilters()
      dispatch(listFilters({ filter: filter.filter }));
    }
  }, [filter.filter]);

  useEffect(() => {
    if (onClose) {
      dispatch(
        listCandidates({
          candidatePgBtn: {
            page: 1,
            take: 50,
            query: '',
            job_filter: '',
            job_filter_include: 'Current',
            location: '',
            tags: [],
            skills: [],
            experience: [],
            work_status: null,
            job_id: null,
            excluded_job_id: null,
            status: null,
            job_status: null,
            resume_updated_on: null,
            filter: null,
            search: '',
            boolean: true,
            source: null,
            sub_source: null,
          },
        })
      ); //either table or tablev2 will be trigger as long as you pass the parameter here
    }
  }, [onClose]);

  useEffect(() => {
    reqTags(tagForm);
    reqCanWorkStat({});
    reJobs({ query: '' });
    reqSubStatus({}).then((res: any) => {
      setJsOption([]);

      if (res?.data?.data?.length > 0) {
        let groups: any = [];
        res?.data?.data.map((item: any) => {
          if (item?.sub?.length > 0) {
            let option: any = [];
            option.push({
              label: 'All',
              value: null,
            });
            item?.sub?.map((sub: any, key: number) => {
              option.push({
                label: sub.sub_status,
                value: sub.id,
              });
            });
            groups.push({
              label: item.status,
              options: option,
            });
          }
          setJsOption(groups);
        });
      }
    });
    urlChangeStatus();
  }, []);

  const subSource = (sub: any) => {
    let option: any = [];
    if (sub?.length > 0) {
      sub.map((item: any) => {
        option.push({
          label: item?.sub_source,
          value: item?.id,
        });
      });
    }
    setCandSubSource(option);
  };

  useMemo(() => {
    reqUserList({ data: UserPgBtn }).then((res: any) => {
      let option: any = [];
      if (Object.values(res?.data?.data)?.length > 0) {
        Object.values(res?.data?.data).map((item: any) => {
          option.push({
            value: item.id,
            label: [item?.first_name, item?.last_name].join(' '),
          });
        });
      }
      setUserSource(option);
    })
  }, [])

  useMemo(() => {
    reqCandSource({}).then((res: any) => {
      let option: any = [];
      if (res?.data?.data?.length > 0) {
        res?.data?.data.map((item: any) => {
          option.push({
            label: item.source,
            value: item.id,
            sub: item.sub,
          });
        });
      }
      setCandSource(option);
    });
  }, [])

  useEffect(() => {
    if (resTags.isSuccess) {
      const getData = async () => {
        const option = await formatTagOption(resTags.data.data);
        setDefOption(option);
      };
      getData().catch(console.error);
    }
  }, [resTags.isSuccess]);

  useEffect(() => {
    setFilterDefValue(
      candidate_filters
        .map((item: any) => ({
          value: item.id,
          label: item.title,
        }))
        .filter((val: any) => val.value === filter.filter)[0]
    );
  }, [filter.filter, candidate_filters]);

  useEffect(() => {
    if (urlParam?.jobsId) {
      setVisibleFilter(false);
    }

    let status = jobCandidateStatus[urlParam.jobsTabId];

    if (urlParam.jobsTabId === 'in-progress') {
      let subs: any = [];
      subs['submits'] = [1];
      subs['interviews'] = [3];
      subs['offers'] = [4];
      status = urlParam?.jobsTabNav ? subs[urlParam.jobsTabNav] : [1];
    }

    let newParam = {
      page: filter.page,
      take: filter.take,
      query: filter.query,
      search: filter.search,
      boolean: isBool,
      job_filter: filter.job_filter,
      job_filter_include: filter.job_filter_include,
      location: filter.location,
      tags: filter.tags,
      skills: filter.skills,
      experience: filter.experience,
      work_status: filter.work_status,
      resume_updated_on: filter.resume_updated_on,
      job_id: job_id,
      excluded_job_id: excluded_job_id,
      job_status: filter.job_status,
      status: status,
      filter: filter?.filter,
      source: filter?.source,
      sub_source: filter?.sub_source,
    };

    if (urlParam['*'] === 'candidates') {
      // reqCandidates(newParam);
      dispatch(
        listCandidates({
          candidatePgBtn: newParam,
        })
      ); //either table or tablev2 will be trigger as long as you pass the parameter here
    }
  }, [batches, urlParam]);

  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  useEffect(() => {
    urlChangeStatus();
  }, [resSubStatus.isSuccess]);

  const [count, setCount] = useState(0);

  useEffect(() => {
    const {
      search,
      job_filter,
      job_filter_include,
      location: filterLocation,
      tags,
      experience,
      work_status,
      job_status,
      resume_updated_on,
      source,
      sub_source,
    } = filter;

    const nonEmptyValues = [
      filter.boolean
        ? search !== ''
        : search?.must_have?.length > 0 || search?.exclude?.length > 0,
      job_filter !== '',
      job_filter_include !== 'Current',
      filterLocation !== '' && location.length > 0,
      tags.length > 0,
      experience.length > 0,
      work_status !== null && work_status.length > 0,
      job_status !== null,
      resume_updated_on !== null,
      source !== null,
      sub_source !== null,
    ];

    setCount(nonEmptyValues.filter(Boolean).length);
  }, [filter, candidatePgBtn, location]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <Container
      w="20%"
      borderRight="1px solid"
      borderColor="default.borderlight"
      bg="#F7FAFC"
      overflowY="scroll"
      height="90vh"
      position="relative"
      p="0"
      pr="10px"
    >
      {visibleViewFilter && (
        <Box
          sx={{
            bg: '#FFF',
            borderRadius: '8px',
            mt: '20px',
            mb: '8px',
            p: '8px',
          }}
        >
          <Flex
            sx={{
              gap: '5px',
              mb: '15px',
              color: 'default.primarytext',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '18px',
            }}
          >
            <Image src={ViewList} />
            Views
          </Flex>

          <Box>
            <AtsSelectCandidateView
              name="candidate_view"
              onChange={(e: any) => {
                const id = e.label == 'All Candidates' ? null : e.value;
                filterViewChange(id);
                dispatch(listFilters({ filter: id }));
                setFilterDefValue([e]);
                setFilterActive(e.value);
              }}
              defaultValue={filterDefValue}
              value={filterDefValue}
              placeholder="Select View"
              isActive={filterActive}
            />
          </Box>
          <FilterList
            active={filterActive}
            setFilterActive={setFilterActive}
            onChange={(e) => {
              setFilterDefValue([]);
              filterPinChange(e);
            }}
          />
        </Box>
      )}

      <Flex
        gap="5px"
        my="24px"
        color="default.primarytext"
        fontSize="16px"
        fontWeight="600"
        lineHeight="18px"
      >
        Filters{' '}
        {count !== 0 && <Box sx={{ color: 'primary.800' }}>({count})</Box>}
      </Flex>

      <Box>
        {/* Job Candidate Status */}
        {visibleJS && (
          <Box
            sx={{
              bg: '#FFF',
              borderRadius: '8px',
              mb: '8px',
              p: '8px',
              '.chakra-select__icon-wrapper': {
                color: 'primary.800',
              },
            }}
          >
            <Box pb="4px" color="default.secondarytext" fontSize="14px">
              Job Candidate Status
            </Box>
            <AtsSelect
              options={jsOption}
              onChange={(event: any) => jsChange(event)}
              value={
                (filter.job_status &&
                  jsOption
                    .flatMap((category) => category.options)
                    .find((option) => option.value === filter.job_status)) || [
                  {
                    label: 'All',
                    value: null,
                  },
                ]
              }
              styles={{
                control: (styles: any) => ({
                  ...styles,
                  border: '1px solid #E2E8F0',
                  borderRadius: '6px',
                  cursor: 'pointer',
                  boxShadow: 'none',
                  '&:hover': { border: '1px solid #E2E8F0' },
                }),
                dropdownIndicator: (styles: any) => ({
                  ...styles,
                  color: '#6930CA',
                }),
                placeholder: (styles: any) => ({
                  ...styles,
                  fontSize: '14px',
                  color: '#A0AEC0',
                }),
                multiValue: (styles: any) => ({
                  ...styles,
                  background: '#F7FAFC',
                  border: '1px solid #E2E8F0',
                  borderRadius: '6px',
                  '&& div:first-of-type': {
                    color: '#6930CA',
                    fontWeight: 600,
                  },
                }),
              }}
            />
          </Box>
        )}

        {/* Location */}
        <Box
          sx={{
            bg: '#FFF',
            borderRadius: '8px',
            mb: '8px',
            p: '8px',
          }}
        >
          <Flex
            sx={{
              gap: '5px',
              pb: '4px',
              color: 'default.secondarytext',
              fontSize: '14px',
            }}
          >
            <Image src={LocationPin} />
            Location
          </Flex>
          <Box>
            <Box
              mb="5px"
              sx={{
                '&&& .multiValue': {
                  bg: 'red',
                },
              }}
            >
              <AtsCraetableSelDyn
                name="location"
                value={location}
                placeholder="Location Search"
                onChange={(item: any) => {
                  // reqGeo({ item, currentName });
                  setLocation(item);
                  let newParam = {
                    ...filter,
                    page: 1,
                    boolean: isBool,
                    location: item,
                    defTags: defTags,
                    job_id: job_id,
                    excluded_job_id: excluded_job_id,
                  };

                  sumbitList(newParam);
                }}
                onInputChange={promiseLocOptions}
                options={itemLocation}
                isMulti
                isClearable
                isLoading={resGeo.isLoading}
                formatCreateLabel={(userInput: any) => `Search "${userInput}"`}
                styles={{
                  control: (styles: any) => ({
                    ...styles,
                    border: '1px solid #E2E8F0',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    boxShadow: 'none',
                    '&:hover': { border: '1px solid #E2E8F0' },
                  }),
                  dropdownIndicator: (styles: any) => ({
                    ...styles,
                    color: '#6930CA',
                  }),
                  placeholder: (styles: any) => ({
                    ...styles,
                    fontSize: '14px',
                    color: '#A0AEC0',
                  }),
                  multiValue: (styles: any) => ({
                    ...styles,
                    background: '#F7FAFC',
                    border: '1px solid #E2E8F0',
                    borderRadius: '6px',
                    '&& div:first-of-type': {
                      color: '#6930CA',
                      fontWeight: 600,
                    },
                  }),
                }}
                components={{
                  Option: ({ label, innerProps }: any) => (
                    <Box onClick={(e) => innerProps.onClick(e)}>
                      <Flex
                        sx={{
                          fontFamily: 'Proxima Nova Regular',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: '12px 24px',
                          cursor: 'pointer',
                        }}
                        _hover={{
                          backgroundColor: 'rgba(239, 229, 255, 0.4)',
                          color: 'primary.800',
                        }}
                      >
                        <Box
                          sx={{
                            fontWeight: 600,
                            fontSize: '12px',
                          }}
                        >
                          {label}
                        </Box>
                      </Flex>
                    </Box>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Job Title */}
        <Box
          sx={{
            bg: '#FFF',
            borderRadius: '8px',
            mb: '8px',
            p: '8px',
          }}
        >
          <Flex
            sx={{
              gap: '5px',
              pb: '4px',
              color: 'default.secondarytext',
              fontSize: '14px',
            }}
          >
            <Image src={Work} />
            Job Title
          </Flex>
          <Box>
            <InputGroup
              bg="#fff"
              color="primary.800"
              sx={{
                '.chakra-textarea': {
                  fontWeight: 600,
                },
                '.chakra-textarea::placeholder': {
                  fontWeight: 'normal',
                },
              }}
            >
              <InputRightElement cursor="pointer" />
              <AutoResizeTextarea
                placeholder="Search Job Title"
                value={jobTitle}
                onChange={(e: any) => {
                  setJobTitle(e.target.value);
                  jobTitleChange(e);
                }}
                _focus={{
                  boxShadow: 'none',
                  border: '1px solid #E2E8F0',
                }}
              />
            </InputGroup>
          </Box>
          <Box pt="8px" pb="4px" color="default.secondarytext" fontSize="14px">
            Include:
          </Box>
          <RadioGroup value={titleInclude}>
            <Stack spacing={1} direction="column">
              <Radio
                color="primary.800"
                colorScheme="purple"
                value="Current or Past"
                onChange={(e: any) => jobTitleIncludeChange(e)}
              >
                Current or Past
              </Radio>
              <Radio
                color="primary.800"
                colorScheme="purple"
                value="Current"
                onChange={(e: any) => jobTitleIncludeChange(e)}
              >
                Current
              </Radio>
              <Radio
                color="primary.800"
                colorScheme="purple"
                value="Past"
                onChange={(e: any) => jobTitleIncludeChange(e)}
              >
                Past
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>

        {/* Candidate Source Sub Source */}
        <Box
          sx={{
            bg: '#FFF',
            borderRadius: '8px',
            mb: '8px',
            p: '8px',
          }}
        >
          <Flex
            sx={{
              gap: '5px',
              color: '#4A5568',
              fontSize: '16px',
              mb: "4px",
              verticalAlign: "center",
              alignItems: "center",
            }}
          >
            <FAIcon iconName="clipboard" />
            <Flex fontSize='14px'>
              Candidate Source{' '}
            </Flex>
          </Flex>
          <Box>
            <AtsSelect
              options={candSource}
              isLoading={resCandSource.isLoading}
              defaultValue={'values.source_type'}
              value={
                (filter.source &&
                  candSource.find(
                    (option) => option.value === filter.source
                  )) ||
                null
              }
              variant="outline"
              styles={{
                placeholder: (styles: any) => ({
                  ...styles,
                  fontSize: '14px',
                  color: '#A0AEC0',
                }),
                control: (styles: any) => ({
                  ...styles,
                  cursor: 'pointer',
                  border: '1px solid #E2E8F0',
                  borderRadius: '6px',
                  boxShadow: 'none',
                  '&:hover': { border: '1px solid #E2E8F0' },
                }),
                singleValue: (styles: any) => ({
                  ...styles,
                  color: '#6930CA',
                  fontSize: '14px',
                  fontWeight: '600',
                }),
                dropdownIndicator: (styles: any) => ({
                  ...styles,
                  color: '#6930CA',
                }),
              }}
              components={{
                Option: ({ label, innerProps }: any) => (
                  <Box onClick={(e) => innerProps.onClick(e)}>
                    <Flex
                      sx={{
                        fontFamily: 'Proxima Nova Regular',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: '12px 24px',
                        cursor: 'pointer',
                      }}
                      _hover={{
                        backgroundColor: 'rgba(239, 229, 255, 0.4)',
                        color: 'primary.800',
                      }}
                    >
                      <Box
                        sx={{
                          fontWeight: 600,
                          fontSize: '12px',
                        }}
                      >
                        {label}
                      </Box>
                    </Flex>
                  </Box>
                ),
              }}
              onChange={(e: any) => {
                sourceChange(e)
                if (
                  e.value === 3 ||
                  e.value === 5 ||
                  e.value === 6
                ) {
                  // const data = {
                  //   value: authSt?.id,
                  //   label: [
                  //     authSt?.first_name,
                  //     authSt?.last_name,
                  //   ].join(' '),
                  // };

                  setCandSubSource(userSource);
                  setCandSourceVisible(true);
                } else {
                  subSource(e?.sub);
                  setCandSourceVisible(true);
                  if (e.sub.length === 0) {
                    setCandSourceVisible(false);
                  }
                }
              }}
            />
            {candSourceVisible && (
              <>
                <Flex
                  sx={{
                    gap: '5px',
                    pt: "8px",
                    pb: '4px',
                    color: 'default.secondarytext',
                    fontSize: '14px',
                  }}
                >
                  Sub Source
                </Flex>
                <Box>
                  <AtsSelect
                    options={candSubSource}
                    value={(filter.sub_source &&
                      candSubSource.find(
                        (option) => option.value === filter.sub_source
                      )) ||
                      null}
                    onChange={(e: any) => {
                      subSourceChange(e)
                    }}
                    variant="outline"
                    styles={{
                      placeholder: (styles: any) => ({
                        ...styles,
                        fontSize: '14px',
                        color: '#A0AEC0',
                      }),
                      control: (styles: any) => ({
                        ...styles,
                        cursor: 'pointer',
                        border: '1px solid #E2E8F0',
                        borderRadius: '6px',
                        boxShadow: 'none',
                        '&:hover': { border: '1px solid #E2E8F0' },
                      }),
                      singleValue: (styles: any) => ({
                        ...styles,
                        color: '#6930CA',
                        fontSize: '14px',
                        fontWeight: '600',
                      }),
                      dropdownIndicator: (styles: any) => ({
                        ...styles,
                        color: '#6930CA',
                      }),
                    }}
                    components={{
                      Option: ({ label, innerProps }: any) => (
                        <Box onClick={(e) => innerProps.onClick(e)}>
                          <Flex
                            sx={{
                              fontFamily: 'Proxima Nova Regular',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              p: '12px 24px',
                              cursor: 'pointer',
                            }}
                            _hover={{
                              backgroundColor: 'rgba(239, 229, 255, 0.4)',
                              color: 'primary.800',
                            }}
                          >
                            <Box
                              sx={{
                                fontWeight: 600,
                                fontSize: '12px',
                              }}
                            >
                              {label}
                            </Box>
                          </Flex>
                        </Box>
                      ),
                    }}
                  />
                </Box>
              </>
            )
            }
          </Box>
        </Box>

        {/* Boolean */}
        <Box
          sx={{
            bg: '#FFF',
            borderRadius: '8px',
            mb: '8px',
            p: '8px',
          }}
        >
          <Flex
            sx={{
              justifyContent: 'space-between',
              mb: '4px',
              color: 'default.secondarytext',
              fontSize: '14px',
            }}
          >
            <Flex
              sx={{
                gap: '5px',
                pb: '4px',
                color: 'default.secondarytext',
                fontSize: '14px',
              }}
            >
              <Image src={BooleanImg} width="18px" height="18px" />
              Boolean
            </Flex>
            <Switch
              colorScheme="purple"
              id="isBool"
              isChecked={isBool}
              onChange={(e: any) => {
                setIsBool(e.target.checked);
                const searchBool = e.target.checked
                  ? ''
                  : {
                    must_have:
                      (filter.search.must_have &&
                        filter.search.must_have?.map(
                          (item: any, index: number) => ({
                            title: item,
                            id: index,
                          })
                        )) ||
                      [],
                    exclude:
                      (filter.search.exclude &&
                        filter.search.exclude?.map(
                          (item: any, index: number) => ({
                            title: item,
                            id: index,
                          })
                        )) ||
                      [],
                  };

                setSearch(searchBool);
                localStorage.setItem(
                  'candidatesFilter',
                  JSON.stringify({
                    ...filter,
                    search: searchBool,
                    boolean: e.target.checked,
                  })
                );
              }}
            />
          </Flex>
          {isBool === true ? (
            <Box
              sx={{
                '.chakra-textarea': {
                  fontWeight: 600,
                },
                '.chakra-textarea::placeholder': {
                  fontWeight: 'normal',
                },
              }}
            >
              <AutoResizeTextarea
                placeholder="Search..."
                value={String(search)}
                onChange={(event: any) => {
                  setSearch(event.target.value);
                  booleanSearchChange(event)
                }}
                mb="5px"
                bg="#fff"
                color="primary.800"
                fontSize="14px"
                _focus={{
                  boxShadow: 'none',
                  border: '1px solid #E2E8F0',
                }}
              />
            </Box>
          ) : (
            <CandidateFilter
              setSearch={setSearch}
              mustHaveTasks={mustHaveTasks}
              setMustHaveTasks={setMustHaveTasks}
              excludeTasks={excludeTasks}
              setExcludeTasks={setExcludeTasks}
              filterChange={filterChange}
            />
          )}
        </Box>

        {/* Resume updated on */}
        <Box
          sx={{
            bg: '#FFF',
            borderRadius: '8px',
            mb: '8px',
            p: '8px',
            '.chakra-select__icon-wrapper': {
              color: 'primary.800',
            },
          }}
        >
          <Flex
            sx={{
              gap: '5px',
              pb: '4px',
              color: 'default.secondarytext',
              fontSize: '14px',
            }}
          >
            <Image src={DocumentScanner} />
            Resume updated on
          </Flex>
          <AtsSelect
            name="company"
            options={resumeOptions}
            value={
              (filter.resume_updated_on &&
                resumeOptions.find(
                  (option) => option.label === filter.resume_updated_on
                )) ||
              null
            }
            variant="outline"
            onChange={(e: any) => resumeUpdatedOnChange(e)}
            styles={{
              placeholder: (styles: any) => ({
                ...styles,
                fontSize: '14px',
                color: '#A0AEC0',
              }),
              control: (styles: any) => ({
                ...styles,
                cursor: 'pointer',
                border: '1px solid #E2E8F0',
                borderRadius: '6px',
                boxShadow: 'none',
                '&:hover': { border: '1px solid #E2E8F0' },
              }),
              singleValue: (styles: any) => ({
                ...styles,
                color: '#6930CA',
                fontSize: '14px',
                fontWeight: '600',
              }),
              dropdownIndicator: (styles: any) => ({
                ...styles,
                color: '#6930CA',
              }),
            }}
            components={{
              Option: ({ label, innerProps }: any) => (
                <Box onClick={(e) => innerProps.onClick(e)}>
                  <Flex
                    sx={{
                      fontFamily: 'Proxima Nova Regular',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: '12px 24px',
                      cursor: 'pointer',
                    }}
                    _hover={{
                      backgroundColor: 'rgba(239, 229, 255, 0.4)',
                      color: 'primary.800',
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 600,
                        fontSize: '12px',
                      }}
                    >
                      {label}
                    </Box>
                  </Flex>
                </Box>
              ),
            }}
          />
        </Box>

        {/* Last Activity -- Comment out, since it doesn't have an API yet */}
        {/* <Box
        pb="24px"
        sx={{
          '.chakra-select__icon-wrapper': {
            color: 'primary.800',
          },
        }}
      >
        <Box pb="4px" color="default.secondarytext" fontSize="14px">
          Last Activity
        </Box>
        <AtsSelect
          name="last_activity_time"
          options={lastActivityOptions}
          variant="outline"
        />
      </Box> */}

        {/* Work Status */}
        <Box
          sx={{
            bg: '#FFF',
            borderRadius: '8px',
            mb: '8px',
            p: '8px',
            '.chakra-select__icon-wrapper': {
              color: 'primary.800',
            },
          }}
        >
          <Flex
            sx={{
              gap: '5px',
              pb: '4px',
              color: 'default.secondarytext',
              fontSize: '14px',
            }}
          >
            <Image src={Circle} />
            Work Status
          </Flex>
          <AtsSelect
            name="work_status"
            options={workStatusOptions}
            value={
              (filter.work_status &&
                candidate_work_status
                  .filter((item: any) => filter.work_status?.includes(item.id))
                  .map((item: any) => ({
                    value: item.id,
                    label: item.status,
                  }))) ||
              []
            }
            variant="outline"
            onChange={workStatusChange}
            isMulti
            styles={{
              placeholder: (styles: any) => ({
                ...styles,
                fontSize: '14px',
                color: '#A0AEC0',
              }),
              control: (styles: any) => ({
                ...styles,
                border: '1px solid #E2E8F0',
                borderRadius: '6px',
                cursor: 'pointer',
                boxShadow: 'none',
                '&:hover': { border: '1px solid #E2E8F0' },
              }),
              dropdownIndicator: (styles: any) => ({
                ...styles,
                color: '#6930CA',
              }),
              multiValue: (styles: any) => ({
                ...styles,
                background: '#F7FAFC',
                border: '1px solid #E2E8F0',
                borderRadius: '6px',
                '&& div:first-of-type': {
                  color: '#6930CA',
                  fontWeight: 600,
                },
              }),
            }}
            components={{
              Option: ({ label, innerProps }: any) => (
                <Box onClick={(e) => innerProps.onClick(e)}>
                  <Flex
                    sx={{
                      fontFamily: 'Proxima Nova Regular',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: '12px 24px',
                      cursor: 'pointer',
                    }}
                    _hover={{
                      backgroundColor: 'rgba(239, 229, 255, 0.4)',
                      color: 'primary.800',
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 600,
                        fontSize: '12px',
                      }}
                    >
                      {label}
                    </Box>
                  </Flex>
                </Box>
              ),
            }}
          />
        </Box>

        {/* Tags */}
        <Box
          sx={{
            bg: '#FFF',
            borderRadius: '8px',
            mb: '70px',
            p: '8px',
          }}
        >
          <Flex
            sx={{
              gap: '5px',
              pb: '4px',
              color: 'default.secondarytext',
              fontSize: '14px',
            }}
          >
            <Image src={Tag} />
            Tags
          </Flex>
          <RadioGroup defaultValue={tagPrivate}>
            <Stack py={2} spacing={5} direction="row">
              <Radio
                color="primary.800"
                colorScheme="purple"
                value="1"
                onChange={(e: any) => setTagPrivate(e.target.value)}
              >
                All Tags
              </Radio>
              <Radio
                color="primary.800"
                colorScheme="purple"
                value="2"
                onChange={(e: any) => setTagPrivate(e.target.value)}
              >
                Private
              </Radio>
            </Stack>
          </RadioGroup>
          <AtsAsyncSelect2
            value={
              (filter.tags &&
                defOption.filter((option) =>
                  filter.tags?.includes(option.value)
                )) ||
              []
            }
            loadOptions={promiseTagOptions}
            isMulti={true}
            defaultOptions={defOption}
            onChange={tagChange}
            cacheOptions={false}
            styles={{
              control: (styles: any) => ({
                ...styles,
                border: '1px solid #E2E8F0',
                borderRadius: '6px',
                cursor: 'pointer',
                boxShadow: 'none',
                '&:hover': { border: '1px solid #E2E8F0' },
              }),
              dropdownIndicator: (styles: any) => ({
                ...styles,
                color: '#6930CA',
              }),
              placeholder: (styles: any) => ({
                ...styles,
                fontSize: '14px',
                color: '#A0AEC0',
              }),
              multiValue: (styles: any) => ({
                ...styles,
                background: '#F7FAFC',
                border: '1px solid #E2E8F0',
                borderRadius: '6px',
                '&& div:first-of-type': {
                  color: '#6930CA',
                  fontWeight: 600,
                },
              }),
            }}
            components={{
              Option: ({ label, innerProps }: any) => (
                <Box onClick={(e) => innerProps.onClick(e)}>
                  <Flex
                    sx={{
                      fontFamily: 'Proxima Nova Regular',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: '12px 24px',
                      cursor: 'pointer',
                    }}
                    _hover={{
                      backgroundColor: 'rgba(239, 229, 255, 0.4)',
                      color: 'primary.800',
                    }}
                  >
                    <Box
                      sx={{
                        fontWeight: 600,
                        fontSize: '12px',
                      }}
                    >
                      {label}
                    </Box>
                  </Flex>
                </Box>
              ),
            }}
          />
        </Box>
      </Box>

      {count !== 0 && (
        <Flex
          sx={{
            position: 'fixed',
            bottom: 0,
            bg: '#fff',
            w: '20%',
            borderTop: '1px solid #E6E9EE',
            ml: '-20px',
            p: '16px 24px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ fontSize: '14px', fontWeight: 600, color: 'primary.800' }}>
            {count} item(s) selected
          </Box>
          <Button variant="solid" size="md" onClick={clearFilterPanel}>
            Clear Filter
          </Button>
        </Flex>
      )}
    </Container>
  );
}

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Formik } from 'formik';

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import ScrollToFieldError from 'components/app/ScrollError';
import LoadingPage from 'components/app/Loading';
import TemplateTable from './table';
import TemplateAddModal from './modal/add';
import { useListTemplateMutation } from 'store/template.slice';

export default function EmailTemplates() {
  const initialValue = {
    search: '',
  };

  const { templateNav, templatePage, templatePageBtn } = useSelector(
    (state: any) => state.template
  );
  const [reqListTemplate, resListTemplate] = useListTemplateMutation();

  const {
    isOpen: addOpen,
    onOpen: addOnOpen,
    onClose: addOnClose,
  } = useDisclosure();

  const onSubmit = (data: any) => {
    let params = {
      page: 1,
      take: templatePageBtn.take,
      query: data.search,
    };
    submitForm(params);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        page: 1,
        take: templatePageBtn.take,
        query: '',
      };
      submitForm(params);
    }
  };

  const submitForm = (form: any) => {
    reqListTemplate(form);
  };

  return (
    <Box fontFamily="Proxima Nova Regular">
      <Flex justifyContent="space-between" px={6}>
        <Formik
          enableReinitialize
          initialValues={initialValue}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => (
            <Flex
              justifyContent="space-around"
              maxWidth="40%"
              alignItems="center"
            >
              <Box pr={2}>
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <InputGroup background="#fff">
                    <InputRightElement
                      cursor="pointer"
                      children={<FAIcon iconName="search" />}
                    />
                    <Input
                      type="search"
                      placeholder="Search Template"
                      value={values.search}
                      id="search"
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </form>
              </Box>
            </Flex>
          )}
        </Formik>
        <Button leftIcon="plus" variant="solid" onClick={addOnOpen}>
          Add Template
        </Button>
      </Flex>
      <Box>
        <TemplateTable />
      </Box>
      {addOpen && <TemplateAddModal isOpen={addOpen} onClose={addOnClose} />}
    </Box>
  );
}

import { Box, Container, keyframes, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const animationKeyframes = keyframes`
  0% { transform: scale(1) rotate(0); border-radius: 20%; }
  25% { transform: scale(2) rotate(0); border-radius: 20%; }
  50% { transform: scale(2) rotate(270deg); border-radius: 50%; }
  75% { transform: scale(1) rotate(270deg); border-radius: 50%; }
  100% { transform: scale(1) rotate(0); border-radius: 20%; }
`;

const animation = `${animationKeyframes} 2s ease-in-out infinite`;
interface loadingInterface {
  text?: string;
}
const LoadingPage = ({ text = '' }: loadingInterface) => {
  return (
    <Container
      h="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        position: 'fixed',
        width: '100%',
        maxWidth: '100%',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        background: '#ffffffc7',
        zIndex: 99999999,
      }}
    >
      <Box
        as={motion.div}
        animation={animation}
        // not work: transition={{ ... }}
        padding="2"
        // @ts-ignore - "Does not exist" Type Error against Motion
        bgGradient="linear(to-l, #7928CA, #FF0080)"
        width="12"
        height="12"
        display="flex"
      />
      <Text style={{ position: 'absolute', marginTop: '14%' }}>{text}</Text>
    </Container>
  );
};

export default LoadingPage;

import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Image,
  Link,
  Box,
} from '@chakra-ui/react';

import { fileApplication } from 'utils/fileApplication';
import { downloadLink } from 'utils/downloadlink';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import ZipImage from '../../../../assets/images/icons/zip.svg';
import DocImage from '../../../../assets/images/icons/docx.svg';
import ExcelImage from '../../../../assets/images/icons/excel.svg';
import CsvImage from '../../../../assets/images/icons/csv.svg';
import TxtImage from '../../../../assets/images/icons/txt.svg';
import UnknownImage from '../../../../assets/images/icons/unkown.svg';
// import '../../../../assets/css/pdf.css';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import PDFViewer from 'components/app/PDFViewer';

interface StreamModalProps {
  isOpen: any;
  onClose: any;
  file?: any;
  route?: string;
}

export default function StreamAttachmentModal({
  isOpen,
  onClose,
  file,
  route = '/lead-attachment/download/',
}: StreamModalProps) {
  // const { Document, Page, StyleSheet, pdfjs } = require('react-pdf');
  // const [pageNumber, setNumPages] = useState(null);
  // useEffect(() => {
  //   pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  // }, []);
  // function onDocumentLoadSuccess(pdf: any) {
  //   console.log('pdf', pdf?.numPages);
  //   setNumPages(pdf?.numPages);
  // }

  // console.log('pageNumber', pageNumber);

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });

  // const [resumeUrl, setResumeUrl] = useState('');

  const StreamComponent = () => {
    switch (fileApplication(file.file_name)) {
      case 'jpeg':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={file.signedUrl}
          />
        );
      case 'jpg':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={file.signedUrl}
          />
        );
      case 'png':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={file.signedUrl}
          />
        );
      case 'zip':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={ZipImage}
          />
        );
      case 'doc':
        const doc = [{ uri: file.signedUrl, fileType: 'doc' }];

        return (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={doc}
            style={{ width: '100%', height: 700 }}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />
        );
      case 'docx':
        const docx = [{ uri: file.signedUrl, fileType: 'docx' }];

        return (
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={docx}
            style={{ width: '100%', height: 700 }}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: true,
              },
            }}
          />
        );
      case 'csv':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={CsvImage}
          />
        );
      case 'xls':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={ExcelImage}
          />
        );
      case 'xlsx':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={ExcelImage}
          />
        );

      case 'txt':
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            src={TxtImage}
          />
        );
      case 'pdf':
        return (
          // <Document file={file.signedUrl} onLoadSuccess={onDocumentLoadSuccess}>
          //   <Page pageNumber={pageNumber} />
          // </Document>
          <PDFViewer
            pdfUrl={file.signedUrl}
            // setResumeUrl={setResumeUrl}
            height="80vh"
          />
        );
      case 'mp4':
        return (
          <ReactPlayer
            url={file.signedUrl}
            controls={true}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '70vh',
              margin: 'auto',
            }}
          />
        );
      case 'mov':
        return (
          <ReactPlayer
            url={file.signedUrl}
            controls={true}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '70vh',
              margin: 'auto',
            }}
          />
        );
      case 'mpeg':
        return (
          <ReactPlayer
            url={file.signedUrl}
            controls={true}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '70vh',
              margin: 'auto',
            }}
          />
        );

      case 'mp3':
        return (
          <ReactPlayer
            url={file.signedUrl}
            controls={true}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '70vh',
              margin: 'auto',
            }}
          />
        );

      default:
        return (
          <Image
            style={{ maxHeight: '70vh', width: '100%', objectFit: 'contain' }}
            objectFit="contain"
            src={UnknownImage}
          />
        );
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {file.original_file_name}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            alignItems="center"
            textAlign={'center'}
          >
            <StreamComponent />
          </ModalBody>
          <ModalFooter
            position="sticky"
            bottom="0"
            background="default.white.100"
            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
          >
            <Link variant="solid" href={downloadLink(file.id, route)}>
              Download
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

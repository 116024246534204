import {
  Box,
  Divider,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import Button from 'Library/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import replacePTags from 'utils/replaceTags';
import replacePlaceholders from 'utils/textPlaceholders';
import 'assets/css/list-style.css';

interface EmailPreviewModalProps {
  isOpen: boolean;
  onClose: any;
  data: any;
  onSubmit: any;
  disabled: boolean;
  isLoading?: boolean;
  isFollowUp?: boolean;
}

const EmailPreviewModal = ({
  isOpen,
  onClose,
  data,
  onSubmit,
  disabled,
  isLoading,
  isFollowUp,
}: EmailPreviewModalProps) => {
  const { attachments } = useSelector((state: any) => state.uploads);

  const ReplacePWithDiv = ({ children }: any) => {
    return (
      <div>
        {children.map((child: any) => {
          if (child.type === 'p') {
            return <div>{child.props.children}</div>;
          } else {
            return child;
          }
        })}
      </div>
    );
  };

  const [ccLabel, setCC] = useState([]);
  const [BccLabel, setBcC] = useState([]);

  useEffect(() => {
    let ccOption = [] as any;
    data?.cc?.map((item: any) => {
      ccOption.push(item?.value); //remove the item in the pushed options, causing to display [object]
    });

    setCC(ccOption);
    let BccOption = [] as any;
    data?.bcc?.map((item: any) => {
      BccOption.push(item?.value);
    });
    setBcC(BccOption);
  }, [data]);

  const { jobData, byContactCandidates, selectedCandidates } = useSelector(
    (state: any) => state.jobs
  );

  const { placehoderPair } = useSelector((state: any) => state.template);

  const placholders = (text: string) => {
    const placeholderParam = {
      ...placehoderPair,
      job_title: jobData?.title || '',
      contact_first_name: jobData?.lead?.first_name || '',
      job_id: jobData?.id,
    };

    return replacePlaceholders(text, placeholderParam);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader></ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody
            borderRadius="0 0 4px 4px"
            p="24px"
            fontFamily="Proxima Nova Regular"
          >
            <Flex gap="16px" direction="column">
              <Box sx={{ fontSize: '18px', fontWeight: 700, color: '#2D3748' }}>
                {placholders(data?.subject)}
              </Box>

              <Flex gap="8px" direction="column">
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  To: <span style={{ color: '#000' }}>{data?.to}</span>
                </Box>
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Cc:{' '}
                  <span style={{ color: '#000' }}>
                    {(ccLabel as any).filter(Boolean).join(', ')}
                  </span>
                </Box>
                <Box sx={{ fontSize: '14px', color: '#718096' }}>
                  Bcc:{' '}
                  <span style={{ color: '#000' }}>
                    {(BccLabel as any).filter(Boolean).join(', ')}
                  </span>
                </Box>
              </Flex>

              <Divider />

              <Box>
                <div
                  style={{
                    fontFamily: 'verdana',
                    fontSize: '12px',
                    margin: 0,
                    padding: 0,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: replacePTags(placholders(data?.body)),
                  }}
                />
              </Box>

              <Flex gap="5px" direction="column">
                {data?.primary && (
                  <Tag width="fit-content">
                    <Stack spacing={1}>
                      <HStack>
                        <TagLabel sx={{ fontWeight: 600 }}>
                          {data?.resume}
                        </TagLabel>
                      </HStack>
                    </Stack>
                  </Tag>
                )}
                {attachments.length > 0 &&
                  attachments.map((item: any, key: number) => {
                    return (
                      <Tag key={'attachments-' + key} width="fit-content">
                        <Stack spacing={1}>
                          <HStack>
                            <TagLabel sx={{ fontWeight: 600 }}>
                              {item.name}
                            </TagLabel>
                          </HStack>
                        </Stack>
                      </Tag>
                    );
                  })}
              </Flex>
            </Flex>

            <Box textAlign="right">
              <Button
                variant="solid"
                type="submit"
                disabled={disabled}
                onClick={onSubmit}
                loading={isLoading}
              >
                {isFollowUp ? 'Send Follow-up' : 'Submit to Client'}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailPreviewModal;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import * as Yup from 'yup';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { cleanUpload } from 'store/uploads.slice';
import { useGetLeadsMutation } from 'store/leads.slice';
import {
  useGetAgreementMutation,
  useListLeadAgreementMutation,
  useUpdateAgreementMutation,
} from 'store/leadagreement.sclice';
import AtsDropZone from '../../Dropzone';
import LeadAgreementAttachments from './attachments';
import toDate from 'utils/toDate';
import ScrollToFieldError from 'components/app/ScrollError';
import { useParams } from 'react-router';
import LeadsEditgreementModal from './edit';
import moment from 'moment';

interface LeadsAgrrmentModalProps {
  isOpen: any;
  onClose: any;
}

export default function LeadsViewAgreementModal({
  isOpen,
  onClose,
}: LeadsAgrrmentModalProps) {
  const { agreementData, attachments } = useSelector(
    (state: any) => state.leadagreement
  );

  const [reqget, resget] = useGetAgreementMutation();

  const {
    isOpen: isEditAgreementOpen,
    onOpen: onEditAgreementOpen,
    onClose: onEditAgreementClose,
  } = useDisclosure();

  useEffect(() => {
    reqget({ id: agreementData?.id });
  }, [onEditAgreementClose]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            View Agreement
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Box p="32px">
              <Flex gap="32px" mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Title
                  </FormLabel>
                  {agreementData?.title}
                </FormControl>
              </Flex>
              <Box mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Content
                  </FormLabel>
                  {agreementData?.content}
                </FormControl>
              </Box>

              <Flex gap="32px" mb="34px">
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Signed On:{' '}
                    {moment
                      .utc(agreementData?.date_signed)
                      .format('MM/DD/YYYY')}
                  </FormLabel>
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Valid Until:{' '}
                    {moment
                      .utc(agreementData?.valid_until)
                      .format('MM/DD/YYYY')}
                  </FormLabel>
                </FormControl>
              </Flex>
              <Box mb="34px">
                <LeadAgreementAttachments attachments={attachments} />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter
            position="sticky"
            bottom="0"
            background="default.white.100"
            boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
          >
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button variant="solid" type="button" onClick={onEditAgreementOpen}>
              Edit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isEditAgreementOpen && (
        <LeadsEditgreementModal
          isOpen={isEditAgreementOpen}
          onClose={onEditAgreementClose}
        />
      )}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import {
  Box,
  Input,
  InputGroup,
  Tag,
  Stack,
  HStack,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

const Filter = ({
  mustHaveTasks,
  setMustHaveTasks,
  excludeTasks,
  setExcludeTasks,
  setSearch,
  filterChange,
}: any) => {
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const candidatedFilterData: string | null =
    localStorage.getItem('candidatesFilter');

  const filterData =
    candidatedFilterData === null
      ? candidatePgBtn
      : JSON.parse(candidatedFilterData);

  useEffect(() => {
    const mustHave = mustHaveTasks.map((val: any) => val.title);
    const exclude = excludeTasks.map((val: any) => val.title);

    setSearch({
      must_have: mustHave,
      exclude: exclude,
    });

    const filter = {
      search: {
        must_have: mustHave,
        exclude: exclude,
      },
      filter: filterData,
    };

    filterChange(filter);
  }, [mustHaveTasks, excludeTasks, setSearch]);

  const onDragStart = (
    event: React.DragEvent<HTMLDivElement>,
    id: { toString: () => any },
    category: any
  ) => {
    event.dataTransfer.setData('taskId', id.toString());
    event.dataTransfer.setData('category', category);
  };

  const onDragOver = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const onDrop = (
    event: React.DragEvent<HTMLDivElement>,
    targetCategory: string
  ) => {
    const taskId = event.dataTransfer.getData('taskId');
    const sourceCategory = event.dataTransfer.getData('category');

    if (sourceCategory === targetCategory) {
      return;
    }

    let updatedTask;
    let sourceTasks;
    let targetTasks;

    switch (sourceCategory) {
      case 'must-have':
        updatedTask = mustHaveTasks.find(
          (task: { id: { toString: () => any } }) =>
            task.id.toString() === taskId
        );
        sourceTasks = mustHaveTasks.filter(
          (task: { id: { toString: () => any } }) =>
            task.id.toString() !== taskId
        );
        break;
      case 'exclude':
        updatedTask = excludeTasks.find(
          (task: { id: { toString: () => any } }) =>
            task.id.toString() === taskId
        );
        sourceTasks = excludeTasks.filter(
          (task: { id: { toString: () => any } }) =>
            task.id.toString() !== taskId
        );
        break;
      default:
        return;
    }

    switch (targetCategory) {
      case 'must-have':
        targetTasks = [...mustHaveTasks, updatedTask];
        setMustHaveTasks(targetTasks);
        break;
      case 'exclude':
        targetTasks = [...excludeTasks, updatedTask];
        setExcludeTasks(targetTasks);
        break;
      default:
        return;
    }

    switch (sourceCategory) {
      case 'must-have':
        setMustHaveTasks(sourceTasks);
        break;
      case 'exclude':
        setExcludeTasks(sourceTasks);
        break;
      default:
        return;
    }
  };

  const onDragEnd = (category: any) => {
    switch (category) {
      case 'must-have':
        setMustHaveTasks((tasks: any[]) =>
          tasks.map((task: any, index: number) => ({ ...task, id: index + 1 }))
        );
        break;
      case 'exclude':
        setExcludeTasks((tasks: any[]) =>
          tasks.map((task: any, index: number) => ({ ...task, id: index + 1 }))
        );
        break;
      default:
        return;
    }
  };

  const removeTask = (taskId: string, category: string) => {
    let updatedTasks;

    switch (category) {
      case 'must-have':
        updatedTasks = mustHaveTasks.filter(
          (task: { id: { toString: () => string } }) =>
            task.id.toString() !== taskId
        );
        setMustHaveTasks(updatedTasks);
        break;
      case 'exclude':
        updatedTasks = excludeTasks.filter(
          (task: { id: { toString: () => string } }) =>
            task.id.toString() !== taskId
        );
        setExcludeTasks(updatedTasks);
        break;
      default:
        return;
    }
  };

  const renderTasks = (tasks: any[], category: string) => {
    return tasks.map(
      (task: {
        id: React.Key;
        title:
          | string
          | number
          | boolean
          | React.ReactElement<any, string | React.JSXElementConstructor<any>>
          | Iterable<React.ReactNode>
          | React.ReactPortal;
      }) => (
        <Box
          key={task.id}
          className="task"
          draggable
          onDragStart={(event) => onDragStart(event, task.id, category)}
          onDragEnd={() => onDragEnd(category)}
          sx={{ display: 'inline-block', cursor: 'pointer' }}
        >
          <Tag
            mr="10px"
            mb="5px"
            bg={category === 'exclude' ? '#f07663' : '#52cf52'}
          >
            <Stack spacing={1}>
              <HStack>
                <TagLabel maxW="100px">{task.title}</TagLabel>
                <TagCloseButton
                  onClick={() => removeTask(task.id.toString(), category)}
                />
              </HStack>
            </Stack>
          </Tag>
        </Box>
      )
    );
  };

  return (
    <Box>
      <Box mt="10px">
        <Box pb="4px" color="default.secondarytext" fontSize="14px">
          Must Have
        </Box>
        <Box
          border="1px solid"
          borderColor="rgb(226, 232, 240)"
          borderRadius="md"
          p="5px"
          pb="0"
          minH="36px"
          onDragOver={onDragOver}
          onDrop={(event) => onDrop(event, 'must-have')}
          cursor="pointer"
        >
          {renderTasks(mustHaveTasks, 'must-have')}
        </Box>
      </Box>
      <Box mt="10px">
        <Box pb="4px" color="default.secondarytext" fontSize="14px">
          Exclude
        </Box>
        <Box
          border="1px solid"
          borderColor="rgb(226, 232, 240)"
          borderRadius="md"
          p="5px"
          pb="0"
          minH="36px"
          onDragOver={onDragOver}
          onDrop={(event) => onDrop(event, 'exclude')}
          cursor="pointer"
        >
          {renderTasks(excludeTasks, 'exclude')}
        </Box>
      </Box>
    </Box>
  );
};

const CandidateFilter = ({
  setSearch,
  filterChange,
  mustHaveTasks,
  setMustHaveTasks,
  excludeTasks,
  setExcludeTasks,
}: any) => {
  const [newTask, setNewTask] = useState('');
  const handleAddTask = () => {
    if (newTask.trim() === '') {
      return;
    }

    const newTaskObj = {
      id: mustHaveTasks.length + 1,
      title: newTask.trim(),
    };

    setMustHaveTasks([...mustHaveTasks, newTaskObj]);
    setNewTask('');
  };

  return (
    <div>
      <InputGroup
        background="#fff"
        mb="24px"
        color="primary.800"
        sx={{
          '.chakra-input': {
            fontWeight: 600,
            fontSize: '14px',
          },
          '.chakra-input::placeholder': {
            fontWeight: 'normal',
          },
        }}
      >
        <Input
          type="search"
          placeholder="Type Keyword"
          value={newTask}
          onChange={(event) => {
            setNewTask(event.target.value);
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleAddTask();
            }
          }}
          _focus={{
            boxShadow: 'none',
            border: '1px solid #E2E8F0',
          }}
        />
      </InputGroup>
      <Filter
        mustHaveTasks={mustHaveTasks}
        setMustHaveTasks={setMustHaveTasks}
        excludeTasks={excludeTasks}
        setExcludeTasks={setExcludeTasks}
        setSearch={setSearch}
        filterChange={filterChange}
      />
    </div>
  );
};

export default CandidateFilter;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Field, FieldArray, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  MenuDivider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  FormErrorMessage,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  useToast,
  Switch,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';

import AtsDropZone from '../Dropzone';
import AtsSelect2 from '../AtsSelect2/index';

import {
  useListLeadsMutation,
  useToClientMutation,
  useByClientMutation,
  useGetLeadsMutation,
} from 'store/leads.slice';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import { changeUploadName } from 'utils/newFilename';
import ScrollToFieldError from '../ScrollError';
import { useUserListMutation } from 'store/user.slice';
import Button from 'Library/Button';
import { useViewClientMutation } from 'store/client.slice';
import {
  useGetCountriesMutation,
  useGetJobSpecsMutation,
  useGetLeadSourceMutation,
} from 'store/constant.slice';
import AtsSelect from '../AtsSelect';
import { addressFormat, leadSourceFormat } from '../../../utils/utilFormat';
import AddressComponent from './components/AddressComponent';

interface LeadsConvertClientModalProps {
  isOpen: any;
  onClose: any;
  leadId?: number;
}
export default function LeadsConvertClientModal({
  isOpen,
  onClose,
  leadId = 0,
}: LeadsConvertClientModalProps) {
  const dispatch = useDispatch();
  const params = useParams();
  const toast = useToast();

  const { leadData, leadClient, leadPgBtn } = useSelector(
    (state: any) => state.leads
  );
  const { clientData } = useSelector((state: any) => state.clients);
  const { uploaded, prefix, withPrefix, uploading } = useSelector(
    (state: any) => state.uploads
  );

  const { countries, lead_source, job_specs } = useSelector(
    (state: any) => state.constants
  );

  const { userList } = useSelector((state: any) => state.user);

  const [reqLeads] = useListLeadsMutation();
  const [reqGetLeads] = useGetLeadsMutation();
  const [reqClient, resClient] = useToClientMutation();
  const [reqLeadClient] = useByClientMutation();
  const [reqUser, resUser] = useUserListMutation();
  const [reqClientData, resClientData] = useViewClientMutation();
  const [reqLeadSource, resLeadSource] = useGetLeadSourceMutation();
  const [reqCountry, resCountry] = useGetCountriesMutation();
  const [reqSpecs, resSpecs] = useGetJobSpecsMutation();

  let [managers, setManager] = useState([]);

  const [jobSpecsDefOption, setJobSpecsDefOption] = useState({});
  const [jobSpecsOption, setJobSpecsOption] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [selectOption, setSelectOption] = useState([]);
  const [leadSrouceList, setLeadSrouceList] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);

  const leadID = params.leadsId;

  useEffect(() => {
    reqSpecs({});
  }, [reqSpecs]);

  useEffect(() => {
    reqLeadSource({});
    reqCountry({});
  }, [leadID, isOpen]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const leadClientData: any = Object.values(leadClient).filter(
    (lead: any) => lead.id === leadData?.id
  );

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption([
            {
              label: item.country,
              value: item.id,
              code: item.code,
            },
          ]);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    // leadSrouceList, setLeadSrouceList;
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSrouceList(options);
  }, [lead_source]);

  useEffect(() => {
    reqUser({});
    dispatch(uploadList({ prefix: 'Attachment' }));
    dispatch(cleanUpload({ withPrefix: false }));
    dispatch(cleanUpload({ attachments: [] }));
    dispatch(cleanUpload({ uploaded: [] }));
    reqLeadClient({ id: leadData?.client_id });

    if (resClient.isSuccess) {
      onClose();
      reqLeads(leadPgBtn);

      try {
        const description = `Lead was successfully converted to a contact`;

        toast({
          title: 'Request to Convert',
          description: description,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
    if (resClient.isError) {
      let errorMessage: any = resClient.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMessage(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMessage(errorMessage['data']['message']);
        } catch (e) {
          console.log('e', e);
          setErrorMessage('Server Error');
        }
      }
      onOpen();
    }
  }, [
    leadID,
    resClient.isError,
    resClient.isSuccess,
    leadData?.client_id,
    resClient.error,
  ]);

  useEffect(() => {
    reqClientData({ id: leadData?.client_id });
    reqGetLeads({ id: leadData?.id });
  }, []);

  useEffect(() => {
    let userOption: any = [];
    if (resUser.isSuccess) {
      if (resUser.data.data.length > 0) {
        resUser.data.data.map(async (data: any) => {
          await userOption.push({
            value: data.id,
            label: [data.first_name, data.last_name].join(' '),
          });
        });
        setSelectOption(userOption);
      }
    }
  }, [resUser.isSuccess]);

  useEffect(() => {
    let options: any = [];
    if (job_specs?.length > 0) {
      job_specs.map((item: any) => {
        options.push({
          label: item.specification,
          value: item.id,
        });
        if (item.id === leadData.spec_id) {
          setJobSpecsDefOption({
            label: item.specification,
            value: item.id,
          });
        }
      });
    }
    setJobSpecsOption(options);
  }, [job_specs]);
  useEffect(() => {
    if (clientData?.manager?.length > 0) {
      let options: any = [];
      clientData?.manager.map((data: any) => {
        options.push({
          value: data?.user?.id,
          label: [data?.user?.first_name, data?.user?.last_name].join(' '),
        });
      });
      setManager(options);
    }
  }, [clientData]);

  useEffect(() => {
    let userOption: any = [];

    if (resClientData.isSuccess) {
      const managersId: any = resClientData?.data?.data?.manager?.map(
        (a: any) => a.user_id
      );
      if (resUser.isSuccess) {
        if (resUser.data.data.length > 0) {
          resUser.data.data.map(async (data: any) => {
            if (!managersId?.includes(data.id)) {
              await userOption.push({
                value: data.id,
                label: [data.first_name, data.last_name].join(' '),
              });
            }
          });
          setSelectOption(userOption);
        }
      }
    }
  }, [
    resUser?.data?.data,
    resUser.isSuccess,
    resClientData?.isSuccess,
    resClientData?.data?.data,
  ]);

  const formValues = {
    client_name: leadData !== undefined ? leadData.company : '',
    first_name: leadData !== undefined ? leadData.first_name : '',
    last_name: leadData !== undefined ? leadData.last_name : '',
    address: addressFormat(leadClientData[0]?.client.address, countries) || [],
    website: leadData !== undefined ? leadData.website : '',
    email: leadData !== undefined ? leadData.primary_email : '',
    personal_phone: leadData !== undefined ? leadData.personal_phone : '',
    work_phone: leadData !== undefined ? leadData.work_phone : '',
    extension: leadData !== undefined ? leadData.extension : '',
    linkedIn_url: leadData !== undefined ? leadData.linked_in_url : '',
    title: leadData !== undefined ? leadData.title : '',
    owner_id: leadData !== undefined ? leadData.owner_id : 0,
    isMultipleAccountManager: 'false',
    manager: managers,
    notes: '',
    lead_source: leadSourceFormat(leadData?.lead_source),
    specification_id:
      leadData !== undefined ? leadData.spec_id : (undefined as any),
  };
  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      client_name: Yup.string().required('Client name is required.'),
      first_name: Yup.string()
        .required('First name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
      last_name: Yup.string()
        .required('Last name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
      address: Yup.array()
        .of(
          Yup.object().shape({
            location: Yup.string().required('Location is required.'),
            address: Yup.string(),
            city: Yup.string().required('City is required.'),
            state: Yup.string().required('State is required.'),
            country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
            zip_code: Yup.string(),
            primary: Yup.boolean(),
          })
        )
        .min(1, 'Address is required.'),
      website: Yup.string().required('Website is required.'),
      email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      personal_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Personal phone is invalid'
      ),
      work_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Work phone is invalid'
      ),
      extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
      linkedIn_url: Yup.string(),
      title: Yup.string().required('Title is required.'),
      owner_id: Yup.string().required('Lead owner is required.'),
      isMultipleAccountManager: Yup.string(),
      manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
      notes: Yup.string(),
      // lead_source: Yup.array().min(1, 'Pick at least 1 lead').nullable(),
      lead_source: Yup.object().shape({
        label: Yup.string().required('Pick at least 1 source'),
        value: Yup.number(),
      }),
      status: Yup.number(),
      specification_id: Yup.number()
        .required('Industry is required.')
        .min(1, 'Invalid level.'),
    })
  );

  const onSubmit = async (data: any) => {
    data['attachments'] = withPrefix
      ? await changeUploadName(uploaded, prefix)
      : uploaded;
    if (data.manager[0] === undefined) {
      let manager: any = [];
      manager.push(data.manager);
      data['manager'] = manager;
    }
    data.address?.sort((a: any, b: any) =>
      a.primary && !b.primary ? -1 : !a.primary && b.primary ? 1 : 0
    );
    reqClient({ data, id: leadData.id });
  };

  const handleAddressSelection = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(
      'address',
      values.address.map((address: any, idx: any) => ({
        ...address,
        primary: idx === index,
      }))
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => onClose()}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Request to Convert
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({
                values,
                setFieldValue,
                handleChange,
                handleSubmit,
                errors,
                touched,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.client_name && touched.client_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Client Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="client_name"
                          name="client_name"
                          type="text"
                          placeholder="Client Name"
                          variant="outline"
                          value={values.client_name}
                        />
                        <FormErrorMessage>
                          {String(errors.client_name)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(!!errors.website && touched.website)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Website{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Field
                          as={Input}
                          id="website"
                          name="website"
                          type="text"
                          placeholder="Website"
                          variant="outline"
                          value={values.website}
                        />
                        <FormErrorMessage>
                          {String(errors.website)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.specification_id && touched.specification_id
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Industry{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>

                        <Field
                          name="specification_id"
                          options={jobSpecsOption}
                          component={AtsSelect}
                          placeholder="Select"
                          value={jobSpecsDefOption}
                          onChange={(e: any) => {
                            setJobSpecsDefOption(e);
                            setFieldValue('specification_id', String(e.value));
                          }}
                        />
                        {/* <AtsSelect
                          id="specification_id"
                          name="specification_id"
                          placeholder="Select"
                          variant="outline"
                          onChange={(e: any) =>
                            setFieldValue('specification_id', String(e.value))
                          }
                          value={ 
                            (job_specs?.length > 0 &&
                              job_specs
                                .map((item: any) => ({
                                  label: item.specification,
                                  value: item.id,
                                }))
                                .filter(
                                  (val: any) =>
                                    val.value === values.specification_id
                                )[0]) ||
                            values.specification_id
                          }
                          options={
                            job_specs?.length > 0 &&
                            job_specs.map((item: any) => ({
                              label: item.specification,
                              value: item.id,
                            }))
                          }
                        /> */}
                        <FormErrorMessage>
                          {String(errors.specification_id)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Box mb="34px">
                      <Text fontWeight={600} mb="34px">
                        Address Information{' '}
                        <Box as="span" color="caution.800">
                          *
                        </Box>
                      </Text>
                      <FormControl
                        isInvalid={Boolean(!!errors.address && touched.address)}
                      >
                        <FieldArray name="address">
                          {({ remove, push }) => (
                            <div>
                              {values.address &&
                                values.address.length > 0 &&
                                values.address.map(
                                  (job: any, index: number) => (
                                    <Accordion
                                      defaultIndex={[index]}
                                      allowMultiple
                                      key={`jobAdd-${index}`}
                                    >
                                      <AccordionItem>
                                        <Flex position="relative">
                                          <AccordionButton>
                                            <Box
                                              as="span"
                                              flex="1"
                                              textAlign="left"
                                            >
                                              <Box
                                                as="span"
                                                fontSize="14px"
                                                lineHeight="18px"
                                                fontWeight={600}
                                                color="default.primarytext"
                                                mb="8px"
                                              >
                                                {job.location
                                                  ? `${job.location}${
                                                      job.primary
                                                        ? ` (Primary Address)`
                                                        : ''
                                                    }`
                                                  : `Address ${index + 1}`}
                                              </Box>
                                            </Box>

                                            <Box>
                                              <AccordionIcon color="#6930CA" />
                                            </Box>
                                          </AccordionButton>
                                          <Flex
                                            gap="15px"
                                            alignItems="center"
                                            position="absolute"
                                            right="55px"
                                            top="0"
                                            bottom="0"
                                          >
                                            <Field
                                              as={Switch}
                                              colorScheme="purple"
                                              name={`address.${index}.primary`}
                                              isChecked={job.primary}
                                              onChange={() =>
                                                handleAddressSelection(
                                                  index,
                                                  setFieldValue,
                                                  values
                                                )
                                              }
                                            />
                                            <Box>
                                              <Button
                                                leftIcon="close"
                                                onClick={() => remove(index)}
                                                size="sm"
                                              >
                                                Remove
                                              </Button>
                                            </Box>
                                          </Flex>
                                        </Flex>
                                        <AccordionPanel pb={4}>
                                          <AddressComponent
                                            setFieldValue={setFieldValue}
                                            index={index}
                                            handleChange={handleChange}
                                            countryOption={countryOption}
                                            countryField={{
                                              name: `address.${index}.country`,
                                              value: job.country,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.country,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.country,
                                            }}
                                            locationField={{
                                              name: `address.${index}.location`,
                                              value: job.location,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.location,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.location,
                                            }}
                                            addressField={{
                                              name: `address.${index}.address`,
                                              value: job.address,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.address,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.address,
                                            }}
                                            cityField={{
                                              name: `address.${index}.city`,
                                              value: job.city,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.city,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.city,
                                            }}
                                            stateField={{
                                              name: `address.${index}.state`,
                                              value: job.state,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.state,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.state,
                                            }}
                                            zipField={{
                                              name: `address.${index}.zip_code`,
                                              value: job.zip_code,
                                              errors: (
                                                errors.address as any[]
                                              )?.[index]?.zip_code,
                                              touched: (
                                                touched.address as any[]
                                              )?.[index]?.zip_code,
                                            }}
                                          />
                                          {/* <div
                                            className="row"
                                            key={`jobAdd-${index}`}
                                          >
                                            <FormControl
                                              mb="34px"
                                              isInvalid={Boolean(
                                                (errors.address as any[])?.[
                                                  index
                                                ]?.location &&
                                                (touched.address as any[])?.[
                                                  index
                                                ]?.location
                                              )}
                                            >
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                              >
                                                Location Name{' '}
                                                <Box
                                                  as="span"
                                                  color="caution.800"
                                                >
                                                  *
                                                </Box>
                                              </FormLabel>
                                              <Field
                                                as={Input}
                                                name={`address.${index}.location`}
                                                placeholder="Location Name"
                                                type="text"
                                                variant="outline"
                                                value={job.location}
                                                onChange={handleChange}
                                              />
                                              <FormErrorMessage>
                                                {String(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.location
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>
                                            <FormControl
                                              mb="34px"
                                              isInvalid={Boolean(
                                                (errors.address as any[])?.[
                                                  index
                                                ]?.address &&
                                                (touched.address as any[])?.[
                                                  index
                                                ]?.address
                                              )}
                                            >
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                              >
                                                Address
                                              </FormLabel>
                                              <Field
                                                as={Input}
                                                name={`address.${index}.address`}
                                                placeholder="Address"
                                                type="text"
                                                variant="outline"
                                                value={job.address}
                                                onChange={handleChange}
                                              />
                                              <FormErrorMessage>
                                                {String(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.address
                                                )}
                                              </FormErrorMessage>
                                            </FormControl>

                                            <Flex gap="32px" mb="34px">
                                              <FormControl
                                                isInvalid={Boolean(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.city &&
                                                  (
                                                    touched.address as any[]
                                                  )?.[index]?.city
                                                )}
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  City{' '}
                                                  <Box
                                                    as="span"
                                                    color="caution.800"
                                                  >
                                                    *
                                                  </Box>
                                                </FormLabel>
                                                <Field
                                                  as={Input}
                                                  name={`address.${index}.city`}
                                                  placeholder="City"
                                                  type="text"
                                                  variant="outline"
                                                  value={job.city}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    (errors.address as any[])?.[
                                                      index
                                                    ]?.city
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                              <FormControl
                                                isInvalid={Boolean(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.state &&
                                                  (
                                                    touched.address as any[]
                                                  )?.[index]?.state
                                                )}
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  State / Province{' '}
                                                  <Box
                                                    as="span"
                                                    color="caution.800"
                                                  >
                                                    *
                                                  </Box>
                                                </FormLabel>
                                                <Field
                                                  as={Input}
                                                  name={`address.${index}.state`}
                                                  placeholder="State / Province"
                                                  type="text"
                                                  variant="outline"
                                                  value={job.state}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    (errors.address as any[])?.[
                                                      index
                                                    ]?.state
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                            </Flex>
                                            <Flex gap="32px" mb="34px">
                                              <FormControl
                                                isInvalid={Boolean(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.country &&
                                                  (
                                                    touched.address as any[]
                                                  )?.[index]?.country
                                                )}
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  Country{' '}
                                                  <Box
                                                    as="span"
                                                    color="caution.800"
                                                  >
                                                    *
                                                  </Box>
                                                </FormLabel> */}
                                          {/* <Field
                                                  as={Input}
                                                  name={`address.${index}.country`}
                                                  placeholder="Country"
                                                  type="text"
                                                  variant="outline"
                                                  value={job.country}
                                                  onChange={handleChange}
                                                /> */}
                                          {/* <Field
                                                  name={`address.${index}.country`}
                                                  options={countryOption}
                                                  component={AtsSelect}
                                                  defaultValue={job.country}
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      `address.${index}.country`,
                                                      e
                                                    );
                                                  }}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    (errors.address as any[])?.[
                                                      index
                                                    ]?.country
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                              <FormControl
                                                isInvalid={Boolean(
                                                  (errors.address as any[])?.[
                                                    index
                                                  ]?.zip_code &&
                                                  (
                                                    touched.address as any[]
                                                  )?.[index]?.zip_code
                                                )}
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                >
                                                  Zip Code
                                                </FormLabel>
                                                <Field
                                                  as={Input}
                                                  name={`address.${index}.zip_code`}
                                                  placeholder="Zip Code"
                                                  type="text"
                                                  variant="outline"
                                                  value={job.zip_code}
                                                  onChange={handleChange}
                                                />
                                                <FormErrorMessage>
                                                  {String(
                                                    (errors.address as any[])?.[
                                                      index
                                                    ]?.zip_code
                                                  )}
                                                </FormErrorMessage>
                                              </FormControl>
                                            </Flex>
                                          </div> */}
                                        </AccordionPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  )
                                )}
                              {!leadData.address && (
                                <Box mt="50px">
                                  <Button
                                    leftIcon="plus"
                                    onClick={() => {
                                      push({
                                        location: '',
                                        address: '',
                                        city: '',
                                        state: '',
                                        country: '',
                                        zip_code: '',
                                        primary:
                                          leadData.address?.length === 0 ||
                                          values.address?.length === 0
                                            ? true
                                            : false,
                                      });
                                    }}
                                  >
                                    {values.address && values.address.length > 0
                                      ? 'Add Another Address'
                                      : 'Add Address'}
                                  </Button>
                                </Box>
                              )}
                            </div>
                          )}
                        </FieldArray>
                        {values.address.length === 0 && (
                          <FormErrorMessage mt="20px">
                            {String(errors.address)}
                          </FormErrorMessage>
                        )}
                      </FormControl>
                    </Box>

                    <Box>
                      <Text fontWeight={600} mb="34px">
                        Contact
                      </Text>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.first_name && touched.first_name
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Contact First Name{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            as={Input}
                            id="first_name"
                            name="first_name"
                            type="text"
                            placeholder="First Name"
                            variant="outline"
                            value={values.first_name}
                          />
                          <FormErrorMessage>
                            {String(errors.first_name)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.last_name && touched.last_name
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Contact Last Name{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            as={Input}
                            id="last_name"
                            name="last_name"
                            type="text"
                            placeholder="Contact Last Name"
                            variant="outline"
                            value={values.last_name}
                          />
                          <FormErrorMessage>
                            {String(errors.last_name)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.personal_phone && touched.personal_phone
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Personal Phone
                          </FormLabel>
                          <Field
                            as={Input}
                            id="personal_phone"
                            name="personal_phone"
                            type="text"
                            placeholder="Phone Number"
                            variant="outline"
                            value={values.personal_phone}
                          />
                          <FormErrorMessage>
                            {String(errors.personal_phone)}
                          </FormErrorMessage>
                        </FormControl>
                        <Flex width="100%" gap="32px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.work_phone && touched.work_phone
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Work Phone
                            </FormLabel>
                            <Input
                              id="work_phone"
                              name="work_phone"
                              type="text"
                              placeholder="Work Phone"
                              variant="outline"
                              value={values.work_phone}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.work_phone)}
                            </FormErrorMessage>
                          </FormControl>
                          <Flex width="30%">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.work_phone && touched.work_phone
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Extension
                              </FormLabel>
                              <Input
                                id="extension"
                                name="extension"
                                type="number"
                                placeholder="Ext"
                                variant="outline"
                                value={values.extension}
                                onChange={(e) => {
                                  setFieldValue(
                                    'extension',
                                    String(e.target.value)
                                  );
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.extension)}
                              </FormErrorMessage>
                            </FormControl>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(!!errors.email && touched.email)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Email{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            as={Input}
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            variant="outline"
                            value={values.email}
                          />
                          <FormErrorMessage>
                            {String(errors.email)}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.linkedIn_url && touched.linkedIn_url
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            LinkedIn Link
                          </FormLabel>
                          <Field
                            as={Input}
                            id="linkedIn_url"
                            name="linkedIn_url"
                            type="text"
                            placeholder="LinkedIn Link"
                            variant="outline"
                            value={values.linkedIn_url}
                          />
                          <FormErrorMessage>
                            {String(errors.linkedIn_url)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(!!errors.title && touched.title)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Title{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            as={Input}
                            id="title"
                            name="title"
                            type="text"
                            placeholder="Title"
                            variant="outline"
                            value={values.title}
                          />
                          <FormErrorMessage>
                            {String(errors.title)}
                          </FormErrorMessage>
                        </FormControl>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.owner_id && touched.owner_id
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Contact Owner{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          {/* <Field
                            as={BasicSelect}
                            id="owner_id"
                            name="owner_id"
                            placeholder="Select"
                            variant="outline"
                            value={values.owner_id}
                          >
                            {Object.keys(userList).length > 0 ? (
                              Object.values(userList).map((data: any) => {
                                return (
                                  <option value={data.id}>
                                    {[data.first_name, data.last_name].join(
                                      ' '
                                    )}
                                  </option>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Field> */}
                          <AtsSelect
                            id="owner_id"
                            name="owner_id"
                            placeholder="Select"
                            variant="outline"
                            value={
                              (Object.values(userList).length > 0 &&
                                Object.values(userList)
                                  .map((item: any) => ({
                                    label: [
                                      item.first_name,
                                      item.last_name,
                                    ].join(' '),
                                    value: item.id,
                                  }))
                                  .filter(
                                    (val: any) =>
                                      val.value === Number(values.owner_id)
                                  )[0]) ||
                              values.owner_id
                            }
                            onChange={(e: any) =>
                              setFieldValue('owner_id', String(e.value))
                            }
                            options={Object.values(userList).map(
                              (item: any) => ({
                                label: [item.first_name, item.last_name].join(
                                  ' '
                                ),
                                value: item.id,
                              })
                            )}
                          />
                          <FormErrorMessage>
                            {String(errors.owner_id)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.lead_source && touched.lead_source
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Source{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          {/* <Field
                            as={Input}
                            id="lead_source"
                            name="lead_source"
                            type="text"
                            placeholder="Contact Last Name"
                            variant="outline"
                            value={values.lead_source}
                          /> */}
                          <Field
                            id="lead_source"
                            name="lead_source"
                            options={leadSrouceList}
                            component={AtsSelect}
                            value={values.lead_source}
                            onChange={(e: any) => {
                              setFieldValue(`lead_source`, e);
                            }}
                          />
                          <FormErrorMessage>
                            {errors.lead_source &&
                              touched.lead_source &&
                              String((errors.lead_source as any).label)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <MenuDivider />

                      <Box my="34px">
                        <FormControl>
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Multiple account managers?
                          </FormLabel>
                        </FormControl>

                        <Flex mb="20px">
                          <FormLabel
                            display="flex"
                            gap="10px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            <Field
                              id="isMultipleAccountManager"
                              name="isMultipleAccountManager"
                              type="radio"
                              variant="outline"
                              value={'true'}
                            />
                            Yes
                          </FormLabel>
                          <FormLabel
                            display="flex"
                            gap="10px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            <Field
                              id="isMultipleAccountManager"
                              name="isMultipleAccountManager"
                              type="radio"
                              variant="outline"
                              value={'false'}
                            />
                            No
                          </FormLabel>
                        </Flex>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.manager && touched.manager
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Account Manager{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            name="manager"
                            isMulti={
                              values.isMultipleAccountManager === 'true'
                                ? true
                                : false
                            }
                            options={selectOption}
                            component={AtsSelect2}
                            value={values.manager}
                            variant="outline"
                          />

                          <FormErrorMessage>
                            {String(errors.manager)}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box mb="34px">
                      <FormControl mb="3">
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Attachments
                        </FormLabel>
                      </FormControl>
                      <AtsDropZone
                        multipleFile={true}
                        uploadedEnd={(e: any) => {}}
                        deletedFile={(key: string) => {}}
                      />
                    </Box>
                    <Box>
                      <FormLabel
                        mb="8px"
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Notes
                      </FormLabel>
                      <Field
                        as={Textarea}
                        id="notes"
                        name="notes"
                        type="text"
                        placeholder="Here is a sample placeholder"
                        variant="outline"
                        value={values.notes}
                      />
                      <Text
                        mt="8px"
                        fontSize="12px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Maximum 200 characters allowed
                      </Text>
                    </Box>
                  </Box>
                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      disabled={isOpenAlert || uploading}
                      type="submit"
                      onClick={() => {
                        console.log(errors);
                        handleSubmit();
                      }}
                    >
                      Convert
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  FormErrorMessage,
  Flex,
} from '@chakra-ui/react';
import * as Yup from 'yup';

import ScrollToFieldError from 'components/app/ScrollError';
import {
  useCreateUserMutation,
  useUserListPaginateMutation,
} from 'store/user.slice';
import {
  useCreateGroupMutation,
  useListGroupMutation,
} from 'store/email_group.slice';

interface TemplateAddGroup {
  isOpen: any;
  onClose: any;
}

export default function TemplateAddGroup({
  isOpen,
  onClose,
}: TemplateAddGroup) {
  const [reqGroup] = useListGroupMutation();

  const { groupPageBtn } = useSelector((state: any) => state.email_group);
  const [reqCreate, resCreate] = useCreateGroupMutation();

  useEffect(() => {
    if (resCreate.isSuccess) {
      onClose();
      reqGroup(groupPageBtn);
    }
  }, [resCreate.isSuccess]);

  const submitAgreement = async (data: any) => {
    reqCreate(data);
  };

  const initialValues = {
    name: '',
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      name: Yup.string().required('Group name is required.'),
    })
  );

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="md"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Add New Group
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              onSubmit={submitAgreement}
              validationSchema={validationSchema}
            >
              {({ values, handleSubmit, handleChange, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(!!errors.name && touched.name)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Group Name
                          </FormLabel>
                          <Input
                            name="name"
                            type="text"
                            placeholder="Group Name"
                            variant="outline"
                            value={values.name}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.name)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      type="submit"
                      isLoading={resCreate.isLoading}
                    >
                      Add
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

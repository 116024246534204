import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { Formik, FieldArray, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
  Textarea,
} from '@chakra-ui/react';

import { CheckIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import {
  getJob,
  useCreateJobsMutation,
  useCreateQuickMutation,
  useGetDefaultPitchMutation,
  useGetJobsMutation,
  useListClientJobsMutation,
  useListJobsMutation,
  useUpdateJobsMutation,
  useUpdateQuickMutation,
} from 'store/jobs.slice';
import Button from 'Library/Button';

import {
  listClients,
  setClientId,
  useClientLocationMutation,
  useRawListClientsMutation,
} from 'store/client.slice';
import AtsSelect2 from 'components/app/AtsSelect2';
import {
  useGetCompensationsMutation,
  useGetCountriesMutation,
  useGetEducationsMutation,
  useGetEmpTypeMutation,
  useGetExperienceLevelMutation,
  // useGetJobSpecsMutation,
  useGetWorkTypesMutation,
} from 'store/constant.slice';
import { BsCurrencyDollar } from 'react-icons/bs';
import { useUserListMutation } from 'store/user.slice';
import { authStorage } from 'utils/localStorage';
import AtsSelect from 'components/app/AtsSelect';
import { useNavigate, useParams } from 'react-router';
import AtsSelectAddress from 'components/app/AtsSelectAddress';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import LoadingEdit from './loadingEdit';
import AtsEmailBody from 'components/app/EmailBody';
import AddressComponent from '../components/AddressComponent';
import { number } from 'yup';
import { useSearchGeoTypesMutation } from 'store/geo.slice';
import { debounce } from 'lodash';
import AtsAutoComplete from 'components/app/Leads/components/Autocomplete';
import replacePlaceholders from 'utils/textPlaceholders';
import {
  usePlaceHolderPairMutation,
  usePlaceHoldersMutation,
} from 'store/template.slice';
import { CAREER_PAGE_URL } from 'constants/values';
import ScrollToFieldError from 'components/app/ScrollError';

interface JobPairingIntParam {
  job_title: string;
  candidate_first_name: string;
  job_url: string;
  job_name_url: string;
}

interface JobsModalCreateProps {
  isOpen: any;
  onClose: any;
  modalTitle: string;
  duplicate?: boolean;
  isDrawer?: boolean;
  companyDef?: any;
  contactDef?: any;
  searchableLeads?: any;
  searchableClient?: any;
  contactOptionValue?: any;
  jobId?: number;
}

const JobsModal = ({
  isOpen,
  onClose,
  modalTitle,
  duplicate = false,
  isDrawer,
  companyDef = [],
  contactDef = [],
  searchableLeads = null,
  searchableClient = null,
  contactOptionValue = [],
  jobId = null,
}: JobsModalCreateProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const toast = useToast();

  const { jobData, jobPgBtn, defaultPitch } = useSelector(
    (state: any) => state.jobs
  );

  const { userList } = useSelector((state: any) => state.user);

  const [reqUsers, resUsers] = useUserListMutation();
  const [recOptions, setRecruiters] = useState([]);
  const [reqGetJob, resGetJob] = useGetJobsMutation();

  const [jobPairTemplate, setJobPairTemplate] = useState(
    {} as JobPairingIntParam
  );

  const {
    RawList,
    client_location,
    selected_client,
    selected_client_location,
  } = useSelector((state: any) => state.clients);

  const {
    work_types,
    educations,
    compensations,
    employment_types,
    // job_specs,
    experience_level,
    countries,
  } = useSelector((state: any) => state.constants);

  const isEditModal = modalTitle === 'Edit Job';

  const [reqJobs, resBobs] = useListJobsMutation();
  const [reqClientJobs] = useListClientJobsMutation();
  const [reqCreateJob, resCreateJob] = useCreateJobsMutation();
  const [reqUpdateJob, resUpdateJob] = useUpdateJobsMutation();
  const [reqCreateQuick, resCreateQuick] = useCreateQuickMutation();
  const [reqUpdateQuick, resUpdateQuick] = useUpdateQuickMutation();
  const [reqcompany, resCompany] = useRawListClientsMutation();
  const [CompanyOption, setCompanyOption] = useState([]);
  const [contactOption, setContactOption] = useState(contactOptionValue);
  const [locationOption, setLocationOption] = useState([]);
  const [aMOption, setAmOption] = useState([]);
  const [reqWorkTypes] = useGetWorkTypesMutation();
  const [reqEducTypes] = useGetEducationsMutation();
  const [reqCompTypes] = useGetCompensationsMutation();
  const [reqEmpTypes] = useGetEmpTypeMutation();
  const [reqLocation] = useClientLocationMutation();
  const [reqCountry] = useGetCountriesMutation();
  const [reqExpLevel] = useGetExperienceLevelMutation();

  useEffect(() => {
    if (jobId) {
      const load = async () => {
        await reqGetJob({ id: jobId });
      };
      load();
    }
  }, [jobId]);

  const auth = authStorage();

  // const [hasSkills, setHasSkills] = useState('');
  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const hasJobFormData: string | null = localStorage.getItem('jobFormData');
  const initJobFormData =
    hasJobFormData == null ? {} : JSON.parse(hasJobFormData);

  const [jobFormData, setJobFormData] = useState<any>(initJobFormData);
  const [contactOptionsEmail, setContactOptionsEmail] = useState([]);
  const [ccOption, setCcOption] = useState([]);
  const [bccOption, setBccOption] = useState([]);

  // Customize Pitch Tab Variables

  // const { placehoderPair, placehodlers } = useSelector((state: any) => state.template);
  // const [placehoder, setPlaceHoders] = useState(placehoderPair);

  const placeholderOptions = [
    'job_title',
    'candidate_first_name',
    'job_url',
    'job_name_url',
  ];

  useEffect(() => {
    if (isOpen) {
      const candidate_first_name = 'Harvey';
      const init_url = 'https://careers.talently.com/job-details/';
      let job_url = `<a href="${init_url}" style="color: blue; text-decoration: underline" target="_blank">${init_url}</a>`;
      let job_name_url = '';
      let job_title = '';
      if (jobId) {
        job_title = jobData?.title || '';
        if (jobData?.jobAds?.length > 0) {
          const ads = jobData?.jobAds[0];

          if (ads) {
            const url = `${CAREER_PAGE_URL}/job-details/${ads?.id}`;
            job_url = `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${url}</a>`;
            job_name_url = `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${ads.title}</a>`;
          }
        }
      }

      const jobParam = {
        job_title,
        candidate_first_name,
        job_url,
        job_name_url,
      } as JobPairingIntParam;

      jobPairing(jobParam);
    }
  }, [isOpen]);

  const jobPairing = async (param: JobPairingIntParam) => {
    const newpair = {
      job_title: param.job_title,
      candidate_first_name: param.candidate_first_name,
      job_url: param.job_url,
      job_name_url: param.job_name_url,
    };

    setJobPairTemplate(newpair);
  };
  // add here slice for get default pitch email body

  const placholders = (text: string) => {
    return replacePlaceholders(text, jobPairTemplate);
  };

  const placeHolderClick = (holder: string) => {
    navigator.clipboard.writeText(`{{${holder}}}`);
    toast({
      title: 'Placeholder copied',
      duration: 1000,
      position: 'top',
    });
  };

  // useEffect(() => {
  //   reqPlaceHolder({});
  //   if (jobId) {
  //     const data = {
  //       candidate_id: null,
  //       job_id: jobId || null,
  //     } as any;
  //     reqPair(data)
  //   }
  // }, [])

  // useEffect(() => {
  //   setPlaceHoders(placehoderPair);
  // }, [placehoderPair]);

  // const jobSkills =
  //   (isEditModal || duplicate) &&
  //   jobData &&
  //   jobData.skills?.map((skill: { skill: string }) => skill.skill);

  // const jobQuestions = // TODO: hide for now
  //   (isEditModal || duplicate) &&
  //   jobData &&
  //   jobData.questions?.map((question: any) => {
  //     return {
  //       question: question.question,
  //       order: question.order,
  //     };
  //   });
  const jobAds =
    (isEditModal || duplicate) && jobData && jobData?.jobAds?.length > 0
      ? jobData.jobAds?.map((ad: any) => {
          return {
            title: ad.title,
            state: ad.state,
            city: ad.city,
            country: (countries?.length > 0 &&
              countries
                .map((item: any) => ({
                  label: item.country,
                  value: item.id,
                  code: item.code,
                }))
                .filter((val: any) => val.label === ad.country))[0],
            description: ad.description,
            career_page_publish: ad.career_page_publish,
            show_profile: ad.show_profile,
            free_job_board_publish: ad.free_job_board_publish,
            paid_job_board_publish: ad.paid_job_board_publish,
            active: ad.active,
            worktype_id: ad.worktype_id,
            // specification_id: ad.spec_id,
            id: ad.id,
          };
        })
      : [
          {
            title: '',
            state: client_location[0]?.state || '',
            country: (countries?.length > 0 &&
              countries
                .map((item: any) => ({
                  label: item.country,
                  value: item.id,
                  code: item.code,
                }))
                .filter(
                  (val: any) =>
                    val.label === client_location[0]?.country?.country
                ))[0],
            city: client_location[0]?.city || '',
            description: '',
            career_page_publish: true,
            show_profile: false,
            free_job_board_publish: true,
            paid_job_board_publish: true,
            active: 1,
            worktype_id: jobFormData?.work_type_id || null,
            id: null,
          },
        ];

  const recuiterPopulate = (recruiters: any) => {
    try {
      let data: any = [];
      recruiters.map((item: any) => {
        data.push({
          name: item.recruiter_id,
          split_percentage: item.split,
          post_new_job_ads: item.post_new_job_ads,
          no_submittal_review: item.no_submittal_review,
          review_send_as_client_owner: item.review_send_as_client_owner,
          review_send_as_client_recruiter: item.review_send_as_client_recruiter,
          interview_send_as_client_owner: item.review_send_as_client_owner,
          interview_send_as_recruiter: item.interview_send_as_recruiter,
        });
      });
      return data;
    } catch (e) {
      return [];
    }
  };

  useEffect(() => {
    if (companyDef.value) {
      reqLocation({ id: companyDef.value });
      setCompanyOption(contactOptionValue);
    }
  }, [companyDef]);

  useEffect(() => {
    if (jobData?.client_id !== undefined) {
      reqLocation({ id: jobData.client_id });
      const company = RawList.filter((obj: any) => {
        return obj.id === jobData.client_id;
      });
      let options: any = [];
      let optionsEmails: any = [];
      if (company[0] !== undefined) {
        if (company[0].leads?.length > 0) {
          let emails = [] as any;
          // await Promise.all(
          company[0].leads.map(async (item: any) => {
            options.push({
              label: [item.first_name, item.last_name]
                .filter(Boolean)
                .join(' '),
              value: item.id,
            });
            optionsEmails.push({
              label: [item.first_name, item.last_name]
                .filter(Boolean)
                .join(' '),
              value: item.primary_email,
            });
          });
        }
      }

      // optionsEmails = [...optionsEmails, ...populateUSers()];

      setContactOption(options);
      setCcOption(optionsEmails);
      setBccOption(optionsEmails);
      setContactOptionsEmail(optionsEmails);
    }
  }, [jobData]);

  const ccBc = (email: any) => {
    let options: any = [];
    try {
      // console.log('email', email);
      email.filter(Boolean).map((item: any) => {
        options.push({
          label: item,
          value: item,
        });
      });
    } catch (e) {
      console.warn('e', e);
    }

    return options;
  };
  const [dataCc, setDataCc] = useState([]);
  const [dataBcc, setDataBcc] = useState([]);
  useEffect(() => {
    if (jobData?.bcc?.cc) {
      setDataCc(ccBc(jobData.bcc.cc));
    }
    if (jobData?.bcc?.bcc) {
      setDataBcc(ccBc(jobData.bcc.bcc));
    }
  }, [jobData]);

  const setBcc = (jobData: any) => {
    if (jobData?.bcc?.bcc) {
      return ccBc(jobData?.bcc?.bcc);
    }
    return [];
  };

  const setCc = (jobData: any) => {
    if (jobData?.bcc?.cc) {
      return ccBc(jobData?.bcc?.cc);
    }
    return [];
  };

  const initialValues: any =
    (isEditModal || duplicate) &&
    ((jobData && Object.keys(jobData).length !== 0) ||
      (jobFormData && Object.keys(jobFormData).length !== 0))
      ? {
          // client
          job_type_id: Number(jobFormData.job_type_id) || jobData.job_type_id,
          company: jobFormData.company || {
            label: jobData.client?.name,
            value: jobData.client_id,
          },
          contact: jobFormData.contact || {
            label: jobData.lead?.first_name + ' ' + jobData.lead?.last_name,
            value: jobData.lead_id,
          },
          cc: jobFormData.cc || setCc(jobData),
          bcc: jobFormData.bcc || setBcc(jobData),
          location: jobFormData.location || {
            label: [
              jobData.location?.address,
              jobData.location?.city,
              jobData.location?.state,
              jobData.location?.zip_code,
              jobData.location?.country?.country,
            ]
              .filter((addressPart) => addressPart !== '')
              .join(', '),
            value: jobData.location_id,
          },
          // job details
          // created_by: jobData.user_id,
          // specification_id: jobData.spec_id,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id:
            jobFormData.am_id || jobData.am_id || aMOption?.length > 0
              ? aMOption[0]?.value
              : ('' as any),
          title: jobFormData.title || jobData.title,
          opening:
            jobData.opening === -1 || jobFormData.opening === -1
              ? 1
              : jobFormData.opening || jobData.opening,
          experience_level:
            jobData.experience_level === -1 ||
            jobFormData.experience_level === -1
              ? -1
              : jobFormData.experience_level || jobData.experience_level?.id,
          //
          education_id: jobFormData.education_id || jobData.education_id || -1,
          work_type_id: jobFormData.work_type_id || jobData.work_type_id,
          client_job_link:
            jobFormData.client_job_link ||
            (jobData.client_job_link &&
              jobFormData.client_job_link?.replace(/(^\w+:|^)\/\//, '')) ||
            jobData.client_job_link?.replace(/(^\w+:|^)\/\//, ''),
          description: jobFormData.description || jobData.description || '',

          // skills: jobSkills,
          notes: jobFormData?.notes || '',
          //
          employment_type_id:
            jobFormData.employment_type_id || jobData.employment_type_id || 1,
          compensation_id:
            jobFormData.compensation_id || jobData.compensation_id || 2,
          salary_range_start:
            jobData.salary_range_start === -1 ||
            jobFormData.salary_range_start === -1
              ? 0
              : jobFormData.salary_range_start ||
                jobData.salary_range_start?.toLocaleString(),
          salary_range_end:
            jobData.salary_range_end === -1 ||
            jobFormData.salary_range_end === -1
              ? 0
              : jobFormData.salary_range_end ||
                jobData.salary_range_end?.toLocaleString(),

          // ques
          // questions: jobFormData.questions || jobQuestions, // TODO: hide for now
          // rec
          recruiters:
            jobFormData.recruiters ||
            recuiterPopulate(jobData.recruiters) ||
            [],
          // job ads
          job_ads: jobFormData.job_ads || jobAds,

          // customize pitch
          email_subject:
            jobFormData.email_subject ||
            jobData?.pitch?.subject ||
            String(defaultPitch?.subject) ||
            '',
          email_content:
            jobFormData.email_content ||
            jobData?.pitch?.content ||
            String(defaultPitch?.content) ||
            '',

          // not include
          status: jobData.status,
          lead_id: jobData.lead_id,
          job_status_id: jobFormData.job_status_id || jobData.job_status_id,
        }
      : jobFormData && Object.keys(jobFormData).length !== 0
      ? {
          // client
          job_type_id: Number(jobFormData.job_type_id),
          company: jobFormData.company,
          contact: jobFormData.contact,
          location: jobFormData.location,
          cc: jobFormData.cc ? jobFormData.cc : ([] as any),
          bcc: jobFormData.bcc ? jobFormData.bcc : ([] as any),
          // specification_id: jobFormData.specification_id,
          // job details
          // created_by: jobFormData.created_by,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id: jobFormData.am_id,
          title: jobFormData.title,
          opening: jobFormData.opening,
          experience_level: jobFormData.experience_level,
          //
          education_id: jobFormData.education_id,
          work_type_id: jobFormData.work_type_id,
          client_job_link: jobFormData.client_job_link,
          description: jobFormData.description,

          // skills: jobFormData.skills,
          notes: jobFormData?.notes || '',
          //
          employment_type_id: jobFormData.employment_type_id,
          compensation_id: jobFormData.compensation_id,
          salary_range_start: jobFormData.salary_range_start,
          salary_range_end: jobFormData.salary_range_end,

          // ques
          // questions: jobFormData.questions || [],
          // rec
          recruiters: jobFormData.recruiters || [],
          // job ads
          job_ads: jobFormData.job_ads || [
            {
              title: '',
              state: client_location[0]?.state || '',
              country: (countries?.length > 0 &&
                countries
                  .map((item: any) => ({
                    label: item.country,
                    value: item.id,
                    code: item.code,
                  }))
                  .filter(
                    (val: any) =>
                      val.label === client_location[0]?.country?.country
                  ))[0],
              city: client_location[0]?.city || '',
              description: '',
              career_page_publish: true,
              show_profile: false,
              free_job_board_publish: true,
              paid_job_board_publish: true,
              active: 1,
              worktype_id: jobFormData?.work_type_id || null,
              id: null,
            },
          ],
          // customize pitch
          email_subject:
            jobFormData.email_subject || String(defaultPitch?.subject) || '',
          email_content:
            jobFormData.email_content || String(defaultPitch?.content) || '',

          // not include
          status: 1,
          lead_id: 1,
          job_status_id: jobFormData.job_status_id || 1,
        }
      : {
          // client
          job_type_id: 1,
          company: selected_client.company || companyDef,
          contact: selected_client.contact || contactDef,
          location: selected_client_location,
          cc: [] as any,
          bcc: [] as any,
          // specification_id: undefined as any,
          // job details
          // created_by: auth?.id,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData?.primary?.recruiter_id ||
            auth?.id,
          am_id: aMOption?.length > 0 ? aMOption[0]?.value : ('' as any),
          title: '',
          opening: 1,
          experience_level: undefined as any,
          //
          education_id: undefined as any,
          work_type_id: undefined as any,
          client_job_link: '',
          description: '',

          // skills: [] as any,
          notes: jobFormData?.notes || '',
          //
          employment_type_id: 1,
          compensation_id: 2,
          salary_range_start: 0,
          salary_range_end: 0,

          // ques
          // questions: [] as any, // TODO: hide for now
          // rec
          recruiters: [] as any,
          // job ads
          job_ads: [
            {
              title: '',
              state: client_location[0]?.state || '',
              country: (countries?.length > 0 &&
                countries
                  .map((item: any) => ({
                    label: item.country,
                    value: item.id,
                    code: item.code,
                  }))
                  .filter(
                    (val: any) =>
                      val.label === client_location[0]?.country?.country
                  ))[0],
              city: client_location[0]?.city || '',
              // specification_id:
              //   jobFormData?.specification_id ||
              //   (undefined as any),
              description: '',
              career_page_publish: true,
              show_profile: false,
              free_job_board_publish: true,
              paid_job_board_publish: true,
              active: 1,
              worktype_id: jobFormData?.work_type_id || null,
              id: null,
            },
          ] as any,

          // customize pitch
          email_subject: String(defaultPitch?.subject) || '',
          email_content: String(defaultPitch?.content) || '',
          // not include
          status: 1, // should not include in create job
          lead_id: 1, // should not include in create job
          job_status_id: 1, // should not include in create job
        };
  const initialClientValues =
    (isEditModal || duplicate) &&
    ((jobData && Object.keys(jobData).length !== 0) ||
      (jobFormData && Object.keys(jobFormData).length !== 0))
      ? {
          // client
          job_type_id: Number(jobFormData.job_type_id) || jobData.job_type_id,
          company: jobFormData.company || {
            label: jobData.client?.name,
            value: jobData.client_id,
          },
          contact: jobFormData.contact || {
            label: jobData.lead?.first_name + ' ' + jobData.lead?.last_name,
            value: jobData.lead_id,
          },
          location: jobFormData.location || {
            label: [
              jobData.location?.address,
              jobData.location?.city,
              jobData.location?.state,
              jobData.location?.zip_code,
              jobData.location?.country?.country,
            ]
              .filter((addressPart) => addressPart !== '')
              .join(', '),
            value: jobData.location_id,
          },
          cc: jobFormData.cc || setCc(jobData),
          bcc: jobFormData.bcc || setBcc(jobData),
          // bcc: [
          //   { label: 'jimarzape@gmail.com', id: 'jimarzape@gmail.com' },
          // ] as any,
          job_status_id:
            Number(jobFormData.job_status_id) || jobData.job_status_id || 1,
        }
      : jobFormData && Object.keys(jobFormData).length !== 0
      ? {
          // client
          job_type_id: Number(jobFormData.job_type_id),
          company: jobFormData.company,
          contact: jobFormData.contact,
          location: jobFormData.location,
          cc: jobFormData.cc ? jobFormData.cc : ([] as any),
          bcc: jobFormData.bcc ? jobFormData.bcc : ([] as any),
          job_status_id: Number(jobFormData.job_status_id) || 1,
        }
      : {
          // client
          job_type_id: 1,
          client_job_link: '',
          company: selected_client.company || companyDef,
          contact: selected_client.contact || contactDef,
          location: selected_client_location,
          cc: [] as any,
          bcc: [] as any,
          job_status_id: 1,
        };

  const initialJobDetailsValues =
    (isEditModal || duplicate) &&
    ((jobData && Object.keys(jobData).length !== 0) ||
      (jobFormData && Object.keys(jobFormData).length !== 0))
      ? {
          // job details
          job_type_id: jobFormData.job_type_id || jobData.job_type_id,
          // created_by: jobFormData.created_by || jobData.user_id || auth?.id,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id:
            jobFormData.am_id || jobData.am_id || aMOption?.length > 0
              ? aMOption[0]?.value
              : ('' as any),
          title: jobFormData.title || jobData.title,
          opening:
            jobFormData.opening || jobData.opening === -1
              ? jobFormData.opening || 1
              : jobData.opening,
          // specification_id: jobFormData.specification_id || jobData.spec_id,
          experience_level:
            jobFormData.experience_level || jobData.experience_level === -1
              ? jobFormData.experience_level || null
              : jobData.experience_level?.id,
          //
          education_id: jobFormData.education_id || jobData.education_id || -1,
          work_type_id: jobFormData.work_type_id || jobData.work_type_id,
          client_job_link:
            jobFormData.client_job_link ||
            jobData.client_job_link?.replace(/(^\w+:|^)\/\//, ''),
          description: jobFormData.description || jobData.description || '',

          // skills: jobFormData.skills || jobSkills,
          notes: jobFormData.notes || '',
          //
          employment_type_id:
            jobFormData.employment_type_id || jobData.employment_type_id || 1,
          compensation_id:
            jobFormData.compensation_id || jobData.compensation_id || 2,
          salary_range_start:
            jobFormData.salary_range_start || jobData.salary_range_start === -1
              ? jobFormData.salary_range_start || 0
              : jobData.salary_range_start?.toLocaleString(),
          salary_range_end:
            jobFormData.salary_range_end || jobData.salary_range_end === -1
              ? jobFormData.salary_range_end || 0
              : jobData.salary_range_end?.toLocaleString(),
          job_status_id:
            jobFormData.job_status_id || jobData.job_status_id || 1,
        }
      : jobFormData && Object.keys(jobFormData).length !== 0
      ? {
          // job details
          job_type_id: jobFormData.job_type_id,
          // created_by: jobFormData.created_by || auth?.id,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id:
            jobFormData.am_id || aMOption?.length > 0
              ? aMOption[0]?.value
              : ('' as any),
          title: jobFormData.title,
          opening: jobFormData.opening,
          // specification_id: jobFormData.specification_id,
          experience_level: jobFormData.experience_level,
          //
          education_id: jobFormData.education_id,
          work_type_id: jobFormData.work_type_id,
          client_job_link: jobFormData.client_job_link,
          description: jobFormData.description || '',

          // skills: jobFormData.skills,
          notes: jobFormData?.notes || '',
          //
          employment_type_id: jobFormData.employment_type_id,
          compensation_id: jobFormData.compensation_id,
          salary_range_start: jobFormData.salary_range_start,
          salary_range_end: jobFormData.salary_range_end,
          job_status_id: jobFormData.job_status_id || 1,
        }
      : {
          // job details
          job_type_id: jobFormData?.job_type_id || 1,
          // created_by: auth?.id,
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id: aMOption?.length > 0 ? aMOption[0]?.value : ('' as any),
          title: '',
          opening: 1,
          // specification_id: undefined as any,
          experience_level: undefined as any,
          //
          education_id: undefined as any,
          work_type_id: undefined as any,
          client_job_link: '',
          description: '',

          // skills: [] as any,
          notes: jobFormData?.notes || '',
          //
          employment_type_id: 1,
          compensation_id: 2,
          salary_range_start: 0,
          salary_range_end: 0,
          job_status_id: 1,
        };

  // const initialQuestionsValues = // TODO: hide for now
  //   (isEditModal || duplicate) &&
  //   ((jobData && Object.keys(jobData).length !== 0) ||
  //     (jobFormData && Object.keys(jobFormData).length !== 0))
  //     ? {
  //         // ques
  //         job_type_id: jobFormData.job_type_id || jobData.job_type_id || 1,
  //         questions: jobFormData.questions || jobQuestions || [],
  //         job_status_id:
  //           jobFormData.job_status_id || jobData.job_status_id || 1,
  //       }
  //     : jobFormData && Object.keys(jobFormData).length !== 0
  //     ? {
  //         // ques
  //         job_type_id: jobFormData.job_type_id || 1,
  //         questions: jobFormData.questions || [],
  //         job_status_id: jobFormData.job_status_id || 1,
  //       }
  //     : {
  //         // ques
  //         job_type_id: jobFormData?.job_type_id || 1,
  //         questions: [] as any,
  //         job_status_id: 1,
  //       };

  const initialRecruitersValues =
    (isEditModal || duplicate) &&
    ((jobData && Object.keys(jobData).length !== 0) ||
      (jobFormData && Object.keys(jobFormData).length !== 0))
      ? {
          // rec
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id: jobFormData.am_id || jobData?.am_id,
          // created_by: jobFormData.created_by || jobData.user_id || auth?.id,
          job_type_id: jobFormData.job_type_id || jobData.job_type_id || 1,
          recruiters:
            jobFormData.recruiters ||
            recuiterPopulate(jobData.recruiters) ||
            ([] as any),
          job_status_id:
            jobFormData.job_status_id || jobData.job_status_id || 1,
        }
      : jobFormData && Object.keys(jobFormData).length !== 0
      ? {
          // rec
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          // created_by: jobFormData.created_by || auth?.id,
          am_id:
            jobFormData.am_id || aMOption?.length > 0 ? aMOption[0]?.value : '',
          job_type_id: jobFormData.job_type_id || 1,
          recruiters: jobFormData.recruiters || ([] as any),
          job_status_id: jobFormData.job_status_id || 1,
        }
      : {
          // rec
          primary_recruiter:
            jobFormData.primary_recruiter ||
            jobData.primary?.recruiter_id ||
            auth?.id,
          am_id: aMOption?.length > 0 ? aMOption[0]?.value : ('' as any),
          // created_by: auth?.id,
          job_type_id: jobFormData?.job_type_id || 1,
          recruiters: [] as any,
          job_status_id: 1,
        };
  const initialJobAdsValues =
    (isEditModal || duplicate) &&
    ((jobData && Object.keys(jobData).length !== 0) ||
      (jobFormData && Object.keys(jobFormData).length !== 0))
      ? {
          job_ads: jobFormData.job_ads ||
            jobAds || [
              {
                title: '',
                state: client_location[0]?.state || '',
                country: (countries?.length > 0 &&
                  countries
                    .map((item: any) => ({
                      label: item.country,
                      value: item.id,
                      code: item.code,
                    }))
                    .filter(
                      (val: any) =>
                        val.label === client_location[0]?.country?.country
                    ))[0],
                city: client_location[0]?.city || '',
                description: '',
                career_page_publish: true,
                show_profile: false,
                free_job_board_publish: true,
                paid_job_board_publish: true,
                active: 1,
                worktype_id: jobFormData?.work_type_id || null,
                id: null,
              },
            ],
        }
      : {
          job_ads:
            jobFormData.job_ads ||
            jobAds ||
            ([
              {
                title: '',
                state: client_location[0]?.state || '',
                country: (countries?.length > 0 &&
                  countries
                    .map((item: any) => ({
                      label: item.country,
                      value: item.id,
                      code: item.code,
                    }))
                    .filter(
                      (val: any) =>
                        val.label === client_location[0]?.country?.country
                    ))[0],
                city: client_location[0]?.city || '',
                // specification_id:
                //   jobFormData?.specification_id ||
                //   (undefined as any),
                description: '',
                career_page_publish: true,
                show_profile: false,
                free_job_board_publish: true,
                paid_job_board_publish: true,
                active: 1,
                worktype_id: jobFormData?.work_type_id || null,
                id: null,
              },
            ] as any),
        };
  const [hasJobPostType, setHasJobPostType] = useState(
    String(initialValues.job_type_id)
  );

  const [jobStatus, setJobStatus] = useState(
    (jobFormData || initialValues).job_status_id
  );

  useEffect(() => {
    setHasJobPostType(String(initialValues.job_type_id));
  }, [initialValues.job_type_id]);

  const emailSchema = Yup.string().email('Invalid email format');

  const validationSchema = Yup.lazy((values) => {
    var currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);
    if (currentJobStatus === 5) return Yup.mixed().notRequired();
    return hasJobPostType === '1'
      ? Yup.object().shape({
          // client
          job_type_id: Yup.number().required('Job type is required.'),
          company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
          contact: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
          location: Yup.array().min(1, 'Pick at least 1 location').nullable(),
          cc: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().when('$isLabelProvided', {
                is: true,
                then: emailSchema,
                otherwise: Yup.string(),
              }),
              id: Yup.string(),
              __isNew__: Yup.boolean(),
            })
          ),
          bcc: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().when('$isLabelProvided', {
                is: true,
                then: emailSchema,
                otherwise: Yup.string(),
              }),
              id: Yup.string(),
              __isNew__: Yup.boolean(),
            })
          ),
          // job details
          // created_by: Yup.number().required('Created by is required.'),
          primary_recruiter: Yup.number().required(
            'Primary Recruiter is required.'
          ),
          am_id: Yup.number().required('Account Manager is required.'),
          title: Yup.string().required('Job title is required.'),
          opening: Yup.number()
            .required('No. of openings is required.')
            .min(1, 'Invalid number.'),
          experience_level: Yup.number()
            .required('Experience level is required.')
            .min(1, 'Invalid level.'),
          //
          education_id: Yup.number(),
          work_type_id: Yup.number().required('Work type is required.'),
          client_job_link: Yup.string(),
          description: Yup.string(),

          // skills: Yup.array().of(Yup.string()),
          notes: Yup.string(),
          //
          employment_type_id: Yup.number(),
          compensation_id: Yup.number(),
          salary_range_start: Yup.string().matches(
            /^[\d,]+$/gi,
            'Salary is invalid'
          ),
          salary_range_end: Yup.string().matches(
            /^[\d,]+$/gi,
            'Salary is invalid'
          ),

          // questions // TODO: hide for now
          // questions: Yup.array().of(
          //   Yup.object().shape({
          //     order: Yup.number(),
          //     question: Yup.string(),
          //   })
          // ),
          // recruiters
          recruiters: Yup.array().of(
            Yup.object().shape({
              name: Yup.string().required('Recruiter is required'),
              split_percentage: Yup.number().required(
                'Split percentage is required'
              ),
              post_new_job_ads: Yup.boolean(),
              no_submittal_review: Yup.boolean(),
              review_send_as_client_owner: Yup.boolean(),
              review_send_as_client_recruiter: Yup.boolean(),
              interview_send_as_client_owner: Yup.boolean(),
              interview_send_as_recruiter: Yup.boolean(),
            })
          ),
          // job ads
          job_ads: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Posting Title is required'),
              state: Yup.string(),
              city: Yup.string(),
              country: Yup.object().required('Country is required').nullable(),
              // specification_id: Yup.number(),
              description: Yup.string(),
              career_page_publish: Yup.boolean(),
              show_profile: Yup.boolean(),
              free_job_board_publish: Yup.boolean(),
              paid_job_board_publish: Yup.boolean(),
              active: Yup.number(),
              worktype_id: Yup.number(),
              id: Yup.number().nullable(),
            })
          ),

          // customize pitch
          emaiL_subject: Yup.string(),
          email_content: Yup.string(),

          // not include
          status: Yup.number().required('Status is required.'),
          lead_id: Yup.number().required('Lead is required.'),
          job_status_id: Yup.number().required('Job status is required.'),
        })
      : Yup.object().shape({
          //client
          job_type_id: Yup.number().required('Job type is required.'),
          company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
          contact: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
          location: Yup.array().min(1, 'Pick at least 1 location').nullable(),
          cc: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().when('$isLabelProvided', {
                is: true,
                then: emailSchema,
                otherwise: Yup.string(),
              }),
              id: Yup.string(),
              __isNew__: Yup.boolean(),
            })
          ),
          bcc: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().when('$isLabelProvided', {
                is: true,
                then: emailSchema,
                otherwise: Yup.string(),
              }),
              id: Yup.string(),
              __isNew__: Yup.boolean(),
            })
          ),

          //job details
          // created_by: Yup.number().required('Created by is required.'),
          primary_recruiter: Yup.number().required(
            'Primary Recruiter is required.'
          ),
          am_id: Yup.number().required('Account Manager is required.'),
          title: Yup.string().required('Job title is required.'),
          experience_level: Yup.number()
            .required('Experience level is required.')
            .min(1, 'Invalid number.'),
          // skills: Yup.array().of(Yup.string()),
          notes: Yup.string(),
          client_job_link: Yup.string(),

          // not necessary
          status: Yup.number().required('Status is required.'),
          lead_id: Yup.number().required('Lead is required.'),
        });
  });

  const validationClientSchema = Yup.lazy((values) => {
    var currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);
    if (currentJobStatus === 5) return Yup.mixed().notRequired();
    return hasJobPostType === '1'
      ? Yup.object().shape({
          // client
          job_type_id: Yup.number().required('Job type is required.'),
          company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
          contact: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
          location: Yup.array().min(1, 'Pick at least 1 location').nullable(),
          cc: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().when('$isLabelProvided', {
                is: true,
                then: emailSchema,
                otherwise: Yup.string(),
              }),
              id: Yup.string(),
              __isNew__: Yup.boolean(),
            })
          ),
          bcc: Yup.array().of(
            Yup.object().shape({
              label: Yup.string().when('$isLabelProvided', {
                is: true,
                then: emailSchema,
                otherwise: Yup.string(),
              }),
              id: Yup.string(),
              __isNew__: Yup.boolean(),
            })
          ),

          // not include
          job_status_id: Yup.number().required('Job status is required.'),
        })
      : Yup.object().shape({
          //client
          job_type_id: Yup.number().required('Job type is required.'),
          company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
          contact: Yup.array().min(1, 'Pick at least 1 contact').nullable(),
          location: Yup.array().min(1, 'Pick at least 1 location').nullable(),
        });
  });

  const validationJobDetailsSchema = Yup.lazy((values) => {
    var currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);
    if (currentJobStatus === 5) return Yup.mixed().notRequired();
    return hasJobPostType === '1'
      ? Yup.object().shape({
          // job details
          job_type_id: Yup.number().required('Job type is required.'),
          title: Yup.string().required('Job title is required.'),
          opening: Yup.number()
            .required('No. of openings is required.')
            .positive('Must be a positive number.'),
          experience_level: Yup.number()
            .required('Experience level is required.')
            .min(1, 'Invalid number.'),
          //
          education_id: Yup.number(),
          work_type_id: Yup.number().required('Work type is required.'),
          client_job_link: Yup.string(),
          description: Yup.string(),

          // skills: Yup.array().of(Yup.string()),
          notes: Yup.string(),
          //
          employment_type_id: Yup.number(),
          compensation_id: Yup.number(),
          salary_range_start: Yup.string().matches(
            /^[\d,]+$/gi,
            'Salary is invalid'
          ),
          salary_range_end: Yup.string().matches(
            /^[\d,]+$/gi,
            'Salary is invalid'
          ),

          job_status_id: Yup.number().required('Job status is required.'),
        })
      : Yup.object().shape({
          //job details
          job_type_id: Yup.number().required('Job type is required.'),
          client_job_link: Yup.string(),
          // created_by: Yup.number().required('Created by is required.'),
          primary_recruiter: Yup.number().required(
            'Primary Recruiter is required.'
          ),
          am_id: Yup.number().required('Account Manager is required.'),
          title: Yup.string().required('Job title is required.'),
          experience_level: Yup.number()
            .required('Experience level is required.')
            .min(1, 'Invalid number.'),
          // skills: Yup.array().of(Yup.string()),
          notes: Yup.string(),
        });
  });

  // const validationQuestionsSchema = Yup.lazy((values) => { // TODO: hide for now
  //   var currentJobStatus = values.job_status_id;
  //   setJobStatus(currentJobStatus);
  //   if (currentJobStatus === 5) return Yup.mixed().notRequired();
  //   return Yup.object().shape({
  //     // questions
  //     job_type_id: Yup.number().required('Job type is required.'),
  //     questions: Yup.array().of(
  //       Yup.object().shape({
  //         order: Yup.number(),
  //         question: Yup.string(),
  //       })
  //     ),
  //     // not include
  //     job_status_id: Yup.number().required('Job status is required.'),
  //   });
  // });

  const validationRecruitersSchema = Yup.lazy((values) => {
    var currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);
    if (currentJobStatus === 5) return Yup.mixed().notRequired();
    return Yup.object().shape({
      // recruiters
      // created_by: Yup.number().required('Created by is required.'),
      primary_recruiter: Yup.number().required(
        'Primary Recruiter is required.'
      ),
      am_id: Yup.number().required('Account Manager is required.'),
      job_type_id: Yup.number().required('Job type is required.'),
      recruiters: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required('Recruiter is required'),
          split_percentage: Yup.number().required(
            'Split percentage is required'
          ),
          post_new_job_ads: Yup.boolean(),
          no_submittal_review: Yup.boolean(),
          review_send_as_client_owner: Yup.boolean(),
          review_send_as_client_recruiter: Yup.boolean(),
          interview_send_as_client_owner: Yup.boolean(),
          interview_send_as_recruiter: Yup.boolean(),
        })
      ),
      // not include
      job_status_id: Yup.number().required('Job status is required.'),
    });
  });

  const validationJobAdsSchema = Yup.lazy((values) => {
    var currentJobStatus = values.job_status_id;
    setJobStatus(currentJobStatus);
    if (currentJobStatus === 5) return Yup.mixed().notRequired();
    return Yup.object().shape({
      // recruiters
      // created_by: Yup.number().required('Created by is required.'),
      job_ads: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required('Posting Title is required'),
          state: Yup.string(),
          city: Yup.string(),
          country: Yup.object().required('Country is required').nullable(),
          // specification_id: Yup.number(),
          description: Yup.string(),
          career_page_publish: Yup.boolean(),
          show_profile: Yup.boolean(),
          free_job_board_publish: Yup.boolean(),
          paid_job_board_publish: Yup.boolean(),
          active: Yup.number(),
          worktype_id: Yup.number(),
          id: Yup.number().nullable(),
        })
      ),
    });
  });

  // const tabListIndex: any = [ // TODO: hide for now
  //   { id: 0, title: 'Client' },
  //   { id: 1, title: 'Job Details' },
  //   { id: 2, title: 'Screening Details' },
  //   { id: 3, title: 'Recruiting Team' },
  //   { id: 4, title: 'Job Ads' },
  // ];

  const tabListIndex: any = [
    { id: 0, title: 'Client' },
    { id: 1, title: 'Job Details' },
    {
      id: 2,
      title: hasJobPostType === '2' ? 'Customize Pitch' : 'Recruiting Team',
    },
    { id: 3, title: 'Job Ads' },
    { id: 4, title: 'Customize Pitch' },
  ];

  const [tabList, setTabList] = useState(tabListIndex);
  const [tabIndex, setTabIndex] = useState(tabList[0]);
  const [errorList, setErrorList] = useState({});

  const [isDraft, setIsDraft] = useState(false);

  const [protocol, setProtocol] = useState({
    value: 'https://',
    label: 'https://',
  });

  useEffect(() => {
    if (tabIndex.id === 1) {
      // Focus on the topComponent element after the component has mounted
      document.getElementById('title').focus();
    }
  }, [tabIndex.id]);

  useEffect(() => {
    if (hasJobPostType === '2') {
      setTabList(tabListIndex.slice(0, 3));
    } else {
      return setTabList(tabListIndex);
    }
  }, [hasJobPostType]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const changeToDefault = (data: any) => {
    data.job_status_id = 5;
    // client
    data.company =
      data.company?.length === 0
        ? (data.company = { value: null, label: ' ' })
        : data.company;
    data.contact =
      data.contact?.length === 0
        ? (data.contact = { value: 1, label: ' ' })
        : data.contact;

    // job details
    // data.created_by = jobFormData.created_by || jobData.user_id || auth?.id;
    // data.specification_id =
    //   data.specification_id === undefined ? '2' : data.specification_id;
    data.primary_recruiter =
      jobFormData.primary_recruiter ||
      jobData.primary?.recruiter_id ||
      auth?.id;
    data.am_id =
      jobFormData?.am_id || jobData?.am_id || aMOption?.length > 0
        ? aMOption[0]?.value
        : '';
    data.title = data.title === undefined ? '' : data.title;
    data.opening = data.opening === undefined ? 1 : data.opening;
    data.experience_level =
      data.experience_level === undefined ? null : data.experience_level;
    //
    // data.skills = data.skills === undefined ? [] : data.skills;
    data.notes = data.notes;
    data.work_type_id =
      data.work_type_id === undefined ? null : data.work_type_id;

    // data.skills = data.company?.length === 0 ? [] : data.skills;
    //
    data.employment_type_id =
      data.employment_type_id === undefined ? 1 : data.employment_type_id;
    data.compensation_id =
      data.compensation_id === undefined ? 2 : data.compensation_id;
    data.salary_range_start =
      data.salary_range_start === undefined ? -1 : data.salary_range_start;
    data.salary_range_end =
      data.salary_range_end === undefined ? -1 : data.salary_range_end;

    if (data.job_type_id === '1') {
      // data.questions = data.questions.length === 0 ? [] : data.questions;
      data.recruiters = data.recruiters.length === 0 ? [] : data.recruiters;
      data.job_ads = data.job_ads.length === 0 ? [] : data.job_ads;
    }
    return data;
  };

  const onSubmit = (data: any) => {
    // console.log('submit', data);
    data.location = data.location.value;
    data.education_id = data.education_id === -1 ? 1 : data.education_id;
    data.employment_type_id =
      data.employment_type_id === -1 ? 1 : data.employment_type_id;
    data.compensation_id =
      data.compensation_id === -1 ? 2 : data.compensation_id;

    if (data.notes !== '') {
      const notes = document.createElement('p');
      notes.innerHTML = data.notes;
      notes.style.fontFamily = 'Verdana';
      notes.style.fontSize = '10pt';
      notes.style.lineHeight = 'normal';
      data.notes = String(notes.outerHTML);
    }

    data.questions = []; // TODO: Temporary

    if (
      data.email_content &&
      data.email_subject &&
      (data.email_content !== '' || data.email_subject !== '')
    ) {
      const customize_pitch = {
        email_content: data.email_content,
        email_subject: data.email_subject,
      };
      data['customize_pitch'] = customize_pitch;
    }

    delete data.email_content;
    delete data.email_subject;

    if (data.job_ads) {
      data.job_ads[0].country = data.job_ads[0]?.country?.label;
    }

    if (data.client_job_link) {
      data.client_job_link =
        protocol.value + data.client_job_link?.replace(/(^\w+:|^)\/\//, '');
    }

    let cc: any = [];
    if (data.cc.length > 0) {
      data.cc.map((item: any) => {
        if (typeof item === 'object') {
          cc.push(item.value);
        } else {
          cc.push(item.label);
        }
      });
    }
    data.cc = cc;
    let bcc: any = [];
    if (data.bcc.length > 0) {
      data.bcc.map((item: any) => {
        if (typeof item === 'object') {
          bcc.push(item.value);
        } else {
          bcc.push(item.label);
        }
      });
    }
    data.bcc = bcc;
    if (hasJobPostType === '2') {
      delete data.job_type_id;
      delete data.questions;
      delete data.job_ads;
      delete data.description;
      delete data.recruiters;
      delete data.opening;
      delete data.education_id;
      delete data.employment_type_id;
      delete data.salary_range_end;
      delete data.salary_range_start;
      delete data.work_type_id;
      delete data.compensation_id;
    } else {
      data.job_type_id = Number(data.job_type_id);
      data.salary_range_start =
        Number(String(data.salary_range_start)?.replace(/,/g, '')) || -1;
      data.salary_range_end =
        Number(String(data.salary_range_end)?.replace(/,/g, '')) || -1;
    }
    if (
      hasJobPostType === '1' &&
      data.recruiters &&
      data.recruiters?.length > 0
    ) {
      data.recruiters.map((item: any, key: number) => {
        let index = `recruiters${key}`;
        delete data[index]; //remove unwanted index
      });
    }

    localStorage.removeItem('jobFormData');

    // console.log('data', data);

    if (isEditModal) {
      if (hasJobPostType === '2') {
        reqUpdateQuick({ data, id: jobData.id });
      } else {
        reqUpdateJob({ data, id: jobData.id });
      }
    } else if (hasJobPostType === '2') {
      reqCreateQuick(data);
    } else {
      reqCreateJob(data);
    }
  };

  const loadConstant = () => {
    reqUsers({});
    reqCountry({});
    reqcompany({});
    reqWorkTypes({});
    reqEducTypes({});
    reqCompTypes({});
    reqEmpTypes({});
    // reqSpecs({});
  };

  useEffect(() => {
    if (isOpen) {
      loadConstant();
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      resCreateJob.isSuccess ||
      resUpdateJob.isSuccess ||
      resCreateQuick.isSuccess ||
      resUpdateQuick.isSuccess
    ) {
      {
        onCloseAlert();
        onClose();
        if (resCreateJob.isSuccess || resCreateQuick.isSuccess) {
          dispatch(getJob({ jobData: {} }));
          dispatch(
            listClients({ selected_client: [], selected_client_location: [] })
          );
        }

        if (!isDrawer) {
          reqJobs(jobPgBtn);
        } else if (params.clientsTab) {
          reqClientJobs({ data: jobPgBtn, id: params.clientsId });
        }

        let jobId = null;

        if (resCreateJob.isSuccess) {
          jobId = resCreateJob?.data?.data?.data?.id;
        }
        if (resUpdateJob.isSuccess) {
          jobId = resUpdateJob?.data?.data?.data?.id;
        }
        if (resCreateQuick.isSuccess) {
          jobId = resCreateQuick?.data?.data?.data?.id;
        }
        if (resUpdateQuick.isSuccess) {
          jobId = resUpdateQuick?.data?.data?.data?.id;
        }

        navigate(`/jobs/${jobId}/view-candidates/candidate-pool`);

        try {
          let title = '';
          let description = '';
          let status: 'error' | 'warning' | 'success' | 'info' = 'success';

          if (resCreateJob.isSuccess && jobStatus === 1) {
            title = 'Added Job';
            description = 'New Job successfully added';
          } else if (resCreateJob.isSuccess && jobStatus === 5) {
            title = 'Saved Job to Draft';
            description = 'Job has been saved to draft';
          } else if (resUpdateJob.isSuccess) {
            title = 'Updated Job';
            description = 'Job has been updated';
            status = 'info';
          } else if (resCreateQuick.isSuccess && jobStatus === 1) {
            title = 'Added Job Quick';
            description = 'New Job Quick successfully added';
          } else if (resCreateQuick.isSuccess && jobStatus === 5) {
            title = 'Saved Job Quick to Draft';
            description = 'Job Quick has been saved to draft';
          } else if (resUpdateQuick.isSuccess) {
            title = 'Updated Job Quick';
            description = 'Job Quick has been updated';
            status = 'info';
          }

          toast({
            title: title,
            description: description,
            status: status,
            isClosable: true,
            duration: 3000,
            position: 'top',
          });
        } catch (e) {
          console.log('error', e);
        }
      }
    }
  }, [
    resCreateJob.isSuccess,
    resUpdateJob.isSuccess,
    resCreateQuick.isSuccess,
    resUpdateQuick.isSuccess,
  ]);

  useEffect(() => {
    if (
      resCreateJob.isError ||
      resUpdateJob.isError ||
      resCreateQuick.isError ||
      resUpdateQuick.isError
    ) {
      onOpen();
    }
    if (resCreateJob.isError) {
      let errorMessage: any = resCreateJob.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMsg(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMsg(errorMessage['data']['message']);
        } catch (e) {
          // console.log('e', e);
          setErrorMsg('Server Error');
        }
      }
    }
  }, [
    resCreateJob.error,
    resCreateJob.isError,
    resCreateQuick.isError,
    resUpdateJob.isError,
    resUpdateQuick.isError,
  ]);

  useEffect(() => {
    let users: any = [];
    if (resUsers.isSuccess) {
      if (resUsers.data?.data.length > 0) {
        Promise.all(
          resUsers.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setRecruiters(users);
  }, [resUsers.data?.data, resUsers.isSuccess]);

  useEffect(() => {
    reqExpLevel({});

    if (resCompany.isSuccess && contactOptionValue.length === 0) {
      const company = resCompany.data.data;
      let comOption: any = [];
      if (company.length > 0) {
        company.map((option: any) => {
          comOption.push({
            value: option.id,
            label: option.name,
          });
        });
        setCompanyOption(comOption);
      }

      if (initialValues.company?.length > 0) {
        setContact(initialValues.company[0].value);
      }
      // setManager(e.value);
      if (jobData?.client_id) {
        setManager(jobData.client_id);
      }
    }
  }, [resCompany.isSuccess]);

  const CompanyChange = async (e: any) => {
    setContact(e.value);
    setManager(e.value);
  };

  const protocolRegex = /^(.*?:\/\/)/;

  const handleProtocol = (value: string) => {
    const match = value.match(protocolRegex);

    if (match) {
      const protocol = match[1];
      setProtocol({
        value: protocol,
        label: protocol,
      });
    }
  };

  useEffect(() => {
    if (jobData.client_job_link) {
      handleProtocol(jobData.client_job_link);
    }
  }, [jobData?.client_job_link]);

  useEffect(() => {
    if (jobData?.client_id) {
      setManager(jobData?.client_id);
      setContact(jobData?.client_id);
    }
  }, [jobData, RawList]);

  useEffect(() => {
    const setManager = async (id: any) => {
      const company = RawList.filter((obj: any) => {
        return obj.id === id;
      });

      let options: any = [];
      if (company[0] !== undefined) {
        if (company[0].manager?.length > 0) {
          await Promise.all(
            company[0].manager.map(async (item: any) => {
              options.push({
                label: [item?.user?.first_name, item?.user?.last_name].join(
                  ' '
                ),
                value: item.user_id,
              });
            })
          );
        }
      }
      setAmOption(options);
    };
    if (jobFormData?.company?.value) {
      setManager(jobFormData?.company?.value);
    }
  }, [RawList, jobFormData?.company?.value]);

  const setManager = async (id: any) => {
    const company = RawList.filter((obj: any) => {
      return obj.id === id;
    });

    let options: any = [];
    if (company[0] !== undefined) {
      if (company[0].manager?.length > 0) {
        await Promise.all(
          company[0].manager.map(async (item: any) => {
            options.push({
              label: [item?.user?.first_name, item?.user?.last_name].join(' '),
              value: item.user_id,
            });
          })
        );
      }
    }
    setAmOption(options);
  };

  const setContact = async (id: any) => {
    const company = RawList.filter((obj: any) => {
      return obj.id === id;
    });
    let options: any = [];
    let optionsEmails: any = [];
    if (company[0] !== undefined) {
      if (company[0].leads?.length > 0) {
        let emails = [] as any;
        await Promise.all(
          company[0].leads.map(async (item: any) => {
            options.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
            optionsEmails.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.primary_email,
            });
          })
        ).then(() => {
          // optionsEmails.push({
          //   label: 'Clients',
          //   options: optionsEmails,
          // });
        });
      }
    }

    // optionsEmails = [...optionsEmails, ...populateUSers()];

    setContactOption(options);
    setContactOptionsEmail(optionsEmails);
    setCcOption(optionsEmails);
    setBccOption(optionsEmails);
    dispatch(setClientId({ client_id: id }));
    reqLocation({ id });
  };

  const populateUSers = () => {
    let emails = [] as any;

    Object.values(userList).map((item: any) => {
      emails.push({
        label: [item.first_name, item.last_name].filter(Boolean).join(' '),
        value: item.email,
      });
    });

    return emails;
  };

  useEffect(() => {
    const setLocation = async () => {
      let options: any = [];
      const address = client_location;

      if (address !== undefined) {
        if (address?.length > 0) {
          await Promise.all(
            address.map(async (item: any) => {
              options.push({
                label: [
                  item.address,
                  item.city,
                  item.state,
                  item.zip_code,
                  item.country?.country,
                ]
                  .filter((addressPart) => addressPart !== '')
                  .join(', '),
                value: item.id,
                data: item,
              });
            })
          );
        }
      }
      options.push({
        label: 'Create New Address',
        value: options.length + 1,
      });
      setLocationOption(options);
    };

    if (client_location) {
      setLocation();
    }
  }, [companyDef.value, client_location]);

  useEffect(() => {
    localStorage.setItem('jobFormData', JSON.stringify(jobFormData));
  }, [jobFormData]);

  const { geo_location } = useSelector((state: any) => state.geolocation);

  const [itemLocation, setItemLocation] = useState([]);
  const [reqGeo, resGeo] = useSearchGeoTypesMutation();
  const [currentName, setName] = useState('');

  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      geo_location.map((item: any, key: number) => {
        const value = currentName == 'city' ? item.city : item.postal_code;
        optios.push({
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
          value: value,
          label: geoFormat(item),
          id: number,
        });
      });
      setItemLocation(optios);
    } else {
      setItemLocation([]);
    }
  };

  const geoFormat = (item: any) => {
    let format: any = '';
    try {
      const cityB = currentName == 'city' ? { fontWeight: 'bold' } : {};
      const postB = currentName == 'postal_code' ? { fontWeight: 'bold' } : {};
      format = (
        <>
          <Flex gap="2px">
            <Text style={postB}>{item?.postal_code}</Text>
            <Text style={cityB}>{item?.city}</Text>
            <Text>{item?.state}</Text>
            <Text>{item?.country}</Text>
          </Flex>
        </>
      );
    } catch (e) {
      format = 'Error formatting';
    }
    return format;
  };

  const geoLocationChange = debounce((e: any, column: string) => {
    const search = e.target.value;
    if (search.length > 3) {
      const data = {
        search,
        column,
      };
      reqGeo(data);
    }
  }, 500);

  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption([
            {
              label: item.country,
              value: item.id,
              code: item.code,
            },
          ]);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  const onCcInputChange = (e: any) => {
    if (e.length > 2) {
      setCcOption(populateUSers());
    } else {
      setCcOption(contactOptionsEmail);
    }
  };

  const onBccInputchange = (e: any) => {
    if (e.length > 2) {
      setBccOption(populateUSers());
    } else {
      setBccOption(contactOptionsEmail);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          !isEditModal && dispatch(getJob({ jobData: {} }));
          onCloseAlert();
          onClose();
          dispatch(
            listClients({ selected_client: [], selected_client_location: [] })
          );
          localStorage.removeItem('jobFormData');
        }}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {modalTitle}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && jobStatus !== 5 && (
            <Box>
              <Alert
                status="error"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Flex gap="20px" position="relative" left="20px">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Oh snap!</AlertTitle>
                    <AlertDescription>
                      {errMsg || (
                        <>
                          There were some problems in you input. Change a few
                          things up and try again.
                          <UnorderedList
                            mt="10px"
                            fontSize="13px"
                            lineHeight="1.5"
                            color="var(--chakra-colors-red-500)"
                          >
                            {Object.values(errorList).map((key: any) => (
                              <ListItem key={key}>{key}</ListItem>
                            ))}
                          </UnorderedList>
                        </>
                      )}
                    </AlertDescription>
                  </Box>
                </Flex>

                <CloseButton
                  position="absolute"
                  right="20px"
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="Proxima Nova Regular"
          >
            <Tabs colorScheme="purple" variant="unstyled" index={tabIndex.id}>
              <Flex gap={2} background="#F9F9FB">
                <Box
                  width="30%"
                  py={4}
                  overflow="auto"
                  borderRight="1px solid"
                  borderColor="transparent"
                  px={6}
                >
                  <TabList
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="8px"
                  >
                    {tabList.map((tab: any, key: number) => {
                      return (
                        <Tab
                          key={`tablist01-${key}`}
                          justifyContent="space-between"
                          width="100%"
                          border="0.5px solid rgba(155, 155, 155, 0.5)"
                          borderRadius="3px"
                          background="#fff"
                          p="12px 20px"
                          lineHeight="18px"
                          fontSize="14px"
                          fontWeight="600"
                          _selected={{
                            bg: '#EFE5FF',
                            border: '0.5px solid #EFE5FF',
                          }}
                          _focus={{
                            boxShadow: 'none',
                          }}
                        >
                          {tab.title}
                          <Icon
                            as={ChevronLeftIcon}
                            transition="all .25s ease-in-out"
                            transform={isOpen ? 'rotate(180deg)' : ''}
                            w={6}
                            h={6}
                            ml="8px"
                            color={
                              tabIndex.id === tab.id ? 'primary.800' : '#fff'
                            }
                          />
                        </Tab>
                      );
                    })}
                  </TabList>
                </Box>

                <Box width="70%">
                  {resGetJob.isLoading ? (
                    <LoadingEdit />
                  ) : (
                    <TabPanels>
                      {tabIndex.id === 0 && (
                        <Formik
                          initialValues={initialClientValues}
                          validationSchema={validationClientSchema}
                          onSubmit={(data, { setSubmitting, validateForm }) => {
                            if (isDraft === false) {
                              setJobFormData((prev: any) => ({
                                opening:
                                  jobFormData.opening || jobData.opening || 1,
                                employment_type_id:
                                  jobFormData.employment_type_id ||
                                  jobData.employment_type_id ||
                                  1,
                                compensation_id:
                                  jobFormData.compensation_id ||
                                  jobData.compensation_id ||
                                  2,
                                am_id:
                                  aMOption?.length > 0
                                    ? aMOption[0]?.value
                                    : ('' as any),
                                ...prev,
                                ...data,
                                // created_by:
                                //   jobFormData.created_by ||
                                //   jobData.user_id ||
                                //   auth?.id,
                                primary_recruiter:
                                  jobFormData.primary_recruiter ||
                                  jobData.primary?.recruiter_id ||
                                  auth?.id,
                              }));
                            }
                            setSubmitting(false);
                            validateForm().then((errors) => {
                              if (Object.keys(errors).length === 0) {
                                if (isDraft === true) {
                                  onSubmit({
                                    ...initialValues,
                                    ...changeToDefault(data),
                                    ...jobFormData,
                                    job_status_id: 5,
                                  });
                                } else if (
                                  tabList?.length !==
                                  tabIndex.id + 1
                                ) {
                                  setTabIndex(tabList[tabIndex.id + 1]);
                                }
                              }
                            });
                          }}
                          enableReinitialize={true}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            isValid,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <ScrollToFieldError />
                              {/* Client */}
                              <Box
                                overflow="auto"
                                maxHeight="450px"
                                p="30px"
                                background="#fff"
                                borderRadius="8px"
                                minHeight="65vh"
                              >
                                <>
                                  <FormControl
                                    isInvalid={Boolean(
                                      !!errors.job_type_id &&
                                        touched.job_type_id
                                    )}
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                    >
                                      Job Post Type
                                    </FormLabel>
                                    <RadioGroup
                                      value={String(values.job_type_id)}
                                      mb="24px"
                                    >
                                      <Stack spacing={5} direction="row">
                                        <Flex alignItems="center" gap="10px">
                                          <Radio
                                            colorScheme="purple"
                                            name="job_type_id"
                                            value="1"
                                            fontSize="14px"
                                            lineHeight="16px"
                                            color="default.primarytext"
                                            onChange={(e: any) => {
                                              handleChange(e);
                                              localStorage.setItem(
                                                'jobFormData',
                                                JSON.stringify({
                                                  ...jobFormData,
                                                  ...values,
                                                })
                                              );
                                              setHasJobPostType(e.target.value);
                                              setJobFormData((prev: any) => ({
                                                ...prev,
                                                job_type_id: e.target.value,
                                              }));
                                              setFieldValue(
                                                'job_type_id',
                                                e.target.value
                                              );
                                            }}
                                          >
                                            Full Job Post
                                          </Radio>
                                        </Flex>
                                        {isEditModal &&
                                        String(jobData.job_type_id) === '1' ? (
                                          ''
                                        ) : (
                                          <Flex alignItems="center" gap="10px">
                                            <Radio
                                              colorScheme="purple"
                                              name="job_type_id"
                                              value="2"
                                              fontSize="14px"
                                              lineHeight="16px"
                                              color="default.primarytext"
                                              onChange={(e: any) => {
                                                handleChange(e);
                                                localStorage.setItem(
                                                  'jobFormData',
                                                  JSON.stringify({
                                                    ...jobFormData,
                                                    ...values,
                                                  })
                                                );
                                                setHasJobPostType(
                                                  e.target.value
                                                );
                                                setJobFormData((prev: any) => ({
                                                  ...prev,
                                                  job_type_id: e.target.value,
                                                  company: values.company,
                                                  contact: values.contact,
                                                  location: values.location,
                                                }));
                                                setFieldValue(
                                                  'job_type_id',
                                                  e.target.value
                                                );
                                              }}
                                            >
                                              Quick Job Posts
                                            </Radio>
                                          </Flex>
                                        )}
                                      </Stack>
                                    </RadioGroup>

                                    <FormErrorMessage>
                                      {String(errors.job_type_id)}
                                    </FormErrorMessage>
                                  </FormControl>

                                  <Flex gap="32px" mb="34px">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.company && touched.company
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Company{' '}
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>

                                      <Box
                                        border={
                                          !!errors.company &&
                                          touched.company &&
                                          '1px solid #E53E3E'
                                        }
                                        borderRadius={
                                          !!errors.company &&
                                          touched.company &&
                                          '4px'
                                        }
                                        boxShadow={
                                          !!errors.company &&
                                          touched.company &&
                                          '0 0 0 1px #E53E3E'
                                        }
                                        onClick={() => {
                                          setFieldValue('contact', []);
                                          setFieldValue('location', []);
                                        }}
                                        onBlur={() =>
                                          localStorage.setItem(
                                            'jobFormData',
                                            JSON.stringify({
                                              ...jobFormData,
                                              ...values,
                                            })
                                          )
                                        }
                                      >
                                        {searchableLeads != null ||
                                        searchableClient != null ? (
                                          <Field
                                            name="company"
                                            options={CompanyOption}
                                            component={AtsSelect2}
                                            defaultValue={values.company}
                                            variant="outline"
                                            isLoading={resCompany.isLoading}
                                            onChange={(e: any) =>
                                              CompanyChange(e)
                                            }
                                            placeholder="Select Company"
                                            menuIsOpen={searchableLeads}
                                          />
                                        ) : (
                                          <Field
                                            name="company"
                                            options={CompanyOption}
                                            component={AtsSelect2}
                                            defaultValue={values.company}
                                            variant="outline"
                                            isLoading={resCompany.isLoading}
                                            onChange={(e: any) =>
                                              CompanyChange(e)
                                            }
                                            placeholder="Select Company"
                                          />
                                        )}
                                      </Box>
                                      <FormErrorMessage>
                                        {String(errors.company)}
                                      </FormErrorMessage>
                                    </FormControl>

                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.contact && touched.contact
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Contact{' '}
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>
                                      <Box
                                        border={
                                          !!errors.contact &&
                                          touched.contact &&
                                          '1px solid #E53E3E'
                                        }
                                        borderRadius={
                                          !!errors.contact &&
                                          touched.contact &&
                                          '4px'
                                        }
                                        boxShadow={
                                          !!errors.contact &&
                                          touched.contact &&
                                          '0 0 0 1px #E53E3E'
                                        }
                                        onBlur={() =>
                                          localStorage.setItem(
                                            'jobFormData',
                                            JSON.stringify({
                                              ...jobFormData,
                                              ...values,
                                            })
                                          )
                                        }
                                      >
                                        {searchableLeads != null ? (
                                          <Field
                                            name="contact"
                                            options={contactOption}
                                            component={AtsSelect2}
                                            defaultValue={values.contact}
                                            // onChange={() => {}}
                                            placeholder="Select Contact"
                                            menuIsOpen={searchableLeads}
                                          />
                                        ) : (
                                          <Field
                                            name="contact"
                                            options={contactOption}
                                            component={AtsSelect2}
                                            defaultValue={values.contact}
                                            // onChange={() => {}}
                                            placeholder="Select Contact"
                                          />
                                        )}
                                      </Box>
                                      <FormErrorMessage>
                                        {String(errors.contact)}
                                      </FormErrorMessage>
                                    </FormControl>
                                  </Flex>

                                  <FormControl
                                    isInvalid={Boolean(
                                      !!errors.location && touched.location
                                    )}
                                    mb="34px"
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                    >
                                      Location{' '}
                                      <Box as="span" color="caution.800">
                                        *
                                      </Box>
                                    </FormLabel>

                                    <Box
                                      border={
                                        !!errors.contact &&
                                        touched.contact &&
                                        '1px solid #E53E3E'
                                      }
                                      borderRadius={
                                        !!errors.contact &&
                                        touched.contact &&
                                        '4px'
                                      }
                                      boxShadow={
                                        !!errors.contact &&
                                        touched.contact &&
                                        '0 0 0 1px #E53E3E'
                                      }
                                      onClick={() => {
                                        dispatch(
                                          listClients({
                                            selected_client: values,
                                            selected_client_location: [],
                                          })
                                        );
                                      }}
                                      onBlur={() =>
                                        localStorage.setItem(
                                          'jobFormData',
                                          JSON.stringify({
                                            ...jobFormData,
                                            ...values,
                                          })
                                        )
                                      }
                                    >
                                      <Field
                                        name="location"
                                        options={locationOption}
                                        component={AtsSelectAddress}
                                        value={values.location}
                                        placeholder="Select Location"
                                      />
                                    </Box>

                                    <FormErrorMessage>
                                      {String(errors.location)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    mb="34px"
                                    isInvalid={Boolean(
                                      !!errors.cc && touched.cc
                                    )}
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                    >
                                      cc
                                    </FormLabel>
                                    <AtsCraetableSelect
                                      id="cc"
                                      name="cc"
                                      onChange={(event: any) =>
                                        setFieldValue('cc', event)
                                      }
                                      onBlur={() =>
                                        localStorage.setItem(
                                          'jobFormData',
                                          JSON.stringify({
                                            ...jobFormData,
                                            ...values,
                                          })
                                        )
                                      }
                                      options={ccOption}
                                      isMulti={true}
                                      placeholder="cc"
                                      defaultValue={values.cc}
                                      onInputChange={onCcInputChange}
                                    />
                                    <FormErrorMessage>
                                      {String(errors.cc)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  <FormControl
                                    mb="34px"
                                    isInvalid={Boolean(
                                      !!errors.bcc && touched.bcc
                                    )}
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                    >
                                      bcc
                                    </FormLabel>
                                    <AtsCraetableSelect
                                      id="bcc"
                                      name="bcc"
                                      onChange={(event: any) =>
                                        setFieldValue('bcc', event)
                                      }
                                      onBlur={() =>
                                        localStorage.setItem(
                                          'jobFormData',
                                          JSON.stringify({
                                            ...jobFormData,
                                            ...values,
                                          })
                                        )
                                      }
                                      isMulti={true}
                                      options={bccOption}
                                      placeholder="bcc"
                                      defaultValue={values.bcc}
                                      onInputChange={onBccInputchange}
                                      // defaultValue={dataBcc}
                                    />
                                    <FormErrorMessage>
                                      {String(errors.bcc)}
                                    </FormErrorMessage>
                                  </FormControl>
                                </>
                              </Box>
                              <ModalFooter
                                position="sticky"
                                bottom="0"
                                background="#F9F9FB"
                                mb="20px"
                                mr="20px"
                                gap="16px"
                              >
                                {((String(values.job_type_id) === '1' &&
                                  tabIndex.id < 4) ||
                                  (String(values.job_type_id) === '2' &&
                                    tabIndex.id < 2)) && (
                                  <Button
                                    variant="solid"
                                    disabled={isOpenAlert}
                                    onClick={(e: any) => {
                                      setIsDraft(false);
                                      handleSubmit(e);
                                    }}
                                  >
                                    Next
                                  </Button>
                                )}
                              </ModalFooter>
                            </form>
                          )}
                        </Formik>
                      )}
                      {tabIndex.id === 1 && (
                        <Formik
                          initialValues={initialJobDetailsValues}
                          validationSchema={validationJobDetailsSchema}
                          onSubmit={(data, { setSubmitting, validateForm }) => {
                            const pairTemplate = {
                              ...jobPairTemplate,
                              job_title: data.title,
                            };
                            // if (hasJobPostType === '1') {
                            // const pairTemplate = {
                            //   ...jobPairTemplate,
                            //   job_title: data.title,
                            // };
                            jobPairing(pairTemplate);
                            if (isDraft === false) {
                              setJobFormData((prev: any) => ({
                                ...prev,
                                ...data,
                              }));
                              setSubmitting(false);
                            }
                            validateForm().then((errors) => {
                              if (Object.keys(errors).length === 0) {
                                if (isDraft === true) {
                                  onSubmit({
                                    ...initialValues,
                                    ...changeToDefault(data),
                                    ...jobFormData,
                                    job_status_id: 5,
                                  });
                                } else if (
                                  tabList?.length !==
                                  tabIndex.id + 1
                                ) {
                                  setTabIndex(tabList[tabIndex.id + 1]);
                                }
                              }
                            });
                            // } else if (isDraft === false) {
                            //   onSubmit({
                            //     ...jobFormData,
                            //     ...data,
                            //     job_status_id: 1,
                            //   });
                            // }
                            // jobPairing(pairTemplate)
                            // if (isDraft === false) {
                            //   setJobFormData((prev: any) => ({
                            //     ...prev,
                            //     ...data,
                            //   })
                            //   );
                            //   setSubmitting(false);
                            // }

                            // validateForm().then((errors) => {
                            //   if (Object.keys(errors).length === 0) {
                            //     if (isDraft === true) {
                            //       onSubmit({
                            //         ...initialValues,
                            //         ...changeToDefault(data),
                            //         ...jobFormData,
                            //         job_status_id: 5,
                            //       });
                            //     } else if (
                            //       tabList?.length !==
                            //       tabIndex.id + 1
                            //     ) {
                            //       console.log("jobFormData ", jobFormData)
                            //       setTabIndex(tabList[tabIndex.id + 1]);
                            //     }
                            //   }
                            // });
                          }}
                          enableReinitialize={true}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            isValid,
                            setFieldValue,
                            setValues,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <ScrollToFieldError />
                              {/* Job Details */}

                              <Box
                                overflow="auto"
                                maxHeight="450px"
                                p="30px"
                                background="#fff"
                                borderRadius="8px"
                                minHeight="65vh"
                              >
                                {hasJobPostType === '2' && (
                                  <>
                                    <Flex gap="32px" mb="34px">
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!errors.am_id && touched.am_id
                                        )}
                                      >
                                        <FormLabel>
                                          Account Manager{' '}
                                          <Box as="span" color="caution.800">
                                            *
                                          </Box>
                                        </FormLabel>
                                        <AtsSelect
                                          name="am_id"
                                          id="am_id"
                                          placeholder="Select"
                                          variant="outline"
                                          onChange={(e: any) =>
                                            setFieldValue(
                                              'am_id',
                                              String(e.value)
                                            )
                                          }
                                          onBlur={() =>
                                            localStorage.setItem(
                                              'jobFormData',
                                              JSON.stringify({
                                                ...jobFormData,
                                                ...values,
                                              })
                                            )
                                          }
                                          defaultValue={
                                            (aMOption?.length > 0 &&
                                              aMOption
                                                .map((item: any) => ({
                                                  label: item.label,
                                                  value: item.value,
                                                }))
                                                .filter(
                                                  (val: any) =>
                                                    val.value === values.am_id
                                                )[0]) ||
                                            values.am_id
                                          }
                                          options={
                                            aMOption?.length > 0 &&
                                            aMOption.map((item: any) => ({
                                              label: item.label,
                                              value: item.value,
                                            }))
                                          }
                                        />
                                        <FormErrorMessage>
                                          {String(errors.am_id)}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!errors.primary_recruiter &&
                                            touched.primary_recruiter
                                        )}
                                      >
                                        <FormLabel>
                                          Primary Recruiter{' '}
                                          <Box as="span" color="caution.800">
                                            *
                                          </Box>
                                        </FormLabel>

                                        <AtsSelect
                                          name="primary_recruiter"
                                          id="primary_recruiter"
                                          placeholder="Select"
                                          variant="outline"
                                          onChange={(e: any) =>
                                            setFieldValue(
                                              'primary_recruiter',
                                              String(e.value)
                                            )
                                          }
                                          onBlur={() =>
                                            localStorage.setItem(
                                              'jobFormData',
                                              JSON.stringify({
                                                ...jobFormData,
                                                ...values,
                                              })
                                            )
                                          }
                                          defaultValue={
                                            (recOptions?.length > 0 &&
                                              recOptions
                                                .map((item: any) => ({
                                                  label: item.label,
                                                  value: item.value,
                                                }))
                                                .filter(
                                                  (val: any) =>
                                                    val.value ===
                                                    Number(
                                                      values.primary_recruiter
                                                    )
                                                )[0]) ||
                                            values.primary_recruiter
                                          }
                                          options={
                                            recOptions?.length > 0 &&
                                            recOptions.map((item: any) => ({
                                              label: item.label,
                                              value: item.value,
                                            }))
                                          }
                                        />
                                        <FormErrorMessage>
                                          {String(errors.primary_recruiter)}
                                        </FormErrorMessage>
                                      </FormControl>
                                    </Flex>
                                    {/* <Flex gap="32px" mb="34px"> */}
                                    {/* <FormControl
                                      isInvalid={Boolean(
                                        !!errors.created_by &&
                                        touched.created_by
                                      )}
                                    >
                                      <FormLabel>
                                        Created By{' '}
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>

                                      <AtsSelect
                                        name="created_by"
                                        id="created_by"
                                        placeholder="Select"
                                        variant="outline"
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            'created_by',
                                            String(e.value)
                                          )
                                        }
                                        onBlur={() =>
                                          localStorage.setItem(
                                            'jobFormData',
                                            JSON.stringify({
                                              ...jobFormData,
                                              ...values,
                                            })
                                          )
                                        }
                                        defaultValue={
                                          (recOptions?.length > 0 &&
                                            recOptions
                                              .map((item: any) => ({
                                                label: item.label,
                                                value: item.value,
                                              }))
                                              .filter(
                                                (val: any) =>
                                                  val.value ===
                                                  Number(values.created_by)
                                              )[0]) ||
                                          values.created_by
                                        }
                                        options={
                                          recOptions?.length > 0 &&
                                          recOptions.map((item: any) => ({
                                            label: item.label,
                                            value: item.value,
                                          }))
                                        }
                                      />
                                      <FormErrorMessage>
                                        {String(errors.created_by)}
                                      </FormErrorMessage>
                                    </FormControl> */}

                                    {/* </Flex> */}
                                  </>
                                )}
                                <Flex gap="32px" mb="34px">
                                  <FormControl
                                    isInvalid={Boolean(
                                      !!errors.title && touched.title
                                    )}
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                      id="job_title_label"
                                    >
                                      Internal Job Title{' '}
                                      <Box as="span" color="caution.800">
                                        *
                                      </Box>
                                    </FormLabel>
                                    <Input
                                      autoFocus
                                      id="title"
                                      name="title"
                                      type="text"
                                      placeholder="Job Title"
                                      variant="outline"
                                      value={values.title}
                                      onChange={handleChange}
                                      onBlur={() =>
                                        localStorage.setItem(
                                          'jobFormData',
                                          JSON.stringify({
                                            ...jobFormData,
                                            ...values,
                                          })
                                        )
                                      }
                                    />
                                    <FormErrorMessage>
                                      {String(errors.title)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  {hasJobPostType !== '2' && (
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.opening && touched.opening
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        No. of Openings{' '}
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>
                                      <Input
                                        id="opening"
                                        name="opening"
                                        type="number"
                                        min={1}
                                        placeholder="Ex: 1"
                                        variant="outline"
                                        value={values.opening}
                                        onChange={handleChange}
                                        onBlur={() =>
                                          localStorage.setItem(
                                            'jobFormData',
                                            JSON.stringify({
                                              ...jobFormData,
                                              ...values,
                                            })
                                          )
                                        }
                                      />
                                      <FormErrorMessage>
                                        {String(errors.opening)}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Flex>

                                <Flex gap="32px" mb="34px">
                                  <FormControl
                                    isInvalid={Boolean(
                                      !!errors.experience_level &&
                                        touched.experience_level
                                    )}
                                  >
                                    <FormLabel
                                      fontSize="14px"
                                      lineHeight="18px"
                                      color="default.secondarytext"
                                    >
                                      Experience Level{' '}
                                      <Box as="span" color="caution.800">
                                        *
                                      </Box>
                                    </FormLabel>

                                    <AtsSelect
                                      id="experience_level"
                                      name="experience_level"
                                      placeholder="Select"
                                      onChange={(e: any) =>
                                        setFieldValue(
                                          'experience_level',
                                          String(e.value)
                                        )
                                      }
                                      onBlur={() =>
                                        localStorage.setItem(
                                          'jobFormData',
                                          JSON.stringify({
                                            ...jobFormData,
                                            ...values,
                                          })
                                        )
                                      }
                                      defaultValue={
                                        (experience_level?.length > 0 &&
                                          experience_level
                                            .map((item: any) => ({
                                              label: item.label,
                                              value: item.id,
                                            }))
                                            .filter(
                                              (val: any) =>
                                                val.value ===
                                                Number(values.experience_level)
                                            )[0]) ||
                                        values.experience_level
                                      }
                                      options={
                                        experience_level?.length > 0 &&
                                        experience_level.map((item: any) => ({
                                          label: item.label,
                                          value: item.id,
                                        }))
                                      }
                                    />
                                    <FormErrorMessage>
                                      {String(errors.experience_level)}
                                    </FormErrorMessage>
                                  </FormControl>
                                  {hasJobPostType !== '2' ? (
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.education_id &&
                                          touched.education_id
                                      )}
                                    >
                                      <FormLabel
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Education
                                      </FormLabel>

                                      <AtsSelect
                                        id="education_id"
                                        name="education_id"
                                        placeholder="Select"
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            'education_id',
                                            String(e.value)
                                          )
                                        }
                                        onBlur={() =>
                                          localStorage.setItem(
                                            'jobFormData',
                                            JSON.stringify({
                                              ...jobFormData,
                                              ...values,
                                            })
                                          )
                                        }
                                        defaultValue={
                                          (educations?.length > 0 &&
                                            educations
                                              .map((item: any) => ({
                                                label: item.name,
                                                value: item.id,
                                              }))
                                              .filter(
                                                (val: any) =>
                                                  val.value ===
                                                  Number(values.education_id)
                                              )[0]) ||
                                          values.education_id
                                        }
                                        options={
                                          educations?.length > 0 &&
                                          educations.map((item: any) => ({
                                            label: item.name,
                                            value: item.id,
                                          }))
                                        }
                                      />
                                      <FormErrorMessage>
                                        {String(errors.education_id)}
                                      </FormErrorMessage>
                                    </FormControl>
                                  ) : (
                                    <FormControl></FormControl>
                                  )}
                                </Flex>

                                {hasJobPostType !== '2' && (
                                  <>
                                    <Flex gap="32px" mb="34px" width="100%">
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!errors.work_type_id &&
                                            touched.work_type_id
                                        )}
                                      >
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Work Type{' '}
                                          <Box as="span" color="caution.800">
                                            *
                                          </Box>
                                        </FormLabel>

                                        <AtsSelect
                                          id="work_type_id"
                                          name="work_type_id"
                                          placeholder="Select"
                                          variant="outline"
                                          defaultValue={
                                            work_types
                                              .map((item: any) => ({
                                                label: item.name,
                                                value: item.id,
                                              }))
                                              .filter(
                                                (val: any) =>
                                                  val.value ===
                                                  Number(values.work_type_id)
                                              )[0] || values.work_type_id
                                          }
                                          onChange={(e: any) =>
                                            setFieldValue(
                                              'work_type_id',
                                              String(e.value)
                                            )
                                          }
                                          onBlur={() =>
                                            localStorage.setItem(
                                              'jobFormData',
                                              JSON.stringify({
                                                ...jobFormData,
                                                ...values,
                                              })
                                            )
                                          }
                                          options={
                                            work_types?.length > 0 &&
                                            work_types.map((item: any) => ({
                                              label: item.name,
                                              value: item.id,
                                            }))
                                          }
                                        />
                                        <FormErrorMessage>
                                          {String(errors.work_type_id)}
                                        </FormErrorMessage>
                                      </FormControl>
                                      <FormControl
                                        isInvalid={Boolean(
                                          !!errors.client_job_link &&
                                            touched.client_job_link
                                        )}
                                        width="100%"
                                      >
                                        <FormLabel
                                          fontSize="14px"
                                          lineHeight="18px"
                                          color="default.secondarytext"
                                        >
                                          Client Job Link{' '}
                                        </FormLabel>
                                        <Flex
                                          width="100%"
                                          direction="row"
                                          alignItems="center"
                                          alignSelf="stretch"
                                        >
                                          <Flex alignSelf="stretch">
                                            <Flex alignItems="center">
                                              <AtsSelect
                                                variant="outline"
                                                menuPosition="fixed"
                                                value={protocol}
                                                onChange={(e: any) => {
                                                  setProtocol(e);
                                                }}
                                                styles={{
                                                  control: (styles: any) => ({
                                                    ...styles,
                                                    width: '110px',
                                                  }),
                                                }}
                                                options={[
                                                  {
                                                    value: 'https://',
                                                    label: 'https://',
                                                  },
                                                  {
                                                    value: 'http://',
                                                    label: 'http://',
                                                  },
                                                ]}
                                                isSearchable={false}
                                              />
                                            </Flex>
                                          </Flex>
                                          <Flex width="100%">
                                            <Flex width="100%">
                                              <Field
                                                as={Input}
                                                value={values.client_job_link}
                                                name="client_job_link"
                                                margin="0px"
                                                padding="0px"
                                                type="text"
                                                onChange={handleChange}
                                                onBlur={(e: any) => {
                                                  setFieldValue(
                                                    'client_job_link',
                                                    e.target.value.replace(
                                                      /(^\w+:|^)\/\//,
                                                      ''
                                                    )
                                                  );
                                                  const { value } = e.target;
                                                  handleProtocol(value);
                                                }}
                                              />
                                            </Flex>
                                          </Flex>
                                        </Flex>
                                        <FormErrorMessage mb="12px">
                                          {String(errors.client_job_link)}
                                        </FormErrorMessage>
                                      </FormControl>
                                    </Flex>
                                    <Box>
                                      <Flex gap="32px" mb="34px">
                                        <FormControl
                                          isInvalid={Boolean(
                                            !!errors.employment_type_id &&
                                              touched.employment_type_id
                                          )}
                                        >
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Employment Type
                                          </FormLabel>

                                          <AtsSelect
                                            id="employment_type_id"
                                            name="employment_type_id"
                                            placeholder="Select"
                                            variant="outline"
                                            defaultValue={
                                              (employment_types?.length > 0 &&
                                                employment_types
                                                  .map((item: any) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                  }))
                                                  .filter(
                                                    (val: any) =>
                                                      val.value ===
                                                      Number(
                                                        values.employment_type_id
                                                      )
                                                  )[0]) ||
                                              values.employment_type_id
                                            }
                                            onChange={(e: any) =>
                                              setFieldValue(
                                                'employment_type_id',
                                                String(e.value)
                                              )
                                            }
                                            onBlur={() =>
                                              localStorage.setItem(
                                                'jobFormData',
                                                JSON.stringify({
                                                  ...jobFormData,
                                                  ...values,
                                                })
                                              )
                                            }
                                            options={
                                              employment_types?.length > 0 &&
                                              employment_types.map(
                                                (item: any) => ({
                                                  label: item.name,
                                                  value: item.id,
                                                })
                                              )
                                            }
                                          />
                                          <FormErrorMessage>
                                            {String(errors.employment_type_id)}
                                          </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                          isInvalid={Boolean(
                                            !!errors.compensation_id &&
                                              touched.compensation_id
                                          )}
                                        >
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Compensation Type
                                          </FormLabel>

                                          <AtsSelect
                                            id="compensation_id"
                                            name="compensation_id"
                                            placeholder="Select"
                                            variant="outline"
                                            // value={values.compensation_id}
                                            defaultValue={
                                              (compensations?.length > 0 &&
                                                compensations
                                                  .map((item: any) => ({
                                                    label: item.name,
                                                    value: item.id,
                                                  }))
                                                  .filter(
                                                    (val: any) =>
                                                      val.value ===
                                                      Number(
                                                        values.compensation_id
                                                      )
                                                  )[0]) ||
                                              values.compensation_id
                                            }
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                'compensation_id',
                                                String(e.value)
                                              );
                                            }}
                                            onBlur={() =>
                                              localStorage.setItem(
                                                'jobFormData',
                                                JSON.stringify({
                                                  ...jobFormData,
                                                  ...values,
                                                })
                                              )
                                            }
                                            options={
                                              compensations?.length > 0 &&
                                              compensations.map(
                                                (item: any) => ({
                                                  label: item.name,
                                                  value: item.id,
                                                })
                                              )
                                            }
                                          />
                                          <FormErrorMessage>
                                            {String(errors.compensation_id)}
                                          </FormErrorMessage>
                                        </FormControl>
                                      </Flex>

                                      <Flex gap="32px" mb="34px">
                                        <FormControl
                                          isInvalid={Boolean(
                                            !!errors.salary_range_start &&
                                              touched.salary_range_start
                                          )}
                                        >
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Salary Range Start
                                          </FormLabel>
                                          <InputGroup>
                                            <InputLeftElement
                                              pointerEvents="none"
                                              children={
                                                <Icon
                                                  as={BsCurrencyDollar}
                                                  color="gray.500"
                                                />
                                              }
                                            />
                                            <Field
                                              as={Input}
                                              id="salary_range_start"
                                              name="salary_range_start"
                                              placeholder="0.00"
                                              type="text"
                                              variant="outline"
                                              textAlign="right"
                                              value={values.salary_range_start}
                                              onChange={(event: any) => {
                                                let { value } = event.target;

                                                // Allow only numeric characters and a dot
                                                value = value.replace(
                                                  /[^0-9.]/g,
                                                  ''
                                                );

                                                // Remove leading zeros
                                                value = value.replace(
                                                  /^0+/g,
                                                  ''
                                                );

                                                // Format the number with commas
                                                const formattedValue =
                                                  Number(
                                                    value
                                                  )?.toLocaleString();

                                                setFieldValue(
                                                  'salary_range_start',
                                                  formattedValue
                                                );
                                              }}
                                              onBlur={() =>
                                                localStorage.setItem(
                                                  'jobFormData',
                                                  JSON.stringify({
                                                    ...jobFormData,
                                                    ...values,
                                                  })
                                                )
                                              }
                                            />
                                          </InputGroup>
                                          <FormErrorMessage>
                                            {String(errors.salary_range_start)}
                                          </FormErrorMessage>
                                        </FormControl>
                                        <FormControl
                                          isInvalid={Boolean(
                                            !!errors.salary_range_end &&
                                              touched.salary_range_end
                                          )}
                                        >
                                          <FormLabel
                                            fontSize="14px"
                                            lineHeight="18px"
                                            color="default.secondarytext"
                                          >
                                            Salary Range End
                                          </FormLabel>
                                          <InputGroup>
                                            <InputLeftElement
                                              pointerEvents="none"
                                              children={
                                                <Icon
                                                  as={BsCurrencyDollar}
                                                  color="gray.500"
                                                />
                                              }
                                            />
                                            <Field
                                              as={Input}
                                              id="salary_range_end"
                                              name="salary_range_end"
                                              placeholder="0.00"
                                              type="string"
                                              variant="outline"
                                              textAlign="right"
                                              value={values.salary_range_end}
                                              onChange={(event: any) => {
                                                let { value } = event.target;

                                                // Allow only numeric characters and a dot
                                                value = value.replace(
                                                  /[^0-9.]/g,
                                                  ''
                                                );

                                                // Remove leading zeros
                                                value = value.replace(
                                                  /^0+/g,
                                                  ''
                                                );

                                                // Format the number with commas
                                                const formattedValue =
                                                  Number(
                                                    value
                                                  )?.toLocaleString();

                                                setFieldValue(
                                                  'salary_range_end',
                                                  formattedValue
                                                );
                                              }}
                                              onBlur={() =>
                                                localStorage.setItem(
                                                  'jobFormData',
                                                  JSON.stringify({
                                                    ...jobFormData,
                                                    ...values,
                                                  })
                                                )
                                              }
                                            />
                                          </InputGroup>
                                          <FormErrorMessage>
                                            {String(errors.salary_range_end)}
                                          </FormErrorMessage>
                                        </FormControl>
                                      </Flex>
                                    </Box>

                                    <Box mb="34px">
                                      <FormLabel
                                        mb="8px"
                                        fontSize="14px"
                                        lineHeight="18px"
                                        color="default.secondarytext"
                                      >
                                        Internal Job Description
                                      </FormLabel>

                                      <AtsEmailBody
                                        id="description"
                                        value={values.description}
                                        onChange={(e: any) => {
                                          setFieldValue('description', e);
                                        }}
                                        refocusTo="title"
                                      />
                                      <Box color="red.700">
                                        <ErrorMessage
                                          name="description"
                                          className="alert alert-danger"
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                )}

                                <Box mb="34px">
                                  <FormLabel
                                    mb="8px"
                                    fontSize="14px"
                                    lineHeight="18px"
                                    color="default.secondarytext"
                                  >
                                    Notes
                                  </FormLabel>

                                  <Field
                                    as={Textarea}
                                    id="notes"
                                    name="notes"
                                    type="text"
                                    placeholder="Type your notes here..."
                                    variant="outline"
                                    value={values.notes}
                                    // sx={{
                                    //   zIndex: 0,
                                    // }}
                                  />
                                </Box>
                              </Box>
                              <ModalFooter
                                position="sticky"
                                bottom="0"
                                background="#F9F9FB"
                                mb="20px"
                                mr="20px"
                                gap="16px"
                              >
                                {String(jobFormData.job_type_id) === '1' &&
                                  tabIndex.id < 4 && (
                                    <Button
                                      disabled={
                                        isOpenAlert ||
                                        resCreateJob.isLoading ||
                                        resCreateQuick.isLoading ||
                                        resUpdateQuick.isLoading ||
                                        resUpdateJob.isLoading
                                      }
                                      loading={
                                        resCreateJob.isLoading ||
                                        resCreateQuick.isLoading ||
                                        resUpdateQuick.isLoading ||
                                        resUpdateJob.isLoading
                                      }
                                      mr={3}
                                      onClick={() => {
                                        setIsDraft(true);
                                        console.log(errors);
                                        handleSubmit();
                                        !isValid && onOpen();
                                      }}
                                    >
                                      Save Draft
                                    </Button>
                                  )}
                                {tabIndex.id > 0 && (
                                  <Button
                                    disabled={isOpenAlert}
                                    mr={3}
                                    onClick={() => {
                                      tabIndex.id > 0 &&
                                        setTabIndex(tabList[tabIndex.id - 1]);
                                      setJobFormData((prev: any) => ({
                                        ...prev,
                                        ...values,
                                      }));
                                    }}
                                  >
                                    Previous
                                  </Button>
                                )}
                                {((String(jobFormData.job_type_id) === '1' &&
                                  tabIndex.id < 4) ||
                                  (String(jobFormData.job_type_id) === '2' &&
                                    tabIndex.id < 2)) && (
                                  <Button
                                    variant="solid"
                                    disabled={isOpenAlert}
                                    onClick={(e: any) => {
                                      setIsDraft(false);
                                      handleSubmit(e);
                                    }}
                                  >
                                    Next
                                  </Button>
                                )}
                                {/* {((String(jobFormData.job_type_id) === '1' &&
                                  tabIndex.id === 4) ||
                                  (String(jobFormData.job_type_id) === '2' &&
                                    tabIndex.id === 2)) && (
                                    <Button
                                      variant="solid"
                                      onClick={() => {
                                        setValues({
                                          ...jobFormData,
                                          ...values,
                                        });
                                        console.log(errors);
                                        setErrorList(errors);
                                        values.job_status_id = 1;
                                        handleSubmit();
                                        !isValid && onOpen();
                                      }}
                                      disabled={isOpenAlert}
                                      zIndex={1}
                                    >
                                      {modalTitle === 'Edit Job'
                                        ? 'Update'
                                        : 'Save'}
                                    </Button>
                                  )} */}
                              </ModalFooter>
                            </form>
                          )}
                        </Formik>
                      )}
                      {tabIndex.id === 2 &&
                        (hasJobPostType === '2' ? (
                          <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(data: any) => {
                              onSubmit({
                                ...jobFormData,
                                status: jobStatus,
                                lead_id: data.lead_id,
                                email_subject: data.email_subject,
                                email_content: data.email_content,
                                job_status_id: 1,
                              });
                            }}
                            enableReinitialize={true}
                          >
                            {({
                              values,
                              handleChange,
                              handleSubmit,
                              errors,
                              touched,
                              isValid,
                              setFieldValue,
                              setValues,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <ScrollToFieldError />
                                <Box
                                  overflow="auto"
                                  maxHeight="450px"
                                  p="30px"
                                  background="#fff"
                                  borderRadius="8px"
                                  minHeight="65vh"
                                  width="100%"
                                >
                                  <Tabs colorScheme="purple" width="100%">
                                    <TabList fontSize="md" fontWeight="500">
                                      <Tab
                                        _focus={{
                                          boxShadow: 'none !important',
                                        }}
                                        width="100%"
                                      >
                                        Compose
                                      </Tab>
                                      <Tab
                                        _focus={{
                                          boxShadow: 'none !important',
                                        }}
                                        width="100%"
                                      >
                                        Preview
                                      </Tab>
                                    </TabList>

                                    <TabPanels mt="10px">
                                      <TabPanel>
                                        <Box>
                                          <Box>
                                            <FormControl
                                              isInvalid={Boolean(
                                                !!errors.email_subject &&
                                                  touched.email_subject
                                              )}
                                              mb="20px"
                                            >
                                              <Flex
                                                alignItems="center"
                                                // alignContent="space-between"
                                                justifyContent="space-between"
                                                mb="8px"
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                  margin="0px"
                                                >
                                                  Email Subject
                                                  <Box
                                                    as="span"
                                                    color="caution.800"
                                                  >
                                                    *
                                                  </Box>
                                                </FormLabel>
                                                <Box>
                                                  <Menu closeOnSelect={true}>
                                                    <MenuButton type="button">
                                                      <Button
                                                        type="button"
                                                        htmlType="menu"
                                                        size="sm"
                                                      >
                                                        <Text fontSize="15px">
                                                          {'{{ }}'}
                                                        </Text>
                                                      </Button>
                                                    </MenuButton>
                                                    <MenuList
                                                      fontSize="sm"
                                                      maxHeight="250px"
                                                      overflowY="auto"
                                                      sx={{
                                                        // position: 'relative',
                                                        right: '38px',
                                                        button: {
                                                          background: 'none',
                                                          radius: '6px',
                                                          fontSize: '12px',
                                                          fontWeight: 600,
                                                          lineHeight: '16px',
                                                          color: 'primary.600',
                                                        },
                                                      }}
                                                    >
                                                      {placeholderOptions.map(
                                                        (
                                                          item: string,
                                                          i: number
                                                        ) => {
                                                          return (
                                                            <MenuItem
                                                              id={String(i)}
                                                              key={`placeholder-${item}`}
                                                            >
                                                              <Button
                                                                leftIcon="copy"
                                                                onClick={() =>
                                                                  placeHolderClick(
                                                                    item
                                                                  )
                                                                }
                                                                sx={{
                                                                  height:
                                                                    'fit-content',
                                                                  p: '5px 10px',
                                                                  fontSize:
                                                                    '10px',
                                                                }}
                                                              >
                                                                {item}
                                                              </Button>
                                                            </MenuItem>
                                                          );
                                                        }
                                                      )}
                                                    </MenuList>
                                                  </Menu>
                                                </Box>
                                              </Flex>

                                              <Input
                                                name="email_subject"
                                                type="text"
                                                placeholder="Email Subject"
                                                variant="outline"
                                                value={values.email_subject}
                                                onChange={(e: any) => {
                                                  // setFieldValue('email_subject', placholders(e.target.value));
                                                  setFieldValue(
                                                    'email_subject',
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                              <FormErrorMessage>
                                                {String(errors.email_subject)}
                                              </FormErrorMessage>
                                            </FormControl>

                                            <FormControl
                                              isInvalid={Boolean(
                                                !!errors.email_content &&
                                                  touched.email_content
                                              )}
                                            >
                                              <Flex
                                                height="32px"
                                                // alignContent="center"
                                                alignItems="center"
                                                mb="8px"
                                              >
                                                <FormLabel
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  color="default.secondarytext"
                                                  margin="0px"
                                                >
                                                  Email Content
                                                  <Box
                                                    as="span"
                                                    color="caution.800"
                                                  >
                                                    *
                                                  </Box>
                                                </FormLabel>
                                              </Flex>

                                              <AtsEmailBody
                                                initialValue={''}
                                                value={values.email_content}
                                                onChange={(e: any) => {
                                                  setFieldValue(
                                                    'email_content',
                                                    e
                                                  );
                                                }}
                                              />
                                              <FormErrorMessage>
                                                {String(errors.email_content)}
                                              </FormErrorMessage>
                                            </FormControl>
                                          </Box>
                                        </Box>
                                      </TabPanel>
                                      <TabPanel>
                                        <Box>
                                          <Box>
                                            <Text
                                              fontSize="24px"
                                              fontWeight="bold"
                                            >
                                              {parse(
                                                placholders(
                                                  values.email_subject
                                                ) || ''
                                              )}
                                            </Text>
                                          </Box>
                                          <Divider
                                            m="24px 0 24px 0"
                                            borderColor="gray.200"
                                          />
                                          <Box>
                                            <Box>
                                              {parse(
                                                placholders(
                                                  values.email_content
                                                ) || ''
                                              )}
                                            </Box>
                                          </Box>
                                        </Box>
                                        {/* }
                                    </VStack> */}
                                      </TabPanel>
                                    </TabPanels>
                                  </Tabs>
                                </Box>
                                <ModalFooter
                                  position="sticky"
                                  bottom="0"
                                  background="#F9F9FB"
                                  mb="20px"
                                  mr="20px"
                                  gap="16px"
                                >
                                  {/* <Button
                                      disabled={
                                        isOpenAlert ||
                                        resCreateJob.isLoading ||
                                        resCreateQuick.isLoading ||
                                        resUpdateQuick.isLoading ||
                                        resUpdateJob.isLoading
                                      }
                                      loading={
                                        resCreateJob.isLoading ||
                                        resCreateQuick.isLoading ||
                                        resUpdateQuick.isLoading ||
                                        resUpdateJob.isLoading
                                      }
                                      mr={3}
                                      onClick={() => {
                                        setIsDraft(true);
                                        console.log(errors);
                                        handleSubmit();
                                        !isValid && onOpen();
                                      }}
                                    >
                                      Save Draft
                                    </Button> */}
                                  {tabIndex.id > 0 && (
                                    <Button
                                      disabled={isOpenAlert}
                                      mr={3}
                                      onClick={() => {
                                        tabIndex.id > 0 &&
                                          setTabIndex(tabList[tabIndex.id - 1]);
                                        setJobFormData((prev: any) => ({
                                          ...prev,
                                          ...values,
                                          email_subject: values.email_subject,
                                          email_content: values.email_content,
                                        }));
                                      }}
                                    >
                                      Previous
                                    </Button>
                                  )}
                                  {String(jobFormData.job_type_id) === '2' &&
                                    tabIndex.id === 2 && (
                                      <Button
                                        type="submit"
                                        variant="solid"
                                        loading={
                                          resCreateJob.isLoading ||
                                          resCreateQuick.isLoading ||
                                          resUpdateQuick.isLoading ||
                                          resUpdateJob.isLoading
                                        }
                                        onClick={(e: any) => {
                                          console.log(errors);
                                          handleSubmit(e);
                                          setErrorList(errors);
                                          !isValid && onOpen();
                                        }}
                                        disabled={
                                          isOpenAlert ||
                                          resCreateJob.isLoading ||
                                          resCreateQuick.isLoading ||
                                          resUpdateQuick.isLoading ||
                                          resUpdateJob.isLoading
                                        }
                                      >
                                        {modalTitle === 'Edit Job'
                                          ? 'Update'
                                          : 'Save'}
                                      </Button>
                                    )}
                                </ModalFooter>
                              </form>
                            )}
                          </Formik>
                        ) : (
                          <Formik
                            initialValues={initialRecruitersValues}
                            validationSchema={validationRecruitersSchema}
                            onSubmit={(
                              data,
                              { setSubmitting, validateForm }
                            ) => {
                              if (isDraft === false) {
                                setJobFormData((prev: any) => ({
                                  ...prev,
                                  ...data,
                                }));
                              }
                              setSubmitting(false);
                              validateForm().then((errors) => {
                                if (Object.keys(errors).length === 0) {
                                  if (isDraft === true) {
                                    onSubmit({
                                      ...initialValues,
                                      ...changeToDefault(data),
                                      ...jobFormData,
                                      job_status_id: 5,
                                    });
                                  } else if (
                                    tabList?.length !==
                                    tabIndex.id + 1
                                  ) {
                                    setTabIndex(tabList[tabIndex.id + 1]);
                                  }
                                }
                              });
                            }}
                            enableReinitialize={true}
                          >
                            {({
                              values,
                              handleChange,
                              handleSubmit,
                              errors,
                              touched,
                              isValid,
                              setFieldValue,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <ScrollToFieldError />
                                {/* Recruiting Team */}
                                <Box
                                  overflow="auto"
                                  maxHeight="450px"
                                  p="30px"
                                  background="#fff"
                                  borderRadius="8px"
                                  minHeight="65vh"
                                >
                                  <Flex gap="32px" mb="34px">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.am_id && touched.am_id
                                      )}
                                    >
                                      <FormLabel>
                                        Account Manager{' '}
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>
                                      <AtsSelect
                                        name="am_id"
                                        id="am_id"
                                        placeholder="Select"
                                        variant="outline"
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            'am_id',
                                            String(e.value)
                                          )
                                        }
                                        onBlur={() =>
                                          localStorage.setItem(
                                            'jobFormData',
                                            JSON.stringify({
                                              ...jobFormData,
                                              ...values,
                                            })
                                          )
                                        }
                                        defaultValue={
                                          (aMOption?.length > 0 &&
                                            aMOption
                                              ?.map((item: any) => ({
                                                label: item.label,
                                                value: item.value,
                                              }))
                                              ?.filter(
                                                (val: any) =>
                                                  val.value === values.am_id
                                              )[0]) ||
                                          values.am_id
                                        }
                                        options={
                                          aMOption?.length > 0 &&
                                          aMOption?.map((item: any) => ({
                                            label: item.label,
                                            value: item.value,
                                          }))
                                        }
                                      />
                                      <FormErrorMessage>
                                        {String(errors.am_id)}
                                      </FormErrorMessage>
                                    </FormControl>
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.primary_recruiter &&
                                          touched.primary_recruiter
                                      )}
                                    >
                                      <FormLabel>
                                        Primary Recruiter{' '}
                                        <Box as="span" color="caution.800">
                                          *
                                        </Box>
                                      </FormLabel>
                                      <AtsSelect
                                        name="primary_recruiter"
                                        id="primary_recruiter"
                                        placeholder="Select"
                                        variant="outline"
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            'primary_recruiter',
                                            String(e.value)
                                          )
                                        }
                                        onBlur={() =>
                                          localStorage.setItem(
                                            'jobFormData',
                                            JSON.stringify({
                                              ...jobFormData,
                                              ...values,
                                            })
                                          )
                                        }
                                        defaultValue={
                                          (recOptions?.length > 0 &&
                                            recOptions
                                              .map((item: any) => ({
                                                label: item.label,
                                                value: item.value,
                                              }))
                                              .filter(
                                                (val: any) =>
                                                  val.value ===
                                                  Number(
                                                    values.primary_recruiter
                                                  )
                                              )[0]) ||
                                          values.primary_recruiter
                                        }
                                        options={
                                          recOptions?.length > 0 &&
                                          recOptions.map((item: any) => ({
                                            label: item.label,
                                            value: item.value,
                                          }))
                                        }
                                      />
                                      <FormErrorMessage>
                                        {String(errors.primary_recruiter)}
                                      </FormErrorMessage>
                                    </FormControl>
                                  </Flex>
                                  <FieldArray name="recruiters">
                                    {({ remove, push }) => (
                                      <div>
                                        {values.recruiters?.length > 0 &&
                                          values.recruiters.map(
                                            (recruiter: any, index: number) => {
                                              const recruiter_name = `recruiters[${index}].name`;
                                              const split_name = `recruiters[${index}].split_percentage`;
                                              const post_new_job_ads = `recruiters[${index}]post_new_job_ads`;
                                              const no_submittal_review = `recruiters[${index}]no_submittal_review`;
                                              const review_send_as_client_owner = `recruiters[${index}]review_send_as_client_owner`;
                                              const review_send_as_client_recruiter = `recruiters[${index}]review_send_as_client_recruiter`;
                                              const interview_send_as_client_owner = `recruiters[${index}]interview_send_as_client_owner`;
                                              const interview_send_as_recruiter = `recruiters[${index}]interview_send_as_recruiter`;
                                              return (
                                                <>
                                                  <Box
                                                    className="row"
                                                    key={`recr-${index}`}
                                                  >
                                                    <FormControl mb="34px">
                                                      <Flex
                                                        justifyContent="space-between"
                                                        alignItems="flex-end"
                                                      >
                                                        <FormLabel
                                                          fontSize="14px"
                                                          lineHeight="18px"
                                                          color="default.secondarytext"
                                                        >
                                                          Recruiter
                                                        </FormLabel>
                                                        {values.recruiters
                                                          ?.length > 0 && (
                                                          <Box mb="10px">
                                                            <Button
                                                              leftIcon="close"
                                                              onClick={() =>
                                                                remove(index)
                                                              }
                                                              size="sm"
                                                            >
                                                              Remove
                                                            </Button>
                                                          </Box>
                                                        )}
                                                      </Flex>

                                                      <AtsSelect
                                                        name={recruiter_name}
                                                        id={recruiter_name}
                                                        placeholder="Select"
                                                        variant="outline"
                                                        onChange={(e: any) =>
                                                          setFieldValue(
                                                            recruiter_name,
                                                            String(e.value)
                                                          )
                                                        }
                                                        onBlur={() =>
                                                          localStorage.setItem(
                                                            'jobFormData',
                                                            JSON.stringify({
                                                              ...jobFormData,
                                                              ...values,
                                                            })
                                                          )
                                                        }
                                                        defaultValue={
                                                          (recOptions?.length >
                                                            0 &&
                                                            recOptions
                                                              .map(
                                                                (
                                                                  item: any
                                                                ) => ({
                                                                  label:
                                                                    item.label,
                                                                  value:
                                                                    item.value,
                                                                })
                                                              )
                                                              .filter(
                                                                (val: any) =>
                                                                  val.value ===
                                                                  Number(
                                                                    recruiter.name
                                                                  )
                                                              )[0]) ||
                                                          recruiter.name
                                                        }
                                                        options={
                                                          recOptions?.length >
                                                            0 &&
                                                          recOptions.map(
                                                            (item: any) => ({
                                                              label: item.label,
                                                              value: item.value,
                                                            })
                                                          )
                                                        }
                                                      />
                                                      <FormErrorMessage>
                                                        {String(recruiter_name)}
                                                      </FormErrorMessage>
                                                    </FormControl>

                                                    <Flex mb="34px">
                                                      <FormControl>
                                                        <FormLabel
                                                          fontSize="14px"
                                                          lineHeight="18px"
                                                          color="default.secondarytext"
                                                        >
                                                          Select split
                                                          percentage{' '}
                                                          <Box
                                                            as="span"
                                                            color="caution.800"
                                                          >
                                                            *
                                                          </Box>
                                                        </FormLabel>
                                                        <Field
                                                          as={Input}
                                                          id={split_name}
                                                          name={split_name}
                                                          placeholder="Split Percentage"
                                                          type="number"
                                                          variant="outline"
                                                          max={100}
                                                          min={0}
                                                          value={
                                                            recruiter.split_percentage
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            handleChange(e);
                                                            localStorage.setItem(
                                                              'jobFormData',
                                                              JSON.stringify({
                                                                ...jobFormData,
                                                                ...values,
                                                              })
                                                            );
                                                          }}
                                                        />

                                                        <FormErrorMessage>
                                                          {String(split_name)}
                                                        </FormErrorMessage>
                                                      </FormControl>
                                                    </Flex>

                                                    <Box mb="20px">
                                                      <Checkbox
                                                        id={post_new_job_ads}
                                                        name={post_new_job_ads}
                                                        isChecked={
                                                          recruiter.post_new_job_ads
                                                        }
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          localStorage.setItem(
                                                            'jobFormData',
                                                            JSON.stringify({
                                                              ...jobFormData,
                                                              ...values,
                                                            })
                                                          );
                                                        }}
                                                      >
                                                        Allow recruiter to post
                                                        new jobs ads
                                                      </Checkbox>
                                                    </Box>

                                                    <Box mb="20px">
                                                      <FormLabel
                                                        fontSize="14px"
                                                        lineHeight="18px"
                                                        color="default.secondarytext"
                                                        mb="16px"
                                                      >
                                                        Submittal Review
                                                      </FormLabel>

                                                      <Box mb="10px">
                                                        <Checkbox
                                                          id={
                                                            no_submittal_review
                                                          }
                                                          name={
                                                            no_submittal_review
                                                          }
                                                          isChecked={
                                                            recruiter.no_submittal_review
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            handleChange(e);
                                                            localStorage.setItem(
                                                              'jobFormData',
                                                              JSON.stringify({
                                                                ...jobFormData,
                                                                ...values,
                                                              })
                                                            );
                                                          }}
                                                        >
                                                          No submittal review
                                                        </Checkbox>
                                                      </Box>
                                                      <Box mb="10px">
                                                        <Checkbox
                                                          id={
                                                            review_send_as_client_owner
                                                          }
                                                          name={
                                                            review_send_as_client_owner
                                                          }
                                                          isChecked={
                                                            recruiter.review_send_as_client_owner
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            handleChange(e);
                                                            localStorage.setItem(
                                                              'jobFormData',
                                                              JSON.stringify({
                                                                ...jobFormData,
                                                                ...values,
                                                              })
                                                            );
                                                          }}
                                                        >
                                                          Review submittal and
                                                          send as client owner
                                                        </Checkbox>
                                                      </Box>
                                                      <Box mb="10px">
                                                        <Checkbox
                                                          id={
                                                            review_send_as_client_recruiter
                                                          }
                                                          name={
                                                            review_send_as_client_recruiter
                                                          }
                                                          isChecked={
                                                            recruiter.review_send_as_client_recruiter
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            handleChange(e);
                                                            localStorage.setItem(
                                                              'jobFormData',
                                                              JSON.stringify({
                                                                ...jobFormData,
                                                                ...values,
                                                              })
                                                            );
                                                          }}
                                                        >
                                                          Review submittal and
                                                          send as recruiter
                                                        </Checkbox>
                                                      </Box>
                                                    </Box>

                                                    <Box mb="20px">
                                                      <FormLabel
                                                        fontSize="14px"
                                                        lineHeight="18px"
                                                        color="default.secondarytext"
                                                        mb="16px"
                                                      >
                                                        Interview Settings
                                                      </FormLabel>

                                                      <Box mb="10px">
                                                        <Checkbox
                                                          id={
                                                            interview_send_as_client_owner
                                                          }
                                                          name={
                                                            interview_send_as_client_owner
                                                          }
                                                          isChecked={
                                                            recruiter.interview_send_as_client_owner
                                                          }
                                                          onChange={(
                                                            e: any
                                                          ) => {
                                                            handleChange(e);
                                                            localStorage.setItem(
                                                              'jobFormData',
                                                              JSON.stringify({
                                                                ...jobFormData,
                                                                ...values,
                                                              })
                                                            );
                                                          }}
                                                        >
                                                          Send interview as
                                                          client owner
                                                        </Checkbox>
                                                      </Box>
                                                      <Box mb="10px">
                                                        <Checkbox
                                                          id={
                                                            interview_send_as_recruiter
                                                          }
                                                          name={
                                                            interview_send_as_recruiter
                                                          }
                                                          isChecked={
                                                            recruiter.interview_send_as_recruiter
                                                          }
                                                          onChange={
                                                            handleChange
                                                          }
                                                          onBlur={() =>
                                                            localStorage.setItem(
                                                              'jobFormData',
                                                              JSON.stringify({
                                                                ...jobFormData,
                                                                ...values,
                                                              })
                                                            )
                                                          }
                                                        >
                                                          Send interview as
                                                          recruiter
                                                        </Checkbox>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Divider mb="50px" />
                                                </>
                                              );
                                            }
                                          )}
                                        <Button
                                          leftIcon="plus"
                                          onClick={() =>
                                            push({
                                              name: '',
                                              split_percentage: null,
                                              post_new_job_ads: false,
                                              no_submittal_review: false,
                                              review_send_as_client_owner:
                                                false,
                                              review_send_as_client_recruiter:
                                                false,
                                              interview_send_as_client_owner:
                                                false,
                                              interview_send_as_recruiter:
                                                false,
                                            })
                                          }
                                        >
                                          {values.recruiters?.length > 0
                                            ? 'Add Another Recruiter'
                                            : 'Add Recruiter'}
                                        </Button>
                                      </div>
                                    )}
                                  </FieldArray>
                                </Box>
                                <ModalFooter
                                  position="sticky"
                                  bottom="0"
                                  background="#F9F9FB"
                                  mb="20px"
                                  mr="20px"
                                  gap="16px"
                                >
                                  <Button
                                    disabled={
                                      isOpenAlert ||
                                      resCreateJob.isLoading ||
                                      resCreateQuick.isLoading ||
                                      resUpdateQuick.isLoading ||
                                      resUpdateJob.isLoading
                                    }
                                    loading={
                                      resCreateJob.isLoading ||
                                      resCreateQuick.isLoading ||
                                      resUpdateQuick.isLoading ||
                                      resUpdateJob.isLoading
                                    }
                                    mr={3}
                                    onClick={() => {
                                      setIsDraft(true);
                                      console.log(errors);
                                      handleSubmit();
                                      !isValid && onOpen();
                                    }}
                                  >
                                    Save Draft
                                  </Button>
                                  {tabIndex.id > 0 && (
                                    <Button
                                      disabled={isOpenAlert}
                                      mr={3}
                                      onClick={() => {
                                        tabIndex.id > 0 &&
                                          setTabIndex(tabList[tabIndex.id - 1]);
                                        setJobFormData((prev: any) => ({
                                          ...prev,
                                          ...values,
                                        }));
                                      }}
                                    >
                                      Previous
                                    </Button>
                                  )}
                                  {((String(jobFormData.job_type_id) === '1' &&
                                    tabIndex.id < 4) ||
                                    (String(jobFormData.job_type_id) === '2' &&
                                      tabIndex.id < 2)) && (
                                    <Button
                                      variant="solid"
                                      disabled={isOpenAlert}
                                      onClick={(e: any) => {
                                        setIsDraft(false);
                                        handleSubmit(e);
                                      }}
                                    >
                                      Next
                                    </Button>
                                  )}
                                </ModalFooter>
                              </form>
                            )}
                          </Formik>
                        ))}
                      {tabIndex.id === 3 && (
                        <Formik
                          initialValues={initialJobAdsValues}
                          validationSchema={validationJobAdsSchema}
                          onSubmit={(data, { setSubmitting, validateForm }) => {
                            let url =
                              'https://careers.talently.com/job-details/';
                            let job_name_url = '';
                            if (jobId) {
                              const ads = jobData?.jobAds[0];
                              if (ads) {
                                url = `${CAREER_PAGE_URL}/job-details/${ads?.id}`;
                                job_name_url = `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${data.job_ads[0].title}</a>`;
                              }
                            } else {
                              job_name_url = `<a href="${url}" style="color: blue; text-decoration: underline" target="_blank">${data.job_ads[0].title}</a>`;
                            }

                            const pairTemplate = {
                              ...jobPairTemplate,
                              job_name_url: job_name_url,
                            };
                            jobPairing(pairTemplate);
                            if (isDraft === false) {
                              setJobFormData((prev: any) => ({
                                ...prev,
                                ...data,
                              }));
                            }
                            setSubmitting(false);
                            validateForm().then((errors) => {
                              if (Object.keys(errors).length === 0) {
                                if (isDraft === true) {
                                  onSubmit({
                                    ...initialValues,
                                    ...changeToDefault(data),
                                    ...jobFormData,
                                    // job_ads: [
                                    //   {
                                    //     ...data.job_ads[0],
                                    //     // country: data.job_ads[0].country.label,
                                    //   },
                                    // ],
                                    job_status_id: 5,
                                  });
                                } else if (
                                  tabList?.length !==
                                  tabIndex.id + 1
                                ) {
                                  setTabIndex(tabList[tabIndex.id + 1]);
                                }
                              }
                            });
                          }}
                          enableReinitialize={true}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            isValid,
                            setFieldValue,
                            setValues,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <ScrollToFieldError />
                              {/* Job Ads */}
                              <Box
                                overflow="auto"
                                maxHeight="450px"
                                p="30px"
                                background="#fff"
                                borderRadius="8px"
                                minHeight="65vh"
                              >
                                <FieldArray name="job_ads">
                                  {() => (
                                    <div>
                                      {values.job_ads?.length > 0 &&
                                        values.job_ads.map(
                                          (job_ad: any, index: number) => (
                                            <>
                                              <Box
                                                className="row"
                                                key={`jas-${index}`}
                                              >
                                                <FormControl
                                                  mb="34px"
                                                  isInvalid={Boolean(
                                                    !!(
                                                      errors.job_ads as any[]
                                                    )?.[index]?.title &&
                                                      (
                                                        touched.job_ads as any[]
                                                      )?.[index]?.title
                                                  )}
                                                >
                                                  <Flex
                                                    justifyContent="space-between"
                                                    alignItems="flex-end"
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      Posting Title{' '}
                                                      <Box
                                                        as="span"
                                                        color="caution.800"
                                                      >
                                                        *
                                                      </Box>
                                                    </FormLabel>
                                                  </Flex>
                                                  <Field
                                                    autoFocus
                                                    as={Input}
                                                    id={`job_ads.${index}.title`}
                                                    name={`job_ads.${index}.title`}
                                                    placeholder="Posting Title"
                                                    type="text"
                                                    variant="outline"
                                                    value={job_ad.title}
                                                    onChange={handleChange}
                                                    onBlur={() =>
                                                      localStorage.setItem(
                                                        'jobFormData',
                                                        JSON.stringify({
                                                          ...jobFormData,
                                                          ...values,
                                                        })
                                                      )
                                                    }
                                                  />
                                                  <FormErrorMessage>
                                                    {String(
                                                      (
                                                        errors.job_ads as any[]
                                                      )?.[index]?.title
                                                    )}
                                                  </FormErrorMessage>
                                                </FormControl>
                                                <Flex gap="32px" mb="34px">
                                                  <FormControl>
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      Work Type
                                                    </FormLabel>

                                                    <AtsSelect
                                                      name={`job_ads.${index}.worktype_id`}
                                                      id={`job_ads.${index}.worktype_id`}
                                                      placeholder="Select"
                                                      variant="outline"
                                                      defaultValue={
                                                        work_types
                                                          .map((item: any) => ({
                                                            label: item.name,
                                                            value: item.id,
                                                          }))
                                                          .filter(
                                                            (val: any) =>
                                                              val.value ===
                                                              Number(
                                                                job_ad.worktype_id
                                                              )
                                                          )[0] ||
                                                        job_ad.worktype_id
                                                      }
                                                      onChange={(e: any) =>
                                                        setFieldValue(
                                                          `job_ads[${index}].worktype_id`,
                                                          String(e.value)
                                                        )
                                                      }
                                                      onBlur={() =>
                                                        localStorage.setItem(
                                                          'jobFormData',
                                                          JSON.stringify({
                                                            ...jobFormData,
                                                            ...values,
                                                          })
                                                        )
                                                      }
                                                      options={
                                                        work_types?.length >
                                                          0 &&
                                                        work_types.map(
                                                          (item: any) => ({
                                                            label: item.name,
                                                            value: item.id,
                                                          })
                                                        )
                                                      }
                                                    />
                                                    <FormErrorMessage>
                                                      {String(
                                                        `job_ads${index}.worktype_id`
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                  <FormControl
                                                    isInvalid={Boolean(
                                                      !!(
                                                        errors.job_ads as any[]
                                                      )?.[index]?.city &&
                                                        (
                                                          touched.job_ads as any[]
                                                        )?.[index]?.city
                                                    )}
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      City
                                                    </FormLabel>

                                                    <AtsAutoComplete
                                                      name={`job_ads.${index}.city`}
                                                      placeholder="City"
                                                      type="text"
                                                      variant="outline"
                                                      value={job_ad?.city}
                                                      onChange={(e: any) => {
                                                        setFieldValue(
                                                          `job_ads.${index}.city`,
                                                          e.target.value
                                                        );
                                                      }}
                                                      onFocus={() =>
                                                        setName('city')
                                                      }
                                                      options={itemLocation}
                                                      onKeyUp={(e: any) =>
                                                        geoLocationChange(
                                                          e,
                                                          'city'
                                                        )
                                                      }
                                                      isLoading={
                                                        resGeo.isLoading
                                                      }
                                                      onSelect={(item: any) => {
                                                        setFieldValue(
                                                          `job_ads.${index}.city`,
                                                          item.value
                                                        );

                                                        setFieldValue(
                                                          `job_ads.${index}.state`,
                                                          item.state
                                                        );
                                                        setFieldValue(
                                                          `job_ads.${index}.city`,
                                                          item.city
                                                        );
                                                        setFieldValue(
                                                          `job_ads.${index}.country`,
                                                          (countries?.length >
                                                            0 &&
                                                            countries
                                                              .map(
                                                                (
                                                                  item: any
                                                                ) => ({
                                                                  label:
                                                                    item.country,
                                                                  value:
                                                                    item.id,
                                                                  code: item.code,
                                                                })
                                                              )
                                                              .filter(
                                                                (val: any) =>
                                                                  val.label ===
                                                                  item.country
                                                              ))[0]
                                                        );

                                                        localStorage.setItem(
                                                          'jobFormData',
                                                          JSON.stringify({
                                                            ...jobFormData,
                                                            ...values,
                                                          })
                                                        );
                                                      }}
                                                    />

                                                    <FormErrorMessage>
                                                      {String(
                                                        (
                                                          errors.job_ads as any[]
                                                        )?.[index]?.city
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                </Flex>
                                                <Flex gap="32px" mb="34px">
                                                  <FormControl
                                                    isInvalid={Boolean(
                                                      !!(
                                                        errors.job_ads as any[]
                                                      )?.[index]?.state &&
                                                        (
                                                          touched.job_ads as any[]
                                                        )?.[index]?.state
                                                    )}
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      State / Province
                                                    </FormLabel>
                                                    <Input
                                                      id={`job_ads.${index}.state`}
                                                      name={`job_ads.${index}.state`}
                                                      type="text"
                                                      placeholder="State / Province"
                                                      variant="outline"
                                                      value={job_ad?.state}
                                                      onChange={handleChange}
                                                    />
                                                    <FormErrorMessage>
                                                      {String(
                                                        (
                                                          errors.job_ads as any[]
                                                        )?.[index]?.state
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                  <FormControl
                                                    isInvalid={Boolean(
                                                      !!(
                                                        errors.job_ads as any[]
                                                      )?.[index]?.country &&
                                                        (
                                                          touched.job_ads as any[]
                                                        )?.[index]?.country
                                                    )}
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      Country
                                                    </FormLabel>
                                                    <Field
                                                      id={`job_ads.${index}.country`}
                                                      name={`job_ads.${index}.country`}
                                                      options={countryOption}
                                                      component={AtsSelect}
                                                      placeholder="Country"
                                                      value={
                                                        job_ad?.country ||
                                                        (countryOption.length >
                                                          0 &&
                                                          countryOption
                                                            .map(
                                                              (item: any) => ({
                                                                label:
                                                                  item.label,
                                                                value:
                                                                  item.value,
                                                              })
                                                            )
                                                            .filter(
                                                              (val: any) =>
                                                                val.label ===
                                                                job_ad?.country
                                                            ))
                                                      }
                                                      onChange={(e: any) => {
                                                        setFieldValue(
                                                          `job_ads.${index}.country`,
                                                          e
                                                        );
                                                      }}
                                                    />
                                                    <FormErrorMessage>
                                                      {String(
                                                        (
                                                          errors.job_ads as any[]
                                                        )?.[index]?.country
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                </Flex>
                                                <Box mb="34px">
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                    mb="16px"
                                                  >
                                                    Publish On
                                                  </FormLabel>

                                                  <Flex gap="32px">
                                                    <Flex flexDir="column" gap="5px">
                                                      <Checkbox
                                                        id={`job_ads.${index}.career_page_publish`}
                                                        name={`job_ads.${index}.career_page_publish`}
                                                        isChecked={
                                                          job_ad.career_page_publish
                                                        }
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          localStorage.setItem(
                                                            'jobFormData',
                                                            JSON.stringify({
                                                              ...jobFormData,
                                                              ...values,
                                                            })
                                                          );
                                                        }}
                                                      >
                                                        Careers Page
                                                      </Checkbox>
                                                      <Checkbox
                                                        id={`job_ads.${index}.show_profile`}
                                                        name={`job_ads.${index}.show_profile`}
                                                        isChecked={
                                                          job_ad.show_profile
                                                        }
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          localStorage.setItem(
                                                            'jobFormData',
                                                            JSON.stringify({
                                                              ...jobFormData,
                                                              ...values,
                                                            })
                                                          );
                                                        }}
                                                      >
                                                        Show Profile on Careers
                                                        page
                                                      </Checkbox>
                                                    </Flex>
                                                    <Box>
                                                      <Checkbox
                                                        id={`job_ads.${index}.free_job_board_publish`}
                                                        name={`job_ads.${index}.free_job_board_publish`}
                                                        isChecked={
                                                          job_ad.free_job_board_publish
                                                        }
                                                        onChange={(e: any) => {
                                                          handleChange(e);
                                                          localStorage.setItem(
                                                            'jobFormData',
                                                            JSON.stringify({
                                                              ...jobFormData,
                                                              ...values,
                                                            })
                                                          );
                                                        }}
                                                      >
                                                        Free Job boards
                                                      </Checkbox>
                                                    </Box>
                                                    <Box>
                                                      <Checkbox
                                                        id={`job_ads.${index}.paid_job_board_publish`}
                                                        name={`job_ads.${index}.paid_job_board_publish`}
                                                        isChecked={
                                                          job_ad.paid_job_board_publish
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={() =>
                                                          localStorage.setItem(
                                                            'jobFormData',
                                                            JSON.stringify({
                                                              ...jobFormData,
                                                              ...values,
                                                            })
                                                          )
                                                        }
                                                      >
                                                        Paid Job boards
                                                      </Checkbox>
                                                    </Box>
                                                  </Flex>
                                                </Box>

                                                <Box mb="34px">
                                                  <FormLabel
                                                    mb="8px"
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    Job Description
                                                  </FormLabel>
                                                  <AtsEmailBody
                                                    id={`job_ads.${index}.description`}
                                                    value={job_ad.description}
                                                    onChange={(e: any) => {
                                                      setFieldValue(
                                                        `job_ads.${index}.description`,
                                                        e
                                                      );
                                                    }}
                                                    refocusTo={`job_ads.${index}.title`}
                                                  />
                                                  <Box color="red.700">
                                                    <ErrorMessage
                                                      name={`job_ads.${index}.description`}
                                                      className="alert alert-danger"
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Divider mb="50px" />
                                            </>
                                          )
                                        )}
                                    </div>
                                  )}
                                </FieldArray>
                              </Box>

                              <ModalFooter
                                position="sticky"
                                bottom="0"
                                background="#F9F9FB"
                                mb="20px"
                                mr="20px"
                                gap="16px"
                              >
                                {tabIndex.id > 0 && (
                                  <>
                                    <Button
                                      disabled={
                                        isOpenAlert ||
                                        resCreateJob.isLoading ||
                                        resCreateQuick.isLoading ||
                                        resUpdateQuick.isLoading ||
                                        resUpdateJob.isLoading
                                      }
                                      loading={
                                        resCreateJob.isLoading ||
                                        resCreateQuick.isLoading ||
                                        resUpdateQuick.isLoading ||
                                        resUpdateJob.isLoading
                                      }
                                      mr={3}
                                      onClick={() => {
                                        setIsDraft(true);
                                        console.log(errors);
                                        handleSubmit();
                                        !isValid && onOpen();
                                      }}
                                    >
                                      Save Draft
                                    </Button>
                                    <Button
                                      disabled={isOpenAlert}
                                      mr={3}
                                      onClick={() => {
                                        tabIndex.id > 0 &&
                                          setTabIndex(tabList[tabIndex.id - 1]);
                                        setJobFormData((prev: any) => ({
                                          ...prev,
                                          ...values,
                                          job_ads: [
                                            {
                                              ...values.job_ads[0],
                                              // country: values.job_ads[0].country,
                                            },
                                          ],
                                        }));
                                      }}
                                    >
                                      Previous
                                    </Button>
                                  </>
                                )}
                                {((String(jobFormData.job_type_id) === '1' &&
                                  tabIndex.id < 4) ||
                                  (String(jobFormData.job_type_id) === '2' &&
                                    tabIndex.id === 2)) && (
                                  <Button
                                    variant="solid"
                                    disabled={isOpenAlert}
                                    onClick={(e: any) => {
                                      setIsDraft(false);
                                      handleSubmit(e);
                                    }}
                                  >
                                    Next
                                  </Button>
                                )}
                              </ModalFooter>
                            </form>
                          )}
                        </Formik>
                      )}
                      {tabIndex.id === 4 && (
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={(data: any) => {
                            onSubmit({
                              ...jobFormData,
                              status: jobStatus,
                              lead_id: data.lead_id,
                              email_subject: data.email_subject,
                              email_content: data.email_content,
                              job_status_id: 1,
                            });
                          }}
                          enableReinitialize={true}
                        >
                          {({
                            values,
                            handleChange,
                            handleSubmit,
                            errors,
                            touched,
                            isValid,
                            setFieldValue,
                            setValues,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <ScrollToFieldError />
                              <Box
                                overflow="auto"
                                maxHeight="450px"
                                p="30px"
                                background="#fff"
                                borderRadius="8px"
                                minHeight="65vh"
                                width="100%"
                              >
                                <Tabs colorScheme="purple" width="100%">
                                  <TabList fontSize="md" fontWeight="500">
                                    <Tab
                                      _focus={{ boxShadow: 'none !important' }}
                                      width="100%"
                                    >
                                      Compose
                                    </Tab>
                                    <Tab
                                      _focus={{ boxShadow: 'none !important' }}
                                      width="100%"
                                    >
                                      Preview
                                    </Tab>
                                  </TabList>

                                  <TabPanels mt="10px">
                                    <TabPanel>
                                      <Box>
                                        <Box>
                                          <FormControl
                                            isInvalid={Boolean(
                                              !!errors.email_subject &&
                                                touched.email_subject
                                            )}
                                            mb="20px"
                                          >
                                            <Flex
                                              alignItems="center"
                                              // alignContent="space-between"
                                              justifyContent="space-between"
                                              mb="8px"
                                            >
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                                margin="0px"
                                              >
                                                Email Subject
                                                <Box
                                                  as="span"
                                                  color="caution.800"
                                                >
                                                  *
                                                </Box>
                                              </FormLabel>
                                              <Box>
                                                <Menu closeOnSelect={true}>
                                                  <MenuButton type="button">
                                                    <Button
                                                      type="button"
                                                      htmlType="menu"
                                                      size="sm"
                                                    >
                                                      <Text fontSize="15px">
                                                        {'{{ }}'}
                                                      </Text>
                                                    </Button>
                                                  </MenuButton>
                                                  <MenuList
                                                    fontSize="sm"
                                                    maxHeight="250px"
                                                    overflowY="auto"
                                                    sx={{
                                                      // position: 'relative',
                                                      right: '38px',
                                                      button: {
                                                        background: 'none',
                                                        radius: '6px',
                                                        fontSize: '12px',
                                                        fontWeight: 600,
                                                        lineHeight: '16px',
                                                        color: 'primary.600',
                                                      },
                                                    }}
                                                  >
                                                    {placeholderOptions.map(
                                                      (
                                                        item: string,
                                                        i: number
                                                      ) => {
                                                        return (
                                                          <MenuItem
                                                            id={String(i)}
                                                            key={`placeholder-${item}`}
                                                          >
                                                            <Button
                                                              leftIcon="copy"
                                                              onClick={() =>
                                                                placeHolderClick(
                                                                  item
                                                                )
                                                              }
                                                              sx={{
                                                                height:
                                                                  'fit-content',
                                                                p: '5px 10px',
                                                                fontSize:
                                                                  '10px',
                                                              }}
                                                            >
                                                              {item}
                                                            </Button>
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                  </MenuList>
                                                </Menu>
                                              </Box>
                                            </Flex>

                                            <Input
                                              name="email_subject"
                                              type="text"
                                              placeholder="Email Subject"
                                              variant="outline"
                                              value={values.email_subject}
                                              onChange={(e: any) => {
                                                // setFieldValue('email_subject', placholders(e.target.value));
                                                setFieldValue(
                                                  'email_subject',
                                                  e.target.value
                                                );
                                              }}
                                            />
                                            <FormErrorMessage>
                                              {String(errors.email_subject)}
                                            </FormErrorMessage>
                                          </FormControl>

                                          <FormControl
                                            isInvalid={Boolean(
                                              !!errors.email_content &&
                                                touched.email_content
                                            )}
                                          >
                                            <Flex
                                              height="32px"
                                              // alignContent="center"
                                              alignItems="center"
                                              mb="8px"
                                            >
                                              <FormLabel
                                                fontSize="14px"
                                                lineHeight="18px"
                                                color="default.secondarytext"
                                                margin="0px"
                                              >
                                                Email Content
                                                <Box
                                                  as="span"
                                                  color="caution.800"
                                                >
                                                  *
                                                </Box>
                                              </FormLabel>
                                            </Flex>

                                            <AtsEmailBody
                                              initialValue={''}
                                              value={values.email_content}
                                              onChange={(e: any) => {
                                                setFieldValue(
                                                  'email_content',
                                                  e
                                                );
                                              }}
                                            />
                                            <FormErrorMessage>
                                              {String(errors.email_content)}
                                            </FormErrorMessage>
                                          </FormControl>
                                        </Box>
                                      </Box>
                                    </TabPanel>
                                    <TabPanel>
                                      <Box>
                                        <Box>
                                          <Text
                                            fontSize="24px"
                                            fontWeight="bold"
                                          >
                                            {parse(
                                              placholders(
                                                values.email_subject
                                              ) || ''
                                            )}
                                          </Text>
                                        </Box>
                                        <Divider
                                          m="24px 0 24px 0"
                                          borderColor="gray.200"
                                        />
                                        <Box>
                                          <Box>
                                            {parse(
                                              placholders(
                                                values.email_content
                                              ) || ''
                                            )}
                                          </Box>
                                        </Box>
                                      </Box>
                                      {/* }
                                    </VStack> */}
                                    </TabPanel>
                                  </TabPanels>
                                </Tabs>
                              </Box>
                              <ModalFooter
                                position="sticky"
                                bottom="0"
                                background="#F9F9FB"
                                mb="20px"
                                mr="20px"
                                gap="16px"
                              >
                                {/* <Button
                                    disabled={
                                      isOpenAlert ||
                                      resCreateJob.isLoading ||
                                      resCreateQuick.isLoading ||
                                      resUpdateQuick.isLoading ||
                                      resUpdateJob.isLoading
                                    }
                                    loading={
                                      resCreateJob.isLoading ||
                                      resCreateQuick.isLoading ||
                                      resUpdateQuick.isLoading ||
                                      resUpdateJob.isLoading
                                    }
                                    mr={3}
                                    onClick={() => {
                                      setIsDraft(true);
                                      console.log(errors);
                                      handleSubmit();
                                      !isValid && onOpen();
                                    }}
                                  >
                                    Save Draft
                                  </Button> */}
                                {tabIndex.id > 0 && (
                                  <Button
                                    disabled={isOpenAlert}
                                    mr={3}
                                    onClick={() => {
                                      tabIndex.id > 0 &&
                                        setTabIndex(tabList[tabIndex.id - 1]);
                                      setJobFormData((prev: any) => ({
                                        ...prev,
                                        ...values,
                                        email_subject: values.email_subject,
                                        email_content: values.email_content,
                                      }));
                                    }}
                                  >
                                    Previous
                                  </Button>
                                )}
                                {((String(jobFormData.job_type_id) === '1' &&
                                  tabIndex.id === 4) ||
                                  (String(jobFormData.job_type_id) === '2' &&
                                    tabIndex.id === 2)) && (
                                  <Button
                                    type="submit"
                                    variant="solid"
                                    loading={
                                      resCreateJob.isLoading ||
                                      resCreateQuick.isLoading ||
                                      resUpdateQuick.isLoading ||
                                      resUpdateJob.isLoading
                                    }
                                    onClick={(e: any) => {
                                      console.log(errors);
                                      handleSubmit(e);
                                      setErrorList(errors);
                                      !isValid && onOpen();
                                    }}
                                    disabled={
                                      isOpenAlert ||
                                      resCreateJob.isLoading ||
                                      resCreateQuick.isLoading ||
                                      resUpdateQuick.isLoading ||
                                      resUpdateJob.isLoading
                                    }
                                  >
                                    {modalTitle === 'Edit Job'
                                      ? 'Update'
                                      : 'Save'}
                                  </Button>
                                )}
                              </ModalFooter>
                            </form>
                          )}
                        </Formik>
                      )}
                    </TabPanels>
                  )}
                </Box>
              </Flex>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default JobsModal;

import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import * as THREE from 'three';
import WAVES from 'assets/js/vanta.waves';

import { Formik } from 'formik';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  useDisclosure,
} from '@chakra-ui/react';

import Logo from 'assets/images/Talently.png';

import { useLoginAuthMutation } from 'store/auth.slice';
import { useNavigate } from 'react-router';
import FAIcon from './lib/FAIcon';

export default function Login() {
  const navigate = useNavigate();

  const [reqAuth, resAuth] = useLoginAuthMutation();

  const [show, setShow] = useState(false);

  const handleClick = () => setShow(!show);

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      password: Yup.string().required('Password is required.'),
    })
  );

  const onSubmit = async (data: any) => await reqAuth(data);

  const {
    isOpen: isVisible,
    onClose,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (resAuth.isSuccess) {
      onClose();
      navigate('/dashboard');
    } else if (resAuth.isError) {
      onOpen();
    }
  }, [navigate, onClose, onOpen, resAuth, resAuth.isSuccess]);

  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        WAVES({
          el: vantaRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0x80909,
          shininess: 44.0,
          waveHeight: 13.5,
          waveSpeed: 0.5,
          zoom: 1.1,
        })
      );
    }
  }, [vantaEffect]);

  return (
    <Flex fontFamily="Proxima Nova Regular">
      <Box width="50%" background="#000">
        <Container py={10} position="relative" top="200px">
          <Box textAlign="center" mx="70px">
            <FormLabel mb="50px" fontSize="25px" lineHeight="18px" color="#fff">
              Sign In
            </FormLabel>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit} onChange={onClose}>
                  {isVisible && (
                    <Box mb="20px">
                      <Alert status="error" justifyContent="space-around">
                        <AlertIcon />
                        <Box>
                          <AlertTitle>Incorrect email or password</AlertTitle>
                          <AlertDescription>
                            Try entering your information again.
                          </AlertDescription>
                        </Box>
                        <CloseButton
                          position="relative"
                          right={-1}
                          top={-3}
                          onClick={onClose}
                        />
                      </Alert>
                    </Box>
                  )}
                  <FormControl
                    isInvalid={Boolean(!!errors.email && touched.email)}
                    mb="20px"
                  >
                    <FormLabel fontSize="14px" lineHeight="18px" color="#fff">
                      Email
                    </FormLabel>
                    <Input
                      id="email"
                      name="email"
                      type="text"
                      variant="outline"
                      value={values.email}
                      onChange={handleChange}
                      background="#000"
                      border="1px solid #2E2E2E !important"
                      borderRadius="4px"
                      color="#fff"
                    />
                    <FormErrorMessage>{String(errors.email)}</FormErrorMessage>
                  </FormControl>

                  <FormControl
                    isInvalid={Boolean(!!errors.password && touched.password)}
                    mb="20px"
                  >
                    <FormLabel fontSize="14px" lineHeight="18px" color="#fff">
                      Password
                    </FormLabel>
                    <InputGroup background="#000" borderRadius="4px">
                      <Input
                        id="password"
                        name="password"
                        type={show ? 'text' : 'password'}
                        variant="outline"
                        value={values.password}
                        onChange={handleChange}
                        border="1px solid #2E2E2E !important"
                        borderRadius="4px"
                        color="#fff"
                      />
                      <InputRightElement color="#fff">
                        <Box onClick={handleClick} cursor="pointer">
                          <FAIcon iconName={show ? 'eye' : 'eye-slash'} />
                        </Box>
                      </InputRightElement>
                    </InputGroup>

                    <FormErrorMessage>
                      {String(errors.password)}
                    </FormErrorMessage>
                  </FormControl>

                  <Box py={4}>
                    <Button
                      background="#000"
                      _hover={{
                        bg: '#000',
                      }}
                      border="1px solid #2E2E2E !important"
                      width="100%"
                      color="#fff"
                      variant="ghost"
                      type="submit"
                      isDisabled={isVisible}
                      isLoading={
                        resAuth.isLoading
                      }
                    >
                      SIGN IN
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
      <Box width="50%">
        <Box ref={vantaRef} height="100vh">
          <Box color="#fff" top="250px" p="70px">
            <Image src={Logo} alt="logo" width="40%" m="0 auto" />
          </Box>
        </Box>
      </Box>
    </Flex>
  );
}

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Formik, ErrorMessage, FieldArray, Field } from 'formik';
import * as Yup from 'yup';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import {
  getLead,
  useCreateLeadsMutation,
  useGetLeadsMutation,
  useListLeadsMutation,
  useUpdateLeadsMutation,
} from 'store/leads.slice';
import AtsDropZone from '../Dropzone';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import { changeUploadName } from 'utils/newFilename';
import ScrollToFieldError from '../ScrollError';
import { authStorage } from '../../../utils/localStorage';
import { useUserListMutation } from 'store/user.slice';
import AtsCraetableSelDyn from '../AtsCreatabeSelect/Dyanmic';
import {
  useClientSearchMutation,
  useViewClientMutation,
} from 'store/client.slice';
import Buttonv2 from 'Library/Button';
import AtsSelect2 from '../AtsSelect2';
import AtsSelect from '../AtsSelect';
import {
  useGetCountriesMutation,
  useGetJobSpecsMutation,
  useGetLeadSourceMutation,
} from 'store/constant.slice';
import '../../../assets/css/accordion-collapse.css';
import { addressFormat, leadSourceFormat } from 'utils/utilFormat';
import AddressComponent from './components/AddressComponent';
import { useParams } from 'react-router';

interface LeadsModalProps {
  isOpen: any;
  onClose: any;
  modalTitle: string;
}

const LeadsModal = ({ isOpen, onClose, modalTitle }: LeadsModalProps) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const params = useParams();
  const leadID = params.leadsId;

  // const { auth } = useSelector((state: any) => state.auth);
  const { leadData, leadPgBtn, leadDetails } = useSelector(
    (state: any) => state.leads
  );
  const { countries, lead_source, job_specs } = useSelector(
    (state: any) => state.constants
  );

  const { clientData } = useSelector((state: any) => state.clients);
  const { userList } = useSelector((state: any) => state.user);
  const { uploaded, prefix, withPrefix, uploading } = useSelector(
    (state: any) => state.uploads
  );
  const [reqCountry] = useGetCountriesMutation();
  const [reqCompnay, resCompany] = useClientSearchMutation();
  const [reqLeads] = useListLeadsMutation();
  const [reqleadsData] = useGetLeadsMutation();
  const [reqCreateLead, resCreateLead] = useCreateLeadsMutation();
  const [reqUpdateLead, resUpdateLead] = useUpdateLeadsMutation();
  const [reqUser, resUser] = useUserListMutation();
  const [reqClientData, resClientData] = useViewClientMutation();
  const [reqLeadSource] = useGetLeadSourceMutation();
  const [reqSpecs] = useGetJobSpecsMutation();

  const [jobSpecsDefOption, setJobSpecsDefOption] = useState({});
  const [jobSpecsOption, setJobSpecsOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [countryDefOption, setCountryDefOption] = useState([]);
  const [selectOption, setSelectOption] = useState([]);
  const auth = authStorage();
  const [companyList, setCompanyList] = useState([]);
  const [leadSrouceList, setLeadSrouceList] = useState([]);
  let [managers, setManager] = useState([]);

  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const defManager = () => {
    let manager: any = [];
    try {
      manager.push({
        value: auth?.id,
        label: [auth?.first_name, auth?.last_name].join(' '),
      });
    } catch (e) {
      console.warn(e);
    }

    return manager;
  };

  useEffect(() => {
    reqSpecs({});
  }, [reqSpecs]);

  useEffect(() => {
    let userOption: any = [];
    if (resUser.isSuccess) {
      if (resUser.data.data.length > 0) {
        resUser.data.data.map(async (data: any) => {
          await userOption.push({
            value: data.id,
            label: [data.first_name, data.last_name].join(' '),
          });
        });
        setSelectOption(userOption);
      }
    }
  }, [resUser.isSuccess]);

  useEffect(() => {
    // reqCountry({});
    if (clientData?.manager?.length > 0) {
      let options: any = [];
      clientData?.manager.map((data: any) => {
        options.push({
          value: data?.user?.id,
          label: [data?.user?.first_name, data?.user?.last_name].join(' '),
        });
      });
      setManager(options);
    }
  }, [clientData]);

  useEffect(() => {
    if (leadID) {
      reqleadsData({ id: leadID });
    }
  }, [leadID]);

  useEffect(() => {
    let options: any = [];
    if (countries?.length > 0) {
      countries.map((item: any) => {
        options.push({
          label: item.country,
          value: item.id,
          code: item.code,
        });

        if (item.code === 'us') {
          setCountryDefOption([
            {
              label: item.country,
              value: item.id,
              code: item.code,
            },
          ]);
        }
      });
    }
    setCountryOption(options);
  }, [countries]);

  useEffect(() => {
    // leadSrouceList, setLeadSrouceList;
    let options: any = [];
    if (lead_source?.length > 0) {
      lead_source.map((item: any) => {
        options.push({
          label: item.source,
          value: item.id,
        });
      });
    }
    setLeadSrouceList(options);
  }, [lead_source]);

  const initialValues =
    leadData && Object.keys(leadData).length !== 0
      ? {
          first_name: leadData.first_name,
          last_name: leadData.last_name,
          title: leadData.title,
          company: {
            value: leadDetails?.lead?.client?.id,
            label: leadDetails?.lead?.client?.name,
          },
          specification_id: leadData.spec_id,
          email: leadData.primary_email,
          personal_phone: leadData.personal_phone,
          work_phone: leadData.work_phone,
          extension: leadData.extension,
          // company_location: leadData.company_location,
          address: addressFormat(leadDetails?.address, countries),
          linkedIn_url: leadData.linked_in_url,
          website: leadData.website,
          lead_owner: leadDetails.lead?.owner_id,
          lead_source: leadSourceFormat(leadData.lead_source),
          created_by: String(leadData?.user_id),
          notes: '',
          status: leadData.leadStatus?.id,
          isMultipleAccountManager: leadData?.multipleAm ? 'true' : 'false',
          manager: managers,
        }
      : {
          first_name: '',
          last_name: '',
          title: '',
          company: {},
          specification_id: '',
          email: '',
          personal_phone: '',
          work_phone: '',
          extension: '',
          address: [
            {
              location: '',
              address: '',
              city: '',
              state: '',
              country: countryDefOption,
              zip_code: '',
              primary: true,
              id: null as any,
            },
          ],
          linkedIn_url: '',
          website: '',
          lead_owner: 0,
          lead_source: '',
          created_by: String(auth?.id),
          notes: '',
          status: 1,
          isMultipleAccountManager: 'false',
          manager: defManager(),
        };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      first_name: Yup.string()
        .required('First name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'First name is invalid.'),
      last_name: Yup.string()
        .required('Last name is required.')
        .matches(/^[a-z ,.'-]+$/gi, 'Last name is invalid.'),
      title: Yup.string().required('Title is required.'),
      company: Yup.array().min(1, 'Pick at least 1 company').nullable(),
      specification_id: Yup.number()
        .required('Industry is required.')
        .min(1, 'Invalid level.'),
      email: Yup.string()
        .email('Invalid email.')
        .required('Email is required.'),
      personal_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Personal phone is invalid'
      ),
      work_phone: Yup.string().matches(
        /^[\d ()+-.]+$/gi,
        'Work phone is invalid'
      ),
      extension: Yup.string().matches(/^[\d ()+-]+$/gi, 'Extension is invalid'),
      address: Yup.array()
        .of(
          Yup.object().shape({
            location: Yup.string().required('Location is required.'),
            address: Yup.string(),
            city: Yup.string().required('City is required.'),
            state: Yup.string().required('State is required.'),
            country: Yup.array().min(1, 'Pick at least 1 country').nullable(),
            zip_code: Yup.string(),
            primary: Yup.boolean(),
          })
        )
        .min(1, 'Address is required.'),
      linkedIn_url: Yup.string(),
      website: Yup.string().required('Website is required.'),
      isMultipleAccountManager: Yup.string(),
      manager: Yup.array().min(1, 'Pick at least 1 manager').nullable(),
      lead_owner: Yup.string().required('Lead owner is required.'),
      // lead_source: Yup.array().min(1, 'Pick at least 1 source').nullable(),
      lead_source: Yup.object().shape({
        label: Yup.string().required('Pick at least 1 source'),
        value: Yup.number(),
      }),
      created_by: Yup.string().required('Created by is required.'),
      notes: Yup.string(),
      status: Yup.number(),
    })
  );

  const [isSend, setIsSend] = useState<boolean>(false);

  const isEdit = modalTitle === 'Edit Lead';

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const onSubmit = async (data: any) => {
    if (data.manager[0] === undefined) {
      let manager: any = [];
      manager.push(data.manager);
      data['manager'] = manager;
    }

    if (isSend) {
      data.status = 3;
    } else {
      data.status = leadData.leadStatus?.id || 1;
    }
    try {
      delete data['company']['address'];
      delete data['company']['location'];
      delete data['company']['website'];
    } catch (e) {}

    if (isEdit) {
      data['attachments'] = withPrefix
        ? await changeUploadName(uploaded, prefix)
        : uploaded;
      reqUpdateLead({ data, id: leadData.id });
    } else {
      data['created_by'] = parseInt(data.created_by);
      data['lead_owner'] = parseInt(data.lead_owner);
      data['attachments'] = withPrefix
        ? await changeUploadName(uploaded, prefix)
        : uploaded;
      reqCreateLead(data);
    }
  };

  useEffect(() => {
    let options: any = [];
    if (job_specs?.length > 0) {
      job_specs.map((item: any) => {
        options.push({
          label: item.specification,
          value: item.id,
        });
        if (item.id === leadData.spec_id) {
          setJobSpecsDefOption({
            label: item.specification,
            value: item.id,
          });
        }
      });
    }
    setJobSpecsOption(options);
  }, [job_specs]);

  useEffect(() => {
    if (isOpen) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      reqCountry({});
      reqLeadSource({});
      if (!isEdit) {
        dispatch(getLead({ leadData: {} }));
        dispatch(getLead({ leadDetails: [] }));
      }
      reqCompnay({ search: '' });
    }
  }, [isOpen, isEdit]);

  useEffect(() => {
    reqUser({});
    dispatch(uploadList({ prefix: 'Attachment' }));

    if (resUpdateLead.isSuccess) {
      reqleadsData({ id: leadID });
    }

    if (resCreateLead.isSuccess || resUpdateLead.isSuccess) {
      onCloseAlert();
      onClose();
      setIsSend(false);
      dispatch(getLead({ leadData: {} }));
      dispatch(getLead({ leadDetails: [] }));
      reqLeads(leadPgBtn);
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (resCreateLead.isSuccess) {
          title = 'Added Lead';
          description = 'New Lead successfully added';
        } else if (resUpdateLead.isSuccess) {
          title = 'Updated Lead';
          description = 'Lead has been updated';
          status = 'info';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    } else if (resCreateLead.isError || resUpdateLead.isError) {
      onOpen();
    }

    if (resCreateLead.isError) {
      let errorMessage: any = resCreateLead.error;
      if (errorMessage['data']['error'] !== undefined) {
        setErrorMsg(errorMessage['data']['error']['message']);
      } else {
        try {
          setErrorMsg(errorMessage['data']['message']);
        } catch (e) {
          console.log('e', e);
          setErrorMsg('Server Error');
        }
      }
    }
  }, [
    resCreateLead.isSuccess,
    resCreateLead.isError,
    resUpdateLead.isSuccess,
    resUpdateLead.isError,
  ]);

  useEffect(() => {
    if (resCompany.isSuccess) {
      const data: any = resCompany.data.data;

      let option: any = [];
      data.map((item: any) => {
        option.push({
          label: item.name,
          value: item.id,
          website: item.website,
          address: addressFormat(item.address, countries),
        });
      });
      setCompanyList(option);
    }
  }, [resCompany.isSuccess]);

  useEffect(() => {
    if (leadData?.client_id) {
      reqClientData({ id: leadData?.client_id });
    }
  }, [leadData?.client_id]);

  useEffect(() => {
    let userOption: any = [];

    if (resClientData.isSuccess) {
      const managersId: any = resClientData?.data?.data?.manager?.map(
        (a: any) => a.user_id
      );
      if (resUser.isSuccess) {
        if (resUser.data.data.length > 0) {
          resUser.data.data.map(async (data: any) => {
            if (!managersId?.includes(data.id)) {
              await userOption.push({
                value: data.id,
                label: [data.first_name, data.last_name].join(' '),
              });
            }
          });
          setSelectOption(userOption);
        }
      }
    }
  }, [
    resUser?.data?.data,
    resUser.isSuccess,
    resClientData?.isSuccess,
    resClientData?.data?.data,
  ]);

  const SearchCompany = debounce((e: any) => {
    reqCompnay({ search: e });
  }, 300);

  const [isDisabledWebsite, setIsDisabledWebsite] = useState(false);

  const handleAddressSelection = (
    index: number,
    setFieldValue: any,
    values: any
  ) => {
    setFieldValue(
      'address',
      values.address.map((address: any, idx: any) => ({
        ...address,
        primary: idx === index,
      }))
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          // dispatch(getLead({ leadData: {} }));
          onCloseAlert();
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {modalTitle}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box width="100%">
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errMsg}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
              enableReinitialize={true}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit} onChange={onCloseAlert}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.first_name && touched.first_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          First Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="first_name"
                          name="first_name"
                          type="text"
                          placeholder="First Name"
                          variant="outline"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.first_name)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.last_name && touched.last_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Last Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="last_name"
                          name="last_name"
                          type="text"
                          placeholder="Last Name"
                          variant="outline"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.last_name)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.personal_phone && touched.personal_phone
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Personal Phone
                        </FormLabel>
                        <Input
                          id="personal_phone"
                          name="personal_phone"
                          type="text"
                          placeholder="Personal Phone"
                          variant="outline"
                          value={values.personal_phone}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.personal_phone)}
                        </FormErrorMessage>
                      </FormControl>
                      <Flex width="100%" gap="32px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.work_phone && touched.work_phone
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Work Phone
                          </FormLabel>
                          <Input
                            id="work_phone"
                            name="work_phone"
                            type="text"
                            placeholder="Work Phone"
                            variant="outline"
                            value={values.work_phone}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.work_phone)}
                          </FormErrorMessage>
                        </FormControl>
                        <Flex width="30%">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.work_phone && touched.work_phone
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Extension
                            </FormLabel>
                            <Input
                              id="extension"
                              name="extension"
                              type="number"
                              placeholder="Ext"
                              variant="outline"
                              value={values.extension}
                              onChange={(e) => {
                                setFieldValue(
                                  'extension',
                                  String(e.target.value)
                                );
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.extension)}
                            </FormErrorMessage>
                          </FormControl>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex gap="32px" mb="40px">
                      <FormControl
                        isInvalid={Boolean(!!errors.email && touched.email)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Email{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="email"
                          name="email"
                          type="email"
                          placeholder="Email"
                          variant="outline"
                          value={values.email}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.email)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.linkedIn_url && touched.linkedIn_url
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          LinkedIn Link
                        </FormLabel>
                        <Input
                          id="linkedIn_url"
                          name="linkedIn_url"
                          type="text"
                          placeholder="LinkedIn Link"
                          variant="outline"
                          value={values.linkedIn_url}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.linkedIn_url)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>

                    <Box mb="40px">
                      <Text fontWeight={600} mb="34px">
                        Company Details
                      </Text>
                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(!!errors.title && touched.title)}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Title{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            placeholder="Title"
                            variant="outline"
                            value={values.title}
                            onChange={handleChange}
                          />
                          <FormErrorMessage>
                            {String(errors.title)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.company && touched.company
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Company{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <AtsCraetableSelDyn
                            id="company"
                            name="company"
                            defaultValue={values.company}
                            placeholder="Company"
                            onChange={(event: any) => {
                              reqClientData({ id: event?.value });
                              setFieldValue('company', event);
                              if (event?.address) {
                                setFieldValue('address', event.address);
                              }

                              if (event?.website) {
                                setFieldValue('website', event.website);
                                setIsDisabledWebsite(true);
                              }

                              if (!event) {
                                setFieldValue('address', []);
                                setFieldValue('website', '');
                                setIsDisabledWebsite(false);
                              }
                            }}
                            onInputChange={SearchCompany}
                            isLoading={resCompany.isLoading}
                            options={companyList}
                            isClearable
                          />
                          <FormErrorMessage>
                            {String(errors.company)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.specification_id &&
                              touched.specification_id
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Industry{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>

                          <Field
                            name="specification_id"
                            options={jobSpecsOption}
                            component={AtsSelect}
                            value={jobSpecsDefOption}
                            placeholder="Select"
                            onChange={(e: any) => {
                              setJobSpecsDefOption(e);
                              setFieldValue(
                                'specification_id',
                                String(e.value)
                              );
                            }}
                          />
                          {/* <AtsSelect
                            id="specification_id"
                            name="specification_id"
                            placeholder="Select"
                            variant="outline"
                            onChange={(e: any) =>
                              setFieldValue('specification_id', String(e.value))
                            }
                            value={
                              (job_specs?.length > 0 &&
                                job_specs
                                  .map((item: any) => ({
                                    label: item.specification,
                                    value: item.id,
                                  }))
                                  .filter(
                                    (val: any) =>
                                      val.value === values.specification_id
                                  )[0]) ||
                              values.specification_id
                            }
                            options={
                              job_specs?.length > 0 &&
                              job_specs.map((item: any) => ({
                                label: item.specification,
                                value: item.id,
                              }))
                            }
                          /> */}
                          <FormErrorMessage>
                            {String(errors.specification_id)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.website && touched.website
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Company Website{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Input
                            id="website"
                            name="website"
                            type="text"
                            placeholder="Company Website"
                            variant="outline"
                            value={values.website}
                            onChange={handleChange}
                            disabled={isDisabledWebsite}
                          />
                          <FormErrorMessage>
                            {String(errors.website)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>

                      <Box>
                        <Text fontWeight={600} mb="34px">
                          Address Information{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </Text>
                      </Box>

                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.address && touched.address
                          )}
                        >
                          <FieldArray name="address">
                            {({ remove, push }) => (
                              <div>
                                {values.address &&
                                  values.address.length > 0 &&
                                  values.address.map(
                                    (job: any, index: number) => (
                                      <Accordion
                                        defaultIndex={[index]}
                                        allowMultiple
                                        key={`jobAdd-${index}`}
                                      >
                                        <AccordionItem className="accordion-item">
                                          <Flex position="relative">
                                            <AccordionButton>
                                              <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                              >
                                                <Box
                                                  as="span"
                                                  fontSize="14px"
                                                  lineHeight="18px"
                                                  fontWeight={600}
                                                  color="default.primarytext"
                                                  mb="8px"
                                                >
                                                  {job.location
                                                    ? `${job.location}${
                                                        job.primary
                                                          ? ` (Primary Address)`
                                                          : ''
                                                      }`
                                                    : `Address ${index + 1}`}
                                                </Box>
                                              </Box>

                                              <Box>
                                                <AccordionIcon color="#6930CA" />
                                              </Box>
                                            </AccordionButton>
                                            <Flex
                                              gap="15px"
                                              alignItems="center"
                                              position="absolute"
                                              right="55px"
                                              top="0"
                                              bottom="0"
                                            >
                                              <Field
                                                as={Switch}
                                                colorScheme="purple"
                                                name={`address.${index}.primary`}
                                                isChecked={job.primary}
                                                onChange={() =>
                                                  handleAddressSelection(
                                                    index,
                                                    setFieldValue,
                                                    values
                                                  )
                                                }
                                              />
                                              <Box>
                                                <Buttonv2
                                                  leftIcon="close"
                                                  onClick={() => remove(index)}
                                                  size="sm"
                                                >
                                                  Remove
                                                </Buttonv2>
                                              </Box>
                                            </Flex>
                                          </Flex>
                                          <AccordionPanel
                                            pb={4}
                                            className="accorion-panel"
                                          >
                                            <AddressComponent
                                              setFieldValue={setFieldValue}
                                              index={index}
                                              handleChange={handleChange}
                                              countryOption={countryOption}
                                              countryField={{
                                                name: `address.${index}.country`,
                                                value: job.country,
                                                errors: (
                                                  errors.address as any[]
                                                )?.[index]?.country,
                                                touched: (
                                                  touched.address as any[]
                                                )?.[index]?.country,
                                              }}
                                              locationField={{
                                                name: `address.${index}.location`,
                                                value: job.location,
                                                errors: (
                                                  errors.address as any[]
                                                )?.[index]?.location,
                                                touched: (
                                                  touched.address as any[]
                                                )?.[index]?.location,
                                              }}
                                              addressField={{
                                                name: `address.${index}.address`,
                                                value: job.address,
                                                errors: (
                                                  errors.address as any[]
                                                )?.[index]?.address,
                                                touched: (
                                                  touched.address as any[]
                                                )?.[index]?.address,
                                              }}
                                              cityField={{
                                                name: `address.${index}.city`,
                                                value: job.city,
                                                errors: (
                                                  errors.address as any[]
                                                )?.[index]?.city,
                                                touched: (
                                                  touched.address as any[]
                                                )?.[index]?.city,
                                              }}
                                              stateField={{
                                                name: `address.${index}.state`,
                                                value: job.state,
                                                errors: (
                                                  errors.address as any[]
                                                )?.[index]?.state,
                                                touched: (
                                                  touched.address as any[]
                                                )?.[index]?.state,
                                              }}
                                              zipField={{
                                                name: `address.${index}.zip_code`,
                                                value: job.zip_code,
                                                errors: (
                                                  errors.address as any[]
                                                )?.[index]?.zip_code,
                                                touched: (
                                                  touched.address as any[]
                                                )?.[index]?.zip_code,
                                              }}
                                            />
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>
                                    )
                                  )}
                                {!leadData.address && (
                                  <Box mt="20px">
                                    <Buttonv2
                                      leftIcon="plus"
                                      onClick={() => {
                                        push({
                                          location: '',
                                          address: '',
                                          city: '',
                                          state: '',
                                          country: countryDefOption,
                                          zip_code: '',
                                          primary:
                                            leadData.address?.length === 0 ||
                                            values.address?.length === 0
                                              ? true
                                              : false,
                                        });
                                      }}
                                    >
                                      {values.address &&
                                      values.address.length > 0
                                        ? 'Add Another Address'
                                        : 'Add Address'}
                                    </Buttonv2>
                                  </Box>
                                )}
                              </div>
                            )}
                          </FieldArray>
                          {values?.address?.length === 0 && (
                            <FormErrorMessage mt="20px">
                              {String(errors.address)}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      </Flex>
                    </Box>

                    <Box>
                      <Text fontWeight={600} mb="34px">
                        Additional Information
                      </Text>
                      <Box my="34px">
                        <FormControl>
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Multiple Account Managers?
                          </FormLabel>
                        </FormControl>

                        <Flex mb="20px">
                          <FormLabel
                            display="flex"
                            gap="10px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            <Field
                              id="isMultipleAccountManager"
                              name="isMultipleAccountManager"
                              type="radio"
                              variant="outline"
                              value={'true'}
                            />
                            Yes
                          </FormLabel>
                          <FormLabel
                            display="flex"
                            gap="10px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            <Field
                              id="isMultipleAccountManager"
                              name="isMultipleAccountManager"
                              type="radio"
                              variant="outline"
                              value={'false'}
                            />
                            No
                          </FormLabel>
                        </Flex>

                        <FormControl
                          isInvalid={Boolean(
                            !!errors.manager && touched.manager
                          )}
                          mb="34px"
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Account Manager{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          <Field
                            name="manager"
                            isMulti={
                              values.isMultipleAccountManager === 'true'
                                ? true
                                : false
                            }
                            options={selectOption}
                            component={AtsSelect2}
                            defaultValue={values.manager}
                          />
                          <FormErrorMessage>
                            {String(errors.manager)}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>

                      <Flex gap="32px" mb="34px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.created_by && touched.created_by
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Created By:
                          </FormLabel>

                          <AtsSelect
                            id="created_by"
                            name="created_by"
                            placeholder="Select"
                            variant="outline"
                            value={
                              (Object.values(userList).length > 0 &&
                                Object.values(userList)
                                  .map((item: any) => ({
                                    label: [
                                      item.first_name,
                                      item.last_name,
                                    ].join(' '),
                                    value: item.id,
                                  }))
                                  .filter(
                                    (val: any) =>
                                      val.value === Number(values.created_by)
                                  )[0]) ||
                              values.created_by
                            }
                            onChange={(e: any) =>
                              setFieldValue('created_by', String(e.value))
                            }
                            options={Object.values(userList).map(
                              (item: any) => ({
                                label: [item.first_name, item.last_name].join(
                                  ' '
                                ),
                                value: item.id,
                              })
                            )}
                          />
                          <FormErrorMessage>
                            {String(errors.created_by)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.lead_source && touched.lead_source
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Source{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>
                          {/* <Input
                            id="lead_source"
                            name="lead_source"
                            type="text"
                            placeholder="Lead Source"
                            variant="outline"
                            value={values.lead_source}
                            onChange={handleChange}
                          /> */}
                          <Field
                            id="lead_source"
                            name="lead_source"
                            options={leadSrouceList}
                            component={AtsSelect}
                            defaultValue={values.lead_source}
                            onChange={(e: any) => {
                              setFieldValue(`lead_source`, e);
                            }}
                          />
                          <FormErrorMessage>
                            {errors.lead_source &&
                              touched.lead_source &&
                              String((errors.lead_source as any).label)}
                          </FormErrorMessage>
                        </FormControl>
                      </Flex>
                      <Flex mb="34px" gap="32px">
                        <FormControl
                          isInvalid={Boolean(
                            !!errors.lead_owner && touched.lead_owner
                          )}
                        >
                          <FormLabel
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Lead Owner{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </FormLabel>

                          <AtsSelect
                            id="lead_owner"
                            name="lead_owner"
                            placeholder="Select"
                            variant="outline"
                            // value={values.lead_owner}
                            value={
                              (Object.values(userList).length > 0 &&
                                Object.values(userList)
                                  .map((item: any) => ({
                                    label: [
                                      item.first_name,
                                      item.last_name,
                                    ].join(' '),
                                    value: item.id,
                                  }))
                                  .filter(
                                    (val: any) =>
                                      val.value === Number(values.lead_owner)
                                  )[0]) ||
                              values.lead_owner
                            }
                            onChange={(e: any) =>
                              setFieldValue('lead_owner', String(e.value))
                            }
                            options={Object.values(userList).map(
                              (item: any) => ({
                                label: [item.first_name, item.last_name].join(
                                  ' '
                                ),
                                value: item.id,
                              })
                            )}
                          />
                          <FormErrorMessage>
                            {String(errors.lead_owner)}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl />
                      </Flex>
                    </Box>
                    {!isEdit && (
                      <>
                        <Box mb="34px">
                          <FormControl>
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Attachments
                            </FormLabel>
                          </FormControl>
                          <AtsDropZone
                            multipleFile={true}
                            uploadedEnd={(e: any) => {}}
                            deletedFile={(key: string) => {}}
                          />
                        </Box>
                        <Box>
                          <FormLabel
                            mb="8px"
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Notes
                          </FormLabel>
                          <Textarea
                            id="notes"
                            name="notes"
                            placeholder="Type here"
                            variant="outline"
                            value={values.notes}
                            onChange={handleChange}
                          />
                          <Text
                            mt="8px"
                            fontSize="12px"
                            lineHeight="18px"
                            color="default.secondarytext"
                          >
                            Maximum 200 characters allowed
                          </Text>
                          <Box color="red.700">
                            <ErrorMessage
                              name="notes"
                              className="alert alert-danger"
                            />
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    {isEdit ? (
                      <Button
                        variant="solid"
                        type="submit"
                        isLoading={resUpdateLead.isLoading}
                        disabled={isOpenAlert || resUpdateLead.isLoading}
                        mr={3}
                      >
                        Update
                      </Button>
                    ) : (
                      <>
                        <Button
                          type="submit"
                          disabled={isOpenAlert || resCreateLead.isLoading}
                          isLoading={resCreateLead.isLoading}
                          mr={3}
                        >
                          Add
                        </Button>
                        <Button
                          variant="solid"
                          type="submit"
                          isLoading={resCreateLead.isLoading}
                          disabled={
                            isOpenAlert || uploading || resCreateLead.isLoading
                          }
                          onClick={() => setIsSend(true)}
                        >
                          Add & Send for approval
                        </Button>
                      </>
                    )}
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default LeadsModal;

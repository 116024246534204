import { Formik } from 'formik';

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import ScrollToFieldError from 'components/app/ScrollError';
import AtsSelect from 'components/app/AtsSelect';

interface AddToCampaignModalProps {
  isOpen: any;
  onClose: any;
}

export default function AddToCampaignModal({
  isOpen,
  onClose,
}: AddToCampaignModalProps) {
  const initialValues = {
    campaign: {},
  };

  const handleSubmit = (data: any) => {
    console.log(data);
  };

  const campaignOptions = [
    { label: 'Option 1', value: 0 },
    { label: 'Option 2', value: 1 },
    { label: 'Option 3', value: 2 },
  ];

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="lg"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Add To Campaign
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Campaign
                        </FormLabel>
                        <AtsSelect
                          id="campaign"
                          name="campaign"
                          placeholder="Select"
                          variant="outline"
                          value={values.campaign}
                          onChange={(e: any) =>
                            setFieldValue('campaign', String(e.value))
                          }
                          options={campaignOptions}
                        />
                      </FormControl>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button mr={3} onClick={() => onClose()}>
                      Cancel
                    </Button>
                    <Button variant="solid" type="submit">
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

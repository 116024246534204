import { dom, library } from '@fortawesome/fontawesome-svg-core';
import {
  faA,
  faAd,
  faAdd,
  faAddressBook,
  faAddressCard,
  faAdjust,
  faAirFreshener,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAllergies,
  faAmbulance,
  faAmericanSignLanguageInterpreting,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowsV,
  faBan,
  faBars,
  faBell,
  faBold,
  faBolt,
  faBriefcase,
  faBullhorn,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleChevronDown,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleChevronUp,
  faCircleNotch,
  faClipboard,
  faClock,
  faClone,
  faCloudUpload,
  faCode,
  faCodeCommit,
  faCoffee,
  faCommentDots,
  faCopy,
  faCut,
  faDiamond,
  faDotCircle,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationCircle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilePdf,
  faFileUpload,
  faFileWord,
  faFilter,
  faFolder,
  faFrown,
  faGlobe,
  faGrin,
  faGripLinesVertical,
  faGripVertical,
  faHandPaper,
  faHomeAlt,
  faHourglassStart,
  faIdCard,
  faInfoCircle,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faLocationPin,
  faLock,
  faLongArrowLeft,
  faLongArrowRight,
  faMapMarker,
  faMapMarkerAlt,
  faMinus,
  faMinusCircle,
  faPaintBrush,
  faPalette,
  faPaperclip,
  faPause,
  faPencil,
  faPenNib,
  faPenSquare,
  faPhone,
  faPlay,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faQuoteRight,
  faRedo,
  faRepeat,
  faSave,
  faSearch,
  faShareAlt,
  faShekelSign,
  faSignOut,
  faSignOutAlt,
  faSitemap,
  faSmile,
  faSort,
  faStar,
  faStickyNote,
  faTags,
  faThumbsDown,
  faThumbsUp,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTrash,
  faTv,
  faUnderline,
  faUndo,
  faUpload,
  faUser,
  faUserCircle,
  faUserFriends,
  faUsers,
  faUserTie,
  faVolumeUp,
  faWindowClose,
  faPersonCircleCheck,
  faCircleXmark,
  faVideo,
  faFile,
  faRotateLeft,
  faComment,
  faDollar,
  faTrophy,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(
  faAdd,
  faAd,
  faAddressBook,
  faChevronDown,
  faChevronRight,
  faChevronCircleDown,
  faChevronCircleLeft,
  faChevronCircleRight,
  faChevronCircleUp,
  faChevronLeft,
  faChevronUp,
  faCircleChevronDown,
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleChevronUp,
  faAddressCard,
  faA,
  faAdjust,
  faAirFreshener,
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faAllergies,
  faAmbulance,
  faAmericanSignLanguageInterpreting,
  faUsers,
  faCut,
  faBullhorn,
  faPenNib,
  faCircle,
  faPalette,
  faVolumeUp,
  faSmile,
  faGrin,
  faShekelSign,
  faTv,
  faUserTie,
  faFolder,
  faPaintBrush,
  faCircleNotch,
  faSignOutAlt,
  faCoffee,
  faCheckSquare,
  faPlusSquare,
  faCheck,
  faCoffee,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faLongArrowLeft,
  faEnvelope,
  faChevronRight,

  faSearch,
  faTimes,
  faCheckCircle,
  faCircle,
  faBolt,
  faAngleLeft,
  faAngleRight,

  faPlus,
  faPlusSquare,

  faBars,
  faMinus,
  faFileUpload,
  faCloudUpload,

  faInfoCircle,
  faLongArrowRight,
  faUserCircle,
  faUsers,
  faSignOut,
  faGripLinesVertical,
  faGripVertical,
  faUpload,
  faQuestionCircle,
  faLink,
  faLock,
  faPencil,
  faTrash,
  faThumbsDown,
  faThumbsUp,
  faUndo,
  faEllipsisH,
  faChevronLeft,
  faExclamationCircle,
  faDownload,
  faRepeat,
  faRedo,
  faStickyNote,
  faArrowDown,
  faAngleDown,
  faAngleUp,
  faChevronDown,
  faBriefcase,
  faEllipsisV,
  faPaperclip,
  faFilter,
  faFileAlt,
  faFileWord,
  faFilePdf,
  faClipboard,
  faCommentDots,
  faPause,
  faPlay,
  faArrowsV,
  faSmile,
  faStar,
  faFrown,
  faSave,
  faChevronUp,
  faExternalLink,
  faBan,
  faTimesCircle,
  faWindowClose,
  faHandPaper,
  faCodeCommit,
  faMapMarker,
  faMapMarkerAlt,
  faFolder,
  faCopy,
  faBold,
  faItalic,
  faUnderline,
  faCode,
  faListOl,
  faListUl,
  faQuoteRight,
  faBell,
  faAngleUp,
  faDotCircle,
  faGlobe,
  faMinusCircle,
  faHourglassStart,
  faClone,
  faCaretUp,
  faCaretDown,
  faEye,
  faEyeSlash,
  faSitemap,
  faSort,
  faHomeAlt,
  faUserFriends,
  faCalendar,
  faClock,
  faShareAlt,
  faThumbtack,
  faIdCard,
  faPenSquare,
  faArrowsAltH,
  faTags,
  faPhone,
  faCaretRight,
  faUser,
  faDiamond,
  faLocationPin,
  faPersonCircleCheck,
  faCircleXmark,
  faVideo,
  faFile,
  faRotateLeft,
  faComment,
  faDollar,
  faGripVertical,
  faTrophy,
  faMoneyBill,
);
dom.watch();

interface IconProps {
  iconName: any;
  [rest: string]: any;
}

const FAIcon = ({ iconName }: IconProps) => {
  return <FontAwesomeIcon icon={iconName} size="sm" />;
};

export default FAIcon;

import Select from 'react-select';

import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import ScrollToFieldError from 'components/app/ScrollError';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import replacePlaceholders from 'utils/textPlaceholders';
import AtsEmailBody from 'components/app/EmailBody';
import {
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
} from 'store/template.slice';
import { useParams } from 'react-router';
import { FollowUpTable } from '../../FollowUpTable';
import AtsSelect from 'components/app/AtsSelect';
import EmailPreviewModal from './preview';
import {
  listJobs,
  useListFollowUpCandidatesMutation,
  usePostFollowUpCandidatesMutation,
} from 'store/jobs.slice';
import moment from 'moment';
import FollowUpLoading from './loading';
import { to_resume_url } from 'utils/encryption';

interface FollowUpModalProps {
  isOpen: any;
  onClose: any;
  isAll: boolean;
  job_id: number;
}

interface templateInt {
  value: number | null;
  label: string | null;
  subject: string | null;
  body: string | null;
  attachments: any | null;
  table: string | null;
  tr: string | null;
  placeholders: any | null;
  subjectmultiple: string | null;
  bodymultiple: string | null;
  table_container: string | null;
}

export const FollowUpModal = ({
  isOpen,
  onClose,
  isAll,
  job_id,
}: FollowUpModalProps) => {
  const dispatch = useDispatch();
  const params = useParams();

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenPreview,
    onClose: onClosePreview,
    onOpen: openPreview,
  } = useDisclosure();

  const [reqList, resList] = useRawListTemplateMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqListFollowUpCandidates, resListFollowUpCandidates] =
    useListFollowUpCandidatesMutation();
  const [reqPostFollowUpCandidates, resPostFollowUpCandidates] =
    usePostFollowUpCandidatesMutation();

  const { candidate_work_status } = useSelector(
    (state: any) => state.constants
  );
  const { jobData, byContactCandidates, selectedCandidates } = useSelector(
    (state: any) => state.jobs
  );
  const { placehoderPair } = useSelector((state: any) => state.template);
  const { userList } = useSelector((state: any) => state.user);
  const { resumes } = useSelector((state: any) => state.candidateResume);

  const [attachments, setAttachments] = useState([]);
  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  const [template, setTemplate] = useState([]);
  const [defTemplate, setDefTemplate] = useState<templateInt>({
    value: 0,
    label: '',
    subject: '',
    body: '',
    attachments: '',
    table: '',
    tr: '',
    placeholders: '',
    subjectmultiple: '',
    bodymultiple: '',
    table_container: '',
  });
  const [quilNotes, setquilNotes] = useState('');
  const [dataCc, setDataCc] = useState([]);
  const [dataBcc, setDataBcc] = useState([]);

  const [ccBcc, setCcBcc] = useState([]);
  const [ccOption, setCcOption] = useState([]);
  const [bccOption, setBccOption] = useState([]);

  const [isSubmit, setIsSubmit] = useState(true);
  const [isInterview, setIsInterview] = useState(true);

  const [selectedVersion, setSelectedVersion] = useState(null);

  const [tFuObj, setTFuObj] = useState<any>({});

  const [jobSelect, setJobSelect] = useState(
    byContactCandidates &&
    byContactCandidates.map((item: any) => ({
      value: item.id,
      label: item.title,
    }))
  );

  const jobSelectOptions =
    byContactCandidates &&
    byContactCandidates.map((item: any) => ({
      value: item.id,
      label: item.title,
    }));

  useEffect(() => {
    let latest_version = resumes.filter(
      (resume: any) => resume.is_primary === true
    );
    if (latest_version.length <= 0) {
      latest_version = resumes;
    }
    if (latest_version.length > 0) {
      setSelectedVersion(latest_version[0]);
    }
    if (resumes.length <= 0) {
      setSelectedVersion(null);
    }
  }, [resumes]);

  useEffect(() => {
    if (resPostFollowUpCandidates.isSuccess) {
      onClose();
      setJobSelect([]);
      dispatch(listJobs({ byContactCandidates: [] }));
    }
  }, [resPostFollowUpCandidates.isSuccess]);

  useEffect(() => {
    reqList({ id: 5 });
    const data = {
      //   candidate_id: candidate.id,
      job_id: params.jobsId,
    };
    reqPair(data);
  }, [params.jobsId]);

  useEffect(() => {
    if (jobData?.bcc?.cc) {
      setDataCc(ccBc(jobData.bcc.cc));
    }
    if (jobData?.bcc?.bcc) {
      setDataBcc(ccBc(jobData.bcc.bcc));
    }
  }, [jobData]);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  const jobTitlePh = (text: string) => {
    const placeholderParam = {
      job_id,
    };

    return replacePlaceholders(text, placeholderParam);
  };

  useEffect(() => {
    if (resList.isSuccess) {
      if (resList?.data?.data?.length > 0) {
        let option: any = [];
        resList.data.data.map((item: any) => {
          const values = {
            value: item.id || 0,
            label: item.title,
            subject: item.subject,
            body: item.body,
            attachments: item.attachments,
            table: item.table || null,
            tr: item.tr || null,
            placeholders: item.placehoder_reference || null,
            subjectmultiple: item.subjectmultiple || item.subject,
            bodymultiple: item.bodymultiple || item.body,
            table_container: item.table_container || null,
          } as templateInt;
          option.push(values);
          if (values.value == 0) {
            setDefTemplate(values);
            setTFuObj(values);
            if (isAll) {
              setquilNotes(item.bodymultiple);
            } else {
              setquilNotes(jobTitlePh(item.body));
            }
          }
        });
        setTemplate(option);
      }
    }
  }, [resList.isSuccess]);

  useEffect(() => {
    if (isOpen) {
      let ccOption = [] as any;
      Object.values(userList).map((item: any) => {
        ccOption.push({
          label: [item.first_name, item.last_name].filter(Boolean).join(' '),
          value: item.email,
        });
      });
      setCcBcc(ccOption);
    }
  }, [isOpen]);

  const ccBc = (email: any) => {
    let options: any = [];
    try {
      email.map((item: any) => {
        if (item !== '' && item !== null && item !== undefined) {
          options.push({
            label: item,
            value: item,
          });
        }
      });
    } catch (e) {
      console.warn('e', e);
    }
    return options;
  };

  const initialValues = {
    to: jobData?.lead?.primary_email,
    cc: dataCc,
    bcc: dataBcc,
    subject: isAll ? defTemplate.subjectmultiple : defTemplate.subject,
    body: isAll ? defTemplate.bodymultiple : defTemplate.body,
    primary_resume: true,
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      to: Yup.string()
        .email('Invalid email.')
        .required('Email to is required.'),
    })
  );

  useEffect(() => {
    listCandidates();
  }, [selectedCandidates]);

  const placholders = (text: string, values: any) => {
    const work_status = candidate_work_status
      .filter(Boolean)
      .filter((item: any) => Number(item.id) == Number(values.work_status));

    const placeholderParam = {
      ...placehoder,

      candidate_work_status: work_status?.[0]?.status ?? '',
      job_title: jobData?.title || '',
      contact_first_name: jobData?.lead?.first_name || '',
      job_id: jobData?.id,
    };

    return replacePlaceholders(text, placeholderParam);
  };

  const ccBccValue = (email: any) => {
    let mails = [] as any;
    try {
      email.map((item: any) => {
        mails.push(item.value);
      });
    } catch (e) {
      return mails;
    }
    return mails;
  };

  const onSubmit = (e: any) => {
    const data = {
      ...e,
      cc: ccBccValue(e.cc),
      bcc: ccBccValue(e.bcc),
      body: placholders(e.body, e),
      subject: placholders(e.subject, e),
      candidates: selectedCandidates.map(
        ({ candidate_id, status_id, job_id }: any) => ({
          candidate_id,
          status_id,
          job_id,
        })
      ),
    };
    // console.log('data', data);
    reqPostFollowUpCandidates({
      id: jobData?.lead_id || 0, // TODO: Which id to be used
      data,
    });
  };

  const toResumeUrl = (id: any, name: any) => {
    const url = to_resume_url(id);
    console.log('url', { url, id });
    return `<a href="${url}" target="_blank">${name}</a>`;
  };

  const listCandidates = async () => {
    // tFuObj;
    //follow-up-container for multiple
    const tr = tFuObj.tr;
    const htmlString = quilNotes;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    if (!isAll) {
      const element = doc.getElementById(`fj-${jobData?.id}`);
      let html: string = '';
      if (selectedCandidates.length > 0) {
        await Promise.all(
          selectedCandidates.map((item: any) => {
            // console.log('item', item);
            const placeholders = {
              job_id: jobData?.id,
              candidate_full_name: toResumeUrl(item.candidate_id, item.name),
              status: item.status,
              submitted_interview_date: moment
                .utc(item.date)
                .format('MM/DD/YYYY'),
            } as any;
            // console.log('placeholders', placeholders);
            try {
              html += replacePlaceholders(tr, placeholders);
            } catch (e) {
              console.warn('e', e);
            }
          })
        );
      } else {
        html = tr;
      }

      if (element) {
        element.innerHTML = html;
        const serializer = new XMLSerializer();
        const newHtmlString = serializer.serializeToString(doc);
        setquilNotes(newHtmlString);
        // console.log("newHtmlString")
      }
    } else {
      const container = doc.getElementById(`follow-up-container`);
      const candidates = [...selectedCandidates];
      let grouped = [] as any;
      if (candidates?.length > 0) {
        candidates?.map((item: any) => {
          if (item) {
            if (!grouped[item?.job_id]) {
              grouped[item?.job_id] = [] as any;
            }
            grouped[item?.job_id].push(item);
          }
        });

        if (grouped.length > 0) {
          //table_content
          //table_list
          let content = ``;
          grouped.map((groupings: any) => {
            let tr_content = ``;
            let job_title = '';
            groupings.map((item: any) => {
              job_title = item?.job_title;
              const placeholders = {
                job_id: item?.job_id,
                candidate_full_name: toResumeUrl(item.candidate_id, item.name),
                status: item.status,
                submitted_interview_date: moment
                  .utc(item.date)
                  .format('MM/DD/YYYY'),
              } as any;
              try {
                tr_content += replacePlaceholders(tr, placeholders);
              } catch (e) { }
            });
            if (tr_content != '') {
              const ph = {
                tbody: tr_content,
              };
              const table_content = replacePlaceholders(tFuObj.table, ph);
              const container_ph = {
                table_content,
                job_title,
              };

              content += replacePlaceholders(
                tFuObj.table_container,
                container_ph
              );
            }
          });

          if (container) {
            container.innerHTML = content;
            const serializer = new XMLSerializer();
            const newHtmlString = serializer.serializeToString(doc);
            setquilNotes(newHtmlString);
          }
        }
      }
    }
  };

  const onCcInputChange = (e: any) => {
    if (e.length > 2) {
      setCcOption(ccBcc);
    } else {
      setCcOption([]);
    }
  };

  const onBccInputchange = (e: any) => {
    if (e.length > 2) {
      setBccOption(ccBcc);
    } else {
      setBccOption([]);
    }
  };

  const jobSelectChange = (e: any) => {
    setJobSelect(e);
  };

  useEffect(() => {
    reqListFollowUpCandidates({
      job_id: isAll
        ? jobSelect.map((val: any) => Number(val.value))
        : [jobData.id],
      status_id: [isSubmit && 1, isInterview && 3],
    });
  }, [isInterview, isSubmit, jobSelect, jobData]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        onClose();
        setJobSelect([]);
        dispatch(listJobs({ byContactCandidates: [] }));
      }}
      size="full"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="Proxima Nova Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          <Flex justifyContent="space-between">
            <Flex sx={{ gap: '16px', alignItems: 'center' }}>
              <Box mr="8px">
                {isAll ? 'Follow-up for All Jobs' : 'Follow-up'}
              </Box>
              <Box>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  defaultChecked={isSubmit}
                  name="primary"
                  id="primary"
                  onChange={(e) => {
                    setIsSubmit(e.target.checked);
                  }}
                >
                  Submit
                </Checkbox>
              </Box>
              <Box>
                <Checkbox
                  colorScheme="purple"
                  color="default.secondarytext"
                  defaultChecked={isInterview}
                  name="primary"
                  id="primary"
                  onChange={(e) => {
                    setIsInterview(e.target.checked);
                  }}
                >
                  Interview
                </Checkbox>
              </Box>
            </Flex>
            {isAll ? (
              <Box
                sx={{
                  mr: '30px',
                  '.chakra-select__icon-wrapper': {
                    color: 'primary.800',
                  },
                }}
              >
                <AtsSelect
                  name="job_select"
                  options={jobSelectOptions}
                  value={jobSelect || []}
                  variant="outline"
                  onChange={jobSelectChange}
                  isMulti
                  styles={{
                    placeholder: (styles: any) => ({
                      ...styles,
                      fontSize: '14px',
                      color: '#A0AEC0',
                    }),
                    control: (styles: any) => ({
                      ...styles,
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      cursor: 'pointer',
                      boxShadow: 'none',
                      minWidth: '400px',
                      maxWidth: '400px',
                      '&:hover': { border: '1px solid #E2E8F0' },
                    }),
                    valueContainer: (styles: any) => ({
                      ...styles,
                      overflowY: 'scroll',
                      maxHeight: '70px',
                    }),
                    dropdownIndicator: (styles: any) => ({
                      ...styles,
                      color: '#6930CA',
                      zIndex: 9999999,
                    }),
                    multiValue: (styles: any) => ({
                      ...styles,
                      background: '#F7FAFC',
                      border: '1px solid #E2E8F0',
                      borderRadius: '6px',
                      '&& div:first-of-type': {
                        color: '#6930CA',
                        fontWeight: 600,
                      },
                    }),
                  }}
                  components={{
                    Option: ({ label, innerProps }: any) => (
                      <Box onClick={(e) => innerProps.onClick(e)}>
                        <Flex
                          sx={{
                            fontFamily: 'Proxima Nova Regular',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '12px 24px',
                            cursor: 'pointer',
                          }}
                          _hover={{
                            backgroundColor: 'rgba(239, 229, 255, 0.4)',
                            color: 'primary.800',
                          }}
                        >
                          <Box
                            sx={{
                              fontWeight: 600,
                              fontSize: '12px',
                            }}
                          >
                            {label}
                          </Box>
                        </Flex>
                      </Box>
                    ),
                  }}
                />
              </Box>
            ) : (
              <Box
                id={`tagTooltip`}
                color="primary.600"
                fontSize="12px"
                lineHeight="18px"
                fontWeight={500}
                height="fit-content"
                mr="30px"
              >
                <Link
                  href={`/jobs/${jobData.id}/details`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {jobData.title}
                </Link>
              </Box>
            )}
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          sx={{
            margin: isAll && '20px 0',
            top: isAll ? 0 : '13px',
            right: '20px',
            color: 'default.secondarytext',
          }}
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box width="100%">
                <AlertTitle>Oh snap!</AlertTitle>
                {/* <AlertDescription>{errMsg}</AlertDescription> */}
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody
          borderRadius="0 0 4px 4px"
          p={0}
          overflow="hidden"
          height="100vh"
        >
          {resListFollowUpCandidates.isLoading || resList.isLoading ? (
            <FollowUpLoading />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />

                  <Flex>
                    <Box
                      width="60%"
                      height="90vh"
                      py={2}
                      borderRight="1px solid"
                      borderColor="default.borderlight"
                      px={3}
                      pr={0}
                    >
                      <FollowUpTable />
                    </Box>

                    <Box
                      width="40%"
                      p="32px"
                      sx={{ overflowY: 'scroll', height: '90vh' }}
                    >
                      <>
                        <Box mb="10px">
                          <FormControl
                            isInvalid={Boolean(!!errors.to && touched.to)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              To
                            </FormLabel>
                            <Input
                              id="to"
                              name="to"
                              type="email"
                              variant="outline"
                              value={values.to}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.to)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                        <Box mb="10px">
                          <FormControl
                            isInvalid={Boolean(!!errors.cc && touched.cc)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Cc
                            </FormLabel>
                            <AtsCraetableSelect
                              id="cc"
                              name="cc"
                              onChange={(event: any) =>
                                setFieldValue('cc', event)
                              }
                              options={ccOption}
                              isMulti={true}
                              placeholder="cc"
                              defaultValue={values.cc}
                              onInputChange={onCcInputChange}
                            />

                            <FormErrorMessage>
                              {String(errors.cc)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                        <Box mb="10px">
                          <FormControl
                            isInvalid={Boolean(!!errors.bcc && touched.bcc)}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Bcc
                            </FormLabel>
                            <AtsCraetableSelect
                              id="bcc"
                              name="bcc"
                              onChange={(event: any) => {
                                setFieldValue('bcc', event);
                              }}
                              options={bccOption}
                              isMulti={true}
                              placeholder="bcc"
                              defaultValue={values.bcc}
                              onInputChange={onBccInputchange}
                            />

                            <FormErrorMessage>
                              {String(errors.cc)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                        <Box mb="10px">
                          <FormControl>
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Template
                            </FormLabel>
                            <Select
                              placeholder="Select"
                              isMulti={false}
                              options={template}
                              id="template"
                              // defaultValue={defTemplate}
                              defaultValue={{
                                value: '',
                                label: 'Follow-up',
                              }}
                              onChange={(e: any) => {
                                setTFuObj(e);
                                if (isAll) {
                                  setquilNotes(e.bodymultiple);
                                  setFieldValue('body', e.bodymultiple);
                                  setFieldValue('subject', e.subjectmultiple);
                                } else {
                                  setquilNotes(jobTitlePh(e.body));
                                  setFieldValue('body', jobTitlePh(e.body));
                                  setFieldValue('subject', e.subject);
                                }
                                setAttachments(e.attachments);
                              }}
                            />
                          </FormControl>
                        </Box>
                        <Box mb="34px">
                          <FormControl
                            isInvalid={Boolean(
                              !!errors.subject && touched.subject
                            )}
                          >
                            <FormLabel
                              fontSize="14px"
                              lineHeight="18px"
                              color="default.secondarytext"
                            >
                              Subject{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </FormLabel>
                            <Input
                              id="subject"
                              name="subject"
                              type="text"
                              variant="outline"
                              placeholder="Subject"
                              value={values.subject}
                              onChange={handleChange}
                            />
                            <FormErrorMessage>
                              {String(errors.subject)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                        <Box mb="250px">
                          <FormControl
                            isInvalid={Boolean(!!errors.body && touched.body)}
                          >
                            <Text mb="10px" fontWeight={700}>
                              Body{' '}
                              <Box as="span" color="caution.800">
                                *
                              </Box>
                            </Text>
                            <AtsEmailBody
                              id="notes"
                              value={quilNotes}
                              height={250}
                              onChange={(e: any) => {
                                setquilNotes(e);
                                setFieldValue('body', e);
                              }}
                            />
                            <FormErrorMessage>
                              {String(errors.body)}
                            </FormErrorMessage>
                          </FormControl>
                        </Box>
                      </>
                    </Box>
                  </Flex>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Flex sx={{ gap: '10px', alignItems: 'center' }}>
                      <Box>
                        <Checkbox
                          colorScheme="purple"
                          color="default.secondarytext"
                          defaultChecked={values.primary_resume}
                          name="primary_resume"
                          id="primary_resume"
                          onChange={handleChange}
                        >
                          Attach Primary Resume
                        </Checkbox>
                      </Box>
                      <Button onClick={() => openPreview()}>
                        <Flex gap="10px" dir="column" alignItems="center">
                          <FAIcon iconName="eye" />
                          <Box>Preview</Box>
                        </Flex>
                      </Button>
                      <Button
                        variant="solid"
                        type="submit"
                        disabled={
                          isOpenAlert || resPostFollowUpCandidates.isLoading
                        }
                        loading={resPostFollowUpCandidates.isLoading}
                        onClick={handleSubmit}
                      //   loading={resSubmit.isLoading}
                      >
                        Send Follow-up
                      </Button>
                    </Flex>

                    {isOpenPreview && (
                      <EmailPreviewModal
                        data={{
                          ...values,
                          attachments,
                          resume: `${selectedVersion?.original_file_name}.${selectedVersion?.file_name.split('.').splice(-1)[0]
                            }`,
                        }}
                        isOpen={isOpenPreview}
                        onClose={onClosePreview}
                        onSubmit={handleSubmit}
                        disabled={
                          isOpenAlert || resPostFollowUpCandidates.isLoading
                        }
                        isLoading={resPostFollowUpCandidates.isLoading}
                        isFollowUp
                      />
                    )}
                  </ModalFooter>
                </form>
              )}
            </Formik>
          )}
          {/* <FollowUpLoading /> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

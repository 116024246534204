import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import ScrollToFieldError from 'components/app/ScrollError';
import FAIcon from 'components/lib/FAIcon';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  listCandidates,
  useListCandidatesMutation,
} from 'store/candidates.slice';

export default function JobsScreen() {
  document.documentElement.dir = 'ltr';
  const dispatch = useDispatch();
  const params = useParams();

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const storageName = 'candidatesFilterRejected';
  const defaultParam = localStorage.getItem(storageName) as any;
  const initialValue = {
    search: defaultParam?.query || '',
  };

  let subs: any = [];
  subs['submits'] = [1];
  subs['screens'] = [2];
  subs['interviews'] = [3];
  subs['offers'] = [4];
  subs['hired'] = [5];
  subs['rejected'] = [6];

  let status: any;

  if (params?.jobsTabId) {
    switch (params?.jobsTabId) {
      case 'screen':
        status = [2];
        break;
      case 'in-progress':
        status = params?.jobsTabNav ? subs[params.jobsTabNav] : [1];
        break;
      case 'hired':
        status = [5];
        break;
      case 'rejected':
        status = [6];
        break;
      default:
        status = null;
    }
  }

  const [reqCandidates, resCandidates] = useListCandidatesMutation();

  const onSubmit = (data: any) => {
    let newParams = {
      ...candidatePgBtn,
      page: 1,
      take: candidatePgBtn.take,
      query: data.search,
      ...(params.jobsTabId !== 'candidate-pool' && { status: status }),
      ...(data?.job_id &&
        params.jobsTabId === 'screen' && { job_id: data.job_id }),
    };
    submitForm(newParams);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        ...candidatePgBtn,
        page: 1,
        take: candidatePgBtn.take,
        query: '',
      };
      submitForm(params);
    }
  };

  const submitForm = (params: any) => {
    dispatch(
      listCandidates({
        candidatePgBtn: params,
      })
    );
    reqCandidates(params);
    localStorage.setItem(storageName, JSON.stringify(params));
  };

  return (
    <>
      <Flex justifyContent="space-between" px={6} pt="8px">
        <Flex gap="10px">
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Box pr={2}>
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <InputGroup background="#fff">
                    <InputLeftElement
                      cursor="pointer"
                      children={
                        <Box sx={{ color: 'primary.800' }}>
                          <FAIcon iconName="search" />
                        </Box>
                      }
                    />
                    <Input
                      type="search"
                      placeholder="Search by name, phone, email"
                      value={values.search}
                      id="search"
                      width="300px"
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </form>
              </Box>
            )}
          </Formik>
        </Flex>
      </Flex>
    </>
  );
}

import { useEffect, useState } from 'react';
import { Field, FieldArray, Formik } from 'formik';

import {
  Box,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  RadioGroup,
  Stack,
  Radio,
  FormLabel,
  Flex,
  FormErrorMessage,
  Button,
  Checkbox,
} from '@chakra-ui/react';

import ScrollToFieldError from 'components/app/ScrollError';
import AtsSelect from 'components/app/AtsSelect';
import FAIcon from 'components/lib/FAIcon';

import { useUserListMutation } from 'store/user.slice';
import { useSelector } from 'react-redux';
import { useSkillListMutation } from 'store/skills.slice';
import { useTagListingMutation } from 'store/tag.slice';
import FilterInputs from '../components/FilterInputs';
import {
  useCreateCandidateFilterMutation,
  useDelCandidateFilterMutation,
  useListCandidateFilterMutation,
  usePinCandidateFilterMutation,
  usePinListCandidateFilterMutation,
  useUpdateCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import {
  useGetCandidateSourceMutation,
  useGetCandidatetatusMutation,
  useGetClientTypesMutation,
  useGetContactTypesMutation,
  useGetEmpTypeMutation,
  useGetWorkTypesMutation,
} from 'store/constant.slice';
import { AtsConfirm } from 'utils/swal';
import { swalContent } from 'types';
import { useJobTitleSearchMutation } from 'store/jobs.slice';
import { useContactListMutation } from 'store/contact.slice';
import { useAllListClientsMutation } from 'store/client.slice';
import { useListCandidatesMutation } from 'store/candidates.slice';

interface CandidateViewModalProps {
  isOpen: any;
  onClose: any;
  candidateViewData: any;
  isEdit?: boolean;
  id?: number;
}

export default function CandidateViewModal({
  isOpen,
  onClose,
  candidateViewData,
  isEdit = false,
  id = 0,
}: CandidateViewModalProps) {
  const { candidate_filter_columns, filter_operators } = useSelector(
    (state: any) => state.constants
  );
  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const { skillPageBtn } = useSelector((state: any) => state.skills);

  const { tagsPageBtn } = useSelector((state: any) => state.tags);

  const [reqCreate, resCreate] = useCreateCandidateFilterMutation();
  const [reqUpdate, resUpdate] = useUpdateCandidateFilterMutation();
  const [reqList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();
  const [reqCandidateList] = useListCandidatesMutation();
  const [reqDel, resDel] = useDelCandidateFilterMutation();
  const [reqCandidateStat] = useGetCandidateSourceMutation();
  const [reqWorkTypes] = useGetWorkTypesMutation();
  const [reqEmptypes] = useGetEmpTypeMutation();
  const [reJobs] = useJobTitleSearchMutation();

  const [reqUser, resUser] = useUserListMutation();
  const [reqSkills] = useSkillListMutation();
  const [reqTags] = useTagListingMutation();
  const [reqContacts] = useContactListMutation();
  const [reqClients] = useAllListClientsMutation();
  const [reqClientTypes] = useGetClientTypesMutation();
  const [reqContactTypes] = useGetContactTypesMutation();
  const [reqCandidateStatus] = useGetCandidatetatusMutation();

  const [hasOperator, setHasOperator] = useState(
    candidateViewData.operators || []
  );
  const [users, setUsers] = useState();

  useEffect(() => {
    if (isOpen) {
      reqUser({});
      reqWorkTypes({});
      reqEmptypes({});
      reJobs({ query: '' });
      reqContacts({ query: '' });
      reqClients({ query: '' });
      reqClientTypes({});
      reqContactTypes({});
      reqCandidateStatus({});
    }
  }, [isOpen]);

  useEffect(() => {
    reqSkills(skillPageBtn);
    // reqCandidateStat({});
  }, [skillPageBtn]);

  useEffect(() => {
    reqTags(tagsPageBtn);
  }, [tagsPageBtn]);

  useEffect(() => {
    if (isOpen) {
      reqList(candidatePgBtn); // need for view pin & filter
      reqPinList(candidatePgBtn);
    }
  }, [candidatePgBtn, isOpen, reqList, reqPinList]);

  useEffect(() => {
    if (resCreate.isSuccess || resUpdate.isSuccess) {
      reqCandidateList(candidatePgBtn);
      onClose();
    }
  }, [
    resCreate.isSuccess,
    resUpdate.isSuccess,
    reqList,
    reqPinList,
    candidatePgBtn,
    onClose,
    reqCandidateList,
  ]);

  useEffect(() => {
    if (resDel.isSuccess) {
      reqList(candidatePgBtn); // need for view pin & filter
      reqPinList(candidatePgBtn);
      reqCandidateList(candidatePgBtn);
      onClose();
    }
  }, [
    resDel.isSuccess,
    reqList,
    reqPinList,
    candidatePgBtn,
    onClose,
    reqCandidateList,
  ]);

  useEffect(() => {
    let users: any = [];
    if (resUser.isSuccess) {
      if (resUser.data?.data.length > 0) {
        Promise.all(
          resUser.data?.data.map((item: any) => {
            users.push({
              label: [item.first_name, item.last_name].join(' '),
              value: item.id,
            });
          })
        );
      }
    }
    setUsers(users);
  }, [resUser.data?.data, resUser.isSuccess]);

  const formatQuery = (query: any) => {
    if (query.value) {
      return query.value;
    }
    return query;
  };

  const submitFilter = (data: any) => {
    console.log('data', data);
    const mappedModule = data.module.map((item: any, key: number) => ({
      column: item.criteria.value,
      condition: item.condition.value,
      value: formatQuery(item.query),
      operator: hasOperator[key] ? hasOperator[key].operator : '',
      order: key,
    }));

    let users: any = [];
    data.user?.map((item: any) => {
      users.push(item?.value);
    });

    const mappedData = {
      title: data.title,
      shareable: data.share,
      filters: mappedModule,
      users,
      group_by_job: data.group_by_job,
    };
    // console.log('mappedData', mappedData);
    if (!isEdit) {
      reqCreate(mappedData);
    }
    if (isEdit) {
      reqUpdate({ data: mappedData, id });
    }
  };

  const hasModule = (moduleData: any) => {
    let module: any = [];

    if (moduleData?.length > 0) {
      moduleData?.map((mod: any) => {
        module.push({
          criteria:
            candidate_filter_columns.length > 0 &&
            candidate_filter_columns
              .filter((val: { label: any }) => val.label === mod.criteria)
              .map((item: any) => ({
                value: item.id,
                label: item.label,
                ...item,
              }))[0],
          condition:
            filter_operators.length > 0 &&
            filter_operators
              .filter((val: { label: any }) => val.label === mod.condition)
              .map((item: any) => ({
                value: item.id,
                label: item.label,
                ...item,
              }))[0],
          query: mod.query,
        });
      });
    }

    if (module.length > 0) {
      return module;
    }
  };

  const deleteFilter = async () => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to delete this view`,
      buttons: ['Cancel', 'Delete'],
      icon: 'warning',
    };

    const confirm = await AtsConfirm(content);
    if (confirm) {
      reqDel({ id });
    }
  };

  const initialValues: any =
    Object.keys(candidateViewData).length > 0
      ? {
          ...candidateViewData,
          module: hasModule(candidateViewData?.module),
        }
      : {
          title: 'Untitled View',
          module: [
            {
              criteria: { value: 'null', label: '' },
              condition: { value: 'null', label: '' },
              query: '',
            },
          ],
          share: 'only me',
          operators: [],
          user: [],
          group_by_job: false,
        };
  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Candidate View
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik initialValues={initialValues} onSubmit={submitFilter}>
              {({
                values,
                errors,
                handleSubmit,
                handleChange,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <FormControl w="300px">
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Title
                        </FormLabel>
                        <Input
                          name="title"
                          type="text"
                          placeholder="Title"
                          variant="outline"
                          value={values.title}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Box>

                    <Box
                      pb="15px"
                      color="default.primarytext"
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="18px"
                    >
                      Specify Criteria
                    </Box>

                    <Box background="#fff" borderRadius="8px">
                      <FieldArray name="module">
                        {({ remove, push }) => (
                          <Flex flexWrap="wrap">
                            {values.module?.length > 0 &&
                              values.module.map((mod: any, index: number) => {
                                const criteria = `module[${index}].criteria`;
                                const condition = `module[${index}].condition`;
                                const query = `module[${index}].query`;

                                const handleQueryValue = (e: any) => {
                                  if (e.value === 4) {
                                    return setFieldValue(query, '${EMPTY}');
                                  } else if (e.value === 5) {
                                    return setFieldValue(query, '${NOTEMPTY}');
                                  }
                                  // return setFieldValue(query, '');
                                };
                                return (
                                  <Flex key={index} flexDirection="column">
                                    <FilterInputs
                                      handleChange={handleChange}
                                      setFieldValue={setFieldValue}
                                      index={index}
                                      criteria={criteria}
                                      candidate_filter_columns={
                                        candidate_filter_columns
                                      }
                                      condition={condition}
                                      filter_operators={filter_operators}
                                      query={query}
                                      mod={mod}
                                      values={values}
                                      setHasOperator={setHasOperator}
                                      handleQueryValue={handleQueryValue}
                                      remove={remove}
                                    />
                                    {values.module.length > 1 &&
                                      values.module.length !== index + 1 && (
                                        <Flex
                                          onClick={() =>
                                            setHasOperator(
                                              (prevOperators: any[]) =>
                                                prevOperators.map(
                                                  (operator: {
                                                    id: number;
                                                    operator: string;
                                                  }) =>
                                                    operator.id === index
                                                      ? {
                                                          ...operator,
                                                          operator:
                                                            operator.operator ===
                                                            'AND'
                                                              ? 'OR'
                                                              : 'AND',
                                                        }
                                                      : operator
                                                )
                                            )
                                          }
                                          cursor="pointer"
                                          width="30px"
                                          alignItems="center"
                                          flexDirection="column"
                                          ml="-2px"
                                        >
                                          <Box lineHeight="0.7">|</Box>
                                          <Box fontSize="12px" lineHeight="1">
                                            {hasOperator[index]?.operator}
                                          </Box>
                                          <Box lineHeight="0.7">|</Box>
                                        </Flex>
                                      )}
                                  </Flex>
                                );
                              })}
                            <Box ml="10px">
                              <Button
                                onClick={() => {
                                  push({
                                    criteria: '',
                                    condition: '',
                                    query: '',
                                  });
                                  setHasOperator((prev: any) => [
                                    ...prev,
                                    {
                                      id: hasOperator.length,
                                      operator: 'AND',
                                    },
                                  ]);
                                }}
                              >
                                <FAIcon iconName="plus" />
                              </Button>
                            </Box>
                          </Flex>
                        )}
                      </FieldArray>
                    </Box>

                    <Box
                      pt="32px"
                      pb="15px"
                      color="default.primarytext"
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="18px"
                    >
                      Share this with:
                    </Box>

                    <RadioGroup defaultValue={values.share}>
                      <Stack spacing={2} direction="column">
                        <Radio
                          colorScheme="purple"
                          name="share"
                          onChange={handleChange}
                          value="only me"
                        >
                          Only me
                        </Radio>
                        <Radio
                          colorScheme="purple"
                          name="share"
                          onChange={handleChange}
                          value="everyone"
                        >
                          Everyone
                        </Radio>
                        <Flex gap="16px">
                          <Radio
                            colorScheme="purple"
                            name="share"
                            onChange={handleChange}
                            value="selected users"
                          >
                            Selected User
                          </Radio>

                          {values.share === 'selected users' && (
                            <FormControl width="300px">
                              <Field
                                name="user"
                                id="user"
                                options={users}
                                component={AtsSelect}
                                value={values.user}
                                onChange={(e: any) => {
                                  setFieldValue('user', e);
                                }}
                                isMulti
                              />
                              <FormErrorMessage>
                                {String(errors.user)}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Flex>
                      </Stack>
                    </RadioGroup>

                    <FormControl pt="32px" pb="15px">
                      <Checkbox
                        colorScheme="purple"
                        color="default.primarytext"
                        onChange={handleChange}
                        name="group_by_job"
                        id="group_by_job"
                        defaultChecked={values.group_by_job}
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        Group by Jobs
                      </Checkbox>
                    </FormControl>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                    gap="16px"
                  >
                    {isEdit && (
                      <Button
                        variant="ghost"
                        onClick={() => deleteFilter()}
                        isLoading={resDel.isLoading}
                        disabled={resCreate.isLoading || resUpdate.isLoading}
                      >
                        Delete
                      </Button>
                    )}

                    <Button
                      variant="solid"
                      onClick={() => handleSubmit()}
                      isLoading={resCreate.isLoading || resUpdate.isLoading}
                      disabled={resDel.isLoading}
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useMsGraphGetTokenMutation } from 'store/msgraph.slice';
import LoadingPage from '../Loading';

export default function MsGraphOuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const href = window.location.href.split('#');
  if (href.length > 1) {
    window.location.href = href.join('?');
  }
  const code = queryParameters.get('code');
  const client_info = queryParameters.get('client_info');
  const state = queryParameters.get('state');
  const session_state = queryParameters.get('session_state');
  const [reqGetToken, resGetToken] = useMsGraphGetTokenMutation();
  const [loadingText, setLoadingText] = useState('Requesting for token...');

  const { graphAccessToken, graphEndPoint } = useSelector(
    (state: any) => state.msgraph
  );

  useEffect(() => {
    if (code != null && state != null) {
      const data = {
        code,
        state,
      };
      reqGetToken(data);
    }
  }, [code, state]);

  useEffect(() => {
    if (resGetToken.isLoading) {
      setLoadingText('Acquiring token...');
    }
    if (resGetToken.isSuccess) {
      setLoadingText('Token Acquired');
      navigate('/msgraph/me');
    }
  }, [resGetToken.isLoading, resGetToken.isSuccess]);

  useEffect(() => {
    if (resGetToken.isError) {
      const error: any = resGetToken.error;
      try {
        setLoadingText(error['data']['error']['message']);
      } catch (e) {
        console.warn(e);
        setLoadingText('Uknown error occured');
      }
    }
  }, [resGetToken.isError]);

  return (
    <Box
      fontFamily="Proxima Nova Regular"
      padding="8px 16px"
      style={{ minHeight: '88vh' }}
    >
      <LoadingPage text={loadingText} />
    </Box>
  );
}

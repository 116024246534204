import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Formik, FieldArray, Field, ErrorMessage, getIn } from 'formik';
import * as Yup from 'yup';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
  Divider,
} from '@chakra-ui/react';

import { ChevronLeftIcon } from '@chakra-ui/icons';
import {
  useCreateJobAdsMutation,
  useGetJobsMutation,
  useListJobsMutation,
  useUpdateJobAdsMutation,
} from 'store/jobs.slice';
import Button from 'Library/Button';
import { useGetWorkTypesMutation } from 'store/constant.slice';
import AtsSelect from 'components/app/AtsSelect';
import AtsEmailBody from 'components/app/EmailBody';
import { useSearchGeoTypesMutation } from 'store/geo.slice';
import { number } from 'yup';
import { debounce } from 'lodash';
import AtsAutoComplete from 'components/app/Leads/components/Autocomplete';
import ScrollToFieldError from 'components/app/ScrollError';

interface JobsModalProps {
  isOpen: any;
  onClose: any;
  isEdit?: boolean;
  jobAdsData?: any[];
  update?: boolean;
}

const JobsAdsModal = ({
  isOpen,
  onClose,
  jobAdsData,
  update = false,
}: JobsModalProps) => {
  const toast = useToast();

  const { jobData, jobPgBtn } = useSelector((state: any) => state.jobs);
  const { work_types } = useSelector((state: any) => state.constants);

  const [reqJobs] = useListJobsMutation();
  const [reqGetJob] = useGetJobsMutation();
  const [reqCreateJobAds, resCreateJobAds] = useCreateJobAdsMutation();
  const [reqUpdateJobAds, resUpdateJobAds] = useUpdateJobAdsMutation();
  const [reqWorkTypes] = useGetWorkTypesMutation();
  // const [reqSpecs] = useGetJobSpecsMutation();

  const jobAds = jobAdsData?.map((ad: any) => {
    return {
      title: ad.title,
      state: ad.state,
      city: ad.city,
      country: ad?.country,
      description: ad.description,
      career_page_publish: ad.career_page_publish,
      show_profile: ad.show_profile,
      free_job_board_publish: ad.free_job_board_publish,
      paid_job_board_publish: ad.paid_job_board_publish,
      active: ad.active,
      worktype_id: ad.worktype_id,
      // specification_id: ad.spec_id,
    };
  });

  const initialValues: any = {
    job_ads: jobAds || [
      {
        title: '',
        state: jobData.location?.state || '',
        city: jobData.location?.city || '',
        country: jobData.location?.country?.country || '',
        description: '',
        career_page_publish: true,
        show_profile: false,
        free_job_board_publish: true,
        paid_job_board_publish: true,
        active: 1,
        worktype_id: jobData?.work_type_id || null,
        // specification_id: jobData?.spec_id || null,
      },
    ],
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      job_ads: Yup.array().of(
        Yup.object().shape({
          title: Yup.string().required('Title is required'),
          state: Yup.string(),
          city: Yup.string().required('City is required'),
          country: Yup.string().required('Country is required'),
          description: Yup.string(),
          career_page_publish: Yup.boolean(),
          show_profile: Yup.boolean(),
          free_job_board_publish: Yup.boolean(),
          paid_job_board_publish: Yup.boolean(),
          active: Yup.number(),
          worktype_id: Yup.number().required('Work type is required'),
          // specification_id: Yup.number().required('Industry is required.'),
        })
      ),
    })
  );

  const tabListIndex: any = [{ id: 0, title: 'Job Ads' }];

  const [tabIndex, setTabIndex] = useState(tabListIndex[0]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const onSubmit = async (data: any) => {
    if (jobAdsData && update) {
      reqUpdateJobAds({ data: data.job_ads[0], id: jobAdsData[0].id });
      setTabIndex(tabListIndex[0]);
    } else {
      await reqCreateJobAds({ data: data.job_ads[0], id: jobData.id });
      setTabIndex(tabListIndex[0]);
    }
  };

  useEffect(() => {
    reqWorkTypes({});
    // reqSpecs({});
    if (resCreateJobAds.isSuccess || resUpdateJobAds.isSuccess) {
      onCloseAlert();
      onClose();
      reqJobs(jobPgBtn);
      reqGetJob({ id: jobData.id });

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (resCreateJobAds.isSuccess) {
          title = 'Added Job Ad';
          description = 'New job ad successfully added';
        } else if (resUpdateJobAds.isSuccess) {
          title = 'Updated Job Ad';
          description = 'The job ad has been updated';
          status = 'info';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    } else if (resCreateJobAds.isError || resUpdateJobAds.isError) {
      onOpen();
    }
  }, [
    jobPgBtn,
    onClose,
    onCloseAlert,
    onOpen,
    reqJobs,
    resCreateJobAds.isError,
    resCreateJobAds.isSuccess,
    jobData.id,
    reqGetJob,
    resUpdateJobAds.isSuccess,
    resUpdateJobAds.isError,
    reqWorkTypes,
    toast,
  ]);

  const { geo_location } = useSelector((state: any) => state.geolocation);

  const [itemLocation, setItemLocation] = useState([]);
  const [reqGeo, resGeo] = useSearchGeoTypesMutation();
  const [currentName, setName] = useState('');

  useEffect(() => {
    setGeoItem();
  }, [geo_location, currentName]);

  const setGeoItem = () => {
    if (geo_location.length > 0) {
      let optios: any = [];
      geo_location.map((item: any, key: number) => {
        const value = currentName == 'city' ? item.city : item.postal_code;
        optios.push({
          city: item.city,
          country: item.country,
          postal_code: item.postal_code,
          country_code: item.country_code,
          state: item.state,
          value: value,
          label: geoFormat(item),
          id: number,
        });
      });
      setItemLocation(optios);
    } else {
      setItemLocation([]);
    }
  };

  const geoFormat = (item: any) => {
    let format: any = '';
    try {
      const cityB = currentName == 'city' ? { fontWeight: 'bold' } : {};
      const postB = currentName == 'postal_code' ? { fontWeight: 'bold' } : {};
      format = (
        <>
          <Flex gap="2px">
            <Text style={postB}>{item?.postal_code}</Text>
            <Text style={cityB}>{item?.city}</Text>
            <Text>{item?.state}</Text>
            <Text>{item?.country}</Text>
          </Flex>
        </>
      );
    } catch (e) {
      format = 'Error formatting';
    }
    return format;
  };

  const geoLocationChange = debounce((e: any, column: string) => {
    const search = e.target.value;
    if (search.length > 3) {
      const data = {
        search,
        column,
      };
      reqGeo(data);
    }
  }, 500);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
        }}
        size="5xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="#F4F6F9"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            {jobAdsData ? 'Edit Job Ads' : 'Create New Job Ads'}
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>
                    There was an error processing your request. Change a few
                    things up and try again.
                  </AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="Proxima Nova Regular"
            overflow="hidden"
          >
            <Tabs colorScheme="purple" variant="unstyled" index={tabIndex.id}>
              <Flex gap={2} background="#F9F9FB">
                <Box
                  width="30%"
                  py={4}
                  overflowY="hidden"
                  borderRight="1px solid"
                  borderColor="transparent"
                  px={6}
                >
                  <TabList
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="8px"
                  >
                    {tabListIndex.map((tab: any) => {
                      return (
                        <Tab
                          key={`tabsJA-${tab.id}`}
                          justifyContent="space-between"
                          width="100%"
                          border="0.5px solid rgba(155, 155, 155, 0.5)"
                          borderRadius="3px"
                          background="#fff"
                          p="12px 20px"
                          lineHeight="18px"
                          fontSize="14px"
                          fontWeight="600"
                          _selected={{
                            bg: '#EFE5FF',
                            border: '0.5px solid #EFE5FF',
                          }}
                          onClick={() => setTabIndex(tabListIndex[tab.id])}
                        >
                          {tab.title}
                          <Icon
                            as={ChevronLeftIcon}
                            transition="all .25s ease-in-out"
                            transform={isOpen ? 'rotate(180deg)' : ''}
                            w={6}
                            h={6}
                            ml="8px"
                            color={
                              tabIndex.id === tab.id ? 'primary.800' : '#fff'
                            }
                          />
                        </Tab>
                      );
                    })}
                  </TabList>
                </Box>

                <Box width="70%">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      values,
                      handleChange,
                      handleSubmit,
                      errors,
                      touched,
                      isValid,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <ScrollToFieldError />
                        <TabPanels>
                          <TabPanel>
                            {/* Job Ads */}
                            <Box
                              overflow="auto"
                              maxHeight="450px"
                              p="30px"
                              background="#fff"
                              borderRadius="8px"
                              minHeight="65vh"
                            >
                              <FieldArray name="job_ads">
                                {({ remove, push }) => (
                                  <div>
                                    {values.job_ads &&
                                      values.job_ads.length > 0 &&
                                      values.job_ads.map(
                                        (job_ad: any, index: number) => {
                                          // const specification = `job_ads[${index}].specification_id`;
                                          const title = `job_ads[${index}].title`;
                                          const worktype = `job_ads[${index}].worktype_id`;
                                          return (
                                            <>
                                              <Box
                                                className="row"
                                                key={`jobadsArr-${index}`}
                                              >
                                                <FormControl
                                                  mb="34px"
                                                  isInvalid={Boolean(
                                                    !!getIn(errors, title) &&
                                                      getIn(touched, title)
                                                  )}
                                                >
                                                  <Flex
                                                    justifyContent="space-between"
                                                    alignItems="flex-end"
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      Posting Title
                                                    </FormLabel>
                                                    {!jobAdsData &&
                                                      values.job_ads &&
                                                      values.job_ads.length >
                                                        0 && (
                                                        <Box mb="10px">
                                                          <Button
                                                            leftIcon="close"
                                                            onClick={() =>
                                                              remove(index)
                                                            }
                                                            size="sm"
                                                          >
                                                            Remove
                                                          </Button>
                                                        </Box>
                                                      )}
                                                  </Flex>
                                                  <Field
                                                    autoFocus
                                                    as={Input}
                                                    id={title}
                                                    name={title}
                                                    placeholder="Posting Title"
                                                    type="text"
                                                    variant="outline"
                                                    value={job_ad.title}
                                                    onChange={handleChange}
                                                  />
                                                  <FormErrorMessage>
                                                    {getIn(errors, title)}
                                                  </FormErrorMessage>
                                                </FormControl>

                                                <Flex gap="32px" mb="34px">
                                                  <FormControl
                                                    // mb="34px"
                                                    isInvalid={Boolean(
                                                      !!getIn(
                                                        errors,
                                                        worktype
                                                      ) &&
                                                        getIn(touched, worktype)
                                                    )}
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      Work Type
                                                    </FormLabel>
                                                    <AtsSelect
                                                      name={worktype}
                                                      id={worktype}
                                                      placeholder="Select"
                                                      variant="outline"
                                                      defaultValue={
                                                        work_types
                                                          .map((item: any) => ({
                                                            label: item.name,
                                                            value: item.id,
                                                          }))
                                                          .filter(
                                                            (val: any) =>
                                                              val.value ===
                                                              job_ad.worktype_id
                                                          )[0] ||
                                                        job_ad.worktype_id
                                                      }
                                                      onChange={(e: any) =>
                                                        setFieldValue(
                                                          worktype,
                                                          String(e.value)
                                                        )
                                                      }
                                                      options={
                                                        work_types?.length >
                                                          0 &&
                                                        work_types.map(
                                                          (item: any) => ({
                                                            label: item.name,
                                                            value: item.id,
                                                          })
                                                        )
                                                      }
                                                    />
                                                    <FormErrorMessage>
                                                      {getIn(errors, worktype)}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                  <FormControl
                                                    isInvalid={Boolean(
                                                      !!(
                                                        errors.job_ads as any[]
                                                      )?.[index]?.city &&
                                                        (
                                                          touched.job_ads as any[]
                                                        )?.[index]?.city
                                                    )}
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      City
                                                    </FormLabel>

                                                    <AtsAutoComplete
                                                      name={`job_ads.${index}.city`}
                                                      placeholder="City"
                                                      type="text"
                                                      variant="outline"
                                                      value={job_ad?.city}
                                                      onChange={(e: any) => {
                                                        setFieldValue(
                                                          `job_ads.${index}.city`,
                                                          e.target.value
                                                        );
                                                      }}
                                                      onFocus={() =>
                                                        setName('city')
                                                      }
                                                      options={itemLocation}
                                                      onKeyUp={(e: any) =>
                                                        geoLocationChange(
                                                          e,
                                                          'city'
                                                        )
                                                      }
                                                      isLoading={
                                                        resGeo.isLoading
                                                      }
                                                      onSelect={(item: any) => {
                                                        setFieldValue(
                                                          `job_ads.${index}.city`,
                                                          item.value
                                                        );

                                                        setFieldValue(
                                                          `job_ads.${index}.state`,
                                                          item.state
                                                        );
                                                        setFieldValue(
                                                          `job_ads.${index}.city`,
                                                          item.city
                                                        );
                                                        setFieldValue(
                                                          `job_ads.${index}.country`,
                                                          item.country
                                                        );
                                                      }}
                                                    />

                                                    <FormErrorMessage>
                                                      {String(
                                                        (
                                                          errors.job_ads as any[]
                                                        )?.[index]?.city
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                </Flex>
                                                <Flex gap="32px" mb="34px">
                                                  <FormControl
                                                    isInvalid={Boolean(
                                                      !!(
                                                        errors.job_ads as any[]
                                                      )?.[index]?.state &&
                                                        (
                                                          touched.job_ads as any[]
                                                        )?.[index]?.state
                                                    )}
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      State / Province
                                                    </FormLabel>
                                                    <Input
                                                      id={`job_ads.${index}.state`}
                                                      name={`job_ads.${index}.state`}
                                                      type="text"
                                                      placeholder="State / Province"
                                                      variant="outline"
                                                      value={job_ad?.state}
                                                      onChange={handleChange}
                                                    />
                                                    <FormErrorMessage>
                                                      {String(
                                                        (
                                                          errors.job_ads as any[]
                                                        )?.[index]?.state
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                  <FormControl
                                                    isInvalid={Boolean(
                                                      !!(
                                                        errors.job_ads as any[]
                                                      )?.[index]?.country &&
                                                        (
                                                          touched.job_ads as any[]
                                                        )?.[index]?.country
                                                    )}
                                                  >
                                                    <FormLabel
                                                      fontSize="14px"
                                                      lineHeight="18px"
                                                      color="default.secondarytext"
                                                    >
                                                      Country
                                                    </FormLabel>
                                                    <Input
                                                      id={`job_ads.${index}.country`}
                                                      name={`job_ads.${index}.country`}
                                                      type="text"
                                                      placeholder="Country"
                                                      variant="outline"
                                                      value={job_ad?.country}
                                                      onChange={handleChange}
                                                    />
                                                    <FormErrorMessage>
                                                      {String(
                                                        (
                                                          errors.job_ads as any[]
                                                        )?.[index]?.country
                                                      )}
                                                    </FormErrorMessage>
                                                  </FormControl>
                                                </Flex>
                                                {/* <AddressComponent
                                                  setFieldValue={setFieldValue}
                                                  handleChange={handleChange}
                                                  cityField={{
                                                    name: city,
                                                    value: job_ad.city,
                                                    errors: !!getIn(
                                                      errors,
                                                      city
                                                    ),
                                                    touched: getIn(
                                                      touched,
                                                      city
                                                    ),
                                                  }}
                                                  stateField={{
                                                    name: `job_ads.${index}.state`,
                                                    value: job_ad.state,
                                                  }}
                                                  countryField={{
                                                    name: country,
                                                    value: job_ad.country,
                                                    errors: !!getIn(
                                                      errors,
                                                      country
                                                    ),
                                                    touched: getIn(
                                                      touched,
                                                      country
                                                    ),
                                                  }}
                                                /> */}
                                                <Box mb="34px">
                                                  <FormLabel
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                    mb="16px"
                                                  >
                                                    Publish On
                                                  </FormLabel>
                                                  <Flex gap="32px">
                                                    <Flex
                                                      flexDir="column"
                                                      gap="5px"
                                                    >
                                                      <Checkbox
                                                        id={`job_ads.${index}.career_page_publish`}
                                                        name={`job_ads.${index}.career_page_publish`}
                                                        isChecked={
                                                          job_ad.career_page_publish
                                                        }
                                                        onChange={handleChange}
                                                      >
                                                        Careers Page
                                                      </Checkbox>
                                                      <Checkbox
                                                        id={`job_ads.${index}.show_profile`}
                                                        name={`job_ads.${index}.show_profile`}
                                                        isChecked={
                                                          job_ad.show_profile
                                                        }
                                                        onChange={handleChange}
                                                      >
                                                        Show Profile on Careers
                                                        page
                                                      </Checkbox>
                                                    </Flex>
                                                    <Box>
                                                      <Checkbox
                                                        id={`job_ads.${index}.free_job_board_publish`}
                                                        name={`job_ads.${index}.free_job_board_publish`}
                                                        isChecked={
                                                          job_ad.free_job_board_publish
                                                        }
                                                        onChange={handleChange}
                                                      >
                                                        Free Job boards
                                                      </Checkbox>
                                                    </Box>
                                                    <Box>
                                                      <Checkbox
                                                        id={`job_ads.${index}.paid_job_board_publish`}
                                                        name={`job_ads.${index}.paid_job_board_publish`}
                                                        isChecked={
                                                          job_ad.paid_job_board_publish
                                                        }
                                                        onChange={handleChange}
                                                      >
                                                        Paid Job boards
                                                      </Checkbox>
                                                    </Box>
                                                  </Flex>
                                                </Box>
                                                <Box mb="34px">
                                                  <FormLabel
                                                    mb="8px"
                                                    fontSize="14px"
                                                    lineHeight="18px"
                                                    color="default.secondarytext"
                                                  >
                                                    Job Description
                                                  </FormLabel>
                                                  {/* <ReactQuill
                                                    id={`job_ads.${index}.description`}
                                                    theme="snow"
                                                    value={job_ad.description}
                                                    onChange={(e: any) => {
                                                      setFieldValue(
                                                        `job_ads.${index}.description`,
                                                        e
                                                      );
                                                    }}
                                                    placeholder="Type what ever you need here..."
                                                  /> */}
                                                  <AtsEmailBody
                                                    id={`job_ads.${index}.description`}
                                                    value={job_ad.description}
                                                    onChange={(e: any) => {
                                                      setFieldValue(
                                                        `job_ads.${index}.description`,
                                                        e
                                                      );
                                                    }}
                                                    refocusTo={title}
                                                  />
                                                  <Box color="red.700">
                                                    <ErrorMessage
                                                      name={`job_ads.${index}.description`}
                                                      className="alert alert-danger"
                                                    />
                                                  </Box>
                                                </Box>
                                              </Box>
                                              <Divider mb="50px" />
                                            </>
                                          );
                                        }
                                      )}
                                    {!jobAdsData && (
                                      <Button
                                        leftIcon="plus"
                                        onClick={() =>
                                          push({
                                            title: '',
                                            state: '',
                                            city: '',
                                            country: '',
                                            description: '',
                                            career_page_publish: true,
                                            show_profile: false,
                                            free_job_board_publish: true,
                                            paid_job_board_publish: true,
                                            active: 1,
                                            worktype_id: null,
                                            // specification_id: null,
                                          })
                                        }
                                      >
                                        {values.job_ads &&
                                        values.job_ads.length > 0
                                          ? 'Add Another Job Ad'
                                          : 'Add Job Ad'}
                                      </Button>
                                    )}
                                  </div>
                                )}
                              </FieldArray>
                            </Box>
                          </TabPanel>
                          <ModalFooter
                            position="sticky"
                            bottom="0"
                            background="#F9F9FB"
                            mb="20px"
                            mr="20px"
                            gap="16px"
                          >
                            <Button
                              variant="solid"
                              onClick={() => {
                                console.log(errors);
                                handleSubmit();
                                !isValid && onOpen();
                              }}
                              loading={
                                resCreateJobAds.isLoading ||
                                resUpdateJobAds.isLoading
                              }
                              disabled={
                                isOpenAlert ||
                                resCreateJobAds.isLoading ||
                                resUpdateJobAds.isLoading
                              }
                            >
                              Save
                            </Button>
                          </ModalFooter>
                        </TabPanels>
                      </form>
                    )}
                  </Formik>
                </Box>
              </Flex>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default JobsAdsModal;

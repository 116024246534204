import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import Button from 'Library/Button';

import { downloadLink } from 'utils/downloadlink';
import { AtsConfirm } from 'utils/swal';

import { swalContent } from 'types';

import GoTo from 'assets/images/goto.svg';
import {
  useDelCandidateResumeMutation,
  useListResumeCandidateMutation,
} from 'store/candidateresume.slice';
import TrLoading from '../TrLoading';

const CandidatesAttachments = () => {
  const { candidateData } = useSelector((state: any) => state.candidates);
  const { resumes } = useSelector((state: any) => state.candidateResume);
  const [reqAttachment, resAttachments] = useListResumeCandidateMutation();
  const [reqDel, resDel] = useDelCandidateResumeMutation();

  const tableHeader: { label: string }[] = [
    { label: 'File Name' },
    { label: 'Uploaded By' },
    { label: 'Uploaded On' },
    { label: '' },
  ];

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this file.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const delAttachment = async (id: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDel({ id });
    }
  };

  useEffect(() => {
    if (resDel.isSuccess) {
      reqAttachment({ id: candidateData.id });
    }
  }, [resDel.isSuccess]);

  return (
    <Box>
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
      >
        <Table>
          <Thead>
            <Tr bg="default.white.800">
              {tableHeader.map((title) => (
                <Th key={title.label}>
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {resAttachments.isLoading ? (
              <TrLoading rows={3} columns={4} />
            ) : (
              resumes &&
              resumes.map((item: any, key: number) => {
                return (
                  <Tr key={key} cursor="pointer" onClick={() => {}}>
                    <Td>
                      <Flex alignItems="center" color="primary.800">
                        <a
                          href={downloadLink(
                            item.id,
                            '/candidate-resume/download/'
                          )}
                          target="_blank"
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                        >
                          {item.original_file_name}
                          <span>
                            <Image src={GoTo} alt="logo" />
                          </span>
                        </a>
                      </Flex>
                    </Td>
                    <Td>
                      {[item.user?.first_name, item.user?.last_name].join(' ')}
                    </Td>
                    <Td>
                      {moment(item.created_at).format('MM/DD/YYYY hh:mm A')}
                    </Td>
                    <Td>
                      <Menu>
                        <MenuButton onClick={() => delAttachment(item.id)}>
                          <Button
                            htmlType="menu"
                            variant="ghost"
                            iconName="trash"
                            iconSize="xl"
                          />
                        </MenuButton>
                      </Menu>
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CandidatesAttachments;

import swal from 'sweetalert';
import { swalContent } from 'types';

const AtsConfirm = async (content: swalContent) => {
  return await swal(content).then((value) => {
    return value;
  });
};

const RejectAts = async (data: any) => {
  let rejectSwal = {
    title: 'Reason for rejection',
    content: {
      element: 'textarea',
      attributes: {
        placeholder: 'Type reason',
      },
    },
    buttons: ['Cancel', 'Reject'],
    icon: 'warning',
  };

  return await swal(rejectSwal).then((value) => {
    let content = (
      document.querySelector('.swal-content__textarea') as HTMLInputElement
    ).value;
    return [value, content];
  });
};

const RejectOptionsAts = async (data: any, options: any) => {
  const options2: any = {
    option1: 'Option 1',
    option2: 'Option 2',
    option3: 'Option 3',
  };

  const inputOptions = Object.keys(options2).reduce((acc: any, key) => {
    acc[key] = options[key];
    return acc;
  }, {});

  let rejectSwal = {
    title: 'Reason for rejection',
    input: 'select',
    inputOptions,
    inputPlaceholder: 'Select reason',
    showCancelButton: true,
    showLoaderOnConfirm: true,
    buttons: ['Cancel', 'Reject'],
    icon: 'warning',
    preConfirm: (selectedOption: any) => {
      // Handle the selected option here if needed
      return selectedOption;
    },
  };

  return await swal(rejectSwal).then((value) => {
    let content = (
      document.querySelector('.swal-content__textarea') as HTMLInputElement
    ).value;
    return [value, content];
  });
};

export { AtsConfirm, RejectAts, RejectOptionsAts };

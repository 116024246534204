import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
} from '@chakra-ui/react';

export default function InterviewLoading() {
  return (
    <Box p="32px">
      <FormControl mb="24px">
        <FormLabel
          fontSize="14px"
          lineHeight="18px"
          color="default.secondarytext"
        >
          Subject{' '}
          <Box as="span" color="caution.800">
            *
          </Box>
        </FormLabel>
        <Skeleton height={30} />
      </FormControl>
      <FormLabel
        fontSize="14px"
        lineHeight="18px"
        color="default.secondarytext"
      >
        Date & Time{' '}
        <Box as="span" color="caution.800">
          *
        </Box>
      </FormLabel>

      <Flex mb="24px" gap="10px">
        <FormControl>
          <Skeleton height={30} />
        </FormControl>
        <FormControl>
          <Skeleton height={30} />
        </FormControl>
        <FormControl>
          <Skeleton height={30} />
        </FormControl>
      </Flex>
      <Flex mb="24px" gap="10px">
        <FormControl>
          <FormLabel
            fontSize="14px"
            lineHeight="18px"
            color="default.secondarytext"
          >
            Timezone{' '}
            <Box as="span" color="caution.800">
              *
            </Box>
          </FormLabel>
          <Skeleton height={30} />
        </FormControl>
        <FormControl mb="24px">
          <FormLabel
            fontSize="14px"
            lineHeight="18px"
            color="default.secondarytext"
          >
            Job{' '}
            <Box as="span" color="caution.800">
              *
            </Box>
          </FormLabel>
          <Skeleton height={30} />
        </FormControl>
      </Flex>
      <FormLabel
        fontSize="14px"
        lineHeight="18px"
        color="default.secondarytext"
      >
        Meeting Mode{' '}
        <Box as="span" color="caution.800">
          *
        </Box>
      </FormLabel>
      <Flex gap="20px">
        <FormControl mb="24px">
          <Skeleton height={30} />
        </FormControl>

        <FormControl mb="24px">
          <Skeleton height={30} />
        </FormControl>
      </Flex>
      <FormLabel
        fontSize="14px"
        lineHeight="18px"
        color="default.secondarytext"
      >
        Panel Members
      </FormLabel>

      <Skeleton height={30} />
    </Box>
  );
}

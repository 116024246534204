import { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  getCandidate,
  useGetPanelMembersMutation,
  useListCandidatesMutation,
  useSubmitInterviewMutation,
} from 'store/candidates.slice';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useListCandidateInterviewMutation } from 'store/candidateinterview.slice';
import { useGetJobsMutation, useOpenJobsMutation } from 'store/jobs.slice';
import InterviewLoading from './Loading';
import replacePlaceholders from 'utils/textPlaceholders';
import { cleanUpload } from 'store/uploads.slice';
import InterviewDetails from './details';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import InterviewConfirmation from './confirmation';
import { setPairTemplate } from 'store/template.slice';

interface CandidatesInterviewModalProps {
  isOpen: any;
  onClose: any;
  associate?: number;
  subject?: string;
  isJobView?: boolean;
  jobsId?: number;
  contactOptionValue?: any;
  callback?: () => void;
}
interface CandidatePairingIntParam {
  candidate_full_name: string;
  candidate_first_name: string;
  candidate_email: string;
  candidate_current_position: string;
  candidate_current_company: string;
  candidate_mobile: string;
  candidate_linkedin_url: string;
  candidate_work_status: string;
  job_url: string;
  job_name_url: string;
  job_title: string;
  job_location: string;
  job_contact_name: string;
  job_contact_first_name: string;
  job_company_name: string;
  job_primary_recruiter_name: string;
  job_ad_description: string;
  job_contact_title: string;
  internal_job_description: string;
  interview_date: string;
  interview_location: string;
  interview_type: string;
}
interface optionInterface {
  label: string;
  value: any;
}

export default function CandidatesInterviewModal({
  isOpen,
  onClose,
  associate = null,
  subject,
  isJobView = false,
  jobsId = null,
  callback = null,
  contactOptionValue = [],
}: CandidatesInterviewModalProps) {
  const params = useParams();
  const toast = useToast();
  const dispatch = useDispatch();
  const { interviewBody } = useSelector(
    (state: any) => state.candidatesInterview
  );
  const { candidateData, candidatePgBtn } = useSelector(
    (state: any) => state.candidates
  );
  const { userList } = useSelector((state: any) => state.user);
  const { openJobs, jobData } = useSelector((state: any) => state.jobs);
  const { candidateInterviewPgBtn } = useSelector(
    (state: any) => state.candidatesInterview
  );
  const { placehoderPair } = useSelector((state: any) => state.template);
  const { uploading, uploaded } = useSelector((state: any) => state.uploads);
  const [panelOptions, setPanelOptions] = useState([]);

  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqGetJob] = useGetJobsMutation();
  const [placehoder, setPlaceHoders] = useState<any>();
  const [userOptions, setUserOptions] = useState([]);

  const [reqList] = useListCandidateInterviewMutation();
  const [reqSubmitInterview, resSubmitInterview] = useSubmitInterviewMutation();
  const [reqOpen, resOpen] = useOpenJobsMutation();
  const [reqGetPanel, resGetPanel] = useGetPanelMembersMutation();
  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );
  const [reqPair, resPair] = usePlaceHolderPairMutation();

  const [selectedDate, setSelectedDate] = useState(
    moment.tz('US/Pacific').format('MM-DD-YYYY')
  );

  const [minDate, setMinDate] = useState(moment.tz(moment(), 'US/Pacific'));
  const [interviewTab, setInterviewTab] = useState(1);

  useEffect(() => {
    if (isOpen) {
      const data = {
        candidate_id: candidateData?.id || null,
        job_id: params?.jobsId || jobsId || null,
      } as any;
      reqPair(data).then((res) => {
        const data = res as any;
        setPlaceHoders(data?.data?.data);
      });

      let userOptions = [] as any;
      Promise.all(
        Object.values(userList).map((item: any) => {
          userOptions.push({
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            value: item.email,
          });
        })
      ).then(() => {
        setUserOptions(userOptions);
      });
    }
  }, [isOpen]);

  // Query Panel Members (Leads and Contacts of Same Client ID)
  useEffect(() => {
    if (jobData?.client?.id) {
      reqGetPanel({ id: jobData?.client?.id }).then((result: any) => {
        let options: optionInterface[] = [];
        result?.data?.data?.map((panel: any) => {
          options.push({
            label: [panel?.first_name, panel?.last_name].join(' '),
            value: panel?.id,
          });
        });
        setPanelOptions(options);
      });
    }
  }, [jobData]);

  function generateTimeOptions(
    startMinutes: number,
    numOptions: number,
    minDate: any
  ) {
    return Array.from({ length: numOptions }, (_, index) => {
      let totalMinutes: number = index * 30 + startMinutes;
      const hours = Math.floor(totalMinutes / 60) % 12 || 12;
      const minutes = totalMinutes % 60;
      const period = totalMinutes < 720 ? 'AM' : 'PM';
      const formattedHour = hours.toString().padStart(2, '0');
      const formattedMinute = minutes.toString().padStart(2, '0');
      const label = `${formattedHour}:${formattedMinute} ${period}`;
      const value = totalMinutes;
      const currDate = moment(minDate).format('MM-DD-YYYY');

      const currDateInMins = minDate.hours() * 60 + minDate.minutes();
      const convertedSelectedDate = moment(selectedDate).format('MM-DD-YYYY');

      const isDisabled =
        currDate < convertedSelectedDate
          ? false
          : currDate === convertedSelectedDate
            ? totalMinutes <= currDateInMins
            : true;
      return { label, value, isDisabled };
    });
  }

  const [currentDateInMin, setCurrentDateInMin] = useState(
    minDate.hours() * 60 + minDate.minutes()
  );
  const minimumStartTime =
    480 >= currentDateInMin
      ? { label: '08:00 AM', value: 480, isDisabled: false }
      : generateTimeOptions(0, 95, minDate).find(
        (timeOption: any) => timeOption.value >= currentDateInMin
      );

  const minimumEndTime =
    480 >= currentDateInMin
      ? { label: '08:30 AM', value: 510, isDisabled: false }
      : generateTimeOptions(30, 95, minDate).find(
        (timeOption: any) => timeOption.value >= currentDateInMin + 30
      );

  let initialValues = {
    subject: subject
      ? subject
      : `Video Interview | <Job TItle>  | ${candidateData?.first_name} ${candidateData?.last_name}/<Client Name>`,
    date: moment.tz('US/Pacific').format('MM/DD/YYYY'),
    start_time: minimumStartTime,
    end_time: minimumEndTime,
    meeting_mode: {
      label: 'Ms Team',
      value: 'Video Interview',
    },
    meeting_location: '',
    timezone: { label: 'US/Pacific (UTC-07:00)', value: 'US/Pacific' },
    panel_members: contactOptionValue,
    job_id: params?.jobsId || jobsId || '',
    body: interviewBody?.body || '',
    attachment: [] as any,
    primary: true,
  };

  const [detailInitial, setDetailInitial] = useState(initialValues);

  const getPrimary = () => {
    let email = '';
    try {
      const firstemail =
        candidateData?.emails?.length > 0 ? candidateData?.emails[0] : null;
      const data =
        candidateData?.emails?.find((item: any) => item.primary === 1) ||
        firstemail;
      email = data?.email || '';
    } catch (e) { }
    return email;
  };

  const initialValueConfirm = {
    to: getPrimary(),
    cc: [] as any,
    subject: '',
    body: '',
    attachment: [] as any,
    auto_interview: true,
    template: null as any,
  };

  const [confirmInitial, setConfirmInitial] = useState(initialValueConfirm);
  const [submitDetails, setSubmitDetails] = useState({} as any);

  const convertToMilitaryTime = (time12Hour: any) => {
    const [time, period] = time12Hour.split(' ');

    let [hours, minutes] = time.split(':');
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  const getPrimaryMobile = (contact: any) => {
    try {
      let mobile = '';

      contact.map((item: any) => {
        if (item.primary && item.type == 'mobile') {
          mobile = item?.number;
        }
      });

      return mobile;
    } catch (e) {
      return '';
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (candidateData) {
        const candidate_full_name = candidateData?.data
          ? [candidateData?.data?.first_name, candidateData?.data?.last_name]
            .filter(Boolean)
            .join(', ')
          : [candidateData?.first_name, candidateData?.last_name]
            .filter(Boolean)
            .join(', ') || '';
        const candidate_first_name =
          candidateData?.data?.first_name ||
          candidateData?.first_name ||
          placehoderPair?.first_name;
        const candidate_email =
          candidateData?.data?.primary_email ||
          candidateData?.primary_email ||
          placehoderPair?.candidate_email;
        const candidate_current_position =
          candidateData?.data?.latest_job_title ||
          candidateData?.latest_job_title ||
          '';
        const candidate_current_company =
          candidateData?.data?.latest_employer ||
          candidateData?.latest_employer ||
          '';
        const candidate_mobile =
          getPrimaryMobile(candidateData?.data?.contact) ||
          placehoderPair?.candidate_mobile;
        const candidate_linkedin_url = candidateData?.linkedin_url;
        const candidate_work_status = candidateData?.work_status?.status || '';
        let job_url = '';
        let job_name_url = '';
        let job_title = '';
        let job_location = '';
        let job_contact_name = '';
        let job_contact_first_name = '';
        let job_company_name = '';
        let job_primary_recruiter_name = '';
        let job_ad_description = '';
        let job_contact_title = '';
        let internal_job_description = '';
        let interview_date = '';
        let interview_location = '';
        let interview_type = '';
        if (params?.jobsId || jobsId) {
          job_title = jobData?.title || '';
          job_location =
            [
              jobData?.data?.location?.city,
              jobData?.data?.location?.state,
              jobData?.data?.location?.country?.country,
            ]
              .filter(Boolean)
              .join(', ') || '';
          job_contact_name =
            [jobData?.lead?.first_name, jobData?.lead?.last_name]
              .filter(Boolean)
              .join(', ') || '';
          job_contact_first_name = jobData?.lead?.first_name || '';
          job_company_name = jobData?.client?.name || '';
          job_primary_recruiter_name =
            [
              jobData?.primary?.recruiter?.first_name,
              jobData?.primary?.recruiter?.last_name,
            ]
              .filter(Boolean)
              .join(', ') || '';
          job_contact_title =
            jobData?.lead?.title || placehoderPair?.job_contact_title || '';
          internal_job_description =
            jobData?.data?.description ||
            placehoderPair?.internal_job_description ||
            '';
          if (jobData?.jobAds?.length > 0) {
            const ads = jobData?.jobAds?.[0];
            job_ad_description =
              ads?.description || placehoderPair?.job_ad_description;
            if (ads) {
              const url = `https://careers.talently.com/job-details/${ads?.id}`;
              job_url = `<a href="${url}" target="_blank">${url}</a>`;
              job_name_url = `<a href="${url}" target="_blank">${ads.title}</a>`;
            }
          }
        }

        const candidateParam = {
          candidate_full_name,
          candidate_first_name,
          candidate_email,
          candidate_current_position,
          candidate_current_company,
          candidate_mobile,
          candidate_linkedin_url,
          candidate_work_status,
          job_url,
          job_name_url,
          job_title,
          job_location,
          job_contact_name,
          job_contact_first_name,
          job_company_name,
          job_primary_recruiter_name,
          job_ad_description,
          job_contact_title,
          internal_job_description,
          interview_date,
          interview_location,
          interview_type,
        } as CandidatePairingIntParam;

        candidatePairing(candidateParam);
      }
    }
  }, [isOpen]);

  const candidatePairing = async (param: CandidatePairingIntParam) => {
    const newpair = {
      ...placehoderPair,
      candidate_full_name: param.candidate_full_name,
      candidate_first_name: param.candidate_first_name,
      candidate_email: param.candidate_email,
      candidate_current_position: param.candidate_current_position,
      candidate_current_company: param.candidate_current_company,
      candidate_mobile: param.candidate_mobile,
      candidate_linkedin_url: param.candidate_linkedin_url,
      candidate_work_status: param.candidate_work_status,
      job_url: param.job_url,
      job_name_url: param.job_name_url,
      job_title: param.job_title,
      job_location: param.job_location,
      job_contact_name: param.job_contact_name,
      job_contact_first_name: param.job_contact_first_name,
      job_company_name: param.job_company_name,
      job_primary_recruiter_name: param.job_primary_recruiter_name,
      job_ad_description: param.job_ad_description,
      job_contact_title: param.job_contact_title,
      internal_job_description: param.internal_job_description,
      interview_date: param.interview_date,
      interview_location: param.interview_location,
      interview_type: param.interview_type,
    };

    setPlaceHoders(newpair);
    await dispatch(setPairTemplate({ placehoderPair: newpair }));
  };

  const placeholders = (text: string) => {
    return replacePlaceholders(text, placehoderPair);
  };

  const finalPlaceholders = (text: string) => {
    // Revert interview location which have "Ms Team" type back to {{interview_location}}
    // as this will be changed into the actual teams url in the api
    if (placehoderPair?.interview_type === 'Ms Team') {
      const newPlaceholderPair = {
        ...placehoderPair,
      };
      delete newPlaceholderPair.interview_location;
      return replacePlaceholders(text, newPlaceholderPair);
    }
    return replacePlaceholders(text, placehoderPair);
  };

  const onSubmitNext = (data: any) => {
    const panelMembers =
      data.panel_members.length > 0
        ? data.panel_members.map((member: any) => member.value)
        : [];

    const newParam = {
      subject: finalPlaceholders(data.subject),
      meeting_type: data.meeting_mode.value,
      meeting_date: data.date,
      meeting_start: convertToMilitaryTime(data.start_time.label),
      meeting_end: convertToMilitaryTime(data.end_time.label),
      meeting_location: data.meeting_location,
      meeting_timezone: data.timezone.value,
      panel_members: panelMembers,
      associate: associate,
      job_id: data.job_id,
      body: finalPlaceholders(data.body),
      attachments: data.attachment,
      primary: data.primary,
    };
    setSubmitDetails(newParam);
    setDetailInitial(data);
    setInterviewTab(2);
  };

  const onPevClick = (data: any) => {
    setConfirmInitial(data);
    setInterviewTab(1);
  };

  const onSubmit = (data: any) => {
    const cc =
      data.cc.length > 0 ? data.cc.map((member: any) => member.value) : [];

    const confirmation = {
      attachment: data.attachment,
      auto_interview: data.auto_interview,
      body: finalPlaceholders(data.body),
      cc: cc,
      subject: finalPlaceholders(data.subject),
      to: data.to,
    };
    const formData = {
      ...submitDetails,
      confirmData: confirmation,
    };
    // console.log('formData', formData);
    reqSubmitInterview({ data: formData, id: candidateData.id });
  };
  // Query Panel Members (Leads and Contacts of Same Client ID)

  useEffect(() => {
    const reload = async () => {
      const title = 'Submit Interview';
      const description = 'Interview successfully submitted';
      toast({
        title: title,
        description: description,
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top',
      });
      await dispatch(cleanUpload({ attachments: [] }));
      await dispatch(cleanUpload({ uploaded: [] }));
      onClose();
      if (candidateData?.id) {
        reqList({
          data: candidateInterviewPgBtn,
          id: candidateData.id,
        });
      }
      if (isJobView) {
        setTimeout(async () => {
          await reqCandidates(candidatePgBtn);
        }, 1000);

        await reqGetJob({ id: params?.jobsId });
      }
      if (callback) {
        callback();
      }
    };
    if (resSubmitInterview.isSuccess) {
      setTimeout(reload, 100);
    }
  }, [resSubmitInterview.isSuccess]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (resSubmitInterview.isError) {
      openAlert();
      reqList({
        data: candidateInterviewPgBtn,
        id: candidateData.candidatesId,
      });
      const error: any = resSubmitInterview.error;
      try {
        setErrorMsg(error.data.error.message);
      } catch (e) {
        setErrorMsg('Unknown error occured.');
      }
    }
  }, [resSubmitInterview.isError]);

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        contactOptionValue = [];
        dispatch(getCandidate({ panelMembers: {} }));
        dispatch(cleanUpload({ attachments: [] }));
        dispatch(cleanUpload({ uploaded: [] }));
        onClose();
      }}
      size="6xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent fontFamily="Proxima Nova Regular">
        <ModalHeader
          background="default.white.800"
          borderRadius="4px 4px 0"
          p="18px 32px"
          fontSize="18px"
          lineHeight="21.92px"
          textAlign="left"
        >
          Schedule Interview
        </ModalHeader>
        <ModalCloseButton
          top="13px"
          right="20px"
          color="default.secondarytext"
        />
        {isOpenAlert && (
          <Box>
            <Alert status="error" justifyContent="space-around">
              <AlertIcon />
              <Box width="100%">
                <AlertTitle>Oh snap!</AlertTitle>
                <AlertDescription>{errMsg}</AlertDescription>
              </Box>
              <CloseButton
                position="relative"
                right={-1}
                top={-3}
                onClick={onCloseAlert}
              />
            </Alert>
          </Box>
        )}
        <ModalBody borderRadius="0 0 4px 4px" p={0}>
          {resOpen.isLoading ? (
            <InterviewLoading />
          ) : interviewTab == 1 ? (
            <InterviewDetails
              onSubmit={(e: any) => onSubmitNext(e)}
              initialValues={detailInitial}
              jobData={jobData}
              candidateData={candidateData}
              jobsId={params?.jobsId || jobsId || false}
              openJobs={openJobs}
              placeholders={(e: any) => placeholders(e)}
              panelOptions={panelOptions}
              candidatePairing={candidatePairing}
              userOptions={userOptions}
            />
          ) : (
            <InterviewConfirmation
              initialvalues={confirmInitial}
              onSubmit={(e: any) => onSubmit(e)}
              onPrev={(e: any) => onPevClick(e)}
              placeholders={(e: any) => placeholders(e)}
              panelOptions={panelOptions}
              loading={resSubmitInterview.isLoading}
              userOptions={userOptions}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

import { Formik } from 'formik';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from '@chakra-ui/react';

import ScrollToFieldError from 'components/app/ScrollError';
import { useGetSubStatusMutation } from 'store/constant.slice';
import { useEffect, useState } from 'react';
import {
  useBulkRejectCandidateMutation,
  useListCandidatesMutation,
  useRejectCandidateMutation,
} from 'store/candidates.slice';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useGetJobsMutation } from 'store/jobs.slice';
import AtsSelect3 from 'components/app/AtsSelect3';

interface ChangeStatusModalProps {
  isOpen: any;
  onClose: any;
  id: number;
  idList?: number[];
  drawerOnClose?: any;
  rejectType?: string;
  jobsId?: number;
  isBulkReject?: boolean;
  callback?: () => void;
}

export default function RejectModal({
  isOpen,
  onClose,
  id,
  idList,
  drawerOnClose = () => {},
  rejectType = null,
  jobsId = null,
  isBulkReject = false,
  callback = null,
}: ChangeStatusModalProps) {
  const initialValues = {
    reason: {
      value: 14,
      label: 'Recruiter Reject',
    },
  };

  const params = useParams();

  // const dispatch = useDispatch();

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);

  const [reSub, resSub] = useGetSubStatusMutation();
  const [status, setStatus] = useState([]);
  const [reqReject, resReject] = useRejectCandidateMutation();
  const [reqBulkReject, resBulkReject] = useBulkRejectCandidateMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqGetJob] = useGetJobsMutation();

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      reason: Yup.object()
        .shape({
          value: Yup.number().required(),
          label: Yup.string().required(),
        })
        .required('Reason is required'),
    })
  );

  // console.log('params', params.jobsTabId);

  useEffect(() => {
    reSub({ id: 6 });
  }, [id]);

  useEffect(() => {
    const data: any = resSub.data;
    const target =
      (params.jobsTabId || rejectType) == 'hired' ? 'hired' : 'other';
    let options = [] as any;
    if (data?.data) {
      data?.data.map((item: any) => {
        if (target == item.target) {
          options.push({
            value: item.id,
            label: item.sub_status,
          });
        }
      });
    }
    setStatus(options);
  }, [resSub.data, resSub.isSuccess]);

  const handleSubmit = (data: any) => {
    data.reason = data.reason.value;
    if (isBulkReject) {
      data.idList = idList;
      reqBulkReject({ data });
    } else {
      reqReject({ id, data });
    }
  };

  useEffect(() => {
    if (resReject.isSuccess || resBulkReject.isSuccess) {
      let Newparams = {
        ...candidatePgBtn,
        page: candidatePgBtn.page,
        take: candidatePgBtn.take,
        query: candidatePgBtn.query,
        location: candidatePgBtn.location,
        tags: candidatePgBtn.tags,
        skills: candidatePgBtn.skills,
        experience: candidatePgBtn.experience,
        work_status: candidatePgBtn.work_status,
        job_id: params.jobsId || jobsId,
        status: candidatePgBtn.status,
        search: candidatePgBtn.search,
      };

      reqCandidates(Newparams);
      reqGetJob({ id: params.jobsId || jobsId });
      onClose();
      drawerOnClose();
      if (callback) {
        callback();
      }
    }
  }, [resReject.isSuccess, resBulkReject.isSuccess]);

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: onOpenAlert,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    if (resReject.isError || resBulkReject.isError) {
      onOpenAlert();
    }
  }, [resReject.isError, resBulkReject.isError]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="lg"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Reject Candidate
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>
                    There was an error processing your request. Change a few
                    things up and try again.
                  </AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleSubmit,
                handleChange,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.reason && touched.reason)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Please select a reject reason
                        </FormLabel>
                        <AtsSelect3
                          id="reason"
                          name="reason"
                          placeholder="Select"
                          variant="outline"
                          value={values.reason}
                          onChange={(e: any) => setFieldValue('reason', e)}
                          options={status}
                        />
                        <FormErrorMessage>
                          {String(errors.reason)}
                        </FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      mr={3}
                      onClick={() => onClose()}
                      disabled={resReject.isLoading || resBulkReject.isLoading}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="solid"
                      type="submit"
                      disabled={resReject.isLoading || resBulkReject.isLoading}
                      isLoading={resReject.isLoading || resBulkReject.isLoading}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Box, Flex, Grid, GridItem, IconButton } from '@chakra-ui/react';
import 'react-quill/dist/quill.snow.css';
import {
  useListJobNoteMutation,
  useDelJobNoteMutation,
  setJobNotes,
} from 'store/jobnote.slice';
import { useParams } from 'react-router';
import { useUserListPaginateMutation } from 'store/user.slice';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';

const JobsNotes = () => {
  const parse = require('html-react-parser');
  const params = useParams();
  const dispatch = useDispatch();

  const { jobNotePgBtn, jobNotes } = useSelector(
    (state: any) => state.jobNotes
  );
  const { UserPgBtn, userList } = useSelector((state: any) => state.user);

  const [reqUser] = useUserListPaginateMutation();
  const [reqList, resList] = useListJobNoteMutation();
  const [reqDel, resDel] = useDelJobNoteMutation();
  const [loadingNoteId, setLoadingNoteId] = useState(null); // Track the note ID being currently deleted

  const [hover, setHover] = useState({ id: null, hover: null });

  useEffect(() => {
    reqList({ data: jobNotePgBtn, id: params.jobsId });
  }, [params.jobsId, resDel.isSuccess]);

  useEffect(() => {
    reqUser(UserPgBtn);
    setLoadingNoteId(null);
    dispatch(setJobNotes({ jobNoteData: null }));
  }, [UserPgBtn, reqUser]);

  const content: swalContent = {
    title: 'Are you sure?',
    text: 'You are about to delete this notes.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const delNote = async (id: number) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      setLoadingNoteId(id); // Set the ID of the note being deleted
      reqDel({ id });
    }
  };

  const editNote = async (data: any) => {
    dispatch(setJobNotes({ jobNoteData: data }));
  };

  return (
    <Box mb="300px" p={5} pt={0}>
      <Box>
        {jobNotes.length > 0 &&
          jobNotes?.map((notes: any) => {
            const user =
              userList.length > 0 &&
              userList.filter((x: any) => x.id === Number(notes.user_id))[0];
            return (
              <Box
                borderBottom="1px solid #E6E9EE"
                py={4}
                key={`job-note-${notes.id}`}
              >
                <Grid templateColumns="repeat(5, 1fr)" gap={4}>
                  <GridItem colSpan={4}>
                    <Flex
                      gap={2}
                      alignItems="center"
                      justifyContent="flex-start"
                      fontWeight={700}
                      fontSize="14px"
                      color="primary.dark"
                    >
                      <Box>{[user.first_name, user.last_name].join(' ')}</Box>
                      <Box color="#9B9B9B">·</Box>
                      <Box
                        onMouseEnter={() => {
                          setHover({ id: notes.id, hover: true });
                        }}
                        onMouseLeave={() => {
                          setHover({ id: notes.id, hover: false });
                        }}
                      >
                        {hover.id === notes.id && hover.hover
                          ? moment
                              .utc(notes.created_at)
                              .format('MM/DD/YYYY hh:mm:ss A')
                          : moment.utc(notes.created_at).format('MM/DD/YYYY')}
                      </Box>
                    </Flex>
                  </GridItem>
                  <GridItem colEnd={8}>
                    <Flex
                      gap={2}
                      alignItems="right"
                      justifyContent="flex-end"
                      fontWeight={700}
                      fontSize="14px"
                      color="primary.dark"
                    >
                      <IconButton
                        size="xs"
                        aria-label="delete"
                        icon={<BsTrash />}
                        onClick={() => delNote(Number(notes.id))}
                        isLoading={
                          loadingNoteId === notes.id && resDel.isLoading
                        }
                      />
                      <IconButton
                        size="xs"
                        aria-label="edit"
                        icon={<BsPencil />}
                        onClick={() => editNote(notes)}
                      />
                    </Flex>
                  </GridItem>
                </Grid>

                <Box fontSize="lg">{parse(notes.notes)}</Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default JobsNotes;

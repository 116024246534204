import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  useDisclosure,
} from '@chakra-ui/react';

import { listCandidates } from 'store/candidates.slice';
import {
  useGetCandidateFilterColumnsMutation,
  useGetFilterOperatorsMutation,
} from 'store/constant.slice';

import Button from 'Library/Button';
import FAIcon from '../../lib/FAIcon';
import CandidatesTable from './Table';
import CandidatesModal from './Modals/modal';
import ScrollToFieldError from '../ScrollError';
import CandidateUploadResumeModal from './Modals/uploadResume';
import CandidatesTableV2 from './TableV2';

export default function CandidatesAll() {
  document.documentElement.dir = 'ltr';
  const storageName = 'candidatesFilter';

  const dispatch = useDispatch();

  const {
    isOpen: isOpenAddCandidate,
    onOpen: onOpenAddCandidate,
    onClose: onCloseAddCandidate,
  } = useDisclosure();

  const {
    isOpen: isOpenUploadResume,
    onOpen: onOpenUploadResume,
    onClose: onCloseUploadResume,
  } = useDisclosure();

  const { candidatePgBtn, candidateOthers } = useSelector(
    (state: any) => state.candidates
  );
  const { filter } = useSelector((state: any) => state.candidateFilters);

  const [reqCandidateFilterColumn] = useGetCandidateFilterColumnsMutation();
  const [reqFilterOperators] = useGetFilterOperatorsMutation();

  useEffect(() => {
    reqCandidateFilterColumn({});
    reqFilterOperators({});
  }, [reqCandidateFilterColumn, reqFilterOperators]);

  const initialValue = {
    search: candidatePgBtn.query || '',
  };

  const onSubmit = (data: any) => {
    let params = {
      ...candidatePgBtn,
      page: 1,
      take: candidatePgBtn.take,
      query: data.search,
    };
    submitForm(params);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let params = {
        ...candidatePgBtn,
        page: 1,
        take: candidatePgBtn.take,
        query: '',
      };
      submitForm(params);
    }
  };

  const submitForm = (params: any) => {
    dispatch(
      listCandidates({
        candidatePgBtn: params,
      })
    );
    localStorage.setItem(storageName, JSON.stringify(params));
  };

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
      candidateOthers.length > 0 &&
      candidateOthers[0].group_by_job
    );
  }, [candidateOthers]);

  useEffect(() => {
    if (filter) {
      setIsGroupByJob(true);
    }
  }, [filter]);

  return (
    <>
      <Flex justifyContent="space-between" mt="24px" px={6} pt="8px">
        <Flex gap="10px">
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, handleSubmit }) => (
              <Box pr={2}>
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <InputGroup background="#fff">
                    <InputLeftElement
                      cursor="pointer"
                      children={
                        <Box sx={{ color: 'primary.800' }}>
                          <FAIcon iconName="search" />
                        </Box>
                      }
                    />
                    <Input
                      type="search"
                      placeholder="Search by name, phone, email"
                      value={values.search}
                      id="search"
                      width="300px"
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </form>
              </Box>
            )}
          </Formik>
        </Flex>

        <Flex gap="10px">
          <Button leftIcon="plus" onClick={onOpenUploadResume}>
            Upload Resume
          </Button>
          <Button leftIcon="plus" variant="solid" onClick={onOpenAddCandidate}>
            Add Candidate
          </Button>
        </Flex>

        {isOpenAddCandidate && (
          <CandidatesModal
            isOpen={isOpenAddCandidate}
            onClose={onCloseAddCandidate}
          />
        )}
        {isOpenUploadResume && (
          <CandidateUploadResumeModal
            isOpen={isOpenUploadResume}
            onClose={onCloseUploadResume}
          />
        )}
      </Flex>

      <Box>{isGroupByJob ? <CandidatesTableV2 /> : <CandidatesTable />}</Box>
    </>
  );
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, useParams } from 'react-router-dom';
import { BsCheckCircle } from 'react-icons/bs';
import { FaRegTimesCircle } from 'react-icons/fa';

import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Icon,
  Tag,
  Checkbox,
} from '@chakra-ui/react';

import Button from 'Library/Button';
import Pagination from 'Library/Pagination';

import ApprovalsDrawer from './Drawer';
import LeadType from '../LeadTypes';
import LeadStatus from '../LeadStatus';

import {
  listApprovals,
  getApproval,
  useListAppprovalsMutation,
  useRejectApprovalMutation,
  useApproveApprovalMutation,
  useGetApprovalMutation,
} from 'store/approval.slice';

import { AtsConfirm, RejectAts } from 'utils/swal';
import { ApprovalsTypes, swalContent } from 'types';
import { useUserListMutation } from 'store/user.slice';

export default function ApprovalsTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { approvals, approvalPage, approvalPgBtn } = useSelector(
    (state: any) => state.approvals
  );

  const [reqApprovals, resApprovals] = useListAppprovalsMutation();
  const [reqGetApproval] = useGetApprovalMutation();
  const [reqReject, resReject] = useRejectApprovalMutation();
  const [reqApprove, resApprove] = useApproveApprovalMutation();
  const [reqUser] = useUserListMutation();

  useEffect(() => {
    reqUser({});
  }, [reqUser]);

  const [rowId, setRowId] = useState(1);

  document.documentElement.dir = 'ltr';

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const approvalID =
      approvals.findIndex(
        (x: ApprovalsTypes) => x.id === Number(params.approvalsId)
      ) + 1;
    setRowId(approvalID);
  }, [approvals, params.approvalsId]);

  useEffect(() => {
    if (resApprove.isSuccess || resReject.isSuccess) {
      reqApprovals(approvalPgBtn);
    }
  }, [approvalPgBtn, reqApprovals, resApprove.isSuccess, resReject.isSuccess]);

  useEffect(() => {
    if (params.approvalsId) {
      reqGetApproval({ id: params.approvalsId });
      onOpen();
    }
  }, [onOpen, params.approvalsId, reqGetApproval]);

  const handleClick = (approval: ApprovalsTypes) => {
    dispatch(getApproval({ approvalData: approval }));

    onOpen();
    navigate(`/approvals/${approval.id}/details`);

    const key =
      approvals.findIndex((x: ApprovalsTypes) => x.id === approval.id) + 1;
    setRowId(key);
  };

  const stateTab: any = params.approvalsTab;

  const handlePrevData = () => {
    const approvalID = approvals[rowId - 1 - 1].id;
    setRowId((prevKey: number) => prevKey - 1);
    navigate(`/approvals/${approvalID}/${stateTab}`);
    const prevData = approvals.filter(
      (data: ApprovalsTypes) => data.id === approvalID
    );
    dispatch(getApproval({ approvalData: prevData[0] }));
  };

  const handleNextData = () => {
    const approvalID = approvals[rowId - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(`/approvals/${approvalID}/${stateTab}`);
    const nextData = approvals.filter(
      (data: ApprovalsTypes) => data.id === approvalID
    );
    dispatch(getApproval({ approvalData: nextData[0] }));
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);

    approvals.map(
      (li: any) =>
        li.approvalStatus.status === 'Approval Pending' &&
        setIsCheck((prev) => [...prev, li.id])
    );

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < approvalPgBtn.take) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === approvalPgBtn.take) {
      setIsCheckAll(true);
    }
  }, [approvalPgBtn.take, isCheck, isCheckAll]);

  const tableHeader: { label: any }[] = [
    {
      label: (
        <Checkbox
          onChange={handleSelectAll}
          isIndeterminate={isIndeterminate}
          checked={isCheckAll}
          defaultChecked={false}
          colorScheme="purple"
        />
      ),
    },
    { label: 'Lead Details' },
    { label: 'Contact Information' },
    { label: 'Lead Type' },
    { label: 'Status' },
    { label: '' },
  ];

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: approvalPgBtn.take,
      query: approvalPgBtn.query,
      hot: approvalPgBtn.hot,
      qualified: approvalPgBtn.qualified,
      status: approvalPgBtn.status,
      types: approvalPgBtn.types,
    };

    dispatch(
      listApprovals({
        approvalPgBtn: newParam,
      })
    );
    reqApprovals(newParam);

    setIsCheck([]);
    setIsCheckAll(false);
  };

  const approve = async (data: any) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to approve lead: ${data.lead?.first_name} ${data.lead?.last_name}`,
      buttons: ['Cancel', 'Approve it!'],
      icon: 'info',
    };

    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqApprove({ id: data.id });
      onClose();
      reqApprovals(approvalPgBtn);
    }
  };

  const reject = async (data: any) => {
    let [confirm, content] = await RejectAts(data);
    if (confirm) {
      let form = {
        reason: content,
      };
      await reqReject({ data: form, id: data.id });
      onClose();
      reqApprovals(approvalPgBtn);
    }
  };

  const entryChange = (entries: number) => {
    let newParam = {
      // page: approvalPgBtn.page,
      page: 1,
      take: entries,
      query: approvalPgBtn.query,
      hot: approvalPgBtn.hot,
      qualified: approvalPgBtn.qualified,
      status: approvalPgBtn.status,
      types: approvalPgBtn.types,
    };

    dispatch(
      listApprovals({
        approvalPgBtn: newParam,
      })
    );
    reqApprovals(newParam);
  };

  return (
    <Box px={6} py={4} justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex py={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button variant="basic" leftIcon="check" size="sm">
            Approve
          </Button>
          <Button variant="basic" leftIcon="close" size="sm">
            Reject
          </Button>
        </Flex>
      )}
      <TableContainer
        boxSizing="border-box"
        borderX="1px solid"
        borderTop="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        minHeight="70vh"
      >
        <Table>
          <Thead>
            <Tr bg="default.white.600">
              {tableHeader.map((title) => (
                <Th key={title.label}>
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {approvals.map((data: ApprovalsTypes) => (
              <Tr
                key={data.id}
                _hover={{
                  bg: '#f8f9fa',
                }}
                cursor="pointer"
              >
                <Td>
                  {data.approvalStatus?.status === 'Approval Pending' && (
                    <Checkbox
                      onChange={handleCheck}
                      isChecked={isCheck.includes(data.id)}
                      id={data.id.toString()}
                      key={data.id}
                      colorScheme="purple"
                    />
                  )}
                </Td>
                <Td onClick={() => handleClick(data)}>
                  <Box whiteSpace="pre-line" maxWidth="200px">
                    <Box fontWeight="bold">
                      {[data.lead?.first_name, data.lead?.last_name].join(' ')}
                    </Box>
                    <Box fontSize="sm"> {data.lead?.title}</Box>
                    <Box fontSize="sm"> {data.lead?.company}</Box>
                  </Box>
                </Td>
                <Td onClick={() => handleClick(data)}>
                  <Box>
                    <Box fontWeight="bold" fontSize="sm">
                      {data.lead?.primary_email}
                    </Box>
                    <Box fontSize="sm"> {data.lead?.personal_phone}</Box>
                  </Box>
                </Td>
                <Td onClick={() => handleClick(data)}>
                  <LeadType type={data.leadType?.type} />
                </Td>
                <Td onClick={() => handleClick(data)} maxW="300px">
                  <LeadStatus
                    status={data.approvalStatus?.status}
                    reason={data.reason}
                  />
                </Td>
                <Td>
                  {data.approvalStatus?.status === 'Approval Pending' && (
                    <Menu>
                      <MenuButton>
                        <Button
                          htmlType="menu"
                          variant="ghost"
                          iconName="ellipsis-h"
                          iconSize="xl"
                        />
                      </MenuButton>

                      <MenuList fontSize="sm">
                        <MenuItem onClick={() => approve(data)}>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Icon as={BsCheckCircle} />
                            <Box ml={12}>Approve</Box>
                          </Flex>
                        </MenuItem>
                        <MenuItem color="#CB2C2C" onClick={() => reject(data)}>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Icon as={FaRegTimesCircle} />
                            <Box ml={12}>Reject</Box>
                          </Flex>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>

          {isOpen && (
            <ApprovalsDrawer
              isOpen={isOpen}
              onClose={onClose}
              rowId={rowId}
              totalLeads={approvals.length}
              handlePrevData={handlePrevData}
              handleNextData={handleNextData}
            />
          )}
        </Table>
      </TableContainer>

      <Pagination
        totalPages={approvalPage.lastPage}
        currentPage={approvalPage.currentPage}
        onPageChange={(page) => pageChange(page)}
        totalEntries={approvalPage.count}
        onEntryChange={(entries) => entryChange(entries)}
        currentCount={approvals.length}
        targetCount={approvalPgBtn.take}
      />
    </Box>
  );
}

const leadSourceFormat = (source: any) => {
  let defOption: any = {};
  try {
    defOption = {
      value: source?.id,
      label: source?.source,
    };
  } catch (e) {
    console.warn('e', e);
  }

  return defOption;
};

const formatCountry = (country: any) => {
  let option: any = [];
  try {
    option.push({
      label: country?.country,
      value: country?.id,
      code: country.code,
    });
  } catch (e) {
    console.log('e', e);
  }
  return option;
};

const formatCountryId = (countryId: any, countries: any) => {
  let option: any = [];

  const country = countries?.filter((val: any) => val.id === countryId)[0];

  try {
    option.push({
      label: country?.country,
      value: country?.id,
      code: country.code,
    });
  } catch (e) {
    console.log('e', e);
  }
  return option;
};

const addressFormat = (address: any, countries?: any) => {
  let newFormat: any = [];
  address?.map((item: any) => {
    newFormat.push({
      address: item.address,
      city: item.city,
      country: item.country
        ? formatCountry(item.country)
        : formatCountryId(item.country_id, countries),
      country_id: item.country_id,
      id: item.id,
      lead_id: item.lead_id,
      location: item.location,
      primary: item.primary,
      search: item.search,
      state: item.state,
      zip_code: item.zip_code,
    });
  });

  return newFormat;
};

const truncateToTwoDecimalPlaces = (number: any) => {
  // Shift the decimal point two places to the right
  let shiftedNumber = number * 100;

  // Truncate the decimal part
  let truncatedNumber = Math.floor(shiftedNumber) / 100;

  return truncatedNumber;
};

const formatDigits = (value: any) => {
  if (value === 0 || value === null) {
    return '0';
  } else if (Math.abs(value) < 1e3) {
    return `$${truncateToTwoDecimalPlaces(value)}`;
  } else if (Math.abs(value) < 1e6) {
    return `$${truncateToTwoDecimalPlaces(value / 1e3)}K`;
  } else if (Math.abs(value) < 1e9) {
    return `$${truncateToTwoDecimalPlaces(value / 1e6)}M`;
  } else if (Math.abs(value) < 1e12) {
    return `$${truncateToTwoDecimalPlaces(value / 1e9)}B`;
  } else if (Math.abs(value) < 1e15) {
    return `$${truncateToTwoDecimalPlaces(value / 1e12)}T`;
  }
};

export { leadSourceFormat, formatCountry, addressFormat, truncateToTwoDecimalPlaces, formatDigits };

import { Box, Flex } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  listFilters,
  useUnPinCandidateFilterMutation,
  useChangeFilterOrderMutation,
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { BsXLg } from 'react-icons/bs';
import './style.css';
import {
  listCandidates,
  useListCandidatesMutation,
} from 'store/candidates.slice';
import FAIcon from 'components/lib/FAIcon';

interface filterListInt {
  onChange: (e: any) => void;
  active?: any;
  setFilterActive: (e: any) => void;
}

const FilterList = ({
  onChange,
  active = 0,
  setFilterActive,
}: filterListInt) => {
  const dispatch = useDispatch();

  const { candidatePgBtn } = useSelector((state: any) => state.candidates);
  const { candidate_filters, candidate_pin_filters } = useSelector(
    (state: any) => state.candidateFilters
  );
  const [activeFilter, setActiveFilter] = useState<number | null>(active);
  const [reqUnpin, resUnpin] = useUnPinCandidateFilterMutation();
  const [reqChangeOrder, resChangeOrder] = useChangeFilterOrderMutation();
  const [reqList] = useListCandidatesMutation();
  const [reqFilterList, resFilterList] = useListCandidateFilterMutation();
  const [reqPinList, resPinList] = usePinListCandidateFilterMutation();

  useEffect(() => {
    if (resUnpin.isSuccess) {
      reqList({});
      reqFilterList(candidatePgBtn);
      reqPinList(candidatePgBtn);
    }
  }, [resUnpin.isSuccess]);

  useEffect(() => {
    if (active) {
      setActiveFilter(active);
    }
  }, [active]);

  const [filters, setFilters] = useState([]);
  const [draggedFilter, setDraggedFilter] = useState(null);

  const handleDragStart = (index: any) => {
    setDraggedFilter(index);
  };

  const handleDragOver = (index: number) => {
    if (draggedFilter !== null) {
      const newFilters = [...filters];
      const draggedItem = newFilters[draggedFilter];

      newFilters.splice(draggedFilter, 1);
      newFilters.splice(index, 0, draggedItem);
      setFilters(newFilters);
      setDraggedFilter(index);
    }
  };

  const handleDragEnd = async () => {
    const filterOrders: any = [];
    filters.filter(Boolean).map((filter) => {
      filterOrders.push(filter.id);
    });
    const param = {
      filterOrders: filterOrders,
    };
    await reqChangeOrder({ data: param }).then(async (res: any) => {
      await reqPinList({})
    });
    setDraggedFilter(null);
  };

  useEffect(() => {
    const candidatedFilterPinData: string | null = null;
    // localStorage.getItem(
    //   'candidatesFilterPin'
    // );

    if (candidatedFilterPinData === null && candidate_pin_filters?.length > 0) {
      let options: any = [];
      // candidate_pin_filters.map((item: any) => {
      //   if (item?.filter?.title === 'All Candidates') {
      //     options.push(item?.filter);
      //     return;
      //   }
      // });
      candidate_pin_filters.map((item: any) => {
        // if (item?.pin) {
        // const item
        options.push(item.filter);
        // }
      });
      return setFilters(options);
    } else {
      // setFilters(JSON.parse(candidate_pin_filters));
    }
  }, [candidate_filters, candidate_pin_filters]);

  const handlePin = (id: any) => {
    reqUnpin({ id });

    const updatedFilters = filters.filter((filter) => filter.id !== id);
    // localStorage.setItem('candidatesFilterPin', JSON.stringify(updatedFilters));
    setFilters(updatedFilters);
  };

  const onClick = async (data: any) => {
    const id = data.label == 'All Candidates' ? null : data.value;
    await onChange(data);

    setActiveFilter(data.value);
    setFilterActive(data.value);
    dispatch(listFilters({ filter: id }));

    let params = {
      // CLEARS FILTER
      page: 1,
      take: 50,
      job_filter: '',
      job_filter_include: 'Current',
      location: '',
      tags: [] as any,
      skills: [] as any,
      experience: [] as any,
      work_status: null as any,
      job_id: null as any,
      excluded_job_id: null as any,
      status: null as any,
      job_status: null as any,
      resume_updated_on: null as any,
      search: '',
      boolean: true,
      reject_reason: null as any,
      source: null as any,
      sub_source: null as any,
      query: '',
      // SAVES NEW FILTER
      filter: id,
    };

    dispatch(
      listCandidates({
        candidatePgBtn: params,
      })
    );
    localStorage.setItem('candidatesFilter', JSON.stringify(params));
  };

  return filters?.length > 0 ? (
    <Box
      mt="15px"
      sx={{ fontFamily: 'Proxima Nova Regular', color: 'secondarytext' }}
    >
      {filters.map((item: any, sk: number) => {
        if (item) {
          return (
            <Box
              key={`filterview-${sk}`}
              draggable
              onDragStart={() => handleDragStart(sk)}
              onDragOver={() => handleDragOver(sk)}
              onDragEnd={handleDragEnd}
              className={`filter-button ${active !== null && activeFilter === item.id ? 'active' : ''
                }`}
            >
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '5px',
                  border: '1px solid rgba(186, 186, 186, 0.4)',
                  borderRadius: '4px',
                  mb: '8px',
                  p: '8px 16px',
                  fontSize: '12px',
                  fontWeight: 600,
                }}
                _hover={{ backgroundColor: 'rgba(239, 229, 255, 0.4)' }}
                onClick={() => onClick({ value: item?.id, label: item?.title })}
              >
                <Flex gap="5px">
                  <Box color="primary.800" sx={{ mr: '5px' }}>
                    <FAIcon iconName="grip-vertical" />
                  </Box>
                  <Box
                    sx={{
                      maxWidth: '130px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item?.title}
                  </Box>
                  <Box>-</Box>
                  <Box style={{ color: '#6930CA' }}>{item?.data_count}</Box>
                </Flex>
                <Box onClick={() => handlePin(item?.id)}>
                  <BsXLg />
                </Box>
              </Flex>
            </Box>
          );
        } else {
          return <></>;
        }
      })}
    </Box>
  ) : (
    <></>
  );
};

export default FilterList;

import { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  MenuDivider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  FormErrorMessage,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Checkbox,
  InputGroup,
  InputLeftElement,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  InputLeftAddon,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';
import Pagination from 'Library/Pagination';
import AtsSelect from '../AtsSelect';
import ScrollToFieldError from '../ScrollError';

interface ClientShareModalProps {
  isOpen: any;
  onClose: any;
}
export default function ClientShareModal({
  isOpen,
  onClose,
}: ClientShareModalProps) {
  const [errorMessage] = useState('');
  const { isOpen: isOpenAlert, onClose: onCloseAlert } = useDisclosure({
    defaultIsOpen: false,
  });

  const formValues = {
    recruiter_name: '',
    isExpirationPeriod: false,
    start_date: '',
    end_date: '',
    isAllowRecruiterPost: false,
    split_percentage: '',
    isSubmittalReview: '',
    isInterviewSetting: '',
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      recruiter_name: Yup.string().required('Recruiter name is required.'),
      isExpirationPeriod: Yup.boolean(),
      start_date: Yup.string(),
      end_date: Yup.string(),
      isAllowRecruiterPost: Yup.boolean(),
      split_percentage: Yup.string(),
      isSubmittalReview: Yup.string(),
      isInterviewSetting: Yup.string(),
    })
  );

  const onSubmit = async (data: any) => {
    console.log(data);
  };

  const tableHeader: { label: any }[] = [
    { label: <Checkbox colorScheme="purple" /> },
    { label: 'Job Name' },
    { label: 'Split Percentage' },
  ];

  const pageChange = async (page: number) => {
    console.log(page);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => onClose()}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Share Client
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Flex gap="32px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.recruiter_name && touched.recruiter_name
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Recruiter{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <AtsSelect
                          iconColor="primary.800"
                          id="recruiter_name"
                          name="recruiter_name"
                          placeholder="Select Recruiter Name"
                          onChange={(e: any) =>
                            setFieldValue('recruiter_name', e.value)
                          }
                          options={[
                            { label: 'Option 1', value: 0 },
                            { label: 'Option 2', value: 1 },
                            { label: 'Option 3', value: 2 },
                          ]}
                        />
                        <FormErrorMessage>
                          {String(errors.recruiter_name)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl></FormControl>
                    </Flex>
                    <Box
                      mt="24px"
                      mb="16px"
                      fontSize="14px"
                      lineHeight="17.05px"
                      color="default.primarytext"
                    >
                      <Checkbox
                        id="isExpirationPeriod"
                        name="isExpirationPeriod"
                        isChecked={values.isExpirationPeriod}
                        onChange={handleChange}
                      >
                        No Expiration Period
                      </Checkbox>
                    </Box>
                    <Flex gap="32px">
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Start Date
                        </FormLabel>
                        <Input
                          id="start_date"
                          name="start_date"
                          placeholder="Select Date"
                          size="md"
                          type="date"
                          value={values.start_date}
                          onChange={handleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          End Date
                        </FormLabel>
                        <Input
                          id="end_date"
                          name="end_date"
                          placeholder="Select Date"
                          size="md"
                          type="date"
                          value={values.end_date}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Flex>
                    <Box
                      mt="24px"
                      mb="16px"
                      fontSize="14px"
                      lineHeight="17.05px"
                      color="default.primarytext"
                    >
                      <Checkbox
                        id="isAllowRecruiterPost"
                        name="isAllowRecruiterPost"
                        isChecked={values.isAllowRecruiterPost}
                        onChange={handleChange}
                      >
                        Allow recruiter to post new jobs ads
                      </Checkbox>
                    </Box>
                    <Flex gap="32px" mb="32px">
                      <Box>
                        <InputGroup>
                          <InputLeftAddon
                            children="Split %"
                            fontSize="12px"
                            fontWeight="600"
                            lineHeight="18px"
                            color="default.primarytext"
                          />
                          <Box width="270px">
                            <AtsSelect
                              iconColor="primary.800"
                              id="split_percentage"
                              name="split_percentage"
                              placeholder="Select Split %"
                              onChange={(e: any) =>
                                setFieldValue('split_percentage', e.value)
                              }
                              options={[
                                { label: '50 / 50', value: 0 },
                                { label: '60 / 40', value: 1 },
                                { label: '80 / 20', value: 2 },
                              ]}
                            />
                          </Box>
                        </InputGroup>
                      </Box>
                      <FormControl></FormControl>
                    </Flex>
                    <MenuDivider />
                    <Box mt="32px">
                      <Box
                        mb="8px"
                        fontSize="18px"
                        lineHeight="21.92px"
                        color="default.primarytext"
                      >
                        Select Jobs to Share
                      </Box>
                      <Box
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                      >
                        Select jobs from below table to be shared with recuiter
                      </Box>
                      <Box mt="24px">
                        <Flex gap="32px">
                          <Box width="50%">
                            <InputGroup background="#fff">
                              <InputLeftElement
                                cursor="pointer"
                                children={<FAIcon iconName="search" />}
                              />
                              <Input type="search" placeholder="Search Jobs" />
                            </InputGroup>
                          </Box>

                          <Box width="50%">
                            <InputGroup>
                              <InputLeftAddon
                                children="Split %"
                                fontSize="12px"
                                fontWeight="600"
                                lineHeight="18px"
                                color="default.primarytext"
                              />
                              <AtsSelect
                                iconColor="primary.800"
                                id="split_percentage"
                                name="split_percentage"
                                placeholder="Select Split %"
                                onChange={(e: any) =>
                                  setFieldValue('split_percentage', e.value)
                                }
                                options={[
                                  { label: '50 / 50', value: 0 },
                                  { label: '60 / 40', value: 1 },
                                  { label: '80 / 20', value: 2 },
                                ]}
                                width="260px"
                              />
                            </InputGroup>
                          </Box>
                        </Flex>
                        <Box mt="24px" mb="32px">
                          <TableContainer
                            boxSizing="border-box"
                            border="1px solid"
                            borderColor="default.white.400"
                            borderRadius="md"
                          >
                            <Table>
                              <Thead>
                                <Tr bg="default.white.800">
                                  {tableHeader.map((title) => (
                                    <Th key={title.label}>
                                      <Box color="default.gray.600">
                                        {title.label}
                                      </Box>
                                    </Th>
                                  ))}
                                </Tr>
                              </Thead>

                              <Tbody
                                boxSizing="border-box"
                                background="default.white.100"
                                borderBottom="1px solid"
                                borderColor="default.white.400"
                              >
                                <Tr cursor="pointer" onClick={() => {}}>
                                  <Td>
                                    <Checkbox colorScheme="purple" />
                                  </Td>
                                  <Td>Job Name</Td>
                                  <Td>
                                    <Box>
                                      <AtsSelect
                                        iconColor="primary.800"
                                        id="split_percentage"
                                        name="split_percentage"
                                        placeholder="Select Split %"
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            'split_percentage',
                                            e.value
                                          )
                                        }
                                        options={[
                                          { label: '50 / 50', value: 0 },
                                          { label: '60 / 40', value: 1 },
                                          { label: '80 / 20', value: 2 },
                                        ]}
                                        width="260px"
                                      />
                                    </Box>
                                  </Td>
                                </Tr>
                              </Tbody>
                            </Table>
                          </TableContainer>

                          <Pagination
                            totalPages={1}
                            currentPage={1}
                            onPageChange={(page) => pageChange(page)}
                            onEntryChange={() => {}}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box mb="34px">
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                        mb="16px"
                      >
                        Submittal Review
                      </FormLabel>

                      <FormLabel
                        display="flex"
                        gap="10px"
                        fontSize="14px"
                        lineHeight="17.05px"
                        color="default.primarytext"
                      >
                        <Field
                          id="isSubmittalReview"
                          name="isSubmittalReview"
                          type="radio"
                          variant="outline"
                          value="1"
                        />
                        No submittal review
                      </FormLabel>
                      <FormLabel
                        display="flex"
                        gap="10px"
                        fontSize="14px"
                        lineHeight="17.05px"
                        color="default.primarytext"
                      >
                        <Field
                          id="isSubmittalReview"
                          name="isSubmittalReview"
                          type="radio"
                          variant="outline"
                          value="2"
                        />
                        Review submittal and send as client owner
                      </FormLabel>
                      <FormLabel
                        display="flex"
                        gap="10px"
                        fontSize="14px"
                        lineHeight="17.05px"
                        color="default.primarytext"
                      >
                        <Field
                          id="isSubmittalReview"
                          name="isSubmittalReview"
                          type="radio"
                          variant="outline"
                          value="3"
                        />
                        Review submittal and send as recruiter
                      </FormLabel>
                    </Box>
                    <Box mb="34px">
                      <FormLabel
                        fontSize="14px"
                        lineHeight="18px"
                        color="default.secondarytext"
                        mb="16px"
                      >
                        Interview Settings
                      </FormLabel>

                      <FormLabel
                        display="flex"
                        gap="10px"
                        fontSize="14px"
                        lineHeight="17.05px"
                        color="default.primarytext"
                      >
                        <Field
                          id="isInterviewSetting"
                          name="isInterviewSetting"
                          type="radio"
                          variant="outline"
                          value="1"
                        />
                        Send interview as client owner
                      </FormLabel>
                      <FormLabel
                        display="flex"
                        gap="10px"
                        fontSize="14px"
                        lineHeight="17.05px"
                        color="default.primarytext"
                      >
                        <Field
                          id="isInterviewSetting"
                          name="isInterviewSetting"
                          type="radio"
                          variant="outline"
                          value="2"
                        />
                        Send interview as recruiter
                      </FormLabel>
                    </Box>
                  </Box>
                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button
                      variant="solid"
                      disabled={isOpenAlert}
                      type="submit"
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

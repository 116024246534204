import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { Formik } from 'formik';

import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { BsStar, BsStarFill } from 'react-icons/bs';

import Pagination from 'Library/Pagination';
import FAIcon from '../../../lib/FAIcon';

import ClientsContactsDrawer from './Drawer';

import {
  getContact,
  listContact,
  useContactListMutation,
} from 'store/contact.slice';
import {
  useGetStatusMutation,
  useGetTypesMutation,
  useInactiveLeadsMutation,
  useReActivateMutation,
} from 'store/leads.slice';
import { filterContactVariables } from 'constants/fileVariables';
import TrLoading from 'components/app/TrLoading';
import Button from 'Library/Button';
import ContactModal from './Modal';

export default function ContactsClientsTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const {
    isOpen: isOpenAddContact,
    onOpen: onOpenAddContact,
    onClose: onCloseAddContact,
  } = useDisclosure();

  const { contactList, contactPage, contactPageBtn } = useSelector(
    (state: any) => state.contacts
  );

  const [reqContacts, resContacts] = useContactListMutation();
  const [reqStatus] = useGetStatusMutation();
  const [reqTypes] = useGetTypesMutation();

  const { clientNav } = useSelector((state: any) => state.clients);

  const [rowId, setRowId] = useState(1);

  document.documentElement.dir = 'ltr';

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reqInactiveLead, resInactiveLead] = useInactiveLeadsMutation();
  const [reqActivate, resActivate] = useReActivateMutation();

  const [filterCheck] = useState(filterContactVariables);
  const [typeIsChecked, setTypeChecked] = useState([]);

  useEffect(() => {
    reqStatus({});
    reqTypes({});
  }, [reqStatus, reqTypes]);

  useEffect(() => {
    const clientID =
      contactList.findIndex((x: any) => x.id === Number(params.clientsId)) + 1;
    setRowId(clientID);
  }, [contactList, params.clientsId]);

  const stateTab: any = params.clientsTab;

  useEffect(() => {
    if (clientNav === 'contacts') {
      reqContacts(contactPageBtn);
    }
  }, [clientNav]);

  useEffect(() => {
    if (clientNav === 'contacts') {
      setTypeChecked(contactPageBtn.filters);
    }
  }, [clientNav, contactPageBtn.filters]);

  useEffect(() => {
    if (resActivate.isSuccess) {
      reqContacts(contactPageBtn);
    }
  }, [
    contactPageBtn,
    reqContacts,
    resActivate.isSuccess,
    resActivate?.data?.data?.message,
  ]);

  useEffect(() => {
    if (resInactiveLead.isSuccess) {
      reqContacts(contactPageBtn);
    }
  }, [
    contactPageBtn,
    reqContacts,
    resInactiveLead.isSuccess,
    resInactiveLead?.data?.data?.message,
  ]);

  const handleInactive = async (e: any, data: any) => {
    const status = e.target.checked;
    if (!status) {
      await reqInactiveLead({ id: data.id });
    } else {
      await reqActivate({ id: data.id });
    }
  };

  useEffect(() => {
    if (clientNav === 'contacts' && stateTab && params.clientsId) {
      const contactData = contactList.filter(
        (x: any) => x.id === Number(params.clientsId)
      )[0];
      dispatch(getContact({ contactData: contactData }));
      onOpen();
    }
  }, [onOpen, clientNav, params.clientsId, stateTab]);

  const handleClick = (contact: any) => {
    dispatch(getContact({ contactData: contact }));
    onOpen();
    navigate(`/clients/${contact.id}/overview`);

    const key = contactList.findIndex((x: any) => x.id === contact.id) + 1;
    setRowId(key);
  };

  const handlePrevData = () => {
    const contactID = contactList[rowId - 1 - 1].id;
    setRowId((prevKey: number) => prevKey - 1);
    navigate(`/clients/${contactID}/${stateTab}`);
    const prevData = contactList.filter((data: any) => data.id === contactID);
    dispatch(getContact({ contactData: prevData[0] }));
  };

  const handleNextData = () => {
    const contactID = contactList[rowId - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(`/clients/${contactID}/${stateTab}`);
    const nextData = contactList.filter((data: any) => data.id === contactID);
    dispatch(getContact({ contactData: nextData[0] }));
  };

  const tableHeader: { label: string }[] = [
    { label: 'Contact Name' },
    { label: 'Company' },
    { label: 'Contact Information' },
    { label: '' },
    { label: '' },
  ];

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: contactPageBtn.take,
      query: contactPageBtn.query,
      types: contactPageBtn.types,
      status: contactPageBtn.status,
    };

    dispatch(
      listContact({
        contactPageBtn: newParam,
      })
    );
    reqContacts(newParam);
  };

  const initialValues = {
    search: '',
  };

  const onSubmit = (data: any) => {
    let newParam = {
      query: data.search,
      page: contactPageBtn.page,
      take: contactPageBtn.take,
      types: contactPageBtn.types,
      status: contactPageBtn.status,
    };

    dispatch(
      listContact({
        contactPageBtn: newParam,
      })
    );
    reqContacts(newParam);
  };

  const clearSearch = (e: any) => {
    if (e.target.value === '') {
      let newParam = {
        query: '',
        page: contactPageBtn.page,
        take: contactPageBtn.take,
        types: contactPageBtn.types,
        status: contactPageBtn.status,
      };
      dispatch(
        listContact({
          contactPageBtn: newParam,
        })
      );
      reqContacts(newParam);
    }
  };

  const entryChange = (entries: number) => {
    let newParam = {
      page: 1,
      take: entries,
      query: contactPageBtn.query,
      types: contactPageBtn.types,
      status: contactPageBtn.status,
    };

    dispatch(
      listContact({
        contactPageBtn: newParam,
      })
    );
    reqContacts(newParam);
  };

  const handleCheckTypes = useCallback(
    (e: any) => {
      let newParam = {
        query: contactPageBtn.query || '',
        page: contactPageBtn.page,
        take: contactPageBtn.take,
        filters: typeIsChecked,
      };

      const { id, checked } = e.target;
      setTypeChecked([...typeIsChecked, id]);
      newParam.filters = [...typeIsChecked, id];

      if (!checked) {
        const isChecked = typeIsChecked.filter((item: number) => item !== id);
        setTypeChecked(isChecked);
        newParam.filters = isChecked;
      }

      dispatch(
        listContact({
          contactPageBtn: newParam,
        })
      );
      reqContacts(newParam);
      localStorage.setItem('contactsFilter', JSON.stringify(newParam));
    },
    [
      contactPageBtn.page,
      contactPageBtn.query,
      contactPageBtn.take,
      dispatch,
      reqContacts,
      typeIsChecked,
    ]
  );

  return (
    <Box fontFamily="Proxima Nova Regular">
      <Flex justifyContent="space-between" py={2} px={6}>
        <Flex>
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ values, handleChange, handleSubmit, errors, touched }) => (
              <form onSubmit={handleSubmit}>
                <Box pr={2}>
                  <InputGroup background="#fff">
                    <InputRightElement
                      cursor="pointer"
                      children={<FAIcon iconName="search" />}
                    />
                    <Input
                      type="search"
                      placeholder="Search a client"
                      id="search"
                      value={values.search}
                      onChange={(e) => {
                        handleChange(e);
                        clearSearch(e);
                      }}
                    />
                  </InputGroup>
                </Box>
              </form>
            )}
          </Formik>

          <Flex px={2} justifyContent="flex-start">
            {filterCheck.map((item: any, key: number) => {
              return item.id ? (
                <Checkbox
                  key={key}
                  colorScheme="purple"
                  color="default.secondarytext"
                  mx={1}
                  onChange={handleCheckTypes}
                  id={item.id}
                  marginRight={5}
                  isChecked={typeIsChecked?.includes(item?.id)}
                >
                  {item.label}
                </Checkbox>
              ) : (
                <></>
              );
            })}
          </Flex>
        </Flex>

        <Button leftIcon="plus" variant="solid" onClick={onOpenAddContact}>
          Add Contact
        </Button>
      </Flex>

      <Box px={6} py={4} justifyContent="space-between">
        {resContacts.isLoading ? (
          <Box px={6} py={4} justifyContent="space-between">
            <TableContainer
              boxSizing="border-box"
              borderX="1px solid"
              borderTop="1px solid"
              borderColor="default.white.400"
              borderRadius="md"
              minHeight="70vh"
            >
              <Table>
                <Thead height="40.5px">
                  <Tr bg="default.white.600">
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody
                  boxSizing="border-box"
                  background="default.white.100"
                  borderBottom="1px solid"
                  borderColor="default.white.400"
                >
                  <TrLoading rows={7} columns={5} />
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <TableContainer
            boxSizing="border-box"
            borderX="1px solid"
            borderTop="1px solid"
            borderColor="default.white.400"
            borderRadius="md"
            minHeight="70vh"
          >
            <Table>
              <Thead>
                <Tr bg="default.white.600">
                  {tableHeader.map((title, i) => (
                    <Th key={title.label + i}>
                      <Box color="default.gray.600">{title.label}</Box>
                    </Th>
                  ))}
                </Tr>
              </Thead>

              <Tbody
                boxSizing="border-box"
                background="default.white.100"
                borderBottom="1px solid"
                borderColor="default.white.400"
              >
                {contactList?.map((data: any, key: number) => {
                  return (
                    <Tr
                      key={data.id}
                      _hover={{
                        bg: '#f8f9fa',
                      }}
                      cursor="pointer"
                    >
                      <Td onClick={() => handleClick(data)}>
                        {data.first_name + ' ' + data.last_name}
                      </Td>
                      <Td onClick={() => handleClick(data)}>{data.company}</Td>
                      <Td onClick={() => handleClick(data)}>
                        <Box>
                          <Box fontWeight="bold" fontSize="sm">
                            {data.primary_email}
                          </Box>
                          <Box fontSize="sm"> {data.personal_phone}</Box>
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          {data?.is_mvp ? (
                            <Icon as={BsStarFill} color="yellow.400" />
                          ) : (
                            <Icon as={BsStar} />
                          )}
                        </Box>
                      </Td>
                      <Td>
                        <Box>
                          <Switch
                            colorScheme="purple"
                            value={data.lead_status_id}
                            defaultChecked={
                              data.lead_status_id === 2 ? true : false
                            }
                            onChange={(e) => handleInactive(e, data)}
                          />
                        </Box>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>

              {isOpen && (
                <ClientsContactsDrawer
                  isOpen={isOpen}
                  onClose={onClose}
                  rowId={rowId}
                  totalClients={contactList.length}
                  handlePrevData={handlePrevData}
                  handleNextData={handleNextData}
                />
              )}

              {isOpenAddContact && (
                <ContactModal
                  modalTitle="Add Contact"
                  isOpen={isOpenAddContact}
                  onClose={onCloseAddContact}
                />
              )}
            </Table>
          </TableContainer>
        )}

        <Pagination
          totalPages={contactPage.lastPage}
          currentPage={contactPage.currentPage}
          onPageChange={(page) => pageChange(page)}
          onEntryChange={(entries) => entryChange(entries)}
          totalEntries={contactPage.count}
          currentCount={contactList?.length}
          targetCount={contactPageBtn.take}
        />
      </Box>
    </Box>
  );
}

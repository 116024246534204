import Login from "components/login.component";
import MainPage from "components/app/MainPage";
import { PageRouteProps } from "types";

const pageRoute: PageRouteProps[] = [
  {
    name: "Login",
    component: <Login />,
    path: "/login",
  },
  {
    name: "Dashboard",
    component: <MainPage />,
    path: "/*",
  },
];

export default pageRoute;

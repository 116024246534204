import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import JobsCandidatePool from './CandidatePool';
import JobsHired from './Hired';
import JobsInProgress from './InProgress';
import JobsRejected from './Rejected';
import JobsScreen from './Screen';
import CandidatesFilterBar from 'components/app/Candidates/VerticalFilterBar';
import JobsViewCandidatesTable from './ViewCandidatesTable';
import { listJobs, useListJobsMutation } from 'store/jobs.slice';
import { listFilters } from 'store/candidatefilter.slice';
import { useListCandidatesMutation } from 'store/candidates.slice';

export default function JobsViewCandidatesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  document.documentElement.dir = 'ltr';

  const tabIndex: any = {
    'candidate-pool': 0,
    screen: 1,
    'in-progress': 2,
    hired: 3,
    rejected: 4,
  };

  const [reqJobs] = useListJobsMutation();
  const [reqCandidates, resCandidates] = useListCandidatesMutation();

  const {
    candidatePgBtn,
  } = useSelector((state: any) => state.candidates);
  const { jobData, jobPgBtn } = useSelector((state: any) => state.jobs);
  const [hasStatus, setHasStatus] = useState(true);
  const [filterCache, setFilterCache] = useState(JSON.stringify({}));

  useEffect(() => {
    if (params?.jobsTabId === 'candidate-pool') {
      setHasStatus(false);
    }
  }, [params?.jobsTabId]);

  const handleRoute = useCallback(
    (route: string) => {
      navigate(`/jobs/${jobData.id}/view-candidates/${route}`);
    },
    [jobData.id, navigate]
  );

  const handleBackButton = useCallback(async () => {
    await reqJobs(jobPgBtn);
    navigate(`/jobs`);
  }, [navigate]);

  const locationParse = (location: any) => {
    try {
      if (jobData.job) {
        const loc = [
          jobData.job.location?.city,
          jobData.job.location?.state,
        ].join(', ');
        if (loc !== ', ') {
          return loc;
        }
      } else if (location) {
        return [location?.city, location?.state].join(', ');
      }
      return '';
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  useEffect(() => {
    if (params.jobsTab === 'view-candidates') {
      dispatch(listFilters({ params: params.jobsTab }));
    }
  }, [params.jobsTab]);

  useMemo(() => {
    if (filterCache != '{}' && filterCache != JSON.stringify(candidatePgBtn)) {
      reqCandidates(candidatePgBtn);
    }
    setFilterCache(JSON.stringify(candidatePgBtn));
    return candidatePgBtn;
  }, [candidatePgBtn]);

  return (
    <Flex mt="-44px" fontFamily="Proxima Nova Regular">
      <CandidatesFilterBar />
      <Box w="80%" background="default.white.800">
        <Flex mt="24px" mx={10} gap="20px">
          <Button iconName="chevron-left" onClick={handleBackButton} />
          <Box>
            <Flex gap={2} fontSize="md" color="default.gray.600">
              <Box fontSize="md" color="default.gray.800">
                {jobData.title}
              </Box>
              |
              <Box fontSize="md" color="default.gray.800">
                {jobData.serial_number}
              </Box>
              |
              <Box fontSize="md" color="default.gray.800">
                {locationParse(jobData.location)}
              </Box>
            </Flex>
            <Flex
              alignItems="center"
              gap={2}
              fontSize="md"
              color="default.gray.600"
            >
              <Box fontSize="sm" color="default.secondarytext">
                {jobData.client?.name}
              </Box>
              |
              <Box fontSize="sm" color="default.secondarytext">
                {jobData.client?.website}
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Box>
          <Tabs colorScheme="purple" defaultIndex={tabIndex[params.jobsTabId]}>
            <TabList mt="24px" mx={10}>
              <Tab
                _focus={{ boxShadow: 'none !important' }}
                onClick={() => {
                  handleRoute('candidate-pool');
                  setHasStatus(false);
                  dispatch(
                    listJobs({
                      jobRoute: '',
                    })
                  );
                }}
              >
                Candidate Pool
              </Tab>
              <Tab
                _focus={{ boxShadow: 'none !important' }}
                onClick={() => {
                  handleRoute('screen');
                  setHasStatus(true);
                  dispatch(
                    listJobs({
                      jobRoute: '',
                    })
                  );
                }}
              >
                Screen
              </Tab>
              <Tab
                _focus={{ boxShadow: 'none !important' }}
                onClick={() => {
                  handleRoute('in-progress');
                  setHasStatus(true);
                }}
              >
                In Progress
              </Tab>
              <Tab
                _focus={{ boxShadow: 'none !important' }}
                onClick={() => {
                  handleRoute('hired');
                  setHasStatus(true);
                  dispatch(
                    listJobs({
                      jobRoute: '',
                    })
                  );
                }}
              >
                Hired
              </Tab>
              <Tab
                _focus={{ boxShadow: 'none !important' }}
                onClick={() => {
                  handleRoute('rejected');
                  setHasStatus(true);
                }}
              >
                Rejected
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <JobsCandidatePool />
              </TabPanel>
              <TabPanel>
                <JobsScreen />
              </TabPanel>
              <TabPanel>
                <JobsInProgress />
              </TabPanel>
              <TabPanel>
                <JobsHired />
              </TabPanel>
              <TabPanel>
                <JobsRejected />
              </TabPanel>
            </TabPanels>
          </Tabs>

          <JobsViewCandidatesTable hasStatus={hasStatus} />
        </Box>
      </Box>
    </Flex>
  );
}

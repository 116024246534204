import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import Pagination from 'Library/Pagination';

import Button from 'Library/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  getCandidateLogs,
  useCandidateActivityLogsMutation,
} from 'store/candidateActivity';
import { useEffect } from 'react';
import TrLoading from '../TrLoading';
import moment from 'moment';

const CandidatesActivity = () => {
  const tableHeader: { label: any }[] = [
    { label: 'Time of Activity' },
    { label: 'Activity Description' },
  ];

  const { candidateLogs, candidateLogsPage, candidateLogsPgBtn } = useSelector(
    (state: any) => state.candidateActivity
  );

  const [reqLogs, resLogs] = useCandidateActivityLogsMutation();

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const candidateID = params.jobsTabNav || params.candidatesId;

  useEffect(() => {
    reqLogs({ id: candidateID, data: candidateLogsPgBtn });
  }, [candidateID]);

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: candidateLogsPgBtn.take,
      query: candidateLogsPgBtn.query,
    };

    dispatch(
      getCandidateLogs({
        candidateLogsPgBtn: newParam,
      })
    );
    reqLogs({ id: candidateID, data: newParam });
  };

  const entryChange = (entries: number) => {
    let newParam = {
      page: 1,
      take: entries,
      query: candidateLogsPgBtn.query,
      hot: candidateLogsPgBtn.hot,
    };

    dispatch(
      getCandidateLogs({
        candidateLogsPgBtn: newParam,
      })
    );
    reqLogs({ id: candidateID, data: newParam });
  };

  return (
    <Box justifyContent="space-between">
      <Flex gap="20px" justifyContent="flex-end" mb="24px">
        <Button rightIcon="chevron-down">All Users</Button>
        <Button rightIcon="chevron-down">Any Time</Button>
        <Button rightIcon="chevron-down">All Activity</Button>
      </Flex>
      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
      >
        <Table>
          <Thead>
            <Tr bg="default.white.800">
              {tableHeader.map((title) => (
                <Th key={title.label}>
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {candidateLogs?.map((item: any) => {
              return (
                <Tr cursor="pointer" key={`activity-logs-${item.id}`}>
                  <Td>{moment.utc(item?.created_at).format('MM/DD/YYYY')}</Td>
                  <Td>{item?.message}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        totalPages={1}
        currentPage={1}
        onPageChange={() => {}}
        totalEntries={0}
        onEntryChange={(entries) => entryChange(entries)}
      />
    </Box>
  );
};

export default CandidatesActivity;

import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  Box,
  Checkbox,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Button from '../../../Library/Button';

import CandidatesAbout from './About';
import StreamAttachmentComponent from './stream';
import { getCandidate } from 'store/candidates.slice';
import { CandidatesTypes } from 'types';
import CandidatesNotes from './Notes';
import CandidatesActivity from './Activity';
import CandidatesAssociatedJobs from './AssociatedJobs';
import CandidatesInterviews from './Interviews';
import CandidatesAttachments from './Attachments';
import { useUpdateResumePrimaryStatusMutation } from 'store/candidateresume.slice';
import CandidateUpdateResumeModal from './Modals/updateResume';

import GoTo from 'assets/images/arrowSquare.svg';
import FAIcon from 'components/lib/FAIcon';

const CandidatesTabs = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { candidates, candidateData, candidatePage } = useSelector(
    (state: any) => state.candidates
  );
  const { resumes } = useSelector((state: any) => state.candidateResume);

  const [reqUpdate] = useUpdateResumePrimaryStatusMutation();

  const [selectedVersion, setSelectedVersion] = useState(null);
  const [isPrimary, setIsPrimary] = useState(false);
  const [resumeUrl, setResumeUrl] = useState('');
  const [resumeId, setResumeId] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const {
    isOpen: isOpenUploadResume,
    onOpen: onOpenUploadResume,
    onClose: onCloseUploadResume,
  } = useDisclosure();

  const tabIndex: any = {
    about: 0,
    notes: 1,
    activity: 2,
    associated_jobs: 3,
    attachments: 4,
    interviews: 5,
  };

  const stateTab: any = params.candidatesTab || params.jobsDrawerTab;
  const candidateID = params.candidatesId;
  const [clientTab, setClientTab] = useState(stateTab);
  // console.log("clientTab ", clientTab)

  // useEffect(() => {
  //   if (candidateID) {
  //     const getCandidateData = candidates.filter(
  //       (candidate: CandidatesTypes) => candidate.id === Number(candidateID)
  //     )[0];
  //     console.log("candidateID ", candidateID)
  //     dispatch(getCandidate({ candidateData: getCandidateData }));
  //   }
  // }, [candidateID]);

  useEffect(() => {
    let latest_version = resumes.filter(
      (resume: any) => resume.is_primary === true
    );
    if (latest_version.length <= 0) {
      latest_version = resumes;
    }
    if (latest_version.length > 0) {
      setSelectedVersion(latest_version[0]);
      setIsPrimary(latest_version[0].is_primary);
    }
    if (resumes.length <= 0) {
      setSelectedVersion(null);
      setResumeUrl(null);
    }
  }, [resumes, candidateID]);

  const handleRoute = (route: string) => {
    navigate(
      params['*'].includes('view-candidates')
        ? `${params.jobsId}/${params.jobsTab}/${params.jobsTabId}/${params.jobsTabNav}/${route}`
        : `/candidates/${candidatePage.currentPage}/${candidateData.id}/${route}${search}`
    );
    setClientTab(route);
  };

  const changePrimary = async (e: any) => {
    let primary = e.target.checked;
    setIsPrimary(primary);
    const body = { is_primary: primary };
    reqUpdate({ data: body, id: selectedVersion.id });
  };

  const changeResumeVersion = (key: number) => {
    console.log(`went to change Resume Version number: ${key}`);
    setSelectedVersion(resumes[key]);
    setIsPrimary(resumes[key]?.is_primary);
  };

  const ResumeHtml = () => {
    return (
      <Box textAlign="center" overflow="auto" height="80vh" width="100%">
        {selectedVersion ? (
          <div dangerouslySetInnerHTML={{ __html: selectedVersion?.html }} />
        ) : (
          <Text>No resume available</Text>
        )}
      </Box>
    );
  };

  const FileRender = () => {
    return (
      <Box textAlign="center">
        {selectedVersion ? (
          <StreamAttachmentComponent
            filename={selectedVersion?.file_name}
            fileUrl={selectedVersion?.signedUrl}
            setResumeUrl={setResumeUrl}
            pdfHeight="60vh"
            docHeight="63vh"
          />
        ) : (
          <Text>No resume available</Text>
        )}
      </Box>
    );
  };

  const memoizedResume = useMemo(() => {
    setResumeId(selectedVersion?.id);
    return (
      <Box textAlign="center" overflow="auto" height="80vh" width="100%">
        {selectedVersion ? (
          // selectedVersion?.html == 'null' ? (
          //   <FileRender />
          // ) : (
          //   <ResumeHtml />
          // )
          <FileRender />
        ) : (
          <Text>No resume available</Text>
        )}
      </Box>
    );
  }, [selectedVersion]);

  return (
    <>
      <Tabs colorScheme="purple" defaultIndex={tabIndex[stateTab]}>
        <TabList fontSize="md" fontWeight="500" sx={{ padding: '0 20px' }}>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('about')}
          >
            About
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('notes')}
          >
            Notes
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('activity')}
          >
            Activity
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('associated_jobs')}
          >
            Associated Jobs
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('attachments')}
          >
            Attachments
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none !important' }}
            onClick={() => handleRoute('interviews')}
          >
            Interviews
          </Tab>
        </TabList>

        <Flex>
          <Box
            width="60%"
            height="70vh"
            py={2}
            borderRight="1px solid"
            borderColor="default.borderlight"
            px={3}
            pr={0}
          >
            <Flex
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                px: '12px',
              }}
            >
              <Box>
                {resumeUrl ? (
                  <Link
                    // href={`${resumeUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                    target="_blank"
                    style={{
                      display: 'flex',
                      gap: '5px',
                      fontSize: '15px',
                      color: 'primary.600',
                    }}
                    onClick={() => {
                      const link = document.createElement('a');
                      link.target = '_blank';
                      const explode = selectedVersion?.file_name.split('.');
                      const extension = explode.splice(-1)[0];
                      let url = resumeUrl;
                      if (extension !== 'pdf') {
                        var encodedUrl = encodeURIComponent(
                          selectedVersion?.signedUrl
                        );
                        url =
                          'https://view.officeapps.live.com/op/embed.aspx?src=' +
                          encodedUrl;
                      } else {
                        url = selectedVersion?.signedUrl
                      }
                      link.href = `${url}#toolbar=0&navpanes=0&scrollbar=0`;
                      link.click();
                    }}
                    rel="noreferrer"
                  >
                    Resume
                    <span>
                      <Image src={GoTo} alt="logo" height="20px" />
                    </span>
                  </Link>
                ) : (
                  'Resume'
                )}
              </Box>

              <Flex
                sx={{
                  gap: '9px',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <Link
                  // href={`${selectedVersion?.signedUrl}#?download=my-new-filename.pdf`}
                  target="_blank"
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    fontSize: '15px',
                    color: 'primary.600',
                    mr: '15px',
                  }}
                  rel="noreferrer"
                  onClick={() => {
                    const link = document.createElement('a');
                    const explode = selectedVersion?.file_name.split('.');
                    const extension = explode.splice(-1)[0];
                    link.download =
                      [candidateData.first_name, candidateData.last_name].join(
                        ' '
                      ) || selectedVersion?.original_file_name;
                    if (extension === 'docx' || extension === 'doc') {
                      link.download = link.download + '.' + extension;
                    }
                    link.href = resumeUrl;
                    link.target = '_blank';
                    link.click();
                  }}
                >
                  <span>
                    <FAIcon iconName="download" />
                  </span>
                  Download
                </Link>

                {resumes.length > 0 && (
                  <Checkbox
                    id="isPrimary"
                    name="isPrimary"
                    isChecked={isPrimary}
                    onChange={(e) => changePrimary(e)}
                  >
                    Primary
                  </Checkbox>
                )}

                <Menu closeOnSelect={true}>
                  <MenuButton>
                    <Box
                      sx={{
                        button: {
                          border: 'none',
                          color: 'primary.500',
                        },
                      }}
                    >
                      {resumes.length > 0 && (
                        <Box
                          sx={{
                            button: {
                              height: '25px',
                              fontSize: '13px',
                            },
                          }}
                        >
                          <Button rightIcon="chevron-down">
                            {moment
                              .utc(selectedVersion?.created_at)
                              .format('MMM DD, YYYY hh:mm A')}
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </MenuButton>
                  {
                    <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
                      {resumes?.map((item: any, key: number) => {
                        return (
                          <MenuItem onClick={() => changeResumeVersion(key)}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Box cursor="pointer">
                                {moment
                                  .utc(item?.created_at)
                                  .format('MMM DD, YYYY hh:mm A')}
                              </Box>
                            </Flex>
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  }
                </Menu>

                <Box
                  sx={{
                    button: {
                      radius: '6px',
                      fontSize: '12px',
                      fontWeight: 600,
                      lineHeight: '16px',
                      color: 'primary.600',
                    },
                  }}
                >
                  <Button onClick={onOpenUploadResume}>Update</Button>
                </Box>
              </Flex>
            </Flex>

            <Box mt="15px" textAlign="center">
              {memoizedResume}
            </Box>
          </Box>

          <Box width="40%">
            <TabPanels>
              <TabPanel
                sx={{ overflowY: 'scroll', height: '60vh', padding: '24px' }}
              >
                {clientTab === 'about' && (
                  <>
                    {!isEdit && (
                      <Flex
                        sx={{
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          mb: '16px',
                        }}
                      >
                        Personal Information
                        <Box
                          sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            lineHeight: '14.62px',
                            textDecor: 'underline',
                            cursor: 'pointer',
                          }}
                          onClick={() => setIsEdit((prev) => !prev)}
                        >
                          Edit
                        </Box>
                      </Flex>
                    )}
                    <CandidatesAbout isEdit={isEdit} setIsEdit={setIsEdit} />
                  </>
                )}
              </TabPanel>
              <TabPanel sx={{ overflowY: 'scroll', height: '50vh' }}>
                {clientTab === 'notes' && <CandidatesNotes />}
              </TabPanel>
              <TabPanel sx={{ overflowY: 'scroll', height: '60vh' }}>
                {clientTab === 'activity' && <CandidatesActivity />}
              </TabPanel>
              <TabPanel sx={{ overflowY: 'scroll', height: '60vh' }}>
                {clientTab === 'associated_jobs' && (
                  <CandidatesAssociatedJobs />
                )}
              </TabPanel>
              <TabPanel sx={{ overflowY: 'scroll', height: '60vh' }}>
                {clientTab === 'attachments' && <CandidatesAttachments />}
              </TabPanel>
              <TabPanel sx={{ overflowY: 'scroll', height: '60vh' }}>
                {clientTab === 'interviews' && <CandidatesInterviews />}
              </TabPanel>
            </TabPanels>
          </Box>
        </Flex>
      </Tabs>

      {isOpenUploadResume && (
        <CandidateUpdateResumeModal
          isOpen={isOpenUploadResume}
          onClose={onCloseUploadResume}
        />
      )}
    </>
  );
};

export default CandidatesTabs;

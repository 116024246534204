import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import moment from 'moment';

import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  InputGroup,
  Link,
  Select,
} from '@chakra-ui/react';

import Button from 'Library/Button';

import ApprovalsTabs from './Tabs';
import LeadType from '../LeadTypes';

import {
  getApproval,
  useApproveApprovalMutation,
  useListAppprovalsMutation,
  useRejectApprovalMutation,
} from 'store/approval.slice';

import { AtsConfirm, RejectAts } from 'utils/swal';
import { approvalInterface, swalContent } from 'types';
import LeadStatus from '../LeadStatus';
import toUrl from 'utils/toUrl';
import ProfileCard from 'components/profile-card.component';
import { navClient } from 'store/client.slice';
import AtsSelect from '../AtsSelect';

interface LeadProps {
  isOpen: boolean;
  onClose: () => void;
  rowId: number;
  totalLeads: number;
  handlePrevData: (approval: approvalInterface) => void;
  handleNextData: (approval: approvalInterface) => void;
}

function ApprovalsDrawer({
  isOpen,
  onClose,
  rowId,
  totalLeads,
  handlePrevData,
  handleNextData,
}: LeadProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { approvalData, approvals, approvalPgBtn, approvalDetails } =
    useSelector((state: any) => state.approvals);
  const { userList } = useSelector((state: any) => state.user);
  const [reqApprovals] = useListAppprovalsMutation();
  const [reqReject, resReject] = useRejectApprovalMutation();
  const [reqApprove, resApprove] = useApproveApprovalMutation();
  const [leadOwner, setLeadOwner] = useState(
    Object.values(userList)
      .map((item: any) => ({
        label: [item.first_name, item.last_name].join(' '),
        value: item.id,
      }))
      .filter((val: any) => val.value === approvalData?.lead?.owner_id)[0]
  );
  useEffect(() => {
    reqApprovals(approvalPgBtn);
  }, [approvalPgBtn, reqApprovals, resApprove.isSuccess, resReject.isSuccess]);

  const approvalID = params.approvalsId;

  useEffect(() => {
    setLeadOwner(
      Object.values(userList)
        .map((item: any) => ({
          label: [item.first_name, item.last_name].join(' '),
          value: item.id,
        }))
        .filter((val: any) => val.value === approvalData?.lead?.owner_id)[0]
    );
    if (approvalID) {
      const getData =
        approvals &&
        approvals.filter(
          (approval: any) => approval.id === Number(approvalID)
        )[0];
      dispatch(getApproval({ approvalData: getData }));
    }
  }, [dispatch, approvalID, approvals, approvalData?.lead?.owner_id]);

  const handleOpenDrawer = () => {
    if (approvalID) {
      return true;
    }
    return isOpen;
  };

  const ownerChange = async (e: any) => {
    // approvalData?.lead?.owner_id
    setLeadOwner(e);
  };

  const approve = async (data: any) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to approve lead: ${data.lead?.first_name} ${data.lead?.last_name}`,
      buttons: ['Cancel', 'Approve it!'],
      icon: 'info',
    };

    const confirm = await AtsConfirm(content);
    if (confirm) {
      const form = {
        owner_id: leadOwner,
      };
      await reqApprove({ data: form, id: data.id });
      onClose();
      reqApprovals(approvalPgBtn);
    }
  };

  const reject = async (data: any) => {
    onClose();

    let [confirm, content] = await RejectAts(data);
    if (confirm) {
      let form = {
        reason: content,
        owner_id: leadOwner,
      };
      await reqReject({ data: form, id: data.id });
      reqApprovals(approvalPgBtn);
    }
  };

  return (
    <>
      {approvalData && (
        <Drawer
          key={approvalData.id}
          onClose={() => {
            onClose();
            navigate('/approvals');
            dispatch(getApproval({ approvalData: {} }));
          }}
          isOpen={handleOpenDrawer()}
          size="xl"
        >
          <DrawerOverlay top="60px" />
          <DrawerContent
            maxW="80vw"
            top="62px! important"
            fontFamily="Proxima Nova Regular"
          >
            <DrawerCloseButton
              position="absolute"
              top="0px"
              left="-40px"
              borderRadius="unset"
              p="20px"
              bg="#E6E9EE"
              color="default.secondarytext"
              _hover={{
                backgroundColor: 'none',
              }}
            />
            <Flex
              justifyContent="space-between"
              alignItems="center"
              pl={8}
              pr={0}
              borderBottom="1px solid #E6E9EE"
              fontSize="sm"
              fontWeight="normal"
              color="default.secondarytext"
            >
              <Box>
                Showing <b>{rowId}</b> of {totalLeads}
              </Box>
              <Flex>
                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-left"
                    variant="ghost"
                    onClick={() => rowId !== 1 && handlePrevData(approvalData)}
                    disabled={rowId === 1}
                  />
                </Box>

                <Box borderLeft="1px solid #6B6C7B" borderRadius="unset">
                  <Button
                    iconName="chevron-right"
                    variant="ghost"
                    onClick={() =>
                      rowId !== totalLeads && handleNextData(approvalData)
                    }
                    disabled={rowId === totalLeads}
                  />
                </Box>
              </Flex>
            </Flex>
            <DrawerBody p="0">
              <Box
                bg="default.white.800"
                borderBottom="1px solid"
                borderColor="default.white.400"
                p="32px"
                pb="60px"
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb="10px"
                >
                  <Flex gap={3} alignItems="center">
                    <Box
                      fontSize="20px"
                      fontWeight="600"
                      color="default.primarytext"
                    >
                      <Link href={`/leads/${approvalData.lead_id}/details`}>
                        {[
                          approvalData?.lead?.first_name,
                          approvalData?.lead?.last_name,
                        ].join(' ')}
                      </Link>
                    </Box>
                    <Box>
                      <LeadStatus
                        status={approvalData?.approvalStatus?.status}
                      />
                    </Box>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb="10px"
                >
                  <Box>
                    <Flex
                      gap={2}
                      fontSize="md"
                      color="default.gray.600"
                      mb="10px"
                    >
                      <Box fontSize="md" color="default.gray.800">
                        {approvalData?.lead?.title}
                      </Box>
                      |
                      <Box
                        fontSize="md"
                        color="default.gray.800"
                        onClick={() => {
                          dispatch(navClient({ clientNav: 'all-clients' }));
                          navigate(
                            `/clients/${approvalData?.lead?.client_id}/overview`
                          );
                        }}
                      >
                        <Link>{approvalData?.lead?.company}</Link>
                      </Box>
                      |
                      <Box fontSize="md" color="default.gray.800">
                        <Link
                          href={toUrl(approvalData?.lead?.website)}
                          target="_blank"
                          style={{ display: 'flex', gap: '7px' }}
                          rel="noreferrer"
                        >
                          {toUrl(approvalData?.lead?.website)}
                        </Link>
                      </Box>
                    </Flex>
                    <Flex
                      alignItems="center"
                      gap={2}
                      fontSize="md"
                      color="default.gray.600"
                    >
                      <Box fontSize="sm" color="default.secondarytext">
                        Created on:{' '}
                        {moment(approvalData.created_at).format(
                          'MM/DD/YYYY hh:mm A'
                        )}
                      </Box>
                      |
                      <Flex
                        fontSize="sm"
                        color="default.secondarytext"
                        gap="5px"
                      >
                        Lead Owner:{' '}
                        <ProfileCard
                          // id={approvalData?.lead?.leadOwner?.id}
                          id="1"
                          user_id={approvalData?.lead?.leadOwner?.id}
                          name={[
                            approvalData?.lead?.leadOwner?.first_name,
                            approvalData?.lead?.leadOwner?.last_name,
                          ].join(' ')}
                          email={approvalData?.lead?.leadOwner?.email}
                          thumbnail={
                            approvalData?.lead?.leadOwner?.thumbnailSignedUrl
                          }
                          profile={approvalData?.lead?.leadOwner?.signedUrl}
                        />
                      </Flex>
                    </Flex>
                  </Box>
                  {approvalData?.approvalStatus?.status ===
                    'Approval Pending' && (
                      <Box>
                        <Box fontSize="sm" color="default.secondarytext" pb={3}>
                          Initiated by:
                          {[
                            ' ',
                            approvalDetails?.user?.first_name,
                            approvalDetails?.user?.last_name,
                          ].join(' ')}
                        </Box>
                        <Flex gap={4}>
                          <Box>
                            <Box
                              fontSize="sm"
                              color="default.secondarytext"
                              pb={3}
                            >
                              Lead Owner
                            </Box>
                            <Box background="#fff">
                              {/* <InputGroup>
                            <InputRightElement
                              cursor="pointer"
                              children={<FAIcon iconName="chevron-down" />}
                            />
                            <Input
                              type="search"
                              placeholder="Lead First Owner Name"
                            />
                          </InputGroup> */}
                              <InputGroup>
                                <AtsSelect
                                  id="lead_owner"
                                  name="lead_owner"
                                  placeholder="Select"
                                  defaultValue={leadOwner}
                                  onChange={(e: any) => ownerChange(e.value)}
                                  options={Object.values(userList).map(
                                    (item: any) => ({
                                      label: [
                                        item.first_name,
                                        item.last_name,
                                      ].join(' '),
                                      value: item.id,
                                    })
                                  )}
                                />
                              </InputGroup>
                            </Box>
                          </Box>
                          <Box>
                            <Flex
                              fontSize="sm"
                              color="default.secondarytext"
                              pb={2}
                              gap={1}
                            >
                              Lead Pending Approval to be
                              <Box color="default.primarytext" fontWeight="bold">
                                <LeadType type={approvalData?.leadType?.type} />
                              </Box>
                            </Flex>
                            <Flex gap={4}>
                              <Button
                                leftIcon="times"
                                onClick={() => reject(approvalData)}
                              >
                                Reject
                              </Button>
                              <Button
                                variant="solid"
                                leftIcon="check"
                                onClick={() => approve(approvalData)}
                              >
                                Approve
                              </Button>
                            </Flex>
                          </Box>
                        </Flex>
                      </Box>
                    )}
                </Flex>
              </Box>
              <Box mt="-39px" p="0 32px">
                <ApprovalsTabs />
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default ApprovalsDrawer;

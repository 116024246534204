import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import {
  Badge,
  Box,
  Checkbox,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import JobsDrawer from './Drawer';
import Pagination from 'Library/Pagination';
import JobsModal from './Modal';
import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import {
  getJob,
  listJobs,
  useBulkDeleteJobsMutation,
  useDeleteJobsMutation,
  useGetJobsMutation,
  useListByContactCandidatesMutation,
  useListJobsMutation,
  useToggleAdsStatusMutation,
  useToggleStatusMutation,
} from 'store/jobs.slice';

import { JobsTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import LoadingPage from '../Loading';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import JobsAdsModal from './Modal/jobAds';
import { navClient } from 'store/client.slice';

import Fire from 'assets/images/Fire.png';
import { FollowUpModal } from './Modal/followup/FollowUpModal';

interface JobTableProps {
  setIsViewCandidates: React.Dispatch<React.SetStateAction<boolean>>;
}

interface followUpClickProps {
  open: boolean;
  all: boolean;
  job_id: number;
  lead_id: number | null;
}

interface followUpModalProps {
  lead_id: number | null;
  job_id: number | null;
}

export default function JobsTable({ setIsViewCandidates }: JobTableProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { jobs, jobPage, jobPgBtn } = useSelector((state: any) => state.jobs);

  const [reqJobs, resJobs] = useListJobsMutation();
  const [reqGetJob, resGetJob] = useGetJobsMutation();
  const [reqToggleStatus] = useToggleStatusMutation();
  const [reqToggleAds] = useToggleAdsStatusMutation();
  const [reqDelJob] = useDeleteJobsMutation();
  const [reqBulkDelJob, resBulkDelJob] = useBulkDeleteJobsMutation();
  const [reqListByContactCandidates] = useListByContactCandidatesMutation();

  const [rowId, setRowId] = useState(1);
  const [modalTitle, setModalTitle] = useState('Edit Job');
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [jobDataState, setJobData] = useState([]);
  const [jobAdsData, setJobAdsData] = useState([]);
  const [toggleClickState, setToggleClick] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [jobId, setJobId] = useState(null);

  const [isSorted, setIsSorted] = useState(false);
  const [isFollowUp, setIsFollowUp] = useState(false);
  const [isFollowUpAll, setIsFollowUpAll] = useState(false);
  const [orderBy] = useState(null);
  const [followUpParam, setFollowUpParam] = useState<followUpModalProps>({
    lead_id: null,
    job_id: null,
  });

  document.documentElement.dir = 'ltr';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isEditJobOpen,
    onOpen: onEditJobOpen,
    onClose: onEditJobClose,
  } = useDisclosure();

  const {
    isOpen: isEditJobAdsOpen,
    onOpen: onEditJobAdsOpen,
    onClose: onEditJobAdsClose,
  } = useDisclosure();

  const {
    isOpen: isFollowUpOpen,
    onOpen: onFollowUpOpen,
    onClose: onFollowUpClose,
  } = useDisclosure({ defaultIsOpen: false });

  useEffect(() => {
    const jobID =
      jobs.findIndex((x: JobsTypes) => x.id === Number(params.jobsId)) + 1;
    setRowId(jobID);
  }, [jobs, params.jobsId]);

  useEffect(() => {
    if (params.jobsId) {
      reqGetJob({ id: params.jobsId });
      onOpen();
    }
  }, [params.jobsId]);

  useEffect(() => {
    if (isFollowUp && resGetJob.isSuccess) {
      onFollowUpOpen();
    }
  }, [isFollowUp, resGetJob.isSuccess]);

  const handleClick = (job: JobsTypes) => {
    dispatch(getJob({ jobData: job }));

    onOpen();
    navigate(`/jobs/${job.id}/details`);

    const key = jobs.findIndex((x: JobsTypes) => x.id === job.id) + 1;
    setRowId(key);
  };

  let content: swalContent = {
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this job data.',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleToggleStatus = async (job: JobsTypes) => {
    const status = job.job_status_id === 1 ? 'closed' : 'open';
    content.text = `You are about to mark this job as ${status}, continue?`;
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleStatus({ id: job.id });
      reqJobs(jobPgBtn);
    }
  };

  const handleToggleAdsStatus = async (job: any) => {
    const status = job.job_status_id === 1 ? 'inactive' : 'open';
    content.text = `You are about to mark this job ads as ${status}, continue?`;
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleAds({ id: job.id });
      reqJobs(jobPgBtn);
    }
  };

  const stateTab: any = params.jobsTab;

  const handlePrevData = () => {
    if (rowId !== 0) {
      const jobID = jobs[rowId - 1 - 1].id;
      setRowId((prevKey: number) => prevKey - 1);
      navigate(`/jobs/${jobID}/${stateTab}`);
      const prevData = jobs.filter((data: JobsTypes) => data.id === jobID);
      dispatch(getJob({ jobData: prevData[0] }));
    }
  };

  const handleNextData = () => {
    const jobID = jobs[rowId - 1 + 1].id;
    setRowId((prevKey: number) => prevKey + 1);
    navigate(`/jobs/${jobID}/${stateTab}`);
    const nextData = jobs.filter((data: JobsTypes) => data.id === jobID);
    dispatch(getJob({ jobData: nextData[0] }));
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(jobs.map((li: any) => li.id));

    if (!checked) {
      setIsCheck([]);
    }
  };

  const handleCheck = async (e: any) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
    }
  };

  useEffect(() => {
    setisIndeterminate(false);
    if (isCheck.length > 0 && isCheck.length < jobPgBtn.take) {
      setisIndeterminate(true);
    }
    if (isCheck.length <= 0) {
      setIsCheckAll(false);
    }
    if (isCheck.length === jobPgBtn.take) {
      setIsCheckAll(true);
    }
  }, [isCheck, isCheckAll, jobPgBtn.take]);

  const tableHeader: { label: any; width: any }[] = [
    { label: 'Location', width: '200px' },
    { label: 'Job Boards', width: '100px' },
    { label: 'Client Name', width: '100px' },
    { label: 'Campaign Status', width: '100px' },
    { label: 'Screens', width: '10px' },
    { label: 'Submits', width: '10px' },
    { label: 'Interview', width: '10px' },
  ];

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: jobPgBtn.take,
      query: jobPgBtn.query,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    dispatch(
      listJobs({
        jobPgBtn: newParam,
      })
    );
    reqJobs(newParam);
  };

  let dynamicJobs: any = [];

  useEffect(() => {
    dynamicJobs = [];
    if (jobs.length > 0) {
      jobs.map((job: any) => {
        dynamicJobs.push({
          job: job,
          id: job.id,
          title: job.title,
          open: false,
          job_status_id: job.job_status_id,
          client: job.client,
          jobads: job.jobads,
          screen: job.screen,
          submits: job.submits,
          interview: job.interview,
        });
      });
    }
    setJobData(dynamicJobs);
  }, [jobs]);

  useEffect(() => {
    if (isSorted) {
      const params = {
        page: jobPgBtn.page,
        take: jobPgBtn.take,
        query: jobPgBtn.query,
        closed: jobPgBtn.closed,
        open: jobPgBtn.open,
        quickJobs: jobPgBtn.quickJobs,
        job_assigned: jobPgBtn.job_assigned,
        job_ads: jobPgBtn.job_ads,
        draft: jobPgBtn.draft,
        boolean: jobPgBtn.boolean,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };
      dispatch(
        listJobs({
          jobPgBtn: params,
        })
      );
      reqJobs(params);
      setIsSorted(false);
    }
  }, [isSorted]);

  const entryChange = (entries: number) => {
    let newParam = {
      // page: jobPgBtn.page,
      page: 1,
      take: entries,
      query: jobPgBtn.query,
      closed: jobPgBtn.closed,
      open: jobPgBtn.open,
      quickJobs: jobPgBtn.quickJobs,
      job_assigned: jobPgBtn.job_assigned,
      job_ads: jobPgBtn.job_ads,
    };
    dispatch(
      listJobs({
        jobPgBtn: newParam,
      })
    );
    reqJobs(newParam);
  };

  const toggleClick = (key: number, open: boolean) => {
    dynamicJobs = jobDataState;
    dynamicJobs[key]['open'] = !open;
    setJobData(dynamicJobs);
    setToggleClick(!toggleClickState);
  };

  const editAdsClick = (item: any, job: any) => {
    dispatch(listJobs({ jobData: job }));
    setJobAdsData([item]);
    onEditJobAdsOpen();
    setIsUpdate(true);
  };

  const duplicateClick = (item: any, job: any) => {
    dispatch(listJobs({ jobData: job }));
    setJobAdsData([item]);
    onEditJobAdsOpen();
    setIsUpdate(false);
  };

  const locationParse = (location: any) => {
    try {
      // old format (city, country)
      // const loc = [location?.city, location?.country?.country].join(', ');
      // new format (city, state)
      const loc = [location?.city, location?.state].join(', ');
      if (loc !== ', ') {
        return loc;
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  };

  const DelJobs = async (job: any) => {
    // console.log('job', job);
    let content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to delete ${job?.title} (${job?.job?.serial_number}).`,
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDelJob({ id: job.id });
      reqJobs(jobPgBtn);
    }
    // reqDelJob;
  };

  const BulkDelete = async () => {
    let content: swalContent = {
      title: 'Are you sure?',
      text: `You are about to delete all the checked jobs.`,
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    if (isCheck.length > 0) {
      const confirm = await AtsConfirm(content);
      if (confirm) {
        console.log('isCheck', isCheck);
        const data = {
          id: isCheck,
        };
        await reqBulkDelJob(data);
        reqJobs(jobPgBtn);
      }
    }
  };

  const filteredData = tableHeader.filter((item) => {
    return !jobPgBtn.job_ads && item.label === 'Job Boards' ? false : true;
  });

  const followUpClick = (params: followUpClickProps) => {
    const { open, all, job_id, lead_id } = params;
    setFollowUpParam({
      job_id,
      lead_id,
    });
    setIsFollowUp(open);
    setIsFollowUpAll(all);
    reqGetJob({ id: job_id });
    if (all) {
      reqListByContactCandidates({
        id: lead_id,
      });
    }
  };

  return (
    <Box px={6} justifyContent="space-between">
      {isCheck.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {isCheck.length}
              </Box>{' '}
              {isCheck.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button variant="basic" leftIcon="copy" size="sm">
            Duplicate
          </Button>
          <Button variant="basic" leftIcon="ban" size="sm">
            Mark as Closed
          </Button>
          <Button
            variant="basic"
            leftIcon="trash"
            size="sm"
            onClick={() => BulkDelete()}
            loading={resBulkDelJob.isLoading}
          >
            Delete Job
          </Button>
        </Flex>
      )}
      {resJobs.isLoading && <LoadingPage />}
      <TableContainer
        boxSizing="border-box"
        border="1px solid"
        borderColor="default.white.400"
        borderRadius="md"
        height="65vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead sx={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <Tr bg="default.white.600">
              <Th
                sx={{
                  position: 'sticky',
                  left: 0,
                  bg: 'default.white.600',
                  color: 'default.gray.600',
                  boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
                }}
              >
                {jobPgBtn.job_ads ? (
                  <Box sx={{ ml: '10px' }}>Job Details</Box>
                ) : (
                  <Flex gap="30px">
                    <Checkbox
                      onChange={handleSelectAll}
                      isIndeterminate={isIndeterminate}
                      checked={isCheckAll}
                      defaultChecked={false}
                      colorScheme="purple"
                    />
                    <Box>Job Details</Box>
                  </Flex>
                )}
              </Th>
              {(jobPgBtn.job_ads ? tableHeader : filteredData).map(
                (title, key: number) => (
                  <Th key={title.label + '-' + key}>
                    <Box color="default.gray.600">{title.label}</Box>
                  </Th>
                )
              )}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {jobDataState.map((job: any, key: number) => {
              const trueCounts = job.jobads?.reduce(
                (counts: any, jobAd: any) => {
                  Object.keys(jobAd).forEach((key) => {
                    if (jobAd[key]) {
                      counts[key] = (counts[key] || 0) + 1;
                    }
                  });
                  return counts;
                },
                {}
              );
              return (
                <>
                  <Tr
                    key={`jobsTable-${key}`}
                    _hover={{
                      bg: '#f8f9fa',
                    }}
                    cursor="pointer"
                  >
                    <Td
                      position="sticky"
                      left={0}
                      bg="white"
                      boxShadow="inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)"
                      _hover={{
                        bg: '#f8f9fa',
                      }}
                    >
                      <Flex justifyContent="space-between">
                        <Flex alignItems="center" width="100%">
                          {jobPgBtn.job_ads ? (
                            job.jobads?.length > 0 ? (
                              <Box
                                width="16px"
                                onClick={() => toggleClick(key, job.open)}
                                className="dropdownClass"
                              >
                                {job.open ? (
                                  <Icon as={BsChevronDown} />
                                ) : (
                                  <Icon as={BsChevronUp} />
                                )}
                              </Box>
                            ) : (
                              <Box width="16px"></Box>
                            )
                          ) : (
                            <Checkbox
                              onChange={handleCheck}
                              isChecked={isCheck.includes(job.id)}
                              id={job.id.toString()}
                              key={job.id}
                              colorScheme="purple"
                              className="checkboxClass"
                            />
                          )}
                          <Flex
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width="300px"
                            marginLeft={
                              jobPgBtn.job_ads
                                ? job.jobads?.length > 0
                                  ? '15px'
                                  : '15px'
                                : '30px'
                            }
                            onClick={(e: any) => {
                              const isExceptionClick =
                                e.target.classList.contains('checkboxClass') ||
                                e.target.closest('.checkboxClass') ||
                                e.target.classList.contains('dropdownClass') ||
                                e.target.closest('.dropdownClass') ||
                                e.target.classList.contains('menuClass') ||
                                e.target.closest('.menuClass');
                              if (!isExceptionClick) {
                                navigate(
                                  `/jobs/${job.id}/view-candidates/screen`
                                );
                                setIsViewCandidates(true);
                              }
                            }}
                          >
                            <Flex gap="15px">
                              {job.job.job_type_id === 2 ? (
                                <Image
                                  src={Fire}
                                  alt="Fire"
                                  height="14px"
                                  mt="2px"
                                />
                              ) : (
                                <Box width="14px" />
                              )}
                              <Tooltip
                                label={
                                  job.job_status_id === 1
                                    ? 'Open'
                                    : job.job_status_id === 5
                                    ? 'Draft'
                                    : 'Closed'
                                }
                              >
                                <Box
                                  fontSize="12px"
                                  color={
                                    job.job_status_id === 1
                                      ? '#75B42D'
                                      : job.job_status_id !== 5
                                      ? '#D5605D'
                                      : '#999999'
                                  }
                                >
                                  <FAIcon iconName="circle" />
                                </Box>
                              </Tooltip>
                              <Box>
                                <Flex
                                  fontWeight="bold"
                                  fontSize="16px"
                                  color="default.primarytext"
                                  gap="10px"
                                >
                                  <Box
                                    onClick={() => {
                                      navigate(
                                        `/jobs/${job.id}/view-candidates/screen`
                                      );
                                      setIsViewCandidates(true);
                                    }}
                                    sx={{
                                      maxWidth: '170px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {job.title}
                                  </Box>
                                  {job.jobads?.length > 0 && (
                                    <Tooltip label="No. of Job Ads">
                                      <Badge
                                        colorScheme="purple"
                                        border="0.5px solid #44337a"
                                        borderRadius="4px"
                                        height="fit-content"
                                        textTransform="lowercase"
                                        onClick={() =>
                                          toggleClick(key, job.open)
                                        }
                                      >
                                        x{job.jobads?.length}
                                      </Badge>
                                    </Tooltip>
                                  )}
                                </Flex>
                                <Box
                                  fontSize="14px"
                                  color="default.secondarytext"
                                  onClick={() => handleClick(job.job)}
                                >
                                  {job.job?.serial_number}
                                </Box>
                              </Box>
                            </Flex>
                          </Flex>
                        </Flex>
                        <Box className="menuClass">
                          <Menu>
                            <MenuButton>
                              <Button
                                htmlType="menu"
                                variant="ghost"
                                iconName="ellipsis-h"
                                iconSize="xl"
                              />
                            </MenuButton>

                            <MenuList
                              fontSize="sm"
                              sx={{
                                position: 'relative',
                                left: '38px',
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  if (!isNaN(job.id) && job.id !== null) {
                                    reqGetJob({ id: job.id });
                                  }
                                  onEditJobOpen();
                                  setModalTitle('Edit Job');
                                  setIsDuplicate(false);
                                  setJobId(Number(job.id));
                                }}
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FAIcon iconName="pencil" />
                                  <Box ml={12} cursor="pointer">
                                    Edit
                                  </Box>
                                </Flex>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  navigate(
                                    `/jobs/${job.id}/view-candidates/screen`
                                  );
                                  setIsViewCandidates(true);
                                }}
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FAIcon iconName="eye" />
                                  <Box ml={12} cursor="pointer">
                                    View Candidates
                                  </Box>
                                </Flex>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  followUpClick({
                                    open: true,
                                    all: false,
                                    job_id: Number(job.id),
                                    lead_id: Number(job?.job?.lead_id) || null,
                                  });
                                }}
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FAIcon iconName="envelope" />
                                  <Box ml={12} cursor="pointer">
                                    Follow-up
                                  </Box>
                                </Flex>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  followUpClick({
                                    open: true,
                                    all: true,
                                    job_id: Number(job.id),
                                    lead_id: Number(job?.job?.lead_id) || null,
                                  });
                                }}
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FAIcon iconName="envelope" />
                                  <Box ml={12} cursor="pointer">
                                    Follow-up for All Jobs
                                  </Box>
                                </Flex>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  if (!isNaN(job.id) && job.id !== null) {
                                    reqGetJob({ id: job.id });
                                  }
                                  onEditJobOpen();
                                  setModalTitle('Duplicate Job');
                                  setIsDuplicate(true);
                                  setJobId(Number(job.id));
                                }}
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FAIcon iconName="copy" />
                                  <Box ml={12} cursor="pointer">
                                    Duplicate
                                  </Box>
                                </Flex>
                              </MenuItem>

                              {job.job_status_id !== 5 && (
                                <MenuItem
                                  onClick={() => handleToggleStatus(job)}
                                  color={
                                    job.job_status_id === 1
                                      ? 'caution.800'
                                      : 'success.800'
                                  }
                                >
                                  <Flex
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    {job.job_status_id === 1 ? (
                                      <>
                                        <FAIcon iconName="ban" />
                                        <Box ml={12} cursor="pointer">
                                          Mark as Closed
                                        </Box>
                                      </>
                                    ) : (
                                      <>
                                        <FAIcon iconName="check" />
                                        <Box ml={12} cursor="pointer">
                                          Mark as Open
                                        </Box>
                                      </>
                                    )}
                                  </Flex>
                                </MenuItem>
                              )}
                              <MenuItem
                                color="caution.800"
                                onClick={() => {
                                  DelJobs(job);
                                }}
                              >
                                <Flex
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <FAIcon iconName="trash" />
                                  <Box ml={12} cursor="pointer">
                                    Delete Job
                                  </Box>
                                </Flex>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Box>
                      </Flex>
                    </Td>
                    <Td onClick={() => handleClick(job.job)}>
                      <Box
                        width="300px"
                        whiteSpace="break-spaces"
                        sx={{ w: '200px', textWrap: 'balance' }}
                      >
                        {job?.job?.location && locationParse(job.job.location)}
                      </Box>
                    </Td>
                    {jobPgBtn.job_ads && (
                      <Td onClick={() => handleClick(job.job)}>
                        <Flex gap="8px">
                          <Tooltip label="Career Page Publish">
                            <Flex gap="2px" width="27px" marginLeft="15px">
                              <Badge
                                colorScheme="purple"
                                border="0.5px solid #44337a"
                                borderRadius="4px"
                                height="fit-content"
                              >
                                C
                              </Badge>
                              <Box fontSize="9px">
                                {trueCounts?.career_page_publish &&
                                  `x${trueCounts?.career_page_publish}`}
                              </Box>
                            </Flex>
                          </Tooltip>
                          <Tooltip label="Free Job Board Publish">
                            <Flex gap="2px" width="27px">
                              <Badge
                                colorScheme="purple"
                                border="0.5px solid #44337a"
                                borderRadius="4px"
                                height="fit-content"
                              >
                                F
                              </Badge>
                              <Box fontSize="9px">
                                {trueCounts?.free_job_board_publish &&
                                  `x${trueCounts?.free_job_board_publish}`}
                              </Box>
                            </Flex>
                          </Tooltip>
                          <Tooltip label="Paid Job Board Publish">
                            <Flex gap="2px" width="27px">
                              <Badge
                                colorScheme="purple"
                                border="0.5px solid #44337a"
                                borderRadius="4px"
                                height="fit-content"
                              >
                                P
                              </Badge>
                              <Box fontSize="9px">
                                {trueCounts?.paid_job_board_publish &&
                                  `x${trueCounts?.paid_job_board_publish}`}
                              </Box>
                            </Flex>
                          </Tooltip>
                        </Flex>
                      </Td>
                    )}
                    <Td
                      onClick={(e: any) => {
                        // Check if the click was on the checkbox or its parent
                        const isClientHyperlinkClick =
                          e.target.classList.contains('clientClass') ||
                          e.target.closest('.clientClass');
                        if (!isClientHyperlinkClick) {
                          handleClick(job.job);
                        }
                      }}
                    >
                      <Box
                        onClick={() => {
                          dispatch(navClient({ clientNav: 'all-clients' }));
                          navigate(`/clients/${job.client?.id}/overview`);
                        }}
                        className="clientClass"
                      >
                        <Link>{job.client?.name}</Link>
                      </Box>
                    </Td>
                    <Td
                      width="50px"
                      p="5px 24px"
                      fontSize="14px"
                      onClick={() => handleClick(job.job)}
                    >
                      {job.job.is_campaign ? (
                        <Badge
                          sx={{
                            borderRadius: '6px',
                            p: '2px 8px',
                            textTransform: 'capitalize',
                          }}
                          colorScheme="green"
                        >
                          Active
                        </Badge>
                      ) : (
                        <Badge
                          sx={{
                            borderRadius: '6px',
                            p: '2px 8px',
                            textTransform: 'capitalize',
                          }}
                          colorScheme="red"
                        >
                          Inactive
                        </Badge>
                      )}
                    </Td>
                    <Td onClick={() => handleClick(job.job)}>{job.screen}</Td>
                    <Td onClick={() => handleClick(job.job)}>{job.submits}</Td>
                    <Td onClick={() => handleClick(job.job)}>
                      {job.interview}
                    </Td>
                  </Tr>

                  {job.jobads?.length > 0 &&
                    job.open &&
                    job.jobads.map((ads: any) => {
                      return (
                        <Tr
                          key={`jobsAds-${ads.id}`}
                          _hover={{
                            bg: '#dedede',
                          }}
                          bg="#f8f9fa"
                          height="10px"
                          cursor="pointer"
                        >
                          <Td
                            position="sticky"
                            left={0}
                            boxShadow="inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)"
                            _hover={{
                              bg: '#f8f9fa',
                            }}
                            background={
                              job.jobads?.length > 0 ? '#f8f9fa' : 'white'
                            }
                          >
                            <Flex
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="space-between"
                              width={
                                job.jobads?.length > 0 ? 'initial' : '300px'
                              }
                              marginLeft="15px"
                            >
                              <Flex gap="15px" alignItems="center" ml="50px">
                                <Tooltip
                                  label={
                                    ads.job_status_id === 1 ? 'Open' : 'Closed'
                                  }
                                >
                                  <Box
                                    fontSize="7px"
                                    width="10px"
                                    color={
                                      ads.job_status_id === 1
                                        ? '#75B42D'
                                        : ads.job_status_id !== 5
                                        ? '#D5605D'
                                        : '#'
                                    }
                                  >
                                    <FAIcon iconName="circle" />
                                  </Box>
                                </Tooltip>
                                <Box>
                                  <Box
                                    fontWeight="bold"
                                    fontSize="14px"
                                    color="default.primarytext"
                                    sx={{
                                      maxWidth: '170px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                    }}
                                  >
                                    {ads.title}
                                  </Box>
                                  <Box
                                    fontSize="14px"
                                    color="default.secondarytext"
                                  >
                                    {ads.serial_number}
                                  </Box>
                                </Box>
                              </Flex>
                              <Menu>
                                <MenuButton>
                                  <Button
                                    htmlType="menu"
                                    variant="ghost"
                                    iconName="ellipsis-h"
                                    iconSize="xl"
                                  />
                                </MenuButton>

                                <MenuList
                                  fontSize="sm"
                                  sx={{
                                    position: 'relative',
                                    left: '38px',
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => editAdsClick(ads, job.job)}
                                  >
                                    <Flex
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <FAIcon iconName="pencil" />
                                      <Box ml={12} cursor="pointer">
                                        Edit
                                      </Box>
                                    </Flex>
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => duplicateClick(ads, job.job)}
                                  >
                                    <Flex
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <FAIcon iconName="copy" />
                                      <Box ml={12} cursor="pointer">
                                        Duplicate
                                      </Box>
                                    </Flex>
                                  </MenuItem>

                                  <MenuItem
                                    color={
                                      ads.job_status_id === 1
                                        ? 'caution.800'
                                        : 'success.800'
                                    }
                                    onClick={() => handleToggleAdsStatus(ads)}
                                  >
                                    <Flex
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      {ads.job_status_id === 1 ? (
                                        <>
                                          <FAIcon iconName="ban" />
                                          <Box ml={12} cursor="pointer">
                                            Mark as Inactive
                                          </Box>
                                        </>
                                      ) : (
                                        <>
                                          <FAIcon iconName="check" />
                                          <Box ml={12} cursor="pointer">
                                            Mark as Open
                                          </Box>
                                        </>
                                      )}
                                    </Flex>
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Flex>
                          </Td>
                          <Td
                            width="300px"
                            whiteSpace="break-spaces"
                            p="5px 24px"
                            fontSize="14px"
                          >
                            {[ads?.country, ads?.state, ads?.city].join(', ')}
                          </Td>
                          {/* TODO: Data from BE */}
                          <Td p="5px 24px"></Td>
                          <Td p="5px 24px"></Td>
                          <Td p="5px 24px">
                            {ads?.is_campaign ? (
                              <Badge
                                sx={{
                                  borderRadius: '6px',
                                  p: '2px 8px',
                                  textTransform: 'capitalize',
                                }}
                                colorScheme="green"
                              >
                                Active
                              </Badge>
                            ) : (
                              <Badge
                                sx={{
                                  borderRadius: '6px',
                                  p: '2px 8px',
                                  textTransform: 'capitalize',
                                }}
                                colorScheme="red"
                              >
                                Inactive
                              </Badge>
                            )}
                          </Td>
                          <Td width="50px" p="5px 24px" fontSize="14px">
                            {ads.screen}
                          </Td>
                          <Td width="50px" p="5px 24px" fontSize="14px">
                            {ads.submits}
                          </Td>
                          <Td width="50px" p="5px 24px" fontSize="14px">
                            {ads.interview}
                          </Td>
                        </Tr>
                      );
                    })}
                </>
              );
            })}
          </Tbody>
          {isOpen && (
            <JobsDrawer
              isOpen={isOpen}
              onClose={onClose}
              rowId={rowId}
              totalJobs={jobs?.length}
              handlePrevData={handlePrevData}
              handleNextData={handleNextData}
              setIsViewCandidates={setIsViewCandidates}
            />
          )}
          {isEditJobAdsOpen && (
            <JobsAdsModal
              isOpen={isEditJobAdsOpen}
              onClose={onEditJobAdsClose}
              jobAdsData={jobAdsData}
              update={isUpdate}
            />
          )}
        </Table>
      </TableContainer>

      <Pagination
        totalPages={jobPage.lastPage}
        currentPage={jobPage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={jobPage.count}
        currentCount={jobs?.length}
        targetCount={jobPgBtn.take}
      />

      {isEditJobOpen && (
        <JobsModal
          isOpen={isEditJobOpen}
          onClose={onEditJobClose}
          modalTitle={modalTitle}
          duplicate={isDuplicate}
          jobId={jobId}
        />
      )}

      {isFollowUpOpen && (
        <FollowUpModal
          isOpen={isFollowUpOpen}
          onClose={() => {
            onFollowUpClose();
            setIsFollowUp(false);
          }}
          isAll={isFollowUpAll}
          job_id={followUpParam.job_id}
        />
      )}
    </Box>
  );
}

import { FieldArray, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  Box,
  Button as ChakraButton,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  Flex,
  InputGroup,
  InputRightElement,
  Tag,
  Stack,
  HStack,
  Text,
  TagLabel,
  TagCloseButton,
  useDisclosure,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  UnorderedList,
  ListItem,
  CloseButton,
  MenuButton,
  MenuList,
  Menu,
  MenuGroup,
  MenuItem,
} from '@chakra-ui/react';

import Button from 'Library/Button';

import {
  cleanUpload,
  uploadList,
  addToParsingQueue,
  setParsingProgress,
  removeFromParsingQueue,
} from 'store/uploads.slice';
import AtsDropZone from '../../Dropzone';
import { changeUploadName } from 'utils/newFilename';
import { CandidatesUploadResumeTypes } from 'types';
import ScrollToFieldError from 'components/app/ScrollError';
import axios from 'axios';
import { BASE_URL } from 'constants/values';
import UploadResumeAssociateJobModal from './ResumeAssociateJobs';

interface CandidateUploadResumeModalProps {
  isOpen: any;
  onClose: any;
}

export default function CandidateUploadResumeModal({
  isOpen,
  onClose,
}: CandidateUploadResumeModalProps) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { candidate_status } = useSelector((state: any) => state.constants);
  const {
    resumeParsingQueue,
    resumeUploaded,
    resumeAttachments,
    prefix,
    uploading,
  } = useSelector((state: any) => state.uploads);

  const { isOpen: isOpenAlert, onClose: onCloseAlert } = useDisclosure({
    defaultIsOpen: false,
  });

  // USE STATES
  const [errMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );
  const [errorList] = useState({});

  const [status, setStatus] = useState({
    label: 'New' as string,
    value: 1 as number,
  });

  const [selectedJob, setSelectedJob] = useState({
    title: '',
    id: null,
  });

  const [statOption, setStatOption] = useState([]);

  const {
    isOpen: isOpenAssociate,
    onOpen: onOpenAssociate,
    onClose: onCloseAssociate,
  } = useDisclosure();
  useEffect(() => {
    if (candidate_status?.length > 0) {
      let groups: any = [];
      candidate_status.map((item: any) => {
        if (item?.sub?.length > 0) {
          let option: any = [];
          item?.sub?.map((sub: any) => {
            option.push({
              label: sub.sub_status,
              value: sub.id,
            });
          });

          groups.push({
            label: item.status,
            options: option,
          });
        }
      });
      setStatOption(groups);
    }
  }, [candidate_status]);

  const handleStatus = async (data: any) => {
    const form = {
      label: data.label,
      value: data.value,
    };
    setStatus(form);
  };

  const submitAgreement = async (data: any) => {
    data['attachments'] = await changeUploadName(resumeUploaded, prefix);

    if (selectedJob?.id !== null) {
      data['job_id'] = selectedJob?.id;
      data['sub_id'] = status?.value;
    }

    onClose();
    const description =
      resumeUploaded.length > 1
        ? `${resumeUploaded.length} Resumes are added to parse`
        : '1 Resume is added to parse';
    toast({
      title: 'Added Resume',
      description: description,
      status: 'success',
      isClosable: true,
      duration: 3000,
      position: 'top',
    });
    dispatch(cleanUpload({ resumeAttachments: [] }));
    await Promise.all(
      resumeUploaded?.map(async (item: any, key: number) => {
        const submitForm =
          data['job_id'] === null
            ? {}
            : {
                sub_id: data['sub_id'],
                job_id: data['job_id'],
              };
        let id = item.id;

        const file = data['attachments'][key];
        let file_name = data['attachments'][key]['original_file_name'];
        dispatch(uploadList({ uploading: true }));
        dispatch(
          addToParsingQueue({
            id: id,
            name: file_name,
            progress: 0,
            uploading: true,
            file: file,
          })
        );
        const config = {
          withCredentials: true,
          onUploadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch(
              setParsingProgress({
                id: id,
                name: file_name,
                progress: percentCompleted,
                uploading: percentCompleted < 100 ? true : false,
                file: file,
              })
            );
          },
        };
        let url = BASE_URL + `/resume-parser/extract-job/${id}`;
        await axios
          .post(url, submitForm, config)
          .then((res) => {
            let id_parse = 0;
            resumeParsingQueue.map((item: any, key: number) => {
              if (item.id === id) id_parse = key;
            });
            dispatch(removeFromParsingQueue(id_parse));
            toast({
              title: 'Parse Resume',
              description: `${file_name} was successfully Parsed`,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top',
            });
          })
          .catch((err) => {
            let id_parse = 0;
            resumeParsingQueue.map((item: any, key: number) => {
              if (item.id === id) id_parse = key;
            });
            dispatch(removeFromParsingQueue(id_parse));
            toast({
              title: 'Parse Resume',
              description: `${file_name} was unsuccessfully Parsed`,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top',
            });
          });
      })
    );
    if (resumeParsingQueue.length > 0) {
      resumeParsingQueue.map((item: any, key: number) => {
        dispatch(removeFromParsingQueue(key));
      });
    }
    dispatch(cleanUpload({ resumeUploaded: [] }));
  };

  let initialValues: CandidatesUploadResumeTypes = {
    attachments: [],
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
          dispatch(cleanUpload({ resumeAttachments: [] }));
          dispatch(cleanUpload({ resumeUploaded: [] }));
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Upload Resume
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert
                status="error"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Flex gap="20px" position="relative" left="20px">
                  <AlertIcon />
                  <Box>
                    <AlertTitle>Oh snap!</AlertTitle>
                    <AlertDescription>
                      {errMsg || (
                        <>
                          There were some problems in you input. Change a few
                          things up and try again.
                          <UnorderedList
                            mt="10px"
                            fontSize="13px"
                            lineHeight="1.5"
                            color="var(--chakra-colors-red-500)"
                          >
                            {Object.values(errorList).map((key: any) => (
                              <ListItem key={key}>{key}</ListItem>
                            ))}
                          </UnorderedList>
                        </>
                      )}
                    </AlertDescription>
                  </Box>
                </Flex>

                <CloseButton
                  position="absolute"
                  right="20px"
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik initialValues={initialValues} onSubmit={submitAgreement}>
              {({ values, handleSubmit, handleChange, setFieldValue }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Box mb="34px">
                      <AtsDropZone
                        multipleFile={true}
                        isResume
                        uploadedEnd={(e: any) => {}}
                        deletedFile={(key: string) => {}}
                      />
                    </Box>
                    <Flex mb="34px">
                      <Box
                        sx={{
                          button: {
                            p: '10px',
                            height: '35px',
                            fontSize: '12px',
                            // borderRight: 'none',
                            borderRadius: '6px 6px 6px 6px',
                          },
                        }}
                      >
                        <Flex
                          sx={{
                            button: {
                              p: '10px',
                              height: '35px',
                              fontSize: '12px',
                              justifyContent: 'space-between',
                              borderRadius: '6px 6px 6px 6px',
                              alignContent: 'right',
                            },
                          }}
                          height="fit-content"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Text
                            fontSize="14px"
                            lineHeight="18px"
                            color="default.secondarytext"
                            marginRight="10px"
                          >
                            Job:
                          </Text>
                          <Button type="button" onClick={onOpenAssociate}>
                            {selectedJob.id === null
                              ? 'Select A Job'
                              : selectedJob?.title}
                          </Button>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex width="500px">
                      <Box
                        sx={{
                          button: {
                            height: '35px',
                            fontSize: '12px',
                            borderRadius: '6px 6px 6px 6px',
                          },
                        }}
                      >
                        <Menu closeOnSelect={true}>
                          <MenuButton type="button">
                            <Flex
                              sx={{
                                button: {
                                  p: '10px',
                                  width: '150px',
                                  height: '35px',
                                  fontSize: '12px',
                                  // borderLeft: 'none',
                                  justifyContent: 'space-between',

                                  borderRadius: '6px 6px 6px 6px',
                                  alignContent: 'right',
                                },
                              }}
                              height="fit-content"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Text
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                                marginRight="10px"
                              >
                                Job Candidate Status:
                              </Text>
                              <Button rightIcon="chevron-down">
                                {status?.label}{' '}
                              </Button>
                            </Flex>
                          </MenuButton>
                          {
                            <MenuList
                              fontSize="sm"
                              maxHeight="250px"
                              overflowY="auto"
                              marginLeft="145px"
                              sx={{
                                '.chakra-menu__group__title': {
                                  margin: '0.4rem 0.8rem',
                                  fontSize: '12px',
                                  fontWeight: 400,
                                  lineHeight: '10px',
                                  color: 'default.gray.400',
                                  textTransform: 'uppercase',
                                },
                              }}
                            >
                              {statOption.map((val) => {
                                return (
                                  <MenuGroup title={val.label}>
                                    {val.options.map((op: any) => {
                                      return (
                                        <MenuItem
                                          onClick={(e) => {
                                            handleStatus(op);
                                          }}
                                        >
                                          {op.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </MenuGroup>
                                );
                              })}
                            </MenuList>
                          }
                        </Menu>
                      </Box>
                    </Flex>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <ChakraButton
                      variant="solid"
                      type="submit"
                      disabled={uploading || resumeAttachments.length <= 0}
                    >
                      Start Resume Parsing
                    </ChakraButton>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
      {isOpenAssociate && (
        <UploadResumeAssociateJobModal
          isOpen={isOpenAssociate}
          onClose={onCloseAssociate}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
          fromUpload={true}
        />
      )}
    </>
  );
}

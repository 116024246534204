import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import AtsSelect3 from 'components/app/AtsSelect3';
import AtsDropZone from 'components/app/Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import AtsEmailBody from 'components/app/EmailBody';
import { useSelector } from 'react-redux';
import { useGetPanelMembersMutation } from 'store/candidates.slice';
import { useOpenJobsMutation } from 'store/jobs.slice';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import AtsCraetableSelect2 from 'components/app/AtsCreatabeSelect2';
import { fileUploadedInt, InterviewPreviewInt } from 'types';
import { BsEyeFill } from 'react-icons/bs';
import PreviewDetails from './previews/Details';
import replacePlaceholders from 'utils/textPlaceholders';
import NotesModal from '../../Modals/Notes';

interface initialValueInt {
  subject: string;
  date: any;
  start_time: any;
  end_time: any;
  meeting_mode: { label: string; value: string };
  meeting_location: string;
  timezone: { label: string; value: string };
  panel_members: any;
  job_id: any;
  body: string;
  attachment: any;
  primary: boolean;
}

interface interviewDetails {
  onSubmit: (e: any) => void;
  placeholders: (body: any) => string | null;
  jobData: any;
  candidateData: any;
  jobsId: any;
  openJobs: any;
  initialValues: initialValueInt;
  panelOptions: any;
  userOptions: any;
  candidatePairing: (e: any) => void;
}
interface optionInterface {
  label: string;
  value: any;
}

const InterviewDetails = ({
  onSubmit,
  placeholders,
  jobData,
  candidateData,
  jobsId,
  openJobs,
  initialValues,
  panelOptions,
  candidatePairing,
  userOptions,
}: interviewDetails) => {
  let intialOption: optionInterface[] = [];

  const { uploading, uploaded } = useSelector((state: any) => state.uploads);
  const { placehoderPair } = useSelector((state: any) => state.template);
  const [selectedTimezone, setSelectedTimezone] = useState('US/Pacific');

  const [hasJob] = useState(jobsId);
  const [defOption, setDefOption] = useState(intialOption);
  const [meetingPH, setMeetingPh] = useState('Meeting Location');
  const [panelOption, setPanelOptions] = useState<any>(panelOptions);
  const [reqGetPanel, resGetPanel] = useGetPanelMembersMutation();
  const [reqOpen, resOpen] = useOpenJobsMutation();

  const [selectedDate, setSelectedDate] = useState(
    moment.tz(initialValues.timezone.value).format('MM-DD-YYYY')
  );
  const [minDate, setMinDate] = useState(
    moment.tz(moment(), initialValues.timezone.value)
  );
  const [attachments, setAttachments] = useState(initialValues.attachment);

  const { resumes } = useSelector((state: any) => state.candidateResume);

  function generateTimeOptions(
    startMinutes: number,
    numOptions: number,
    minDate: any,
    interval: number
  ) {
    return Array.from({ length: numOptions }, (_, index) => {
      let totalMinutes: number = index * interval + startMinutes;

      const hours = Math.floor(totalMinutes / 60) % 12 || 12;
      const minutes = totalMinutes % 60;
      const period = totalMinutes < 720 ? 'AM' : 'PM';
      const formattedHour = hours.toString().padStart(2, '0');
      const formattedMinute = minutes.toString().padStart(2, '0');
      const label = `${formattedHour}:${formattedMinute} ${period}`;
      const value = totalMinutes;
      const currDate = moment(minDate).format('MM-DD-YYYY');
      const currDateInMins = minDate.hours() * 60 + minDate.minutes();
      const convertedSelectedDate = moment(selectedDate).format('MM-DD-YYYY');
      const isDisabled =
        currDate < convertedSelectedDate
          ? false
          : currDate === convertedSelectedDate
            ? totalMinutes <= currDateInMins
            : true;
      return { label, value, isDisabled };
    });
  }

  // const [currentDateInMin, setCurrentDateInMin] = useState(
  //   minDate.hours() * 60 + minDate.minutes()
  // );

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      date: Yup.date()
        .when('timezone', (timezone, schema) => {
          const minimumDate: any = moment(minDate)
            .tz(timezone.label.replace(/ \(UTC.*$/, ''))
            .format('YYYY-MM-DD');
          return schema.min(minimumDate, 'Date is too early.');
        })
        .required('Date is required.'),
      job_id: Yup.number().required('Job is required.'),
      start_time: Yup.object()
        .shape({
          label: Yup.string().required('Start time is required.'),
          value: Yup.string(),
          isDisabled: Yup.boolean(),
        })
        .required('Start time is required.'),
      // .test(
      //   'is-false',
      //   'Start time selected is not allowed',
      //   (value: any) =>
      //     !(value.value <= currentDateInMin
      //       ? minDate.toISOString().split('T')[0] >= selectedDate
      //       : false)
      // ),
      end_time: Yup.object()
        .shape({
          label: Yup.string().required('End time is required.'),
          value: Yup.string().required('End time is required.'),
          isDisabled: Yup.boolean().required('End time is required.'),
        })
        .required('End time is required.'),
      // .test(
      //   'is-false',
      //   'End time selected is not allowed',
      //   (value: any) =>
      //     !(value.value <= currentDateInMin
      //       ? minDate.toISOString().split('T')[0] >= selectedDate
      //       : false)
      // ),
      meeting_mode: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required('Meeting mode is required.'),
      meeting_location: Yup.string()
        .when('meeting_mode', {
          is: (value: any) => value === 'On-site',
          then: Yup.string().required('Meeting location is required.'),
        })
        .when('meeting_mode', {
          is: (value: any) => value === 'Phone Interview',
          then: Yup.string()
            .matches(/^[\d ()+-.]+$/gi, 'Phone number is invalid')
            .required('Phone Number is required.'),
        }),
      panel_members: Yup.array().of(
        Yup.object().shape({
          value: Yup.mixed().test(
            'is-string-or-number',
            'Must be a string or a number',
            (value) => {
              return typeof value === 'string' || typeof value === 'number';
            }
          ),
          label: Yup.string().required('Label is required'),
        })
      ),
      timezone: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required('Timezone is required.'),
      body: Yup.string(),
      primary: Yup.boolean(),
    })
  );

  const [timezones, setTimezones] = useState([]);

  const setMinimumDate = async (timezone: any) => {
    setMinDate(moment.tz(moment(), timezone));
  };

  useEffect(() => {
    const minimumDate = moment.tz(moment(), selectedTimezone);
    setStartTimeOptions(generateTimeOptions(0, 95, minimumDate, 30));
    setStartTimeOptionsAll(generateTimeOptions(0, 1410, minimumDate, 1));
    setEndTimeOptionsAll(generateTimeOptions(30, 1410, minimumDate, 1));
    setEndTimeOptions(generateTimeOptions(30, 95, minimumDate, 30));
    // setCurrentDateInMin(minimumDate.hours() * 60 + minimumDate.minutes());
    setMinDate(minDate);
  }, [selectedDate, selectedTimezone]);

  useEffect(() => {
    const fetchTimezones = async () => {
      const timezoneString = [
        'US/Pacific',
        'US/Central',
        'US/Eastern',
        'US/Alaska',
        'US/Mountain',
        'US/Hawaii',
        'EST',
        'Asia/Manila',
      ];

      const timezoneData = timezoneString.map((zone) => {
        const offset = moment.tz(zone).format('Z');
        const label = `${zone} (UTC${offset})`;
        return {
          label,
          value: zone === 'EST' ? 'Eastern Standard Time' : zone,
        };
      });

      setTimezones(timezoneData);
    };

    fetchTimezones();
  }, [candidateData]);

  const meetingModeOptions = [
    { label: 'Ms Team', value: 'Video Interview' },
    { label: 'On-site', value: 'In-Person Interview' },
    { label: 'Phone Interview', value: 'Phone Interview' },
  ];

  const [quilNotes, setquilNotes] = useState(initialValues.body || '');

  const formatOption = async (jobs: any) => {
    let options: optionInterface[] = [];
    await Promise.all(
      jobs.map(async (job: any) => {
        options.push({
          label: job?.job?.title,
          value: job?.job?.id,
        });
      })
    );

    return await options;
  };

  const endTimeRef = useRef(null);

  const [startTimeOptions, setStartTimeOptions] = useState(
    generateTimeOptions(480, 63, minDate, 30)
  );

  const [startTimeOptionsAll, setStartTimeOptionsAll] = useState(
    generateTimeOptions(480, 930, minDate, 1)
  );
  const [endTimeOptionsAll, setEndTimeOptionsAll] = useState(
    generateTimeOptions(510, 930, minDate, 1)
  );
  const [endTimeOptions, setEndTimeOptions] = useState(
    generateTimeOptions(510, 63, minDate, 30)
  );

  function adjustTime(timeValue: any, timeOptions: any) {
    const time = timeOptions.find((timeOption: any) => {
      return timeOption.value === timeValue;
    });
    return {
      label: time.label,
      value: time.value,
      isDisabled: time.isDisabled,
    };
  }

  function compareTime(startTime: any, endTime: any) {
    return startTime > endTime ? 1 : startTime === endTime ? 0 : -1;
  }

  useEffect(() => {
    if (!hasJob) {
      const data = {
        query: '',
        candidate_id: candidateData?.id,
      };
      reqOpen(data);
    }
  }, []);

  useEffect(() => {
    if (resOpen.isSuccess) {
      let options: optionInterface[] = [];
      openJobs.map((job: any) => {
        options.push({
          label: job?.job?.title,
          value: job?.job?.id,
        });
      });
      setDefOption(options);
    }
  }, [resOpen, resOpen.isSuccess]);

  const loadOpen = async (
    inputValue = '',
    callback: (options: optionInterface[]) => void
  ) => {
    const getData = async () => {
      const options = await formatOption(openJobs);
      const selectedOptions = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(selectedOptions);
    };
    getData().catch(console.error);
  };
  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });
  const {
    isOpen: isOpenNotes,
    onClose: onCloseNotes,
    onOpen: onOpenNotes,
  } = useDisclosure();

  const meetingModeChange = (mode: any) => {
    if (mode === 'Ms Team') {
      setMeetingPh('Meeting Link');
    }
    if (mode === 'On-site') {
      setMeetingPh('Meeting Location');
    }
    if (mode === 'Phone Interview') {
      setMeetingPh('Phone Number');
    }
  };

  const formatDate = (dateString: any) => {
    try {
      const date = moment(dateString).format('MM/DD/YYYY');
      return date;
    } catch (e) {
      return '';
    }
  };
  const inputRef = useRef(null);
  const handleKeyDown = (event: any) => {
    // Prevent deleting "/" character
    if (
      event.key === '/' &&
      event.code === 'Backspace' &&
      event.value.includes('/')
    ) {
      event.preventDefault();
    }
    if (event.key === 'Tab') {
      const { value } = event.target;
      const dateParts = value.split('/');
      let month = dateParts[0] || '';
      let day = dateParts[1] || '';
      let year = dateParts[2] || '';

      event.preventDefault();
      const input = inputRef.current;

      if (input.selectionStart === 0) {
        input.setSelectionRange(3, 5);
        if (day === 'DD') day = '00';
      } else if (input.selectionStart === 3) {
        input.setSelectionRange(6, 10);
        if (year === 'YYYY') year = '0000';
      } else {
        input.setSelectionRange(0, 2);
        if (month === 'DD') month = '00';
      }
      event.target.value = month + '/' + day + '/' + year;
    }
  };
  const handleKeyPress = (event: any) => {
    const charCode = event.which || event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      // Allow numeric characters (0-9)
      return true;
    }
    // Prevent alphabetic characters and other keys
    event.preventDefault();
    return false;
  };

  const changeStartAndEndTime = async (
    values: any,
    setFieldValue: any,
    currentDateInMinutes: number
  ) => {
    if (values.start_time.value < currentDateInMinutes) {
      const adjustStartTime = startTimeOptionsAll.find(
        (timeOption) =>
          timeOption.value >= currentDateInMinutes &&
          timeOption.value % 30 === 0
      );
      setFieldValue('start_time', adjustStartTime);
    }

    if (values.end_time.value < currentDateInMinutes) {
      const adjustEndTime = endTimeOptionsAll.find(
        (timeOption) =>
          timeOption.value >= currentDateInMinutes + 30 &&
          timeOption.value % 30 === 0
      );
      setFieldValue('end_time', adjustEndTime);
    }
  };

  const handleTimezoneChange = async (
    event: any,
    values: any,
    setFieldValue: any
  ) => {
    setFieldValue('timezone', event);
    const tz = event.label;
    const rawTz = tz.replace(/ \(UTC.*$/, '');
    setSelectedTimezone(rawTz);
    const minimumDate = moment.tz(moment(), rawTz);
    const currentDateInMinutes =
      minimumDate.hours() * 60 + minimumDate.minutes();
    setMinimumDate(rawTz);
    if (new Date(minimumDate.format('YYYY-MM-DD')) >= new Date(selectedDate)) {
      changeStartAndEndTime(values, setFieldValue, currentDateInMinutes);
    }
  };

  const handleDateChange = (event: any, values: any, setFieldValue: any) => {
    const { value } = event.target;
    let dateString = value;
    const dateParts = value.split('/');

    if (dateString === '') {
      setFieldValue('date', 'MM/DD/YYYY');
    }

    if (dateParts.length !== 3) {
      return;
    }

    let [month, day, year] = dateParts;

    month = month.padStart(2, '0');

    const maxDaysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (
      parseInt(month) < 1 ||
      parseInt(month) > 12 ||
      parseInt(day) < 1 ||
      parseInt(day) > maxDaysPerMonth[parseInt(month) - 1] ||
      parseInt(year) < 0
    ) {
      setFieldValue('date', 'MM/DD/YYYY');
    } else {
      day = day.padStart(2, '0');
      year = year.padStart(4, '0');
      const formattedDate = `${month}/${day}/${year}`;
      setFieldValue('date', formattedDate);
      setSelectedDate(`${year}-${month}-${day}`);
    }
  };

  const filterStartTimeOptions = (
    option: any,
    rawInput: string,
    selected_time: number
  ) => {
    const { label, value } = option;
    // cleans raw inputs and label of all leading zeros and spaces to accommodate all valid use cases
    const cleanedTime = rawInput.replace(/\s|^0+/g, '').toLocaleUpperCase();
    const cleanedLabel = label.replace(/\s|^0+/g, '');

    if (
      cleanedLabel.startsWith(cleanedTime) &&
      (value % 30 == 0 || selected_time === value)
    ) {
      return true;
    }
    {
      const filteredOptions = startTimeOptions.filter((opt: any) =>
        opt.label.replace(/\s|^0+/g, '').startsWith(cleanedTime)
      );
      if (filteredOptions.length === 0) {
        if (cleanedLabel.startsWith(cleanedTime)) return true;
      }
    }
    return false;
  };

  const filterEndTimeOptions = (
    option: any,
    rawInput: string,
    selected_time: number
  ) => {
    const { label, value } = option;
    // cleans raw inputs and label of all leading zeros and spaces to accommodate all valid use cases
    const cleanedTime = rawInput.replace(/\s|^0+/g, '').toLocaleUpperCase();
    const cleanedLabel = label.replace(/\s|^0+/g, '');

    if (
      cleanedLabel.startsWith(cleanedTime) &&
      (value % 30 == 0 || selected_time === value)
    ) {
      return true;
    }
    {
      const filteredOptions = endTimeOptions.filter((opt: any) =>
        opt.label.replace(/\s|^0+/g, '').startsWith(cleanedTime)
      );
      if (filteredOptions.length === 0) {
        if (cleanedLabel.startsWith(cleanedTime)) return true;
      }
    }
    return false;
  };

  const handleStartTimeChange = (
    event: any,
    values: any,
    setFieldValue: any
  ) => {
    const startTime = event.value;
    if (compareTime(startTime, values.end_time.value) >= 0) {
      const endTime = adjustTime(startTime + 30, endTimeOptionsAll);
      setFieldValue('end_time', endTime);
    }
    setFieldValue('start_time', event);
  };

  const handleEndTimeChange = (event: any, values: any, setFieldValue: any) => {
    const endTime = event.value;
    if (compareTime(values.start_time.value, endTime) >= 0) {
      const startTime = adjustTime(endTime - 30, startTimeOptionsAll);
      setFieldValue('start_time', startTime);
    }
    setFieldValue('end_time', event);
  };

  const detailsSubmit = (data: any) => {
    const param = {
      ...data,
      attachment: attachments,
    };
    onSubmit(param);
  };

  const uploadedEnd = async (data: any) => {
    setAttachments([...attachments, data]);
  };

  const deleteFile = (key: string) => {
    const items = attachments.filter(
      (file: fileUploadedInt) => file.id !== key
    );
    setAttachments(items);
  };

  const {
    isOpen: isOpenPreview,
    onOpen: onOpenPreview,
    onClose: onClosePreview,
  } = useDisclosure();

  const [PrevParam, setPrevParam] = useState<InterviewPreviewInt>();

  const getPrimary = () => {
    let email = '';
    try {
      const firstemail =
        candidateData?.emails?.length > 0 ? candidateData?.emails[0] : null;
      const data =
        candidateData?.emails?.find((item: any) => item.primary === 1) ||
        firstemail;
      email = data?.email || '';
    } catch (e) { }
    return email;
  };

  const PanelMembers = (members: any) => {
    let panels = [] as string[];
    if (members.length > 0) {
      members.map((item: any) => {
        panels.push(item.label.toString());
      });
    }

    return panels;
  };

  const primaryResumes = () => {
    const res = resumes.find((resume: any) => resume.is_primary === true);
    return res?.original_file_name || null;
  };

  const prevAttachments = (primary: boolean) => {
    const primaryResume = candidateData?.primary_resume || primaryResumes();

    let att = [] as string[];
    if (primary) att.push(primaryResume);

    if (attachments.length > 0) {
      attachments.map((item: any) => {
        att.push(item.original_file_name);
      });
    }
    return att;
  };

  const placeholdersDetails = (text: string, placeholderPair: any) => {
    return replacePlaceholders(text, placeholderPair);
  };

  const updatePlaceholders = async (values: any) => {
    const dateTimeBody = `${moment(values.date).format('ddd MMM DD')} ${values.start_time?.label
      } to ${values.end_time?.label} (${moment
        .tz(values.timezone?.value)
        .format('Z z')})`;
    const interviewParam = {
      interview_type: values.meeting_mode?.label || '',
      interview_location:
        values.meeting_mode?.label === 'Ms Team'
          ? 'https://teams.microsoft.com/l/meetup-join/'
          : values.meeting_location || '',
      interview_date: dateTimeBody || '',
    };

    const placeholderPair = {
      ...placehoderPair,
      ...interviewParam,
    };
    candidatePairing(placeholderPair);
  };

  const PreviewClick = (values: any) => {
    const dateTimeBody = `${moment(values.date).format('ddd MMM DD')} ${values.start_time?.label
      } to ${values.end_time?.label} (${moment
        .tz(values.timezone?.value)
        .format('Z z')})`;
    const interviewParam = {
      interview_type: values.meeting_mode?.label || '',
      interview_location:
        values.meeting_mode?.label === 'Ms Team'
          ? 'https://teams.microsoft.com/l/meetup-join/'
          : values.meeting_location || '',
      interview_date: dateTimeBody || '',
    };

    const placeholderPair = {
      ...placehoderPair,
      ...interviewParam,
    };
    candidatePairing(placeholderPair);

    const dateTime = `${moment(values.date).format('MMM DD')} ${values.start_time?.label
      } to ${values.end_time?.label}`;
    const param = {
      body: placeholdersDetails(values.body, placeholderPair),
      to: getPrimary(),
      panels: PanelMembers(values.panel_members),
      mode: values.meeting_mode?.label || '',
      location:
        values.meeting_mode?.label === 'Ms Team'
          ? 'https://teams.microsoft.com/l/meetup-join/'
          : values.meeting_location || '',
      subject: placeholdersDetails(values.subject, placeholderPair),
      timezone: values.timezone?.value || '',
      date_time: dateTime,
      attachments: prevAttachments(values.primary),
    } as InterviewPreviewInt;

    setPrevParam(param);
    onOpenPreview();
  };

  const onPanelInputChange = (e: any) => {
    if (e.length > 2) {
      setPanelOptions(userOptions);
    } else {
      setPanelOptions(panelOptions);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={detailsSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <ScrollToFieldError />
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem p="32px" width="100%">
                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.secondarytext"
                >
                  Meeting Mode{' '}
                  <Box as="span" color="caution.800">
                    *
                  </Box>
                </FormLabel>
                <Flex gap="20px">
                  <Box width="40%">
                    <AtsSelect3
                      id="meeting_mode"
                      name="meeting_mode"
                      variant="outline"
                      defaultValue={values.meeting_mode}
                      value={values.meeting_mode}
                      onChange={(event: any) => {
                        handleChange(event.label);
                        meetingModeChange(event.label);
                        const job_title = jobData ? jobData?.title : '';
                        const client_name = jobData
                          ? jobData?.client?.name
                          : '';
                        const meeting_mode = event.value;
                        setFieldValue('meeting_mode', event);
                        const subj =
                          meeting_mode +
                          ' | ' +
                          job_title +
                          ' | ' +
                          candidateData?.first_name +
                          ' ' +
                          candidateData?.last_name +
                          '/' +
                          client_name;
                        setFieldValue('subject', subj);
                      }}
                      options={meetingModeOptions}
                    />
                  </Box>
                  <FormControl
                    mb="24px"
                    isInvalid={Boolean(
                      !!errors.meeting_location && touched.meeting_location
                    )}
                  >
                    <Input
                      id="meeting_location"
                      name="meeting_location"
                      type="input"
                      placeholder={meetingPH}
                      value={values.meeting_location}
                      onChange={handleChange}
                    />
                    <FormErrorMessage>
                      {String(errors.meeting_location)}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>

                <FormLabel
                  fontSize="14px"
                  lineHeight="18px"
                  color="default.secondarytext"
                >
                  Date & Time{' '}
                  <Box as="span" color="caution.800">
                    *
                  </Box>
                </FormLabel>

                <Flex mb="24px" gap="10px">
                  <FormControl
                    isInvalid={Boolean(!!errors.date && touched.date)}
                  >
                    <Input
                      type="text"
                      className="date"
                      pattern="(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/\d{4}"
                      placeholder="MM/DD/YYYY"
                      name="date"
                      min={minDate.toISOString().split('T')[0]}
                      variant="outline"
                      ref={inputRef}
                      value={values.date === '' ? 'MM/DD/YYYY' : values.date}
                      onChange={(e: any) => {
                        handleDateChange(e, values, setFieldValue);
                        let { value } = e.target;
                        let dateString = value;
                        const dateParts = value.split('/');
                        if (dateString === '') {
                          setFieldValue('date', 'MM/DD/YYYY');
                        }

                        if (dateParts.length !== 3) {
                          return;
                        }

                        let month = dateParts[0] || '';
                        let day = dateParts[1] || '';
                        let year = dateParts[2] || '';
                        setFieldValue('date', month + '/' + day + '/' + year);
                      }}
                      maxLength={10}
                      onKeyDown={handleKeyDown}
                      onKeyPress={handleKeyPress}
                      onBlur={(e) => {
                        let { value } = e.target;
                        const dateParts = value.split('/');
                        let isValidDate = true;

                        let month = dateParts[0] || '';
                        let day = dateParts[1] || '';
                        let year = dateParts[2] || '';

                        // DAY VERIFICATION
                        let maxDaysPerMonth = [
                          31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
                        ];

                        const isLeapYear =
                          (parseInt(year) % 4 === 0 &&
                            parseInt(year) % 100 !== 0) ||
                          parseInt(year) % 400 === 0;

                        if (isLeapYear) {
                          maxDaysPerMonth[1] = 29;
                        }

                        if (
                          month === '' ||
                          (month.length !== 2 && parseInt(month) < 0) ||
                          parseInt(month) > 12 ||
                          parseInt(month) <= 0
                        ) {
                          month = 'MM';
                          isValidDate = false;
                        } else if (parseInt(month) > 0) {
                          month = month.padStart(2, '0');
                        }
                        if (
                          day === '' ||
                          (day.length !== 2 && parseInt(day) < 0) ||
                          (month !== 'MM' &&
                            (parseInt(day) >
                              maxDaysPerMonth[parseInt(month) - 1] ||
                              parseInt(day) <= 0))
                        ) {
                          day = 'DD';
                          isValidDate = false;
                        } else if (parseInt(day) > 0) {
                          day = day.padStart(2, '0');
                        }

                        if (
                          year === '' ||
                          (year.length !== 4 && parseInt(year) < 0)
                        ) {
                          year = 'YYYY';
                          isValidDate = false;
                        } else if (parseInt(year) > 0) {
                          year = year.padStart(4, '0');
                        }
                        setFieldValue('date', month + '/' + day + '/' + year);
                        if (isValidDate) {
                          const date = year + '-' + month + '-' + day;
                          if (
                            new Date(minDate.format('YYYY-MM-DD')) >=
                            new Date(date)
                          ) {
                            const currentDateInMinutes =
                              minDate.hours() * 60 + minDate.minutes();
                            changeStartAndEndTime(
                              values,
                              setFieldValue,
                              currentDateInMinutes
                            );
                          }
                          setSelectedDate(date);
                        }
                      }}
                    />
                    <input
                      type="date"
                      name="date"
                      id="last name"
                      style={{
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: '0',
                      }}
                      min={moment(minDate)
                        .tz(values.timezone.label.replace(/ \(UTC.*$/, ''))
                        .format('YYYY-MM-DD')}
                      value={moment(values.date).format('YYYY-MM-DD')}
                      onChange={(e) => {
                        const { value } = e.target;
                        setSelectedDate(value);
                        if (
                          new Date(minDate.format('YYYY-MM-DD')) >=
                          new Date(value)
                        ) {
                          const currentDateInMinutes =
                            minDate.hours() * 60 + minDate.minutes();
                          changeStartAndEndTime(
                            values,
                            setFieldValue,
                            currentDateInMinutes
                          );
                        }
                        const formattedDate = formatDate(value);
                        setFieldValue('date', formattedDate);
                      }}
                    />
                    <FormErrorMessage>{String(errors.date)}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(
                      !!errors.start_time && touched.start_time
                    )}
                  >
                    <AtsSelect3
                      id="start_time"
                      name="start_time"
                      placeholder="--:-- --"
                      variant="outline"
                      className={'MyDropdownStartTime'}
                      classNamePrefix={'MyDropdownStartTime'}
                      onChange={(e: any) =>
                        handleStartTimeChange(e, values, setFieldValue)
                      }
                      filterOption={(option: any, rawInput: string) =>
                        filterStartTimeOptions(
                          option,
                          rawInput,
                          values.start_time.value
                        )
                      }
                      isMulti={false}
                      defaultValue={values.start_time}
                      options={startTimeOptionsAll}
                      value={values.start_time}
                    />
                    <FormErrorMessage>
                      {String((errors.start_time as any)?.label)}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={Boolean(!!errors.end_time && touched.end_time)}
                  >
                    <AtsSelect3
                      id="end_time"
                      name="end_time"
                      ref={endTimeRef}
                      placeholder="--:-- --"
                      variant="outline"
                      className={'MyDropdownEndTime'}
                      classNamePrefix={'MyDropdownEndTime'}
                      onChange={(e: any) =>
                        handleEndTimeChange(e, values, setFieldValue)
                      }
                      filterOption={(option: any, rawInput: string) =>
                        filterEndTimeOptions(
                          option,
                          rawInput,
                          values.end_time.value
                        )
                      }
                      isMulti={false}
                      defaultValue={values.end_time}
                      options={endTimeOptionsAll}
                      value={values.end_time}
                    />
                    <FormErrorMessage>
                      {String((errors.end_time as any)?.label)}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
                <Flex mb="24px" gap="10px">
                  <FormControl
                    isInvalid={Boolean(!!errors.timezone && touched.timezone)}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Timezone{' '}
                      <Box as="span" color="caution.800">
                        *
                      </Box>
                    </FormLabel>

                    <AtsSelect3
                      id="timezone"
                      name="timezone"
                      variant="outline"
                      defaultValue={values.timezone}
                      value={values.timezone}
                      onChange={async (e: any) =>
                        handleTimezoneChange(e, values, setFieldValue)
                      }
                      options={timezones}
                    />
                    <FormErrorMessage>
                      {String(errors.timezone)}
                    </FormErrorMessage>
                  </FormControl>
                </Flex>
              </GridItem>
              <GridItem p="32px" width="100%">
                <FormControl
                  mb="24px"
                  isInvalid={Boolean(!!errors.subject && touched.subject)}
                >
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Subject{' '}
                    <Box as="span" color="caution.800">
                      *
                    </Box>
                  </FormLabel>
                  <Input
                    id="subject"
                    name="subject"
                    type="text"
                    placeholder="Subject"
                    variant="outline"
                    value={values.subject}
                    onChange={handleChange}
                  />
                  <FormErrorMessage>{String(errors.subject)}</FormErrorMessage>
                </FormControl>
                <Box mb="24px">
                  <FormLabel
                    fontSize="14px"
                    lineHeight="18px"
                    color="default.secondarytext"
                  >
                    Panel Members
                  </FormLabel>

                  <AtsCraetableSelect
                    id="panel_members"
                    name="panel_members"
                    onChange={(event: any) => {
                      setFieldValue('panel_members', event);
                    }}
                    options={panelOption}
                    isMulti={true}
                    placeholder="Type Panel Members"
                    defaultValue={values.panel_members}
                    onInputChange={onPanelInputChange}
                  />
                </Box>
                <Box mb="24px">
                  <FormControl
                    isInvalid={Boolean(!!errors.body && touched.body)}
                  >
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Body{' '}
                      <Box as="span" color="caution.800">
                        *
                      </Box>
                    </FormLabel>
                    <AtsEmailBody
                      id="body"
                      value={quilNotes}
                      onChange={(e: any) => {
                        setquilNotes(e);
                        setFieldValue('body', e);
                      }}
                      height={250}
                    />
                    <FormErrorMessage>{String(errors.body)}</FormErrorMessage>
                  </FormControl>
                </Box>
                <Box mb="34px">
                  <Checkbox
                    colorScheme="purple"
                    color="default.secondarytext"
                    defaultChecked={values.primary}
                    name="primary"
                    id="primary"
                    onChange={handleChange}
                  >
                    Attach Primary Resume
                  </Checkbox>
                </Box>
                <Box mb="24px">
                  <FormControl>
                    <FormLabel
                      fontSize="14px"
                      lineHeight="18px"
                      color="default.secondarytext"
                    >
                      Attachments{' '}
                      <Box as="span" color="caution.800">
                        *
                      </Box>
                    </FormLabel>
                    <AtsDropZone
                      multipleFile
                      uploadedEnd={(e: any) => uploadedEnd(e)}
                      reference={attachments}
                      hasRef={true}
                      deletedFile={(key: string) => deleteFile(key)}
                    />
                  </FormControl>
                </Box>
              </GridItem>
            </Grid>
            <ModalFooter
              position="sticky"
              bottom="0"
              background="default.white.100"
              boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
              gap={5}
            >
              <Flex width="100%" justifyContent="space-between">
                <Button
                  onClick={onOpenNotes}
                  left="0px"
                  variant="ghost"
                  color="primary.800"
                >
                  Notes
                </Button>
                <Flex gap={5}>
                  <Button
                    variant="outline"
                    type="button"
                    leftIcon={<BsEyeFill />}
                    onClick={() => PreviewClick(values)}
                  >
                    Preview
                  </Button>
                  <Button
                    variant="solid"
                    type="submit"
                    onClick={() => updatePlaceholders(values)}
                  //   isLoading={resSubmitInterview.isLoading}
                  //   disabled={resSubmitInterview.isLoading || uploading || isOpenAlert}
                  >
                    Next
                  </Button>
                </Flex>

              </Flex>

            </ModalFooter>
          </form>
        )}
      </Formik>
      {isOpenPreview && (
        <PreviewDetails
          isOpen={isOpenPreview}
          onClose={onClosePreview}
          param={PrevParam}
        />
      )}
      {isOpenNotes && (
        <NotesModal
          isOpen={isOpenNotes}
          onClose={onCloseNotes} />
      )}
    </>
  );
};

export default InterviewDetails;

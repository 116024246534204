import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import Button from 'Library/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useChangeSourceCandidateMutation,
  useGetCandidatesMutation,
} from 'store/candidates.slice';
import { useUserListMutation } from 'store/user.slice';

interface CandidateSourceInt {
  candidateData: any;
}

interface sourceParam {
  id: number;
  source_id: number;
  sub_source_id: number | null;
  user_source_id: number | null;
}

const CandidateSource = ({ candidateData }: CandidateSourceInt) => {
  const [sourceLabel, setSourceLabel] = useState(candidateData?.source?.source);
  const { candidate_source } = useSelector((state: any) => state.constants);
  const [reqUser] = useUserListMutation();
  const { userList } = useSelector((state: any) => state.user);
  const [subSourceLabel, setSubLabel] = useState('');
  const [subSource, setSubSource] = useState<any>();
  const [haSub, setHasSub] = useState(false);
  const [selectedSource, setSelectedSource] = useState(
    candidateData?.source?.id
  );
  const [reqSourceChange, resSourceChange] = useChangeSourceCandidateMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();

  useEffect(() => {
    setSourceLabel(candidateData?.source?.source);
    let subs = [] as any;
    if (candidateData?.source?.sub?.length > 0) {
      setHasSub(true);
      candidateData?.source?.sub.map((item: any) => {
        subs.push({
          id: item.id,
          label: item.sub_source,
          user: false,
        });
      });
    }

    if (candidateData?.source?.id == 5 || candidateData?.source?.id == 6) {
      if (Object.keys(userList).length > 0) {
        setHasSub(true);
        Object.values(userList).map((item: any, key: number) => {
          subs.push({
            id: item.id,
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            user: true,
          });
        });
      }
      if (candidateData?.user_source) {
        const label = [
          candidateData?.user_source?.first_name,
          candidateData?.user_source?.last_name,
        ]
          .filter(Boolean)
          .join(' ');
        setSubLabel(label);
      }
    }

    setSubSource(subs);

    if (candidateData.subsource) {
      setHasSub(true);
      setSubLabel(candidateData.subsource?.sub_source);
    }
  }, [candidateData, userList]);

  useEffect(() => {
    if (resSourceChange.isSuccess) {
      reqGetCandidate({ id: candidateData.id });
    }
  }, [resSourceChange.isSuccess]);

  useEffect(() => {
    reqUser({});
  }, []);

  const sourceClick = (val: any) => {
    let subs = [] as any;
    let subLabel = '';
    let sub_source_id = null;
    let user_source_id = null;

    setHasSub(false);
    setSelectedSource(val.id);
    setSourceLabel(val.source);

    if (val.sub?.length > 0) {
      setHasSub(true);
      val.sub.map((item: any, key: number) => {
        if (key == 0) {
          subLabel = item.sub_source;
          sub_source_id = item.id;
        }
        subs.push({
          id: item.id,
          label: item.sub_source,
          user: false,
        });
      });
    }

    //for user list
    if (val.id == 5 || val.id == 5) {
      if (Object.keys(userList).length > 0) {
        setHasSub(true);
        Object.values(userList).map((item: any, key: number) => {
          if (key == 0) {
            subLabel = [item.first_name, item.last_name]
              .filter(Boolean)
              .join(' ');
            user_source_id = item.id;
          }
          subs.push({
            id: item.id,
            label: [item.first_name, item.last_name].filter(Boolean).join(' '),
            user: true,
          });
        });
      }
    }

    setSubLabel(subLabel);
    setSubSource(subs);
    sourceRequest({
      id: candidateData?.id,
      user_source_id,
      sub_source_id,
      source_id: val.id,
    });
  };

  const sourceRequest = async (param: sourceParam) => {
    const { id, source_id, sub_source_id, user_source_id } = param;
    const data = {
      source_id,
      sub_source_id,
      user_source_id,
    };
    await reqSourceChange({ id, data });
  };

  const subSourceClick = (val: any) => {
    setSubLabel(val.label);
    let user_source_id = null;
    let sub_source_id = val.id;
    if (val.user) {
      sub_source_id = null;
      user_source_id = val.id;
    }
    const param = {
      id: candidateData?.id,
      user_source_id,
      sub_source_id,
      source_id: selectedSource,
    };
    console.log('param', param);
    sourceRequest(param);
  };

  return (
    <>
      <Flex py={3} gap="50px" alignItems="center">
        <Box
          fontSize="16px"
          lineHeight="18px"
          color="default.gray.600"
          textAlign="left"
          width="100px"
        >
          Source
        </Box>
        <Menu closeOnSelect={true}>
          <MenuButton>
            <Box
              sx={{
                button: {
                  p: '4px 8px',
                  pl: 0,
                  height: '25px',
                  fontSize: '14px',
                  border: 'none',
                  color: 'primary.500',
                },
              }}
            >
              <Button rightIcon="chevron-down">{sourceLabel}</Button>
            </Box>
          </MenuButton>
          {
            <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
              {candidate_source.map((val: any) => {
                return (
                  <MenuItem onClick={() => sourceClick(val)}>
                    {val.source}
                  </MenuItem>
                );
              })}
            </MenuList>
          }
        </Menu>
      </Flex>
      {haSub && (
        <Flex py={3} gap="50px" alignItems="center">
          <Box
            fontSize="16px"
            lineHeight="18px"
            color="default.gray.600"
            textAlign="left"
            width="100px"
          >
            Sub Source
          </Box>
          <Menu closeOnSelect={true}>
            <MenuButton>
              <Box
                sx={{
                  button: {
                    p: '4px 8px',
                    pl: 0,
                    height: '25px',
                    fontSize: '14px',
                    border: 'none',
                    color: 'primary.500',
                  },
                }}
              >
                <Button rightIcon="chevron-down">{subSourceLabel}</Button>
              </Box>
            </MenuButton>
            {
              <MenuList fontSize="sm" maxHeight="120px" overflowY="auto">
                {subSource?.map((val: any) => {
                  return (
                    <MenuItem onClick={() => subSourceClick(val)}>
                      {val.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            }
          </Menu>
        </Flex>
      )}
    </>
  );
};

export default CandidateSource;

import { createSlice } from '@reduxjs/toolkit';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';
import axios from 'axios';

export const candidatesSlice = createSlice({
  name: 'candidates',
  initialState: {
    candidates: [],
    candidateData: {},
    candidateDataJobId: null,
    associateList: [],
    checkedCandidates: [],
    interviewsList: [],
    panelMembers: [],
    candidateOthers: [],
    candidateLoading: false,
    candidatePage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    candidatePgBtn: {
      page: 1,
      take: 50,
      query: '',
      job_filter: '',
      job_filter_include: 'Current',
      location: '',
      tags: [],
      skills: [],
      experience: [],
      work_status: null,
      job_id: null,
      excluded_job_id: null,
      status: null,
      job_status: null,
      resume_updated_on: null,
      filter: null,
      search: '',
      boolean: true,
      reject_reason: null,
      source: null,
      sub_source: null,
    },
    associatePage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    associatePgBtn: {
      page: 1,
      take: 50,
      query: '',
    },
  },
  reducers: {
    listCandidates: (state, { payload }) => ({ ...state, ...payload }),
    getCandidate: (state, { payload }) => ({ ...state, ...payload }),
    listInterviews: (state, { payload }) => ({ ...state, ...payload }),
    setloadingCandidate: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const {
  listCandidates,
  getCandidate,
  listInterviews,
  setloadingCandidate,
} = candidatesSlice.actions;
export default candidatesSlice.reducer;

let abortControllerRef = axios.CancelToken.source(); // Initialize the reference outside your slice
/* ----------------------------------- API ---------------------------------- */
export const candidatesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ListCandidates: builder.mutation({
      query: (data) => {
        // Cancel the previous request (if any) and create a new cancel token source
        abortControllerRef.cancel('Request canceled');
        abortControllerRef = axios.CancelToken.source();
        return {
          url: '/candidates/list',
          method: 'POST',
          credentials: 'include',
          withCredentials: true,
          crossorigin: true,
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: data,
          cancelToken: abortControllerRef.token,
        };
      },
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        dispatch(setloadingCandidate({ candidateLoading: true }));

        try {
          // Cancel the previous request by canceling the token
          // abortControllerRef.cancel('Request canceled');
          // abortControllerRef = axios.CancelToken.source();
          // console.log('abortControllerRef', abortControllerRef);
          const { data } = await queryFulfilled;
          dispatch(listCandidates({ candidates: data.data.data }));
          dispatch(listCandidates({ candidateOthers: data.data.other }));
          dispatch(
            listCandidates({
              candidatePage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
          dispatch(setloadingCandidate({ candidateLoading: false }));
          // abortControllerRef = null;
        } catch ({ error }) {
          dispatch(setloadingCandidate({ candidateLoading: false }));
          dispatch(appError(error));
          const err: any = error;
          // if (err?.status == 403) {
          //   authService.logout();
          // }
          if (err.name === 'AbortError') {
            // Handle request cancellation
            console.log('Request was canceled.');
          } else {
            dispatch(appError(error));
          }
        }
      },
    }),
    CreateCandidates: builder.mutation({
      query: (data) => ({
        url: '/candidates/create',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              candidateData: { ...data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    QuickUpdateCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/quick-update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              candidateData: { ...data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    FirstNameUpdateCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/update-first-name/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              candidateData: { ...data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    LastNameUpdateCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/update-last-name/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              candidateData: { ...data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    JobTitleUpdateCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-experience/update-job-title/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              candidateData: { ...data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CompanyUpdateCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-experience/update-company/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              candidateData: { ...data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    WorkStatusUpdateCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/work-status-update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // console.log('data.data.data', data.data);
          // dispatch(
          //   getCandidate({
          //     candidateData: { ...data.data, ...data.data.data },
          //   })
          // );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetCandidates: builder.mutation({
      query: ({ id }) => ({
        url: `/candidates/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              candidateData: { ...data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DeleteCandidates: builder.mutation({
      query: ({ id }) => ({
        url: `/candidates/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ToggleHideCandidates: builder.mutation({
      query: ({ id }) => ({
        url: `/candidates/hide-toggle/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    AssociateCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/associate-job/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    BulkAssociateCandidates: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/bulk-associate-job`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    AssociateJobList: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/associate-list/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listCandidates({ associateList: data.data.data }));
          dispatch(
            listCandidates({
              associatePage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    rejectCandidate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/reject/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    mergeCandidates: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/merge/`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    bulkRejectCandidate: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/bulk-reject`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    unRejectCandidate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/unreject/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    bulkUnRejectCandidate: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/bulk-unreject`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    screenCandidate: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/move-to-screen`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),

    offerCandidate: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/move-to-offer`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    hiredCandidate: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/move-to-hired`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    removeFromJobCandidate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/remove-from-job/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    bulkRemoveFromJobCandidate: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/bulk-remove-from-job`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    SubmitToClient: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/submit-to-client/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    sendEmailCandidate: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-email/send/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    bulkSendEmailCandidate: builder.mutation({
      query: ({ data }) => ({
        url: `/candidate-email/bulk-send`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    SubmitInterview: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidate-interview/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListInterview: builder.mutation({
      query: (data) => ({
        url: 'candidate-interview/list',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            listInterviews({
              interviewsList: data.data,
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ManualCandidates: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/manual/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    changeSubStatus: builder.mutation({
      query: ({ data, id }) => ({
        url: `/candidates/sub-status/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    bulkChangeSubStatus: builder.mutation({
      query: ({ data }) => ({
        url: `/candidates/bulk-sub-status`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetPanelMembers: builder.mutation({
      query: ({ id }) => ({
        url: `/candidate-interview/panel-members/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getCandidate({
              panelMembers: { ...data.data, ...data.data.data },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    changeEducationCandidate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/candidate-educations/degree-change/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    changeSourceCandidate: builder.mutation({
      query: ({ id, data }) => ({
        url: `/candidates/source-change/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: { 'Content-type': 'application/json; charset=UTF-8' },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const candidatesInitialState = candidatesSlice.getInitialState(); // Access the initial state

export const {
  useListCandidatesMutation,
  useCreateCandidatesMutation,
  useUpdateCandidatesMutation,
  useQuickUpdateCandidatesMutation,
  useFirstNameUpdateCandidatesMutation,
  useLastNameUpdateCandidatesMutation,
  useJobTitleUpdateCandidatesMutation,
  useCompanyUpdateCandidatesMutation,
  useWorkStatusUpdateCandidatesMutation,
  useGetCandidatesMutation,
  useDeleteCandidatesMutation,
  useAssociateCandidatesMutation,
  useBulkAssociateCandidatesMutation,
  useAssociateJobListMutation,
  useRejectCandidateMutation,
  useBulkRejectCandidateMutation,
  useUnRejectCandidateMutation,
  useBulkUnRejectCandidateMutation,
  useScreenCandidateMutation,
  useRemoveFromJobCandidateMutation,
  useBulkRemoveFromJobCandidateMutation,
  useSubmitToClientMutation,
  useSendEmailCandidateMutation,
  useBulkSendEmailCandidateMutation,
  useSubmitInterviewMutation,
  useManualCandidatesMutation,
  useOfferCandidateMutation,
  useListInterviewMutation,
  useHiredCandidateMutation,
  useChangeSubStatusMutation,
  useBulkChangeSubStatusMutation,
  useGetPanelMembersMutation,
  useToggleHideCandidatesMutation,
  useChangeEducationCandidateMutation,
  useChangeSourceCandidateMutation,
  useMergeCandidatesMutation,
} = candidatesApiSlice;

import {
  Box,
  Flex,
  Grid,
  Link,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import TrLoading from 'components/app/TrLoading';
import FAIcon from 'components/lib/FAIcon';
import Button from 'Library/Button';

import Pagination from 'Library/Pagination';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  listCandidateInterview,
  useListCandidateInterviewMutation,
} from 'store/candidateinterview.slice';
import { useGetCandidatesMutation } from 'store/candidates.slice';
import { useGetJobsMutation, useOpenJobsMutation } from 'store/jobs.slice';
import CandidateJobList from '../components/CandidateJobList';
import CandidatesInterviewModal from './modal';
interface jobselectInt {
  id: number;
  type: string;
  assoc_id?: number;
  reject?: string;
}
const CandidatesInterviews = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { candidateData } = useSelector((state: any) => state.candidates);
  const [reqList, resList] = useListCandidateInterviewMutation();
  const [reqOpen] = useOpenJobsMutation();
  const [reqGetCandidate] = useGetCandidatesMutation();
  const { jobData } = useSelector((state: any) => state.jobs);
  const [reqGetJob, resGetJob] = useGetJobsMutation();
  const [contactOptionValue, setContactOptionValue] = useState([]);
  const [subject, setSubject] = useState('');
  const [jobList, setJobList] = useState([]);
  const [actionType, steActionType] = useState('');
  const [assocId, setAssocId] = useState(0);
  const [jobId, setJobId] = useState(null);
  const callBackAction = () => {
    reqGetCandidate({ id: candidateData?.id });
  };

  const {
    candidateInterviews,
    candidateInterviewPage,
    candidateInterviewPgBtn,
  } = useSelector((state: any) => state.candidatesInterview);

  useEffect(() => {
    if (
      params?.jobsId !== undefined &&
      params?.jobsId !== 'undefined' &&
      params?.jobsId !== null
    ) {
      reqGetJob({ id: params?.jobsId });
    }
  }, []);

  useEffect(() => {
    const id = params?.candidatesId || params?.jobsTabNav;
    reqList({ id: id });
    reqOpen({ query: '', candidate_id: params.candidatesId });
  }, [params.candidatesId]);

  useEffect(() => {
    if (
      params?.jobsId !== undefined &&
      params?.jobsId !== 'undefined' &&
      params?.jobsId !== null &&
      resGetJob.isSuccess
    ) {
      const contactOption = [
        {
          label: [jobData?.lead?.first_name, jobData?.lead?.last_name].join(
            ' '
          ),
          value: jobData?.lead?.id,
        },
      ];

      setContactOptionValue(contactOption);
      const client_name = jobData.client ? jobData?.client?.name : '';
      const subject =
        'Video Interview' +
        ' | ' +
        jobData?.title +
        ' | ' +
        candidateData?.first_name +
        ' ' +
        candidateData?.last_name +
        '/' +
        client_name;

      setSubject(subject);
    }
  }, [resGetJob.isSuccess]);

  const tableHeader: { label: any }[] = [
    { label: 'Interview Name/Type' },
    { label: 'Job' },
    { label: 'Status' },
    { label: 'From' },
    { label: 'To' },
    { label: 'Panels' },
    { label: 'Meeting Link' },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const scheduleInterview = async () => {
    let jobs = [] as any;
    let job_id = null as any;
    let assoc = null as any;
    steActionType('interview');
    await Promise.all(
      candidateData?.jobs?.map(async (item: any) => {
        if (params?.jobsId) {
          if (
            (item.candidate_status_id === 2 ||
              item.candidate_status_id === 1) &&
            item?.job?.id == params?.jobsId
          ) {
            if (item?.job) {
              jobs.push(item);
              job_id = item?.job?.id;
              assoc = item;
            }
          }
        } else {
          if (
            item.candidate_status_id === 2 ||
            item.candidate_status_id === 1
          ) {
            if (item?.job) {
              jobs.push(item);
              job_id = item?.job?.id;
              assoc = item;
            }
          }
        }
      })
    );
    setJobList(jobs);
    if (jobs.length === 1) {
      onJobSelect({
        id: Number(job_id),
        type: 'interview',
        assoc_id: assoc?.id,
        reject: assoc?.sub?.target,
      });
    } else {
      onOpenJobList();
    }
  };
  const onJobSelect = (data: jobselectInt) => {
    setAssocId(data.assoc_id);
    if (data.type === 'interview') {
      reqGetJob({ id: data.id }).then((res: any) => {
        setJobId(data.id);
        const job = res?.data?.data;
        if (data.type === 'interview') {
          const client_name = job?.data?.client ? job?.data?.client?.name : '';
          const subject =
            'Video Interview' +
            ' | ' +
            job?.data?.title +
            ' | ' +
            candidateData?.first_name +
            ' ' +
            candidateData?.last_name +
            '/' +
            client_name;

          setSubject(subject);
          let contactOption: any = [];
          contactOption = [
            {
              label: [
                job?.data?.lead?.first_name,
                job?.data?.lead?.last_name,
              ].join(' '),
              value: job?.data?.lead?.id,
            },
          ];
          setContactOptionValue(contactOption);
          onOpen();
        }
      });
    }
  };
  const {
    isOpen: isOpenJobList,
    onClose: onCloseJobList,
    onOpen: onOpenJobList,
  } = useDisclosure({ defaultIsOpen: false });

  const pageChange = async (page: number) => {
    let newParam = {
      page: page,
      take: candidateInterviewPgBtn.take,
      query: candidateInterviewPgBtn.query,
    };
    dispatch(
      listCandidateInterview({
        candidateInterviewPgBtn: newParam,
      })
    );
    const id = params?.candidatesId || params?.jobsTabNav;
    reqList({ data: newParam, id: id });
  };

  const entryChange = (entries: number) => {
    let newParam = {
      page: 1,
      take: entries,
      query: candidateInterviewPgBtn.query,
    };
    dispatch(
      listCandidateInterview({
        candidateInterviewPgBtn: newParam,
      })
    );
    const id = params?.candidatesId || params?.jobsTabNav;
    reqList({ data: newParam, id: id });
  };

  return (
    <Box justifyContent="space-between">
      <Box textAlign="right" mb="24px">
        <Button leftIcon="plus" variant="solid" onClick={scheduleInterview}>
          Schedule Interview
        </Button>
      </Box>
      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        minHeight="70vh"
      >
        <Table>
          <Thead>
            <Tr bg="default.white.800">
              {tableHeader.map((title) => (
                <Th key={title.label}>
                  <Box color="default.gray.600">{title.label}</Box>
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {resList.isLoading ? (
              <TrLoading columns={6} rows={3} />
            ) : (
              candidateInterviews?.length > 0 &&
              candidateInterviews.map((interview: any, key: number) => {
                return (
                  <Tr cursor="pointer" key={`interview-${key}`}>
                    <Td>
                      <Box fontSize="16px" color="default.primarytext">
                        {interview?.subject}
                      </Box>
                      <Box fontSize="14px" color="default.secondarytext">
                        {interview?.meeting_type}
                      </Box>
                    </Td>
                    <Td>
                      <Box fontSize="16px" color="default.primarytext">
                        <Link href={`/jobs/${interview?.job_id}/details`}>
                          {interview.job?.title}
                        </Link>
                      </Box>
                      <Box fontSize="14px" color="default.secondarytext">
                        {interview.job?.serial_number}
                      </Box>
                    </Td>
                    <Td>
                      {interview?.status == 1 ? (
                        <Tag variant="outline" colorScheme="green">
                          Open
                        </Tag>
                      ) : (
                        <Tag variant="outline" colorScheme="red">
                          Closed
                        </Tag>
                      )}
                    </Td>
                    <Td>
                      <Box fontSize="16px" color="default.primarytext">
                        {moment
                          .utc(interview?.meeting_date)
                          .format('MM/DD/YYYY')}
                      </Box>
                      <Box fontSize="14px" color="default.secondarytext">
                        {moment
                          .utc(interview?.meeting_start, 'HH:mm:ss')
                          .format('hh:mm A')}
                      </Box>
                    </Td>
                    <Td>
                      <Box fontSize="16px" color="default.primarytext">
                        {moment
                          .utc(interview?.meeting_date)
                          .format('MM/DD/YYYY')}
                      </Box>
                      <Box fontSize="14px" color="default.secondarytext">
                        {moment
                          .utc(interview?.meeting_end, 'HH:mm:ss')
                          .format('hh:mm A')}
                      </Box>
                    </Td>
                    <Td>
                      <Grid templateColumns="repeat(1, 1fr)" gap={2}>
                        <Wrap spacing={1}>
                          {interview?.panels?.length > 0 &&
                            interview?.panels.map((panel: any, pk: number) => {
                              return (
                                <WrapItem>
                                  <Tag variant="outline">{panel.email}</Tag>
                                </WrapItem>
                              );
                            })}
                        </Wrap>
                      </Grid>
                    </Td>
                    <Td>
                      {interview?.teams_meating_url && (
                        <Flex alignItems="center">
                          <Link
                            href={interview?.teams_meating_url}
                            target="_blank"
                            style={{ display: 'flex', gap: '7px' }}
                            rel="noreferrer"
                          >
                            <FAIcon iconName="link" />
                            {interview?.meeting_type}
                          </Link>
                        </Flex>
                      )}
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <Pagination
        totalPages={candidateInterviewPage.lastPage}
        currentPage={candidateInterviewPage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={candidateInterviewPage.count}
        currentCount={candidateInterviews?.length}
        targetCount={candidateInterviewPgBtn.take}
      />
      {isOpenJobList && (
        <CandidateJobList
          jobs={jobList}
          isOpen={isOpenJobList}
          onClose={onCloseJobList}
          onSelect={(e: any) => onJobSelect(e)}
          type={actionType}
        />
      )}
      {isOpen && (
        <CandidatesInterviewModal
          associate={assocId}
          isJobView={true}
          jobsId={jobId}
          isOpen={isOpen}
          onClose={onClose}
          subject={subject}
          contactOptionValue={contactOptionValue}
          callback={() => {
            callBackAction();
          }}
        />
      )}
    </Box>
  );
};

export default CandidatesInterviews;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import * as Yup from 'yup';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useGetLeadsMutation } from 'store/leads.slice';
import {
  useCreateAgreementMutation,
  useListLeadAgreementMutation,
} from 'store/leadagreement.sclice';
import { cleanUpload, uploadList } from 'store/uploads.slice';

import AtsDropZone from '../../Dropzone';
import ScrollToFieldError from 'components/app/ScrollError';
import { useParams } from 'react-router';

interface LeadsAgrrmentModalProps {
  isOpen: any;
  onClose: any;
}

export default function LeadsAddAgreementModal({
  isOpen,
  onClose,
}: LeadsAgrrmentModalProps) {
  const dispatch = useDispatch();
  const { leadData } = useSelector((state: any) => state.leads);
  const { uploaded, uploading } = useSelector((state: any) => state.uploads);
  const { leadAgreementPgBtn } = useSelector(
    (state: any) => state.leadagreement
  );

  const [reqcreate, rescreate] = useCreateAgreementMutation();
  const [reqleads] = useGetLeadsMutation();
  const [reqAgreement] = useListLeadAgreementMutation();
  const initialValues = {
    title: '',
    content: '',
    date_signed: '',
    valid_until: '',
  };

  const params = useParams();
  const leadID = params.leadsId;

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      title: Yup.string().required('Agreement name is required.'),
      content: Yup.string(),
      date_signed: Yup.string().required('Date signed is required.'),
      valid_until: Yup.string().required('Valid until is required.'),
    })
  );

  const onSubmit = async (data: any) => {
    data['attachments'] = uploaded;
    await reqcreate({ data, id: leadData.id });
  };

  useEffect(() => {
    dispatch(cleanUpload({ withPrefix: false }));
    dispatch(uploadList({ prefix: 'Attachment' }));
    if (rescreate.isSuccess) {
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      reqAgreement({ data: leadAgreementPgBtn, id: leadID });
      onClose();
    }
  }, [
    dispatch,
    leadAgreementPgBtn,
    leadData.id,
    leadID,
    onClose,
    reqAgreement,
    reqleads,
    rescreate.isSuccess,
  ]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="3xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Add Agreement
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          <ModalBody borderRadius="0 0 4px 4px" p={0}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />
                  <Box p="32px">
                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(!!errors.title && touched.title)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Agreement Name{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          placeholder="Agreement Name"
                          variant="outline"
                          value={values.title}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.title)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(!!errors.content && touched.content)}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Description
                        </FormLabel>
                        <Input
                          id="content"
                          name="content"
                          type="textarea"
                          placeholder="Description"
                          variant="outline"
                          value={values.content}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.content)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Box mb="34px">
                      <FormControl mb="3">
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Attachments{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                      </FormControl>
                    </Box>
                    <AtsDropZone
                      multipleFile={false}
                      isAgreement
                      uploadedEnd={(e: any) => {}}
                      deletedFile={(key: string) => {}}
                    />

                    <Flex gap="32px" mb="34px">
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.date_signed && touched.date_signed
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Signed On{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="date_signed"
                          name="date_signed"
                          placeholder="Select Date"
                          size="md"
                          type="date"
                          value={values.date_signed}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.date_signed)}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl
                        isInvalid={Boolean(
                          !!errors.valid_until && touched.valid_until
                        )}
                      >
                        <FormLabel
                          fontSize="14px"
                          lineHeight="18px"
                          color="default.secondarytext"
                        >
                          Valid Until{' '}
                          <Box as="span" color="caution.800">
                            *
                          </Box>
                        </FormLabel>
                        <Input
                          id="valid_until"
                          name="valid_until"
                          placeholder="Select Date"
                          size="md"
                          type="date"
                          value={values.valid_until}
                          onChange={handleChange}
                        />
                        <FormErrorMessage>
                          {String(errors.valid_until)}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  </Box>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Button variant="solid" type="submit" disabled={uploading}>
                      Add
                    </Button>
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

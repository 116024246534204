import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import FAIcon from '../lib/FAIcon';
import Bell from '../../assets/images/bell.svg';
import Logo from '../../assets/images/logo.svg';
import authService from '../../services/auth.service';
import { useEffect, useState } from 'react';
import { useGetParseListMutation } from 'store/resumerparse.slice';
import { SOCKET_URL } from 'constants/values';
import { authStorage } from 'utils/localStorage';
import {
  getUser,
  useUserListMutation,
  useUserListPaginateMutation,
} from 'store/user.slice';
import { navClient } from 'store/client.slice';
import {
  useGetCandidateSourceMutation,
  useGetEducationsMutation,
  useGetJobStatusMutation,
  useGetLeadSourceMutation,
  useGetMailBoxConfigMutation,
} from 'store/constant.slice';
import { useViewInterviewBodyMutation } from 'store/candidateinterview.slice';
import { useGetDefaultPitchMutation } from 'store/jobs.slice';

interface NavItem {
  id: number;
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  to?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    id: 1,
    label: 'Dashboard',
    to: '/dashboard',
  },
  {
    id: 2,
    label: 'Candidates',
    to: '/candidates',
  },
  {
    id: 3,
    label: 'Jobs',
    to: '/jobs',
  },

  {
    id: 4,
    label: 'Clients',
    to: '/clients',
  },
  {
    id: 5,
    label: 'Leads',
    to: '/leads',
  },
  {
    id: 6,
    label: 'Approvals',
    to: '/approvals',
  },
  {
    id: 7,
    label: 'Settings',
    to: '/settings',
  },
];

interface AppNavBarProps {
  handleOpenParseQueue: () => void;
  handleOpenFooterNotif: () => void;
}

export default function LoginAppNavBar({
  handleOpenParseQueue,
  handleOpenFooterNotif,
}: AppNavBarProps) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();
  const { UserPgBtn } = useSelector((state: any) => state.user);
  const { auth } = useSelector((state: any) => state);

  // const auth = authStorage()
  const isActiveLink = location.pathname.split('/')[1];
  const [reqMailBox, resMailbox] = useGetMailBoxConfigMutation();
  const [reqEduc, resEduc] = useGetEducationsMutation();
  const [reqJs, resJs] = useGetJobStatusMutation();
  const [reqGetInterviewBody] = useViewInterviewBodyMutation();
  const [reqLeadSource] = useGetLeadSourceMutation();
  const [reqPitch, resPitch] = useGetDefaultPitchMutation();
  const [reqCandidateStat] = useGetCandidateSourceMutation();

  const handleLogout = async (data: any) => {
    authService.logout();
  };

  const handleClickProfile = () => {
    // console.log("user_id", auth?.id)
    // console.log("went to handleClickProfile")
    dispatch(
      getUser({
        currentUser: {
          id: auth?.id,
          name: [auth?.first_name, auth?.last_name].join(' '),
          email: auth?.email,
          profile: auth?.signedUrl,
        },
      })
    );
  };

  const [reqBatch, resBatch] = useGetParseListMutation();
  const [reqUser] = useUserListPaginateMutation();
  const [reqUsers, resUsers] = useUserListMutation();

  const [totalProcessing, setTotalProcessing] = useState(0);
  const authSt = authStorage();
  const { batches } = useSelector((state: any) => state.resumeParse);

  useEffect(() => {
    const constants = async () => {
      await reqMailBox({});
      await reqEduc({});
      await reqJs({});
      await reqGetInterviewBody({});
      await reqLeadSource({});
      await reqPitch({});
      await reqCandidateStat({});
      await reqUsers({});
    };
    constants();
  }, []);

  useEffect(() => {
    if (batches.length > 0) {
      let total = 0;
      Promise.all(
        batches.map(async (item: any) => {
          if (!item.is_parse) {
            total++;
          }
        })
      );
      setTotalProcessing(total);
    } else {
      setTotalProcessing(0);
    }
  }, [resBatch.isSuccess, batches]);
  let wsScoket: any = null;

  useEffect(() => {
    // Event handler for WebSocket connection established
    const W3CWebSocket = require('websocket').w3cwebsocket;
    // console.log('SOCKET_URL', SOCKET_URL);
    if (authSt) {
      wsScoket = new W3CWebSocket(SOCKET_URL);

      wsScoket.addEventListener('open', (event: any) => {
        console.log('Connected to WebSocket server');
        if (wsScoket?.readyState) {
          wsScoket.send('Hello server!');
          console.log('socket ready');

          // Start sending heartbeat messages every 30 seconds to keep alive
          // setInterval(() => {
          //   wsScoket.send('heartbeat');
          // }, 30000);
        }
      });
      //test commit
      // Event handler for WebSocket messages received
      wsScoket.addEventListener('message', (event: any) => {
        const eventListener = event.data;
        console.log('eventListener', eventListener);
        if (eventListener === 'resumeParse') {
          console.log('resumerparser');
          reqBatch({});
        }
        if (eventListener === 'createThumbnail') {
          console.log('thumbnails');
          reqUser(UserPgBtn);
        }
        if (eventListener === 'filterCountRefresh') {
          // reqList({});
        }
      });

      // Event handler for WebSocket connection closed
      wsScoket.addEventListener('close', () => {
        console.log('WebSocket connection closed');
        // handleOpenFooterNotif();
      });
    }
  }, [wsScoket?.readyState, wsScoket]);

  return (
    <Box position="fixed" zIndex="999" width="100vw">
      <Flex
        bg={useColorModeValue('white', 'gray.800')}
        color={useColorModeValue('gray.600', 'white')}
        minH="60px"
        py={0}
        px={{ base: 8 }}
        borderBottom={1}
        borderStyle="solid"
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align="center"
      >
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Image src={Logo} alt="logo" />

          <Flex
            display={{ base: 'none', md: 'flex' }}
            my="10px"
            ml={10}
            alignItems="center"
            py="0px"
          >
            <Stack direction="row">
              {NAV_ITEMS.map((navItem) => {
                const activeLink = isActiveLink === navItem.to.split('/')[1];
                return (
                  <Box
                    key={navItem.label}
                    onClick={() =>
                      navItem.to.split('/')[1] === 'clients' &&
                      dispatch(navClient({ clientNav: 'my-clients' }))
                    }
                  >
                    <Link to={navItem.to ?? '#'}>
                      <Box
                        px="18px"
                        py="13px"
                        fontSize="14px"
                        fontWeight="bold"
                        _hover={{
                          backgroundColor: 'primary.100',
                          borderRadius: '3px',
                          textDecoration: 'none',
                          color: 'primary.800',
                          fontWeight: 'bold',
                        }}
                        backgroundColor={activeLink && 'primary.100'}
                        borderRadius={activeLink && '3px'}
                        textDecoration={activeLink && 'none'}
                        color={
                          activeLink ? 'primary.800' : 'default.secondarytext'
                        }
                      >
                        {navItem.label}
                      </Box>
                    </Link>
                  </Box>
                );
              })}
            </Stack>
          </Flex>
        </Flex>

        <Stack justify="flex-end" direction="row">
          <Box cursor="pointer" onClick={handleOpenParseQueue} display="flex">
            <Flex
              color="primary.800"
              fontSize="20px"
              mt="auto"
              mb="auto"
              verticalAlign="center"
            >
              <FAIcon iconName="hourglass-start" />
            </Flex>
            <Text
              color="primary.800"
              fontSize="18px"
              fontWeight="bold"
              ml="2px"
            >
              {totalProcessing}
            </Text>
          </Box>

          <Image src={Bell} alt="bell" />
          <Box cursor="pointer" alignItems="center" onClick={onToggle}>
            <Menu closeOnSelect={false}>
              <MenuButton>
                <Flex>
                  <Box>
                    <Text fontSize="14px" fontWeight={600}>
                      {auth && [auth.first_name, auth.last_name].join(' ')}
                    </Text>
                    <Text fontSize="12px" fontWeight={400}></Text>
                  </Box>
                  <Icon
                    as={ChevronDownIcon}
                    transition="all .25s ease-in-out"
                    transform={isOpen ? 'rotate(180deg)' : ''}
                    w={6}
                    h={6}
                    ml="8px"
                    color="#9B9B9B"
                  />
                </Flex>
              </MenuButton>

              <MenuList>
                <Box px={6} py={4}>
                  <Box fontSize="md" color="primary.800" py={4}>
                    <Link
                      onClick={handleClickProfile}
                      to={`/profile/${auth?.id}`}
                    >
                      My Profile
                    </Link>
                  </Box>
                  <Box fontSize="md" color="primary.800">
                    <Link onClick={handleLogout} to="/login">
                      Logout
                    </Link>
                  </Box>
                </Box>
              </MenuList>
            </Menu>
          </Box>
        </Stack>
      </Flex>
    </Box>
  );
}

import { Box, Flex } from '@chakra-ui/react';
import CandidatesAll from './All';
import CandidatesFilterBar from './VerticalFilterBar';

export default function CandidatesPage() {
  document.documentElement.dir = 'ltr';

  return (
    <Flex mt="-44px" fontFamily="Proxima Nova Regular">
      <CandidatesFilterBar />
      <Box w="80%" background="default.white.800">
        <CandidatesAll />
      </Box>
    </Flex>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import authService from 'services/auth.service';
import { apiSlice } from './api.slice';
import { appError } from './app.slice';

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: {
    jobs: [],
    openJobs: [],
    jobData: {},
    fullData: [],
    screens: [],
    followUpCandidates: [],
    byContactCandidates: [],
    selectedCandidates: [],
    jobPage: {
      count: 0,
      currentPage: 1,
      lastPage: 1,
      nextPage: null,
      prevPage: null,
    },
    jobRoute: '',
    jobPgBtn: {
      page: 1,
      take: 50,
      query: '',
      closed: false,
      open: true, //set to default true
      quickJobs: true, //set to default true
      job_assigned: true, //set to default true
      job_ads: false,
      draft: true, //set to default true
      full: false,
      all: false,
    },
    jobCareersPgBtn: {
      take: 50,
      page: 1,
      work_type_id: null, //to make it remote make value to 1
      query: '',
      specification_id: null,
    },
    defaultPitch: {
      email_content: '',
      email_subject: '',
    },
    hiredFormData: {}
  },
  reducers: {
    listJobs: (state, { payload }) => ({ ...state, ...payload }),
    getJob: (state, { payload }) => ({ ...state, ...payload }),
    getPitch: (state, { payload }) => ({ ...state, ...payload }),
  },
});

export const { listJobs, getJob, getPitch } = jobsSlice.actions;
export default jobsSlice.reducer;

/* ----------------------------------- API ---------------------------------- */
export const jobsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    ListJobs: builder.mutation({
      query: (data) => ({
        url: '/jobs',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listJobs({ jobs: data.data.data }));
          dispatch(
            listJobs({
              jobPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
          const err: any = error;
          // if (err?.status == 403) {
          //   authService.logout();
          // }
        }
      },
    }),
    JobTitleSearch: builder.mutation({
      query: (data) => ({
        url: '/jobs/title-search',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listJobs({ jobs: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
          const err: any = error;
          // if (err?.status == 403) {
          //   authService.logout();
          // }
        }
      },
    }),
    OpenJobs: builder.mutation({
      query: (data) => ({
        url: '/jobs/open',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listJobs({ openJobs: data.data }));
        } catch ({ error }) {
          const err: any = error;
          // if (err?.status == 403) {
          //   authService.logout();
          // }
          dispatch(appError(error));
        }
      },
    }),
    CreateJobs: builder.mutation({
      query: (data) => ({
        url: '/jobs/create',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateJobs: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getJob({ jobData: { ...data.data, ...data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CreateQuick: builder.mutation({
      query: (data) => ({
        url: '/jobs/quick',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateQuick: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs/update-quick/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getJob({ jobData: { ...data.data, ...data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetJobs: builder.mutation({
      query: ({ id }) => ({
        url: `/jobs/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getJob({ jobData: { ...data.data, ...data.data.data } }));
          dispatch(getJob({ fullData: { ...data.data, ...data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DeleteJobs: builder.mutation({
      query: ({ id }) => ({
        url: `/jobs/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    BulkDeleteJobs: builder.mutation({
      query: (data) => ({
        url: `/jobs/bulk-delete`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CloseJobs: builder.mutation({
      query: ({ id }) => ({
        url: `/jobs/close/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    GetDefaultPitch: builder.mutation({
      query: ({ }) => ({
        url: `/pitch/default`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            getPitch({ defaultPitch: { ...data.data, ...data.data.data } })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CreateQuestionJobs: builder.mutation({
      query: ({ data, id }) => ({
        url: `/screen-question/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          // const { data } = await queryFulfilled;
          // dispatch(getJob({ jobData: { ...data.data, questions: data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateQuestionJobs: builder.mutation({
      query: ({ data, id }) => ({
        url: `/screen-question/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          // const { data } = await queryFulfilled;
          // dispatch(getJob({ jobData: { ...data.data, questions: data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DeleteQuestionJobs: builder.mutation({
      query: ({ id }) => ({
        url: `/screen-question/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          // const { data } = await queryFulfilled;
          // dispatch(getJob({ jobData: { ...data.data, questions: data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListQuestionJobs: builder.mutation({
      query: ({ data, id }) => ({
        url: `/screen-question/job/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getJob({ screens: { ...data.data, questions: data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CreateJobAds: builder.mutation({
      query: ({ data, id }) => ({
        url: `/job-ads/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListClientJobs: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs/client/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listJobs({ jobs: data.data.data }));
          dispatch(
            listJobs({
              jobPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListLeadJobs: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs/lead/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listJobs({ jobs: data.data.data }));
          dispatch(
            listJobs({
              jobPage: {
                count: data.data.count,
                currentPage: data.data.currentPage,
                lastPage: data.data.lastPage,
                nextPage: data.data.nextPage,
                prevPage: data.data.nextPage,
              },
            })
          );
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateJobAds: builder.mutation({
      query: ({ data, id }) => ({
        url: `/job-ads/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DeleteJobAds: builder.mutation({
      query: ({ id }) => ({
        url: `/job-ads/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    toggleStatus: builder.mutation({
      query: ({ id }) => ({
        url: `/jobs/active-toggle/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    toggleCampaign: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs/campaign-toggle/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CreateRecruiters: builder.mutation({
      query: ({ data, id }) => ({
        url: `/jobs/recruiters/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    UpdateRecruiters: builder.mutation({
      query: ({ data, id }) => ({
        url: `/recruiters/update/${id}`,
        method: 'PUT',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    DeleteRecruiters: builder.mutation({
      query: ({ data, id }) => ({
        url: `/recruiters/delete/${id}`,
        method: 'DELETE',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    toggleAdsStatus: builder.mutation({
      query: ({ id }) => ({
        url: `/job-ads/active-toggle/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    SubmitFile: builder.mutation({
      query: (data) => ({
        url: '/careers/resume/upload',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    SubmitApplication: builder.mutation({
      query: (data) => ({
        url: '/careers/submit',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListCareers: builder.mutation({
      query: (data) => ({
        url: '/careers/ads',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listJobs({ jobs: data.data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListByContactCandidates: builder.mutation({
      query: ({ id, data }) => ({
        url: `jobs/by-contact/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listJobs({ byContactCandidates: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ListFollowUpCandidates: builder.mutation({
      query: (data) => ({
        url: 'job-follow-up/candidates',
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(listJobs({ followUpCandidates: data.data }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    PostFollowUpCandidates: builder.mutation({
      query: ({ id, data }) => ({
        url: `/job-follow-up/create/${id}`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    CreateJobPostPlacement: builder.mutation({
      query: (data) => ({
        url: `/job-placement/create`,
        method: 'POST',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: data,
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
    ViewJobPostPlacement: builder.mutation({
      query: (id) => ({
        url: `/job-placement/view/${id}`,
        method: 'GET',
        credentials: 'include',
        withCredentials: true,
        crossorigin: true,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }),
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(getJob({ hiredFormData: { ...data.data, ...data.data.data } }));
        } catch ({ error }) {
          dispatch(appError(error));
        }
      },
    }),
  }),
});

export const {
  useListJobsMutation,
  useCreateJobsMutation,
  useUpdateJobsMutation,
  useCreateQuickMutation,
  useUpdateQuickMutation,
  useGetJobsMutation,
  useDeleteJobsMutation,
  useCreateQuestionJobsMutation,
  useUpdateQuestionJobsMutation,
  useDeleteQuestionJobsMutation,
  useCreateJobAdsMutation,
  useListClientJobsMutation,
  useUpdateJobAdsMutation,
  useDeleteJobAdsMutation,
  useToggleStatusMutation,
  useToggleCampaignMutation,
  useCreateRecruitersMutation,
  useUpdateRecruitersMutation,
  useDeleteRecruitersMutation,
  useToggleAdsStatusMutation,
  useListQuestionJobsMutation,
  useGetDefaultPitchMutation,
  useListLeadJobsMutation,
  useSubmitFileMutation,
  useSubmitApplicationMutation,
  useListCareersMutation,
  useOpenJobsMutation,
  useJobTitleSearchMutation,
  useCloseJobsMutation,
  useBulkDeleteJobsMutation,
  useListByContactCandidatesMutation,
  useListFollowUpCandidatesMutation,
  usePostFollowUpCandidatesMutation,
  useCreateJobPostPlacementMutation,
  useViewJobPostPlacementMutation,
} = jobsApiSlice;

import { Field, Formik } from 'formik';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Button as ChakraButton,
  Box,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import Select from 'react-select';

import ScrollToFieldError from 'components/app/ScrollError';
import 'react-quill/dist/quill.snow.css';
import 'assets/css/minHquill.css';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AtsDropZone from 'components/app/Dropzone';
import Button from 'Library/Button';
import {
  useGetCandidateWorkStatusMutation,
  useGetEducationsMutation,
} from 'store/constant.slice';
import { useParams } from 'react-router';
import {
  useGetJobsMutation,
  useListQuestionJobsMutation,
} from 'store/jobs.slice';
import {
  useListCandidatesMutation,
  useSubmitToClientMutation,
} from 'store/candidates.slice';
import AtsCraetableSelect from 'components/app/AtsCreatabeSelect';
import { cleanUpload, uploadList } from 'store/uploads.slice';
import {
  usePlaceHolderPairMutation,
  useRawListTemplateMutation,
  setPairTemplate,
} from 'store/template.slice';
import { CAREER_PAGE_URL } from 'constants/values';
import replacePlaceholders from 'utils/textPlaceholders';
import * as Yup from 'yup';
import AtsEmailBody from 'components/app/EmailBody';
import AtsSelect from 'components/app/AtsSelect';
import StreamAttachmentComponent from 'components/app/Candidates/stream';
import FAIcon from 'components/lib/FAIcon';
import { useListResumeCandidateMutation } from 'store/candidateresume.slice';
import EmailPreviewModal from '../../Modal/followup/preview';
import AddressComponent from '../../components/AddressComponent';
import NotesModal from 'components/app/Candidates/Modals/Notes';

interface SubmitToClientModalProps {
  isOpen: any;
  onClose: any;
  candidate: any;
  job_id?: number;
  callback: () => void;
}

interface CandidatePairingIntParam {
  candidate_full_name: string;
  candidate_first_name: string;
  candidate_email: string;
  candidate_current_position: string;
  candidate_current_company: string;
  candidate_mobile: string;
  candidate_linkedin_url: string;
  candidate_work_status: string;
  candidate_city: string;
  candidate_state: string;
  candidate_degree: string;
  candidate_total_years_of_exp: string;
  job_url: string;
  job_name_url: string;
  job_title: string;
  job_location: string;
  job_contact_name: string;
  job_contact_first_name: string;
  job_company_name: string;
  job_primary_recruiter_name: string;
}

export default function SubmitToClientModal({
  isOpen,
  onClose,
  candidate,
  job_id = null,
  callback,
}: SubmitToClientModalProps) {
  const params = useParams();
  const [reqCanWorkStat] = useGetCandidateWorkStatusMutation();
  const [reqEducation, resEducation] = useGetEducationsMutation();
  const [reqQuestion] = useListQuestionJobsMutation();
  const [reqSubmit, resSubmit] = useSubmitToClientMutation();
  const [reqCandidates] = useListCandidatesMutation();
  const { candidatePgBtn, candidateData } = useSelector(
    (state: any) => state.candidates
  );
  const [reqList, resList] = useRawListTemplateMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [tokenLink, setTokenLink] = useState('');
  const [reqGetJob] = useGetJobsMutation();

  const { jobData } = useSelector((state: any) => state.jobs);
  const { userList } = useSelector((state: any) => state.user);

  const { uploading, uploaded } = useSelector((state: any) => state.uploads);
  const [quesetions] = useState([]);
  const [template, setTemplate] = useState([]);
  const [candidateDegree, setCandidateDegree] = useState([]);
  const [candidateDegreeList, setCandidateDegreeList] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [ccBcc, setCcBcc] = useState([]);
  const [ccOption, setCcOption] = useState([]);
  const [bccOption, setBccOption] = useState([]);

  const getLatestEduc = (candidate: any) => {
    try {
      if (candidate.educations) {
        if (candidate.educations.length > 0) {
          return {
            label: candidate.educations[0].degree_level?.name,
            value: candidate.educations[0].id,
          };
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  };

  const [formPairing, setFormPairing] = useState({
    education:
      getLatestEduc(candidate)?.label ||
      getLatestEduc(candidateData)?.label ||
      '',
    total_year_of_experience: candidate?.total_year_of_experience || 0,
    total_months_of_experience: candidate?.total_months_of_experience || 0,
    work_status: candidate?.work_status?.status || '',
    linked_in_url: candidate?.linkedin_url,
    city: candidate?.city || '',
    state_province: candidate?.state_province || '',
    zip_code: candidate?.zip_code || '',
  });

  const { candidate_work_status, educations } = useSelector(
    (state: any) => state.constants
  );

  const [errMsg, setErrorMsg] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const [dataCc, setDataCc] = useState([]);
  const [dataBcc, setDataBcc] = useState([]);

  useEffect(() => {
    if (jobData?.bcc?.cc) {
      setDataCc(ccBc(jobData.bcc.cc));
    }
    if (jobData?.bcc?.bcc) {
      setDataBcc(ccBc(jobData.bcc.bcc));
    }
  }, [jobData]);

  const ccBc = (email: any) => {
    let options: any = [];
    try {
      email.map((item: any) => {
        if (item !== '' && item !== null && item !== undefined) {
          options.push({
            label: item,
            value: item,
          });
        }
      });
    } catch (e) {
      console.warn('e', e);
    }
    return options;
  };

  const { placehoderPair } = useSelector((state: any) => state.template);
  const [placehoder, setPlaceHoders] = useState(placehoderPair);
  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen: openAlert,
  } = useDisclosure({ defaultIsOpen: false });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      const educ: any = getLatestEduc(candidate);
      setCandidateDegree(educ);
      // const list: any = educOption(candidate);
      let options: any = [];
      educations?.map((item: any) => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      // setDegree(options);
      // setCandidateDegreeList(list);
      setCandidateDegreeList(options);
      let ccOption = [] as any;
      Object.values(userList).map((item: any) => {
        ccOption.push({
          label: [item.first_name, item.last_name].filter(Boolean).join(' '),
          value: item.email,
        });
      });
      setCcBcc(ccOption);
    }
  }, [isOpen]);

  const pairing = async () => {
    const newpair = {
      ...placehoderPair,
      candidate_linkedin_url: formPairing.linked_in_url,
      candidate_state: formPairing.state_province,
      candidate_total_years_of_exp: Number(
        formPairing.total_year_of_experience
      ),
      candidate_work_status: formPairing.work_status,
      candidate_degree: formPairing.education,
      candidate_city: formPairing.city,
      candidate_zip_code: formPairing.zip_code,
    };
    await dispatch(setPairTemplate({ placehoderPair: newpair }));
  };

  useMemo(() => {
    pairing();
  }, [formPairing]);

  const pairPH = (holder: string, value: string) => {
    let ph: any = { ...formPairing };
    ph[holder] = value;
    setFormPairing((pairing) => ({ ...ph }));
  };

  const educOption = (candidate: any) => {
    try {
      let options: any = [];
      if (candidate.educations) {
        if (candidate.educations.length > 0) {
          candidate.educations.map((item: any) => {
            options.push({
              label: item?.degree_level?.name,
              value: item.id,
            });
          });
        }
      }
      return options;
    } catch (e) {
      console.log('e', e);
      return [];
    }
  };

  useEffect(() => {
    reqCanWorkStat({});
    reqEducation({});
    reqList({ id: 2 });
    const data = {
      candidate_id: candidate.id,
      job_id: params.jobsId || job_id,
    };
    reqPair(data);
    reqQuestion({ id: params.jobsId || job_id });
    setTokenLink('');
  }, [params.jobsId, job_id]);

  useEffect(() => {
    if (resList.isSuccess) {
      if (resList?.data?.data?.length > 0) {
        let option: any = [];
        resList.data.data.map((item: any) => {
          option.push({
            value: item.id,
            label: item.title,
            subject: item.subject,
            body: item.body,
            attachments: item.attachments,
          });
        });
        setTemplate(option);
      }
    }
  }, [resList.isSuccess]);

  useEffect(() => {
    if (resSubmit.isError) {
      const error: any = resSubmit.error;
      // console.log('error', error);
      try {
        const message =
          error?.data?.error?.message ||
          error?.data?.data?.message ||
          'Unkown error';

        // console.log('message', message);
        if (error?.data?.data?.type == 'redirect') {
          setTokenLink(error?.data?.data?.url);
        }
        setErrorMsg(message);
        openAlert();
      } catch (e) {
        console.log('error', e);
      }
    } else {
      setErrorMsg('');
    }
  }, [resSubmit.isError]);

  useEffect(() => {
    if (resSubmit.isSuccess) {
      callback();
      params?.jobsId && reqGetJob({ id: params?.jobsId });
      reqCandidates(candidatePgBtn);
      dispatch(uploadList({ prefix: 'Attachment' }));
      dispatch(cleanUpload({ attachments: [] }));
      dispatch(cleanUpload({ uploaded: [] }));
      onClose();
      setTokenLink('');
    }
  }, [resSubmit.isSuccess]);

  const initialValues = {
    job_title: candidate?.latest_job_title,
    company: candidate?.latest_employer,
    city: candidate?.city,
    state_province: candidate?.state_province,
    zip_code: candidate?.zip_code,
    education: getLatestEduc(candidate) || getLatestEduc(candidateData),
    // education: candidateDegree,
    total_year_of_experience: candidate?.total_year_of_experience,
    total_months_of_experience: candidate?.total_months_of_experience,
    work_status: candidate?.work_status_id || candidate?.work_status?.id,
    resume: '',
    linked_in_url: candidate?.linkedin_url || '',
    isSubmitQA: 2,
    to: jobData?.lead?.primary_email,
    cc: dataCc,
    bcc: dataBcc,
    subject: '',
    notes: '',
    attachment: [] as any,
    questions: quesetions,
    lead_id: jobData?.lead_id,
    candidate_id: candidate?.id,
    body: '',
    primary: true,
  };

  // console.log('initialValues', initialValues);

  const [quilNotes, setquilNotes] = useState('');
  const [tabIndex, setTabIndex] = useState(1);

  const onSubmit = (data: any) => {
    let dataAtt: any[] = [];

    data['attachment'] = uploaded;

    if (attachments?.length > 0) {
      attachments.map((att: any) => {
        dataAtt.push({
          file_name: att?.file_name,
          file_size: att?.file_size,
          file_type: att?.file_type,
          location: att?.location,
          original_file_name: att?.original_file_name,
          bucket: att?.bucket || 'talently-outreach',
        });
      });
    }
    data['templat_att'] = attachments;

    setTokenLink('');
    let cc: any = [];
    if (data.cc.length > 0) {
      data.cc.map((item: any) => {
        // console.log(item.value);
        cc.push(item.value);
      });
    }
    let bcc: any = [];
    if (data.bcc.length > 0) {
      data.bcc.map((item: any) => {
        // console.log(item.value);
        bcc.push(item.value);
      });
    }
    data.cc = cc;
    data.bcc = bcc;
    data.body = quilNotes;

    if (quilNotes === '') {
      setErrorMsg('Email body is required');
      openAlert();
    } else {
      // console.log('data', data);
      reqSubmit({
        data,
        id: params.jobsId || job_id || candidateData?.jobs?.[0]?.job_id,
      });
    }
  };

  const candidatePairing = async (param: CandidatePairingIntParam) => {
    const newpair = {
      ...placehoderPair,
      candidate_full_name: param.candidate_full_name,
      candidate_first_name: param.candidate_first_name,
      candidate_email: param.candidate_email,
      candidate_current_position: param.candidate_current_position,
      candidate_current_company: param.candidate_current_company,
      candidate_mobile: param.candidate_mobile,
      candidate_linkedin_url: param.candidate_linkedin_url,
      candidate_work_status: param.candidate_work_status,
      candidate_city: param.candidate_city,
      candidate_state: param.candidate_state,
      candidate_degree: param.candidate_degree,
      candidate_total_years_of_exp: param.candidate_total_years_of_exp,
      job_url: param.job_url,
      job_name_url: param.job_name_url,
      job_title: param.job_title,
      job_location: param.job_location,
      job_contact_name: param.job_contact_name,
      job_contact_first_name: param.job_contact_first_name,
      job_company_name: param.job_company_name,
      job_primary_recruiter_name: param.job_primary_recruiter_name,
    };

    // setPlaceHoders(newpair);
    await dispatch(setPairTemplate({ placehoderPair: newpair }));
  };
  const getPrimaryMobile = (contact: any) => {
    try {
      let mobile = '';

      contact.map((item: any) => {
        if (item.primary && item.type == 'mobile') {
          mobile = item?.number;
        }
      });

      return mobile;
    } catch (e) {
      return '';
    }
  };
  useEffect(() => {
    if (isOpen) {
      if (candidateData) {
        const candidate_full_name = candidateData?.data
          ? [candidateData?.data?.first_name, candidateData?.data?.last_name]
            .filter(Boolean)
            .join(', ')
          : [candidateData?.first_name, candidateData?.last_name]
            .filter(Boolean)
            .join(', ') || '';
        const candidate_first_name =
          candidateData?.data?.first_name ||
          candidateData?.first_name ||
          placehoderPair?.first_name ||
          '';
        const candidate_email =
          candidateData?.data?.primary_email ||
          candidateData?.primary_email ||
          placehoderPair?.candidate_email ||
          '';
        const candidate_current_position =
          candidateData?.data?.latest_job_title ||
          candidateData?.latest_job_title ||
          placehoderPair?.candidate_current_position ||
          '';
        const candidate_current_company =
          candidateData?.data?.latest_employer ||
          candidateData?.latest_employer ||
          placehoderPair?.candidate_current_company ||
          '';
        const candidate_mobile =
          getPrimaryMobile(candidateData?.data?.contact) ||
          placehoderPair?.candidate_mobile ||
          '';
        const candidate_linkedin_url =
          candidateData?.linkedin_url ||
          placehoderPair?.candidate_linkedin_url ||
          '';
        const candidate_work_status =
          candidateData?.work_status?.status ||
          placehoderPair?.candidate_work_status ||
          '';
        const candidate_city =
          candidateData?.city || placehoderPair?.candidate_city || '';
        const candidate_state =
          candidateData?.state_province ||
          placehoderPair?.candidate_state ||
          '';
        let candidate_degree = placehoderPair?.candidate_degree || '';
        if (candidateData?.education?.length > 0) {
          candidate_degree = candidateData?.education?.[0]?.degree_level?.name;
        }
        const candidate_total_years_of_exp =
          candidateData?.total_year_of_experience ||
          placehoderPair?.candidate_total_years_of_exp ||
          '';
        let job_url = '';
        let job_name_url = '';
        let job_title = '';
        let job_location = '';
        let job_contact_name = '';
        let job_contact_first_name = '';
        let job_company_name = '';
        let job_primary_recruiter_name = '';
        if (job_id || candidateData?.jobs?.[0]?.job_id) {
          job_title = jobData?.title || placehoderPair?.job_title || '';
          job_location =
            [
              jobData?.data?.location?.city,
              jobData?.data?.location?.state,
              jobData?.data?.location?.country?.country,
            ]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_location ||
            '';
          job_contact_name =
            [jobData?.lead?.first_name, jobData?.lead?.last_name]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_contact_name ||
            '';
          job_contact_first_name =
            jobData?.lead?.first_name ||
            placehoderPair?.job_contact_first_name ||
            '';
          job_company_name =
            jobData?.client?.name || placehoderPair?.job_company_name || '';
          job_primary_recruiter_name =
            [
              jobData?.primary?.recruiter?.first_name,
              jobData?.primary?.recruiter?.last_name,
            ]
              .filter(Boolean)
              .join(', ') ||
            placehoderPair?.job_primary_recruiter_name ||
            '';
          if (jobData?.jobAds?.length > 0) {
            const ads = jobData?.jobAds[0];

            if (ads) {
              const url = `${CAREER_PAGE_URL}/job-details/${ads?.id}`;
              job_url = `<a href="${url}" target="_blank">${url}</a>`;
              job_name_url = `<a href="${url}" target="_blank">${ads.title}</a>`;
            }
          }
        }

        const candidateParam = {
          candidate_full_name,
          candidate_first_name,
          candidate_email,
          candidate_current_position,
          candidate_current_company,
          candidate_mobile,
          candidate_linkedin_url,
          candidate_work_status,
          candidate_city,
          candidate_state,
          candidate_degree,
          candidate_total_years_of_exp,
          job_url,
          job_name_url,
          job_title,
          job_location,
          job_contact_name,
          job_contact_first_name,
          job_company_name,
          job_primary_recruiter_name,
        } as CandidatePairingIntParam;

        candidatePairing(candidateParam);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    setPlaceHoders(placehoderPair);
  }, [placehoderPair]);

  const placholders = (text: string, values: any) => {
    // console.log('values ', values);
    // console.log('candidate_work_status ', candidate_work_status);
    const work_status = candidate_work_status
      .filter(Boolean)
      .filter((item: any) => Number(item.id) == Number(values.work_status));
    // console.log('work_status', work_status);
    const placeholderParam = {
      ...placehoder,
      candidate_state: values.state_province ?? '',
      candidate_city: values.city ?? '',
      candidate_degree: values.education?.label ?? '',
      candidate_linkedin_url: values.linked_in_url ?? '',
      candidate_current_company: values.company ?? '',
      candidate_current_position: values.job_title ?? '',
      candidate_work_status: work_status?.[0]?.status ?? '',
    };
    // console.log('placeholderParam ', placeholderParam);
    return replacePlaceholders(text, placeholderParam);
  };

  const removeAtt = (key: any) => {
    setAttachments([
      ...attachments.slice(0, key),
      ...attachments.slice(key + 1, attachments.length),
    ]);
  };

  const validationSchema = Yup.lazy(() =>
    Yup.object().shape({
      subject: Yup.string().required('Subject is required.'),
      body: Yup.string().required('Body is required.'),
      to: Yup.string()
        .email('Invalid email.')
        .required('Email to is required.'),
    })
  );

  const [resumeUrl, setResumeUrl] = useState('');
  const [selectedVersion, setSelectedVersion] = useState(null);

  const { resumes } = useSelector((state: any) => state.candidateResume);

  const [reqAttachment] = useListResumeCandidateMutation();

  const {
    isOpen: isOpenPreview,
    onClose: onClosePreview,
    onOpen: openPreview,
  } = useDisclosure();
  const {
    isOpen: isOpenNotes,
    onClose: onCloseNotes,
    onOpen: onOpenNotes,
  } = useDisclosure();

  useEffect(() => {
    let latest_version = resumes.filter(
      (resume: any) => resume.is_primary === true
    );
    if (latest_version.length <= 0) {
      latest_version = resumes;
    }
    if (latest_version.length > 0) {
      setSelectedVersion(latest_version[0]);
    }
    if (resumes.length <= 0) {
      setSelectedVersion(null);
    }
  }, [resumes]);

  const onCcInputChange = (e: any) => {
    if (e.length > 2) {
      setCcOption(ccBcc);
    } else {
      setCcOption([]);
    }
  };

  const onBccInputchange = (e: any) => {
    if (e.length > 2) {
      setBccOption(ccBcc);
    } else {
      setBccOption([]);
    }
  };

  const FileRender = () => {
    return (
      <Box textAlign="center">
        {selectedVersion ? (
          <StreamAttachmentComponent
            filename={selectedVersion?.file_name}
            fileUrl={selectedVersion?.signedUrl}
            setResumeUrl={setResumeUrl}
            pdfHeight="80vh"
            docHeight="80vh"
          />
        ) : (
          <Text>No resume available</Text>
        )}
      </Box>
    );
  };

  const memoizedResume = useMemo(() => {
    return (
      <Box textAlign="center" overflow="auto" height="80vh" width="100%">
        {selectedVersion ? <FileRender /> : <Text>No resume available</Text>}
      </Box>
    );
  }, [selectedVersion]);

  useEffect(() => {
    const api = async () => {
      await reqAttachment({ id: candidate.id });
    };
    api();
  }, [candidate.id]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onClose();
        }}
        size="full"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            <Flex justifyContent="space-between">
              <Box>
                Submit To Client{' '}
                <Badge
                  ml="16px"
                  px="8px"
                  border="1px solid #C5C5C5"
                  borderRadius="2px"
                  background="#fff"
                  color="default.gray.800"
                  textTransform="capitalize"
                >
                  {tabIndex === 1 ? 'Step 1/2' : 'Step 2/2'}
                </Badge>
              </Box>
              <Flex gap="5px">
                <Link
                  href={`/jobs/${jobData.id}/details`}
                  target="_blank"
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: 'primary.600',
                  }}
                  rel="noreferrer"
                >
                  {jobData.title}
                </Link>
                -
                <Link
                  href={`/clients/${jobData.client?.id}/overview`}
                  target="_blank"
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: 'primary.600',
                    mr: '24px',
                  }}
                  rel="noreferrer"
                >
                  {jobData.client?.name}
                </Link>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box width="100%">
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errMsg}</AlertDescription>
                  {tokenLink !== '' && (
                    <AlertDescription>
                      <Link href={tokenLink} isExternal>
                        Click to follow this link
                      </Link>
                    </AlertDescription>
                  )}
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            overflow="hidden"
            height="100vh"
          >
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                setFieldValue,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <ScrollToFieldError />

                  <Flex>
                    <Box
                      width="60%"
                      height="90vh"
                      py={2}
                      borderRight="1px solid"
                      borderColor="default.borderlight"
                      px={3}
                      pr={0}
                    >
                      <Box mt="15px" textAlign="center">
                        {memoizedResume}
                      </Box>
                    </Box>

                    <Box
                      width="40%"
                      p="32px"
                      sx={{ overflowY: 'scroll', height: '90vh' }}
                    >
                      {tabIndex === 1 ? (
                        <>
                          <Text mb="34px" fontWeight={700}>
                            Company Details
                          </Text>
                          <Flex gap="32px" mb="24px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.job_title && touched.job_title
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Current Job Title
                              </FormLabel>
                              <Input
                                id="job_title"
                                name="job_title"
                                type="text"
                                variant="outline"
                                value={values.job_title}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.job_title)}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.company && touched.company
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Current Company
                              </FormLabel>
                              <Input
                                id="company"
                                name="company"
                                type="text"
                                variant="outline"
                                value={values.company}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.company)}
                              </FormErrorMessage>
                            </FormControl>
                          </Flex>
                          <Flex gap="32px" mb="32px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.work_status && touched.work_status
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Work Status{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Select
                                id="work_status"
                                name="work_status"
                                placeholder="Select"
                                onChange={(e: any) => {
                                  setFieldValue('work_status', String(e.value));
                                  pairPH('work_status', String(e.label));
                                }}
                                defaultValue={
                                  (candidate_work_status?.length > 0 &&
                                    candidate_work_status
                                      .map((item: any) => ({
                                        label: item.status,
                                        value: item.id,
                                      }))
                                      .filter(
                                        (val: any) =>
                                          val.value ===
                                          Number(values.work_status)
                                      )[0]) ||
                                  values.work_status
                                }
                                options={
                                  candidate_work_status?.length > 0 &&
                                  candidate_work_status.map((item: any) => ({
                                    label: item.status,
                                    value: item.id,
                                  }))
                                }
                              />
                              <FormErrorMessage>
                                {String(errors.work_status)}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.linked_in_url && touched.linked_in_url
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                LinkedIn
                              </FormLabel>
                              <Input
                                id="linked_in_url"
                                name="linked_in_url"
                                type="text"
                                variant="outline"
                                value={values.linked_in_url}
                                onChange={(e: any) => {
                                  handleChange(e);
                                  pairPH(
                                    'linked_in_url',
                                    String(e.target.value)
                                  );
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.linked_in_url)}
                              </FormErrorMessage>
                            </FormControl>
                          </Flex>
                          <Flex gap="32px" mb="32px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.education && touched.education
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Degree
                              </FormLabel>
                              <AtsSelect
                                id="education"
                                name="education"
                                placeholder="Select"
                                variant="outline"
                                onChange={(e: any) => {
                                  const educData = {
                                    label: e.label,
                                    value: values.education?.value || -1,
                                  };
                                  setFieldValue('education', educData);
                                  pairPH('education', String(e.label));
                                }}
                                isMulti={false}
                                isLoading={resEducation.isLoading}
                                defaultValue={values.education}
                                options={candidateDegreeList}
                              />

                              <FormErrorMessage>
                                {String(errors.education)}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl></FormControl>
                          </Flex>

                          <Box mb="34px">
                            <Divider />
                          </Box>

                          {/* <Text mb="34px" fontWeight={700}>
                            Total Experience{' '}
                            <Box as="span" color="caution.800">
                              *
                            </Box>
                          </Text>
                          <Flex gap="32px" mb="34px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.total_year_of_experience &&
                                touched.total_year_of_experience
                              )}
                            >
                              <InputGroup>
                                <InputLeftAddon children="Years" />
                                <Field
                                  as={Input}
                                  id="total_year_of_experience"
                                  name="total_year_of_experience"
                                  type="text" // Change the type to "text" instead of "number"
                                  variant="outline"
                                  value={values.total_year_of_experience}
                                  min={0}
                                  max={100}
                                  placeholder="Years"
                                  onChange={(e: any) => {
                                    const inputValue = e.target.value;
                                    const sanitizedValue = inputValue.replace(
                                      /[^\d]/g,
                                      ''
                                    ); // Remove non-digit characters
                                    const clampedValue = Math.min(
                                      100,
                                      Math.max(0, sanitizedValue)
                                    ); // Clamp value between 0 and 100

                                    handleChange({
                                      target: {
                                        name: 'total_year_of_experience',
                                        value: clampedValue,
                                      },
                                    });
                                    pairPH(
                                      'total_year_of_experience',
                                      String(clampedValue)
                                    );
                                  }}
                                />
                              </InputGroup>

                              <FormErrorMessage>
                                {String(errors.total_year_of_experience)}
                              </FormErrorMessage>
                            </FormControl>
                            -
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.total_months_of_experience &&
                                touched.total_months_of_experience
                              )}
                            >
                              <InputGroup>
                                <InputLeftAddon children="Months" />
                                <Field
                                  as={Input}
                                  id="total_months_of_experience"
                                  name="total_months_of_experience"
                                  type="text" // Change the type to "text" instead of "number"
                                  variant="outline"
                                  value={values.total_months_of_experience}
                                  min={0}
                                  max={11}
                                  placeholder="Months"
                                  onChange={(e: any) => {
                                    const inputValue = e.target.value;
                                    const sanitizedValue = inputValue.replace(
                                      /[^\d]/g,
                                      ''
                                    ); // Remove non-digit characters
                                    const clampedValue = Math.min(
                                      11,
                                      Math.max(0, sanitizedValue)
                                    ); // Clamp value between 0 and 11

                                    handleChange({
                                      target: {
                                        name: 'total_months_of_experience',
                                        value: clampedValue,
                                      },
                                    });
                                  }}
                                />
                              </InputGroup>
                              <FormErrorMessage>
                                {String(errors.total_months_of_experience)}
                              </FormErrorMessage>
                            </FormControl>
                          </Flex>

                          <Box mb="34px">
                            <Divider />
                          </Box> */}

                          <Text mb="34px" fontWeight={700}>
                            Address Information
                          </Text>
                          <AddressComponent
                            setFieldValue={setFieldValue}
                            // index={index}
                            handleChange={handleChange}
                            cityField={{
                              name: 'city',
                              value: values.city,
                              errors: errors.city,
                              touched: touched.city,
                            }}
                            stateField={{
                              name: 'state_province',
                              value: values.state_province,
                              errors: errors.state_province,
                              touched: touched.state_province,
                            }}
                            zipField={{
                              name: 'zip_code',
                              value: values.zip_code,
                              errors: errors.zip_code,
                              touched: touched.zip_code,
                            }}
                          />
                          {/* <Flex gap="32px" mb="80px">
                            <FormControl
                              isInvalid={Boolean(!!errors.city && touched.city)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                City
                              </FormLabel>
                              <Input
                                id="city"
                                name="city"
                                type="text"
                                variant="outline"
                                value={values.city}
                                onChange={(e) => {
                                  handleChange(e);
                                  pairPH('city', String(e.target.value));
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.city)}
                              </FormErrorMessage>
                            </FormControl>
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.state_province &&
                                touched.state_province
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                State
                              </FormLabel>
                              <Input
                                id="state_province"
                                name="state_province"
                                type="text"
                                variant="outline"
                                value={values.state_province}
                                onChange={(e) => {
                                  handleChange(e);
                                  pairPH(
                                    'state_province',
                                    String(e.target.value)
                                  );
                                }}
                              />

                              <FormErrorMessage>
                                {String(errors.state_province)}
                              </FormErrorMessage>
                            </FormControl>
                          </Flex> */}
                        </>
                      ) : (
                        <>
                          <Box mb="10px">
                            <FormControl
                              isInvalid={Boolean(!!errors.to && touched.to)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                To
                              </FormLabel>
                              <Input
                                id="to"
                                name="to"
                                type="email"
                                variant="outline"
                                value={values.to}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.to)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="10px">
                            <FormControl
                              isInvalid={Boolean(!!errors.cc && touched.cc)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Cc
                              </FormLabel>
                              <AtsCraetableSelect
                                id="cc"
                                name="cc"
                                onChange={(event: any) =>
                                  setFieldValue('cc', event)
                                }
                                options={ccOption}
                                isMulti={true}
                                placeholder="cc"
                                defaultValue={values.cc}
                                onInputChange={onCcInputChange}
                              />

                              <FormErrorMessage>
                                {String(errors.cc)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="10px">
                            <FormControl
                              isInvalid={Boolean(!!errors.bcc && touched.bcc)}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Bcc
                              </FormLabel>
                              <AtsCraetableSelect
                                id="bcc"
                                name="bcc"
                                onChange={(event: any) => {
                                  console.log('change');
                                  setFieldValue('bcc', event);
                                }}
                                options={bccOption}
                                isMulti={true}
                                placeholder="bcc"
                                defaultValue={values.bcc}
                                onInputChange={onBccInputchange}
                              />

                              <FormErrorMessage>
                                {String(errors.bcc)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="10px">
                            <FormControl>
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Template
                              </FormLabel>
                              <Select
                                placeholder="Select"
                                isMulti={false}
                                options={template}
                                onChange={(e: any) => {
                                  setquilNotes(placholders(e.body, values));
                                  setFieldValue(
                                    'body',
                                    placholders(e.body, values)
                                  );
                                  setFieldValue(
                                    'subject',
                                    placholders(e.subject, values)
                                  );
                                  setAttachments(e.attachments);
                                }}
                              />
                            </FormControl>
                          </Box>
                          <Box mb="34px">
                            <FormControl
                              isInvalid={Boolean(
                                !!errors.subject && touched.subject
                              )}
                            >
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Subject{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Input
                                id="subject"
                                name="subject"
                                type="text"
                                variant="outline"
                                placeholder="Subject"
                                value={values.subject}
                                onChange={handleChange}
                              />
                              <FormErrorMessage>
                                {String(errors.subject)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>
                          <Box mb="34px">
                            <FormControl
                              isInvalid={Boolean(!!errors.body && touched.body)}
                            >
                              <Text mb="10px" fontWeight={700}>
                                Body{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </Text>
                              <AtsEmailBody
                                id="notes"
                                value={values.notes || quilNotes}
                                height={250}
                                onChange={(e: any) => {
                                  setquilNotes(placholders(e, values));
                                  setFieldValue('body', placholders(e, values));
                                }}
                              />
                              <FormErrorMessage>
                                {String(errors.body)}
                              </FormErrorMessage>
                            </FormControl>
                          </Box>

                          <Box mb="34px">
                            <Divider />
                          </Box>

                          <Box mb="34px">
                            <Checkbox
                              colorScheme="purple"
                              color="default.secondarytext"
                              defaultChecked={values.primary}
                              name="primary"
                              id="primary"
                              onChange={handleChange}
                            >
                              Attach Primary Resume
                            </Checkbox>
                          </Box>
                          <Box mb="80px">
                            <FormControl>
                              <FormLabel
                                fontSize="14px"
                                lineHeight="18px"
                                color="default.secondarytext"
                              >
                                Attachments{' '}
                                <Box as="span" color="caution.800">
                                  *
                                </Box>
                              </FormLabel>
                              <Stack
                                direction={['column', 'row']}
                                spacing="24px"
                                mb="10px"
                              >
                                <Box gap="10px" display="inline-block">
                                  {attachments?.length > 0 &&
                                    attachments.map(
                                      (item: any, key: number) => {
                                        return (
                                          <Tag
                                            style={{ display: 'inline-block' }}
                                            key={'attachments-' + key}
                                            mr="10px"
                                            mb="10px"
                                          >
                                            <Stack spacing={1}>
                                              <HStack>
                                                <TagLabel maxW="100px">
                                                  {item.original_file_name}
                                                </TagLabel>
                                                <TagCloseButton
                                                  onClick={() => removeAtt(key)}
                                                />
                                              </HStack>
                                            </Stack>
                                          </Tag>
                                        );
                                      }
                                    )}
                                </Box>
                              </Stack>
                            </FormControl>
                            <AtsDropZone
                              multipleFile
                              isAgreement
                              uploadedEnd={(e: any) => { }}
                              deletedFile={(key: string) => { }}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </Flex>

                  <ModalFooter
                    position="sticky"
                    bottom="0"
                    background="default.white.100"
                    boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                  >
                    <Flex width="100%" justifyContent="space-between">
                      <ChakraButton
                        onClick={onOpenNotes}
                        left="0px"
                        variant="ghost"
                        color="primary.800"
                      >
                        Notes
                      </ChakraButton>
                      {tabIndex === 2 && (
                        <Flex gap="10px">
                          <Button
                            onClick={() => setTabIndex(1)}
                            variant="previous"
                          >
                            Previous
                          </Button>
                          <Button onClick={() => openPreview()}>
                            <Flex gap="10px" dir="column" alignItems="center">
                              <FAIcon iconName="eye" />
                              <Box>Preview</Box>
                            </Flex>
                          </Button>
                          <Button
                            variant="solid"
                            type="submit"
                            disabled={uploading || isOpenAlert}
                            onClick={handleSubmit}
                            loading={resSubmit.isLoading}
                          >
                            Submit to Client
                          </Button>
                        </Flex>
                      )}

                      {tabIndex === 1 && (
                        <Button variant="solid" onClick={() => setTabIndex(2)}>
                          Next
                        </Button>
                      )}
                    </Flex>

                    {isOpenPreview && (
                      <EmailPreviewModal
                        data={{
                          ...values,
                          attachments,
                          resume: `${selectedVersion?.original_file_name}.${selectedVersion?.file_name.split('.').splice(-1)[0]
                            }`,
                        }}
                        isOpen={isOpenPreview}
                        onClose={onClosePreview}
                        onSubmit={handleSubmit}
                        disabled={uploading || isOpenAlert}
                        isLoading={resSubmit.isLoading}
                      />
                    )}
                    {isOpenNotes && (
                      <NotesModal isOpen={isOpenNotes} onClose={onCloseNotes} />
                    )}
                  </ModalFooter>
                </form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

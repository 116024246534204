import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  Image,
  Link,
  Switch,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import FAIcon from 'components/lib/FAIcon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useDeleteJobAdsMutation,
  useGetJobsMutation,
  useListJobsMutation,
  useToggleAdsStatusMutation,
} from 'store/jobs.slice';
import { JobsTypes, swalContent } from 'types';
import { AtsConfirm } from 'utils/swal';
import JobsAdsModal from './Modal/jobAds';
import parse from 'html-react-parser';
import { FaRegCopy } from 'react-icons/fa';
import Url from 'assets/images/Url.svg';
import moment from 'moment';
import { CAREER_PAGE_URL } from 'constants/values';

const JobsAds = () => {
  const toast = useToast();

  const { jobPgBtn, jobData, fullData } = useSelector(
    (state: JobsTypes) => state.jobs
  );

  const { mail_box } = useSelector((state: JobsTypes) => state.constants);

  const [reqJobs] = useListJobsMutation();
  const [reqGetJob] = useGetJobsMutation();
  const [reqDeleteJobAds, resDeleteJobAds] = useDeleteJobAdsMutation();
  const [reqToggleAds] = useToggleAdsStatusMutation();
  const [adsList, setAdsList] = useState([]);
  const [jobAdsData, setJobAdsData] = useState([]);
  const [hover, setHover] = useState({ id: null, hover: null });

  const {
    isOpen: isEditJobOpen,
    onOpen: onEditJobOpen,
    onClose: onEditJobClose,
  } = useDisclosure();

  let content: swalContent = {
    title: 'Are you sure?',
    text: 'You are about delete this job ads, continue?',
    buttons: ['Cancel', true],
    icon: 'warning',
  };

  const handleDeleteJobAds = async (data: any) => {
    const confirm = await AtsConfirm(content);
    if (confirm) {
      reqDeleteJobAds({ id: data.id });
    }
  };

  const mailBox = (serial: string) => {
    try {
      const splitter = mail_box?.email.split('@');
      return `${splitter[0]}+${serial}@${splitter[1]}`;
    } catch (e) {
      console.error('e', e);
      return serial;
    }
  };

  // console.log('mail_box', mail_box?.email);

  useEffect(() => {
    if (resDeleteJobAds.isSuccess) {
      reqJobs(jobPgBtn);
      reqGetJob({ id: jobData.id });

      try {
        let title = '';
        let description = '';
        let status: 'error' | 'warning' | 'success' | 'info' = 'success';

        if (resDeleteJobAds.isSuccess) {
          title = 'Deleted Recruitment Team';
          description = 'A recruitment team has been deleted';
        }

        toast({
          title: title,
          description: description,
          status: status,
          isClosable: true,
          duration: 3000,
          position: 'top',
        });
      } catch (e) {
        console.log('error', e);
      }
    }
  }, [
    jobData?.id,
    jobPgBtn,
    reqGetJob,
    reqJobs,
    resDeleteJobAds.isSuccess,
    toast,
  ]);

  useEffect(() => {
    let data: any = [];
    if (fullData.jobAds?.length > 0) {
      fullData.jobAds.map((item: any) => {
        data.push({
          id: item.id,
          ads: item,
          title: item.title,
          location: item.location,
          job_status_id: item.job_status_id,
          description: item.description,
          skills: item.skills,
          checked: item.job_status_id === 1 ? true : false,
          serial_number: item.serial_number,
          career_page_publish: item.career_page_publish,
        });
      });
    }
    setAdsList(data);
  }, [fullData.jobAds]);

  const handleToggleAdsStatus = async (job: any, e: any) => {
    const checked = e.target.checked;
    const newList = adsList.map((item, key) => {
      if (item.id === job.id) {
        return { ...item, checked: checked };
      } else {
        return item;
      }
    });
    setAdsList(newList);
    await reqToggleAds({ id: job.id }).then(async () => {
      // await reqJobs(jobPgBtn);
      await reqGetJob({ id: jobData.id });
    });


    // reqJobs(jobPgBtn);
  };

  const copyMailBox = async (mailbox: string) => {
    const text = mailBox(mailbox);
    navigator.clipboard.writeText(text);
    toast({
      title: 'Mailbox copied',
      duration: 1000,
      position: 'top',
    });
  };

  return (
    <Flex direction="column" gap="8px">
      {adsList?.map((item: any, i: number) => {
        return (
          <Accordion defaultIndex={[i]} allowMultiple key={`jobAds-${i}`}>
            <AccordionItem>
              <Flex
                position="relative"
                sx={{ '.chakra-accordion__item': { border: 'none' } }}
              >
                <AccordionButton
                  p="8px 12px"
                  bg="#E6E9EE"
                  borderRadius="4px 4px 0px 0px"
                >
                  <Box as="span" flex="1" textAlign="left">
                    <Box
                      as="span"
                      fontWeight={500}
                      fontSize="14px"
                      lineHeight="18px"
                      mb="8px"
                    >
                      {item.career_page_publish ? (
                        <Link
                          href={`${CAREER_PAGE_URL}/job-details/${item.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Flex
                            alignItems="center"
                            gap="5px"
                            mb="10px"
                            textDecor="underline"
                          >
                            {item.title}
                            <Image src={Url} alt="link" height="18px" />
                          </Flex>
                        </Link>
                      ) : (
                        <Flex
                          alignItems="center"
                          gap="5px"
                          color="default.gray.800"
                        >
                          {item.title}
                        </Flex>
                      )}

                      <Box
                        as="span"
                        fontSize="14px"
                        lineHeight="18px"
                        color="#4A5568"
                      >
                        Created on:{' '}
                        <Box
                          as="span"
                          onMouseEnter={() => {
                            setHover({ id: item.id, hover: true });
                          }}
                          onMouseLeave={() => {
                            setHover({ id: item.id, hover: false });
                          }}
                        >
                          {hover.id === item.id && hover.hover
                            ? moment
                              .utc(item.created_at)
                              .format('MM/DD/YYYY hh:mm:ss A')
                            : moment.utc(item.created_at).format('MM/DD/YYYY')}
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box>
                    <AccordionIcon color="#6930CA" />
                  </Box>
                </AccordionButton>
                <Flex
                  gap="15px"
                  alignItems="center"
                  position="absolute"
                  right="55px"
                  top="0"
                  bottom="0"
                >
                  <Switch
                    colorScheme="purple"
                    id={`active-${i}`}
                    isChecked={item.checked}
                    // defaultChecked={item.job_status_id == 1 ? true : false}
                    onChange={(e: any) => handleToggleAdsStatus(item.ads, e)}
                  />
                  <ButtonGroup>
                    <Button
                      variant="ghost"
                      color="#6930CA"
                      onClick={() => {
                        setJobAdsData([item.ads]);
                        onEditJobOpen();
                      }}
                    >
                      <FAIcon iconName="pencil" />
                    </Button>
                    <Button
                      variant="ghost"
                      color="#CB2C2C"
                      onClick={() => handleDeleteJobAds(item.ads)}
                    >
                      <FAIcon iconName="trash" />
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Flex>
              <AccordionPanel
                px={3}
                border="1px solid #E6E9EE"
                borderRadius="0px 0px 4px 4px"
              >
                <Flex gap={7}>
                  <Box pt={3} width="40%">
                    <Flex gap={2} mb="8px">
                      <Box
                        fontSize="15px"
                        lineHeight="18px"
                        textAlign="left"
                        width="130px"
                        color="default.gray.600"
                      >
                        Created By:
                      </Box>
                      <Box sx={{ color: 'default.dark', fontSize: '15px' }}>
                        {jobData.client?.name}
                      </Box>
                    </Flex>
                    <Flex gap={2} mb="8px">
                      <Box
                        fontSize="15px"
                        lineHeight="18px"
                        textAlign="left"
                        width="130px"
                        color="default.gray.600"
                      >
                        Published On:
                      </Box>
                      <Box sx={{ color: 'default.dark', fontSize: '15px' }}>
                        <Box>
                          {item.ads.career_page_publish && 'Careers Page'}
                        </Box>
                        <Box>
                          {item.ads.free_job_board_publish && 'Free Job Boards'}
                        </Box>
                        <Box>
                          {item.ads.paid_job_board_publish && 'Paid Job Boards'}
                        </Box>
                      </Box>
                    </Flex>
                    {item?.serial_number && (
                      <Flex alignItems="center" gap={2} mb="8px">
                        <Box
                          fontSize="15px"
                          lineHeight="18px"
                          textAlign="left"
                          width="130px"
                          color="default.gray.600"
                        >
                          Resume Mailbox:
                        </Box>
                        <Flex
                          sx={{
                            alignItems: 'center',
                            gap: '5px',
                            color: 'primary.800',
                            fontSize: '15px',
                            cursor: 'pointer',
                          }}
                          onClick={() => copyMailBox(item?.serial_number)}
                        >
                          {mailBox(item?.serial_number)}
                          <FaRegCopy />
                        </Flex>
                      </Flex>
                    )}
                  </Box>
                  <Box width="60%">
                    {item.description ? (
                      <Box>
                        <Box
                          sx={{
                            pt: 3,
                            fontSize: '15px',
                            fontWeight: 700,
                            mb: '8px',
                          }}
                        >
                          Description
                        </Box>
                        {parse(item.description)}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          fontWeight: 700,
                          mt: '30px',
                        }}
                      >
                        No Description Available
                      </Box>
                    )}
                  </Box>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      })}
      {isEditJobOpen && (
        <JobsAdsModal
          isOpen={isEditJobOpen}
          onClose={onEditJobClose}
          jobAdsData={jobAdsData}
          update={true}
        />
      )}
    </Flex>
  );
};

export default JobsAds;

import React, { useRef } from 'react';
import 'react-quill/dist/quill.snow.css'; // Import Quill's styles
import 'assets/css/minHquill.css';
import 'assets/css/tinymce.css';
import { Editor } from '@tinymce/tinymce-react';
import { Box } from '@chakra-ui/react';

interface AtsEmailBodyInterface {
  key?: number;
  onChange: any;
  invalid?: boolean;
  initialValue?: string;
  value?: string;
  id?: any;
  height?: any;
  menubar?: boolean;
  placeholder?: string;
  disabled?: boolean;
  refocusTo?: string; // refocus to another element by Id. Use this when it auto focuses to the editor
}

export default function AtsEmailBody({
  key = 0,
  onChange,
  invalid = false,
  initialValue = '',
  value = '',
  id = null,
  height = 500,
  menubar = true,
  disabled = false,
  refocusTo = '',
  placeholder = 'Type what ever you need here...',
}: AtsEmailBodyInterface) {
  const editorRef = useRef(null);
  const handlePaste = (e: any) => {
    e.preventDefault();

    const editor = editorRef.current;
    if (editor) {
      const clipboardData = e.clipboardData || window?.Clipboard;

      if (clipboardData) {
        // Get the HTML content from the clipboard
        const text = clipboardData.getData('text/plain');
        const pastedHTML = clipboardData.getData('text/html');
        if (pastedHTML != '') {
          // Create a temporary div element to parse the pasted HTML
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = pastedHTML;
          // Clean up and modify the pasted HTML as needed
          // For example, you can add inline styles to all elements
          const modifiedHTML = modifyPastedHTML(tempDiv);
          // Insert the modified HTML into the editor
          editor.execCommand('mceInsertContent', false, modifiedHTML);
        } else {
          //for plain text
          editor.execCommand('mceInsertContent', false, text);
        }
      }
    }
  };
  const modifyPastedHTML = (element: HTMLElement) => {
    // You can modify the pasted HTML here
    // For example, add inline styles to all elements
    const elements = element.querySelectorAll('*');
    elements.forEach((el) => {
      el.setAttribute(
        'style',
        'font-family: Verdana; font-size: 10pt; line-height: normal;'
      );
    });
    // Return the modified HTML
    return element.innerHTML;
  };

  return (
    <Box
      sx={{
        '&& .tox-tinymce': {
          zIndex: 0,
        },
        '&& body#tinymce': {
          height: height,
          overflowY: 'scroll',
        },
      }}
    >
      <Editor
        key={key}
        id={id}
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        init={{
          focus: 'false',
          auto_focus: 'title',
          placeholder: placeholder,
          height: height,
          min_height: height,
          menubar: menubar,
          plugins: 'fullscreen autoresize lists advlist table',
          font_family_formats:
            'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
          toolbar:
            'undo redo | formatselect | fontfamily | fontsizeinput |' +
            ' bold italic | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
          content_style: `body { font-family: verdana; font-size: 10pt; line-height: 1; height: ${height}px; overflow-y: scroll !important }`,
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
          line_height_formats: 'normal 0.5 1 1.2 1.4 1.6 2',
          font_size_input_default_unit: 'pt',
          setup: function (ed) {
            ed.on('init', function (e) {
              ed.execCommand('fontName', true, 'Verdana');
              ed.execCommand('fontSize', true, '10pt');
              ed.execCommand('lineheight', true, 'normal');

              if (refocusTo !== '') {
                document.getElementById(`${refocusTo}`)?.focus();
              }
            });
            ed.on('PreInit', function () {
              ed.getBody().style.fontSize = '10 pt';
            });
          },
          theme_advanced_buttons3_add: 'fullscreen',
          fullscreen_new_window: true,
          fullscreen_settings: {
            theme_advanced_path_location: 'top',
          },
        }}
        apiKey={'cm8v4bdpw5irns81lc7capqacgyg9age3a220mt9zzhnj9lz'}
        onEditorChange={onChange}
        onPaste={handlePaste}
        disabled={disabled}
      />
    </Box>
  );
}

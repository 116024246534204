import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Loader from '../../Library/Loader';
import '../../theme/fonts.css';
import 'react-tooltip/dist/react-tooltip.css';
import { Box, Image, useDisclosure } from '@chakra-ui/react';

import ConfettiExplosion from 'react-confetti-explosion';

import AppNavBar from './AppNavbar';
import ApprovalsPage from './Approvals/Page';
import CandidatesPage from './Candidates/Page';
import ClientsPage from './Clients/Page';
import Dashboard from './DashBoard';
import JobsPage from './Jobs/Page';
import LeadsPage from './Leads/Page';
import NotFoundPage from 'pages/404';
import Settings from './Settings';
import Users from './Settings/Users';
import MyProfile from './MyProfile';
import MsGraph from './MsGraph';
import MsGraphOuth from './MsGraph/oauth';
import MsGraphMe from './MsGraph/me';
import EmailTemplateTab from './Settings/EmailTemplates';
import EmailSignatures from './Settings/EmailSignatures';
import ParseQueue from './ParseQueue';
import RingCentralAuth from './Settings/RingCentral/auth';
import FooterNotif from './FooterNotif';
import ScheduleInterview from './Settings/ScheduleInterview';
import CandidatesResume from './Candidates/Resume';
import { useDispatch, useSelector } from 'react-redux';

import confetti from 'assets/images/confetti.svg';
import confetti1 from 'assets/images/confetti1.svg';
import { updateApp } from 'store/app.slice';

const MainPage = () => {
  const dispatch = useDispatch();
  const { isCongrats } = useSelector((state: any) => state.app);
  const { first_name } = useSelector((state: any) => state.auth);

  const [isNavBar, setIsNavBar] = useState<boolean>(true);
  const {
    isOpen: isOpenParseQueue,
    onClose: onCloseParseQueue,
    onOpen: onOpenParseQueue,
  } = useDisclosure({ defaultIsOpen: false });

  const {
    isOpen: isOpenFooterNotif,
    onClose: onCloseFooterNotif,
    onOpen: onOpenFooterNotif,
  } = useDisclosure({ defaultIsOpen: false });

  const handleOpenParseQueue = () => {
    onOpenParseQueue();
  };

  const handleOpenFooterNotif = () => {
    onOpenFooterNotif();
  };

  const params = useParams();
  const isDashboard = params['*'] === 'dashboard';

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(updateApp({ isCongrats: false }));
    }, 10000);

    // Clean up the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [isCongrats]);

  const config = {
    force: 0.8,
    duration: 10000,
    particleCount: 250,
    width: 1600,
    colors: ['#bdde11', '#1c6cdc', '#fce22a', '#fc447c'],
  };

  return (
    <Box bg="default.white.800" h="100vh">
      <Box pb={6}>
        {isNavBar && (
          <AppNavBar
            handleOpenParseQueue={handleOpenParseQueue}
            handleOpenFooterNotif={handleOpenFooterNotif}
          />
        )}
      </Box>
      {isOpenParseQueue && (
        <ParseQueue isOpen={isOpenParseQueue} onClose={onCloseParseQueue} />
      )}

      <Box
        sx={{ px: !isDashboard && 4, pt: !isDashboard ? 20 : 10 }}
        onClick={() => dispatch(updateApp({ isCongrats: false }))}
      >
        {isCongrats && (
          <Box
            sx={{
              zIndex: 999999999999,
              position: 'absolute',
              top: '30vh',
              left: 0,
              right: 0,
              bgColor: '#FAF5FF',
              width: '80%',
              border: '0.5px solid #6930ca1f',
              borderRadius: '20px',
              margin: '0 auto',
              py: '20px',
              display: 'flex',
              flexDir: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '40px',
              fontWeight: 700,
              color: 'primary.800',
              boxShadow: '-7px 5px 8px 2px rgba(0, 0, 0, 0.05)',
            }}
          >
            <ConfettiExplosion {...config} />
            <Box
              sx={{
                img: {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                },
              }}
            >
              <Image src={confetti} alt="logo" />
            </Box>
            <Box>Woohoooo!! Congrats, {first_name}!</Box>
            <Box> You’re Awesome!</Box>
            <Box
              sx={{
                img: {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  height: '100%',
                },
              }}
            >
              <Image src={confetti1} alt="logo" />
            </Box>
          </Box>
        )}

        <Box sx={{ filter: isCongrats && 'blur(2px)' }}>
          <React.Suspense fallback={<Loader />}>
            <Routes>
              <Route path={'/'} element={<Dashboard />} />
              <Route path={'/dashboard'} element={<Dashboard />} />

              <Route path="/jobs" element={<JobsPage />}>
                <Route path=":jobsId" element={<JobsPage />}>
                  <Route path=":jobsTab" element={<JobsPage />}>
                    <Route path=":jobsTabId" element={<JobsPage />}>
                      <Route path=":jobsTabNav" element={<JobsPage />}>
                        <Route path=":jobsDrawerTab" element={<JobsPage />} />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="/candidates" element={<CandidatesPage />}>
                <Route path=":candidatesPageNum" element={<CandidatesPage />}>
                  <Route path=":candidatesId" element={<CandidatesPage />}>
                    <Route path=":candidatesTab" element={<CandidatesPage />}>
                      <Route
                        path=":candidatesTabId"
                        element={<CandidatesPage />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>

              <Route path="/clients" element={<ClientsPage />}>
                <Route path=":clientsId" element={<ClientsPage />}>
                  <Route path=":clientsTab" element={<ClientsPage />}>
                    <Route path=":clientsTabId" element={<ClientsPage />} />
                  </Route>
                </Route>
              </Route>

              <Route path="/leads" element={<LeadsPage />}>
                <Route path=":leadsId" element={<LeadsPage />}>
                  <Route path=":leadsTab" element={<LeadsPage />}>
                    <Route path=":leadsTabId" element={<LeadsPage />} />
                  </Route>
                </Route>
              </Route>

              <Route path="/approvals" element={<ApprovalsPage />}>
                <Route path=":approvalsId" element={<ApprovalsPage />}>
                  <Route path=":approvalsTab" element={<ApprovalsPage />}>
                    <Route path=":approvalsTabId" element={<ApprovalsPage />} />
                  </Route>
                </Route>
              </Route>

              <Route path="/profile/:profileID" element={<MyProfile />}></Route>

              <Route path="/settings" element={<Settings />}></Route>
              <Route path="settings/users" element={<Users />}></Route>
              <Route
                path="/settings/ringcentral/auth"
                element={<RingCentralAuth />}
              ></Route>
              <Route
                path="*"
                element={<NotFoundPage setIsNavBar={setIsNavBar} />}
              />
              <Route path="/msgraph" element={<MsGraph />}></Route>
              <Route path="/msgraph/oauth" element={<MsGraphOuth />}></Route>
              <Route path="/msgraph/me" element={<MsGraphMe />}></Route>
              <Route path="/email-template" element={<EmailTemplateTab />}>
                <Route
                  path=":templateTab"
                  element={<EmailTemplateTab />}
                ></Route>
              </Route>
              <Route path="/email-signature" element={<EmailSignatures />}>
                <Route
                  path=":signatureTab"
                  element={<EmailSignatures />}
                ></Route>
              </Route>
              <Route
                path="/schedule-interview"
                element={<ScheduleInterview />}
              ></Route>
              <Route
                path="/resume/:resumeId"
                element={<CandidatesResume />}
              ></Route>
            </Routes>
          </React.Suspense>
        </Box>
      </Box>
      {isOpenFooterNotif && (
        <FooterNotif isOpen={isOpenFooterNotif} onClose={onCloseFooterNotif} />
      )}
    </Box>
  );
};

export default MainPage;

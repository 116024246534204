import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  useToast,
  Text,
  TableContainer,
  Table,
  Tr,
  Tbody,
  Td,
  RadioGroup,
  Radio,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useListJobsMutation } from 'store/jobs.slice';
import { Formik } from 'formik';
import {
  useListCandidatesMutation,
  useMergeCandidatesMutation,
} from 'store/candidates.slice';
import LoadingPage from 'components/app/Loading';
import ScrollToFieldError from 'components/app/ScrollError';
import TrLoading from 'components/app/TrLoading';

interface ConditionalRadioButtonProps {
  value: string;
  key: string;
  condition: boolean;
  label: string;
}

interface CandidatesMergeModalProps {
  isOpen: any;
  onClose: any;
  candidate_idx?: number[];
  candidate1?: any;
  candidate2?: any;
}

const CandidatesMergeModal = ({
  isOpen,
  onClose,
  candidate_idx,
  candidate1,
  candidate2,
}: CandidatesMergeModalProps) => {
  const toast = useToast();

  const {
    isOpen: isOpenAlert,
    onClose: onCloseAlert,
    onOpen,
  } = useDisclosure({ defaultIsOpen: false });

  const [errorMessage, setErrorMessage] = useState(
    'There was an error processing your request. Change a few things up and try again.'
  );

  const {
    candidatePgBtn,
  } = useSelector((state: any) => state.candidates);

  const [checkedEmails, setCheckedEmails] = useState([] as Array<number>)
  const [checkedContact, setCheckedContact] = useState([] as Array<number>)
  const [submitted, setSubmitted] = useState(false)

  const [candidates, setCandidates] = useState([] as Array<any>)
  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const [reqList, resJobs] = useListJobsMutation();


  useEffect(() => {
    if (candidate1.isSuccess) {
      const cand1 = candidate1.data.data.data
      // console.log("cand1 ", cand1)
      let checker = 0
      candidates.map((candidate: any) => {
        if (candidate.id === cand1.id)
          checker++;
      })
      if (checker === 0) {
        setCandidates([...candidates, cand1])
      }

    }
  }, [candidate1.isSuccess])

  useEffect(() => {
    if (candidate2.isSuccess) {
      const cand2 = candidate2.data.data.data
      // console.log("cand2 ", cand2)

      let checker = 0
      candidates.map((candidate: any) => {
        if (candidate.id === cand2.id)
          checker++;
      })
      if (checker === 0) {
        setCandidates([...candidates, cand2])
      }
    }
  }, [candidate2.isSuccess])
  // add useMergeCandidatesMutation to candidates slice
  const [reqMerge, resMerge] = useMergeCandidatesMutation();

  useEffect(() => {
    if (resMerge.isSuccess || resMerge.isSuccess) {
      toast({
        title: 'Success',
        description: 'Candidate has been merged',
        status: 'success',
        isClosable: true,
        duration: 2000,
        position: 'top',
      });
      candidate1.reset();
      candidate2.reset();
      reqCandidates(candidatePgBtn);
      onClose();
    }
  }, [resMerge.isSuccess, resMerge.isSuccess]);
  const properties = [
    'contact',
    'emails',
    'linkedin_url',
    'work_status',
    'source',
    'city',
    'state_province',
    'country',
    'zip_code',
    'total_experience',
    'latest_job_title',
    'latest_employer',
    'latest_salary',
    'expected_salary_start',
    'expected_salary_end',
  ];

  const initialValues = {
    master: null as any,
    linkedin_url: null as any,
    work_status: null as any,
    source: null as any,
    city: null as any,
    state_province: null as any,
    country: null as any,
    zip_code: null as any,
    total_experience: null as any,
    latest_job_title: null as any,
    latest_employer: null as any,
    latest_salary: null as any,
    expected_salary_start: null as any,
    expected_salary_end: null as any,
  }
  const validationSchema = Yup.lazy((values) => Yup.object().shape({
    master: Yup.string().nullable().required('Pick 1 Master Record'),
    linkedin_url: Yup.string().nullable().required('Pick 1 LinkedIn Url'),
    work_status: Yup.string().nullable().required('Pick 1 Work Status'),
    source: Yup.string().nullable().required('Pick 1 Candidate Source'),
    city: Yup.string().nullable().required('Pick 1 City'),
    state_province: Yup.string().nullable().required('Pick 1 State Province'),
    country: Yup.string().nullable().required('Pick 1 Country'),
    zip_code: Yup.string().nullable().required('Pick 1 Zip Code'),
    total_experience: Yup.string().nullable().required('Pick 1 Total Year of Experience'),
    latest_job_title: Yup.string().nullable().required('Pick 1 Latest Job Title'),
    latest_employer: Yup.string().nullable().required('Pick 1 Latest Employer'),
    latest_salary: Yup.string().nullable().required('Pick 1 Latest Salary'),
    expected_salary_start: Yup.string().nullable().required('Pick 1 Expected Salary Start'),
    expected_salary_end: Yup.string().nullable().required('Pick 1 Expected Salary End'),
  }))

  const handleMultipleData = (property: string, index: string, setFieldValue: any) => {
    const emails = [] as any;
    const contact = [] as any;
    candidates[Number(index)]?.[property].map((prop: any) => {
      switch (property) {
        case 'emails':
          emails.push(prop.id)
          break;
        case 'contact':
          contact.push(prop.id)
          break;
      }
    })
    if (property === 'emails') {
      setCheckedEmails(emails);
    } else {
      setCheckedContact(contact);
    }
  }
  const handleDataSelect = async (property: string, index: string, setFieldValue: any) => {
    await setFieldValue(property, index)
  }

  const handleCheck = (e: any, property: string) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);

    switch (property) {
      case 'emails':
        setCheckedEmails([...checkedEmails, ID]);
        if (!checked) {
          setCheckedEmails(checkedEmails.filter((item: number) => item !== ID));
        }
        break;
      case 'contact':
        setCheckedContact([...checkedContact, ID]);
        if (!checked) {
          setCheckedContact(checkedContact.filter((item: number) => item !== ID));
        }
        break;
    }

  };

  const conditionalRadioButton = ({ key, value, condition, label }: ConditionalRadioButtonProps) => {
    if (!condition) {
      return (
        <Radio key={key} value={value}><Text whiteSpace="normal" blockSize="auto" w="225px">{label}</Text></Radio>
      )
    }
    else {
      return (
        <Text whiteSpace="normal" blockSize="auto" w="249px" >{label}</Text>
      )
    }

  }

  const onSubmit = async (data: any) => {
    const contact: any = []
    const emails: any = []

    console.log("data ", data)

    candidates[0]?.contact.map((cont: any) => {
      if (checkedContact.includes(cont.id)) {
        let param = {
          id: Number(cont.id),
          category: String(cont.category),
          type: String(cont.type),
          number: String(cont.number),
          primary: Number(cont.primary),
        }
        if (candidates[0]?.id !== candidates[Number(data.master)]?.id) {
          param.primary = 0
        }
        contact.push(param)
      }
    })
    candidates[1]?.contact.map((cont: any) => {
      if (checkedContact.includes(cont.id)) {
        let param = {
          id: Number(cont.id),
          category: String(cont.category),
          type: String(cont.type),
          number: String(cont.number),
          primary: Number(cont.primary),
        }
        if (candidates[1]?.id !== candidates[Number(data.master)]?.id) {
          param.primary = 0
        }
        contact.push(param)
      }
    })

    candidates[0]?.emails.map((email: any) => {
      if (checkedEmails.includes(email.id)) {
        let param = {
          id: Number(email.id),
          email: String(email.email),
          primary: Number(email.primary)
        }
        if (candidates[0]?.id !== candidates[Number(data.master)]?.id) {
          param.primary = 0
        }
        emails.push(param)
      }
    })
    candidates[1]?.emails.map((email: any) => {
      if (checkedEmails.includes(email.id)) {
        let param = {
          id: Number(email.id),
          email: String(email.email),
          primary: Number(email.primary)
        }
        if (candidates[1]?.id !== candidates[Number(data.master)]?.id) {
          param.primary = 0
        }

        emails.push(param)
      }
    })

    const param = {
      candidate_1: candidates[0]?.id,
      candidate_2: candidates[1]?.id,
      master: candidates[Number(data.master)]?.id,
      contact: contact,
      emails: emails,
      linkedin_url: candidates[Number(data.linkedin_url)]?.linkedin_url || null,
      work_status: candidates[Number(data.work_status)]?.work_status || null,
      candidate_source: candidates[Number(data.source)]?.source || null,
      candidate_sub_source: candidates[Number(data.source)]?.sub_source_id || null,
      city: candidates[Number(data.city)]?.city || null,
      state_province: candidates[Number(data.state_province)]?.state_province || null,
      country: candidates[Number(data.country)]?.country || null,
      zip_code: candidates[Number(data.zip_code)]?.zip_code || null,
      total_experience: {
        total_year_of_experience: candidates[Number(data.total_experience)]?.total_year_of_experience || 0,
        total_months_of_experience: candidates[Number(data.total_experience)]?.total_months_of_experience || 0,
      },
      latest_job_title: candidates[Number(data.latest_job_title)]?.latest_job_title || null,
      latest_employer: candidates[Number(data.latest_employer)]?.latest_employer || null,
      latest_salary: candidates[Number(data.latest_salary)]?.latest_salary || 0,
      expected_salary_start: candidates[Number(data.expected_salary_start)]?.expected_salary_start || 0,
      expected_salary_end: candidates[Number(data.expected_salary_end)]?.expected_salary_end || 0,
    }

    // change excess properties
    param.work_status = {
      id: param.work_status.id,
      status: param.work_status.status
    }
    param.candidate_source = {
      id: param.candidate_source.id,
      source: param.candidate_source.source
    }

    console.log("param ", param)
    if (checkedContact.length > 0 && checkedEmails.length > 0) {
      await reqMerge({ data: param });
    } else {
      onOpen();
      setErrorMessage('Please select at least one contact or email');
    }


  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          onCloseAlert();
          onClose();
        }}
        size="4xl"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent fontFamily="Proxima Nova Regular">
          <ModalHeader
            background="default.white.800"
            borderBottom="1px solid gainsboro"
            borderRadius="4px 4px 0"
            p="18px 32px"
            fontSize="18px"
            lineHeight="21.92px"
            textAlign="left"
          >
            Merge Candidates
          </ModalHeader>
          <ModalCloseButton
            top="13px"
            right="20px"
            color="default.secondarytext"
          />
          {isOpenAlert && (
            <Box>
              <Alert status="error" justifyContent="space-around">
                <AlertIcon />
                <Box>
                  <AlertTitle>Oh snap!</AlertTitle>
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Box>
                <CloseButton
                  position="relative"
                  right={-1}
                  top={-3}
                  onClick={onCloseAlert}
                />
              </Alert>
            </Box>
          )}
          <ModalBody
            borderRadius="0 0 4px 4px"
            p={0}
            fontFamily="Proxima Nova Regular"
          >
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting, validateForm }) => {
                validateForm().then((errors) => {
                  // console.log("went to validate")
                  if (Object.keys(errors).length === 0) {
                    onSubmit(data)
                  }
                  else {
                    onOpen()
                    // console.log("errors", errors)
                  }
                })

              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, validateForm }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <ScrollToFieldError />
                    <Box mb="16px" overflow="automatic"
                      p="30px"
                      background="#fff"
                      borderRadius="8px">

                      <TableContainer
                        boxSizing="border-box"
                        border="1px solid"
                        borderColor="default.white.400"
                        borderRadius="md"
                        mb="34px"
                      >
                        <Table __css={{ 'table-layout': 'fixed', width: 'full' }}>
                          <Tbody
                            boxSizing="border-box"
                            background="default.white.100"
                            borderBottom="1px solid"
                            borderColor="default.white.400"
                          >

                            {candidate1.isLoading || candidate2.isLoading ? (
                              <TrLoading rows={properties.length} columns={3} />
                            ) : (
                              <>
                                <Tr >
                                  <Td bg="default.white.800" textTransform="capitalize" w="26%">
                                    Master
                                  </Td>
                                  <Td textTransform="none" flex={1} w="74%">
                                    <FormControl
                                      isInvalid={Boolean(
                                        !!errors.master &&
                                        touched.master
                                      )}>
                                      <RadioGroup value={values.master} name="master" key="master" onChange={(value: string) => {

                                        setFieldValue("master", value)
                                        setCheckedContact([])
                                        setCheckedEmails([])
                                        properties.map((property: string) => {
                                          // change this to when choosing a candidate it would automatically set all unfilled property with the selected candidate
                                          switch (property) {
                                            case 'contact':
                                            case 'emails':
                                              handleMultipleData(property, value, setFieldValue);
                                              break;
                                            default:
                                              handleDataSelect(property, value, setFieldValue);
                                              break;
                                          }
                                          // setCheckAllProperty(value);
                                        })
                                        setTimeout(() => {
                                          validateForm()
                                        }, 100)

                                      }} w="full">
                                        <Flex w="full">
                                          <Flex w="100%">
                                            <Radio value="0">{[candidates[0]?.first_name, candidates[0]?.last_name].join(" ")}</Radio>
                                          </Flex>
                                          <Flex w="100%">
                                            <Radio value="1">{[candidates[1]?.first_name, candidates[1]?.last_name].join(" ")}</Radio>
                                            {/* sample */}
                                          </Flex>
                                        </Flex>

                                      </RadioGroup>
                                      <FormErrorMessage>
                                        {String(errors.master)}
                                      </FormErrorMessage>
                                    </FormControl>

                                  </Td >
                                </Tr>
                                {properties.map((property: string) => {
                                  if (property === "emails" || property === "contact") {
                                    const num_rows = candidates[0]?.[property]?.length > candidates[1]?.[property]?.length ? candidates[0]?.[property]?.length : candidates[1]?.[property]?.length
                                    const rows = Array.from({ length: num_rows }, (_, i) => {
                                      const attribute = (property === 'contact') ? "number" : "email"
                                      return (
                                        <Tr key={i}>
                                          <Td bg="default.white.800" textTransform="capitalize">
                                            {i === 0 ? (
                                              property.replaceAll("_", " ")
                                            )
                                              : ("")
                                            }
                                          </Td>
                                          <Td>
                                            <Flex w="full" onChange={() => setSubmitted(false)}>

                                              <Flex w="100%" >
                                                <FormControl
                                                  isInvalid={submitted && ((property === 'contact') ? checkedContact.length <= 0 : checkedEmails.length <= 0)}>
                                                  {candidates[0]?.[property]?.[i] !== null && candidates[0]?.[property]?.[i] !== undefined && (
                                                    <Checkbox
                                                      id={candidates[0]?.[property]?.[i]?.id}
                                                      onChange={(e) => handleCheck(e, property)}
                                                      isChecked={(property === 'contact') ?
                                                        checkedContact.includes(candidates[0]?.[property]?.[i]?.id) :
                                                        checkedEmails.includes(candidates[0]?.[property]?.[i]?.id)}
                                                    >{candidates[0]?.[property]?.[i]?.[attribute]}
                                                    </Checkbox>
                                                  )}
                                                  {i === num_rows - 1 && (
                                                    <FormErrorMessage>
                                                      {(property === 'contact') ? (checkedContact.length <= 0) && "Pick at least 1 Contact" : (checkedEmails.length <= 0) && "Pick at least 1 Email"}
                                                    </FormErrorMessage>
                                                  )}

                                                </FormControl>
                                              </Flex>
                                              <Flex w="100%">
                                                <FormControl
                                                  isInvalid={submitted && ((property === 'contact') ? checkedContact.length <= 0 : checkedEmails.length <= 0)}>
                                                  {candidates[1]?.[property]?.[i] !== null && candidates[1]?.[property]?.[i] !== undefined && (
                                                    <Checkbox
                                                      id={candidates[1]?.[property]?.[i]?.id}
                                                      onChange={(e) => handleCheck(e, property)}
                                                      isChecked={(property === 'contact') ?
                                                        checkedContact.includes(candidates[1]?.[property]?.[i]?.id) :
                                                        checkedEmails.includes(candidates[1]?.[property]?.[i]?.id)}
                                                    >{candidates[1]?.[property]?.[i]?.[attribute]}</Checkbox>
                                                  )}


                                                </FormControl>
                                              </Flex>
                                            </Flex>
                                          </Td>
                                        </Tr>
                                      )
                                    });
                                    return <>{rows}</>;
                                  } else {
                                    const cand_1 = candidates?.[0]?.[property]
                                    const cand_2 = candidates?.[1]?.[property]
                                    switch (property) {
                                      case 'work_status':
                                        const work_condition = String(cand_1?.status) === String(cand_2?.status)
                                        return (
                                          <Tr w="full">
                                            <Td bg="default.white.800" textTransform="capitalize" >
                                              {property.replaceAll("_", " ")}
                                            </Td >
                                            <Td flex={1} >
                                              <FormControl
                                                isInvalid={Boolean(
                                                  !!errors.work_status &&
                                                  touched.work_status
                                                )}
                                              >
                                                <RadioGroup value={values.work_status} name="work_status" key="work_status" onChange={(value) => { setFieldValue(property, value) }} w="full">
                                                  <Flex w="full">
                                                    <Flex w="100%">
                                                      {conditionalRadioButton(
                                                        {
                                                          key: "0",
                                                          value: "0",
                                                          condition: work_condition,
                                                          label: cand_1?.status,
                                                        })}
                                                    </Flex>
                                                    <Flex w="100%">
                                                      {conditionalRadioButton(
                                                        {
                                                          key: "1",
                                                          value: "1",
                                                          condition: work_condition,
                                                          label: cand_2?.status,
                                                        })}
                                                    </Flex>
                                                  </Flex>
                                                </RadioGroup>
                                                {
                                                  !work_condition && (
                                                    <FormErrorMessage>
                                                      {String(errors.work_status)}
                                                    </FormErrorMessage>
                                                  )
                                                }

                                              </FormControl>
                                            </Td>
                                          </Tr>
                                        )
                                      case 'source':
                                        const source_condition = String(cand_1?.source) === String(cand_2?.source)
                                        return (
                                          <Tr w="full">
                                            <Td bg="default.white.800" textTransform="capitalize" >
                                              {property.replaceAll("_", " ")}
                                            </Td >
                                            <Td flex={1} >
                                              <FormControl
                                                isInvalid={Boolean(
                                                  !!errors.source &&
                                                  touched.source
                                                )}
                                              >
                                                <RadioGroup value={values.source} name="source" key="source" onChange={(value) => { setFieldValue(property, value) }} w="full">
                                                  <Flex w="full">
                                                    <Flex w="100%">
                                                      {conditionalRadioButton(
                                                        {
                                                          key: "0",
                                                          value: "0",
                                                          condition: source_condition,
                                                          label: cand_1?.source,
                                                        })}
                                                    </Flex>
                                                    <Flex w="100%" >
                                                      {conditionalRadioButton(
                                                        {
                                                          key: "1",
                                                          value: "1",
                                                          condition: source_condition,
                                                          label: cand_2?.source,
                                                        })}
                                                    </Flex>
                                                  </Flex>

                                                </RadioGroup>
                                                {
                                                  !source_condition && (
                                                    <FormErrorMessage>
                                                      {String(errors.source)}
                                                    </FormErrorMessage>
                                                  )
                                                }

                                              </FormControl>
                                            </Td>

                                          </Tr>
                                        )
                                      case 'total_experience':
                                        const cand_1_exp = {
                                          years: candidates?.[0]?.total_year_of_experience,
                                          months: candidates?.[0]?.total_months_of_experience,
                                        }
                                        const cand_2_exp = {
                                          years: candidates?.[1]?.total_year_of_experience,
                                          months: candidates?.[1]?.total_months_of_experience,
                                        }
                                        const total_exp_condition = (cand_1_exp?.years * 12) + cand_1_exp?.months === (cand_2_exp?.years * 12) + cand_2_exp?.months
                                        return (
                                          <Tr w="full">
                                            <Td bg="default.white.800" textTransform="capitalize" >
                                              {property.replaceAll("_", " ")}
                                            </Td >
                                            <Td flex={1} >
                                              <FormControl
                                                isInvalid={Boolean(
                                                  !!errors.total_experience &&
                                                  touched.total_experience
                                                )}
                                              >
                                                <RadioGroup value={values.total_experience} name="total_experience" key="total_experience" onChange={(value) => { setFieldValue(property, value) }} w="full">
                                                  <Flex w="full">
                                                    <Flex w="100%">
                                                      {conditionalRadioButton(
                                                        {
                                                          key: "0",
                                                          value: "0",
                                                          condition: total_exp_condition,
                                                          label: [cand_1_exp?.years > 1 ?
                                                            `${cand_1_exp?.years} yrs` :
                                                            cand_1_exp?.years > 0 ?
                                                              `${cand_1_exp?.years} yr` :
                                                              '', cand_1_exp?.months > 1 ?
                                                            `${cand_1_exp?.months} mos` :
                                                            cand_1_exp?.months > 0 ?
                                                              `${cand_1_exp?.months} mo` :
                                                              ''].join(" "),
                                                        })}
                                                    </Flex>
                                                    <Flex w="100%">
                                                      {conditionalRadioButton(
                                                        {
                                                          key: "1",
                                                          value: "1",
                                                          condition: total_exp_condition,
                                                          label: [cand_2_exp?.years > 1 ?
                                                            `${cand_2_exp?.years} yrs` :
                                                            cand_2_exp?.years > 0 ?
                                                              `${cand_2_exp?.years} yr` :
                                                              '', cand_2_exp?.months > 1 ?
                                                            `${cand_2_exp?.months} mos` :
                                                            cand_2_exp?.months > 0 ?
                                                              `${cand_2_exp?.months} mo` :
                                                              ''].join(" "),
                                                        })}
                                                    </Flex>
                                                  </Flex>
                                                  {
                                                    !total_exp_condition && (
                                                      <FormErrorMessage>
                                                        {String(errors.total_experience)}
                                                      </FormErrorMessage>
                                                    )
                                                  }

                                                </RadioGroup>
                                              </FormControl>
                                            </Td>

                                          </Tr>
                                        )
                                      default:
                                        const default_condition = cand_1 == cand_2
                                        return (
                                          <Tr w="full">
                                            <Td bg="default.white.800" textTransform="capitalize" >
                                              {property.replaceAll("_", " ")}
                                            </Td >
                                            <Td flex={1} >
                                              <FormControl
                                                isInvalid={Boolean(
                                                  !!(errors as any)[property] &&
                                                  (touched as any)[property]
                                                )}
                                              >
                                                <RadioGroup value={(values as any)[property]} name={property} key={property} onChange={(value) => { setFieldValue(property, value); }} w="full">
                                                  <Flex w="full">
                                                    <Flex w="100%">
                                                      {conditionalRadioButton(
                                                        {
                                                          key: "0",
                                                          value: "0",
                                                          condition: default_condition,
                                                          label: [(property.includes("salary") ? `$` : ''), cand_1 !== '' ? cand_1 : ''].join(" "),
                                                        })}
                                                    </Flex>
                                                    <Flex w="100%">
                                                      {conditionalRadioButton(
                                                        {
                                                          key: "1",
                                                          value: "1",
                                                          condition: default_condition,
                                                          label: [(property.includes("salary") ? `$` : ''), cand_2 !== '' ? cand_2 : ''].join(" "),
                                                        })}
                                                    </Flex>
                                                  </Flex>
                                                </RadioGroup>
                                                {
                                                  !default_condition && (
                                                    <FormErrorMessage>
                                                      {String((errors as any)[property])}
                                                    </FormErrorMessage>
                                                  )
                                                }
                                              </FormControl>
                                            </Td>

                                          </Tr>
                                        )
                                    }

                                  }
                                })
                                }
                              </>
                            )}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <ModalFooter
                      position="sticky"
                      bottom="0"
                      background="default.white.100"
                      boxShadow="0px -3px 7px rgba(0, 0, 0, 0.05)"
                      height="75px"
                    >
                      <Button
                        variant="ghost"
                        type="button"
                        onClick={() => {
                          onClose()
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="solid"
                        onClick={() => setSubmitted(true)}
                        type="submit"
                        loadingText="Submitting"
                        isDisabled={isOpenAlert}
                        isLoading={resMerge.isLoading}
                      >
                        Merge
                      </Button>
                    </ModalFooter>
                  </form>
                </>
              )}
            </Formik>
          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  );
};

export default CandidatesMergeModal;

import { Box, Input, Link, useOutsideClick } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import '../../../../assets/css/autocomplete.css';

const AtsAutoComplete = (FieldProps: any) => {
  const [isVisible, setVisible] = useState(false);
  const onSelect = (item: any) => {
    if (typeof FieldProps.onSelect === 'function') {
      FieldProps.onSelect(item);
    }
  };

  const ref = useRef();

  const onClickOutside = () => {
    setVisible(false);
  };

  useOutsideClick({
    ref,
    handler: onClickOutside,
  });

  return (
    <Box ref={ref}>
      <Input
        name={FieldProps.name}
        placeholder={FieldProps.placeholder}
        type={FieldProps.type}
        variant={FieldProps.variant}
        value={FieldProps.value}
        onFocus={FieldProps.onFocus}
        onChange={(e: any) => {
          if (typeof FieldProps.onChange === 'function') {
            FieldProps.onChange(e);
          }
          if (e.target.value == '') {
            setVisible(false);
          }
          //   setVisible(true);
        }}
        onKeyUp={(e: any) => {
          if (typeof FieldProps.onKeyUp === 'function') {
            FieldProps.onKeyUp(e);
          }
          setVisible(true);
        }}
        onBlur={(e: any) => {
          if (typeof FieldProps.onBlur === 'function') {
            FieldProps.onBlur(e);
          }
        }}
      />
      {isVisible || FieldProps?.menuOpen ? (
        <Box className="option-container">
          {FieldProps?.options?.length > 0 ? (
            FieldProps?.options?.map((item: any, key: number) => {
              return (
                <Link
                  key={`atscomplete-${key}`}
                  onClick={() => {
                    onSelect(item);
                    setVisible(false);
                  }}
                  className="item"
                  onFocus={() => {
                    setVisible(true);
                  }}
                >
                  {item.label}
                </Link>
              );
            })
          ) : (
            <Box className="item">No result found</Box>
          )}
        </Box>
      ) : (
        <></>
      )}
      {FieldProps.isLoading ? (
        <Box className="option-container">
          <Box className="item">Loading...</Box>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AtsAutoComplete;

import { createSlice } from '@reduxjs/toolkit';
import authService from 'services/auth.service';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isCongrats: false,
  },
  reducers: {
    appError: (state, { payload }) => {
      const err: any = payload;
      if (err?.status == 403) {
        authService.logout();
      }
      return { ...state, error: payload };
    },
    updateApp: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: () => {},
});

export const { appError, updateApp } = appSlice.actions;
export default appSlice.reducer;

import { useSelector } from 'react-redux';
import AdminDashboard from './AdminDashboard';
import RecruiterDashboard from './RecruiterDashboard';

const Dashboard = () => {
  const { deals } = useSelector((state: any) => state.dashboard);
  const isAdmin = !deals.closed;
  return isAdmin ? <AdminDashboard /> : <RecruiterDashboard />;
};
export default Dashboard;

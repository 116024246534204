import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Checkbox,
  Flex,
  Icon,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

import CandidatesDrawer from './Drawer';
import Pagination from 'Library/Pagination';
import Button from 'Library/Button';
import FAIcon from 'components/lib/FAIcon';

import { BsChevronDown, BsChevronRight } from 'react-icons/bs';

import {
  getCandidate,
  listCandidates,
  useDeleteCandidatesMutation,
  useToggleHideCandidatesMutation,
  useListCandidatesMutation,
  useGetCandidatesMutation,
} from 'store/candidates.slice';

import {
  CandidateData,
  candidateJobs,
  CandidatesTypes,
  swalContent,
} from 'types';
import { AtsConfirm } from 'utils/swal';
import CandidatesTagModal from './Modals/Tag';
import CandidatesAssociateJobModal from './Modals/AssociateJobs';
// import CandidatesModal from './Modals';
import CandidatesModal from './Modals/modal';
import CandidateTR from './components/CandidateTR';
import TrLoading from '../TrLoading';
import CandidateEmail from './Modals/Email';
import { getJob, useGetJobsMutation } from 'store/jobs.slice';
import { usePlaceHolderPairMutation } from 'store/template.slice';
import ChangeStatusModal from '../Jobs/ViewCandidates/form/ChangeStatusModal';
import {
  useListCandidateFilterMutation,
  usePinListCandidateFilterMutation,
} from 'store/candidatefilter.slice';
import { debounce } from 'lodash';

const CandidatesTableV2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [reqFilterList] = useListCandidateFilterMutation();
  const [reqPinList] = usePinListCandidateFilterMutation();

  const {
    candidates,
    candidatePage,
    candidateData,
    candidatePgBtn,
    candidateLoading,
    candidateOthers,
  } = useSelector((state: any) => state.candidates);

  const [candidateSort, setCandidateSort] = useState(candidates);

  useEffect(() => {
    const sortedData = [...candidates].sort((a, b) => {
      const jobIdA = a.jobs && a.jobs.length > 0 ? a.jobs[0].id : 0;
      const jobIdB = b.jobs && b.jobs.length > 0 ? b.jobs[0].id : 0;
      return jobIdA - jobIdB;
    });
    setCandidateSort(sortedData);
  }, [candidates]);

  const [reqCandidates, resCandidates] = useListCandidatesMutation();
  const debounceReqCandidates = debounce(reqCandidates, 300);
  const [reqGetCandidate, resGetCandidate] = useGetCandidatesMutation();
  const [reqDeleteCandidate, resDeleteCandidate] =
    useDeleteCandidatesMutation();
  const [reqPair] = usePlaceHolderPairMutation();
  const [reqToggleHide, resToggleHide] = useToggleHideCandidatesMutation();
  const [reqGetJob] = useGetJobsMutation();

  const [candidateSortList, setCandidateSortList] = useState(candidates);
  const [openAccordionItems, setOpenAccordionItems] = useState([]);
  const [rowId, setRowId] = useState(1);
  const [candidateId, setCandidateId] = useState(0);
  const [candidateIdList, setCandidateIdList] = useState([0]);
  const [assocJobs, setAssocJobs] = useState([]);
  const [filterCache, setFilterCache] = useState(JSON.stringify({}));

  const [assocId, setAssocId] = useState(0);
  const [assocIdList, setAssocIdList] = useState([0]);
  const [mainStatus, setMainStatus] = useState(0);
  const [subStatus, setSubStatus] = useState(0);

  document.documentElement.dir = 'ltr';

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isSorted, setIsSorted] = useState(false);
  const [sortOrder, setSortOrder] = useState(true);
  const [orderBy, setOrderBy] = useState(null);

  const [isBulkAction, setIsBulkAction] = useState(false);
  const [emailList, setEmailList] = useState([]);

  const {
    isOpen: isOpenCandidate,
    onOpen: onOpenCandiate,
    onClose: onCloseCandidate,
  } = useDisclosure();
  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();
  const {
    isOpen: isOpenTag,
    onOpen: onOpenTag,
    onClose: onCloseTag,
  } = useDisclosure();

  const {
    isOpen: isOpenAssociate,
    onOpen: onOpenAssociate,
    onClose: onCloseAssociate,
  } = useDisclosure();

  const {
    isOpen: isOpenSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure();

  useEffect(() => {
    setIsBulkAction(false);
  }, [candidateSort, params.candidatesId]);

  const fetchCandidate = async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    // await debounceReqCandidates(candidatePgBtn);
    await reqCandidates(candidatePgBtn);
  };

  useEffect(() => {
    if (resDeleteCandidate.isSuccess || resToggleHide.isSuccess) {
      fetchCandidate();
    }
  }, [resDeleteCandidate.isSuccess, resToggleHide.isSuccess]);

  useMemo(() => {
    if (filterCache != '{}' && filterCache != JSON.stringify(candidatePgBtn)) {
      fetchCandidate();
    }
    setFilterCache(JSON.stringify(candidatePgBtn));
    return candidatePgBtn;
  }, [candidatePgBtn]);

  useEffect(() => {
    if (isSorted) {
      const params = {
        ...candidatePgBtn,
        ...(orderBy !== null && {
          orderBy: {
            column_name: orderBy?.column_name,
            sort_order: orderBy?.sort_order,
          },
        }),
      };
      console.log('trigger');
      dispatch(
        listCandidates({
          candidatePgBtn: params,
        })
      );
      setIsSorted(false);
    }
  }, [isSorted]);

  useEffect(() => {
    if (params.candidatesId && !isOpen) {
      if (candidateData?.id !== params.candidatesId) {
        pageChange(Number(params?.candidatesPageNum)).then(() => {
          onOpen();
        });
      }
      dispatch(getJob({ jobData: {} }));
    }
  }, [params.candidatesId, isOpen]);

  const statusChangeSuccess = async () => {
    setIsBulkAction(false);
    fetchCandidate();
    await reqFilterList({});
    await reqPinList({});
    // await reqGetJob({ id: params.jobsId });
  };

  const associateJobSuccess = async () => {
    setIsBulkAction(false);
    fetchCandidate();
    await reqFilterList({});
    await reqPinList({});
    // await reqGetJob({ id: params.jobsId });
  };

  const handleClick = (candidate: any) => {
    dispatch(
      getCandidate({
        candidateData: candidate,
        candidateDataJobId: candidate?.jobs?.[0]?.id,
      })
    );
    onOpen();
    // queryParameter jobID for TableV2 only
    if (candidate?.jobs?.[0]?.id) {
      navigate(
        `/candidates/${candidatePage.currentPage}/${candidate.id}/about?jobId=${candidate?.jobs?.[0]?.id}`
      );
    } else {
      navigate(
        `/candidates/${candidatePage.currentPage}/${candidate.id}/about`
      );
    }

    reqGetCandidate({ id: candidate.id });
  };

  const handleDeleteCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will delete the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqDeleteCandidate({ id: candidate.id });
      await reqFilterList({});
      await reqPinList({});

      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const handleHideCandidate = async (candidate: CandidateData) => {
    const content: swalContent = {
      title: 'Are you sure?',
      text: 'This will hide the candidate data.',
      buttons: ['Cancel', true],
      icon: 'warning',
    };
    const confirm = await AtsConfirm(content);
    if (confirm) {
      await reqToggleHide({ id: candidate.id });
      await reqFilterList({});
      await reqPinList({});
      dispatch(getCandidate({ candidateData: {} }));
    }
  };

  const stateTab: any = params.candidatesTab;

  const handlePrevData = () => {
    const candidateID = pushedItems.filter(
      (item: any) => item.rowId === rowId - 1
    )[0]?.id;
    setRowId((prevKey: number) => prevKey - 1);
    const prevData = pushedItems.filter(
      (item: any) => item.rowId === rowId - 1
    )[0];

    navigate(
      `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${prevData?.jobs[0]?.id}`
    );
    dispatch(
      getCandidate({
        candidateData: prevData,
        candidateDataJobId: prevData?.jobs[0]?.id,
      })
    );
    reqGetCandidate({ id: candidateID });
  };

  const handleNextData = () => {
    const candidateID = pushedItems.filter(
      (item: any) => item.rowId === rowId + 1
    )[0].id;
    setRowId((prevKey: number) => prevKey + 1);
    const nextData = pushedItems.filter(
      (item: any) => item.rowId === rowId + 1
    )[0];
    // queryParameter jobID for TableV2 only
    navigate(
      `/candidates/${candidatePage.currentPage}/${candidateID}/${stateTab}?jobId=${nextData?.jobs[0]?.id}`
    );
    dispatch(
      getCandidate({
        candidateData: nextData,
        candidateDataJobId: nextData?.jobs[0]?.id,
      })
    );
    reqGetCandidate({ id: candidateID });
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckWithJob, setIsCheckWithJob] = useState([] as any);
  const [isIndeterminate, setisIndeterminate] = useState(false);

  const handleSelectAll = (e: any) => {
    const { checked } = e.target;
    setIsCheckAll(checked);
    setIsCheck(candidateSort.map((li: any) => li.id));
    setIsCheckWithJob(
      candidateSort.map((li: any) => {
        return { id: li.id, job_id: li.jobs[0]?.id };
      })
    );
    if (!checked) {
      setIsCheck([]);
      setIsCheckWithJob([]);
    }
  };

  const handleCheck = async (e: any, job_id: number) => {
    const { id, checked } = e.target;
    let ID = parseInt(id);
    setIsCheck([...isCheck, ID]);
    setIsCheckWithJob([...isCheckWithJob, { id: ID, job_id: job_id }]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== ID));
      setIsCheckWithJob(
        isCheckWithJob.filter(
          (item: any) => item.id !== ID || item.job_id !== job_id
        )
      );
    }
  };

  const handleSort = async (column_name: string) => {
    const newSortOrder = sortOrder ? 'ASC' : 'DESC';
    setSortOrder(!sortOrder);
    setIsSorted(true);
    setOrderBy({
      column_name: column_name,
      sort_order: newSortOrder,
    });
  };

  useEffect(() => {
    setCandidateSortList(candidateSort);
  }, [candidateSort]);

  const [isGroupByJob, setIsGroupByJob] = useState(false);

  useEffect(() => {
    setIsGroupByJob(
      candidateOthers &&
        candidateOthers.length > 0 &&
        candidateOthers[0].group_by_job
    );
  }, [candidateOthers]);
  const { filter } = useSelector((state: any) => state.candidateFilters);

  useEffect(() => {
    if (filter) {
      setIsGroupByJob(true);
    }
  }, [filter]);

  const [pushedItems, setPushedItems] = useState([]);

  useEffect(() => {
    if (isGroupByJob) {
      const sortedData = [...candidateSort].sort((a, b) => {
        const jobIdA = a.jobs && a.jobs.length > 0 ? a.jobs[0].id : 0;
        const jobIdB = b.jobs && b.jobs.length > 0 ? b.jobs[0].id : 0;
        return jobIdA - jobIdB;
      });

      let rowId = 1; // Initialize rowId

      const groupedData = sortedData.reduce((acc: any, item: any) => {
        const jobId = item.jobs && item.jobs.length > 0 ? item.jobs[0].id : 0;
        if (!acc[jobId]) {
          acc[jobId] = [];
          setOpenAccordionItems((prevItems) => [...prevItems, String(jobId)]);
        }

        // Add rowId to the item and then push it
        const itemWithRowId = { ...item, rowId };
        acc[jobId].push(itemWithRowId);

        // Increment rowId for the next item
        rowId++;

        return acc;
      }, {});
      setCandidateSortList(groupedData);

      // Create a separate array for pushed items
      setPushedItems(Object.values(groupedData).flat());
    }
  }, [candidateOthers, candidateSort, isGroupByJob]);

  useEffect(() => {
    setisIndeterminate(false);
    // if (isCheck.length > 0 && isCheck.length < candidatePgBtn.take) {
    if (
      isCheckWithJob.length > 0 &&
      isCheckWithJob.length < candidatePgBtn.take
    ) {
      setisIndeterminate(true);
    }
    // if (isCheck.length <= 0) {
    if (isCheckWithJob.length <= 0) {
      setIsCheckAll(false);
    }
    // if (isCheck.length === candidatePgBtn.take) {
    if (isCheckWithJob.length === candidatePgBtn.take) {
      setIsCheckAll(true);
    }
  }, [candidatePgBtn.take, isCheck, isCheckWithJob, isCheckAll]);

  const tableHeader: { label: any }[] = [
    {
      label: (
        <Th
          sx={{
            position: 'sticky',
            left: 0,
            bg: 'default.white.600',
            color: 'default.gray.600',
            boxShadow: 'inset -3px 0px 2px -2px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Flex gap="30px">
            <Checkbox
              onChange={handleSelectAll}
              isIndeterminate={isIndeterminate}
              checked={isCheckAll}
              defaultChecked={false}
              colorScheme="purple"
            />
            <Box>Candidate Details</Box>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Location</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Resume Update On
            <Flex
              onClick={() => handleSort('resume_update_on')}
              cursor="pointer"
            >
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Work Status</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Created on
            <Flex onClick={() => handleSort('created_at')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Flex gap="10px" justifyContent="center" alignItems="center">
            Last Activity
            <Flex onClick={() => handleSort('last_activity')} cursor="pointer">
              <FAIcon iconName="sort" />
            </Flex>
          </Flex>
        </Th>
      ),
    },

    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Associated Jobs</Box>
        </Th>
      ),
    },
    {
      label: (
        <Th
          sx={{
            bg: 'default.white.600',
            color: 'default.gray.600',
          }}
        >
          <Box>Tags</Box>
        </Th>
      ),
    },
  ];

  const pageChange = async (page: number) => {
    let newParam = {
      ...candidatePgBtn,
      page: page,
    };
    console.log('trigger');
    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
    localStorage.setItem('candidatesFilter', JSON.stringify(newParam));
  };

  const entryChange = (entries: number) => {
    let newParam = {
      ...candidatePgBtn,
      take: entries,
    };
    console.log('trigger');
    dispatch(
      listCandidates({
        candidatePgBtn: newParam,
      })
    );
    localStorage.setItem('candidatesFilter', JSON.stringify(newParam));
  };

  const addTagClick = (id: any) => {
    setIsBulkAction(false);
    setCandidateId(id);
    onOpenTag();
  };

  const associateToJob = async (candidate: CandidateData) => {
    let list: any = [];
    // console.log('candidate', candidate);
    if (candidate.jobs.length > 0) {
      await Promise.all(
        candidate.jobs.map((job: candidateJobs) => {
          list.push(job.id);
        })
      );
    }
    // console.log('list', list);
    setAssocJobs(list);
    setCandidateId(candidate?.id);
    onOpenAssociate();
  };

  const editCandidate = async (candidate: any) => {
    // console.log("candidate ", candidate)
    await reqGetCandidate({ id: candidate.id });
    onOpenCandiate();
    setCandidateId(candidate.id);
  };

  const changeStatus = (candidate: any) => {
    if (candidate?.jobs?.length > 0) {
      candidate?.jobs?.map((job: candidateJobs) => {
        if (job?.id == params.jobsId) {
          setAssocId(Number(job?.assoc_id));
          setMainStatus(Number(job?.status.id));
          setSubStatus(Number(job?.sub.id));
          onOpenChangeStatus();
        }
      });
    }
  };

  const bulkChangeStatus = async () => {
    const candidateList = candidateSort.filter((candidate: any) =>
      // isCheck.includes(candidate.id)
      isCheckWithJob.some(
        (obj: any) =>
          obj.id === candidate.id && obj.job_id === candidate.jobs[0].id
      )
    );
    setIsBulkAction(true);
    let associateIdList: any = [];
    let mainStatusSet = false;
    candidateList?.map((candidate: any) => {
      if (candidate?.jobs?.length > 0) {
        candidate?.jobs?.map((job: candidateJobs) => {
          // if (job?.id == params.jobsId) {
          associateIdList.push(Number(job?.assoc_id));
          // }
          // SET THE MAIN STATUS AND SUB STATUS TO THE CURRENT STATUS OF FIRST CANDIDATE
          if (!mainStatusSet) {
            setMainStatus(Number(job?.status?.id));
            setSubStatus(Number(job?.sub?.id));
            mainStatusSet = true;
          }
        });
      }
    });
    setAssocIdList(associateIdList);
    onOpenChangeStatus();
  };

  const bulkAssociateToJob = async () => {
    // console.log('went to bulk associate to job');
    setIsBulkAction(true);
    const candidateList = candidateSort.filter(
      (candidate: CandidateData) => isCheck.includes(candidate.id)
      // isCheckWithJob.some((obj: any) => obj.id === candidate.id && obj.job_id === candidate.jobs[0].id)
    );
    // const uniqueCandidate = Object.values(
    //   candidateList.reduce((unique: any, item: any) => {
    //     if (!unique[item.id]) {
    //       unique[item.id] = item;
    //     }
    //     return unique;
    //   }, {})
    // ) as any;

    // console.log("uniqueCandidate ", uniqueCandidate)
    // uniqueCandidate.map(async (candidate: CandidateData) => {
    //   let list: any = [];
    //   if (candidate?.jobs?.length > 0) {
    //     await Promise.all(
    //       candidate.jobs.map((job: candidateJobs) => {
    //         list.push(job.id);
    //       })
    //     );
    //   }
    // })
    let list: any = [];
    candidateList.map(async (candidate: CandidateData) => {
      if (candidate?.jobs?.length > 0) {
        await Promise.all(
          candidate.jobs.map((job: candidateJobs) => {
            list.push(job.id);
          })
        );
      }
    });
    setAssocJobs(list);
    setCandidateIdList(isCheckWithJob.map((li: any) => li.id));
    // });
    onOpenAssociate();
  };

  // const bulkChangeStatus =

  const bulkTags = () => {
    setIsBulkAction(true);
    onOpenTag();
  };

  const bulkEmail = async () => {
    setIsBulkAction(true);
    const candidateList = candidateSort?.filter((candidate: any) =>
      isCheck.includes(candidate.id)
    );

    //making sure getting only one candidate
    const uniqueCandidate = Object.values(
      candidateList.reduce((unique: any, item: any) => {
        if (!unique[item.id]) {
          unique[item.id] = item;
        }
        return unique;
      }, {})
    );
    const emails = uniqueCandidate?.map((candidate: any) => {
      const candidateEmail = [...candidate?.emails]?.sort(
        (emailA: any, emailB: any) => {
          return emailB?.primary - emailA?.primary;
        }
      );
      return candidateEmail?.[0]?.email;
    });
    setEmailList(emails);
    setCandidateId(candidateList[0].id);
    // dispatch(getCandidate({ candidateData: candidateList[0] }));
    const data = {
      candidate_id: isCheckWithJob?.[0]?.id,
      // job_id: params?.jobsId || null,
      job_id: isCheckWithJob?.[0]?.job_id || null,
    } as any;
    // console.log("data ", data)
    await reqPair(data);
    onOpenSendEmail();
    await reqGetCandidate({ id: candidateList[0].id });
    // await reqGetJob({ id: candidateList[0]?.jobs?.[0]?.id });
  };

  const accordionItems = Object.keys(candidateSortList).map((jobId, index) => {
    const firstJob = candidateSortList[jobId][0];
    const jobTitle =
      firstJob && firstJob.jobs && firstJob.jobs.length > 0
        ? firstJob.jobs[0].title
        : 'N/A';
    const clientName =
      firstJob && firstJob.jobs && firstJob.jobs.length > 0
        ? firstJob.jobs[0].client?.name
        : 'N/A';

    return (
      <>
        {resCandidates.isLoading ||
        resToggleHide.isLoading ||
        candidateLoading ? (
          <TrLoading rows={10} columns={tableHeader.length} />
        ) : (
          <>
            <Tr
              sx={{
                bg: '#FAF5FF',
                color: 'primary.600',
                fontSize: '12px',
                fontWeight: 700,
                textTransform: 'uppercase',
                cursor: 'pointer',
              }}
              _hover={{
                bg: '#f8f9fa',
              }}
            >
              <Td
                sx={{
                  position: 'sticky',
                  left: 0,
                  p: '8px 24px',
                }}
                _hover={{
                  bg: '#f8f9fa',
                }}
              >
                <Flex
                  alignItems="center"
                  width="100%"
                  gap="10px"
                  onClick={() => {
                    const isOpen = openAccordionItems.includes(jobId);
                    setOpenAccordionItems((prevItems) =>
                      isOpen
                        ? prevItems.filter((item) => item !== jobId)
                        : [...prevItems, jobId]
                    );
                  }}
                >
                  {
                    <Box width="16px">
                      {openAccordionItems.includes(jobId) ? (
                        <Icon as={BsChevronDown} />
                      ) : (
                        <Icon as={BsChevronRight} />
                      )}
                    </Box>
                  }
                  <Flex
                    sx={{
                      whiteSpace: 'initial',
                      width: '300px',
                      textTransform: 'capitalize',
                    }}
                  >
                    {jobTitle} ({candidateSortList[jobId].length}) -{' '}
                    {clientName}
                  </Flex>
                </Flex>
              </Td>
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
            </Tr>

            {openAccordionItems.includes(jobId) &&
              Array.isArray(candidateSortList[jobId]) &&
              candidateSortList[jobId].map((candidate: any, key: number) => {
                return (
                  <CandidateTR
                    candidate={candidate}
                    handleCheck={handleCheck}
                    isCheck={isCheck}
                    handleClick={() => {
                      handleClick(candidate);
                      setRowId(candidate?.rowId);
                    }}
                    associateToJob={associateToJob}
                    addTagClick={addTagClick}
                    editCandidate={editCandidate}
                    handleDeleteCandidate={(candidate: CandidateData) =>
                      handleDeleteCandidate(candidate)
                    }
                    handleHideCandidate={(candidate: CandidateData) =>
                      handleHideCandidate(candidate)
                    }
                    fromJob={false}
                    isCheckWithJob={isCheckWithJob}
                    fromGroupByJobs={true}
                    key={key}
                  />
                );
              })}
          </>
        )}
      </>
    );
  });

  return (
    <Box px={6} pt={7} justifyContent="space-between">
      {/* {isCheck.length > 0 && ( */}
      {isCheckWithJob.length > 0 && (
        <Flex pb={6} gap={4}>
          <Tag
            display="flex"
            borderRadius="4px"
            variant="subtle"
            color="#545454"
            bg="#FAF7FF"
            fontWeight="regular"
            fontSize="md"
            border="1px solid #AE8FE2"
            height="32px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap={2} fontSize="14px" alignItems="center">
              <Box color="#6930CA" fontSize="sm">
                {/* {isCheck.length} */}
                {isCheckWithJob.length}
              </Box>{' '}
              {/* {isCheck.length > 1 ? 'items' : 'item'} selected */}
              {isCheckWithJob.length > 1 ? 'items' : 'item'} selected
            </Flex>
          </Tag>
          <Button variant="basic" leftIcon="comment-dots" size="sm">
            Send SMS
          </Button>
          <Button
            variant="basic"
            leftIcon="envelope"
            size="sm"
            onClick={bulkEmail}
          >
            Send Email
          </Button>
          <Button
            variant="basic"
            leftIcon="person-circle-check"
            size="sm"
            onClick={bulkChangeStatus}
          >
            Change Status
          </Button>
          <Button
            variant="basic"
            leftIcon="eye"
            size="sm"
            onClick={bulkAssociateToJob}
          >
            Associate to Job
          </Button>
          <Button variant="basic" leftIcon="tags" size="sm" onClick={bulkTags}>
            Add Tags
          </Button>
        </Flex>
      )}

      <TableContainer
        boxSizing="border-box"
        border="1px solid #EEEEEE"
        borderRadius="4px"
        height="70vh"
        sx={{ overflowY: 'scroll' }}
      >
        <Table>
          <Thead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <Tr bg="default.white.600">
              {tableHeader.map((title) => title.label)}
            </Tr>
          </Thead>

          <Tbody
            boxSizing="border-box"
            background="default.white.100"
            borderBottom="1px solid"
            borderColor="default.white.400"
          >
            {accordionItems}
          </Tbody>
        </Table>
      </TableContainer>
      {isOpen && (
        <CandidatesDrawer
          // key={"0"}
          isOpen={isOpen}
          onClose={onClose}
          rowId={rowId}
          setRowId={setRowId}
          totalCandidates={candidateSort?.length}
          handlePrevData={handlePrevData}
          handleNextData={handleNextData}
          isLoading={resGetCandidate.isLoading}
          candidates={pushedItems}
        />
      )}
      {isOpenTag && (
        <CandidatesTagModal
          isOpen={isOpenTag}
          onClose={onCloseTag}
          id={candidateId}
          idList={isCheck}
          isBulkTag={isBulkAction}
        />
      )}
      {isOpenChangeStatus && (
        <ChangeStatusModal
          isOpen={isOpenChangeStatus}
          onClose={onCloseChangeStatus}
          id={assocId}
          idList={assocIdList}
          main_status={mainStatus}
          sub_status={subStatus}
          onSuccess={() => statusChangeSuccess()}
          isBulkChangeStatus={isBulkAction}
        />
      )}
      {isOpenAssociate && (
        <CandidatesAssociateJobModal
          isOpen={isOpenAssociate}
          onClose={onCloseAssociate}
          id={candidateId}
          idList={candidateIdList}
          assoc_jobs={assocJobs}
          isBulkAssociateJob={isBulkAction}
          onSuccess={() => associateJobSuccess()}
        />
      )}
      {isOpenCandidate && (
        // <CandidatesModal
        //   isOpen={isOpenCandidate}
        //   onClose={onCloseCandidate}
        //   edit={true}
        //   id={candidateId}
        // />
        <CandidatesModal
          isOpen={isOpenCandidate}
          onClose={onCloseCandidate}
          edit={true}
          id={Number(candidateId)}
          candidateLoading={resGetCandidate.isLoading}
        />
      )}
      {isOpenSendEmail && (
        <CandidateEmail
          isOpen={isOpenSendEmail}
          onClose={onCloseSendEmail}
          id={candidateId}
          email={''}
          idList={isCheckWithJob}
          emailList={isBulkAction ? emailList : []}
          isBulkEmail={isBulkAction}
        />
      )}
      <Pagination
        totalPages={candidatePage.lastPage}
        currentPage={candidatePage.currentPage}
        onPageChange={(page) => pageChange(page)}
        onEntryChange={(entries) => entryChange(entries)}
        totalEntries={candidatePage.count}
        currentCount={candidateSort?.length}
        targetCount={candidatePgBtn.take}
      />
    </Box>
  );
};

export default CandidatesTableV2;
